import React, { useEffect, useState } from "react";
import {
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from "../../service/constant";
import { getDataWithoutToken } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import { getAllAlternatives } from "../../redux/actions/alternative-financing-action";
import { addToArray, getMonth } from "../../utils";
import { useHistory } from "react-router-dom";

export default function AlternativeHomeController() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);

  const [limitSolution, setLimitSolution] = useState(6);
  const [limitIndustry, setLimitIndustry] = useState(6);
  const [limitCountry, setLimitCountry] = useState(6);
  const [limitFunding, setLimitFunding] = useState(6);

  const [solutions, setSolutions] = useState<Array<any>>([]);
  const [featuredSolutions, setFeaturedSolutions] = useState<Array<any>>([]);
  const [industries, setIndustries] = useState<Array<any>>([]);
  const [hqLocation, setHqLocation] = useState<Array<any>>([]);
  const [fundingTarget, setFundingTarget] = useState<Array<any>>([]);

  const [selectedSolution, setSelectedSolution] = useState<Array<number>>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<Array<number>>([]);
  const [selectedCountry, setSelectedCountry] = useState<Array<number>>([]);
  const [selectedFunding, setSelectedFunding] = useState<Array<number>>([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [freeNumber, setFreeNumber] = useState<number>(0);
  const now = new Date();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );

  const [searchText, setSearchText] = useState("");

  const alternativeReducer = useSelector(
    (state: rootState) => state?.alternativeReducer?.all_alternatives
  );
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const companyInvestorPage = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const [allText, setAllText] = useState(["", "", ""]);
  const [showAdsModalP, setShowAdsModalP] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  async function getFiltered() {
    setShowLoading(true);
    let urlType = url?.alternative?.filterAlternative;

    let response = await getDataWithoutToken(urlType);
    if (response?.status === 200) {
      setSolutions(response?.data?.software_solution);
      setFeaturedSolutions(response?.data?.featured_solution);
      setIndustries(response?.data?.targeted_industry);
      setHqLocation(response?.data?.hq_location);
      setFundingTarget(response?.data?.funding_target);
      setShowLoading(false);
    }
  }

  const callback = (data: any) => {
    setShowLoading(false);
  };

  const getAlternatives = () => {
    setShowLoading(true);
    let params = {
      software_solution: [],
      featured_solution: [],
      targeted_industry: [],
      country: [],
      funding: [],
      itemsPerPage: 10,
      page: 1,
      search: undefined,
    };
    dispatch(getAllAlternatives(params, callback));
  };

  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isGuest = userReducer?.roles?.indexOf(GUEST) !== -1;
  const isReseller = userReducer?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = userReducer?.roles?.indexOf(INVESTOR) !== -1;
  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;

  const pathName = window?.location?.pathname;
  const isAlternative = pathName.includes("/alternative-financing");

  useEffect(() => {
    if (isAlternative) {
      getFiltered();
      getAlternatives();
    }
    return () => {};
  }, [isAlternative]);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );

      if (response?.status === 200) {
        const dynamicKey = isIsv
          ? "isv_free_corporate_ads"
          : isGuest
          ? "guest_free_corporate_ads"
          : isReseller
          ? "reseller_corporate_ads"
          : isInvestor
          ? "investor_free_corporate_ads"
          : isOem
          ? "oem_program_corporate_ads"
          : "isv_free_corporate_ads";
        const freeNumberStatus: any = response?.data?.free_status[dynamicKey];
        setFreeNumber(freeNumberStatus?.number_left || 0);
      }
      setChargingPlanDetails(false);
    }
    if (userReducer.token) {
      getPlanDetails();
    }
  }, [currentMonth, userReducer.token]);

  const dataSolution = [...(solutions || []), ...(featuredSolutions || [])];

  const handleSeeSolution = (action: string) => {
    setLimitSolution(action === "0" ? dataSolution.length : 6);
  };

  const handleSeeIndustry = (action: string) => {
    setLimitIndustry(action === "0" ? industries?.length || 0 : 6);
  };

  const handleSeeCountry = (action: string) => {
    setLimitCountry(action === "0" ? hqLocation?.length || 0 : 6);
  };

  const handleSeeFunding = (action: string) => {
    setLimitFunding(action === "0" ? fundingTarget?.length || 0 : 6);
  };

  const handleSelectSolution = (element: number) => {
    setSelectedSolution(addToArray(selectedSolution, element));
  };

  const handleSelectIndustry = (element: number) => {
    setSelectedIndustry(addToArray(selectedIndustry, element));
  };

  const handleSelectFunding = (element: number) => {
    setSelectedFunding(addToArray(selectedFunding, element));
  };

  const handleSelectCountry = (element: number) => {
    setSelectedCountry(addToArray(selectedCountry, element));
  };

  const filterAlternative = () => {
    setShowLoading(true);
    const idFeatured = featuredSolutions?.map((el) => el.id) || [];
    const idSoftware = solutions?.map((el) => el.id) || [];

    const makeSoftware = selectedSolution.filter((el) => {
      if (idSoftware.includes(el)) {
        return el;
      }
    });

    const makeFeatured = selectedSolution.filter((el) => {
      if (idFeatured.includes(el)) {
        return el;
      }
    });

    let params = {
      software_solution: "[" + makeSoftware.join().trim() + "]",
      featured_solution: "[" + makeFeatured.join().trim() + "]",
      targeted_industry: "[" + selectedIndustry.join().trim() + "]",
      country: "[" + selectedCountry.join().trim() + "]",
      funding: "[" + selectedFunding.join().trim() + "]",
      itemsPerPage: 10,
      page: 1,
      search: searchText,
    };
    dispatch(getAllAlternatives(params, callback));
  };

  const resetFilter = () => {
    setShowLoading(true);
    setSelectedCountry([]);
    setSelectedSolution([]);
    setSelectedFunding([]);
    setSelectedIndustry([]);

    let params = {
      software_solution: [],
      featured_solution: [],
      targeted_industry: [],
      country: [],
      funding: [],
      itemsPerPage: 10,
      page: 1,
      search: undefined,
    };
    dispatch(getAllAlternatives(params, callback));
  };

  const handlePageClick = (data: any) => {
    setShowLoading(true);
    const idFeatured = featuredSolutions?.map((el) => el.id) || [];
    const idSoftware = solutions?.map((el) => el.id) || [];

    const makeSoftware = selectedSolution.filter((el) => {
      if (idSoftware.includes(el)) {
        return el;
      }
    });

    const makeFeatured = selectedSolution.filter((el) => {
      if (idFeatured.includes(el)) {
        return el;
      }
    });

    let params = {
      software_solution: "[" + makeSoftware.join().trim() + "]",
      featured_solution: "[" + makeFeatured.join().trim() + "]",
      targeted_industry: "[" + selectedIndustry.join().trim() + "]",
      country: "[" + selectedCountry.join().trim() + "]",
      funding: "[" + selectedFunding.join().trim() + "]",
      itemsPerPage: 10,
      page: data,
      search: searchText,
    };

    dispatch(getAllAlternatives(params, callback));
  };

  const search = () => {
    setShowLoading(true);
    const idFeatured = featuredSolutions?.map((el) => el.id) || [];
    const idSoftware = solutions?.map((el) => el.id) || [];

    const makeSoftware = selectedSolution.filter((el) => {
      if (idSoftware.includes(el)) {
        return el;
      }
    });

    const makeFeatured = selectedSolution.filter((el) => {
      if (idFeatured.includes(el)) {
        return el;
      }
    });

    let params = {
      software_solution: "[" + makeSoftware.join().trim() + "]",
      featured_solution: "[" + makeFeatured.join().trim() + "]",
      targeted_industry: "[" + selectedIndustry.join().trim() + "]",
      country: "[" + selectedCountry.join().trim() + "]",
      funding: "[" + selectedFunding.join().trim() + "]",
      itemsPerPage: 10,
      page: 1,
      search: searchText,
    };

    dispatch(getAllAlternatives(params, callback));
  };

  const postAlternative = () => {
    history.push("/alternative-financing/post");
  };

  const goToPostAlternative = () => {
    const isCompteActive = userReducer?.enable;

    if (isCompteActive) {
      const showCompleteInfoAlert =
        userReducer?.roles?.[0] === ISV &&
        !userReducer?.isvInCompanyPage?.company_page?.completed;

      const investorNotValidePage =
        userReducer?.roles?.[0] === INVESTOR &&
        !companyInvestorPage.validate_by_devinsider;

      if (showCompleteInfoAlert) {
        setAllText([
          "Oops...",
          "",
          "You must be affiliated to a company page in order to publish Alternative financing",
        ]);
        setShowAdsModalP(true);
        return;
      }

      if (investorNotValidePage) {
        setAllText([
          "Oops...",
          "",
          "Please publish your investor page to access publish Alternative financing",
        ]);
        setShowAdsModalP(true);
        return;
      }
      setShowPaymentModal(true);
    } else {
      setShowLogin(true);
    }
  };

  return {
    dataSolution,
    limitSolution,
    handleSeeSolution,
    industries,
    limitIndustry,
    handleSeeIndustry,
    hqLocation,
    limitCountry,
    handleSeeCountry,
    fundingTarget,
    limitFunding,
    handleSeeFunding,
    alternativeReducer,
    selectedCountry,
    selectedFunding,
    selectedIndustry,
    selectedSolution,
    handleSelectCountry,
    handleSelectFunding,
    handleSelectIndustry,
    handleSelectSolution,
    filterAlternative,
    resetFilter,
    handlePageClick,
    searchText,
    search,
    setSearchText,
    setShowLogin,
    showLogin,
    allText,
    setShowAdsModalP,
    showAdsModalP,
    goToPostAlternative,
    showLoading,
    setShowPaymentModal,
    showPaymentModal,
    postAlternative,
    chargingPlanDetails,
    freeNumber,
  };
}
