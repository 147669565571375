import React, { useState } from "react";

export default function EventFilter() {
  const [sliderValue, setSliderValue] = useState<Array<number>>([60, 100]);
  const [selectedHideType, setSelectedHideType] = useState<Array<string>>([]);
  const [selectedCountry, setSelectedCountry] = useState<Array<string>>([]);
  const [selectedSegment, setSelectedSegment] = useState<Array<string>>([]);
  const [selectedTargeted, setSelectedTargeted] = useState<Array<string>>([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState<Array<string>>(
    []
  );
  const [selectedAnnual, setSelectedAnnual] = useState<Array<string>>([]);
  const [selectedFunding, setSelectedFunding] = useState<Array<string>>([]);
  const [selectedFundraising, setSelectedFundraising] = useState<Array<string>>(
    []
  );

  const [isChechedContacted, setisChechedContacted] = useState(false);
  const [unlockedByUser, setUnlockedByUser] = useState<string>("");

  const setupAddArray = (
    arrayData: Array<string>,
    id: string
  ): Array<string> => {
    let found = arrayData?.find((el: string) => el === id);
    if (found) {
      return arrayData?.filter((el: string) => el !== id);
    } else {
      return [...arrayData, id];
    }
  };

  const handleHideType = async (id: string) => {
    let tempFiltered = setupAddArray(selectedHideType, id?.toString());
    setSelectedHideType(tempFiltered);
  };

  const handleCountry = async (id: string) => {
    let tempFiltered = setupAddArray(selectedCountry, id?.toString());
    setSelectedCountry(tempFiltered);
  };

  const handleSegment = async (id: string) => {
    let tempFiltered = setupAddArray(selectedSegment, id?.toString());
    setSelectedSegment(tempFiltered);
  };

  const handleTargeted = async (id: string) => {
    let tempFiltered = setupAddArray(selectedTargeted, id?.toString());
    setSelectedTargeted(tempFiltered);
  };

  const handleCompanySize = async (id: string) => {
    let tempFiltered = setupAddArray(selectedCompanySize, id?.toString());
    setSelectedCompanySize(tempFiltered);
  };

  const handleAnnual = async (id: string) => {
    let tempFiltered = setupAddArray(selectedAnnual, id?.toString());
    setSelectedAnnual(tempFiltered);
  };

  const handleFundingRound = async (id: string) => {
    let tempFiltered = setupAddArray(selectedFunding, id?.toString());
    setSelectedFunding(tempFiltered);
  };

  const handleFundraising = async (id: string) => {
    let tempFiltered = setupAddArray(selectedFundraising, id?.toString());
    setSelectedFundraising(tempFiltered);
  };

  const handleUserSeleted = async (id: string) => {
    setUnlockedByUser(id);
  };

  const resetFilterEvent = async () => {
    if (selectedCountry?.length > 0) setSelectedCountry([]);
    if (selectedCompanySize?.length > 0) setSelectedCompanySize([]);
    if (selectedSegment?.length > 0) setSelectedSegment([]);
    if (selectedAnnual?.length > 0) setSelectedAnnual([]);
    if (selectedTargeted?.length > 0) setSelectedTargeted([]);
    if (sliderValue?.toString() !== [60, 100].toString())
      setSliderValue([60, 100]);
    if (selectedHideType?.length > 0) setSelectedHideType([]);
    if (selectedFunding?.length > 0) setSelectedFunding([]);
    if (selectedFundraising?.length > 0) setSelectedFundraising([]);
    if (isChechedContacted) setisChechedContacted(false);
    if (unlockedByUser !== "") setUnlockedByUser("");
  };

  const handleIsCheched = () => {
    setisChechedContacted(!isChechedContacted);
  };

  return {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
    handleIsCheched,
    isChechedContacted,
    unlockedByUser,
    handleUserSeleted,
  };
}
