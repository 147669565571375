/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from "react";
import "./Dashboard.scss";

import UseDashBoard from "../../../controller/dashboard-ctr/commun-dashboars/commun-dashboard";
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  CONTRIBUTOR,
  GUEST,
} from "../../../service/constant/index";
import {
  concatString,
  fileUrl,
  getTxtNameAccount,
  refaCtoNullKey,
  uppercaseFirstLetter,
} from "../../../utils";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import Header from "../../widget/index";
import * as Section from "./sections/index";
import { LoadingAllPage } from "../../widget/loader";

import { Redirect } from "react-router-dom";
import { getBanner } from "../../../service/applicatif/acount";

export default function DashboardPage() {
  const providerUseDashBoard = { ...UseDashBoard() };
  const userState = useSelector((state: rootState) => state.userReducer);
  const isOem = userState?.roles?.[0] === OEM_PROGRAM;
  const isResseller = userState?.roles?.[0] === RESELLER;
  const isInvestor = userState?.roles?.[0] === INVESTOR;
  const [urlDashboard, setUrlDashboard] = useState<{
    dashboard1: string | null;
    dashboard2: string | null;
    target_url1: string | null;
    target_url2: string | null;
  }>({
    dashboard1: null,
    dashboard2: null,
    target_url1: null,
    target_url2: null,
  });
  const isShowLoader =
    providerUseDashBoard.loader &&
    Object.values(providerUseDashBoard.loaderAtribute).every(
      (el) => el === true
    );

  const urlPath = window.location.pathname;

  const isOemPage = isOem && urlPath !== "/community/oem-feeds";

  const isInvestorPage = isInvestor && urlPath !== "/community/investor-feeds";

  const getB = async () => {
    const resp = await getBanner({ type: "dashboard" });
    if (resp && resp.status === 200) {
      const data = resp && resp.data;
      setUrlDashboard(data);
    }
  };

  React.useEffect(() => {
    getB();
  }, []);

  if (isOemPage) {
    return <Redirect to="/community/oem-feeds" />;
  }

  if (isInvestorPage) {
    return <Redirect to="/community/investor-feeds" />;
  }

  return (
    <div className="bggris dashboard">
      <Header />

      {userState?.roles?.[0] === ISV && <Section.CompletedSection />}

      <section className="containerDashboard">
        {isShowLoader && <LoadingAllPage />}
        <div className="container mx-auto row">
          <div className="leftDashboard">
            <div className="bannerAds dashboard">
              {urlDashboard?.dashboard1 && (
                <img
                  src={`${fileUrl(urlDashboard?.dashboard1)} `}
                  alt=""
                  onClick={() => {
                    window.open(urlDashboard.target_url1 || "", "_blank");
                  }}
                />
              )}
            </div>
            <div className="block allblock">
              <h3 className="title">{getTxtNameAccount(userState.roles)}</h3>
              <ul className="listAffiliatedleft">
                <li>
                  <div
                    className={`imageAffiliatedcompany ${
                      providerUseDashBoard?.userReducer?.file_name
                        ? "empty"
                        : ""
                    }`}
                  >
                    <img
                      src={`${fileUrl(
                        providerUseDashBoard?.userReducer?.file_name
                      )} `}
                      alt=""
                    />
                  </div>
                  <div
                    className="infoAffiliated"
                    onClick={providerUseDashBoard.showProfil}
                  >
                    <h4 className="title pointer">
                      {concatString(
                        uppercaseFirstLetter(
                          providerUseDashBoard?.userReducer?.first_name
                        ),
                        uppercaseFirstLetter(
                          providerUseDashBoard?.userReducer?.last_name
                        ),
                        " "
                      )}
                    </h4>
                    <div className="post">
                      {uppercaseFirstLetter(
                        providerUseDashBoard?.userReducer?.job_title
                      )}
                    </div>
                    <div className="adress">
                      {uppercaseFirstLetter(
                        providerUseDashBoard?.userReducer?.country?.country_name
                      )}
                    </div>
                  </div>
                </li>
                {providerUseDashBoard?.companyPageObject &&
                  !isOem &&
                  !isResseller &&
                  !CONTRIBUTOR &&
                  !GUEST && (
                    <li
                      className="companyItem"
                      onClick={() => providerUseDashBoard?.goToCompanyPage()}
                    >
                      <div
                        className={`imageAffiliatedcompany ${
                          !providerUseDashBoard?.companyPageObject?.profil
                            ? "empty"
                            : "imgSv"
                        }`}
                      >
                        {providerUseDashBoard?.companyPageObject?.profil && (
                          <img
                            src={fileUrl(
                              providerUseDashBoard?.companyPageObject?.profil
                            )}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="infoAffiliated">
                        <h4 className="title">
                          {
                            providerUseDashBoard?.companyPageObject
                              ?.company_name
                          }
                        </h4>

                        <div className="post">
                          {concatString(
                            refaCtoNullKey(
                              providerUseDashBoard?.companyPageObject?.country,
                              ""
                            ),
                            refaCtoNullKey(
                              providerUseDashBoard?.companyPageObject?.city,
                              ""
                            )
                          )}
                        </div>
                      </div>
                    </li>
                  )}
              </ul>
            </div>
            {userState?.roles?.[0] !== GUEST && (
              <Section.UpcommingEvent
                data={providerUseDashBoard.upComingEvent}
                viewAllEvent={() => providerUseDashBoard?.viewAllEvent()}
                navigateSelectedEvent={(eventId: number) =>
                  providerUseDashBoard.navigateSelectedEvent(eventId)
                }
              />
            )}

            {providerUseDashBoard.subscribedDiscussion?.length > 0 && (
              <Section.SubscribeDiscussion
                data={providerUseDashBoard.subscribedDiscussion}
              />
            )}
          </div>

          <div className="rightDashboard">
            <div className="bannerAds dashboard rectangle">
              {urlDashboard?.dashboard2 && (
                <img
                  src={`${fileUrl(urlDashboard?.dashboard2)} `}
                  alt=""
                  onClick={() => {
                    window.open(urlDashboard.target_url2 || "", "_blank");
                  }}
                />
              )}
            </div>
            <Section.MyFeeds />
          </div>
        </div>
      </section>
    </div>
  );
}
