/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteCompanyLogoReseller from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-logo-reseller";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function CompleteCompanyLogoReseller() {
  const {
    fileRef,
    changeHandler,
    handleBtn,
    selectedFile,
    isFilePicked,
    goBack,
    createResellerAcount,
    companyTradeName,
    loader,
  } = UseCompleteCompanyLogoReseller();
  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
          </div>
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Almost done! Upload your company logo.</h1>
        </div>
        <div className="contentBorder row align-items-stretch small-container">
          <div className="col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated">
            <a
              className={`upload-photo square ${
                selectedFile ? "noBackground" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleBtn();
              }}
            >
              {isFilePicked && selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt="" />
              ) : (
                <span></span>
              )}
            </a>

            <div className="info-final-getstarted">
              <div className="nom">{companyTradeName}</div>
              <div className="post">Software reseller</div>
            </div>
          </div>
          <div className="col-xl-5 col-md-6 col-12 d-flex align-items-center justify-content-end py-4 p-0 ">
            <a
              className="link-add-photo"
              onClick={(e) => {
                e.preventDefault();
                handleBtn();
              }}
            >
              Add logo
            </a>
            <input
              type="file"
              id="getFile"
              accept="image/png, image/jpeg"
              ref={fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                changeHandler(event)
              }
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
        <div className="contentBorder row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => createResellerAcount()}
            disabled={loader}
          >
            {loader ? <Loader /> : "Done"}
          </button>

          <a className="skip d-none" onClick={(e) => e.preventDefault()}>
            Skip
          </a>
        </div>
      </div>
    </div>
  );
}
