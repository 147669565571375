import React, { useState, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { convertFromRaw } from "draft-js";
import { ItemsDataSource } from "../../../screen/container/investor/profil-investor/types";
import { toast } from "react-toastify";
import { txtExedUpload } from "../../../utils/txt-message";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { ISV } from "../../../service/constant";
export default function UseWysiwygList(props: { initialData: Array<any> }) {
  let tempInitialData: Array<ItemsDataSource> = Array.from(
    new Array(1).keys()
  ).map((el) => ({
    id: "",
    itemIndex: el?.toString(),
    editorState: EditorState.createEmpty(),
    dataFile: "",
    fileUrl: "",
    disposition: "upTextPhoto",
    isChange: false,
    idForm: el?.toString(),
  }));

  const [isHaveError, setIsHaveError] = useState<boolean>(false);
  const [initialeDataSource, setInitialeDataSource] = useState<
    Array<ItemsDataSource>
  >([]);
  const [idItemRemove, setIdItemRemove] = useState<Array<any>>([]);
  const currentUser = useSelector((state: rootState) => state.userReducer);

  //Change disposition class name
  const choseDisposition = (p: string, itemIndex: number) => {
    let tempToChange = initialeDataSource?.map(
      (el: ItemsDataSource, index: number) => {
        if (index?.toString() === itemIndex?.toString()) {
          return { ...el, disposition: p, isChange: true };
        } else {
          return el;
        }
      }
    );
    setInitialeDataSource(tempToChange);
  };

  useEffect(() => {
    let initialDraft = props?.initialData?.filter(
      (el: any) => el?.draft === true && el?.html !== ""
    );

    if (currentUser.roles.includes(ISV)) {
      initialDraft = props?.initialData?.filter(
        (el: any) => el?.draft === false && el?.html !== ""
      );
    }

    if (initialDraft?.length > 0) {
      let customDataSource = initialDraft?.map((el: any) => ({
        id: el?.id,
        itemIndex: el?.id_form?.toString(),
        editorState:
          el?.html &&
          EditorState?.createWithContent(convertFromRaw(JSON.parse(el?.html))),
        fileUrl: el?.file_location,
        dataFile: "",
        disposition: el?.class_name,
        isChange: false,
        idForm: el?.id_form?.toString(),
      }));
      console.log("initialDraft? ", initialDraft);

      setInitialeDataSource(customDataSource);
    }
    if (initialDraft?.length < 1) {
      setInitialeDataSource(tempInitialData);
    }

    return () => {};
  }, [props?.initialData]);

  /**
   * Change dataFile to file
   * update fileUrl ro empty
   * update isChange true
   */
  const setFileExpertise = async (event: any, itemIndex: any) => {
    if (event?.target?.files?.[0]) {
      let fileName = event?.target?.files?.[0].name
        ?.split(".")
        .pop()
        ?.toLowerCase();
      const fileSize = event?.target?.files?.[0]?.size;
      if (fileSize > 2000000) {
        toast(txtExedUpload);
        return;
      }

      const acceptedForm =
        fileName === "png" ||
        fileName?.includes("jpeg") ||
        fileName?.includes("jpg") ||
        fileName?.includes("jfif");
      if (acceptedForm) {
        let tempToChange = initialeDataSource?.map(
          (el: ItemsDataSource, index: number) => {
            if (index?.toString() === itemIndex?.toString()) {
              return {
                ...el,
                dataFile: event?.target?.files[0],
                fileUrl: "",
                isChange: true,
              };
            } else {
              return el;
            }
          }
        );
        setInitialeDataSource(tempToChange);
      }
    }
  };

  /**
   * add new element a,d set itemIndex = itemIndex + 1
   * setIsChange false
   */
  const addNewElement = () => {
    if (isHaveError) {
      setIsHaveError(false);
    }
    let lastValueIndex = initialeDataSource[initialeDataSource?.length - 1];
    let tempValueToAadd: any = {
      id: "",
      editorState: EditorState.createEmpty(),
      dataFile: "",
      fileUrl: "",
      disposition: "upTextPhoto",
      itemIndex: parseInt(lastValueIndex?.itemIndex || "0") + 1,
      isChange: false,
    };
    setInitialeDataSource([...initialeDataSource, tempValueToAadd]);
  };

  /**
   * remove an element in dataSource
   * if id exist and not null , save id to remove
   *  update DataSource without id not remove
   *
   */
  const removeElement = (p: any) => {
    let id = initialeDataSource?.find(
      (el: ItemsDataSource, index: number) =>
        index?.toString() === p?.toString()
    )?.id;
    let tempData = initialeDataSource?.filter(
      (el: ItemsDataSource, index: number) =>
        index?.toString() !== p?.toString()
    );
    if (id && id !== "") {
      setIdItemRemove([...idItemRemove, id]);
    }
    setInitialeDataSource(tempData);
  };

  const removeFile = (itemIndex: number) => {
    let tempData = initialeDataSource?.map(
      (el: ItemsDataSource, index: number) => {
        if (index?.toString() === itemIndex?.toString()) {
          return {
            ...el,
            dataFile: "",
            fileUrl: "",
            isChange: true,
          };
        } else {
          return el;
        }
      }
    );
    setInitialeDataSource(tempData);
  };

  const setEditorState = (p: any, itemIndex: any) => {
    console.log("setEditorState", p, itemIndex);
    let tempToChange = initialeDataSource?.map(
      (el: ItemsDataSource, index: number) => {
        if (index?.toString() === itemIndex?.toString()) {
          return { ...el, editorState: p, isChange: true };
        } else {
          return el;
        }
      }
    );
    setInitialeDataSource(tempToChange);
  };

  return {
    setEditorState,
    initialeDataSource,
    choseDisposition,
    addNewElement,
    removeElement,
    setFileExpertise,
    removeFile,
    isHaveError,
    idItemRemove,
    setIdItemRemove,
  };
}
