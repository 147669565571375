/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import {
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import * as T from '../../types/types-company-page';
import { getSStorage } from '../../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { getOtherCompanyPageAttributeAction } from '../../../../redux/actions/company-page-isv-action/other_isv_action';
import { OEM_PROGRAM } from '../../../../service/constant';
var _ = require('lodash');

export default function UseSoftwareSolutionCtr() {
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const history = useHistory();

  const idOtherUser = params?.id ? true : false;

  /************************************
   *
   * Company profil object
   */
  let companyPageObject = useSelector((state: rootState) =>
    idOtherUser ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );
  /*************************************
   *
   * User profil object
   */
  let userObject = useSelector((state: rootState) => state.userReducer);
  let oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  /**************************************
   *
   * Use for show more and show less
   */
  const [index1, setindex1] = useState<number>(6);

  const [loaderSoftWareSolution, setLoader] = useState<boolean>(false);

  const [isHaveError, setIsHaveError] = useState<boolean>(false);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  /**********************************************
   *
   * Company software solution to edit
   */
  const [showRequestSoftwareSolution, setShowRequestSoftwareSolution] =
    useState(false);

  const [featuredSolution, setFeaturedSolution] = useState<T.ISolutionListe>();

  /****************************************************
   *
   * Segment detail liste
   */
  const [allResellerSolution, setAllResellerSolution] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<T.IdataMakeSolution>
  >([]);

  const [allSolutionSellected, setAllSolutionSellected] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    async function getSolution() {
      let dataSolutionOffert = dataCompleted?.segments;
      let featured = dataSolutionOffert?.find((el: any) => el.id === 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataSolutionOffert?.filter((el: any) => el.id < 9999)
      );
    }
    getSolution();
    return () => {};
  }, []);

  useEffect(() => {}, [allSolutionSellected]);

  useEffect(() => {}, [solutionChecked]);

  const isNameIncludes = (value1: string, value2: string) => {
    if (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      value2?.trim() == "Analytics Software" &&
      value1?.trim() == "Sales Analytics Software"
    ) {
      return false;
    }
    return (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      (value1?.toLowerCase().includes(value2?.toLowerCase()) ||
        value2?.toLowerCase().includes(value1?.toLowerCase()))
    );
  };

  useEffect(() => {
    let lengthSolution = allResellerSolution?.length;
    if (lengthSolution % 2 == 0) {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, lengthSolution / 2)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(
          (lengthSolution - 1) / 2 + 1,
          lengthSolution + 1
        )
      );
    }
    return () => {};
  }, [allResellerSolution]);

  const transFormnData = (
    data: any
  ): Array<{
    idSolutionOffert: number;
    idSegment: number;
    nameSegment: string;
  }> => {
    const removeOther = data?.solutionOffert?.filter(
      (el: any) => el.name?.toLocaleLowerCase() !== "other"
    );
    let custom = removeOther?.map((el: any) => {
      let temp = {
        idSolutionOffert: data.idSolutionOffert,
        idSegment: el && el.id,
        nameSegment: el && el.name,
      };
      return temp;
    });
    return custom;
  };

  const [allOtherValue, setAllOtherValue] = useState<
    Array<{
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
      otherTxt: string;
    }>
  >([]);

  const setOtherValueTxt = (
    data: {
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
    },
    otherTxt: string
  ) => {
    const foundIfAlreadyExist = allOtherValue.find(
      (el) => el.idSegment?.toString() === data.idSegment?.toString()
    );

    let tempTxt = [];

    if (foundIfAlreadyExist) {
      tempTxt = allOtherValue.map((el) => {
        if (el.idSegment === data.idSegment) {
          return {
            ...el,
            otherTxt,
          };
        } else {
          return el;
        }
      });
    } else {
      tempTxt = [...allOtherValue, { ...data, otherTxt }];
    }
    setAllOtherValue(tempTxt);
  };

  //  idSolutionOffert: solution.id,
  //                                   solutionOffert: solution.segmentDetails,

  // Dev #48487, [ISV] Company page - bug sur la séléction des "software solutions" qui sont affichées plusieurs fois dans la liste
  //Dev #50488
  const selectSolution = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<T.IdataMakeSolution> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple select All
    if (data && data.solutionOffert) {
      //find if bouton is selected all is clicked
      let solutionSellectedAll = allSolutionSellected?.find(
        (el) => el == data.idSolutionOffert
      );
      //tranform data if array
      let dataAfterTransform = transFormnData(data);
      dataMake = [...dataAfterTransform];
      //if selected all is not clicked
      if (!solutionSellectedAll) {
        //remove if item is existe deja
        let dataToStore =
          solutionChecked &&
          solutionChecked.filter(
            (el: any) => el.idSolutionOffert != data.idSolutionOffert
          );

        dataMake?.map((el: any) => {
          dataMake = setRightLeftFeaturedSolutions(dataMake, el);
        });

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setSolutionChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllSolutionSellected([
          ...allSolutionSellected,
          data.idSolutionOffert,
        ]);
      } else {
        let tempSolutions = [...solutionChecked];
        data?.solutionOffert?.map((el: any) => {
          tempSolutions = tempSolutions?.filter(
            (elem: any) =>
              el.id != elem.idSegment &&
              el.name != elem.nameSegment &&
              !isNameIncludes(elem.nameSegment, el.name)
          );
        });

        setSolutionChecked(tempSolutions);

        //Make selected all is not clicked
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != data.idSolutionOffert)
        );
      }
    } else {
      dataMake = [data];
      let found = solutionChecked?.find(
        (el: any) => el.idSegment == data.idSegment
      );

      if (!found) {
        dataMake = setRightLeftFeaturedSolutions(dataMake, data);
        setSolutionChecked([...solutionChecked, ...dataMake]);
      } else {
        //deselection item
        const removeOtherTxt = allOtherValue?.filter(
          (tempRemove) =>
            tempRemove.idSegment.toString() !== data.idSegment?.toString()
        );

        setAllOtherValue(removeOtherTxt);

        let tempSolutions = solutionChecked?.filter(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            !isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        setSolutionChecked(tempSolutions);

        let found2 = solutionChecked?.find(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        if (found2) {
          setAllSolutionSellected(
            allSolutionSellected.filter(
              (el) =>
                el != data.idSolutionOffert &&
                el != found2?.idSolutionOffert.toString()
            )
          );
        } else {
          setAllSolutionSellected(
            allSolutionSellected.filter((el) => el != data.idSolutionOffert)
          );
        }
      }
    }
  };
  // Dev #48487, [ISV] Company page - bug sur la séléction des "software solutions" qui sont affichées plusieurs fois dans la liste
  const removeItemSelected = (solution: any) => {
    let found = solutionChecked?.find(
      (el: T.IdataMakeSolution) => el.idSegment == solution.idSegment
    );
    if (found) {
      let temp: Array<T.IdataMakeSolution> = solutionChecked?.filter(
        (t: T.IdataMakeSolution) => {
          return (
            t.idSegment !== solution.idSegment &&
            !isNameIncludes(t.nameSegment, solution.nameSegment)
          );
        }
      );
      setSolutionChecked(temp);
      if (solution.idSolutionOffert) {
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != solution.idSolutionOffert)
        );
      }
    } else {
      return;
    }
  };

  const setRightLeftFeaturedSolutions = (
    dataMake: any,
    data: T.IdataMakeSolution
  ) => {
    //featured solutions
    solutionLeftColumnData?.forEach((el: T.ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: T.ItemsSolution) => {
        //check if already exists in solutionschecked
        let checkIfExists = solutionChecked?.find(
          (solution: any) => segmentDetails?.id === solution?.idSegment
        );
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment &&
          !checkIfExists
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    solutionRigthColumnData?.forEach((el: T.ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: T.ItemsSolution) => {
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    featuredSolution?.segmentDetails?.forEach((el: T.ItemsSolution) => {
      if (
        isNameIncludes(el.name, data?.nameSegment) &&
        el.id !== data.idSegment
      ) {
        dataMake = [
          ...dataMake,
          {
            idSegment: el.id,
            idSolutionOffert: featuredSolution.id,
            nameSegment: el.name,
          },
        ];
      }
    });
    return dataMake;
  };

  /**
   *  Update info Software solution  company Page
   */
  const updateSoftWareSolution = () => {
    if (solutionChecked?.length <= 0) {
      setIsHaveError(true);
    } else {
      //Remove other data to setString value
      const idSegmentDetail = solutionChecked?.map((el) => el.idSegment);
      //get txt in other value
      const valueOfOther = allOtherValue?.map((el) => ({
        id: el.idSolutionOffert,
        other: el.otherTxt?.trim(),
      }));

      //combine params

      let dataInfo = {
        segmentDetails: JSON.stringify(idSegmentDetail),
        otherSegmentDetails: JSON.stringify(valueOfOther),
      };

      setLoader(true);
      dispatch(
        updateCompanyPageAction(
          dataInfo,
          companyPageObject.id,
          cbUpdateSoftwareSolutionCompany
        )
      );
    }
  };

  const cbUpdateSoftwareSolutionCompany = (data: any) => {
    if (data && data.status === 200) {
      setSoftwareSolutionAttribute();
      setAllOtherValue([]);
    }
  };

  // Get completed attribute Segment detail , to show with data
  const cbSegmentDetails = (response: any) => {
    if (response?.status === 200) {
      let tempSelected =
        response?.data?.map(
          (el: { id: number; name: string; segment: number }) => ({
            idSolutionOffert: el.segment,
            idSegment: el.id,
            nameSegment: el.name,
          })
        ) || [];
      setSolutionChecked(tempSelected);
      if (showRequestSoftwareSolution) {
        setLoader(false);
        setShowRequestSoftwareSolution(false);
      }
    } else if (response?.status === 403) {
      history.push("/profil-not-found");
    }
  };

  useEffect(() => {
    const isEmptySegmentDetails =
      companyPageObject?.id?.toString() !== "0" &&
      companyPageObject?.segment_details?.length < 1 &&
      !idOtherUser;

    if (isEmptySegmentDetails) setSoftwareSolutionAttribute();

    if (idOtherUser) setSoftwareSolutionAttribute();

    return () => {};
  }, [companyPageObject?.id]);

  const setSoftwareSolutionAttribute = () => {
    let attributes = "segmentDetails";
    let currentOemIfExists =
      userObject.roles.indexOf(OEM_PROGRAM) !== -1 ? oemProgramObject.id : "";
    let idCompany =
      currentOemIfExists !== ""
        ? params?.id + "/" + currentOemIfExists
        : params?.id || "";

    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attributes,
          idCompany,
          cbSegmentDetails
        )
      );
    } else {
      dispatch(getCompanyPageAttributeAction(attributes, cbSegmentDetails));
    }
  };

  useEffect(() => {
    isHaveError && setIsHaveError(false);
    return () => {};
  }, [solutionChecked]);

  const cancelUpdateSoftware = () => {
    setShowRequestSoftwareSolution(false);
    loaderSoftWareSolution && setLoader(false);

    setSolutionChecked(
      companyPageObject?.segment_details.map(
        (el: { id: number; name: string; segment: number }) => ({
          idSolutionOffert: el.segment,
          idSegment: el.id,
          nameSegment: el.name,
        })
      ) || []
    );
  };

  useEffect(() => {
    setSolutionChecked(
      companyPageObject?.segment_details.map(
        (el: { id: number; name: string; segment: number }) => ({
          idSolutionOffert: el.segment,
          idSegment: el.id,
          nameSegment: el.name,
        })
      ) || []
    );
  }, []);

  return {
    index1,
    setindex1,
    showRequestSoftwareSolution,
    setShowRequestSoftwareSolution,
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    allSolutionSellected,
    featuredSolution,
    isHaveError,
    selectSolution,
    removeItemSelected,
    updateSoftWareSolution,
    loaderSoftWareSolution,
    cancelUpdateSoftware,
    allOtherValue,
    setOtherValueTxt,
  };
}
