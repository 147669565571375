import React from "react";

type Iprops = {
  goToFindIsv: () => void;
  valueofSubmenu: string;
  goToIsvUnlocked: () => void;
  goFavorite: () => void;
  setShow: (p: boolean) => void;
  goToFindMore: () => void;
};
export default function Nav(props: Iprops) {
  return (
    <div className="menuDynamic bl">
      <div className="item">
        <a
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            props.goToFindIsv();
          }}
          className={props.valueofSubmenu === "match" ? "active" : ""}
        >
          Matching results
        </a>
      </div>
      <div className="item">
        <a
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            props.goToIsvUnlocked();
          }}
          className={props.valueofSubmenu === "isv-unlocked" ? "active" : ""}
        >
          ISVs unlocked
        </a>
      </div>
      <div className="item">
        <a
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            props.goFavorite();
          }}
          className={props.valueofSubmenu === "favorite-page" ? "active" : ""}
        >
          {/* Design & Integration #45506 Find ISVs matchmaking panel */}
          Favorites
        </a>
      </div>
      <div className="item">
        <a href="javascript:;" onClick={() => props.setShow(true)}>
          Blacklist
        </a>
      </div>
      <div className="item">
        <a
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            props.goToFindMore();
          }}
          className={props.valueofSubmenu === "find-more" ? "active" : ""}
        >
          Find more
        </a>
      </div>
    </div>
  );
}
