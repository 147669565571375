/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import * as T from "../../../../controller/company-ctr/types/types-company-page";
import { IPropsSF } from "./type";
export default function SolutionList(props: IPropsSF) {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  console.log(
    "props?.solutionCheckedFeatures",
    props?.featuredSolution?.segmentDetails,
    props?.solutionCheckedFeatures,
    props?.solutionChecked,
    props?.solutionLeftColumnData
  );

  return (
    <div className="getstartedContainer largeContainer">
      <div className="contentCheckboxsolution ">
        <h2 className="title">{props?.featuredSolution?.name}</h2>
        <div className="flex-checkbox ">
          {props?.featuredSolution?.segmentDetails?.map(
            (el: T.ItemsSolution, index: number) => (
              <Form.Check
                custom
                key={index}
                checked={
                  props?.solutionCheckedFeatures?.find(
                    (element: any) => element.id == el.id
                  ) ||
                  props?.solutionChecked?.some(
                    (it) =>
                      it.name?.toLocaleLowerCase() ===
                      el.name?.toLocaleLowerCase()
                  )
                    ? true
                    : false
                }
                label={el.name}
                id={el.id.toString()}
                onClick={() => props?.addSolutionChechedFeatured(el)}
                onChange={() => null}
              />
            )
          )}
        </div>
        <div className="listAccordionSolution">
          <Accordion defaultActiveKey={activeId}>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                {props?.solutionLeftColumnData?.map(
                  (solution: T.ISolutionListe, index: number) => {
                    return (
                      <div
                        className={"panel-wrap active-panel"}
                        onClick={() => props?.addSolutionCheched(solution)}
                        key={index}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id)}
                            className={`panel-toggle noicon ${
                              props?.solutionChecked?.find(
                                (el: any) =>
                                  el?.id == solution.id ||
                                  el.name === solution.name
                              ) ||
                              props?.solutionCheckedFeatures?.some(
                                (it) =>
                                  it.name?.toLocaleLowerCase() ===
                                  solution.name?.toLocaleLowerCase()
                              )
                                ? "colored"
                                : ""
                            } `}
                            eventKey={solution.id.toString()}
                          >
                            {solution && solution.name}
                          </Accordion.Toggle>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                {props?.solutionRigthColumnData?.map(
                  (solution: T.ISolutionListe, index: number) => {
                    return (
                      <div
                        className={"panel-wrap active-panel"}
                        onClick={() => props?.addSolutionCheched(solution)}
                        key={index}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id)}
                            className={`panel-toggle noicon ${
                              props?.solutionChecked?.find(
                                (el: any) =>
                                  el?.id == solution.id ||
                                  el.name === solution.name
                              ) ||
                              props.solutionCheckedFeatures?.some(
                                (it) =>
                                  it.name?.toLocaleLowerCase() ===
                                  solution.name?.toLocaleLowerCase()
                              )
                                ? "colored"
                                : ""
                            } `}
                            eventKey={solution?.id?.toString()}
                          >
                            {solution && solution.name}
                          </Accordion.Toggle>
                        </div>
                      </div>
                    );
                  }
                )}
                <div
                  className={"panel-wrap active-panel"}
                  onClick={() =>
                    props?.addSolutionCheched({
                      id: -1,
                      name: "Other",
                    })
                  }
                >
                  <div className="panel-header">
                    <Accordion.Toggle
                      onClick={() => toggleActive(-1)}
                      className={`panel-toggle noicon ${
                        props?.solutionChecked?.find((el: any) => el?.id == -1)
                          ? "colored"
                          : ""
                      } `}
                      eventKey={"-1"}
                    >
                      Other
                    </Accordion.Toggle>
                  </div>
                </div>
                {props.solutionChecked?.find((el: any) => el.id == -1) && (
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="other"
                    value={props.otherValue}
                    onChange={(evt: any) =>
                      props.setOtherValueTxt(evt.target.value)
                    }
                  />
                )}
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
