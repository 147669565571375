import React from "react";
import Modal from "react-bootstrap/Modal";
import { concatString, fileUrl } from "../../../../utils";
import { BtnNext } from "../../bouton/btn-next";

type IProps = {
  showModal: boolean;
  handleModal: () => void;
  isvMember: any;
  removeIsvMember: () => void;
  loader: boolean;
  loaderRemoveMember: boolean;
};

export default function ModalRemoveMemberIsv(props: IProps) {
  const normalizeRoleLabel = (role?: string) => {
    return role
      ? role
          .toLowerCase()
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  };

  return (
    <Modal
      show={props.showModal}
      className="createCompanypopup small forStep5custom forRemove"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Remove team member</h2>
          <a
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props.handleModal();
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder forBillingCenter">
          <div className="continue mb-5">
            <span className="icon-exclamation-circle"></span>
            <p className="mb-4">Are you sure you want to continue?</p>
            <div className="text-left">
              You are about to remove the following team member :
            </div>
          </div>
          <div className="program mb-5 justify-content-between">
            <div className="d-flex align-items-center">
              <div className="image">
                <img
                  src={fileUrl(props.isvMember?.user?.file_name)}
                  className="Image"
                />
              </div>
              <div className="desc">
                <ul>
                  <li className="titleProgram">
                    {concatString(
                      props.isvMember?.user?.first_name,
                      props.isvMember?.user?.last_name,
                      " "
                    )}
                  </li>
                  <li>{props.isvMember?.user?.job_title}</li>
                </ul>
              </div>
            </div>
            <div className="role">
              <input
                type="text"
                placeholder="Role"
                className="remInput"
                value={normalizeRoleLabel(props.isvMember?.role)}
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="form-group form-check px-0 col-12 checkContent">
              This is a permanent action and cannot be undone. <br />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={props.handleModal}
          >
            Cancel
          </button>
          <button
            onClick={() => props.removeIsvMember()}
            type="submit"
            className="btn btn-devinsider px-5 save"
          >
            <BtnNext
              text={"Remove team member"}
              showLoader={props.loaderRemoveMember}
            />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
