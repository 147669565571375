import React, { useEffect, useState } from "react";
import Header from "../../../../widget/index";
import {
  concatString,
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getUserType,
} from "../../../../../utils";
import { EditorState, convertFromRaw } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { IDiscutionCategorie } from "../../../../../redux/types/community-interface";
import { UserObject } from "../../../../../redux/types/acount-interface";

export default function PreviewPost() {
  const [isSetData, setIsSetData] = useState<boolean>(false);
  const [description, setDescription] = useState(EditorState?.createEmpty());
  const [object, setObject] = useState<string>("");
  const userObject = useSelector((state: rootState) => state.userReducer);

  const [userReducer, setUserReducer] = useState<UserObject | null>(userObject);

  const [category, setCategory] = useState<string>("");
  const [labels, setLabels] = useState<Array<any>>([]);
  const [type, setType] = useState<string>("");
  const dispatch = useDispatch();

  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const makeCategorie = (params: Array<IDiscutionCategorie>) =>
    params?.map((el: IDiscutionCategorie) => ({
      value: el?.id,
      label: el?.title,
    }));

  const categoryList = [
    ...makeCategorie(communityReducer?.discussionCategories),
    ...makeCategorie(communityReducer?.category_about_community),
  ];
  const history = useHistory();

  useEffect(() => {
    const tempState = sessionStorage.getItem("_previewPost");
    let parsedData: any = "";
    if (tempState) {
      parsedData = JSON.parse(tempState);

      if (parsedData && parsedData.content) {
        setDescription(
          EditorState?.createWithContent(convertFromRaw(parsedData.content))
        );
      }
      if (parsedData && parsedData.object) setObject(parsedData.object);
      if (parsedData && parsedData.category) {
        const categoryLabel = categoryList?.find(
          (el) => el.value === parsedData.category
        );
        if (categoryLabel) setCategory(categoryLabel.label);
      }
      const labelDiscussion = parsedData && parsedData.labels;
      const labelPr = parsedData && parsedData.txtFilter;
      const selectedLabel =
        labelDiscussion && labelDiscussion.length > 0
          ? labelDiscussion
          : labelPr?.split(",");

      setLabels(selectedLabel);

      if (parsedData && parsedData.type) setType(parsedData.type);

      setIsSetData(true);
    }
  }, []);

  // const cbGetUserInfo = (response: any) => {
  //   if (response && response.status === 200) {
  //     setUserReducer(response?.data);
  //   }
  // };

  // const getUserInfo = () => {
  //   dispatch(getInfoUserAction(cbGetUserInfo));
  // };

  // useEffect(() => {
  //   getUserInfo();
  // }, []);

  return (
    <div>
      <Header notFound />
      <section className="discussion">
        <div className="post-preview">
          <button onClick={() => history.goBack()}>Exit Preview</button>
        </div>
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li onClick={() => null}>
              <a href="javascript:;">Community</a>
            </li>
            <li onClick={() => null}>
              <a href="javascript:;">Discussion categories</a>
            </li>
            <li onClick={() => null}>
              <a href="javascript:;">{type}</a>
            </li>
            <li>{object}</li>
          </ul>
          <div className="searchTopic">
            <div>
              <form action="">
                <select name="Categories" disabled>
                  <option value="">Search categories</option>
                </select>
                <div className="inputAndbtn">
                  <button type="button" disabled>
                    <span className="icon-search"></span>
                  </button>
                  <input type="text" disabled />
                </div>
              </form>
            </div>
          </div>
          <div>
            <div className="topicMaster reperefixed">
              <div className="badgeSubject">
                <div className="badgePoster">
                  <div className="withPost">
                    <div className="imageAffiliatedcompany empty">
                      {userReducer?.file_name && (
                        <img src={fileUrl(userReducer?.file_name)} alt="" />
                      )}
                    </div>
                    <div
                      className={`nameOfPost ${getUserType(
                        userReducer?.roles?.[0]
                      )}`}
                    />
                  </div>
                  <div className="infoAffiliated">
                    <h3 className="title">
                      {concatString(
                        userReducer?.first_name,
                        userReducer?.last_name,
                        " "
                      )}
                    </h3>
                    <div className="post">{userReducer?.job_title}</div>
                    <b className="post">{userReducer?.company_name}</b>
                  </div>
                </div>
              </div>
              <div className="topicSubject">
                <div className="title">
                  <h2>{object}</h2>
                  <div className="date">
                    {moment(new Date()).format("MMM D, YYYY h:mm a")}
                  </div>
                </div>

                <div className="topic format-wysiwyg">
                  {isSetData && (
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        convertCommentFromJSONToHTML(description)
                      )}
                    />
                  )}
                </div>

                <div className="categContainer">
                  {category && <div className="category">{category}</div>}
                  <div className="subCategory">
                    {labels?.map((el: any, index: number) => (
                      <span key={index} style={{ cursor: "context-menu" }}>
                        {el}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="likeAndBtn">
                  <div className="like">
                    <a className={"active"} onClick={(e) => e.preventDefault()}>
                      <span className={"icon-like"} />
                      like
                    </a>
                    <b>{0}</b> Likes
                  </div>

                  <div className="btnContentSubject">
                    <a className="addFav">Add to favorites</a>

                    <a>Reply</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
