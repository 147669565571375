import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";

import { conversationType } from "../../../../utils";
import {
  getDataService,
  deleteMethode,
  postJsonDataService,
} from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import store from "../../../../redux/store";
import { ItemMa } from "../../../../types/Ma";

export default function UseDetails() {
  const params: { id?: string | undefined } = useParams();
  const history = useHistory();
  const [limitSolution, setLimitSolution] = useState(6);
  const [limitIndustry, setLimitIndustry] = useState(6);
  const [loadingGetItem, setLoadingGetItem] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const [announcing, setAnnouncing] = useState<ItemMa | null>();

  const [showLogin, setShowLogin] = useState<boolean>(false);

  const userReducer = useSelector((state: rootState) => state.userReducer);

  const isMyPost = announcing?.author?.id === userReducer?.id;

  const urlPath = window.location.href;

  const getMaItem = async () => {
    setLoadingGetItem(true);
    try {
      const response = await getDataService(
        url.Ma.getAnnounce + "/" + parseInt(params.id || "0"),
        {},
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        setAnnouncing(response.data);
      }
      setLoadingGetItem(false);
    } catch (error) {}
    setLoadingGetItem(false);
  };

  const deleteItem = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await deleteMethode(
        url.Ma.getAnnounce + "/" + parseInt(params.id || "0"),
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 204) {
        history.push("/ma/listings");
      }
      setIsLoadingDelete(false);
    } catch (error) {}
    setIsLoadingDelete(false);
  };

  const handleAddFavorite = async (data: any) => {
    try {
      const response = await postJsonDataService(
        url.Ma.addFavorites,
        data,
        store?.getState()?.userReducer?.token
      );
      if (response.status !== 204) {
        const tempFavorite: any = {
          ...announcing,
          liked: !announcing?.liked,
        };
        setAnnouncing(tempFavorite);
      }
    } catch (error) {
      const tempFavorite: any = {
        ...announcing,
        liked: !announcing?.liked,
      };
      setAnnouncing(tempFavorite);
    }
  };

  useEffect(() => {
    getMaItem();
  }, []);

  const handleSeeSolution = (action: string) => {
    const dataS = [
      ...(announcing?.softwareSolution || []),
      ...(announcing?.featuredSolution || []),
    ];
    setLimitSolution(action === "0" ? dataS.length || 0 : 6);
  };

  const handleSeeIndustry = (action: string) => {
    setLimitIndustry(
      action === "0" ? announcing?.targetedIndustry?.length || 0 : 6
    );
  };

  const handleAddFavorites = () => {
    if (userReducer?.token === "") {
      return setShowLogin(true);
    }
    const tempFavorite: any = {
      ...announcing,
      liked: !announcing?.liked,
    };

    setAnnouncing(tempFavorite);

    handleAddFavorite({
      mergersAndAcquisitionsAd: parseInt(params.id || "0"),
      status: announcing?.liked ? 0 : 1,
    });
  };

  const handleDeletePost = async () => {
    deleteItem();
  };

  const editPost = () => {
    history.push("/ma/edit/" + parseInt(params.id || "0"));
  };

  const showDiscussion = () => {
    history.push("/contact/discussion");
  };

  const isDisabled = (haveP: boolean | undefined): boolean => {
    let isDisabled = true;
    if (isMyPost) {
      isDisabled = haveP ? false : true;
    } else {
      isDisabled = false;
    }
    return isDisabled;
  };

  const sendMessage = (haveP: boolean | undefined) => {
    if (isDisabled(haveP)) {
      return;
    }
    if (userReducer?.token === "") {
      return setShowLogin(true);
    }
    if (isMyPost) {
      showDiscussion();
      return;
    }
    history.push("/contact/discussion", {
      userId: announcing?.id,
      discussionType: conversationType.MA,
    });
  };
  return {
    showLoading: loadingGetItem || isLoadingDelete,
    announcing,
    userReducer,
    handleSeeSolution,
    handleSeeIndustry,
    limitSolution,
    limitIndustry,
    handleAddFavorites,
    handleDeletePost,
    editPost,
    sendMessage,
    urlPath,
    showLogin,
    setShowLogin,
    isDisabled,
    isMyPost,
  };
}
