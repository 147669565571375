/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent, useState } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import CheckIcon from "@material-ui/icons/Check";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/

import "./style-complete-info.css";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import useCompleteInfoCtr from "../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-info-ctr";
import useEventListener from "../../../../../hooks/event-handler";
import { isEmail } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function Signup(props: any) {
  const {
    navigateToCompleteAdresse,
    txtEmailError,
    loader,
    emailNotSafe,
    checkIfAlreadyEmailExist,
    onSubmit,
    register,
    handleSubmit,
    errors,
    refForm,
  } = useCompleteInfoCtr();

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToCompleteAdresse();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LffKosaAAAAAMvKjOZmVdV60Lj_3b8TW_wAviLG"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
      }}
    >
      <div>
        <Header />
        <form
          className="getstartedContainer"
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
          }}
        >
          <div className="head-getstarted">
            <h1>Create your account.</h1>
          </div>
          <div className="contentBorder border-top row pb-4">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="first-name required">
                First name
                {errors?.firstNameIsv && (
                  <div className={`container-error pl-2  d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className={`input-text form-control ${
                  errors?.firstNameIsv ? "colored-border" : ""
                }`}
                {...register("firstNameIsv", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="last-name">
                Last name
                {errors?.lastNameIsv && (
                  <div className={`container-error pl-2  d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className={`input-text form-control ${
                  errors?.lastNameIsv ? "colored-border" : ""
                }`}
                {...register("lastNameIsv", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pr-xl-2 pr-md-2 p-0">
              <label
                htmlFor="first-name"
                className={`labelForEmailgetStarted `}
              >
                Business email{" "}
                {emailNotSafe === "true" && (
                  <div className="container-error d-inline pl-3">
                    <span className="error-red"> error </span>
                    <span className="txt-error">{txtEmailError}</span>
                  </div>
                )}
                {emailNotSafe?.toString() === "false" && (
                  <CheckIcon className="mailcheckOK" />
                )}
                {errors?.emailIsv && (
                  <div className={`container-error pl-2 d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>

              <input
                type="text"
                className={`input-text form-control ${
                  errors?.emailIsv ? "colored-border" : ""
                }`}
                {...register("emailIsv", {
                  required: true,
                  validate: (email: string) => isEmail(email),
                })}
                onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                  checkIfAlreadyEmailExist(e.target.value);
                }}
                disabled={
                  sessionStorage.getItem("token_affiliated") ? true : false
                }
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="first-name">
                Password
                <span
                  className={`container-error pl-1 
                  d-inline
                  `}
                >
                  (12 or more characters )
                </span>
                {errors?.passWord?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required </span>
                  </div>
                )}
                {errors?.passWord?.type === "minLength" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password length must be 12 or more characters
                    </span>
                  </div>
                )}
                {errors?.passWord?.type == "pattern" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password must contain at least one uppercase, lowercase,
                      special character and one number
                    </span>
                  </div>
                )}
              </label>
              <input
                type="password"
                className={`input-text form-control ${
                  errors?.passWord ? "colored-border" : ""
                }`}
                {...register("passWord", {
                  required: true,
                  minLength: 12,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/,
                })}
              />
            </div>
          </div>
          <div className="contentBorder border-top row py-3 align-items-stretch">
            <div className="form-group form-check col-xl-6 col-md-6 col-sm-12">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("acceptPrivacyTerms", {
                  required: true,
                })}
              />
              <label htmlFor="" className="form-check-label termsLink">
                I agree to Devinsider’s&nbsp;
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/terms-and-conditions", "_blank");
                  }}
                >
                  terms{" "}
                </a>
                and &nbsp;
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/privacy-policy", "_blank");
                  }}
                >
                  privacy policy
                </a>
                .
              </label>

              {errors?.acceptPrivacyTerms && (
                <div className={`container-error pl-2 d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                  <GoogleReCaptcha onVerify={(event: any) => {}} />
                </div>
              )}
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 d-flex align-items-center pr-0">
              <button
                className="btn btn-devinsider large containerBtnBtn"
                disabled={loader}
                type="submit"
                ref={refForm}
              >
                {loader ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </GoogleReCaptchaProvider>
  );
}
