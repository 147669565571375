/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Select from "react-select";
import UseCompleteInvestorTypeof from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-investor-typeof";
import useGoBack from "../../../../../hooks/use-navigate";
import { isUrlLinkDean } from "../../../../../utils";

export default function CompleteInvestorTypeof() {
  const {
    navigateToCountryInvestor,
    investorType,
    setInvestorType,
    webSiteInvestor,
    setWebSiteInvestor,
    urlLinkDeanInvestor,
    setUrlLinkDeanInvestor,
    locationInvestor,
    setLocationInvestor,
    cityInvestor,
    setCityInvestor,
    strategicPartenerInvestor,
    setStrategicPartenerInvestor,
    errorInvestorType,
    errorWebSiteInvestor,
    errorUrlLinkDeanInvestor,
    setErroroUrlLinkDeanInvestor,
    errorLocationInvestor,
    errorCityInvestor,

    isInstitutional,
    dataCompleted,
  } = UseCompleteInvestorTypeof();
  const { goBack } = useGoBack();

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
            className="link-back"
          >
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row py-4">
          <div
            className={`form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 ${
              !isInstitutional ? "d-none" : ""
            }`}
          >
            <label htmlFor="jobtitle required">
              Investor type
              <div
                className={`container-error pl-2 ${
                  errorInvestorType ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <select
              name="typeinvestor"
              id=""
              className="custom-select"
              placeholder="Select an option..."
              onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                setInvestorType(event.target.value);
              }}
            >
              <option value="">Select an option...</option>
              {dataCompleted?.institutionalInvestorType?.map((el: any) => (
                <option
                  value={el.id}
                  selected={el.id == investorType}
                  key={el.id}
                >
                  {el.name}
                </option>
              ))}
            </select>
          </div>
          <div
            className={` form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2 ${
              !isInstitutional ? "d-none" : ""
            } ${
              investorType ==
              dataCompleted?.institutionalInvestorType?.find(
                (el: any) => el.name == "Venture Capital"
              )?.id
                ? "readOnlyArea"
                : ""
            } ${!isInstitutional ? "readOnlyArea" : ""}`}
          ></div>
          <div
            className={` ${
              !isInstitutional ? "" : "required"
            } form-group  col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0`}
          >
            <label htmlFor="adressemail">
              Website
              <div
                className={`container-error pl-2 ${
                  errorWebSiteInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <input
              type="text"
              className="input-text form-control"
              placeholder="Begin with http:// or https:/ or www"
              value={webSiteInvestor}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setWebSiteInvestor(event.target.value)
              }
            />
          </div>
          <div
            className={
              "form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2"
            }
          >
            <label htmlFor="organisation">
              Linkedin
              <div
                className={`container-error pl-2 ${
                  errorUrlLinkDeanInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This url is not linkedin url</span>
              </div>
            </label>
            <div className={"linkedintext"}>
              linkedin.com/in/
              <input
                type="tel"
                className="input-text form-control"
                value={urlLinkDeanInvestor}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUrlLinkDeanInvestor(event.target.value)
                }
                onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                  const { status, value } = isUrlLinkDean(urlLinkDeanInvestor);
                  if (status) {
                    setUrlLinkDeanInvestor(value);
                    setErroroUrlLinkDeanInvestor(false);
                  } else {
                    setErroroUrlLinkDeanInvestor(true);
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="adressemail required">
              Location
              <div
                className={`container-error pl-2 ${
                  errorLocationInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <select
              name="typeinvestor"
              id=""
              className="custom-select"
              placeholder="Select an option..."
              onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                setLocationInvestor(event.target.value);
              }}
            >
              <option value="">Select a Country...</option>
              {dataCompleted?.country?.map((elem: any) => (
                <option value={elem.id} selected={elem.id == locationInvestor}>
                  {elem.country_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
            <label htmlFor="adressemail required">&nbsp;</label>
            <div
              className={`container-error pl-2 ${
                errorCityInvestor ? "d-inline" : "d-none"
              }`}
            >
              <span className="error-red"> error </span>
              <span className="txt-error">This field is required</span>
            </div>
            <input
              type="text"
              className="input-text form-control"
              placeholder="City..."
              value={cityInvestor}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setCityInvestor(event.target.value)
              }
            />
          </div>
          <div className="form-group col-12 p-0 partnershipoption">
            <label htmlFor="organisation ">
              Are you open to facilitate one or multiple of the following
              strategic partnerships?
            </label>
            <Select
              options={dataCompleted?.investorStrategicPartnership?.map(
                (el: any) => {
                  return {
                    id: el.id,
                    label: el.name,
                    value: el.name,
                  };
                }
              )}
              placeholder="Select all that apply"
              className="react-select-container"
              classNamePrefix="react-select"
              id="selectable"
              isMulti={true}
              value={strategicPartenerInvestor}
              onChange={(data) => {
                setStrategicPartenerInvestor(data);
              }}
            />
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToCountryInvestor()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
