import React from "react";
import Modal from "react-bootstrap/Modal";
import { DATA_TOPICS, OEM_PROGRAM } from "../../../service/constant/index";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import { TPropsContactSupport } from "./type";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";

export default function Index(props: TPropsContactSupport) {
  const user = useSelector((state: rootState) => state.userReducer);
  const isOem = user?.roles?.indexOf(OEM_PROGRAM) !== -1;
  let topics = [...DATA_TOPICS];
  if (!isOem) {
    topics.forEach((item, index) => {
      if (item.value === "My plan") topics.splice(index, 1);
    });
  }

  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);
  const isBillingOwner =
    oemProgram?.program?.billing_owner_email === user?.email;
  const oemValue = topics.find((e) => e.value === "Billing");

  return (
    <Modal
      show={props?.isShowContactSupport}
      className="createCompanypopup large forStep5custom"
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Contact support</h2>
            <a
              className="close"
              onClick={() => props?.setIsShowContactSupport(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder forBillingCenter contact">
            <div className="contact row">
              <div className="name required col-6">
                <label htmlFor="">
                  First name
                  {props.errors?.firstName && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  defaultValue={props.userObject?.first_name}
                  className="form-control"
                  {...props?.register("firstName", {
                    required: props?.required,
                  })}
                ></input>
              </div>
              <div className="name required col-6">
                <label htmlFor="">
                  Last name
                  {props.errors?.lastName && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={props.userObject?.last_name}
                  {...props?.register("lastName", {
                    required: props?.required,
                  })}
                />
              </div>
              <div className="help required col-6">
                <label htmlFor="">
                  I'm looking for help with
                  {props.errors?.topics && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  id="help"
                  className="custom-select mr-3"
                  {...props?.register("topics", {
                    required: props?.required,
                  })}
                  defaultValue={
                    isOem && !isBillingOwner ? oemValue?.value : undefined
                  }
                >
                  {topics?.map(
                    (el: { id: number; label: string; value: string }) => (
                      <option key={el.id} value={el?.value}>
                        {el?.label}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <br />
            <div className=" subject row">
              <div className="help required col-12">
                <label htmlFor="">
                  Tell us more about how we can help
                  {props.errors?.subject && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <div className="form-group col-12 descCompany p-0">
                  <textarea
                    {...props?.register("subject", {
                      required: props?.required,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail row">
            <div className="affiliatedPopup contentBorder formForEmail text-left col-lg-8 d-block">
              <p className="removeAttachement">
                Attachment (optional){" "}
                {props?.fileName?.[0] ? props?.fileName?.[0].name : ""}
                {props?.fileName?.[0] && (
                  <span>
                    <button
                      type="button"
                      onClick={() => props.removeFileUploaded()}
                    >
                      <span className="icon-close" />
                      <span className="txt">Remove Attachment</span>
                    </button>
                  </span>
                )}
              </p>
              <label className="btn btn-devinsider px-5 m-0">
                Choose file
                <input
                  type="file"
                  id="getFile"
                  accept="image/png, image/jpeg ,application/pdf"
                  style={{ display: "none !important" }}
                  {...props?.register("fileAttachement")}
                />
              </label>
            </div>
            <div className="contentBorder formForEmail text-right col-lg-4 d-block">
              <p>&nbsp;</p>
              <button type="submit" className="btn btn-devinsider px-5 save">
                {props?.loader ? <Loader /> : "Send"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
