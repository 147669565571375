/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "./style-reset-password.css";
import useResetPassword from "../../../../controller/account-ctr/reset-password/use-reset-password";
import { BtnNext } from "../../../widget/bouton/btn-next";
import Alert from "react-bootstrap/Alert";
import LogoRound from "../../../../resources/images/logo-round.svg";
export default function ResetPassword() {
  const {
    tokenId,
    password,
    loader,
    errorResetPassword,
    setpassword,
    resetPasswordUser,
  } = useResetPassword();
  return (
    <div className="loginScreen">
      <div className="loginContent">
        <img src={LogoRound} alt="" />
        <h1 className="title">Welcome back</h1>
        <form action="" className="loginForm">
          {errorResetPassword && (
            <Alert variant="danger">ERROR password length must be 12 or more characters</Alert>
          )}
          <div className="form-group">
            {/* Bug #45171 Reset password after email */}
            <input
              type="password"
              name=""
              id=""
              value={password}
              placeholder="Password"
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setpassword(event.target.value)
              }
            />
            <div className="mt-3 btn btn-devinsider large containerBtnBtn">
              <BtnNext
                text="Reset"
                showLoader={loader}
                click={() => resetPasswordUser()}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
