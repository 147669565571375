import React, { useState, useEffect, useRef } from 'react';
import { convertToRaw } from 'draft-js';
import { ItemsDataSource } from '../../../screen/container/investor/profil-investor/types';
import { rootState } from '../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateDrafExpertiseAction } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import UseWysiwygList from '../../../screen/widget/wysiwyg-list/use-wysiwyg-list';
import { useParams } from 'react-router-dom';

export const UPDATE_OR_PUBLISH_DRAFT__DATA = "UPDATE_OR_PUBLISH_DRAFT__DATA";

export default function UseExpertiseCtr() {
  const [loader, setLoader] = useState<boolean>(false);
  const [inialData, setInitialData] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  const [isEditClick, setIsEditClick] = useState<boolean>(false);

  const {
    setEditorState,
    initialeDataSource,
    choseDisposition,
    addNewElement,
    removeElement,
    setFileExpertise,
    removeFile,
    isHaveError,
    idItemRemove,
    setIdItemRemove,
  } = UseWysiwygList({ initialData: inialData });

  const tempData = CP_Investor?.investorPageExpertises?.map((el: any) => {
    return {
      ...el,
      file_location: el?.investorPageExpertiseImages?.[0]?.file_location,
    };
  });
  useEffect(() => {
    setInitialData(tempData);
    return () => {};
  }, [CP_Investor?.investorPageExpertises, params?.id]);

  const clickCancel = () => {
    setLoader(false);
    setIsEditClick(false);
    setInitialData(tempData);
  };

  const cbUpdateDraft = (data: any) => {
    setLoader(false);

    setIsEditClick(!isEditClick);
    dispatch({
      type: UPDATE_OR_PUBLISH_DRAFT__DATA,
      payload: {
        isupdated: true
      },
    });
  };

  const saveAllDataForm = () => {
    setLoader(true);
    let allFile = initialeDataSource?.map(
      (el: ItemsDataSource) => el?.dataFile
    );
    /**
     *get data change
     */
    let stringChange = initialeDataSource?.map((el: any, index: number) => {
      if (el?.isChange === true) {
        return {
          id: el?.id || '',
          urlImage: el?.dataFile !== '' ? index : '',
          html: JSON.stringify(
            convertToRaw(el?.editorState.getCurrentContent())
          ),
          images: [],
          idForm: el?.itemIndex,
          draft: true,
          className: el?.disposition,
        };
      }
    });
    //remove undefined data array
    let dataStringToSend = stringChange?.filter((el: any) => el != undefined);
    let formatRemoveForm = idItemRemove?.map((el: any) => {
      return {
        id: el,
        urlImage: '',
        html: '',
        images: [],
        idForm: 0,
        draft: true,
        className: '',
      };
    });
    let dataToSend = JSON.stringify([...dataStringToSend, ...formatRemoveForm]);
    dispatch(
      updateDrafExpertiseAction(
        { expertise: dataToSend, ...allFile },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  return {
    isEditClick,
    setIsEditClick,
    saveAllDataForm,
    setEditorState,
    clickCancel,
    initialeDataSource,
    choseDisposition,
    addNewElement,
    removeElement,
    setFileExpertise,
    removeFile,
    isHaveError,
    loader,
    otherExperTise: isOtherUser ? CP_Investor.investorPageExpertises : [],
  };
}
