import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDiscutionCategorie,
  getTopTags,
  restoreDiscussions,
  restoreTopLabel,
} from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";
import { useHistory } from "react-router";
import { ItemDiscussion } from "../../../redux/types/community-interface";
import UseSearch from "../../../screen/widget/community/search/use-search";
import {
  getDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { useParams } from "react-router";
import { IPropsContributor } from "../home-page/top-liked-contributions";
import { IpropsAuthor } from "../home-page/top-liked-author";

export default function UsePressReleaseCtr() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  const idTags =
    params?.tags_id && params?.tags_id !== "0" ? params?.tags_id : null;

  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );

  const userReducer = useSelector((state: rootState) => state?.userReducer);

  const [loaderGetTags, setLoaderGetTags] = useState<boolean>(false);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limitTopLabel, setLimitTopLabel] = useState<number>(10);

  const [allNotSelectedLabel, setAllNotSelectedLabel] = useState<Array<number>>(
    []
  );
  const [allSelectedLabel, setAllSelectedLabel] = useState<Array<number>>([]);

  const [topLikedPressRelease, setTopLikedPressRelease] = useState<
    Array<IPropsContributor>
  >([]);
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<IpropsAuthor>>([]);

  /**
   *
   * state for all discutions
   */
  const [loaderPosts, setLoaderPosts] = useState<boolean>(false);
  const [limitDiscution, setlimitDiscution] = useState<number>(10);
  const [view, setView] = useState<string>("");
  const [postType, setpostType] = useState(3);
  const [currentDiscutionPage, setCurrentDiscutionPage] = useState<number>(1);
  const [sortedBy, setsortedBy] = useState<string>("post_date");
  const [orderBy, setOrderBy] = useState("DESC");
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  const [companyPageIdSelected, setCompanyPageIdSelected] = useState<number>(0);

  const [isShowPopUpRelease, setIsShowPopUpRelease] = useState<boolean>(false);
  const [indexShowPopUp, setIndexShowPopUp] = useState<number>(0);
  const [indexShowPopUpLastActivity, setIndexShowPopUpLastActivity] =
    useState<number>(0);

  const [allPressReleaseByUser, setAllPressReleaseByUser] = useState<{
    prListe: Array<{
      id: number;
      title: string;
      duration: string;
    }>;
    total: number;
  }>({ total: 0, prListe: [] });

  const [currentPagePrGet, setCurrentPagePrGet] = useState<number>(1);

  /**
   * @returns data splice data liste in all data
   * @param array
   * @param page_size
   * @param page_number
   */
  const paginate = (
    array: Array<any>,
    page_size: number = 10,
    page_number: number = 1
  ) => {
    if (array?.length <= page_size) {
      return array;
    } else {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    }
  };

  const cbChangeCategorie = (data: any) => {
    setLoaderPosts(false);
  };

  useEffect(() => {
    async function getData() {
      //Get top liked Press release
      const result = await getDataService(
        url.community.get_top_liked_contribution + "/" + -1 + "/" + 3,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedPressRelease(result?.data);
      }
    }
    async function getTopLikeAuthor() {
      //Get top liked Authors
      const result = await getDataService(
        url.community.get_top_liked_author + "/" + -1 + "/" + 3,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedAuthor(result?.data);
      }
    }
    getData();
    getTopLikeAuthor();
  }, []);

  useEffect(() => {
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let topLabelStore = communityReducer?.topTags;
    if (topLabelStore?.currentPage < currentPage) {
      setLoaderGetTags(true);
      dispatch(getTopTags({ postType: 3, numberPage: currentPage }, cb));
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreTopLabel(() => {}));
    };
  }, []);

  const cb = (data: any) => {
    setLoaderGetTags(false);
    setLoaderPosts(false);
  };

  const cbNext = () => {
    setLoaderGetTags(false);
    setcurrentPage(currentPage + 1);
  };

  const nextPage = (nextPage: number) => {
    //Chack if allData in store is inferieur of nextPage * limit
    let checkLoadNextData = communityReducer?.topTags?.currentPage < nextPage;

    //if we must getData  , then we get a new topLabel
    if (checkLoadNextData) {
      setLoaderGetTags(true);
      dispatch(getTopTags({ postType: 3, numberPage: nextPage }, cbNext));
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  const clikPrev = (index: number) => {
    setcurrentPage(currentPage - 1);
  };

  useEffect(() => {
    /**
     * Get Press release data
     */

    const tagsId =
      params?.tags_id && params?.tags_id?.toString() !== "0"
        ? params?.tags_id
        : null;

    setAllSelectedLabel(tagsId ? [tagsId] : []);

    setLoaderPosts(true);
    let tempParams = {
      views: view,
      tags: tagsId ? tagsId : "",
      postTypes: postType,
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      notIdlabels: allNotSelectedLabel?.join(),
      orderBy: "DESC",
    };
    dispatch(getDiscutionCategorie(tempParams, cb));

    return () => {
      //remove top label when leave page
      dispatch(restoreDiscussions(() => {}));
    };
  }, [params?.tags_id]);

  const getNewDiscutionAfterUpdate = (
    key: string,
    value?: any,
    isRestorePaginate: boolean = false
  ) => {
    setLoaderPosts(true);
    let checkIfResetCurrentPage =
      currentDiscutionPage === 1 ? currentDiscutionPage : 1;

    if (isRestorePaginate) {
      setCurrentDiscutionPage(1);
    }

    let data = {
      views: view,
      tags: allSelectedLabel?.join(),
      postTypes: postType,
      limit: limitDiscution,
      page: checkIfResetCurrentPage,
      sortBy: sortedBy,
      notIdlabels: allNotSelectedLabel?.join(),
      orderBy: "DESC",
      [key]: value,
    };

    dispatch(getDiscutionCategorie(data, cbChangeCategorie));
  };

  const setLabelsFilter = (labelId: any) => {
    const isSelectedFirst = idTags;
    let tempLabel = isSelectedFirst
      ? allSelectedLabel?.find((el: number) => el == labelId)
      : allNotSelectedLabel?.find((el: number) => el == labelId);

    if (tempLabel) {
      isSelectedFirst
        ? setAllSelectedLabel(
            allSelectedLabel?.filter((el: number) => el != labelId)
          )
        : setAllNotSelectedLabel(
            allNotSelectedLabel?.filter((el: number) => el != labelId)
          );
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "tags" : "notIdlabels",
            isSelectedFirst
              ? allSelectedLabel?.filter((el: number) => el != labelId)?.join()
              : allNotSelectedLabel
                  ?.filter((el: number) => el != labelId)
                  ?.join(),
            true
          )
        )
      );
    } else {
      isSelectedFirst
        ? setAllSelectedLabel([...allSelectedLabel, parseInt(labelId)])
        : setAllNotSelectedLabel([...allNotSelectedLabel, parseInt(labelId)]);

      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "tags" : "notIdlabels",
            isSelectedFirst
              ? [...allSelectedLabel, parseInt(labelId)]?.join()
              : [...allNotSelectedLabel, parseInt(labelId)]?.join(),
            true
          )
        )
      );
    }
  };

  /**
   * getDiscussions after filter change
   */
  const handleFilter = (key: string, value?: any, isReset: boolean = false) => {
    setLoaderPosts(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate(key, value, isReset))
    );
  };

  const handlePageClick = (data: any) => {
    setLoaderPosts(true);
    setCurrentDiscutionPage(data);
    handleFilter("page", data, false);
  };

  const handleSorted = (params: any) => {
    setLoaderPosts(true);
    setsortedBy(params);
    handleFilter("sortBy", params, true);
  };

  const handleLimit = (params: number) => {
    setLoaderPosts(true);
    setlimitDiscution(params);
    handleFilter("limit", params, true);
  };

  /**
   * reset filter
   */
  const getDefaultArticle = () => {
    let data = {
      categories: "",
      views: "",
      labels: "",
      postTypes: 3,
      limit: 10,
      page: 1,
      sortBy: "post_date",
      orderBy: orderBy,
      notIdlabels: "",
      tags: "",
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const resetFilter = () => {
    setLoaderPosts(true);
    setAllSelectedLabel([]);
    setAllNotSelectedLabel([]);
    setView("");
    setCurrentDiscutionPage(1);
    setsortedBy("post_date");
    setlimitDiscution(10);
    dispatch(restoreDiscussions(() => getDefaultArticle()));
  };

  const goToreplayPost = (currentPost: ItemDiscussion) => {
    const categoryId = "0";
    history.push("/community/reply-post/" + categoryId + "/" + currentPost?.id);
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const getUserPressRelease = async (companyId: number, page: number) => {
    const params = {
      company_id: companyId,
      page: page,
    };
    const prUserResponse = await postJsonDataService(
      url.community.get_pr_by_user,
      params,
      userReducer.token
    );
    if (prUserResponse?.status === 200) {
      const pr = prUserResponse?.data?.list_press_release?.press_release_list;
      const tatalPr = prUserResponse?.data?.list_press_release?.total;
      setAllPressReleaseByUser({
        prListe: [...allPressReleaseByUser.prListe, ...pr],
        total: tatalPr,
      });
      if (page !== currentPagePrGet) {
        setCurrentPagePrGet(page);
      }
    }
  };

  const hoverUserPr = (companyId: any, index: number) => {
    setCompanyPageIdSelected(companyId);
    setIsShowPopUpRelease(true);
    setIndexShowPopUp(index);
  };

  const mouseLeave = () => {
    setCompanyPageIdSelected(0);
    setIsShowPopUpRelease(false);
    setIndexShowPopUp(0);
  };

  const hoverUserPrActivity = (companyId: any, index: number) => {
    setCompanyPageIdSelected(companyId);
    setIsShowPopUpRelease(true);
    setIndexShowPopUpLastActivity(index);
  };

  const mouseLeaveActivity = () => {
    setCompanyPageIdSelected(0);
    setIsShowPopUpRelease(false);
    setIndexShowPopUpLastActivity(0);
  };

  useEffect(() => {
    if (companyPageIdSelected && isShowPopUpRelease) {
      setAllPressReleaseByUser({
        prListe: [],
        total: 0,
      });
      setCurrentPagePrGet(1);
      getUserPressRelease(companyPageIdSelected, 1);
    }
  }, [companyPageIdSelected]);

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
      postType: "3",
      //categorie: idCategorie,
    });
  };

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
      postType: "3",
      //categorie: idLabel,
    });
  };

  const nextPagePr = (nextPage: number) => {
    //Check if allData in store is inferieur of nextPage * limit
    let checkLoadNextData = currentPagePrGet < nextPage;
    //if we must getData  , then we get a new topLabel
    if (checkLoadNextData) {
      getUserPressRelease(companyPageIdSelected, nextPage);
    } else {
      setCurrentPagePrGet(currentPagePrGet + 1);
    }
  };

  const clikPrevPr = (index: number) => {
    setCurrentPagePrGet(currentPagePrGet - 1);
  };

  const goToTagsCategory = (tagId: string) => {
    scrollToTop();
    if (tagId?.toString() !== idTags?.toString()) {
      dispatch(restoreDiscussions(() => {}));
      history.replace("/community/press-releases/" + tagId);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return {
    communityReducer,
    currentPage,
    nextPage,
    limitTopLabel,
    totalLabel: communityReducer?.topTags?.total,
    paginate,
    clikPrev,
    limitDiscution,
    sortedBy,
    setlimitDiscution,
    setsortedBy,
    setLabelsFilter,
    allSelectedLabel,
    loaderGetTags,
    loaderPosts,
    nbrePageDiscussion: Math.ceil(
      communityReducer?.paginate_press_release?.total / limitDiscution
    ),
    handlePageClick,
    handleSorted,
    handleLimit,
    resetFilter,
    currentDiscutionPage,
    goToreplayPost,
    goActivity,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    getUserPressRelease,
    isShowPopUpRelease,
    companyPageIdSelected,
    hoverUserPr,
    allPressReleaseByUser,
    currentPagePrGet,
    clikPrevPr,
    nextPagePr,
    allNotSelectedLabel,
    useSelected: idTags,
    goToTagsCategory,
    topLikedPressRelease,
    topLikedAuthor,
    viewAllContributor,
    viewAllAuthor,
    indexShowPopUp,
    mouseLeave,
    hoverUserPrActivity,
    mouseLeaveActivity,
    indexShowPopUpLastActivity,
  };
}
