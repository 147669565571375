import React, { useState, useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OEM_PROGRAM, url } from "../../service/constant";
import { rootState } from "../../redux/reducers";
import {
  patchDataService,
  postJsonDataService,
} from "../../service/applicatif/back-end-service";
import { IsvFindMore } from "../../interface/isv-type";
import { IAction } from "../../redux/types/acount-interface";
import useEventListener from "../../hooks/event-handler";

const GET_JOIN_LIST = "GET_JOIN_LIST";

type IPropsInitialState = {
  isv: Array<IsvFindMore>;
  currentPage: number;
  totalDataPaginate: number;
};
const initialState: IPropsInitialState = {
  isv: [],
  totalDataPaginate: 0,
  currentPage: 0,
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_JOIN_LIST:
      return {
        ...state,
        isv: action.payload?.isvListe,
        currentPage: action?.payload?.currentPage,
        totalDataPaginate: action.payload?.totalDataPaginate,
      };
    default:
      return state;
  }
};

export default function UseFindMore() {
  const [allJoinSoon, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);
  const userState = useSelector((state: rootState) => state.userReducer);
  const [loaderJoin, setloaderJoin] = useState<boolean>(false);
  const [page, setpage] = useState<number>(1);
  const [limit, setlimit] = useState<number>(24);

  const [txtFilter, settxtFilter] = useState<string>("");
  const getJoinSoon = async (p: number, dispatch: any) => {
    setloaderJoin(true);
    const params = {
      page
    };

    let allResponse = await postJsonDataService(
      url?.findIsv?.get_joining_soon,
      params,
      userState?.token
    );

    if (allResponse?.status === 200) {
      const result = {
        isvListe: allResponse?.data?.results,
        totalDataPaginate: allResponse?.data?.total_to_paginate,
        currentPage: params?.page,
      };
      dispatch({ type: GET_JOIN_LIST, payload: result });
    }
    setloaderJoin(false);
  };

  useEffect(() => {
    (async () => {
      await getJoinSoon(page, dispatch);
    })();

    return () => {};
  }, []);

  const hanldeNextPage = async (page: number) => {
    await getJoinSoon(page, dispatch);
  };

  const filterJoinSoon = () => {};

  const keyEnter = async ({ key }: any) => {
    if (key == "Enter") {
      filterJoinSoon();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    allJoinSoon,
    hanldeNextPage,
    loaderJoin,
    limit,
    txtFilter,
    settxtFilter,
    filterJoinSoon,
  };
}
