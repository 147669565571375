import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { useDataContext } from "../../../context/index";
export default function UseCompleteProfileReseller() {
  const history = useHistory();
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { setFilePictureReseller, filePictureReseller, name } =
    useDataContext();
  const [selectedFile, setSelectedFile] = useState<File>(filePictureReseller);
  const [isFilePicked, setIsFilePicked] = useState(
    filePictureReseller ? true : false
  );
  const firstNameReseller = sessionStorage.getItem("firstNameReseller");
  const lastNameReseller = sessionStorage.getItem("lastNameReseller");
  const companyReseller = sessionStorage.getItem("companyReseller");
  const jobReseller = sessionStorage.getItem("jobReseller");
  const [fileSizeError, setFileSizeError] = useState(false);

  const navigateToCompleteCompany = (skip: boolean = false) => {
    
      if (!skip) {
        setFilePictureReseller(selectedFile);
      }
      history.push("/complete-company-reseller");
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        if(event?.target?.files[0].size > 2 * 1024 * 1024) {
          setFileSizeError(true);
        } else {
          setFileSizeError(false);
          setSelectedFile(event?.target?.files[0]);
          setIsFilePicked(true);
        }
      }
    }
  };

  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
      //setIsFilePicked(false);
    }
    setFileSizeError(false);
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    return () => {};
  }, [selectedFile]);

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToCompleteCompany();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateToCompleteCompany,
    goBack,
    handleBtn,
    changeHandler,
    fileRef,
    selectedFile,
    isFilePicked,
    filePictureReseller,
    firstNameReseller,
    lastNameReseller,
    companyReseller,
    jobReseller,
    fileSizeError,
    setFileSizeError
  };
}
