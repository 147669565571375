import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDataContext } from "../../../context";
import { rootState } from "../../../redux/reducers";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { OEM_PROGRAM, url } from "../../../service/constant";

type IUnread = {
  unread: number;
  refresh?: boolean;
  setRefresh?: (value: boolean) => void;
};

export const BlockMessage = (props: IUnread) => {
  const { unread } = props;

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [notifMessage, setNotifMessage] = useState<any>(unread);
  const isOem = userReducer.roles?.[0] === OEM_PROGRAM;
  const currentOemPage = useSelector(
    (state: rootState) => state?.oemProgramReducer
  );
  const { newNotifMessage } = useDataContext();

  const getUnreadCategory = async () => {
    let response = await postJsonDataService(
      url.tchat.unread_in_category,
      isOem ? { oem: currentOemPage?.program?.id } : {},
      userReducer.token
    );
    if (response?.status === 200) {
      const { COMMUNITY, FIND_ISV, CORPORATE_DEVELOPMENT, MARKETPLACE } =
        response && response.data;
      const totalUnread =
        (COMMUNITY || 0) +
        (MARKETPLACE || 0) +
        (CORPORATE_DEVELOPMENT || 0) +
        (FIND_ISV || 0);
      setNotifMessage(totalUnread);
    }
  };

  useEffect(() => {
    if (newNotifMessage) {
      setNotifMessage(newNotifMessage);
    }
    return () => {};
  }, [newNotifMessage]);

  useEffect(() => {
    setNotifMessage(unread);
  }, [unread]);

  useEffect(() => {
    if (props?.refresh != undefined && props?.refresh == true) {
      getUnreadCategory()
        .then((response) => {
          if (props?.setRefresh) props?.setRefresh(false);
        })
        .catch((error) => {});
    }
  }, [props?.refresh]);

  useEffect(() => {
    getUnreadCategory();
  }, []);

  return (
    <a
      href="javascript:;"
      className="linkHeader"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <span className="icon-message"></span>
      <span className="mobile">Message</span>
      {notifMessage != -1 && notifMessage > 0 && (
        <span className="non-lu">{notifMessage}</span>
      )}
      {/* {notifMessage > 0 && <span className='non-lu'>{5}</span>} */}
    </a>
  );
};
