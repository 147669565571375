/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from "react";
import * as T from "./type";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling-white.svg";
import { fileUrl, uppercaseFirstLetter } from "../../../../utils";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import ModalUpdateProgramOwner from "./modal-update-program-owner";
import ModalRemoveOemMember from "./modal-remove-oem-member";
import ModalPermission from "./modal-permission-remove-billing-owner";
export default function ManageRoles(props: T.TaffilliatedInvestor) {
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const invitation = oemProgram?.membersInvitations;

  const pendingInvitation = invitation?.filter(
    (el: T.TitemSentInvitation) => el.status === "PENDING"
  )?.length;

  const affilliated = props.affiliatedList?.length || 0;

  const [permissionBillingRemove, setPermissionBillingRemove] =
    useState<boolean>(false);

  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row border-bottom affiliatedPopup">
        {props.showMessage && (
          <Alert variant={props.typeMessage ? "success" : "danger"}>
            {props?.txtMessage}
          </Alert>
        )}
        <div className="col-12">
          <label>
            Invite new insiders to join your program page{" "}
            {props.errors && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">
                  Email and program page is required
                </span>
              </div>
            )}
          </label>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 form-group pr-3">
          <input
            type="text"
            className="form-control"
            value={props.emailInvite}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              props.setEmailInvite(evt.target.value)
            }
            placeholder="email address"
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 form-group d-flex align-items-center">
          <select
            name=""
            id="size"
            className="custom-select mr-3 select_role" 
            onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
              props.setTypeAffiliated(evt.target.value);
            }}
          >
            {props?.affiliatedType?.map(
              (
                el: { id: number; label: string; value: string },
                index: number
              ) => (
                <option
                  value={el?.value}
                  selected={el?.value == props.typeAffiliated}
                  key={index}
                >
                  {el?.label}
                </option>
              )
            )}
          </select>
          <div className="role_details">
            <OverlayTrigger
              overlay={
                <Tooltip id="roledetails" className="test">
                  <strong>Program owner</strong><br/>
                  <span>There can only be one program owner.<br/>
                  This role allows you to manage:</span><br/>
                  <ul className="mb-2">
                    <li>Program targering</li>
                    <li>Program page content</li>
                    <li>Affiliated members</li>
                    <li>Unlocking and contacting of ISVs</li>
                    <li>Ads and events</li>
                    <li>Billing and monthly limits</li>
                    <li>Deletion of the program page</li>
                  </ul>

                  <strong>Admin</strong><br/>
                  <span>This role allows you to manage:</span><br/>
                  <ul className="mb-2">
                    <li>Program targering</li>
                    <li>Program page content</li>
                    <li>Affiliated members (exept program owner)</li>
                    <li>Unlocking and contacting of ISVs</li>
                    <li>Ads and events</li>
                    <li>Billing and monthly limits</li>
                  </ul>

                  <strong>Editor</strong><br/>
                  <span>This role allows you to manage:</span><br/>
                  <ul className="mb-2">
                    <li>Program page content</li>
                  </ul>

                  <strong>Outreach representative</strong><br/>
                  <span>This role allows you to manage:</span><br/>
                  <ul>
                    <li>Unlocking and contacting of ISVs</li>
                  </ul>
                </Tooltip>
              }
            >
              <span className="icon-aide"></span>
            </OverlayTrigger>
          </div>
          {props?.typeAffiliated === "MEMBER" && (
            <OverlayTrigger
              overlay={
                <Tooltip id="trade">
                  This role “Member” does not grant access to the edit mode of
                  the company page.
                </Tooltip>
              }
            >
              <span className="icon-aide" />
            </OverlayTrigger>
          )}
        </div>

        <div className="col-xl-4 col-lg-4 col-md-12 col-12 text-right">
          <button
            className="btn btn-devinsider px-5"
            onClick={() => {
              props.sendInvitation();
            }}
            disabled={props.loaderAffiliated || !props?.emailInvite ? true : false}
          >
            {props.loaderAffiliated ? <Loader /> : "Send"}
          </button>
        </div>
      </div>
      <div className="invitationSent py-3">
        <h3 className="title">
          {pendingInvitation} Invitation{pendingInvitation > 0 && "s"} sent
        </h3>
        <div className="contentBorder borderAll affiliatedPopup p-0">
          {invitation
            ?.filter((el: T.TitemSentInvitation) => el.status === "PENDING")
            ?.map((el: T.TitemSentInvitation, index: number) => (
              <div className="row" key={index}>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="profilImg empty"></div>
                  <div className="infoAffiliated">{el?.email}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  {uppercaseFirstLetter(el.role)}
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-end p-3 "
                  onClick={() => props.removeInvitation(el?.id)}
                >
                  <a className="cancelInvitation linkAffiliated">
                    {props?.idRemove == el?.id.toString() ? "" : "Cancel"}
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="invitationSent py-3">
        <h3 className="title">
          {affilliated} Member
          {affilliated > 0 && "s"}
        </h3>
        <div className="contentBorder borderAll affiliatedPopup p-0">
          {props?.affiliatedList?.map(
            (el: T.TitemSentAaffiliated, index: number) => (
              <div className="row" key={index}>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="profilImg empty">
                    <img src={fileUrl(el.user.file_name)} alt="" />
                  </div>
                  <div className="infoAffiliated">
                    <span className="noEvent">{el?.user?.first_name}</span>
                    {el?.user?.job_title}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="form-group m-0 p-0">
                    {el?.role == "PROGRAM_OWNER" && (
                      <div className="form-group m-0 p-0">
                        <span className="noEvent">Program owner</span>
                      </div>
                    )}
                    {el?.role !== "PROGRAM_OWNER" &&
                      el?.user?.id.toString() != props.currentId && (
                        <select
                          name={el?.role}
                          id=""
                          className="custom-select"
                          onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                            props.setRoleAffiliated({
                              role: evt?.target?.value,
                              idUser: el?.user?.id?.toString(),
                            });
                          }}
                        >
                          {props?.currentRoleUser === "PROGRAM_OWNER" && (
                            <option value={"PROGRAM_OWNER"} selected={false}>
                              Program owner
                            </option>
                          )}
                          {props?.affiliatedType?.map(
                            (element: {
                              id: number;
                              label: string;
                              value: string;
                            }) => (
                              <option
                                value={element?.value}
                                selected={element?.value == el?.role}
                              >
                                {element?.label}
                              </option>
                            )
                          )}
                        </select>
                      )}
                    <div>
                      {el?.is_billing_owner && (
                        <label htmlFor="">Billing owner</label>
                      )}
                    </div>
                  </div>

                  {el?.user?.id.toString() != props.currentId &&
                    el?.role !== "PROGRAM_OWNER" && (
                      <div className="form-group m-0 p-0">
                        <select
                          name=""
                          id=""
                          className="custom-select"
                          onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                            props.setMembreState({
                              state: evt?.target?.value,
                              idUser: el?.user?.id?.toString(),
                            });
                          }}
                        >
                          {props?.eventAction?.map(
                            (element: {
                              id: number;
                              label: string;
                              value: string;
                            }) => (
                              <option
                                value={element?.value}
                                selected={element?.value == el?.status}
                              >
                                {element?.label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )}
                </div>

                {el?.user?.id.toString() != props.currentId &&
                  el?.role !== "PROGRAM_OWNER" && (
                    <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-end p-3 ">
                      <a
                        onClick={() => {
                          if (el.is_billing_owner) {
                            setPermissionBillingRemove(true);
                            return;
                          }
                          props.removeRole(el);
                        }}
                        className="linkAffiliated cancelInvitation "
                      >
                        Remove
                      </a>
                    </div>
                  )}
              </div>
            )
          )}
        </div>
      </div>
      <ModalUpdateProgramOwner
        showModal={props.showModal}
        handleModal={props.handleModal}
        oemProgram={oemProgram}
        changeProgramOwner={props.changeProgramOwner}
        setChangeProgramOwner={props.setChangeProgramOwner}
        handleProgramOwnerRegistration={props.handleProgramOwnerRegistration}
        loader={props.loader}
      />

      <ModalRemoveOemMember
        showModal={props.showModalRemove}
        handleModal={props.handleModalRemove}
        oemMember={props.oemMemberSelected}
        loader={props.loaderRemoveMember}
        removeOemMember={props.removeOemMember}
        setValideRemoveMember={props.setValideRemoveMember}
        valideRemoveMember={props.valideRemoveMember}
      />

      <ModalPermission
        handleShow={(p: boolean) => setPermissionBillingRemove(p)}
        show={permissionBillingRemove}
      />
    </div>
  );
}
