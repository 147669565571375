import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getData, getDataWithoutToken } from "../../../../service/api";
import { operationBillingAmount, url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const investorPageReducer = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );
  const [amountUnlockIsv, setAmountUnlockIsv] = useState<any>({
    current_price: "",
    instead_of: "",
  });
  const [modalUnlock, setModalUnlock] = useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();

  const closeUnlockModal = () => {
    setStateUnlock({ number_left: 0, number_max: 0 });
    setModalUnlock(false);
    setShowPaymentModal(false);
    setChoiseValue(undefined);
  };

  const [idIsv, setIdIsv] = useState<number | undefined>();

  const showModalUnlock = (id: number) => {
    setModalUnlock(true);
    setIdIsv(id);
  };

  const [stateUnlock, setStateUnlock] = useState<{
    number_left: number;
    number_max: number;
  }>({
    number_left: 0,
    number_max: 0,
  });

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    (async () => {
      let response = await getData(
        url.settings.billingCenter +
          "/" +
          operationBillingAmount.INVESTOR_UNLOCK_ISV +
          "/" +
          investorPageReducer.id,
        userReducer.token
      );
      if (response) {
        setAmountUnlockIsv(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { investor_unlock_isv }: any = response.data.free_status;
        setStateUnlock(investor_unlock_isv);
        setChargingPlanDetails(false);
        const isHaveFree = investor_unlock_isv?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue("FREE");
        }
      }
    }
    if (showPaymentModal) {
      getPlanDetails();
    }
  }, [currentMonth, showPaymentModal]);

  useEffect(() => {
    setStateUnlock({
      number_left: 0,
      number_max: 0,
    });
  }, [showPaymentModal]);

  const goToPaymentModal = (isvId: number) => {
    //const lookupKey = OEM_UNLOCK_ISV + '_' + oemProgram?.oem_subscription?.name?.toUpperCase();
    //const lookupKey = 'OEM' + '_' + oemProgram?.oem_subscription?.name?.toUpperCase() + '_' + UNLOCK_ISV;
    setIdIsv(isvId);

    const lookupKey = "INVESTOR_UNLOCK_ISV";

    setProduct({
      lookupKey: lookupKey,
      name: "ISV unlock",
    });

    setShowPaymentModal(true);
  };

  return {
    modalUnlock,
    closeUnlockModal,
    setModalUnlock,
    chargingPlanDetails,
    stateUnlock,
    handleChoise,
    choiseValue,
    showModalUnlock,
    idIsv,
    amountUnlockIsv,
    showPaymentModal,
    setShowPaymentModal,
    product,
    goToPaymentModal,
  };
}
