/* eslint-disable jsx-a11y/anchor-is-valid */
import { refaCtoNullKey } from "../../../../../../../utils";
import {
  IPropsAbout,
  COMPANY_OVERVIEW,
  KEY_COMPETITIVE_DIFFERENCIATORS,
} from "../type";

export default function blockAbout(props: IPropsAbout) {
  return (
    <div className="col-lg-8 col-12 contactInfo mt-4">
      <div className="boxEditProfil">
        <a
          className="editItem"
          onClick={(e) => {
            e.preventDefault();
            props?.type === COMPANY_OVERVIEW
              ? props?.dataSource?.setOverviewShow(true)
              : props?.type === KEY_COMPETITIVE_DIFFERENCIATORS
              ? props?.dataSource?.setDifShow(true)
              : props?.dataSource?.setLgShow(false);
          }}
        >
          Edit
          <span className="icon-edit-full"></span>
        </a>
        <h2 className="title">
          {props?.type === COMPANY_OVERVIEW
            ? "Company overview"
            : props?.type === KEY_COMPETITIVE_DIFFERENCIATORS
            ? "Key competitive differenciators"
            : ""}
        </h2>
        <p>
          {props?.type === COMPANY_OVERVIEW
            ? refaCtoNullKey(
                props?.dataSource?.companyResellerState?.overview,
                ""
              )
            : props?.type === KEY_COMPETITIVE_DIFFERENCIATORS
            ? refaCtoNullKey(
                props?.dataSource?.companyResellerState?.key_competitive,
                ""
              )
            : ""}
        </p>
      </div>
    </div>
  );
}
