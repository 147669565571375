import React from 'react';
import { NavLink } from 'react-router-dom';
type IProps = {
  seeMoreOrLess: (p: string) => void;
  limit: number;
  dataSource: Array<{ id: number; name?: string; country_name?: string }>;
};
export default function SeeBtn(props: IProps) {
  const seeLess =
    props?.dataSource?.length > 6 && props.dataSource.length === props.limit;

  const title = seeLess
    ? 'See less'
    : props.dataSource.length - props.limit > 0
    ? `See more ${props.dataSource.length - props.limit}`
    : '';

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    props.seeMoreOrLess(seeLess ? '1' : '0');
  };
  
  return (
    <a
      href="#"
      className='seemore'
      onClick={handleClick}
    >
      {title}
    </a>
  );
}
