import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../redux/reducers';
import { url } from '../../service/constant';
import { getData } from '../../service/api';
import { getFavoriteAlternatives } from '../../redux/actions/alternative-financing-action';
import { addToArray } from '../../utils';

export default function FavoriteAlternativeController() {
    const dispatch = useDispatch();

    const [showLoading, setShowLoading] = useState(false);

    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);
    const [limitCountry, setLimitCountry] = useState(6);
    const [limitFunding, setLimitFunding] = useState(6);

    const [solutions, setSolutions] = useState<Array<any>>([]);
    const [featuredSolutions, setFeaturedSolutions] = useState<Array<any>>([]);
    const [industries, setIndustries] = useState<Array<any>>([]);
    const [fundingTarget, setFundingTarget] = useState<Array<any>>([]);
    const [hqLocation, setHqLocation] = useState<Array<any>>([]);

    const [selectedSolution, setSelectedSolution] = useState<Array<number>>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<Array<number>>([]);
    const [selectedCountry, setSelectedCountry] = useState<Array<number>>([]);
    const [selectedFunding, setSelectedFunding] = useState<Array<number>>([]);

    const [searchText, setSearchText] = useState('');

    const alternativeReducer = useSelector(
        (state: rootState) => state?.alternativeReducer?.favorite_alternative
    );
    const userReducer = useSelector((state: rootState) => state?.userReducer);

    async function getFiltered() {
        setShowLoading(true);
        let urlType = url?.alternative?.filterAlternative+"/favorite";

        let response = await getData(urlType,userReducer?.token);
        if(response?.status === 200) {
            setSolutions(response?.data?.software_solution);
            setFeaturedSolutions(response?.data?.featured_solution);
            setIndustries(response?.data?.targeted_industry);
            setFundingTarget(response?.data?.funding_target);
            setHqLocation(response?.data?.hq_location);
            setShowLoading(false);
        }
    }

    const callback = (data: any) => {
        setShowLoading(false);
    };

    const getAlternatives = () => {
        setShowLoading(true);
        let params = {
            software_solution: [],
            featured_solution: [],
            targeted_industry: [],
            country: [],
            funding: [],
            itemsPerPage: 10,
            page: 1,
            search: undefined,
            favorite: 1,
        }
        dispatch(getFavoriteAlternatives(params, callback));
    }

    useEffect(() => {
        getFiltered();
        getAlternatives();
        return () => {};
    }, []);

    const dataSolution = [
        ...(solutions || []),
        ...(featuredSolutions || []),
    ];

    const handleSeeSolution = (action: string) => {
        setLimitSolution(action === '0' ? dataSolution.length : 6);
    };

    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(
          action === '0' ? industries?.length || 0 : 6
        );
    };

    const handleSeeCountry = (action: string) => {
        setLimitCountry(action === '0' ? hqLocation?.length || 0 : 6);
    };

    const handleSeeFunding = (action: string) => {
        setLimitFunding(action === '0' ? fundingTarget?.length || 0 : 6);
    };

    const handleSelectSolution = (element : number) => {
        setSelectedSolution(addToArray(selectedSolution, element));
    }

    const handleSelectIndustry = (element : number) => {
        setSelectedIndustry(addToArray(selectedIndustry, element));
    }

    const handleSelectFunding = (element : number) => {
        setSelectedFunding(addToArray(selectedFunding, element));
    }

    const handleSelectCountry = (element : number) => {
        setSelectedCountry(addToArray(selectedCountry, element));
    }

    const filterAlternative = () => {
        setShowLoading(true);
        const idFeatured = featuredSolutions?.map((el) => el.id) || [];
        const idSoftware = solutions?.map((el) => el.id) || [];

        const makeSoftware = selectedSolution.filter((el) => {
            if(idSoftware.includes(el)) {
                return el;
            }
        });

        const makeFeatured = selectedSolution.filter((el) => {
            if(idFeatured.includes(el)) {
                return el;
            }
        });

        let params = {
            software_solution: "[" + makeSoftware.join().trim() + "]",
            featured_solution: "[" + makeFeatured.join().trim() + "]",
            targeted_industry: "[" + selectedIndustry.join().trim() + "]",
            country: "[" + selectedCountry.join().trim() + "]",
            funding: "[" + selectedFunding.join().trim() + "]",
            itemsPerPage: 10,
            page: 1,
            search: searchText,
            favorite: 1,
        }
        dispatch(getFavoriteAlternatives(params, callback));
    }

    const resetFilter = () => {
        setShowLoading(true);
        setSelectedCountry([]);
        setSelectedSolution([]);
        setSelectedFunding([]);
        setSelectedIndustry([]);

        let params = {
            software_solution: [],
            featured_solution: [],
            targeted_industry: [],
            country: [],
            funding: [],
            itemsPerPage: 10,
            page: 1,
            search: undefined,
            favorite: 1,
        }
        dispatch(getFavoriteAlternatives(params, callback));
    }

    const handlePageClick = (data: any) => {
        setShowLoading(true);
        const idFeatured = featuredSolutions?.map((el) => el.id) || [];
        const idSoftware = solutions?.map((el) => el.id) || [];

        const makeSoftware = selectedSolution.filter((el) => {
            if(idSoftware.includes(el)) {
                return el;
            }
        });

        const makeFeatured = selectedSolution.filter((el) => {
            if(idFeatured.includes(el)) {
                return el;
            }
        });

        let params = {
            software_solution: "[" + makeSoftware.join().trim() + "]",
            featured_solution: "[" + makeFeatured.join().trim() + "]",
            targeted_industry: "[" + selectedIndustry.join().trim() + "]",
            country: "[" + selectedCountry.join().trim() + "]",
            funding: "[" + selectedFunding.join().trim() + "]",
            itemsPerPage: 10,
            page: data,
            search: searchText,
            favorite: 1,
        }

        dispatch(getFavoriteAlternatives(params, callback));
    }

    const search = () => {
        setShowLoading(true);
        const idFeatured = featuredSolutions?.map((el) => el.id) || [];
        const idSoftware = solutions?.map((el) => el.id) || [];

        const makeSoftware = selectedSolution.filter((el) => {
            if(idSoftware.includes(el)) {
                return el;
            }
        });

        const makeFeatured = selectedSolution.filter((el) => {
            if(idFeatured.includes(el)) {
                return el;
            }
        });

        let params = {
            software_solution: "[" + makeSoftware.join().trim() + "]",
            featured_solution: "[" + makeFeatured.join().trim() + "]",
            targeted_industry: "[" + selectedIndustry.join().trim() + "]",
            country: "[" + selectedCountry.join().trim() + "]",
            funding: "[" + selectedFunding.join().trim() + "]",
            itemsPerPage: 10,
            page: 1,
            search: searchText,
            favorite: 1,
        }

        dispatch(getFavoriteAlternatives(params, callback));
    };
    return {
        dataSolution,
        limitSolution,
        handleSeeSolution,
        industries,
        limitIndustry,
        handleSeeIndustry,
        fundingTarget,
        limitFunding,
        handleSeeFunding,
        alternativeReducer,
        selectedCountry,
        selectedFunding,
        selectedIndustry,
        selectedSolution,
        handleSelectCountry,
        handleSelectFunding,
        handleSelectIndustry,
        handleSelectSolution,
        filterAlternative,
        resetFilter,
        handlePageClick,
        searchText,
        search,
        setSearchText,
        showLoading,
        handleSeeCountry,
        limitCountry,
        hqLocation,
    }
}