import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { checkIfEmailExist } from "../../../service/applicatif/acount";
import { isEmail, setSessionStorageObject } from "../../../utils/index";
import { useForm } from "react-hook-form";
import useEventListener from "../../../hooks/event-handler";

export type IPropsInputGuestUser = {
  firstNameExpert: string;
  lastNameExpert: string;
  businessEmailExpert: string;
  passwordExpert: string;
  acceptTermsExpert: boolean;
};

export default function UseCompleteInfoExpert() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<IPropsInputGuestUser>({
    defaultValues: {
      acceptTermsExpert: JSON.parse(
        sessionStorage?.getItem("acceptPrivacyTerms") || "false"
      ),
      businessEmailExpert: sessionStorage?.getItem("businessEmailExpert") || "",
      firstNameExpert: sessionStorage?.getItem("firstNameExpert") || "",
      lastNameExpert: sessionStorage?.getItem("lastNameExpert") || "",
      passwordExpert: sessionStorage?.getItem("passwordExpert") || "",
    },
  });

  const [loader, setloader] = useState<boolean>(false);
  const [emailNotSafe, setEmailNotSafe] = useState<string>("");
  const [txtEmailError, setTxtEmailError] = useState<string>("");
  const tempEmail = watch("businessEmailExpert");


  const checkIfAlreadyEmailExist = async (email: string) => {
    if (isEmail(email)) {
      setloader(true);
      try {
        let response = await checkIfEmailExist(email, true);
        if (response?.status === 200 && response?.data?.details) {
          if (
            response?.status === 200 &&
            response?.data?.reason === "invalid domain"
          ) {
            setTxtEmailError("Professional email is required");
          } else {
            setTxtEmailError("This email address is already  used");
          }
          setloader(false);
          setEmailNotSafe("true");
          return;
        } else if (response?.status === 200) {
          setEmailNotSafe("false");
          setloader(false);
        } else {
          setloader(false);
          setEmailNotSafe("true");
          setTxtEmailError("An error occurred");
        }
      } catch (error: any) {
      }
    }
  };

  useEffect(() => {
    setTxtEmailError("");
    setEmailNotSafe("");
    return () => {};
  }, [tempEmail]);

  const onSubmit = async (data: IPropsInputGuestUser) => {
    setloader(true);
    /**
     * Check if email not safe is empty (business email is not checking)
     * Check if email not is true
     */
    if (emailNotSafe === "" || emailNotSafe === "true") {
      let checkEmailBusiness = await checkIfAlreadyEmailExist(
        data?.businessEmailExpert
      );
    }
    /**
     * email not safe == false , is business email
     */
    if (emailNotSafe == "false") {
      setSessionStorageObject({
        firstNameExpert: data?.firstNameExpert,
        lastNameExpert: data?.lastNameExpert,
        businessEmailExpert: data?.businessEmailExpert,
        passwordExpert: data?.passwordExpert,
        acceptTermsExpert: data?.acceptTermsExpert,
      });
      history.push("/complete-company-expert");
    }
    setloader(false);
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    emailNotSafe,
    loader,
    checkIfAlreadyEmailExist,
    onSubmit,
    txtEmailError,
  };
}
