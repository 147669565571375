import React from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import Industry from "../../../widget/WidgetSelected/IndustryListe/Industry";
import { IPropsIL } from "../../../widget/WidgetSelected/IndustryListe/types";

type IProps = IPropsIL & {
  show: boolean;
  cancel: () => void;
  valid: () => void;
  loader: boolean;
};
export default function ModalIndustryList(props: IProps) {
  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Select all industries that apply.</h2>
          <NavLink
            to="#"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.cancel();
            }}
          >
            <span className="icon-close-pop"></span>
          </NavLink>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <Industry
          addTargetCompany={props.addTargetCompany}
          companyTarget={props.companyTarget}
          companyTargetSecound={props.companyTargetSecound}
          companyTargetSelected={props.companyTargetSelected}
          lastCompanyTarget={props.lastCompanyTarget}
          otherValue={props.otherValue}
          setOtherValueTxt={props.setOtherValueTxt}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.cancel()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5 save"
            onClick={() => props?.valid()}
            disabled={props?.loader}
          >
            {/* {props?.loaderTargetIndustrie ? <Loader /> : 'Save'} */}
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
