/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import { conversationType } from "../../../../../utils";
import warning from "../../../../../resources/images/warning.svg";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import { OemProgram } from "../../../../../controller/oem-ctr/type";

export type IPropsOemControle = {
  switchEditMode: () => void;
  showPreview: () => void;
  setShowPageSettings: () => void;
  setShowManagePage: () => void;
  editMode: boolean;
  loader: boolean;
  txtBtn: string;
  publishOrUpdate: () => void;
  isOtherUser: boolean;
  matching_value: number | string;
  goBackMatchingPanel: () => void;
  addFavorite: () => void;
  isFavorite: boolean;
  contactOem: () => void;
  isPreview: boolean;
  isOnEditWysiwyg: boolean;
  programIdToContact?: string;
  processing: boolean;
  setShowBtnUnpublish: (p: boolean) => void;
  setShowPopupUnpublish: (p: boolean) => void;
  setGearClick: (p: number) => void;
  gearClick: number;
  showBtnUnpublish: boolean;
  showPopupUnpublish: boolean;
  handleUnpublish: () => void;
};
export default function OemManaging(props: IPropsOemControle) {
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const closeAndShowPopup = () => {
    props.setShowBtnUnpublish(false);
    props.setShowPopupUnpublish(true);
  };

  const gearUnpublish = () => {
    props.setShowBtnUnpublish(true);
    props.setGearClick(props.gearClick + 1);
  };

  const cancelPopUp = () => {
    props.setShowPopupUnpublish(false);
    props.setGearClick(props.gearClick + 1);
  };

  const renderStatus = () => {
    const isPublished =
      oemProgram?.status === true &&
      oemProgram?.validate_by_devinsider === true;

    console.log("renderStatus", isPublished);
    if (props?.editMode) return <b>Draft</b>;
    else {
      if (isPublished) return <b>Published</b>;
      return <b>Draft (unpublished)</b>;
    }
  };

  const previewOtherUser = props?.isOtherUser && !props?.isPreview;

  const showManaging = !props?.isOtherUser && !props?.isPreview;
  const matchingValue = props.matching_value as number;

  const isAllowUnpublish =
    oemProgram?.validate_by_devinsider === true &&
    oemProgram?.status === true &&
    props?.editMode === false &&
    oemProgram?.uuid !== "";

  return (
    <>
      {previewOtherUser && (
        <div className="cont oem">
          <div className={`matchValue ${matchingValue < 60 ? "m60" : "m90"}`}>
            {matchingValue < 60 && (
              <>
                <span className="icon-nextbtn" /> 60 % Match
              </>
            )}
            {matchingValue > 60 && matchingValue + "% Match"}
          </div>

          <div className="contact">
            {matchingValue > 60 && (
              <Link
                to={{
                  pathname: "/contact/discussion",
                  state: {
                    userId: props?.programIdToContact,
                    discussionType: conversationType.isv_to_oem,
                  },
                }}
                className="btn btn-devinsider px-5 save"
              >
                Contact
              </Link>
            )}
          </div>

          <span className="separator justForProfil"></span>
          <button
            className="btnSetting justForProfil"
            onClick={() => props?.addFavorite()}
          >
            <span
              className={
                props?.isFavorite ? "icon-star-on star" : "icon-star-off star"
              }
            />{" "}
            Favorites
          </button>
          <button
            className="btnSetting justForProfil"
            onClick={() => props?.goBackMatchingPanel()}
          >
            Back to the Results panel
          </button>
        </div>
      )}

      {props?.isPreview && !props?.isOtherUser && (
        <div className="cont oem">
          <div className={`matchValue m90`}>92 % Match</div>

          <div className="contact">
            <Link
              onClick={(e) => {
                e.preventDefault();
              }}
              to="#"
              className="btn btn-devinsider px-5 save"
            >
              Contact
            </Link>
          </div>

          <span className="separator justForProfil"></span>
          <button className="btnSetting justForProfil">
            <span
              className={
                props?.isFavorite ? "icon-star-on star" : "icon-star-off star"
              }
            />{" "}
            Favorites
          </button>
          <button className="btnSetting justForProfil">
            Back to the Results panel
          </button>
        </div>
      )}
      {showManaging && (
        <>
          <div className="editShow ">
            {!props.isOnEditWysiwyg && (
              <button
                className="btnforEdit doneEdit"
                onClick={props?.switchEditMode}
              >
                Done editing
              </button>
            )}

            <button className="btnforEdit preview" onClick={props?.showPreview}>
              Preview
            </button>
          </div>
          {/* Dev #46417 V2.1 OEM - Désactivation membre d'un program */}
          {oemProgram?.uuid === "" ? (
            <></>
          ) : (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={() => props?.setShowPageSettings()}
              >
                Program targeting
              </button>
            </>
          )}
          {/* Dev #46417 V2.1 OEM - Désactivation membre d'un program */}
          {oemProgram?.uuid === "" ? (
            <></>
          ) : (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={() => props?.setShowManagePage()}
              >
                Manage program
              </button>
            </>
          )}
          <span className="separator justForProfil" />
          {oemProgram?.uuid === "" ? (
            <></>
          ) : (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={props?.switchEditMode}
              >
                {oemProgram?.uuid === "" ? "" : "Edit page"}
              </button>
            </>
          )}
          <div className="infoPublication position-relative">
            <div className="infoItem">
              <span className="icon-mini-key"></span>
              <label>Status: </label>
              {renderStatus()}
              {isAllowUnpublish && (
                <span
                  className="icon-settings ml-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => gearUnpublish()}
                />
              )}
              {props.processing && <Loader />}
            </div>
            <div className="infoItem">
              <span className="icon-calendar"></span>
              <label>Last update: </label>
              {props.showBtnUnpublish && props.gearClick % 2 === 0 && (
                <div
                  className="btnUnpublish"
                  onClick={() => closeAndShowPopup()}
                >
                  Unpublish
                </div>
              )}
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <b>
                      {oemProgram?.updated_at &&
                        moment(new Date(oemProgram?.updated_at)).format(
                          "MMM D, YYYY h:mm A"
                        )}
                    </b>
                  </Tooltip>
                }
                defaultShow={false}
              >
                <b>
                  {oemProgram?.updated_at &&
                    moment(new Date(oemProgram?.updated_at)).format(
                      "MMM D, YYYY [at] h:mm A"
                    )}{" "}
                </b>
              </OverlayTrigger>
            </div>
          </div>

          <div className="cttbtnUpdate justForProfil">
            {
              //Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
              oemProgram?.uuid === "" ? (
                "Program deactivated"
              ) : oemProgram?.status === true &&
                oemProgram?.isupdated === true ? (
                <>
                  <button
                    onClick={() => props?.publishOrUpdate()}
                    disabled={props?.loader}
                  >
                    {props?.loader ? <Loader /> : props?.txtBtn}{" "}
                  </button>
                </>
              ) : oemProgram?.status === false ? (
                <>
                  <button
                    onClick={() => props?.publishOrUpdate()}
                    disabled={props?.loader}
                  >
                    {props?.loader || props.processing ? (
                      <Loader />
                    ) : (
                      props?.txtBtn
                    )}{" "}
                  </button>
                </>
              ) : (
                <></>
              )
            }
          </div>
          {!props?.editMode &&
            oemProgram?.validate_by_devinsider &&
            oemProgram?.status &&
            oemProgram?.isupdated && (
              <div className="visibility" style={{ color: "red" }}>
                You have modified the content on your page. Click on "Update" to
                publish this modification towards ISVs in the matchmaking
                results.
              </div>
            )}
        </>
      )}

      {/* MODAL CONFIRMATION FOR UNPUBLISHING PROGRAM */}
      <Modal
        show={props.showPopupUnpublish}
        className="createCompanypopup large forStep5custom forUnpublish"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Unpublish</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                cancelPopUp();
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder">
            <img src={warning} alt="" />
            <p className="mb-5 mt-5 text-center">
              Are you sure you want to unpublish your page?{" "}
            </p>
            <p>
              When you unpublish your page, the status will be changed to draft
              and your page will be removed from the matchmaking results. <br />
              You can always re-publish your page at a later point.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => cancelPopUp()}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => props.handleUnpublish()}
            >
              <BtnNext text="Confirm" showLoader={props.processing} />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
