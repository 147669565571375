import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import {
  getSStorage,
  isHaveCompanyLogo,
  isRequired,
  validateCompleteInfo,
} from "../../../utils";
import TwoCase from "../../../screen/container/programs/program-matchmaking/complete-info-process";

export default function UseProgramCompleteCompanyPage() {
  const [dataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const companyPageObject = useSelector(
    (state: rootState) => state?.companyPageReducer
  );
  const [progress, setProgress] = useState<number>();

  useEffect(() => {
    let progression = 0;

    let isNotCompletedInfoCompany = !validateCompleteInfo(companyPageObject);
    let isNotCompletedSummary = !isRequired(companyPageObject?.company_summary);
    let isNotCompletedLogo = !isHaveCompanyLogo(companyPageObject.photo_profil);

    let isNotCompletedTargetedAndTypeOffice = !isRequired(
      companyPageObject?.targeted_country
    );
    let isNotCompletedPartenerAndProgramInterested = !isRequired(
      companyPageObject?.oem_program_segment_interest
    );
    let isNotCompletedAnnualTurnOver = !isRequired(
      companyPageObject?.annual_turnover
    );
    let isNotCompletedTargetedIndustry = !isRequired(
      companyPageObject?.targeted_industry
    );
    let isNotCompletedDistributionAndLicencing =
      !isRequired(companyPageObject?.licensing_model) ||
      !isRequired(companyPageObject?.distribution_channel);

    let isNotCompletedWebSite = !isRequired(
      companyPageObject?.website
    );

    // if (isNotCompletedInfoCompany) progression += 1;
    if (isNotCompletedSummary) progression += 1;
    if (isNotCompletedPartenerAndProgramInterested) progression += 1;
    if (isNotCompletedTargetedAndTypeOffice) progression += 1;
    if (isNotCompletedAnnualTurnOver) progression += 1;
    if (isNotCompletedLogo) progression += 1;
    if (isNotCompletedDistributionAndLicencing) progression += 1;
    if (isNotCompletedTargetedIndustry) progression += 1;
    if (isNotCompletedWebSite) progression += 1;

    setProgress(progression);
  }, [companyPageObject]);

  return {
    dataCompleted,
    companyPageObject,
    progress,
  };
}
