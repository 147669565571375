import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GUEST } from '../../../service/constant/index';
import { createGuestUser } from '../../../service/applicatif/acount';
import { isEmail, getSStorage } from '../../../utils/index';
import { checkIfEmailExist } from '../../../service/applicatif/acount';
import useEventListener from '../../../hooks/event-handler';
import { useForm } from 'react-hook-form';
export type IPropsInputGuestUser = {
  firstNameGuest: string;
  lastNameGuest: string;
  jobTitleGuest: string;
  companyNameGuest: string;
  webSiteGuest: string;
  businessEmailGuest: string;
  passwordGuest: string;
  hqLocationGuest: string;
  phoneNumberGuest: string;
  acceptPrivacyTerms: boolean;
};

export default function UseCompleteInfoGuest() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<IPropsInputGuestUser>();
  const tempEmail = watch('businessEmailGuest');
  const history = useHistory();
  const [selected, setSelected] = useState('US');
  const [dialCodeSelected, setDialCodeSelected] = useState("+1");
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );
  const [loader, setloader] = useState<boolean>(false);
  const [emailNotSafe, setEmailNotSafe] = useState<string>('');
  const [txtEmailError, setTxtEmailError] = useState<string>('');
  // Dev #47822 V1 [Guest] remove the field "user type"
  const [userTypeGuest, setUserTypeGuest] = useState({
    value: 1,
    label: 'Investor',
  });
  const [haveError, setHaveError] = useState<boolean>(false);
  const [isErrorUserType, setIsErrorUserType] = useState<boolean>(false);

  const checkIfAlreadyEmailExist = async (email: string) => {
    if (isEmail(email)) {
      setloader(true);
      try {
        let response = await checkIfEmailExist(email);
        if (response?.status === 200 && response?.data?.details) {
          if (
            response?.status === 200 &&
            response?.data?.reason === 'invalid domain'
          ) {
            setTxtEmailError('Professional email is required');
          } else {
            setTxtEmailError('This email address is already  used');
          }
          setloader(false);
          setEmailNotSafe('true');
          return;
        } else if (response?.status === 200) {
          setEmailNotSafe('false');
          setloader(false);
        } else {
          setloader(false);
          setEmailNotSafe('true');
          setTxtEmailError('An error occurred');
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    setTxtEmailError('');
    setEmailNotSafe('');
    return () => {};
  }, [tempEmail]);

  useEffect(() => {
    setIsErrorUserType(false);
    return () => {};
  }, [userTypeGuest]);

  const onSubmit = async (data: IPropsInputGuestUser) => {
    setloader(true);
    /**
     * Check if email not safe is empty (business email is not checking)
     * Check if email not is true
     */
    if (emailNotSafe === '' || emailNotSafe === 'true') {
      let checkEmailBusiness = await checkIfAlreadyEmailExist(
        data?.businessEmailGuest
      );
    }
    /**
     * email not safe == false , is business email
     * isErrotType == false , userType not have an error
     */
    if (
      emailNotSafe == 'false' &&
      isErrorUserType == false &&
      userTypeGuest?.value != -1
    ) {
      let params = {
        firstName: data?.firstNameGuest,
        lastName: data?.lastNameGuest,
        jobTitle: data?.jobTitleGuest,
        phone: data?.phoneNumberGuest,
        type: userTypeGuest?.label,
        companyName: data?.companyNameGuest,
        website: data?.webSiteGuest,
        country: data?.hqLocationGuest,
        email: data?.businessEmailGuest,
        password: data?.passwordGuest,
        roles: GUEST,
        phoneNumberCode: selected,
        dialCode: dialCodeSelected
      };
      let response = await createGuestUser(params);
      if (response && response?.status === 201) {
        history.push('./complete-account', {
          email: data.businessEmailGuest,
        });
      }
    } else {
      if (userTypeGuest?.value == -1) {
        setIsErrorUserType(true);
      } else {
        setHaveError(true);
      }
    }
    setloader(false);
  };

  const keyEnter = ({ key }: any) => {
    if (key === 'Enter') {
    } else return;
  };

  useEventListener('keydown', keyEnter);
  return {
    selected,
    loader,
    onSubmit,
    register,
    handleSubmit,
    errors,
    setSelected,
    dataCompleted,
    checkIfAlreadyEmailExist,
    emailNotSafe,
    txtEmailError,
    getValues,
    isErrorUserType,
    haveError,
    setUserTypeGuest,
    dialCodeSelected,
    setDialCodeSelected
  };
}
