import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import OneCase from './complete-info';
import TwoCase from './complete-info-process';
import MatchingProgram from '../../../../hooks/MatchingProgram';

export default function ProgramMatchmaking() {
  const companyPageReducer = useSelector(
    (state: rootState) => state?.userReducer?.isvInCompanyPage?.company_page
  );

  console.log(companyPageReducer)

  return (
    <MatchingProgram>
      <div className='findIsvcontainer programContainer'>
        {!companyPageReducer ? 
        <OneCase
          roleUser="program"
         /> : <TwoCase />}
      </div>
    </MatchingProgram>
  );
}
