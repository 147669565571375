import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import {stripePrivateKey} from "../../../../service/constant";
import {PaymentInfo} from "./payment-info";

const stripePromise = loadStripe(stripePrivateKey);

export type PaymentInfoProps = {
    product?: {
        lookupKey: string
        name: string
    }
    currency?: string
    setCurrency: (currency: string) => void
    getProductPriceWithTax: (country: string, vat: {
        has_vat: boolean,
        vat_number: string,
        company_name: string
    }) => Promise<any>
    getCurrencySymbol: (currency?: string) => string,
    productPrice: {
        amount: number,
        tax: number,
        currency: string,
        totalAmount: number,
    }
    setProductPrice: (price: {
        amount: number,
        tax: number,
        currency: string,
        totalAmount: number,
    }) => void
    userEmail: string
    successCallback?: (
        isFree: boolean,
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    ) => void,
    handleStatus: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
}

export const PaymentInfoWrapper: React.FC<PaymentInfoProps> = (props) => {
    return(
        <Elements stripe={stripePromise}>
            <PaymentInfo
                {...props}
            />
        </Elements>
    )
}