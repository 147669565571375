import React, { useState, useEffect } from 'react';

export interface IListTarget {
  id: number;
  name: string;
  other?: boolean;
}
export default function UseIndustryList(props: {
  defaultSelected: Array<IListTarget>;
  defaultDataCompleted?: Array<any>;
}) {
  const [companyTarget, setcompanyTarget] = useState<Array<IListTarget>>([]);
  const [companyTargetSecound, setcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);
  const [lastCompanyTarget, setLastcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);
  const [companyTargetSelected, setCompanyTargetSelected] = useState<
    Array<IListTarget>
  >(props?.defaultSelected || []);
  const [otherValue, setOtherValueTxt] = useState<string | undefined>(
    props?.defaultSelected?.find((el) => el.other)?.name || ''
  );
  const addTargetCompany = (dataMake: IListTarget) => {
    let found = companyTargetSelected?.find(
      (el: IListTarget) => el.id?.toString() === dataMake.id?.toString()
    );
    if (!found) {
      setCompanyTargetSelected([...companyTargetSelected, dataMake]);
    } else {
      setCompanyTargetSelected(
        companyTargetSelected?.filter(
          (elem: IListTarget) => elem.id?.toString() !== dataMake.id?.toString()
        )
      );
    }
  };

  useEffect(() => {
    async function getTargetCompany() {
      let industrieData = props.defaultDataCompleted || [];
      let divider = Math.floor(industrieData?.length / 3);
      let firstColumn = industrieData?.slice(0, divider);
      let secondColumn = industrieData?.slice(divider, divider * 2);
      let lastComumn = industrieData?.slice(divider * 2);
      setcompanyTarget(firstColumn);
      setcompanyTargetSecond(secondColumn);
      setLastcompanyTargetSecond(lastComumn);
    }
    getTargetCompany();

    return () => {};
  }, [props?.defaultDataCompleted]);

  /**
   * @returns initialise company selected
   */
  useEffect(() => {
    if (props?.defaultSelected?.length > 0) {
      setCompanyTargetSelected(props?.defaultSelected);
      const otherValue = props?.defaultSelected?.find((el) => el.id === -1);
      if (otherValue) {
        setOtherValueTxt(otherValue.name);
      }
    }
    return () => {};
  }, [props?.defaultSelected]);

  const backToDefault = () => {
    setCompanyTargetSelected(props?.defaultSelected);
    setOtherValueTxt('');
  };

  return {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault,
    otherValue,
    setOtherValueTxt,
  };
}
