import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "./info.scss";

export type Iprops = {
  title?: string;
  secondTitle?: string;
  txt: Array<string>;
  btnTxt?: string;
  urlBtn?: () => void;
  closePopUp?: (pop: boolean) => void;
};

export default function AlertInfo(props: Iprops) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui alert-info">
          <div className="pop-head">
            <p className="text-center">
              {props?.title ? props.title : "Publish"}
            </p>
            <span onClick={onClose} className="close"></span>
          </div>
          <div className="modal-body">
            <svg
              width={150}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            <p className="info-missing">
              {props?.secondTitle
                ? props?.secondTitle
                : "Some information is missing..."}
            </p>

            {props.txt.map((t) => (
              <p className="text-center">{t}</p>
            ))}

            <button
              onClick={() => {
                if (props.urlBtn) {
                  props.urlBtn();
                  onClose();
                } else {
                  onClose();
                }
              }}
            >
              {props.btnTxt ? props.btnTxt : "OK"}
            </button>
          </div>
        </div>
      );
    },
  });
}
