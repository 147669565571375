import React, { useState } from "react";
import "./Header.scss";
import "react-datepicker/dist/react-datepicker.css";
import { UseCreateEventsCtr } from "../../../controller/event-ctr/use-create-events-ctr";
import { getLooUpKeyPostEVENT, getSStorage, isModerator } from "../../../utils";
import ModalChoiseEvent from "./modal/modal-choise-event";
import { Link, NavLink } from "react-router-dom";
import ModalCreateEvent from "./modal/ModalCreateEvent";
import { LoginModal } from "../login/login";

import { Modal } from "react-bootstrap";
import { PaymentPopup } from "../../container/payment-popup/payment-popup";

export default function SecondHeaderEvents(props: any) {
  const providerCreateEvent = UseCreateEventsCtr();
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const eventTypeDataSource = dataCompleted?.event_type || [];

  const eventName =
    eventTypeDataSource?.find(
      (el: any) =>
        el?.id?.toString() ===
        providerCreateEvent?.eventTypeSelected?.toString()
    )?.name || "New events";

  return (
    <>
      <PaymentPopup
        show={providerCreateEvent.showPaymentModal}
        title={"Publish event"}
        setShowPaymentModal={providerCreateEvent.setShowPaymentModal}
        product={{
          lookupKey: getLooUpKeyPostEVENT(
            providerCreateEvent.userReducer?.roles[0]
          ),
          name: "event",
        }}
        successCallback={(
          isFree: boolean,
          paymentMethodId: string | null,
          setLoading: any,
          savePaymentMethod: any,
          setShowPaymentStatus: any
        ) =>
          providerCreateEvent.paymentMethodCallBack(
            isFree,
            paymentMethodId,
            setLoading,
            savePaymentMethod,
            setShowPaymentStatus
          )
        }
        free={providerCreateEvent.freeNumber}
        isPriceInfo={providerCreateEvent.isPriceInfo}
        handleStatusOk={providerCreateEvent.handleStatusOk}
        priceInfoCallback={() => {
          providerCreateEvent.priceInfoCallback();
        }}
        showErrorOemEventActivate={!providerCreateEvent.oemProgram.status}
        cancelPostCallBack={() => {
          if (!providerCreateEvent.isPriceInfo) {
            providerCreateEvent.setShowNext(true);
          }
        }}
      />

      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title" onClick={() => props.handleInitEvent()}>
              Events
            </h2>
            {!isModerator(providerCreateEvent.userReducer?.roles) && (
              <div className="menuDynamic bl">
                <div className="item">
                  <Link
                    to="/events/my-events"
                    className={
                      providerCreateEvent?.isInMyEvents ? "active" : ""
                    }
                  >
                    My events
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to="/events/all-events"
                    className={
                      providerCreateEvent?.isInMyEvents ? "" : "active"
                    }
                  >
                    All events
                  </Link>
                </div>
              </div>
            )}
          </div>
          {!isModerator(providerCreateEvent.userReducer?.roles) && (
            <div className="rightItemHeader">
              <div className="item">
                <Link
                  to="#"
                  className="startPost"
                  onClick={(e) => {
                    e.preventDefault();
                    providerCreateEvent?.handleCreateEvent();
                  }}
                >
                  Create a new event
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalChoiseEvent
        activeCategorie={providerCreateEvent?.activeCategorie}
        eventObject={providerCreateEvent?.eventObject}
        setEventTypeSelected={(p: any) =>
          providerCreateEvent?.setEventTypeSelected(p)
        }
        setShow={(p: boolean) => providerCreateEvent?.setShow(p)}
        setShowNext={(p: boolean) => providerCreateEvent?.setShowNext(p)}
        show={providerCreateEvent?.show}
      />

      <ModalCreateEvent
        showNext={providerCreateEvent.showNext}
        eventName={eventName}
        setShowNext={providerCreateEvent.setShowNext}
        handleSubmit={providerCreateEvent.handleSubmit}
        onSubmit={providerCreateEvent.onSubmit}
        isErrorInfo={providerCreateEvent.isErrorInfo}
        txtError={providerCreateEvent.txtError}
        startDate={providerCreateEvent.startDate}
        setStartDate={providerCreateEvent.setStartDate}
        isAllTime={providerCreateEvent.isAllTime}
        register={providerCreateEvent.register}
        isShowEndTime={providerCreateEvent.isShowEndTime}
        setisShowEndTime={providerCreateEvent.setisShowEndTime}
        setisAllTime={providerCreateEvent.setisAllTime}
        errors={providerCreateEvent.errors}
        isSelected={providerCreateEvent.isSelected}
        loaderCreateEvent={providerCreateEvent.loaderCreateEvent}
        eventTypeSelected={providerCreateEvent?.eventTypeSelected?.toString()}
        control={providerCreateEvent.control}
        eventDescription={providerCreateEvent.eventDescription}
        setEventDescription={providerCreateEvent.setEventDescription}
        isValid={providerCreateEvent.isValid}
        dataLabelFilter={providerCreateEvent.dataLabelFilter}
        defaultDataLabel={providerCreateEvent.defaultDataLabel}
        handleFilterSelect={providerCreateEvent.handleFilterSelect}
        handleSelectedLabels={providerCreateEvent.handleSelectedLabels}
        setTxtFilter={providerCreateEvent.setTxtFilter}
        txtFilter={providerCreateEvent.txtFilter}
        isFree={providerCreateEvent.freeNumber > 0}
        cancelCreate={() => providerCreateEvent.cancelCreate()}
        isFocused={providerCreateEvent.isFocused}
        setIsFocused={providerCreateEvent.setIsFocused}
      />

      <LoginModal
        urlType={"community"}
        show={providerCreateEvent?.showModalLogin}
        setShow={providerCreateEvent?.setShowModalLogin}
      />

      <Modal
        show={providerCreateEvent.showPermissionModal}
        className="small forDesactivate"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">{providerCreateEvent.allText?.[0]}</h2>
            <NavLink
              to="#"
              className="close"
              onClick={() => providerCreateEvent.setPermissionModal(false)}
            >
              <span className="icon-close-pop"></span>
            </NavLink>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <h3 className="title withIcon">
              <span className="icon-info"></span>
              {providerCreateEvent.allText?.[1]}
            </h3>

            <p>{providerCreateEvent.allText?.[2]}</p>
            <p>{providerCreateEvent.allText?.[3]}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
