import React, { ChangeEvent, useState } from "react";
import "./static-page.scss";
import Header from "../../widget/header-logo-center/header-logo-center";
import { useLocation } from "react-router-dom";
import {
  postDataWTokenService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { postDataOdoo, url } from "../../../service/constant";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";

const queryString = require("query-string");

export default function UnsubscribeContact() {
  const location = useLocation();
  const parsed = queryString.parse(location?.search);
  const [checkedState, setcheckedState] = useState<boolean>(false);
  const { pid, cid } = parsed;
  const [stateUnsubscribe, setStateUnsubscribe] = useState(false);
  const [isOk, setIsOk] = useState(false);

  const txt =
    location.pathname == "/mailing/unsubscribe/odoo/platformenablement"
      ? "Plateform enablement"
      : location.pathname == "/mailing/unsubscribe/odoo/newpromotion"
      ? "Devinsider New and Promotion"
      : location.pathname == "/mailing/unsubscribe/odoo/partnercommunication"
      ? "Devinsider Partner Community"
      : "Devinsider Community List";

  const sendUnscribe = async () => {
    try {
      if (pid && cid) {
        setStateUnsubscribe(true);
        const params = {
          pid,
          cid,
          state: !checkedState ? "yes" : "no",
        };

        const resp = await postDataWTokenService(url.unsubscribe, params);
        if (resp?.status === 200) {
          setStateUnsubscribe(false);
          setIsOk(true);
        }
      }
    } catch (error) {
    }
  };

  return (
    <div className="emailConfirm">
      <Header />
      <div className="container mx-auto">
        {!isOk && (
          <div className="box-content">
            <h4 className="title">Are you sure you want to unsubscribe ? </h4>
            <div className="custom-control custom-checkbox orange">
              <input
                type="checkbox"
                className="custom-control-input"
                id="unsubscribe"
                checked={checkedState}
                disabled={stateUnsubscribe}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setcheckedState(!checkedState);
                }}
              />
              <label htmlFor="unsubscribe" className="custom-control-label">
                Unsubscribe from all mailing lists in{" "}
                <a href="javascript:;">{txt}</a>
              </label>
            </div>
            <div
              className="btn btn-devinsider"
              onClick={() => !stateUnsubscribe && sendUnscribe()}
            >
              {stateUnsubscribe ? <Loader /> : "Unsubscribe"}
            </div>
          </div>
        )}

        {isOk && (
          <div className="confirmUnsubscribe">
            <p>Your request has been submitted, thank you.</p>
          </div>
        )}
      </div>
    </div>
  );
}
