import React from 'react';
import { IListTarget, IPropsIL } from './types';
import Form from 'react-bootstrap/Form';

export default function Industry(props: IPropsIL) {
  return (
    <div className='getstartedContainer largeContainer'>
      <div className='contentTargetIndustry'>
        <div className='col4'>
          {props?.companyTarget?.map((element: IListTarget, index: number) => (
            <Form.Check
              key={index}
              custom
              checked={
                props?.companyTargetSelected?.find(
                  (ele: IListTarget) =>
                    ele.id?.toString() === element.id?.toString()
                )
                  ? true
                  : false
              }
              label={element.name}
              id={element.id.toString()}
              onChange={() => props?.addTargetCompany(element)}
            />
          ))}
        </div>
        <div className='col4'>
          {props?.companyTargetSecound?.map(
            (element: IListTarget, index: number) => (
              <Form.Check
                key={index}
                custom
                checked={
                  props?.companyTargetSelected?.find(
                    (ele: IListTarget) =>
                      ele.id?.toString() === element.id?.toString()
                  )
                    ? true
                    : false
                }
                label={element.name}
                id={element.id.toString()}
                onClick={() => {
                  props?.addTargetCompany(element);
                }}
                onChange={() => null}
              />
            )
          )}
        </div>
        <div className='col4'>
          {props?.lastCompanyTarget?.map(
            (element: IListTarget, index: number) => (
              <Form.Check
                key={index}
                custom
                onChange={() => null}
                checked={
                  props?.companyTargetSelected?.find(
                    (ele: IListTarget) =>
                      ele.id?.toString() === element.id?.toString()
                  )
                    ? true
                    : false
                }
                label={element.name}
                id={element.id.toString()}
                onClick={() => {
                  props?.addTargetCompany(element);
                }}
              />
            )
          )}
          <Form.Check
            custom
            onChange={() => null}
            checked={
              props?.companyTargetSelected?.find(
                (ele: IListTarget) => ele.id?.toString() === '-1'
              )
                ? true
                : false
            }
            label={'Other'}
            id={'-1'}
            onClick={() => {
              props?.addTargetCompany({
                id: -1,
                name: 'Other',
              });
            }}
          />
          {props.companyTargetSelected?.find((el: any) => el.id == -1) && (
            <input
              type='text'
              name=''
              id=''
              placeholder='other'
              value={props.otherValue}
              onChange={(evt: any) => props.setOtherValueTxt(evt.target.value)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
