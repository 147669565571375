/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import Modal from "react-bootstrap/Modal";
import { FormHeaderStrategy } from "../../header/Headerform";
export default function CreateCompanyForm5(props: T.IpropsForm5) {
  return (
    <Modal show={props?.showStep5} className="createCompanypopup smallPopup">
      <Modal.Header>
        <FormHeaderStrategy />
        <a className="close back" onClick={props?.handleBackStep4}>
          &nbsp;
        </a>
        <a className="close" onClick={props?.handleCloseStep5}>
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder row">
            <div className="form-group required m-0 col-12">
              <label htmlFor="">Licensing model </label>
              <span className="selectalloverstyle">
                Select all that apply.
                {props.isErrorLicencingModel && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select at least one category{" "}
                    </span>
                  </div>
                )}
              </span>
            </div>
            <div className="col-12 forStep5custom p-2">
              {props.licencing &&
                props.licencing.map((el: T.Tchannel, index: number) => (
                  <div
                    className="custom-control custom-checkbox"
                    key={index}
                    onClick={() => props.addLicencing(el.id)}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={el.id.toString()}
                      checked={
                        props.licenCingModelSelected &&
                        props.licenCingModelSelected?.find(
                          (it: number) => it == el.id
                        )
                          ? true
                          : false
                      }
                      onChange={() => null}
                    />
                    <label htmlFor="c1" className="custom-control-label">
                      {el.name}
                    </label>
                  </div>
                ))}
            </div>
            <div className="form-group required m-0 mt-3 col-12">
              <label htmlFor="">Distribution channel</label>
              <span className="selectalloverstyle">
                Select all that apply.{" "}
                {props.isErrorDistribuTionChanel && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select at least one category{" "}
                    </span>
                  </div>
                )}
              </span>
            </div>
            <div className="col-12 forStep5custom p-2">
              {props.distribution &&
                props.distribution.map((el: T.Tchannel) => (
                  <div
                    className="custom-control custom-checkbox"
                    key={el.id.toString()}
                    onClick={() => props.addDistribution(el.id)}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={el.id.toString()}
                      checked={
                        props.distributedChanelSelected &&
                        props.distributedChanelSelected?.find(
                          (it: number) => it == el.id
                        )
                          ? true
                          : false
                      }
                      onChange={() => null}
                    />
                    <label htmlFor="c1" className="custom-control-label">
                      {el.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder m-0">
          <button
            className="btn btn-devinsider large containerBtnBtn"
            onClick={props?.submitForm5}
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
