/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import visa from '../../../../../resources/images/visa.svg';
import mastercard from '../../../../../resources/images/mastercard.svg';
import express from '../../../../../resources/images/american-express.svg';
import discover from '../../../../../resources/images/discover.svg';
import CardRegistrationPremiumInput from './card-registration-premium-input';

const CardRegistrationForm = (props: any) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <>
      <Modal
        show={props?.showCardRegistrationForm}
        className='createCompanypopup medium forStep5custom forPremium'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Premium subscription</h2>
            <a
              className='close'
              onClick={() => {
                props?.setShowCardRegistrationForm(false);
              }}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter radius pt-3 pb-0'>
            <div className='checkCard'>
              <b>Payment</b>
              <div className='form-group'>
                <input
                  type='radio'
                  className='form-check-input regular-checkbox big-checkbox'
                  checked={true}
                />
                <ul>
                  <li>
                    <img src={visa} />
                  </li>
                  <li>
                    <img src={mastercard} />
                  </li>
                  <li>
                    <img src={express} />
                  </li>
                  <li>
                    <img src={discover} />
                  </li>
                </ul>
              </div>
            </div>

            <div className='row m-0'>
              <div className='name required col-6 pl-4'>
                <label htmlFor=''>First name</label>
                <input
                  type='text'
                  className='form-control'
                  name='MemberName'
                  defaultValue={firstName}
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
              <div className='name required col-6 pr-4'>
                <label htmlFor=''>Last name</label>
                <input
                  type='text'
                  className='form-control'
                  name='Job'
                  defaultValue={lastName}
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
            </div>
            <div className='name required col-12'>
              <CardRegistrationPremiumInput
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                showCardRegistrationForm={props.showCardRegistrationForm}
                setShowCardRegistrationForm={props.setShowCardRegistrationForm}
                setShowPaymentSuccessfullModal={
                  props.setShowPaymentSuccessfullModal
                }
                setShowPaymentErrorModal={props.setShowPaymentErrorModal}
                invoiceNumber={props.invoiceNumber}
                setInvoiceNumber={props.setInvoiceNumber}
                handleErrorCard={() => props?.handleErrorCard()}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardRegistrationForm;
