import React, { useState, useCallback } from "react";

export default function useFilterevent() {
  const [programType, setProgramType] = useState<Array<string>>([]);
  const [countries, setCountries] = useState<Array<string>>([]);
  const [segmentsDetails, setSegmentsDetails] = useState<Array<string>>([]);
  const [sliderValue, setSliderValue] = useState<Array<number>>([60, 100]);
  const setupAddArray = (
    arrayData: Array<string>,
    id: string
  ): Array<string> => {
    let found = arrayData?.find((el: string) => el === id);
    if (found) {
      return arrayData?.filter((el: string) => el !== id);
    } else {
      return [...arrayData, id];
    }
  };

  const handleFilterType = async (id: string) => {
    let tempFiltered = setupAddArray(programType, id?.toString());
    setProgramType(tempFiltered);
  };

  const handleFilterCountry = async (idCountry: string) => {
    let tempFiltered = setupAddArray(countries, idCountry);
    setCountries(tempFiltered);
  };

  const handleFilterProgramSolution = async (idProgramSolution: string) => {
    let tempFiltered = setupAddArray(segmentsDetails, idProgramSolution);
    setSegmentsDetails(tempFiltered);
  };

  const resetFilterEvents = async () => {
    if (segmentsDetails?.length > 0) setSegmentsDetails([]);
    if (programType?.length > 0) setProgramType([]);
    if (countries?.length > 0) setCountries([]);
    if (sliderValue?.toString() !== [60, 100]?.toString())
      setSliderValue([60, 100]);
  };

  return {
    handleFilterType,
    handleFilterCountry,
    handleFilterProgramSolution,
    resetFilterEvents,
    sliderValue,
    segmentsDetails,
    countries,
    programType,
    setSliderValue,
  };
}
