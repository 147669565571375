import { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { BtnNext } from '../../bouton/btn-next';
import CardSection from './card-section';
import UseCartPremiumSetup from './use-cart-premium-setup';

type IProps = {
  firstName: string;
  lastName: string;
  setFirstName: any;
  setLastName: any;
  showCardRegistrationForm: boolean;
  setShowCardRegistrationForm: () => void;
  setShowPaymentSuccessfullModal: (value: boolean) => void;
  setShowPaymentErrorModal: (value: boolean) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  handleErrorCard: () => void;
};
export default function CardSetupPremiumForm(props: IProps) {
  const history = useHistory();
  const goToTermOfUse = () => {
    history.push('/terms-and-conditions');
  };
  const providerUseCartSetup = UseCartPremiumSetup(props);

  const handleSavePaymentInformation = () => {
    document.getElementById('btnSaveCardInputInformationPremium')?.click();
  };

  const disabledBtn =
    !providerUseCartSetup.stripe &&
    providerUseCartSetup.client === null &&
    providerUseCartSetup.gettingSetupIntent === false;

  return (
    <form onSubmit={providerUseCartSetup.handleSubmit} name='registerCard'>
      <br />
      {providerUseCartSetup.gettingSetupIntent === true ? (
        'Loading...'
      ) : (
        <CardSection
          txtError={providerUseCartSetup.txtError}
          chageCardElement={providerUseCartSetup.chageCardElement}
        />
      )}
      <button
        style={{ display: 'none' }}
        id='btnSaveCardInputInformationPremium'
        disabled={disabledBtn}
      />
      <div className='block pb-0'>
        <div className='slidecheck'>
          <div className='custom-checkbox'>
            <input
              id='hideAuthor'
              type='checkbox'
              className='custom-control-input'
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                providerUseCartSetup.setSaveInformations(event.target.checked)
              }
              checked={providerUseCartSetup.saveInformations}
            />
            <label htmlFor='hideAuthor' className='custom-control-label test'>
            <p>Save payment information</p>
            </label>
            <p>Information is encrypted and securely stored</p>
          </div>
        </div>
      </div>

      <div className='col-6 border radius forBillingCenter contact float-right'>
        <div className='row forAds pl-3 pr-3 pb-0'>
          <div className='col-12 d-flex align-items-baseline justify-content-between pt-4'>
            <h5>
              <p>
                Premium <br />
                subscription
              </p>
              <p className='font-medium'>Yearly renewable</p>
            </h5>
            <h5 className='color-orange text-align-right pr-0'>
              $1,500.00 USD
            </h5>
          </div>
        </div>
        <div className='forAds pl-3 pr-3'>
          <p className='mt-3'>
            {' '}
            By clicking "Confirm", you agree to our{' '}
            <a onClick={() => goToTermOfUse()}>terms & conditions</a>. You will
            be automatically charged for your purchase.
          </p>
        </div>
        <button
          className={`${
            providerUseCartSetup.settingSetupIntentProcess ? "disabled-btn-premium" : ""
          } btn btn-devinsider px-5 save ml-3 mr-3 mb-4`}
          onClick={() => handleSavePaymentInformation()}
          disabled={
            props.firstName === '' ||
            props.lastName === '' ||
            disabledBtn ||
            providerUseCartSetup.settingSetupIntentProcess
          }
          
        >
          <BtnNext
            text={'Confirm'}
            showLoader={providerUseCartSetup.settingSetupIntentProcess}
          />
        </button>
      </div>
    </form>
  );
}
