import React, { useState, useEffect } from "react";
import {
  ICountry,
  IprogramType,
  ICategorie,
} from "../../../../interface/program-type";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { useSelector, useDispatch } from "react-redux";
import { url } from "../../../../service/constant";
import { rootState } from "../../../../redux/reducers";

export enum filterType {
  program_type,
  location,
  categories,
  otherWay,
  match_slider,
}
export default function UseFilter(type: string) {
  const [isShowBloc, setisShowBloc] = useState<filterType>(filterType.otherWay);
  const [programTypes, setprogramTypes] = useState<Array<IprogramType>>([]);
  const [countries, setCountries] = useState<Array<ICountry>>([]);
  const [categories, setcategories] = useState<Array<ICategorie>>([]);
  const userState = useSelector((state: rootState) => state.userReducer);

  const [indexLocation, setIndexLocation] = useState(6);
  const [indexCategorie, setIndexCategorie] = useState(6);
  const [indexTargetedIndustry, setIndexTargetedIndustry] = useState(6);

  useEffect(() => {
    async function getFilterd() {
      let urlType =
        type === "favorites"
          ? url?.programs?.get_filter_favorite
          : url?.programs?.get_filter_matching;

      let response = await getDataService(urlType, {}, userState?.token);
      if (response?.status === 200) {
        setprogramTypes(response?.data?.program_type);
        setCountries(response?.data?.countries);
        setcategories(response?.data?.categories);
      }
    }
    getFilterd();

    return () => {};
  }, []);

  const handleBlock = (params: filterType) => {
    if (params === isShowBloc) {
      setisShowBloc(filterType?.otherWay);
    } else {
      setisShowBloc(params);
    }
  };

  return {
    isShowBloc,
    setisShowBloc,
    programTypes,
    countries,
    categories,
    handleBlock,
    indexLocation,
    setIndexLocation,
    indexCategorie,
    setIndexCategorie,
    indexTargetedIndustry,
  };
}
