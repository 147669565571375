/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import logoImg from '../../../resources/images/logo-lettre.svg';
import { useHistory } from 'react-router-dom';

export default function HeaderLogoCenter(props: any) {
  const history = useHistory();

  return (
    <div>
      <header>
        <nav>
          <div className='container mx-auto HeaderCenter'>
            <img
              src={logoImg}
              alt='Logo devinsider'
              className='logoHeaderCenter'
              onClick={() => history.push('/')}
            />
          </div>
        </nav>
      </header>
    </div>
  );
}
