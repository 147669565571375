import React, { useState, useEffect } from 'react';

export interface ItemsSolution {
  id: number;
  name: string;
}
export interface ISolutionListe {
  id: number;
  name: string;
  segmentDetails: Array<ItemsSolution>;
}

export type TPropsData = {
  defaultValue: Array<{ id: number; name: string; other?: boolean }>;
  defaultValueFeatured: Array<{ id: number; name: string }>;
  solutionCategoryList?: Array<any>;
};

export interface IdataMakeSolution {
  idSolutionOffert: number;
}
export default function UseCompleteCategoryInvestor(props: TPropsData) {
  const [allSolution, setAllResellerSolution] = useState<Array<ISolutionListe>>(
    []
  );
  const [otherValue, setOtherValueTxt] = useState<string | undefined>(
    props?.defaultValue?.find((el) => el.other)?.name || ''
  );

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<{ id: number; name: string; other?: boolean }>
  >(props?.defaultValue);

  const [solutionCheckedFeatures, setSolutionCheckedFeatured] = useState<
    Array<{ id: number; name: string }>
  >(props?.defaultValueFeatured || []);

  const [featuredSolution, setFeaturedSolution] = useState<ISolutionListe>({
    id: 0,
    name: '',
    segmentDetails: [],
  });

  /**
   * @returns initialise solution selected
   */
  useEffect(() => {
    if (props?.defaultValueFeatured?.length > 0) {
      setSolutionCheckedFeatured(props?.defaultValueFeatured);
    }
    return () => {};
  }, [props?.defaultValueFeatured]);

  /**
   * @returns initialise solution selected
   */
  useEffect(() => {
    if (props?.defaultValue?.length > 0) {
      setSolutionChecked(props?.defaultValue);
      setOtherValueTxt(props?.defaultValue?.find((el) => el.other)?.name || '');
    }
    return () => {};
  }, [props?.defaultValue]);

  useEffect(() => {
    let lengthSolution = allSolution?.length;
    if (lengthSolution % 2 === 0) {
      setSolutionLeftColumnData(allSolution?.slice(0, lengthSolution / 2));
      setSolutionRigthColumnData(
        allSolution?.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allSolution?.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allSolution?.slice((lengthSolution - 1) / 2 + 1, lengthSolution + 1)
      );
    }
    return () => {};
  }, [props.solutionCategoryList, allSolution]);

  useEffect(() => {
    async function getSolution() {
      let featured = props.solutionCategoryList?.find(
        (el: any) => el.id === 9999
      );
      setFeaturedSolution(featured);
      setAllResellerSolution(
        (props.solutionCategoryList || [])?.filter((el: any) => el.id < 9999)
      );
    }
    getSolution();

    return () => {};
  }, [props.solutionCategoryList]);

  const addSolutionCheched = (element: any) => {
    const allSolution = [...solutionChecked, ...solutionCheckedFeatures];

    let found = solutionChecked?.find((el: any) => el.id == element?.id);
    if (!found) {
      if (allSolution?.length < 3) {
        setSolutionChecked([...solutionChecked, element]);
      }
    } else {
      setSolutionChecked(
        solutionChecked?.filter((elem: any) => elem?.id != element?.id)
      );
    }
  };

  const addSolutionChechedFeatured = (element: any) => {
    const allSolution = [...solutionChecked, ...solutionCheckedFeatures];

    let found = solutionCheckedFeatures?.find(
      (el: any) => el?.id == element?.id
    );
    if (!found) {
      if (allSolution.length < 3) {
        setSolutionCheckedFeatured([...solutionCheckedFeatures, element]);
      }
    } else {
      setSolutionCheckedFeatured(
        solutionCheckedFeatures?.filter((elem: any) => elem?.id != element?.id)
      );
    }
  };

  const backToDefault = () => {
    setSolutionChecked(props?.defaultValue);
    setSolutionCheckedFeatured(props?.defaultValueFeatured);
    setOtherValueTxt('');
  };

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefault,
    otherValue,
    setOtherValueTxt,
  };
}
