/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import ModalEditRessource from "../../../../widget/modal-edit-ressources/index";
import { fileUrl, getExtensionFileName } from "../../../../../utils";

type IProps = {
  fileDataSource: any;
  isOtherUserType: any;
  showModalEditRessource: () => void;
  isPreview: any;
  isEdit: any;
  removeFile: (p: any, index: any) => void;
  setFileResource: (event: any, index: any) => void;
  closeRessourceModal: () => void;
  loader: boolean;
  isShowRessource: boolean;
  saveUpdate: () => void;
  isEmptyFile: boolean;
};
export default function OemResources(props: IProps) {
  const isEmptyRessourcePreviewOem =
    props?.fileDataSource?.every(
      (resource: any) =>
        resource.file_location === "" && resource?.file_name === ""
    ) && props.isPreview;

  const previewEmptyOtherUser = props.isOtherUserType && props.isEmptyFile;
  const isEmptyFile = (file: any) =>
    props.isPreview && file?.file_location === "" && file?.file_name === "";

  return (
    <>
      {previewEmptyOtherUser || isEmptyRessourcePreviewOem ? (
        <></>
      ) : (
        <article className="investorArticle bgGris">
          <div className="mx-auto container cont3">
            <div className="contentLeftInvestor">
              <a
                className="linkEditLeftCompany"
                onClick={(e) => {
                  e.preventDefault();
                  props.showModalEditRessource();
                }}
              >
                Edit
                <span className="icon-edit-full" />
              </a>

              <h3 className="title">Resources </h3>
              {!props.isPreview && (
                <p>Provide additional documentation about your program.</p>
              )}

              <ul className="listSquareItem resource grisbg">
                {props?.fileDataSource?.map((el: any, index: number) =>
                  isEmptyFile(el) ? (
                    <></>
                  ) : (
                    <li key={index}>
                      {el?.file_name && props?.isEdit && (
                        <a
                          className="removeImg"
                          onClick={(e) => {
                            e.preventDefault();
                            props.removeFile(el?.id, index);
                          }}
                        >
                          <span className="icon-close-pop"></span>
                        </a>
                      )}

                      <label>
                        <input
                          type="file"
                          accept=".doc,.docx,.pdf"
                          style={{ display: "none" }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            props.setFileResource(event, index);
                          }}
                          disabled={!props?.isEdit || el?.file_name != ""}
                        />
                        <a
                          className={`${el?.file_name ? "" : "empty"} `}
                          href={fileUrl(el.file_location)}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!el.file_location) {
                              e.preventDefault();
                              return null;
                            }
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="typeDoc">
                            <span
                              className={getExtensionFileName(el.file_name)}
                            ></span>
                          </div>
                          <div className="titleDoc">
                            {el?.file_name?.split(".")[0]}
                          </div>
                        </a>
                      </label>
                    </li>
                  )
                )}
              </ul>

              <ModalEditRessource
                closeRessourceModal={() => props?.closeRessourceModal()}
                fileDataSource={props?.fileDataSource}
                fileRessourceEdit={props?.fileDataSource}
                setFileResource={(file: any, index: number) =>
                  props?.setFileResource(file, index)
                }
                loader={props?.loader}
                removeFile={(id: number, index: number) =>
                  props?.removeFile(id, index)
                }
                isShowRessource={props?.isShowRessource}
                saveUpdate={() => props?.saveUpdate()}
              />
            </div>
          </div>
        </article>
      )}
    </>
  );
}
