import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";

import moment from "moment";
import { useForm } from "react-hook-form";
import { rootState } from "../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { postDataFormDataService } from "../../service/applicatif/back-end-service";
import { getMyEvents } from "../../redux/actions/event-action";
import { timeRange } from "../../utils/time-range";
import { convertToRaw, EditorState } from "draft-js";

import { getDataWithoutToken } from "../../service/api";
import { getMonth, getTimeZoneUtc } from "../../utils";
import {
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  CONTRIBUTOR,
  url,
} from "../../service/constant";
import { toast } from "react-toastify";
import UseSearchEventForm from "../../screen/widget/SearchFormEvent/useSearchEventForm";
import { ITimezone, ITimezoneOption } from "react-timezone-select";
const queryString = require("query-string");

export type IPropsEvent = {
  startTime: string;
  endTime: string;
  isAllDate: boolean;
  eventUrl: string;
  eventTitle: string;
  eventTags: string;
  country?: string;
  city?: string;
  timezone: ITimezone | ITimezoneOption
};
export const UseCreateEventsCtr = () => {
  const user = useSelector((state: rootState) => state.userReducer);
  let params: any = useLocation();

  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [showNext, setShowNext] = useState(false);
  const history = useHistory();
  const [loaderCreateEvent, setloaderCreateEvent] = useState<boolean>(false);
  const [eventTypeSelected, setEventTypeSelected] = useState<string>("");
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const currentProgramPage = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const [eventDescription, setEventDescription] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const [allText, setAllText] = useState(["", "", ""]);

  const [isShowEndTime, setisShowEndTime] = useState<boolean>(false);
  const [isAllTime, setisAllTime] = useState<boolean>(false);
  const [isErrorInfo, setisErrorInfo] = useState(false);
  const [txtError, settxtError] = useState<string>("");

  const [showWhitoutCard, setShowWhitoutCard] = useState(false);
  const [isPayementError, setIsPayementError] = useState(false);
  const [paramsEvent, setParamsEvent] = useState<any>(null);

  const isIsv = user?.roles?.indexOf(ISV) !== -1;
  const isGuest = user?.roles?.indexOf(GUEST) !== -1;
  const isReseller = user?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = user?.roles?.indexOf(INVESTOR) !== -1;
  const isOem = user?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const isExpert = user?.roles?.indexOf(CONTRIBUTOR) !== -1;
  const [showPermissionModal, setPermissionModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(
    queryString.parse(params?.search)?.post ? true : false
  );
  const [freeNumber, setFreeNumber] = useState<number>(0);
  const [isPriceInfo, setIsPriceInfo] = useState(
    queryString.parse(params?.search)?.post ? true : false
  );
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const companyInvestorPage = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );
  const now = new Date();

  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const isConnected = userReducer?.token && userReducer.enable;

  const [show, setShow] = useState<boolean>(false);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
  const [activeCategorie, setactiveCategorie] = useState<number>(-1);
  const eventObject = useSelector((state: rootState) => state?.eventReducer);
  const {
    handleFilterSelect,
    handleSelectedLabels,
    txtFilter,
    setTxtFilter,
    dataLabelFilter,
    setDataLabelFilter,
    defaultDataLabel,
    isFocused,
    setIsFocused,
  } = UseSearchEventForm({ defaultLabelValue: "" });

  const toogleModal = useCallback(
    (p: boolean) => {
      userReducer && setShow(p);
    },
    [show]
  );
  const toogleActiveCategorie = useCallback(
    (p: number) => {
      setactiveCategorie(p);
    },
    [activeCategorie]
  );

  const goToMyEvents = () => {
    history.push("/events/my-events");
  };

  const goToAllEvents = () => {
    history.push("/events/all-events");
  };

  const urlPath = window.location.href;
  const isFound: any = urlPath.indexOf("my-events");

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<IPropsEvent>({ mode: "onChange" });

  const callback = (data: any) => {
    getPlanDetails();
    history?.push("/events/my-events");
  };

  const startTime = watch("startTime");

  const getMyEventsFunction = () => {
    let params = {
      oem_program_id: currentProgramPage?.program?.id,
      tags: "",
      event_type: "",
      limit: 10,
      page: 1,
      search: "",
    };
    dispatch(getMyEvents(params, false, callback));
  };

  const onSubmit = async (data: IPropsEvent) => {
    let tempLabels = txtFilter?.replace(/,$/, "")?.split(",");

    const isEmptyEndDate = data?.endTime === "" || data?.endTime === undefined;
    let startDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.startTime?.toString()
    );
    let endDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.endTime?.toString()
    );
    var dateDiffHour: number = 0;
    let selectedDate = moment(startDate).format("YYYY-MM-DD");
    let formatedDateToSend = moment(startDate).format("DD-MM-YYYY");
    const dateTimeStart = moment(
      new Date(selectedDate + " " + startDateSelected?.value)
    );
    const dateTimeEnd = moment(
      new Date(selectedDate + " " + endDateSelected?.value)
    );
    dateDiffHour = isAllTime
      ? 24
      : isEmptyEndDate
      ? -1
      : moment.duration(dateTimeEnd.diff(dateTimeStart)).asHours();

    let isEmptyTime = data?.startTime === "" && !isAllTime ? true : false;
    if (isEmptyTime) {
      setisErrorInfo(true);
      settxtError("start time  is required");
      return;
    }
    if (dateDiffHour <= 0 && data?.startTime !== "" && !isEmptyEndDate) {
      setisErrorInfo(true);
      settxtError("start time must be greater than end time");
      return;
    }
    setShowNext(false);

    let timeZone = data?.timezone;
    if(!timeZone) {
      timeZone = getTimeZoneUtc();
    } else {
      if (typeof data?.timezone === 'object' && 'value' in data?.timezone) {
        timeZone = data?.timezone.value;
      } 
    }

    let params = {
      eventType: eventTypeSelected,
      at: isAllTime
        ? formatedDateToSend
        : formatedDateToSend + " " + startDateSelected?.value,
      duration: dateDiffHour + " h",
      tags: tempLabels?.join(","),
      presentationText: JSON.stringify(
        convertToRaw(eventDescription.getCurrentContent())
      ),
      register: data?.eventUrl,
      title: data?.eventTitle,
      oemProgram: currentProgramPage?.program?.id,
      beginAt: isAllTime ? "" : data?.startTime,
      endAt: isAllTime || isEmptyEndDate ? "" : data?.endTime,
      country: data?.country || "",
      city: data?.city || "",
      timezone: timeZone,
    };
    setParamsEvent(params);
    setShowPaymentModal(true);
  };

  useEffect(() => {
    if (loaderCreateEvent) {
      setloaderCreateEvent(false);
    }

    if (isErrorInfo) {
      setisErrorInfo(false);
      settxtError("");
    }

    return () => {};
  }, [showNext]);

  const isSelected = (
    data: Array<{ label: string; value: string }>,
    value: string
  ): boolean => {
    return data?.find((el: any) => el?.value === value) ? true : false;
  };

  const handleCreateEvent = () => {
    if (isConnected) {
      //Is isv page not completed
      const showCompleteInfoAlert =
        userReducer?.roles?.[0] === ISV &&
        !userReducer?.isvInCompanyPage?.company_page?.completed;

      //Is investor page not valide
      const investorNotValidePage =
        userReducer?.roles?.[0] === INVESTOR &&
        !companyInvestorPage.validate_by_devinsider;

      if (showCompleteInfoAlert) {
        setAllText([
          "Oops...",
          "",
          "You must be affiliated to a company page in order to publish an event",
        ]);
        setPermissionModal(true);
        return;
      }

      if (investorNotValidePage) {
        setAllText([
          "Oops...",
          "",
          "Please publish your investor page to access publish an event",
        ]);
        setPermissionModal(true);
        return;
      }
      setShowPaymentModal(true);
      setIsPriceInfo(true);
    } else {
      setShowModalLogin(true);
    }
  };

  const handleSuccessPayement = () => {
    setIsSuccess(!isSuccess);
    setInvoiceNumber("");
  };

  const handleErrorPayement = () => {
    setIsPayementError(!isPayementError);
  };

  const continuePublish = async (
    Pid: any,
    choiseValue: any,
    savePaymentMethod: boolean | undefined
  ) => {
    try {
      setloaderCreateEvent(true);
      let response = await postDataFormDataService(
        {
          ...paramsEvent,
          paymentId: Pid || "",
          choice: choiseValue,
          savePaymentMethod,
        },
        url?.events?.create_event,
        userReducer?.token
      );
      setloaderCreateEvent(false);
      reset();
      if (isAllTime) {
        setisAllTime(false);
      }
      setStartDate(new Date(Date.now() + 3600 * 1000 * 24));
      setEventDescription(EditorState.createEmpty());
      setTxtFilter("");
      setDataLabelFilter([]);
      return response;
    } catch (error) {}
  };

  const getPlanDetails = async () => {
    setChargingPlanDetails(true);
    const response = await getDataWithoutToken(
      url.settings.billingCenter + "/information/plan_details",
      { month: currentMonth, oem: oemProgram.id },
      user.token
    );

    if (response?.status === 200) {
      const dynamicKey = isIsv
        ? "isv_free_event"
        : isExpert
        ? "expert_free_event"
        : isReseller
        ? "reseller_free_event"
        : isInvestor
        ? "investor_free_event"
        : isOem
        ? "oem_program_free_event"
        : "isv_free_event";
      const freeNumberStatus: any = response?.data?.free_status[dynamicKey];
      setFreeNumber(freeNumberStatus?.number_left || 0);
    }
    setChargingPlanDetails(false);
  };

  React.useEffect(() => {
    if (user.token) {
      getPlanDetails();
    }
  }, [currentMonth, user.token]);

  useEffect(() => {
    if (isErrorInfo) {
      setisErrorInfo(false);
    }
  }, [startTime]);

  const paymentMethodCallBack = async (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod: boolean | undefined,
    setShowPaymentStatus: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber: string;
        invoiceUrl: string;
      }
    ) => void
  ) => {
    const choiseValue = isFree ? "FREE" : "PAY";
    const responseEvents = await continuePublish(
      paymentMethodId || "",
      choiseValue,
      savePaymentMethod
    );

    setLoading(false);

    console.log("responseEvents", responseEvents);

    if (responseEvents?.data) {
      if (choiseValue === "PAY") {
        setShowPaymentStatus(true, {
          status: responseEvents?.data?.success ? "success" : "error",
          invoiceNumber: responseEvents?.data?.invoice_number,
          invoiceUrl: responseEvents?.data?.invoice_url,
        });
      }
      if (choiseValue === "FREE") {
        setShowPaymentModal(false);
        getMyEventsFunction();
      }
    } else {
      setShowPaymentModal(false);
      setShowPaymentStatus(true, {
        status: "error",
        invoiceNumber: "",
        invoiceUrl: "",
      });
    }
  };

  const priceInfoCallback = () => {
    setIsPriceInfo(false);
    setShowPaymentModal(false);
    setShow(true);
  };

  const handleStatusOk = () => {
    getMyEventsFunction();
  };

  const cancelCreate = () => {
    setStartDate(new Date(Date.now() + 3600 * 1000 * 24));
    reset({});
    setShowNext(false);
    setEventDescription(EditorState.createEmpty());
    setTxtFilter("");
  };

  return {
    oemProgram,
    goToMyEvents,
    goToAllEvents,
    eventTypeSelected,
    startDate,
    showNext,
    setShowNext,
    setStartDate,
    setEventTypeSelected,
    register,
    handleSubmit,
    errors,
    onSubmit,
    loaderCreateEvent,
    getValues,
    isInMyEvents: isFound !== -1 ? true : false,
    isShowEndTime,
    setisShowEndTime,
    isAllTime,
    setisAllTime,
    isErrorInfo,
    txtError,
    isSelected,
    continuePublish,
    handleCreateEvent,
    eventObject,
    show,
    activeCategorie,
    setShow: toogleModal,
    setactiveCategorie,
    toogleActiveCategorie,
    userReducer,
    isGuest,
    isIsv,
    isReseller,
    isInvestor,
    isOem,
    showModalLogin,
    setShowModalLogin,
    showWhitoutCard,
    setShowWhitoutCard,
    isPayementError,
    setIsPayementError,
    isSuccess,
    setIsSuccess,
    handleSuccessPayement,
    invoiceNumber,
    handleErrorPayement,
    isExpert,
    allText,
    setAllText,
    showPermissionModal,
    setPermissionModal,
    showPaymentModal,
    setShowPaymentModal,
    freeNumber,
    setFreeNumber,
    paymentMethodCallBack,
    priceInfoCallback,
    handleStatusOk,
    isPriceInfo,
    setIsPriceInfo,
    control,
    eventDescription,
    setEventDescription,
    isValid,
    handleFilterSelect,
    handleSelectedLabels,
    txtFilter,
    setTxtFilter,
    dataLabelFilter,
    defaultDataLabel,
    cancelCreate,
    isFocused,
    setIsFocused,
  };
};
