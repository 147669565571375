import { getDataParam } from "../../../service/api";
import { deleteMethode, getDataService, postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import store from "../../store";
import { Dispatch } from "../../types/acount-interface"

export const constante = {
    GET_ALL_ALTERNATIVES: "GET_ALL_ALTERNATIVES",
    APPEND_ALL_ALTERNATIVES: "APPEND_ALL_ALTERNATIVES",
    MY_ALTERNATIVES: "MY_ALTERNATIVES",
    ALTERNATIVE: "ALTERNATIVE",
    ADD_FAVORITE_ALTERNATIVE: "ADD_FAVORITE_ALTERNATIVE",
    FAVORITE_ALTERNATIVE: "FAVORITE_ALTERNATIVE",
}

export const getAllAlternatives = (
    params: any,
    callback: (data: any) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            let response = await getDataService(
                url?.alternative?.getAlternative,
                params,
                store && store?.getState()?.userReducer?.token
            );
            if(response?.status === 200) {
                dispatch({
                    type: constante.GET_ALL_ALTERNATIVES,
                    payload: response?.data,
                });
            }
            callback(response);
        } catch (error: any) {}
    };
}

export const getMyAlternatives = (
    params: any,
    callback: (data: any) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            let response = await getDataParam(
                url?.alternative?.getAlternative,
                params,
                store && store?.getState()?.userReducer?.token
            );
            if(response?.status === 200) {
                dispatch({
                    type: constante.MY_ALTERNATIVES,
                    payload: response?.data,
                });
            }
            callback(response);
        } catch (error: any) {}
    };
}

export const getDetailAlternative = (
    params: any,
    callback: (data: any) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            let response = await getDataService(
                url?.alternative?.getAlternative+"/"+params,
                {},
                store && store?.getState()?.userReducer?.token
            );
            if(response?.status === 200) {
                dispatch({
                    type: constante.ALTERNATIVE,
                    payload: response?.data,
                });
            }
            callback(response);
        } catch (error: any) {}
    };
}

export const addFavoriteAlternative = async (
    params: any,
    callback: (data: any) => void
) => {
    try {
        let response = await postJsonDataService(
            url?.alternative?.addFavorite,
            params,
            store && store?.getState()?.userReducer?.token
        );
        if(response?.status === 200) {
            // dispatch({
            //     type: constante.ADD_FAVORITE_ALTERNATIVE,
            //     payload: response?.data,
            // });
            callback(response);
        } else {
            callback(null);
        }
    } catch (error: any) {callback(null);}
}

export const getFavoriteAlternatives = (
    params: any,
    callback: (data: any) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            let response = await getDataParam(
                url?.alternative?.getAlternative,
                params,
                store && store?.getState()?.userReducer?.token
            );
            if(response?.status === 200) {
                dispatch({
                    type: constante.FAVORITE_ALTERNATIVE,
                    payload: response?.data,
                });
            }
            callback(response);
        } catch (error: any) {}
    };
}

export const deleteAlternativeFinancing = async (id: any, callback: any) => {
    try {
        deleteMethode(
            url?.alternative?.addAlternative+"/"+id,
            store && store?.getState()?.userReducer?.token
        );
      callback(true);
  } catch (error: any) {
    callback(false);
  }
}