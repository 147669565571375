import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import {getDataMicroService} from "../../../../service/applicatif/back-end-service";
import { CONTRIBUTOR, GUEST, INVESTOR, ISV, OEM_PROGRAM, url } from "../../../../service/constant";
import {IReceipt} from "./transaction-type";
import {toast} from "react-toastify";
type IProps = {
  tabType: string;
};
export default function UseTransactionHistory(props: IProps) {
  const companyIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const resellerCompanyReducer = useSelector(
    (state: rootState) => state.userReducer?.resellerCompanies
  );
  const [loaderReceipt, setLoaderReceipt] = useState<boolean>(false);

  const investorPageObject = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const userObject = useSelector((state: rootState) => state.userReducer);

  const userType = userObject.roles?.filter((el) => el !== "USER_ROLE")?.[0];

  const guestCompanyReducer = useSelector(
    (state: rootState) => state.userReducer?.guestCompany
  );

  const expertCompanyReducer = useSelector(
    (state: rootState) => state?.userReducer?.expertCompany
  );

  
  let companyId: string =
    userType === ISV
      ? companyIsv.id
      : userType === OEM_PROGRAM
      ? oemProgramObject.id
      : userType === INVESTOR
      ? investorPageObject.id
      : userType === GUEST
      ? guestCompanyReducer.id
      : userType === CONTRIBUTOR
      ? expertCompanyReducer.id
      : resellerCompanyReducer?.[0]?.id;

  const [receipt, setReceipt] = useState<{
    items: Array<IReceipt>;
    meta: {
      nb_item: number;
      pages: number;
      pagination: number;
      total_count: number;
    };
  }>();
  // const currentYear = new Date().getFullYear();
  // const [paramsReceipt, setParamsReceipt] = useState<{
  //   begin: string;
  //   end: string;
  //   page: number;
  //   limit: number;
  //   companyId: string;
  // }>({
  //   begin: currentYear + "-01-01",
  //   end: moment().format("YYYY-MM-DD"),
  //   page: 1,
  //   limit: 10,
  //   companyId: companyId,
  // });

  const getReceipt = async (p?: {
    page: number,
    size: number
  }) => {
    setLoaderReceipt(true);
    getDataMicroService(
        url.microservice.payment.get_user_invoices + '?paid' + (p ? ('&page=' + p.page + '&page_size=' + p.size) : ''),
        userType === OEM_PROGRAM
            ? { oem_program_id: companyId }
            : {},
        userObject.token
    )
        .then(response => {
          if (response.data.message === 'success') {
            setReceipt({
              items: response.data.data.invoices,
              meta: response.data.data.meta
            })
          } else {
            throw response.data.data.detail
          }
        })
        .catch(error => {
          console.log(error)
          toast('Error getting receipts')
        })
        .finally(() => {
          setLoaderReceipt(false);
        })
  };

  useEffect(() => {
    async function getReceiptInfo() {
      setLoaderReceipt(true);
      await getReceipt({
        page: 1,
        size: 5
      })
    }
    props?.tabType === "receipts_list" && getReceiptInfo();
    return () => {};
  }, [props?.tabType]);

  const handleClickReceipt = async (page: number) => {
    await getReceipt({
      size: 5,
      page: page
    });
  };

  return {
    handleClickReceipt,
    loaderReceipt,
    receipt,
    userObject,
  };
}
