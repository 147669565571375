import moment from "moment";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg";
import { asyncPostPhoto } from "../../../../../service/api";
import constants, {
  CONTRIBUTOR,
  MODERATOR,
  url,
} from "../../../../../service/constant";
import {
  concatString,
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  getUserType,
  isModerator,
  refaCtoNullKey,
} from "../../../../../utils";
import { LoadingArticle } from "../../../../widget/loader";
import Reply from "../../../../widget/reply-wysiwig/index";
import { likePost } from "../../../../../redux/actions/community-action";

type Iprops = {
  idReplyShowWysiwyg: string | undefined;
  setidReplyParentDiscution: () => void;
  cancelReply: () => void;
  postReply: () => void;
  stateFormWysywig: any;
  loader: boolean;
  setEditorStatePost: (data: any) => void;
  addToFavorite: () => void;
  loaderFavorite: boolean;
  likedPost: () => void;
  loaderLike: boolean;
  isShowTools: boolean;
  editParentPost: (p: string) => void;
  removeParentPost: (p: string) => void;
  //Dev #47878 [Moderator account] Il faut qu'il y ait 2 options de suppression de post.
  totalRemoveParentPost: (p: string) => void;
  isParentEditMode: boolean;
  loaderFirstReply: boolean;
  showActivity: (user: any) => void;
  isProtectedReply: boolean;
  isParentReply: boolean;
  goToLabelCategory: (idCategory: string, p: string, postType: string) => void;
  handleDeletedMessage: any;
  //Bug #47415 Visibility setting display in community
  renderForVisibility: any;
  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  mention: Array<any>;
  updateMention: (value: any) => void;
  currentUser: any;
  pinParentPost: (p: string) => void;
  lockParentPost: (p: string) => void;
  titlePost: string;
  setTitlePost: (p: string) => void;
};
const ParentPost = (props: Iprops) => {
  const dispatch = useDispatch();

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  let currentPost = communityReducer?.curentDiscutionReplyPost?.currentPost;
  let isEditMode =
    props.idReplyShowWysiwyg?.toString() === currentPost?.id?.toString();

  const isShowWysiwyg =
    props?.isParentEditMode || (isEditMode && props.isParentReply);

  if (isShowWysiwyg && !props?.titlePost)
    props?.setTitlePost(
      communityReducer?.curentDiscutionReplyPost?.currentPost?.title
    );

  const isUserPost = () =>
    communityReducer?.curentDiscutionReplyPost?.currentPost?.user?.id?.toString() ===
    userReducer?.id?.toString();

  const pinnedAndLocked = () => {
    return (
      (isUserPost() || isModerator(userReducer?.roles)) &&
      !communityReducer?.curentDiscutionReplyPost?.currentPost?.isDeleted
    );
  };

  const handleLike = () => {
    if (!props.loaderLike) {
      dispatch(likePost({ post: currentPost.id }, (response) => {
        if(response.status === 200) {}
      }));
    }
  };

  const typeDiscussion = communityReducer?.postType?.find(
    (el: any) =>
      el?.id?.toString() ===
      communityReducer?.curentDiscutionReplyPost?.currentPost?.post_type?.id?.toString()
  )?.id;

  const isDiscussion = typeDiscussion?.toString() === "1";

  const isArticle = typeDiscussion?.toString() === "2";

  const txtPost = isDiscussion ? "Community" : isArticle ? "Article" : "";

  const uploadCallback = (file: string | Blob) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      asyncPostPhoto(
        formData,
        url.post.upload_file_url +
          txtPost?.replace(/\s/g, "_").replace(/\W/g, "").toLowerCase(),
        userReducer.token
      )
        .then((response: { data: string }) => {
          resolve({ data: { link: constants.baseUrl + response.data } });
        })
        .catch((error: any) => {
          if (error.response) {
            const errorMessage = `Error ${error.response.status}: ${error.response.data}`;
            reject(errorMessage);
          } else if (error.request) {
            const errorMessage = "No response";
            reject(errorMessage);
          } else {
            const errorMessage = `Request error: ${error.message}`;
            reject(errorMessage);
          }
        });
    });
  };

  return (
    <>
      {!props?.isParentEditMode && (
        <div>
          {props?.loaderFirstReply ? (
            <LoadingArticle />
          ) : (
            <div className="topicMaster reperefixed">
              {/* ADD BANNER LOCKED OR PINNED */}
              {communityReducer?.curentDiscutionReplyPost?.currentPost
                ?.isLocked &&
                pinnedAndLocked() && (
                  <img className="pinned-item" src={Locked} alt="" />
                )}
              {communityReducer?.curentDiscutionReplyPost?.currentPost
                ?.isPinned &&
                !communityReducer?.curentDiscutionReplyPost?.currentPost
                  ?.isLocked && (
                  <img className="pinned-item" src={Pin} alt="" />
                )}

              {communityReducer?.curentDiscutionReplyPost?.currentPost
                ?.isPinned &&
                communityReducer?.curentDiscutionReplyPost?.currentPost
                  ?.isLocked &&
                pinnedAndLocked() && (
                  <img className="pinned-item" src={Locked} alt="" />
                )}

              {communityReducer?.curentDiscutionReplyPost?.currentPost
                ?.isPinned &&
                communityReducer?.curentDiscutionReplyPost?.currentPost
                  ?.isLocked &&
                !pinnedAndLocked() && (
                  <img className="pinned-item" src={Pin} alt="" />
                )}
              <div className="badgeSubject">
                <div className="badgePoster">
                  <div className="withPost">
                    <div className="imageAffiliatedcompany empty">
                      {currentPost && (
                        <img
                          src={fileUrl(currentPost?.user?.file_name)}
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      className={`nameOfPost ${getUserType(
                        currentPost?.user?.roles?.[0]
                      )}`}
                    />
                  </div>
                  <div className="infoAffiliated">
                    <h3
                      className="title"
                      onClick={() => props?.showActivity(currentPost?.user)}
                    >
                      {concatString(
                        currentPost?.user?.first_name,
                        currentPost?.user?.last_name,
                        " "
                      )}
                    </h3>
                    <div className="post">{currentPost?.user?.job_title}</div>
                    <b className="post">{currentPost?.user?.company_name}</b>
                  </div>
                </div>
                {/*Miaro ticket 47378 <span className="btm" id="scrollToHere">
                  Subject
                </span>*/}
              </div>
              <div className="topicSubject">
                {(props?.isShowTools || isModerator(userReducer.roles)) &&
                  currentPost?.isDeleted === false && (
                    <div className="menudrop">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="icon-inprogress" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              props?.editParentPost(
                                communityReducer?.curentDiscutionReplyPost
                                  ?.currentPost?.id
                              )
                            }
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              props?.removeParentPost(
                                communityReducer?.curentDiscutionReplyPost
                                  ?.currentPost?.id
                              )
                            }
                          >
                            Remove
                          </Dropdown.Item>

                          {props?.currentUser.roles.indexOf(MODERATOR) !==
                            -1 && (
                            <Dropdown.Item
                              onClick={() =>
                                props?.totalRemoveParentPost(
                                  communityReducer?.curentDiscutionReplyPost
                                    ?.currentPost?.id
                                )
                              }
                            >
                              Totally remove
                            </Dropdown.Item>
                          )}
                          {props?.currentUser.roles.indexOf(MODERATOR) !==
                            -1 && (
                            <Dropdown.Item
                              onClick={() =>
                                props?.pinParentPost(
                                  communityReducer?.curentDiscutionReplyPost
                                    ?.currentPost?.id
                                )
                              }
                            >
                              {communityReducer?.curentDiscutionReplyPost
                                ?.currentPost?.isPinned
                                ? "Unpin post"
                                : "Pin post"}
                            </Dropdown.Item>
                          )}
                          {props?.currentUser.roles.indexOf(MODERATOR) !==
                            -1 && (
                            <Dropdown.Item
                              onClick={() =>
                                props?.lockParentPost(
                                  communityReducer?.curentDiscutionReplyPost
                                    ?.currentPost?.id
                                )
                              }
                            >
                              {communityReducer?.curentDiscutionReplyPost
                                ?.currentPost?.isLocked
                                ? "Unlock"
                                : "Lock"}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                <div className="title">
                  <h2>{currentPost?.title}</h2>
                  <div className="date">
                    {currentPost?.created_at &&
                      moment(new Date(currentPost?.created_at)).format(
                        "MMM D, YYYY h:mm a"
                      )}
                  </div>
                </div>
                {currentPost?.isDeleted && (
                  <div className="deleted">
                    <span className="icon-warning alerticon"></span>
                    <h3 className="title"></h3>
                    {props.handleDeletedMessage(currentPost.deleted_by)}
                  </div>
                )}
                {!currentPost?.isDeleted && (
                  <div className="topic format-wysiwyg">
                    {currentPost?.description && (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          convertCommentFromJSONToHTML(
                            getDescriptionEditorState(
                              communityReducer?.curentDiscutionReplyPost
                                ?.currentPost?.description
                            )
                          )
                        )}
                      />
                    )}
                  </div>
                )}

                <div className="categContainer">
                  {currentPost?.category && (
                    <div className="category">
                      {currentPost?.category?.title}
                    </div>
                  )}
                  <div className="subCategory">
                    {currentPost?.labels?.map((el: any, index: number) => (
                      <span
                        key={index}
                        onClick={() => {
                          props.goToLabelCategory(
                            el?.category?.id,
                            el?.id,
                            currentPost.post_type?.id?.toString()
                          );
                        }}
                      >
                        {el?.title}
                      </span>
                    ))}
                    {currentPost?.tags?.map((el: any, index: number) => (
                      <span
                        key={index}
                        onClick={() => {
                          props.goToLabelCategory(
                            el?.category?.id,
                            el?.id,
                            currentPost.post_type?.id?.toString()
                          );
                        }}
                      >
                        {el?.name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="likeAndBtn">
                  <div
                    className="like"
                    onClick={() => !props?.loaderLike && handleLike()}
                  >
                    <a
                      className={currentPost?.liked ? "active" : ""}
                      onClick={(e) => e.preventDefault()}
                    >
                      <span
                        className={
                          communityReducer?.curentDiscutionReplyPost
                            ?.currentPost?.liked
                            ? "icon-like-full"
                            : "icon-like"
                        }
                      />
                      like
                    </a>
                    <b>{currentPost?.numberOfLikes}</b> Likes
                  </div>
                  {!isEditMode && !isModerator(userReducer.roles) && (
                    <div className="btnContentSubject">
                      <a
                        className={`${
                          currentPost?.isFavorited
                            ? "classFavorite"
                            : "classNotFavorite"
                        } addFav`}
                        onClick={(e) => {
                          e.preventDefault();
                          props?.addToFavorite();
                        }}
                      >
                        {props?.loaderFavorite ? (
                          <Loader />
                        ) : (
                          "Add to favorites"
                        )}
                      </a>

                      {!props?.isProtectedReply && (
                        <a
                          className="reply"
                          onClick={(e) => {
                            e.preventDefault();
                            props?.setidReplyParentDiscution();
                          }}
                        >
                          Reply
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isShowWysiwyg && (
        <div className="replyContentUser">
          <div className="badgePoster">
            <div className="withPost">
              <div className="imageAffiliatedcompany">
                <img
                  src={fileUrl(
                    props.renderForVisibility?.renderForVisibilityProfilPicture()
                  )}
                  alt=""
                />
              </div>
              <div
                className={"nameOfPost " + getUserType(userReducer?.roles?.[0])}
              ></div>
            </div>
            <div className="infoAffiliated">
              <h3 className="title">
                {props?.renderForVisibility?.renderForVisibilityUsername()}
              </h3>
              <div className="post">
                {refaCtoNullKey(
                  props.renderForVisibility?.renderForVisibilityJobTitle()
                )}
              </div>
              <b className="post">
                {" "}
                {refaCtoNullKey(
                  props.renderForVisibility?.renderForVisibilityCompanyName()
                )}
              </b>
            </div>
          </div>
          <div className="" style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter a subject..."
              className="form-control my-4"
              value={props?.titlePost}
              onChange={(event) => props?.setTitlePost(event?.target.value)}
              disabled
            />

            <Reply
              //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
              mention={props?.mention}
              updateMention={props?.updateMention}
              cancelPost={props?.cancelReply}
              sendPost={props?.postReply}
              editorState={props?.stateFormWysywig}
              loader={props?.loader}
              setEditorState={(data: any) => props?.setEditorStatePost(data)}
              uploadCallBack={(file: any) => uploadCallback(file)}
              isExpert={userReducer.roles.includes(CONTRIBUTOR)}
              uploadPostType={txtPost}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo((props: Iprops) => ParentPost(props));
