/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { useState } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "../complete-reseller.scss";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteCompanySolutionOffertReseller, {
  ISolutionListe,
  ItemsSolution,
  IdataMakeSolution,
} from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-solution-offert-reseller";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import { getSStorage } from "../../../../../utils";

export default function CompleteCompanySolutionOffertReseller() {
  const {
    navigateCompleteIndustrieTarget,
    solutionLeftColumnData,
    solutionRigthColumnData,
    loader,
    solutionChecked,
    allSolutionSellected,
    selectSolution,
    removeItemSelected,
    featuredSolution,
    errorData,
    goBack,
    setAllOtherValue,
    allOtherValue,
    setOtherValueTxt,
    newFilteredSolutionChecked,
  } = UseCompleteCompanySolutionOffertReseller();
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const renameOther = (sel: IdataMakeSolution) => {
    if (sel.nameSegment.toLowerCase() == "other") {
      const dataSource = JSON.parse(getSStorage("completed_account") || "[]");
      const category = dataSource?.segments?.find(
        (el: any) => el.id?.toString() === sel.idSolutionOffert?.toString()
      );
      return sel.nameSegment + "-" + category?.name;
    } else {
      return sel.nameSegment;
    }
  };

  return (
    <div>
      <Header />
      <div className="getstartedContainer largeContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
          </div>
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Company information</h1>
        </div>
        {loader && <Loader />}

        {!loader && (
          <div className="contentBorder border-top row pt-3 pb-4">
            <div className="form-group required col-xl-12 pr-xl-2 pr-md-2 p-0 m-0">
              <label htmlFor="">
                What solution does your company offer ? Select all that apply.
                {errorData && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select at least one category{" "}
                    </span>
                  </div>
                )}
              </label>
            </div>

            <div className="solutionChecked">
              {solutionChecked &&
                newFilteredSolutionChecked(solutionChecked).map(
                  (el: IdataMakeSolution) => (
                    <div
                      className="item"
                      onClick={() => removeItemSelected(el)}
                      key={el.idSegment}
                    >
                      {renameOther(el)}
                      <span className="icon-close"></span>
                    </div>
                  )
                )}
            </div>
            <div className="contentCheckboxsolution">
              <h2 className="title">
                {featuredSolution && featuredSolution.name}
              </h2>

              <div className="flex-checkbox">
                {featuredSolution &&
                  featuredSolution.segmentDetails.map(
                    (el: ItemsSolution, index: number) => (
                      <Form.Check
                        custom
                        key={index}
                        label={el.name}
                        id={el.id.toString()}
                        checked={
                          solutionChecked?.find(
                            (t: any) => t.idSegment == el.id
                          )
                            ? true
                            : false
                        }
                        onClick={() => {
                          selectSolution({
                            idSolutionOffert: featuredSolution.id,
                            idSegment: el.id,
                            nameSegment: el.name,
                          });
                        }}
                        onChange={() => null}
                      />
                    )
                  )}
              </div>

              <div className="listAccordionSolution">
                <Accordion defaultActiveKey={activeId}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                      {solutionLeftColumnData &&
                        solutionLeftColumnData.map(
                          (solution: ISolutionListe, index: number) => {
                            return (
                              <div
                                className={
                                  activeId == solution.id.toString()
                                    ? "panel-wrap active-panel"
                                    : "panel-wrap"
                                }
                                key={index}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() => toggleActive(solution.id)}
                                    className="panel-toggle "
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}{" "}
                                    {solutionChecked?.filter(
                                      (el: any) =>
                                        el.idSolutionOffert == solution.id
                                    ).length > 0
                                      ? `(${
                                          solutionChecked?.filter(
                                            (el: any) =>
                                              el.idSolutionOffert == solution.id
                                          ).length
                                        }  selected )`
                                      : ""}
                                  </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse
                                  eventKey={solution.id.toString()}
                                >
                                  <div className="panel-body">
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectSolution({
                                          idSolutionOffert: solution.id,
                                          solutionOffert:
                                            solution.segmentDetails,
                                        });
                                      }}
                                    >
                                      <Form.Check
                                        custom
                                        label="Select all"
                                        id="all"
                                        checked={
                                          allSolutionSellected &&
                                          allSolutionSellected?.find(
                                            (el: any) => el == solution.id
                                          )
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                        onClick={() => null}
                                        onChange={() => null}
                                      />
                                    </div>
                                    {solution.segmentDetails &&
                                      solution.segmentDetails.map(
                                        (
                                          solutionItems: ItemsSolution,
                                          index: number
                                        ) => {
                                          return (
                                            <>
                                              <Form.Check
                                                custom
                                                key={index}
                                                label={solutionItems.name}
                                                id={solutionItems.id.toString()}
                                                checked={
                                                  solutionChecked?.find(
                                                    (t: any) =>
                                                      t.idSegment ==
                                                      solutionItems.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  selectSolution({
                                                    idSolutionOffert:
                                                      solution.id,
                                                    idSegment: solutionItems.id,
                                                    nameSegment:
                                                      solutionItems.name,
                                                  });
                                                }}
                                                onChange={() => null}
                                              />
                                              {solutionItems.name?.toLocaleLowerCase() ===
                                                "other" &&
                                                solutionChecked?.find(
                                                  (t: any) =>
                                                    t.idSegment ==
                                                    solutionItems.id
                                                ) && (
                                                  <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="other"
                                                    value={
                                                      allOtherValue?.find(
                                                        (tempTxt) =>
                                                          tempTxt.idSegment.toString() ===
                                                          solutionItems.id.toString()
                                                      )?.otherTxt || ""
                                                    }
                                                    onChange={(evt: any) =>
                                                      setOtherValueTxt(
                                                        {
                                                          idSolutionOffert:
                                                            solution.id,
                                                          idSegment:
                                                            solutionItems.id,
                                                          nameSegment:
                                                            solutionItems.name,
                                                        },

                                                        evt.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            );
                          }
                        )}
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                      {solutionRigthColumnData &&
                        solutionRigthColumnData.map(
                          (solution: ISolutionListe, index: number) => {
                            return (
                              <div
                                className={
                                  activeId == solution.id.toString()
                                    ? "panel-wrap active-panel"
                                    : "panel-wrap"
                                }
                                key={index}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() =>
                                      toggleActive(solution.id.toString())
                                    }
                                    className="panel-toggle "
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}{" "}
                                    {solutionChecked?.filter(
                                      (el: any) =>
                                        el.idSolutionOffert == solution.id
                                    ).length > 0
                                      ? `(${
                                          solutionChecked?.filter(
                                            (el: any) =>
                                              el.idSolutionOffert == solution.id
                                          ).length
                                        }  selected )`
                                      : ""}
                                  </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse
                                  eventKey={solution.id.toString()}
                                >
                                  <div className="panel-body">
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectSolution({
                                          idSolutionOffert: solution.id,
                                          solutionOffert:
                                            solution.segmentDetails,
                                        });
                                      }}
                                    >
                                      <Form.Check
                                        custom
                                        label="Select all"
                                        id="all"
                                        checked={
                                          allSolutionSellected?.find(
                                            (el: any) => el == solution.id
                                          )
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                        onClick={() => null}
                                        onChange={() => null}
                                      />
                                    </div>

                                    {solution.segmentDetails &&
                                      solution.segmentDetails.map(
                                        (
                                          solutionItems: ItemsSolution,
                                          index: number
                                        ) => {
                                          return (
                                            <>
                                              <Form.Check
                                                custom
                                                label={solutionItems.name}
                                                id={solutionItems.id.toString()}
                                                checked={
                                                  solutionChecked?.find(
                                                    (t: any) =>
                                                      t.idSegment ==
                                                      solutionItems.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                key={index}
                                                onClick={() => {
                                                  selectSolution({
                                                    idSolutionOffert:
                                                      solution.id,
                                                    idSegment: solutionItems.id,
                                                    nameSegment:
                                                      solutionItems.name,
                                                  });
                                                }}
                                                onChange={() => null}
                                              />
                                              {solutionItems.name?.toLocaleLowerCase() ===
                                                "other" &&
                                                solutionChecked?.find(
                                                  (t: any) =>
                                                    t.idSegment ==
                                                    solutionItems.id
                                                ) && (
                                                  <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="other"
                                                    value={
                                                      allOtherValue?.find(
                                                        (tempTxt) =>
                                                          tempTxt.idSegment.toString() ===
                                                          solutionItems.id.toString()
                                                      )?.otherTxt || ""
                                                    }
                                                    onChange={(evt: any) =>
                                                      setOtherValueTxt(
                                                        {
                                                          idSolutionOffert:
                                                            solution.id,
                                                          idSegment:
                                                            solutionItems.id,
                                                          nameSegment:
                                                            solutionItems.name,
                                                        },

                                                        evt.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        )}

        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateCompleteIndustrieTarget()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
