import store from "../../store";
import { Dispatch } from "../../types/event-interface";

export const constante = {
  MARK_AS_READ: "MARK_AS_READ",
  // MARK_ALL_AS_READ: "MARK_ALL_AS_READ",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
};

export const addNotification = (numberUnread: number, notification: any) => {
  return {
    type: constante.ADD_NOTIFICATION,
    payload: {
      numberUnread: numberUnread,
      notifications: notification,
    },
  };
};

export const markNotificationAsRead = (notificationId: number) => {
  return {
    type: constante.MARK_AS_READ,
    payload: notificationId,
  };
};

// export const markAllNotificationAsRead = () => {
//   return {
//     type: constante.MARK_ALL_AS_READ
//   };
// };
