import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  handleUpdatePlanDetail,
  handleUpdateSubscriptionDates,
} from "../../../redux/actions/oem-program-action";
import { rootState } from "../../../redux/reducers";
import { postData } from "../../../service/api";
import { url } from "../../../service/constant";
import UseGlogalBilling, { ICardInformation } from "./use-global-billing";

export default function UseSubscriptionPlanCtr() {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const program = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );
  const dispatch = useDispatch();

  const userToken = userReducer.token;
  const history = useHistory();
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [showPurchaseForm, setShowPurchaseForm] = useState<boolean>(false);
  const [showCardRegistrationForm, setShowCardRegistrationForm] =
    useState<boolean>(false);
  const [showPaymentSuccessfullModal, setShowPaymentSuccessfullModal] =
    useState<boolean>(false);
  const [showPaymentErrorModal, setShowPaymentErrorModal] =
    useState<boolean>(false);
  const [settingSetupIntentProcess, setSettingSetupIntentProcess] =
    useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [doneProcessing, setDoneProcessing] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(userReducer.first_name);
  const [lastName, setLastName] = useState<string>(userReducer.last_name);
  const [email, setEmail] = useState<string>(userReducer.email);
  const [programName, setProgramName] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [planList, setPlanList] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >([
    {
      value: "Premium",
      label: "Premium Plan",
    },
    {
      value: "Custom",
      label: "Custom Plan",
    },
  ]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();

  const [sendingSubscription, setSendingSubscription] =
    useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState<string>("");

  useEffect(() => {}, [invoiceNumber]);

  const globalSettingProvider = UseGlogalBilling();
  const [cardInformation, setCardInformation] = useState<ICardInformation>({
    brand: "",
    exp_month: "",
    exp_year: "",
    last4: "",
  });

  const goToPurchase = () => {
    if (
      program.payment_method != null &&
      program.payment_method != undefined &&
      program.payment_method != ""
    ) {
      setShowPurchaseForm(true);
    } else {
      setShowCardRegistrationForm(true);
    }
  };

  const goToPaymentModal = () => {
    const lookupKey = "OEM_PREMIUM";

    setProduct({
      lookupKey: lookupKey,
      name: "Premium subscription",
    });

    setShowPaymentModal(true);
  };

  const goToSettings = () => {
    history.push("/settings", { tabToShow: "billing" });
  };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    setProgramName(program.program_name);
    setPaymentMethod(program.payment_method);
  }, []);

  const postPremiumPlan = async () => {
    setSettingSetupIntentProcess(true);
    const params = {
      paymentId: "",
      oemProgram: program?.id,
      amount: 1500,
    };
    const response = await postData(
      url.subscription_plan.postPremium,
      params,
      userReducer.token
    );

    if (response?.status == 200 && response?.data?.success) {
      setShowPurchaseForm(false);
      if (response.data?.invoice_number) {
        dispatch(handleUpdatePlanDetail(response.data?.subscription));
        dispatch(
          handleUpdateSubscriptionDates({
            key: "subscription_begin_at",
            data: response.data?.subscription_dates.subscription_begin_at,
          })
        );
        dispatch(
          handleUpdateSubscriptionDates({
            key: "subscription_end_at",
            data: response.data?.subscription_dates.subscription_end_at,
          })
        );
        setInvoiceNumber(response.data?.invoice_number);
        setShowPaymentSuccessfullModal(true);
      }
    } else {
      setShowPaymentErrorModal(true);
      setShowPurchaseForm(false);
    }
    setSettingSetupIntentProcess(false);
  };

  const paymentMethodCallBack = async (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod: boolean | undefined,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    const params = {
      paymentId: paymentMethodId,
      oemProgram: program?.id,
      amount: 1500,
      savePaymentMethod: savePaymentMethod,
    };
    const response = await postData(
      url.subscription_plan.postPremium,
      params,
      userReducer.token
    );
    console.log(response);
    if (response?.status == 200 && response?.data?.success) {
      if (response.data?.invoice_number) {
        dispatch(handleUpdatePlanDetail(response.data?.subscription));
        dispatch(
          handleUpdateSubscriptionDates({
            key: "subscription_begin_at",
            data: response.data?.subscription_dates.subscription_begin_at,
          })
        );
        dispatch(
          handleUpdateSubscriptionDates({
            key: "subscription_end_at",
            data: response.data?.subscription_dates.subscription_end_at,
          })
        );
        setShowPaymentStatus &&
          setShowPaymentStatus(true, {
            status: "success",
            invoiceNumber: response?.data?.invoice_number,
            invoiceUrl: response?.data?.invoice_url,
          });
      }
    } else {
      setShowPaymentStatus &&
        setShowPaymentStatus(true, {
          status: "error",
        });
    }

    setLoading(false);
  };

  return {
    showContactForm,
    setShowContactForm: (show: boolean) => setShowContactForm(show),
    planList,
    setPlanList,
    programName,
    setProgramName,
    programId: program.id,
    handleSubscriptionSubmit: (attribute: any) => {
      setSendingSubscription(true);
      //send data to backend
      (async () => {
        let response = await postData(
          url.oem.request_subscription,
          attribute,
          userToken
        );
        if (response.data === "") {
          setSendingSubscription(false);
          setShowContactForm(false);
          setDoneProcessing(true);
          toast("You request has been sent to Devinsider!");
        }
      })();
    },

    sendingSubscription,
    currentPlan,
    setCurrentPlan: (current: string) => setCurrentPlan(current),
    doneProcessing,
    setDoneProcessing,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    comments,
    setComments,
    userReducer,
    programDefault: program.program_name,
    showPurchaseForm,
    setShowPurchaseForm: (show: boolean) => setShowPurchaseForm(show),
    cardInformation,
    paymentMethod,
    goToPurchase,
    showCardRegistrationForm,
    setShowCardRegistrationForm,
    showPaymentSuccessfullModal,
    setShowPaymentSuccessfullModal,
    showPaymentErrorModal,
    setShowPaymentErrorModal,
    postPremiumPlan,
    settingSetupIntentProcess,
    invoiceNumber,
    setInvoiceNumber,
    goToSettings,
    showPaymentModal,
    setShowPaymentModal,
    goToPaymentModal,
    product,
    paymentMethodCallBack,
  };
}
