import React from "react";
import { fileUrl } from "../../../../../../utils";

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  profil_current_user: string;
  companyName_current_user: string;
  roleDiscussion_current: string;
};
export default function CommunityHeader(props: Iprops) {
  return (
    <>
      <div className="titleRepH">Classified ads</div>
      <div className="d-flex actorAndParam">
        <div className="d-flex align-items-center infoActor">
          <div className="photo-title">
            <img src={fileUrl(props.profil)} alt="profil" />
          </div>
          <div className="info-title">
            <div className="company-title">
              <a href="">{props?.companyName || "-"}</a>
            </div>
            <div className="company-type">{props?.roleDiscussion || "-"}</div>
          </div>
        </div>
        <div className="d-flex align-items-center infoActor">
          <div className="photo-title">
            <img src={fileUrl(props.profil_current_user)} alt="company" />
          </div>
          <div className="info-title">
            <div className="company-title">
              <a href="">{props?.companyName_current_user || "-"}</a>
            </div>
            <div className="company-type">
              {props?.roleDiscussion_current || "-"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
