import "./Header.scss";
import React, { useState, useEffect, useRef } from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import { oem_roles } from "../../../utils";
import ModalPermission from "../../widget/permission-oem/index";
import UsePersmission from "../../widget/permission-oem/use-persmission";
import { useDataContext } from "../../../context/index";
import * as Bloc from "./bloc-header-oem/index";
import BlockNotif from "../../widget/block-notif/index";

import PermissionMatching from "../permission-matching/index";
import UsePersmissionMatching from "../permission-matching/use-persmission";

import Modal from "react-bootstrap/Modal";
import { BlockMessage } from "../bloc-message-notif";
import { INVESTOR, MaMatchmakingUrl, OEM_PROGRAM } from "../../../service/constant";
import { Link } from "react-router-dom";
import Alert from "../../widget/alert-message/info";
import { useKeycloak } from "@react-keycloak/web";

export default function HeaderOemProgram(props: any) {
  const allText = [
    "Oops...",
    "",
    'Please publish your program page to access the "Find ISVs" matchmaking feature.',
  ];
  const { keycloak } = useKeycloak();

  const userState = useSelector((state: rootState) => state.userReducer);
  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );
  const { stopEvtSource } = useDataContext();
  let allProgram = userState?.oemProgramMembers;
  const { showPermission, handLePersmission } = UsePersmission();
  const [showModalProgramDeactivated, setShowModalProgramDeactivated] =
    useState<boolean>(false);

  const providerPermissionMatching = UsePersmissionMatching(allText);

  const [stateBody, setStateBody] = useState(false);

  const [modalBlocage, setModalBlocage] = useState<boolean>(false);
  const [modalBlockedFeature, setModalBlockedFeatured] =
    useState<boolean>(false);

  const isAcceptedMatching =
    userState?.roles?.[0] === OEM_PROGRAM || userState?.roles?.[0] === INVESTOR;

  const history = useHistory();
  const dispatch = useDispatch();

  const goToFindIsv = () => {
    const curretRole = currentOemProgram.program.oemProgramMembers?.find(
      (oem) => oem?.user?.id?.toString() === userState?.id?.toString()
    );
    let isEditor = curretRole?.role === oem_roles?.editor;
    if (isEditor) {
      handLePersmission();
      return;
    }
    if (currentOemProgram?.program?.uuid === "") {
      setShowModalProgramDeactivated(true);
      return;
    }
    const isProgramValidate = !currentOemProgram.program.validate_by_devinsider;
    if (isProgramValidate) {
      providerPermissionMatching.hanleShow();
      return;
    }

    if (
      !currentOemProgram.program.status ||
      currentOemProgram.program.uuid === ""
    ) {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publish your program page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: showProgramPage,
      });
      return;
    }
    if (
      currentOemProgram.program.status &&
      (!isAcceptedMatching || !currentOemProgram.program.validate_by_devinsider)
    ) {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publish your program page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: showProgramPage,
      });
      return;
    }

    history.push("/isv/match");
  };

  const goLogin = () => {
    history.push("/signin");
  };
  const showProfil = () => {
    history.push("/profil");
  };

  const goToSettings = () => {
    history.push("/settings", { tabToShow: "d-account" });
  };

  const cb = async (params: boolean) => {
    if (params) {
      stopEvtSource();
      await keycloak.logout();
      // history.push("/signin");
    }
  };
  const logOut = () => {
    dispatch(logout(cb));
  };

  const goToCommunityHome = () => {
    history.push("/community/home");
  };

  const showProgramPage = () => {
    history.push("/OEM/profil/" + currentOemProgram?.program?.id);
  };

  const showGetStarted = () => {
    history.push("/account-choices");
  };

  const openWindowProgram = () => {
    const el: any = document.querySelector<HTMLElement>(".listOfProgramFixed");
    if (stateBody === false) {
      setStateBody(true);
      el.classList.add("openMenu");
    } else {
      setStateBody(false);
      el.classList.remove("openMenu");
    }
  };

  const showConversation = () => {
    history.push("/contact/discussion");
  };

  const urlPath = window.location.href;
  let valueofSubmenu: string = "";
  urlPath.indexOf("match") !== -1
    ? (valueofSubmenu = "match")
    : urlPath.indexOf("profil-locked") !== -1
    ? (valueofSubmenu = "match")
    : urlPath.indexOf("profil") !== -1
    ? (valueofSubmenu = "profil")
    : urlPath.indexOf("isv") !== -1
    ? (valueofSubmenu = "isv")
    : urlPath.indexOf("programs") !== -1
    ? (valueofSubmenu = "programs")
    : urlPath.indexOf("events") !== -1
    ? (valueofSubmenu = "events")
    : urlPath.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : urlPath.indexOf("classified-ads") !== -1
    ? (valueofSubmenu = "classified-ads")
    : (valueofSubmenu = "neant");

  const createNewProgram = () => {
    const curretRole = currentOemProgram.program?.oemProgramMembers?.find(
      (oem) => oem?.user?.id?.toString() === userState?.id?.toString()
    );

    if (curretRole) {
      let isNotProgramOwner = curretRole.role !== oem_roles?.owner;

      if (isNotProgramOwner) {
        handLePersmission();
        return;
      }
      history.push("/complete-website-location-oem");
    }
  };

  const goToBillings = () => {
    history.push("/settings", {
      tabToShow: "billing",
    });
  };

  const goToSubscriptionPlan = () => {
    history.push("/billing-center/subscription-plan");
  };

  const goToHomePage = () => {
    history.push("/");
  };
  const goToHomeAlternative = () => {
    history.push("/alternative-financing/home");
  };
  const goTomanda = () => {
    history.push("/ma/home");
  };

  const goToFindMore = () => {
    const curretRole = currentOemProgram.program.oemProgramMembers?.find(
      (oem) => oem?.user?.id?.toString() === userState?.id?.toString()
    );
    let isEditor = curretRole.role === oem_roles?.editor;
    if (isEditor) {
      handLePersmission();
      return;
    }
    if (
      !currentOemProgram.program.status ||
      currentOemProgram.program.uuid === ""
    ) {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publisheses your program page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: showProgramPage,
      });
      return;
    }
    if (
      currentOemProgram.program.status &&
      (!isAcceptedMatching || !currentOemProgram.program.validate_by_devinsider)
    ) {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publish your program page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: showProgramPage,
      });
      return;
    }

    history.push("/isv/find-more");
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const listOfProgramFixedRef = useRef<HTMLDivElement>(null);

  const closeSidebarOnClickOutside = (event: MouseEvent) => {
    if (
      listOfProgramFixedRef.current &&
      !listOfProgramFixedRef.current.contains(event.target as Node)
    ) {
      const el: any = document.querySelector<HTMLElement>(
        ".listOfProgramFixed"
      );
      if (stateBody === true) {
        setStateBody(false);
        el.classList.remove("openMenu");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebarOnClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeSidebarOnClickOutside);
    };
  }, [listOfProgramFixedRef, stateBody]);

  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <div className="logoHeader">
            <img
              src={logoLettre}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => goToHomePage()}
            />
          </div>
          <div className="menuDynamic tl justForProgram">
            <div className="item">
              <a href="javascript:;" className="linkHeader borRight">
                Find ISV<span className="last_letter">s</span>
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Find ISVs</span>
                    Identify and contact software vendors (ISVs) that meet your
                    target audience
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    className="linkHeader"
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToFindIsv();
                    }}
                  >
                    <span className="name">ISV matchmaking</span>
                    <p>Match with ISVs based on your program targeting.</p>
                  </a>

                  <a
                    className="linkHeader"
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToFindMore();
                    }}
                  >
                    <span className="name">Find more</span>
                    <p>Tap into our network of ISVs outside of Devinsider.</p>
                  </a>

                  <Link
                    className="linkHeader"
                    to={`/OEM/profil/${currentOemProgram?.program?.id}`}
                  >
                    <span className="name">Program page</span>
                    <p>
                      Manage your program page and set up your matchmaking
                      criteria.
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="item">
              <Link
                to="/community/home"
                className={`parent linkHeader borRight${
                  valueofSubmenu === "community" ? "active" : ""
                }`}
              >
                Community
              </Link>
              <Bloc.BlockCommunity goToCommunityHome={goToCommunityHome} />
            </div>
            <div className="item">
              <Link
                to={{
                  pathname: `${
                    userState?.token ? "/ma/home" : "/landing-find-inv"
                  }`,
                }}
                className="linkHeader"
              >
                Corporate development
              </Link>

              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Corporate development</span>
                    Access a curated pool of investors that are looking for
                    software companies to invest in.
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goTomanda();
                    }}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">M&A ads</span>
                    <p>
                      Find exit opportunities among Devinsider’s global
                      community of software vendors and institutional investors.
                    </p>
                  </a>
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToHomeAlternative();
                    }}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">Alternative financing ads</span>

                    <p>
                      Identify financing options as an alternative to standard
                      equity investments and loans.
                    </p>
                  </a>
                  <a 
                      href={MaMatchmakingUrl} 
                      className="linkSubMenu linkHeader"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="name">M&A Matchmaking</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit. 
                      </p>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightItemHeader">
          {userState?.enable ? (
            <div className="menuDynamic tr justForProgram">
              <div className="item" onClick={showConversation}>
                <BlockMessage unread={props.unread} />
              </div>
              <BlockNotif />
              <Bloc.BlocAccountProfil
                goToSettings={goToSettings}
                goToBillings={goToBillings}
                logOut={logOut}
                showProfil={showProfil}
                userState={userState}
                goToSubscriptionPlan={goToSubscriptionPlan}
              />
              {userState?.oemProgramMembers?.length > 0 && (
                <div className="item special">
                  <a
                    href="javascript:;"
                    title={currentOemProgram.program?.program_name}
                    onClick={(e) => {
                      e.preventDefault();
                      openWindowProgram();
                    }}
                  >
                    <span className="icon-program-menu"></span>
                    <div className="nameProgram">
                      <span className="name">
                        {currentOemProgram.program?.program_name}
                      </span>
                      <span className="icon-sparow"></span>
                    </div>
                  </a>
                </div>
              )}
            </div>
          ) : (
            <Bloc.BlockAccount
              goLogin={goLogin}
              showGetStarted={showGetStarted}
            />
          )}
        </div>
      </div>
      <div
        className={`listOfProgramFixed ${isSidebarOpen ? "closed" : ""}`}
        ref={listOfProgramFixedRef}
      >
        <h2 className="title">
          Your programs
          <a
            href="javascript:;"
            onClick={(e) => {
              e.preventDefault();
              openWindowProgram();
            }}
          >
            <span className="icon-close"></span>
          </a>
        </h2>
        <Bloc.BlockProgramList
          allProgram={allProgram}
          createNewProgram={createNewProgram}
          currentOemProgram={currentOemProgram}
          openWindowProgram={openWindowProgram}
        />
        <ModalPermission
          show={showPermission}
          handleSkhow={handLePersmission}
        />
        <PermissionMatching {...providerPermissionMatching} />
      </div>
      {/* Miaro ticket 46657 */}
      <Modal show={modalBlocage} className="medium forDesactivate popPers">
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setModalBlocage(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              We are currently on-boarding ISVs for matchmaking and will release
              this feature soon. In the meantime check out <br />
              the <Link to="/community/home">community</Link> where you can
              engage with ISVs and share your expertise.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* MODAL FOR DEACTIVATED PROGRAM */}
      <Modal
        show={showModalProgramDeactivated}
        className="small forDesactivate popPers"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setShowModalProgramDeactivated(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              Your program is currently deactivated and cannot access the
              matching result.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* Bug #48516,access to matchmaking */}
      <Modal
        show={modalBlockedFeature}
        className="medium forDesactivate popPers"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setModalBlockedFeatured(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              You cannot use this featured while your program page remain
              unpublished, go to{" "}
              <a
                href="javascript:;"
                onClick={(e) => {
                  e.preventDefault();
                  setModalBlockedFeatured(false);
                  history.push("/OEM/profil/" + currentOemProgram.program.id);
                }}
              >
                setting
              </a>{" "}
              and <b>publish</b> your program to see the matching result.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
