import React from "react";
import Form from "react-bootstrap/Form";
import {
  IListTarget,
  IndustryTargetProps,
} from "../../../../../controller/company-ctr/types/types-company-page";

export default function TargetIndustry(props: IndustryTargetProps) {
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder">
        <div className="form-group">
          <label htmlFor="" className="pb-3 notBold">
            What industry do you target with your software solution(s)? Select
            all that apply.
            {props.errorData && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">select at least one category </span>
              </div>
            )}
          </label>

          <div className="contentTargetIndustry smallLabel">
            <div className="col4">
              {props.companyTarget &&
                props.companyTarget.map((element: IListTarget) => (
                  <Form.Check
                    key={element.id}
                    custom
                    checked={
                      props.companyTargetSelected?.find(
                        (ele: IListTarget) => ele.id == element.id
                      )
                        ? true
                        : false
                    }
                    label={element.name}
                    id={element.id.toString()}
                    onClick={() => {
                      props.addTargetCompany(element);
                    }}
                    onChange={() => {}}
                  />
                ))}
            </div>
            <div className="col4">
              {props.companyTargetSecound &&
                props.companyTargetSecound?.map((element: IListTarget) => (
                  <Form.Check
                    key={element.id}
                    custom
                    checked={
                      props.companyTargetSelected?.find(
                        (ele: IListTarget) => ele.id == element.id
                      )
                        ? true
                        : false
                    }
                    label={element.name}
                    id={element.id.toString()}
                    onClick={() => {
                      props.addTargetCompany(element);
                    }}
                    onChange={() => {}}
                  />
                ))}
            </div>
            <div className="col4">
              {props.lastCompanyTarget &&
                props.lastCompanyTarget.map((element: IListTarget) => (
                  <Form.Check
                    key={element.id}
                    custom
                    checked={
                      props.companyTargetSelected?.find(
                        (ele: IListTarget) => ele.id == element.id
                      )
                        ? true
                        : false
                    }
                    label={element.name}
                    id={element.id.toString()}
                    onClick={() => {
                      props.addTargetCompany(element);
                    }}
                    onChange={() => {}}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
