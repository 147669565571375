import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { rootState } from "../../../../../redux/reducers";
import { fileUrl, limitedText } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

type IProps = {
  setIsShowUpdateBackGroundPicture: (p: boolean) => void;
  setIsShowUpdateProfil: (p: boolean) => void;
  showPopupRemoveLogo: boolean;
  setShowPopupRemoveLogo: (p: boolean) => void;
  showPopupRemoveCouverture: boolean;
  setShowPopupRemoveCouverture: (p: boolean) => void;
  handleRemoveLogoCompanyPage: () => void;
  handleRemoveCouvertureCompanyPage: () => void;
  processingRemovePhoto: boolean;
};
export default function BackGroundCompany(props: IProps) {
  const params: { id: string } = useParams();
  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  return (
    <div className="row m-0 p-0">
      <div className="col-12 photoMurcompany">
        <div className="cover">
          {companyPageObject?.photo_couverture && (
            <img
              alt=""
              className="Image"
              src={fileUrl(companyPageObject?.photo_couverture)}
            />
          )}
        </div>
        <div className="editShow">
          <Link
            to="#"
            className="editBackground"
            onClick={(e) => {
              e.preventDefault();
              props?.setIsShowUpdateBackGroundPicture(true);
            }}
          >
            <span className="icon-edit-full"></span>
            Edit background image (Ideal image size: 1200x230)
          </Link>
          {companyPageObject?.photo_couverture.indexOf("default_couverture") ===
            -1 && (
            <>
              <div className="removePhoto bg isvv">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setShowPopupRemoveCouverture(true);
                  }}
                >
                  <span className="icon-bin"></span>Remove background
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="companyPhotoName">
          <div className="photo">
            <div className="editShow">
              <Link
                to="#"
                className="editPictureProfil"
                onClick={(e) => {
                  e.preventDefault();
                  props.setIsShowUpdateProfil(true);
                }}
              >
                <span className="icon-edit-full"></span>
                Edit logo
              </Link>
              {companyPageObject?.photo_profil.indexOf("defaultCompany.png") ===
                -1 && (
                <>
                  <div className="removePhoto logoComp">
                    <Link to="#">
                      <span
                        className="icon-bin"
                        onClick={(e) => {
                          e.preventDefault();
                          props.setShowPopupRemoveLogo(true);
                        }}
                      >
                        Remove logo
                      </span>
                    </Link>
                  </div>
                </>
              )}
            </div>
            {companyPageObject?.photo_profil && (
              <img alt="" src={fileUrl(companyPageObject?.photo_profil)} />
            )}
          </div>
          <h2 className="title">
            {limitedText(companyPageObject?.company_name, 30)}
          </h2>
        </div>
      </div>

      {/* MODAL CONFIRMATION REMOVE LOGO */}
      <Modal
        show={props?.showPopupRemoveLogo || props?.showPopupRemoveCouverture}
        className="small forDesactivate"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">
              {props?.showPopupRemoveLogo
                ? "Remove logo"
                : "Remove background image"}
            </h2>
            <Link
              to="#"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                props?.setShowPopupRemoveCouverture(false);
                props?.setShowPopupRemoveLogo(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </Link>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            {props?.showPopupRemoveLogo ? (
              <p>Are you sure you want to remove the current logo?</p>
            ) : (
              <p>
                Are you sure you want to remove the current background image?
              </p>
            )}
          </div>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => {
                props?.setShowPopupRemoveLogo(false);
                props?.setShowPopupRemoveCouverture(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => {
                if (props?.showPopupRemoveLogo) {
                  props?.handleRemoveLogoCompanyPage();
                } else {
                  props?.handleRemoveCouvertureCompanyPage();
                }
              }}
            >
              {props?.processingRemovePhoto ? <Loader /> : <>Remove</>}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
