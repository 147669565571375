import React, { useState, useEffect } from "react";
import { getDataService } from "../../service/applicatif/back-end-service";
import { useSelector, useDispatch } from "react-redux";
import { INVESTOR, OEM_PROGRAM, url } from "../../service/constant";
import { rootState } from "../../redux/reducers";
export enum filterType {
  otherWay,
  country_type,
  segments,
  target_industry,
  companySize,
  annual_turnover,
  match,
  funding_round,
  fundraising,
}
export type CountryType = {
  id: number;
  country_name: string;
  continent: string;
  cc_iso: string;
  code: string;
};
type DefaultType = { id: number; name: string };

export type ISegmentType = {
  id: number;
  name: string;
  segmentDetails: Array<{ id: number; name: string }>;
};

const hideTypeListe: Array<{ label: string; value: string; id: number }> = [
  { id: 1, label: "Hide Unlocked ISVs", value: "hide_unlocked" },
  { id: 2, label: "Hide Blacklisted ISVs", value: "hide_blacklisted" },
  // {
  //   id: 3,
  //   label: "Hide ISVs that are joining Soon",
  //   value: "hide_joining_soon",
  // },
];

export default function UseFilter(filter_type: string, userType: string) {
  const [isShowBloc, setisShowBloc] = useState<filterType>(filterType.otherWay);

  const [programType, setProgramType] = useState<Array<string>>([]);

  const [countryListe, setCountryListe] = useState<Array<CountryType>>([]);

  const userState = useSelector((state: rootState) => state.userReducer);

  const selectedOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const [segmentListe, setsegmentListe] = useState<Array<ISegmentType>>([]);
  const [targetedIndustry, setTargetedIndustry] = useState<Array<DefaultType>>(
    []
  );
  const [companySize, setcompanySize] = useState<Array<string>>([]);

  const [foundingRound, setFoundingRound] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [fundrasinGoal, setFundrasingGoal] = useState<Array<string>>([]);

  const [annualTurOver, setAnnualTurOver] = useState<Array<string>>([]);

  const [userUnloched, setUserUnloched] = useState<
    Array<{
      id: number;
      name: string;
    }>
  >([]);
  const [indexCountry, setIndexCountry] = useState(6);
  const [indexSegment, setIndexSegment] = useState(6);
  const [indexTargeted, setIndexTargeted] = useState(6);

  const setupAddArray = (
    arrayData: Array<string>,
    id: string
  ): Array<string> => {
    let found = arrayData?.find((el: string) => el === id);
    if (found) {
      return arrayData?.filter((el: string) => el !== id);
    } else {
      return [...arrayData, id];
    }
  };

  const handleFilterType = async (id: string) => {
    let tempFiltered = setupAddArray(programType, id?.toString());
    setProgramType(tempFiltered);
  };

  useEffect(() => {
    async function getFilterd() {
      let tempUrl: string = "";

      /**
       * For oem page , filter , favorie, matching
       *
       */
      let fiterProgramPageFavorie =
        filter_type === "favorites" && userType === OEM_PROGRAM;

      if (fiterProgramPageFavorie) {
        tempUrl = url?.findIsv?.get_filtered_favorie;
      }

      let fiterProgramPage =
        filter_type === "matching" && userType === OEM_PROGRAM;

      if (fiterProgramPage) {
        tempUrl = url?.findIsv?.get_filtered;
      }

      let unlockedOemPage =
        filter_type === "unlocked" && userType === OEM_PROGRAM;

      if (unlockedOemPage) {
        tempUrl = url?.findIsv?.filter_unlocked_page;
      }

      /**
       * For Investor , filter , favorie, matching
       *
       */
      let filterInvestorPageFavorie =
        filter_type === "favorites" && userType === INVESTOR;

      if (filterInvestorPageFavorie) {
        tempUrl = url?.matching_investor_isv?.build_filter_investor_favorie;
      }

      let filterInvestorPage =
        filter_type === "matching" && userType === INVESTOR;

      if (filterInvestorPage) {
        tempUrl = url?.matching_investor_isv?.build_filter_investor;
      }

      let unlockedInvestorPage =
        filter_type === "unlocked" && userType === INVESTOR;

      if (unlockedInvestorPage) {
        tempUrl = url?.matching_investor_isv?.filter_unlocked;
      }

      let subUrl =
        userType === OEM_PROGRAM ? "/" + selectedOemProgram?.program?.id : "";

      let response = await getDataService(
        tempUrl + subUrl,
        {},
        userState?.token
      );

      if (response?.status === 200) {
        setCountryListe(response?.data?.country);
        setsegmentListe(response?.data?.segment);
        setTargetedIndustry(response?.data?.targeted_industry);
        setcompanySize(response?.data?.company_size);
        setAnnualTurOver(response?.data?.annual_turnover);
        if (userType === INVESTOR) {
          setFoundingRound(response?.data?.funding_round);
          setFundrasingGoal(response?.data?.fundrasing_goal);
        }
        if (filter_type === "unlocked") {
          setUserUnloched(response?.data?.ISVs_filter?.unlocked_by);
        }
      }
    }
    getFilterd();

    return () => {};
  }, []);

  const handleBlock = (params: filterType) => {
    if (params === isShowBloc) {
      setisShowBloc(filterType?.otherWay);
    } else {
      setisShowBloc(params);
    }
  };

  return {
    isShowBloc,
    setisShowBloc,
    handleFilterType,
    countryListe,
    segmentListe,
    targetedIndustry,
    handleBlock,
    indexCountry,
    indexSegment,
    indexTargeted,
    setIndexCountry,
    setIndexSegment,
    setIndexTargeted,
    annualTurOver,
    setAnnualTurOver,
    companySize,
    hideTypeListe,
    foundingRound,
    fundrasinGoal,
    selectedOemProgram,
    userUnloched,
  };
}
