import moment from 'moment';
import Select from 'react-select';
import UseFavorite from '../../../../../controller/investor-ctr/find-investor/favorite-investor-ctr/use-favorite-investor';
import MatchingInvestor from '../../../../../hooks/MatchingInvestor';
import { IInvestor } from '../../../../../redux/types/find-investor-interface';
import {
  fileUrl,
  handleClassName,
  handleStatus
} from '../../../../../utils';
import Header from '../../../../widget/index';
import { LoadingFavoriteProgram } from '../../../../widget/loader';
import ModalPermission from '../../../../widget/permission-oem/index';
import Pagination from '../../../../widget/use-pagination/pagination';
import { PaymentPopup } from '../../../payment-popup/payment-popup';
import FindInvestorBlocFilter from '../bloc-filter-find-investor/bloc-filter-find-investor';

export default function InvestorFavorite() {
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains('open')) {
      elementH3.classList.remove('open');
    } else {
      elementH3.classList.add('open');
    }
  }

  const isIndividual = (el: IInvestor): boolean => {
    return !el?.investor?.institutional_investor_type
      ? true
      : el?.investor?.institutional_investor_type?.name === 'Business Angel'
      ? true
      : false;
  };
  const providerUseFavorite = UseFavorite();
  const providerModalUnlock = providerUseFavorite?.providerModalUseUnlockInvestor;

  const renderPercent = (number: string) => {
    let value = parseInt(number);
    if (value < 60) {
      return (
        <div className='matchValue m60'>
          <b>Match</b>
          <span className='icon-nextbtn' />
          {'60 %'}
        </div>
      );
    } else if (value >= 60 && value < 65) {
      return (
        <>
          <div className='matchValue m60'>
            <span className='icon-nextbtn' /> <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 65 && value < 75) {
      return (
        <>
          <div className='matchValue m80'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 75 && value < 90) {
      return (
        <>
          <div className='matchValue m80'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 90) {
      return (
        <>
          <div className='matchValue m90'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    }
  };

  return (
    <>
      <MatchingInvestor>
        <div className='findIsvcontainer'>
          <Header />
          <FindInvestorBlocFilter {...providerUseFavorite} />
          {/* <ModalPayementUnlock
            {...providerUseFavorite.providerModalUseUnlockInvestor}
            unlockedInvestor={(pid: string | null) =>
              providerUseFavorite.unlockedInvestor(pid)
            }
            loading={providerUseFavorite.loading}
            setIsError={providerUseFavorite?.setIsError}
            setIsSuccess={providerUseFavorite?.setIsSuccess}
            isError={providerUseFavorite?.isError}
            isSuccess={providerUseFavorite?.isSuccess}
            setShowAddCart={providerUseFavorite?.setShowAddCart}
            showAddCart={providerUseFavorite?.showAddCart}
          /> */}
          <PaymentPopup
            show={providerModalUnlock?.showPaymentModal}
            title={'Unlock Investor'}
            setShowPaymentModal={providerModalUnlock?.setShowPaymentModal}
            product={providerModalUnlock?.product}
            successCallback={providerUseFavorite?.paymentMethodCallBack}
            free={providerModalUnlock?.freeUnlock}
            isPriceInfo={false}
          />
          <div className='container mx-auto minHeightwin'>
            <div className='listOfIsv'>
              <div className='headerOfList'>
                <div className='leftHead'>
                  <div className='number'>
                    Showing{' '}
                    <b>{providerUseFavorite?.favorites?.total_to_paginate}</b>{' '}
                    Investors
                  </div>
                  <form
                    action=''
                    onClick={(e) => openBlock(e)}
                    onSubmit={providerUseFavorite?.handleSubmit(
                      providerUseFavorite?.onSubmitSearch
                    )}
                  >
                    <input
                      placeholder='Search...'
                      type='text'
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      {...providerUseFavorite?.register('search')}
                    />
                  </form>
                </div>
                <div className='rightHead'>
                  <div className='sortedby labelSelect'>
                    <label>Sorted by :</label>
                    <Select
                      className='triOreder'
                      isClearable={false}
                      options={providerUseFavorite?.filtered}
                      classNamePrefix='tri'
                      defaultValue={providerUseFavorite?.filtered[0]}
                      onChange={(filter: any) =>
                        providerUseFavorite?.handleFilter(filter)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='scrollContainer'>
                {providerUseFavorite?.loader ? (
                  <LoadingFavoriteProgram />
                ) : (
                  <ul className='listOfIsvMatch listUnlocked'>
                    {providerUseFavorite?.favorites?.results?.map((el, key) => (
                      <li
                        className={`${!isIndividual(el) ? '' : 'person'} ${
                          el?.is_unlocked ? 'unlocked' : 'locked'
                        }`}
                        key={key}
                      >
                        <div className='isvLogo'>
                          <img
                            src={fileUrl(el?.investor?.photo_profil)}
                            alt=''
                          />
                        </div>
                        <div className='isvInfo'>
                          <div className='infoUnlocked'>
                            <h3 className='title'>
                              <span className='name'>
                                {el?.investor?.organization_name}
                              </span>
                              <span
                                className={
                                  el?.is_favorite
                                    ? 'icon-star-on'
                                    : 'icon-star-off'
                                }
                                onClick={() =>
                                  providerUseFavorite?.addToFavorites(
                                    el?.investor?.id
                                  )
                                }
                              ></span>
                            </h3>
                            {renderPercent(el.matching_value?.toString())}
                            <div className='typeInvestor'>
                              <b>Location</b>
                              {el?.investor?.country?.country_name}
                            </div>
                            <div className='typeInvestor'>
                              <b>Investor type</b>
                              {el?.investor?.institutional_investor_type
                                ? el?.investor?.institutional_investor_type
                                    ?.name
                                : el?.investor?.investor_type?.name}
                            </div>
                          </div>
                          <div className='actionUnlocked'>
                            <div className='btnContent'>
                              <button
                                className='btn btn-devinsider view'
                                onClick={() =>
                                  window.open(
                                    '/inv-page/' + el?.investor?.id,
                                    '_blank'
                                  )
                                }
                              >
                                View
                              </button>
                              <button
                                className={`btn btn-devinsider ${
                                  el?.matching_value >= 70
                                    ? 'save'
                                    : 'unlockedBtn'
                                }`}
                                onClick={() => {
                                  el?.matching_value >= 70 &&
                                    providerUseFavorite?.addToUnlocked(
                                      el?.investor?.id,
                                      el?.is_unlocked
                                    );
                                }}
                              >
                                {el?.is_unlocked ? 'Contact' : 'Unlock'}
                              </button>
                            </div>
                            <div className='unlockedBy'>
                              {el?.unlocked_at && (
                                <>
                                  <b>
                                    {!el.isIsvUnlocked ? (
                                      'Investor request'
                                    ) : (
                                      <>
                                        Unlocked {el.user_who_unlocks ? `by ${el.user_who_unlocks}` : ""}
                                      </>
                                    )}
                                  </b>
                                  {moment(el?.unlocked_at).format(
                                    'MMMM D, yyyy'
                                  )}
                                </>
                              )}
                            </div>

                            <div
                              className={`statusContact unlockedBy ${handleClassName(
                                el.status_discussion
                              )}`}
                            >
                              <b>{handleStatus(el.status_discussion)}</b>
                              <>
                                <span className='icon-message'></span>
                                {el?.time_message_status &&
                                  moment(
                                    new Date(el?.time_message_status)
                                  ).format('MMMM DD, yyyy')}
                              </>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className='pagginationCategory fixePagination'>
                <div className='pagination'>
                  <Pagination
                    className='pagination'
                    currentPage={providerUseFavorite?.page || 1}
                    totalCount={
                      providerUseFavorite?.favorites?.total_to_paginate
                    }
                    pageSize={providerUseFavorite?.limit}
                    onPageChange={(page: any) =>
                      providerUseFavorite?.loadMoreProgram(page)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MatchingInvestor>
      <ModalPermission
        show={providerUseFavorite.showPermission}
        handleSkhow={providerUseFavorite.handLePersmission}
      />
    </>
  );
}
