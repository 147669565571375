import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFilters } from "../../redux/actions/event-action";
import { EventObject } from "../../redux/types/event-interface";

export const constante = {
  EVENT_TYPE: "EVENT_TYPE",
  TAGS: "TAGS",
  HOSTED_BY: "HOSTED_BY",
  TOP_COUNTRIES: "TOP_COUNTRIES",
};

export default function UseEventFilters() {
  const dispatch = useDispatch();
  const [eventObject, setEventObject] = useState<EventObject>({
    event_type: [],
    top_tags: [],
    hosted_by: [],
    top_countries: [],
    all_events: {
      results: [],
      total: 0,
      current_page: 0,
    },
    my_events: {
      results: [],
      total: 0,
    },
  });
  const [limitType, setLimitType] = useState<number>(6);
  const [limitTag, setLimitTag] = useState<number>(6);
  const [limitHosted, setLimitHosted] = useState<number>(6);
  const [limitCountryes, setLimitCountryes] = useState<number>(6);

  const pathName = window?.location?.pathname;

  const isMyEvent = pathName.includes("/events/my-events") ? true : false;

  const data = isMyEvent
    ? {
        isMyEvent: true,
        event_type: 2,
      }
    : {};

  const cb = (response: any) => {
    if (response?.status === 200) {
      const { data } = response;
      setEventObject(data);
    }
  };

  useEffect(() => {
    dispatch(getFilters(cb, data));
  }, [pathName]);

  const expandOrNot = (type: string, option: number = 1) => {
    switch (type) {
      case constante.EVENT_TYPE:
        option === 2
          ? setLimitType(6)
          : setLimitType(eventObject?.event_type?.length);
        return;
      case constante.TAGS:
        option === 2
          ? setLimitTag(6)
          : setLimitTag(eventObject?.top_tags?.length);
        return;
      case constante.HOSTED_BY:
        option === 2
          ? setLimitHosted(6)
          : setLimitHosted(eventObject?.hosted_by?.length);
        return;
      case constante.TOP_COUNTRIES:
        option === 2
          ? setLimitCountryes(6)
          : setLimitCountryes(eventObject?.top_countries?.length);
        return;
    }
  };

  return {
    eventObject,
    limitType,
    limitTag,
    expandOrNot,
    limitHosted,
    limitCountryes,
  };
}
