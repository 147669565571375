import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../../redux/reducers";
import {
  CONTRIBUTOR,
  GUEST,
  OEM_PROGRAM,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from "../../../../../service/constant";
import ModalPlanDetails, {
  BASIC,
} from "../../../../widget/manage-company-and-billing/index";

type IProps = {
  providerInfo: any;
};
export default function Index(props: IProps) {
  const history = useHistory();
  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  // const isOem = oemProgramObject?.id?.toString() !== "0";

  const userObject = useSelector((state: rootState) => state?.userReducer);
  const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");
  const isOem = role?.[0] === OEM_PROGRAM;

  const isNotGuest = userObject?.roles?.indexOf(GUEST) === -1;
  const isNotExpert = userObject?.roles?.indexOf(CONTRIBUTOR) === -1;

  const goToSubscriptionPlan = () => {
    history.push("/billing-center/subscription-plan");
  };
  return (
    <>
      {isNotGuest && isNotExpert && (
        <div className="itemBilling">
          <h2 className="title">Plan details</h2>
          <div className="contentItem">
            <div className="itemctt">
              {/* RENDER SUBSCRIPTION IF OEM */}
              {isOem && (
                <>
                  Subscription plan : {oemProgramObject.oem_subscription?.name}{" "}
                  <br />
                  {oemProgramObject.oem_subscription?.name !== "Basic" && (
                    <small style={{ fontSize: "14px" }}>
                      {moment(oemProgramObject.subscription_begin_at).format(
                        "LL"
                      ) +
                        " - " +
                        moment(oemProgramObject.subscription_end_at).format(
                          "LL"
                        )}
                    </small>
                  )}
                </>
              )}
            </div>
            <div className="footerItem" style={{ fontSize: "14px" }}>
              <a
                onClick={() => {
                  //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" MENY PERMISSION
                  if (
                    props.providerInfo.role.indexOf(OEM_PROGRAM) !== -1 &&
                    [
                      OEM_PROGRAM_EDITOR,
                      OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                    ].indexOf(props.providerInfo.roleUserIncurrentProgram) !==
                      -1
                  ) {
                    props.providerInfo.setShowModalPermissionOem(true);
                  } else {
                    props.providerInfo?.showPlanBilling();
                  }
                }}
              >
                View details
              </a>
              {/* display only for oem program */}
              {isOem && (
                <>
                  {" "}
                  <span className="separ"></span>{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" MENY PERMISSION
                      if (
                        props.providerInfo.role.indexOf(OEM_PROGRAM) !== -1 &&
                        [
                          OEM_PROGRAM_EDITOR,
                          OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                        ].indexOf(
                          props.providerInfo.roleUserIncurrentProgram
                        ) !== -1
                      ) {
                        props.providerInfo.setShowModalPermissionOem(true);
                      } else {
                        goToSubscriptionPlan();
                      }
                    }}
                  >
                    Upgrade plan
                  </a>
                  {oemProgramObject?.oem_subscription?.name != BASIC && (
                    <>
                      <span className="separ"></span>{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" MENY PERMISSION
                          if (
                            props.providerInfo.role.indexOf(OEM_PROGRAM) !==
                              -1 &&
                            [
                              OEM_PROGRAM_EDITOR,
                              OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                            ].indexOf(
                              props.providerInfo.roleUserIncurrentProgram
                            ) !== -1
                          ) {
                            props.providerInfo.setShowModalPermissionOem(true);
                          } else {
                            props?.providerInfo?.setShowCancelSubscription(
                              true
                            );
                          }
                        }}
                      >
                        Manage plan
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <ModalPlanDetails {...props.providerInfo} />
    </>
  );
}
