/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { LoginModal } from "../login/login";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { getLooUpKeyPostMA, isExist } from "../../../utils";
import { PaymentPopup } from "../../container/payment-popup/payment-popup";
import useEventListener from "../../../hooks/event-handler";

const urlType = {
  favorites: "/favorite",
  listing: "/listing",
};

export default function HeaderAlternativeFinancing(props: any) {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const isConnected = isExist(userReducer.token) && userReducer.enable;

  const path = window.location.href;
  const renderActive = (type: string) => {
    return path?.includes(type) ? "active" : "";
  };

  const goToHomeAlternative = () => {
    history.push("/alternative-financing/home");
  };

  const goToListing = () => {
    isConnected
      ? history.push("/alternative-financing/listing")
      : props?.providerAlternative?.setShowLogin(true);
  };

  const goToFavorite = () => {
    isConnected
      ? history.push("/alternative-financing/favorite")
      : props?.providerAlternative?.setShowLogin(true);
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      props?.handleClick();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return (
    <>
      <PaymentPopup
        show={props?.providerAlternative?.showPaymentModal}
        title={"Post corporate development ad"}
        setShowPaymentModal={props?.providerAlternative?.setShowPaymentModal}
        product={{
          lookupKey: getLooUpKeyPostMA(userReducer?.roles[0]),
          name: "Post corporate development ad",
        }}
        free={props?.providerAlternative?.freeNumber}
        isPriceInfo={true}
        priceInfoCallback={() => {
          props?.providerAlternative?.postAlternative();
        }}
      />
      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">
              <a
                className="colorInherit"
                href="javascript:;"
                onClick={(e) => {
                  e.preventDefault();
                  goToHomeAlternative();
                }}
              >
                Alternative financing
              </a>
            </h2>
            <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    goToFavorite();
                  }}
                  className={renderActive(urlType?.favorites)}
                >
                  Favorites
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    goToListing();
                  }}
                  className={renderActive(urlType?.listing)}
                >
                  My listings
                </a>
              </div>
              <div className="item">
                <div className="searchWord classified">
                  <input
                    type="text"
                    className="keyword"
                    value={props?.searchText}
                    onChange={(evt) =>
                      props?.handleChange &&
                      props?.handleChange(evt?.target?.value)
                    }
                  />
                  <button onClick={props?.handleClick}>
                    <span className="icon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="rightItemHeader">
            <div className="item">
              <a
                href="javascript:;"
                className="startPost"
                onClick={(e) => {
                  e.preventDefault();
                  if (!props?.providerAlternative?.chargingPlanDetails) {
                    props?.providerAlternative &&
                      props?.providerAlternative?.goToPostAlternative();
                  }
                }}
              >
                Post an ad
              </a>
            </div>
          </div>
        </div>
        <Modal
          show={props?.providerAlternative?.showAdsModalP}
          className="small forDesactivate"
        >
          <Modal.Header>
            <div className="headerpermission noStep">
              <h2 className="title">
                {props?.providerAlternative?.allText?.[0]}
              </h2>
              <NavLink
                to="#"
                className="close"
                onClick={(e) => {
                  e.preventDefault();

                  props?.providerAlternative?.setShowAdsModalP(false);
                }}
              >
                <span className="icon-close-pop"></span>
              </NavLink>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="contentBorder row m-0 p-0 desactivepopup">
              <h3 className="title withIcon">
                <span className="icon-info"></span>
                {props?.providerAlternative?.allText?.[1]}
              </h3>

              <p>{props?.providerAlternative?.allText?.[2]}</p>
              <p>{props?.providerAlternative?.allText?.[3]}</p>
            </div>
          </Modal.Body>
        </Modal>
        <LoginModal
          urlType={path.indexOf("community") !== -1 ? "community" : ""}
          show={props?.providerAlternative?.showLogin}
          setShow={props?.providerAlternative?.setShowLogin}
        />
      </div>
    </>
  );
}
