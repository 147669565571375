import React from "react";
type Iprops = {
  goLogin: () => void;
  showGetStarted: () => void;
};
export default function BlockAccount(props: Iprops) {
  return (
    <div className="menuDynamic tr notConnected">
      <div className="item">
        <a
          href="javascript:;"
          className="signinHeader"
          onClick={(e) => {
            e.preventDefault();
            props.goLogin();
          }}
        >
          Sign in
        </a>
      </div>
      <div className="item">
        <a
          href="javascript:;"
          className="getstartedHeader"
          onClick={(e) => {
            e.preventDefault();
            props.showGetStarted();
          }}
        >
          Get started
        </a>
      </div>
    </div>
  );
}
