import { useState } from 'react';
import {
  // useDispatch,
  useSelector
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { updateBillingCenterCardExpert, updateBillingCenterCardGuest, updateBillingCenterCardIsv } from '../../../redux/actions/acounts-action';
// import { updatePaymentMethod as updatePaymentMethodInvestor } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
// import { updateBillingCenterCard } from '../../../redux/actions/company-page-isv-action/current_isv_action';
// import { updateBillingCenterCardResellerCompany } from '../../../redux/actions/company-page-reseller';
// import { updateBillingCardOemInformation } from '../../../redux/actions/oem-program-action';
import { rootState } from '../../../redux/reducers';
import { postData } from '../../../service/api';
import { url } from '../../../service/constant';
import {deleteDataMicroService} from "../../../service/applicatif/back-end-service";

export default function UserRemoveCard(args: any) {
  const history = useHistory();
  // const dispatch = useDispatch();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [removeCard, setRemoveCard] = useState<boolean>(false);
  const cardRemoved = () => {
    setRemoveCard(true);

    (async () => {
      let response = await deleteDataMicroService(
          url.microservice.payment.payment_method,
          (args.entityDetails ? {
            oem_program_id: args.entityDetails.entity_id
          }: {}),
          userReducer.token
      );
      if (response?.status === 204) {
        args.successUpdate(false)
        //update redux
        // if (args.entityDetails.entity_type === 'ISV_USER')
        //   dispatch(updateBillingCenterCardIsv(''));
        // if (args.entityDetails.entity_type === 'COMPANY_PAGE')
        //   dispatch(updateBillingCenterCard(''));
        // if (args.entityDetails.entity_type === 'OEM_PROGRAM')
        //   dispatch(updateBillingCardOemInformation(''));
        // if (args.entityDetails.entity_type === 'INVESTOR_PAGE')
        //   dispatch(updatePaymentMethodInvestor(''));
        // if (args.entityDetails.entity_type === 'RESELLER_COMPANY')
        //   dispatch(updateBillingCenterCardResellerCompany(''));
        // if (args.entityDetails.entity_type === "GUEST_COMPANY")
        //   dispatch(updateBillingCenterCardGuest(''));
        // if (args.entityDetails.entity_type === "EXPERT_COMPANY")
        //   dispatch(updateBillingCenterCardExpert(""));
        
        toast('Card removed successfully');
        setRemoveCard(false);
        args.closePopRemoveCard();
        history.push('/settings');
      }
    })();
  };
  return {
    removeCard,
    cardRemoved,
  };
}
