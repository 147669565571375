import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { IPropsPays } from "./type";
export default function CompleteInvestorCountry(props: IPropsPays) {
  const [activeId, setActiveId] = useState<any>("0");

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  return (
    <div>
      <div className="getstartedContainer">
        <div className="contentBorder row py-0">
          <div className="geographicList">
            <Accordion defaultActiveKey={activeId}>
              {props?.allContinents?.map((items: any, index: number) => {
                return (
                  <div
                    className={
                      activeId === items.id?.toString()
                        ? "panel-wrap active-panel"
                        : "panel-wrap"
                    }
                    key={index + "" + items.id + "-" + items.name}
                  >
                    <div className="panel-header">
                      <Accordion.Toggle
                        onClick={() => toggleActive(items.id)}
                        className={`${
                          props?.paysChecked?.filter(
                            (el: any) =>
                              el.idContinent?.toString() === items.id.toString()
                          ).length > 0
                            ? "panel-toggle selectedItem"
                            : "panel-toggle"
                        }`}
                        eventKey={items.id}
                        key={index}
                      >
                        {items && items.name}{" "}
                        {props?.paysChecked?.filter(
                          (el: any) =>
                            el.idContinent?.toString() === items.id?.toString()
                        ).length > 1
                          ? `(   ${
                              props?.paysChecked?.filter(
                                (el: any) =>
                                  el.idContinent?.toString() ===
                                  items.id?.toString()
                              ).length
                            } countries selected )`
                          : ""}
                        {props?.paysChecked?.filter(
                          (el: any) =>
                            el.idContinent?.toString() === items.id?.toString()
                        ).length === 1
                          ? `( 1 country  selected )`
                          : ""}
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={items.id}>
                      <div className="panel-body">
                        <div
                          onClick={(event) => {
                            props?.addPaysInArray({
                              idContinent: items.id,
                              countryesData: items.countries,
                            });
                            event.stopPropagation();
                          }}
                        >
                          <Form.Check
                            custom
                            label={`Select all `}
                            id="all"
                            checked={
                              props?.allContinentSelected?.find(
                                (el: any) => el == items.id
                              )
                                ? true
                                : false
                            }
                            disabled={true}
                            value={index}
                            onClick={() => null}
                            onChange={() => null}
                          />
                        </div>

                        {items?.countries?.map((pays: any, index: number) => {
                          return (
                            <Form.Check
                              key={index + "-" + items.id}
                              custom
                              label={pays.country_name}
                              id={index + "-" + pays.country_name}
                              checked={
                                props?.paysChecked?.find(
                                  (t: any) => t.countrId == pays.id
                                )
                                  ? true
                                  : false
                              }
                              onClick={(event) => {
                                props?.addPaysInArray({
                                  idContinent: items.id,
                                  countrId: pays.id,
                                  countryName: pays.country_name,
                                });
                                event.stopPropagation();
                              }}
                              onChange={() => null}
                            />
                          );
                        })}
                      </div>
                    </Accordion.Collapse>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
