import React from "react";
import Nav from "react-bootstrap/Nav";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../redux/reducers";
import { oem_roles } from "../../../utils";
import { CONTRIBUTOR, GUEST } from "../../../service/constant";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export type PropTabPanHeader = {
  setTabNavigate: (s: string) => void;
  setShowBillingCenter: () => void;
  companyName: string;
  role: Array<string>;
  showAddCompany: boolean;
  programCompanyName : string;
};
export default function TabPanHeader(props: PropTabPanHeader) {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);

  const isOemOutreach = userReducer?.oemProgramMembers?.find(
    (el) => el.oem_program.id?.toString() === oemProgram.program?.id?.toString()
  );

  const isNotGuest = userReducer?.roles?.indexOf(GUEST) === -1;
  const isNotExpert = userReducer?.roles?.indexOf(CONTRIBUTOR) === -1;

  const goToCreateCompany = () => {
    history.push("/company/create");
  };

  const isOutRepresentative =
    isOemOutreach?.role === oem_roles.outreach_representative;
  return (
    <div className="menuPlanTab borderAll">
      <Nav variant="pills">
        <div className="col-lg-4 evo">
          <div className="title">
            {!props?.showAddCompany && (
              <>
                <h5>
                  {props?.companyName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {" "}
                  {props.programCompanyName ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="trade">
                          {props.programCompanyName}
                        </Tooltip>
                      }
                    >
                    <span 
                      className="programCompanyName"
                      style={{ cursor: "default" }}
                    >{props.programCompanyName}</span>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </h5>
              </>
            )}
            {props?.showAddCompany && (
              <button
                className="create-company m-0"
                onClick={() => goToCreateCompany()}
              >
                Add your company page
              </button>
            )}
          </div>
        </div>
        {isNotGuest && isNotExpert && (
          <Nav.Item className="col-lg-2">
            <Nav.Link
              className="plan"
              eventKey="plan"
              onClick={() => props?.setTabNavigate("plan")}
            >
              Plan details
            </Nav.Link>
          </Nav.Item>
        )}
        {!isOutRepresentative && (
          <Nav.Item className="col-lg-2">
            {(props.role?.indexOf("ROLE_ISV") !== -1 ||
              props.role?.indexOf("ROLE_OEM_PROGRAM") !== -1) && (
              <Nav.Link
                eventKey="role"
                onClick={() => props?.setTabNavigate("role")}
              >
                Roles
              </Nav.Link>
            )}
          </Nav.Item>
        )}
        {!isOutRepresentative && (
          <Nav.Item className="col-lg-2">
            <Nav.Link
              eventKey="transaction"
              onClick={() => props?.setTabNavigate("transaction")}
            >
              Transactions
            </Nav.Link>
          </Nav.Item>
        )}
        {!isOutRepresentative && (
          <Nav.Item className="col-lg-2">
            <Nav.Link
              eventKey="billingCenter"
              onClick={() => props?.setTabNavigate("billingCenter")}
            >
              <button
                className="cancel"
                onClick={() => props.setShowBillingCenter()}
              >
                Billing center
              </button>
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </div>
  );
}
