import React, { ChangeEvent } from "react";
import UseFilterListe, {
  CountryType,
  filterType,
  ISegmentType,
} from "../../../../controller/find-isv/use-filter-ctr";
import Slider, { Range } from "rc-slider";
import { generatedText } from "../../../../utils";
import { INVESTOR, OEM_PROGRAM } from "../../../../service/constant";
import { IPropsFindIsv } from "./type";

export default function Filter(props: IPropsFindIsv) {
  const providerListe = UseFilterListe(props?.filter_type, props?.userType);

  const handleClickIndex = (index: number, currentLength: number) =>
    index > 6 ? 6 : currentLength;

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="infoProgramContainer">
          {props?.userType === OEM_PROGRAM && (
            <>
              <h2 className="title">
                {providerListe?.selectedOemProgram?.program?.company_name}
              </h2>
              <p className="infoProgram">
                {providerListe?.selectedOemProgram?.program?.program_name}
              </p>
            </>
          )}
        </div>
        <div className="containerBtn block">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.lunchFilter()}
          >
            Filter
          </button>
          <a
            href="javascript:;"
            className="resetFilter"
            onClick={() => props?.resetFilter()}
          >
            <span className="icon-refresh"></span>
            {/* Design & Integration #45506 Find ISVs matchmaking panel */}
            Reset filter
          </a>
        </div>

        {props?.filter_type === "unlocked" && (
          <div className="block">
            <div className="custom-control custom-checkbox orange">
              <input
                type="checkbox"
                id="custase"
                className="custom-control-input"
                onChange={props?.handleIsvContacted}
                checked={props?.isCheckedContactedIsv}
              />
              <label htmlFor="custase" className="custom-control-label">
                Show contacted ISVs only
              </label>
            </div>
            {providerListe?.userUnloched?.length > 0 && props?.userType !== INVESTOR && (
              <div className="unlockedSelect">
                <label htmlFor="selectUnlocked">Unlocked by: </label>
                <select
                  name=""
                  id="selectUnlocked"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    props?.handleUserUnlocked &&
                    props?.handleUserUnlocked(e.target.value)
                  }
                  value={props?.unlockedByUser}
                >
                  <option value="">All</option>
                  {providerListe?.userUnloched?.map(
                    (el: { id: number; name: string }, index: number) => (
                      <option value={el?.id} key={index}>
                        {el?.name}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          </div>
        )}

        {props?.filter_type === "matching" && (
          <div className="block">
            {providerListe?.hideTypeListe?.map(
              (
                element: { id: number; label: string; value: string },
                index: number
              ) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={element?.label + element?.id}
                    checked={
                      props?.hideType?.find((v) => v === element.id?.toString())
                        ? true
                        : false
                    }
                    onChange={() => props?.setHideType(element.id?.toString())}
                  />
                  <label
                    htmlFor={element?.label + element?.id}
                    className="custom-control-label"
                  >
                    {element?.label}
                  </label>
                </div>
              )
            )}
          </div>
        )}

        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.match ? "open" : ""
            }`}
            onClick={(e) => providerListe?.handleBlock(filterType.match)}
          >
            Match
          </h3>
          <div className="listCheck">
            <Range
              className="targetInvestorrange"
              min={60}
              max={100}
              value={props?.sliderValue}
              onChange={(value: Array<number>) => {
                props?.setSliderValue(value);
              }}
              marks={{
                0: props?.sliderValue[0] + "%",
                1: "",
                2: "",
                3: "",
                4: "",
                5: "",
                6: "",
                7: "",
                8: "",
                9: "",
                10: props?.sliderValue[1] + "%",
              }}
            />
          </div>
        </div>
        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.country_type
                ? "open"
                : ""
            }`}
            onClick={(e) => providerListe?.handleBlock(filterType.country_type)}
          >
            Country
          </h3>
          <div className="listCheck">
            {providerListe?.countryListe
              ?.filter((el, index) => index < providerListe?.indexCountry)
              .map((el: CountryType, index) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={el?.id + el?.country_name}
                    checked={
                      props?.selectedCountry?.find(
                        (v) => v === el.id?.toString()
                      )
                        ? true
                        : false
                    }
                    onChange={() => props?.setCountryType(el.id?.toString())}
                    className="custom-control-input"
                  />
                  <label
                    htmlFor={el?.id + el?.country_name}
                    className="custom-control-label"
                  >
                    {el?.country_name}
                  </label>
                </div>
              ))}

            <a
              href="javascript:;"
              className="seemore"
              onClick={() => {
                providerListe?.setIndexCountry(
                  handleClickIndex(
                    providerListe?.indexCountry,
                    providerListe?.countryListe?.length
                  )
                );
              }}
            >
              {generatedText(
                providerListe?.indexCountry,
                providerListe?.countryListe?.length
              )}
            </a>
          </div>
        </div>
        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.segments ? "open" : ""
            }`}
            onClick={(e) => providerListe?.handleBlock(filterType.segments)}
          >
            Segment
          </h3>

          <div className="listCheck">
            {providerListe?.segmentListe
              ?.filter((el, index) => index < providerListe?.indexSegment)
              .map((segment: ISegmentType, index: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={segment?.id + segment?.name}
                    checked={
                      props?.selectedSegment?.find(
                        (v) => v === segment.id?.toString()
                      )
                        ? true
                        : false
                    }
                    onChange={() =>
                      props?.setSelectedSegment(segment.id?.toString())
                    }
                    className="custom-control-input"
                  />
                  <label
                    htmlFor={segment?.id + segment?.name}
                    className="custom-control-label"
                  >
                    {segment?.name}
                  </label>
                </div>
              ))}
            <a
              href="javascript:;"
              className="seemore"
              onClick={() => {
                providerListe?.setIndexSegment(
                  handleClickIndex(
                    providerListe?.indexSegment,
                    providerListe?.segmentListe?.length
                  )
                );
              }}
            >
              {generatedText(
                providerListe?.indexSegment,
                providerListe?.segmentListe?.length
              )}
            </a>
          </div>
        </div>

        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.target_industry
                ? "open"
                : ""
            }`}
            onClick={(e) =>
              providerListe?.handleBlock(filterType.target_industry)
            }
          >
            Target industry
          </h3>
          <div className="listCheck">
            {providerListe?.targetedIndustry
              ?.filter((el, index) => index < providerListe?.indexTargeted)
              .map((el: any, index: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    checked={
                      props?.selectedTargetedIndustry?.find(
                        (v) => v === el.id?.toString()
                      )
                        ? true
                        : false
                    }
                    onChange={() =>
                      props?.setSelectedTargeted(el.id?.toString())
                    }
                    className="custom-control-input"
                    id={el?.id + el?.name}
                  />
                  <label
                    htmlFor={el?.id + el?.name}
                    className="custom-control-label"
                  >
                    {el?.name}
                  </label>
                </div>
              ))}

            <a
              href="javascript:;"
              className="seemore"
              onClick={() => {
                providerListe?.setIndexTargeted(
                  handleClickIndex(
                    providerListe?.indexTargeted,
                    providerListe?.targetedIndustry?.length
                  )
                );
              }}
            >
              {generatedText(
                providerListe?.indexTargeted,
                providerListe?.targetedIndustry?.length
              )}
            </a>
          </div>
        </div>

        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.companySize
                ? "open"
                : ""
            }`}
            onClick={(e) => providerListe?.handleBlock(filterType.companySize)}
          >
            Company size
          </h3>
          <div className="listCheck">
            {providerListe?.companySize?.map((el: string, index: number) => (
              <div
                className="custom-control custom-checkbox orange"
                key={index}
              >
                <input
                  type="checkbox"
                  checked={
                    props?.selectedCompanySize?.find((v) => v === el)
                      ? true
                      : false
                  }
                  onChange={() => props?.setSelectedCompanySize(el)}
                  id={index + el}
                  className="custom-control-input"
                />
                <label htmlFor={index + el} className="custom-control-label">
                  {el}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* Filter Annual Turnover */}

        <div className="block">
          <h3
            className={`title withChild ${
              providerListe?.isShowBloc === filterType?.annual_turnover
                ? "open"
                : ""
            }`}
            onClick={(e) =>
              providerListe?.handleBlock(filterType.annual_turnover)
            }
          >
            Annual revenue
          </h3>
          <div className="listCheck">
            {providerListe?.annualTurOver?.map((el: string, index: number) => (
              <div
                className="custom-control custom-checkbox orange"
                key={index}
              >
                <input
                  type="checkbox"
                  checked={
                    props?.selectedAnnual?.find((v) => v === el) ? true : false
                  }
                  onChange={() => props?.setSelectedAnnual(el)}
                  id={index + el}
                  className="custom-control-input"
                />
                <label htmlFor={index + el} className="custom-control-label">
                  {el}
                </label>
              </div>
            ))}
          </div>
        </div>

        {props?.userType === INVESTOR && (
          <div className="block">
            <h3
              className={`title withChild ${
                providerListe?.isShowBloc === filterType?.funding_round
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerListe?.handleBlock(filterType.funding_round)
              }
            >
              Funding round
            </h3>
            <div className="listCheck">
              {providerListe?.foundingRound?.map(
                (el: { id: number; name: string }, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      checked={
                        props?.selectedFundingRound?.find(
                          (v) => v?.toString() === el?.id?.toString()
                        )
                          ? true
                          : false
                      }
                      onChange={() =>
                        props?.setSelectedFundingRound(el?.id?.toString())
                      }
                      id={index + el?.name}
                      className="custom-control-input"
                    />
                    <label
                      htmlFor={index + el?.name}
                      className="custom-control-label"
                    >
                      {el?.name}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        {props?.userType === INVESTOR && (
          <div className="block">
            <h3
              className={`title withChild ${
                providerListe?.isShowBloc === filterType?.fundraising
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerListe?.handleBlock(filterType.fundraising)
              }
            >
              Fundraising goal
            </h3>
            <div className="listCheck">
              {providerListe?.fundrasinGoal.map((el: any, index: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    checked={
                      props?.selectedFundraising?.find(
                        (v) => v?.toString() === el?.toString()
                      )
                        ? true
                        : false
                    }
                    onChange={() => props?.setSelectedFundraising(el)}
                    id={index + el}
                    className="custom-control-input"
                  />
                  <label htmlFor={index + el} className="custom-control-label">
                    {el}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
