import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IPropsSettingsCampanySize } from "./type";
export default function SliderCompanySize(props: IPropsSettingsCampanySize) {
  const createSliderWithTooltip = Slider.Range;
  const Range = createSliderWithTooltip;

  const handleChange = (event: any) => {
    props?.customSetCompanySize(event[0], event[1]);
    props?.setCompanySize(event);
    if (props?.setCompanySizeChanged) props?.setCompanySizeChanged(true);
  };

  console.log("props?.companySize", props?.companySize);
  return (
    <Range
      className="targetInvestorrange"
      min={0}
      max={10}
      value={props?.companySize}
      step={1}
      marks={{
        0:
          props?.companySizeSlide[0] == 0
            ? "0 employees"
            : props?.companySizeSlide[0] + " employees",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10:
          props?.companySizeSlide[1] == 10
            ? "3000+ employees"
            : props?.companySizeSlide[1]?.toString() == "3200"
            ? "3000+ employees"
            : props?.companySizeSlide[1] + " employees",
      }}
      onChange={(event) => {
        handleChange(event);
      }}
    />
  );
}
