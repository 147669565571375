import React from 'react'
import { CardElement } from '@stripe/react-stripe-js';
import {PaymentInputErrors} from "../payment-info/payment-info";

type Props = {
    firstname: string
    lastname: string
    setFirstname: (firstname: string) => void
    setLastname: (lastname: string) => void
    inputErrors: PaymentInputErrors
}

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true
};

export const PaymentCard: React.FC<Props> = ({
    firstname,
    lastname,
    setLastname,
    setFirstname,
    inputErrors
}) => {
    return (
        <>
            <div className='row m-0'>
                <div className='name required col-6'>
                    <label htmlFor=''>
                        First name
                        <div
                            className={`container-error-address pl-2 ${
                                inputErrors.firstname ? 'd-inline' : 'd-none'
                            }`}
                        >
                            <span className='error-red'> error </span>
                            <span className='txt-error'>This field is required</span>
                        </div>
                    </label>
                    <input
                        id='input-firstname'
                        type='text'
                        className='form-control'
                        name='MemberName'
                        defaultValue={firstname}
                        value={firstname}
                        onChange={(event) => setFirstname(event.target.value)}
                    />
                </div>
                <div className='name required col-6'>
                    <label htmlFor=''>
                        Last name
                        <div
                            className={`container-error-address pl-2 ${
                                inputErrors.lastname ? 'd-inline' : 'd-none'
                            }`}
                        >
                            <span className='error-red'> error </span>
                            <span className='txt-error'>This field is required</span>
                        </div>
                    </label>
                    <input
                        id='input-lastname'
                        type='text'
                        className='form-control'
                        name='Job'
                        defaultValue={lastname}
                        value={lastname}
                        onChange={(event) => setLastname(event.target.value)}
                    />
                </div>
            </div>
            <div className='name col-12'>
                <br/>
                <label id="cardContainer">
                    <span className="requi">Credit or debit card number</span>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                </label>
            </div>
        </>
    )
}