import React from "react";
import Modal from "react-bootstrap/Modal";

export function ModalReport (props: any) {

  return(

           <Modal
        show={props.showModal}
        id="company-page"
        className='modal-dialog done'
        >
    
         <Modal.Header>
          <Modal.Title className="report">Thank you!</Modal.Title>
          <span  onClick={() => props.setShowModal(false)} className="close">
            
          </span>

        </Modal.Header>
        <Modal.Body>
        <div className="check-body">
        <div className="check my-4">
        </div>
        <h4 className="text-center my-4">We will get back to you</h4>
        <p className="text-center mt-2">We will evaluate your submission and will notify you soon.</p>
        </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer done'>
        </Modal.Footer>
        </Modal>

    )

}



