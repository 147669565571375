import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBillingCenterCardInvestor } from '../../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { updateBillingCenterCard } from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import { updateBillingCenterCardResellerCompany } from '../../../../redux/actions/company-page-reseller';
import {
  handleUpdatePlanDetail,
  handleUpdateSubscriptionDates,
  updateOemProgramCardInformation,
} from '../../../../redux/actions/oem-program-action';
import { rootState } from '../../../../redux/reducers';
import { postData } from '../../../../service/api';
import {
  getDataService,
  postJsonDataService,
} from '../../../../service/applicatif/back-end-service';
import { getPaymentIntent } from '../../../../service/applicatif/settings';
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from '../../../../service/constant';
import { errorsCard } from './error-card';

type IProps = {
  firstName: string;
  lastName: string;
  setFirstName: any;
  setLastName: any;
  showCardRegistrationForm: boolean;
  setShowCardRegistrationForm: (value: boolean) => void;
  setShowPaymentSuccessfullModal: (value: boolean) => void;
  setShowPaymentErrorModal: (value: boolean) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  handleErrorCard?: () => void;
};
export default function UseCartPremiumSetup(props: IProps) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);
  const resellerCompanyReducer = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const [gettingSetupIntent, setGettingSetupIntent] = useState<boolean>(false);
  //get customer id
  const [client, setClient] = useState<any>(null);
  const [entityId, setEntityId] = useState<string | undefined>();
  const [saveInformations, setSaveInformations] = useState<boolean>(false);

  const [settingSetupIntentProcess, setSettingSetupIntentProcess] =
    useState<boolean>(false);

  const [txtError, setTxtError] = useState<string>();

  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isInvestor = userReducer?.roles.indexOf(INVESTOR) !== -1;
  const isReseller = userReducer?.roles.indexOf(RESELLER) !== -1;
  const isOem = userReducer?.roles.indexOf(OEM_PROGRAM) !== -1;

  const getUserTypeAndCompanyId = () => {
    //return COMPANY_PAGE OR OEM_PROGRAM OR INVESTOR PAGE
    let entityType: string = '';
    let entityId: string | undefined;
    // if (isIsv) {
    //   entityType = 'COMPANY_PAGE';
    //   entityId = userReducer?.isvInCompanyPage?.company_page.id;
    // }
     if (isIsv) {
       entityType = "ISV_USER";
       entityId = userReducer?.id;
     }
    if (isOem) {
      entityType = 'OEM_PROGRAM';
      // TODO get oem progra id
      entityId = oemProgram.program.id;
    }
    if (isInvestor) {
      entityType = 'INVESTOR_PAGE';
      // TODO get investor page id
      entityId = userReducer.investor_members.investor_page.id;
    }
    if (isReseller) {
      entityType = 'RESELLER_COMPANY';
      // TODO get investor page id
      entityId = resellerCompanyReducer.id;
    }
    return { entityType, entityId };
  };

  const setSetupIntentSetting = async (
    token: string,
    paymentId: string,
    customerEmail: string,
    entityId: string | undefined,
    firstName: string,
    lastName: string
  ) => {
    let response: any = await postJsonDataService(
      url.settings.billingCenter + '/set_setup_intent_setting',
      { paymentId, customerEmail, entityId, firstName, lastName },
      token
    );
    //dispatch the response
    if (isIsv) {
      if (response?.status === 200) {
        dispatch(updateBillingCenterCard(response?.data));
      }
    }
    //dispatch the response
    if (isInvestor) {
      if (response?.status === 200) {
        dispatch(updateBillingCenterCardInvestor(response?.data));
      }
    }
    //dispatch the response
    if (isOem) {
      if (response?.status === 200) {
        dispatch(updateOemProgramCardInformation(response?.data));
      }
    }
    //dispatch the response
    if (isReseller) {
      if (response?.status === 200) {
        dispatch(updateBillingCenterCardResellerCompany(response?.data));
      }
    }
    return response;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements || client === null) {
      setTxtError('An Error occurred');
      props?.handleErrorCard && props?.handleErrorCard();
      return;
    }
    setSettingSetupIntentProcess(true);
    const client_secret = client!.customer_id?.client_secret;
    const client_email = client!.customer_email;

    const result: any = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: props.firstName + ' ' + props.lastName,
        },
      },
    });

    if (result.error) {
      const errorCode = result?.error?.code;
      const txtError = errorsCard.find((el: any) => el.code === errorCode);
      if (txtError) {
        setTxtError(txtError?.message);
        props?.handleErrorCard && props?.handleErrorCard();
      } else {
        setTxtError('An Error occurred');
        props?.handleErrorCard && props?.handleErrorCard();
      }
      setSettingSetupIntentProcess(false);
    } else {
      (async () => {
        const params = {
          description:
            'BUY_PREMIUM_SUBSCRIPTION [Program: ' +
            oemProgram?.program.program_name +
            ']',
          currency: 'usd',
          type: 'STRIPE',
          oem_program: oemProgram?.program.id,
        };
        const response = await getPaymentIntent(params, userReducer?.token);

        if (response?.status === 200) {
          const { paymentIntent }: any = await stripe?.confirmCardPayment(
            response?.data?.client_secret,
            {
              payment_method: {
                card: elements.getElement(CardElement)!,
              },
            }
          );
          if (paymentIntent?.status == 'succeeded') {
            const res = await postPremiumPlan(paymentIntent?.id);
            if (res.status == 200 && res.data?.invoice_number) {
              if (saveInformations) {
                let payment_method = result.setupIntent.payment_method;
                /** register with customer id */
                (async () => {
                  let response = await setSetupIntentSetting(
                    userReducer.token,
                    payment_method!,
                    client_email,
                    entityId,
                    props.firstName || '',
                    props.lastName || ''
                  );
                  if (response?.status === 200) {
                    setSettingSetupIntentProcess(false);
                  }
                })();
              }
              dispatch(handleUpdatePlanDetail(res.data?.subscription));
              dispatch(handleUpdateSubscriptionDates({"key": "subscription_begin_at", "data": res.data?.subscription_dates.subscription_begin_at}));
              dispatch(handleUpdateSubscriptionDates({"key": "subscription_end_at", "data": res.data?.subscription_dates.subscription_end_at}));

              props.setInvoiceNumber(res.data?.invoice_number);
              props.setShowPaymentSuccessfullModal(true);
            }
          } else {
            props.setShowPaymentErrorModal(true);
          }
          setSettingSetupIntentProcess(false);
          props.setShowCardRegistrationForm(false);
        }
      })();
    }
  };

  const postPremiumPlan = async (paymentId: string) => {
    const params = {
      paymentId: paymentId,
      oemProgram: oemProgram?.program?.id,
      amount: 1500,
    };
    const response = await postData(
      url.subscription_plan.postPremium,
      params,
      userReducer.token
    );
    return response;
  };

  useEffect(() => {
    //data user
    const { entityType, entityId } = getUserTypeAndCompanyId();
    const foramtUrl: string =
      url.settings.billingCenter +
      '/get_setup_intent_setting/' +
      entityType +
      '/' +
      entityId;

    async function getClient() {
      setGettingSetupIntent(true);
      const response: any = await getDataService(
        foramtUrl,
        {},
        userReducer.token
      );
      setGettingSetupIntent(false);
      if (response?.status === 200) {
        setClient(response.data);
        setEntityId(entityId);
        props?.setFirstName(response.data?.registered_first_name);
        props?.setLastName(response.data?.registered_last_name);
      }
    }
    getClient();
  }, []);

  const chageCardElement = () => {
    setTxtError(undefined);
  };

  return {
    handleSubmit,
    gettingSetupIntent,
    client,
    stripe,
    settingSetupIntentProcess,
    txtError,
    chageCardElement,
    setSaveInformations,
    saveInformations,
  };
}
