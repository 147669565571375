import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import { getDataService } from "../../../../../service/applicatif/back-end-service";
import { url } from "../../../../../service/constant";
import { mounths } from "../../../../../utils";

export type IPropsMemberBilling = {
  user_uri: string;
  user_first_name: string;
  user_last_name: string;
  user_role: string;
  user_photo: string;
  POST_PRESS_RELEASE: number;
  UNLOCK_INVESTOR: number;
  POST_CLASSIFIED_ADS: number;
};
export default function UseIsvMemberPlan() {
  const [userInfo, setUserInfo] = useState<Array<IPropsMemberBilling>>([]);
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const mounthIndex = new Date().getMonth();
  const currentYears = new Date().getFullYear();

  const selectedIndex = mounths?.find(
    (el: { id: number; value: string }) => el?.id === mounthIndex
  );

  const [index, setIndex] = useState<number>(selectedIndex?.id || 0);

  const handleSelect = async (selectedIndex: number, e: any) => {
    const mounthSelected = mounths.find(
      (el: { id: number; value: string }) => el.id === selectedIndex
    );
    setIndex(selectedIndex);

    const txtMounth = new Date().getFullYear() + "-" + mounthSelected?.label;
    const result = await get(txtMounth);
    if (result?.status === 200) {
      setUserInfo(result?.data?.member_information?.data);
    }
  };

  const get = async (mounthSelected: string) => {
    const data = {
      month: mounthSelected,
    };
    const result = await getDataService(
      url.settings.member_company_billing,
      data,
      userReducer.token
    );
    return result;
  };

  useEffect(() => {
    async function getMember() {
      const year = new Date().getFullYear();
      const mounth = new Date().getMonth() * 1 + 1;
      const defaultDate = year + "-" + mounth + "-01";

      const result = await get(defaultDate);
      if (result?.status === 200) {
        setUserInfo(result?.data?.member_information?.data);
      }
    }
    getMember();
  }, []);

  return { userInfo, handleSelect, index, currentYears };
}
