/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-already/header";
import UseCompleteInfoInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-info-investor";
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { isEmail } from "../../../../../utils";

export default function completeInfoInvestor() {
  const {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    loader,
    txtEmailError,
    emailNotSafe,
    checkIfAlreadyEmailExist,
  } = UseCompleteInfoInvestor();
  return (
    <div className="layoutinvestor">
      <Header />
      <section className="investor">
        <form className="getstartedContainer" onSubmit={handleSubmit(onSubmit)}>
          <div className="formInvestor">
            <h1 className="title">
              Investor registration
              <span>Getting started with Devinsider is fast and free.</span>
            </h1>
            <div className="formInfo">
              <div className="form-group required pb-2">
                <label htmlFor="">First name </label>

                {errors?.firstNameInvestor && (
                  <div className={`container-error pl-2 d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}

                <input
                  type="text"
                  className="input-text form-control"
                  id="firstname"
                  {...register("firstNameInvestor", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-group required pb-2">
                <label htmlFor="">Last name </label>

                {errors?.lastNameInvestor && (
                  <div className={`container-error pl-2 d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}

                <input
                  type="text"
                  className="input-text form-control"
                  id="lastname"
                  {...register("lastNameInvestor", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-group required pb-2 safeMail">
                <label
                  htmlFor=""
                  className={`labelForEmailgetStarted ${
                    emailNotSafe?.toString() === "false" ? "safeMail" : ""
                  }`}
                >
                  {emailNotSafe?.toString() === "false" && (
                    <CheckIcon className="mailcheckOK" />
                  )}
                  Email
                </label>

                {errors?.emailInvestor && (
                  <div className={`container-error pl-2 d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}

                {emailNotSafe === "true" && (
                  <div className="container-error d-inline pl-3">
                    <span className="error-red"> error </span>
                    <span className="txt-error">{txtEmailError}</span>
                  </div>
                )}

                <input
                  type="email"
                  className="input-text form-control"
                  id="email"
                  {...register("emailInvestor", {
                    required: true,
                    validate: (email: string) => isEmail(email),
                  })}
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    checkIfAlreadyEmailExist(e.target.value);
                  }}
                />
              </div>
              <div className="form-group required pb-2">
                <label htmlFor="">Password</label>
                {errors?.passwordIvestor?.type === "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required </span>
                  </div>
                )}
                {errors?.passwordIvestor?.type === "minLength" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password length must be 12 or more characters
                    </span>
                  </div>
                )}
                {errors?.passwordIvestor?.type === "pattern" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password must contain at least one uppercase, lowercase,
                      special character and one number
                    </span>
                  </div>
                )}
                <input
                  type="password"
                  className="input-text form-control"
                  id="password"
                  {...register("passwordIvestor", {
                    required: true,
                    minLength: 12,
                    pattern:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/,
                  })}
                />
              </div>
              <div className="form-group required pb-2">
                <label htmlFor="">Verify password</label>

                {errors?.confirmPasswordInvestor?.type === "validate" && (
                  <div className={`container-error pl-2 d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password confirm should be same as field password
                    </span>
                  </div>
                )}

                {errors?.confirmPasswordInvestor?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}

                <input
                  type="password"
                  className="input-text form-control"
                  id="vpassword"
                  {...register("confirmPasswordInvestor", {
                    required: true,
                    validate: () =>
                      getValues("passwordIvestor") ===
                      getValues("confirmPasswordInvestor")
                        ? true
                        : false,
                  })}
                />
              </div>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("acceptCguInvestor", {
                  required: true,
                })}
              />
              <label htmlFor="" className="form-check-label termsLink">
                I agree to Devinsider’s&nbsp;
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/terms-and-conditions", "_blank");
                  }}
                >
                  terms
                </a>{" "}
                and &nbsp;
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/privacy-policy", "_blank");
                  }}
                >
                  privacy policy
                </a>
                .
              </label>

              {errors?.passwordIvestor && (
                <div className={`container-error pl-2 d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </div>
            <div className="pt-3">
              <button
                className="btn btn-devinsider large containerBtnBtn"
                type="submit"
              >
                {loader ? <Loader /> : " Sign Up"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
