import { IAction } from "../../types/type-action";
import { ICompanyPageInvestor } from "../../../interface/compnay-page-investor";
import * as CONSTANTE from "../../actions/company-page-investor-action/my-investor-page-action";

export const initialState: ICompanyPageInvestor = {
  id: "0",
  job_title: "",
  organization_name: "",
  phone_number: "",
  email: "",
  number_of_members: undefined,
  website: "",
  location: "",
  country: {
    id: undefined,
    country_name: "",
  },
  isv_company_size: undefined,
  isv_annual_turnover: null,
  min_company_size: 0,
  max_company_size: 0,
  min_annual_turnover: undefined,
  max_annual_turnover: undefined,
  min_fundraising_goal: 0,
  max_fundraising_gol: 0,
  isv_find_me: false,
  photo_profil: "",
  photo_couverture: "",
  status: false,
  published_at: "",
  description: "",
  about: "",
  community_profile: "",
  segments: [],
  targeted_industry: [],
  investor_type: {
    id: undefined,
    name: "",
  },
  institutional_investor_type: {
    id: undefined,
    name: "",
  },
  targeted_country: [],
  investorPostClassifiedAds: {
    number_left: undefined,
    next_grant_at: "",
    amount: undefined,
  },
  investor_strategic_partnership: [],
  investor_funding_round: [],
  linkedin: "",
  validate_by_devinsider: false,
  payment_method: "",
  membersInvitations: [],
  investorMembers: [],
  segment_details: [],
  investorPageResources: [],
  investorPagePortfolios: [],
  photo_profil_draft: null,
  photo_cover_draft: null,
  investor_page_draft: null,
  investorPageExpertises: [],
  phone_number_code: "",
  showUpdatedFlag: false,
  closed: false
};
export const companyInvestorPageReducer = (
  state = initialState,
  action: IAction
): ICompanyPageInvestor => {
  switch (action.type) {
    //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
    case CONSTANTE.UNPUBLISH_PROGRAM:
      return {
        ...state,
        status: false,
      };
    case CONSTANTE.REMOVE_LOGO_DRAFT:
      return {
        ...state,
        photo_profil_draft: action.payload.photo_profil_draft,
        photo_profil: action.payload.photo_profil,
      };
    case CONSTANTE.REMOVE_COUVERTURE_DRAFT:
      return {
        ...state,
        photo_cover_draft: action.payload.photo_couverture_draft,
        photo_couverture: action.payload.photo_couverture,
      };
    case CONSTANTE.GET_COMPANY_PAGE_INVESTOR: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return { ...state, ...action.payload };
    }
    case CONSTANTE.UPDATE_COMPANY_PAGE_INVESTOR:
      return { ...state, ...action.payload };
    case CONSTANTE.UPDATE_COMPANY_PAGE_INVESTOR_PAYMENT_METHOD:
      return { ...state, payment_method: action.payload };
    case CONSTANTE.UPDATE_DRAFT_DATA: {
      return {
        ...state,
        investor_page_draft: action?.payload?.investorPageDraft,
        photo_cover_draft: action?.payload?.photoCoverDraft || "",
        photo_profil_draft: action?.payload?.photoProfileDraft || "",
      };
    }

    case CONSTANTE.UPDATE_DRAFT_RESSOURCE_DATA:
      return { ...state, investorPageResources: action.payload };
    case CONSTANTE.UPDATE_DRAFT_EXPERTISE_DATA:
      return {
        ...state,
        investorPageExpertises: [...action.payload],
      };

    case CONSTANTE.REMOVE_RESOURCE_FILE:
      return {
        ...state,
        investorPageResources: helperFindRemove(
          state?.investorPageResources,
          action.payload
        ),
      };

    case CONSTANTE.UPDATE_BILLING_CENTER_CARD_INVESTOR:
      return {
        ...state,
        payment_method: action.payload,
      };

    case CONSTANTE.UPDATE_DRAFT_PORTFOLIO_DATA:
      return {
        ...state,
        investorPagePortfolios: action?.payload,
      };

    case CONSTANTE.UPDATE_OR_PUBLISH_DRAFT__DATA:
      return {
        ...state,
        ...action?.payload,
      };

    case CONSTANTE.GET_INVESTOR_ATTRIBUT:
      return {
        ...state,
        [action?.payload?.key]: action?.payload?.data,
      };

    default:
      return state;
  }
};

const helperFindRemove = (state: any, value: any) => {
  let found = state?.find((el: any) => el?.id == value?.id);
  let returnValue = [];
  if (found) {
    returnValue = [...state?.filter((el: any) => el.id != value?.id)];
  } else {
    returnValue = [value, ...state];
  }
  return returnValue;
};
