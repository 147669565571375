import React, { useState } from 'react';
import Header from "../../widget/index";
import Author from '../../../resources/images/team1.png';
import CompanyImg from '../../../resources/images/splunk.png';
import "./software-labeling.scss";

export default function ads() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [show, setShow] = useState(false);
    
    return (
        <>
        <Header />
            <section className='classified mergers details'>
                <div className='container mx-auto'>
                    <div className='leftSection'>
                        <div className='block'>
                            <div className='leftItem itemAuthor'>
                                <div className='imageAffiliatedcompany empty'>
                                    <img src={Author} alt="" />
                                </div>
                                <div className=''>
                                    <div className='post'>Author:</div>
                                    <h3 className='title'>
                                        John
                                    </h3>
                                    <h3 className='title'>
                                    Meyer
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="block infoCompanyAds">
                            <div className="imgclassified empty">
                                <img src={CompanyImg} alt="" />
                            </div>
                            <div className="infoAds">Company:<h3 className="title">Splunk</h3>
                                {/*<div className="role">ISV</div>*/}
                                <div className="contry">Paris, France</div>
                            </div>
                        </div>
                        <div className="block solInd">
                            <h3 className="title">Solution</h3>
                            <div>AR/VR</div>
                            <div>Artificial Intelligence</div>
                            <div>IOT</div>
                        </div>
                        <div className="block solInd">
                            <h3 className="title">Industry</h3>
                            <div>Aerospace</div>
                            <div>Agriculture</div>
                            <div>Automotive</div>
                            <div>Construction</div>
                            <div>E-Commerce and online shopping</div>
                            <div>Finance</div>
                            <a href="javascript:;" className="seemore">See more(11)</a>
                        </div>
                    </div>
                    <div className="rightSection ads">
                        <h3>Looking for a partnership Anayltic Embedded Solution</h3>
                        <span className="mini-title">Alternative Financing</span>
                        <ul className="btn">
                            <li>View 3 messages</li>
                            <li className="pl-2 pr-2"><span className="icon-fav-line"></span></li>
                            <li className="pl-1 pr-1">
                                <a href="javascript:;" onClick={() => setShow(!show)} ><span className="icon-inprogress"></span></a>
                            </li>
                            {show && (
                            <ul className="editListing">
                                <li>Edit listing</li>
                                <li>Delete</li>
                            </ul>
                        )}
                        </ul>
                        <div>
                        Lorem ipsum dolor sit amet, <br/><br/>

                        Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.<br/><br/>

                        Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.<br/><br/>
                            <div className="pl-4 mb-4">
                                - Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod ut laoreet<br/>
                                - Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat,<br/>
                                - Dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.<br/>
                            </div>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}