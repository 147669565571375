import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { IPropsPortfolio, PropsPortFolio } from "../types";
import { toast } from "react-toastify";
import {
  fileUrl,
  getSStorage,
  isURL,
  refaCtoNullKey,
  // renderLogo,
} from "../../../../../utils";
import { Link } from "react-router-dom";
import SofTwareSolution from "../../../../widget/software-solution/index";
import SolutionSpecialization from "../../../../widget/industries-list/index";
import DefaultCompany from "../../../../../resources/images/defaultCompany.png";

export default function EditPortfolio(props: IPropsPortfolio) {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const makeUrl = (fileObject: any, logo: string, url: string) => {
    return fileObject
      ? URL.createObjectURL(fileObject)
      : logo !== ""
      ? fileUrl(logo)
      : DefaultCompany;
  };
  const checkIsFile = (index: number) => {
    return (
      props?.initialPortFolio[index]?.dataFile ||
      props?.initialPortFolio?.[index]?.logo ||
      props?.initialPortFolio?.[index]?.url
    );
  };

  const makeNameFile =
    props.fileUrl && typeof props.fileUrl !== "string"
      ? URL.createObjectURL(props?.fileUrl)
      : fileUrl(props.fileUrl);

  let selectedSolutions = [
    ...props.solutionChecked,
    ...props.solutionCheckedFeatures,
  ];
  if (props?.filterSolutionChecked)
    selectedSolutions = props?.filterSolutionChecked(selectedSolutions);

  const filterDeleted = (data: Array<PropsPortFolio>) => {
    return data.filter((portfolio) => portfolio.isDeleted !== true);
  };

  const solutionCategories = () => {
    // var result = [];

    // for (var i = 0; i < props.solutionChecked.length; i++) {
    //   result.push(props.solutionChecked[i]);
    // }

    // for (var j = 0; j < props.solutionCheckedFeatures.length; j++) {
    //   result.push(props.solutionCheckedFeatures[j]);
    // }

    if (selectedSolutions.length > 3) {
      toast("Must be 3 solutions maximum");
    } else {
      props?.saveSolutionChecked();
    }
  };

  return (
    <>
      {props.isShowPortfolio && (
        <Modal
          show={props.isShowPortfolio}
          className="createCompanypopup large forStep5custom bgGris"
        >
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title"> Edit portfolio</h2>
              <Link
                to="#"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  props?.clausePortFolio();
                }}
              >
                <span className="icon-close-pop"></span>
              </Link>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="contentBorder row mx-auto modalInvestor">
              <div className="form-group col-12">
                <div className="btnAdd">
                  <button
                    className="btnShowPortfolio mod"
                    onClick={() => props.addOtherPortFolio()}
                  >
                    Add more to portfolio
                  </button>
                </div>
                <ul className="listSquareItem portfolio">
                  {filterDeleted(props?.initialPortFolio)?.map(
                    (portfolio, index) => (
                      <li key={index}>
                        <Link
                          to={"#"}
                          className="removeImg"
                          onClick={(e) => {
                            e.preventDefault();
                            props.removeItemsValue(index);
                          }}
                        >
                          <span className="icon-close-pop"></span>
                        </Link>
                        <Link
                          to={"#"}
                          className={checkIsFile(index) ? "" : "empty"}
                          onClick={(e) => {
                            e.preventDefault();
                            props?.showModal(index.toString());
                          }}
                        >
                          <div className="logo">
                            {checkIsFile(index) && (
                              <img
                                id={"imageModal_" + index * 20}
                                onError={() => {
                                  const imgElement: any =
                                    document.getElementById(
                                      "imageModal_" + index * 20
                                    );
                                  if (imgElement) {
                                    imgElement.src = DefaultCompany;
                                  }
                                }}
                                src={makeUrl(
                                  portfolio?.dataFile,
                                  portfolio?.logo,
                                  portfolio?.url
                                )}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="namePortfolio">
                            {refaCtoNullKey(portfolio?.name, "")}
                          </div>
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right forPitch">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props?.clausePortFolio()}
                type="button"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-devinsider px-5"
                onClick={() => props?.updateListePortFolio()}
              >
                {props?.loader ? <Loader /> : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props?.show && (
        <Modal
          show={props?.show}
          className="createCompanypopup large forStep5custom"
        >
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">Add company</h2>
              <Link
                to={"#"}
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  props?.closeModal();
                }}
              >
                <span className="icon-close-pop"></span>
              </Link>
            </div>
          </Modal.Header>
          <form onSubmit={props?.handleSubmit(props?.submit)}>
            <Modal.Body className="contentBorder forPortfolio">
              <div className="contentBorder">
                <div className="logo text-center">
                  <label
                    htmlFor=""
                    title="Your logo"
                    style={{
                      position: "relative",
                      color: "#1897c8",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {!props.fileUrl && (
                      <>
                        <span className="icon-close-pop"></span>Logo
                      </>
                    )}

                    {props.errorEmpty && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          To save this company please add the company name and
                          website.
                        </span>
                      </div>
                    )}

                    {!props.fileUrl && (
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/*"
                        style={{
                          top: 0,
                          left: 0,
                          position: "absolute",
                          zIndex: 25,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          props.setFilePortfolio(event);
                        }}
                      />
                    )}

                    {props?.fileUrl ? (
                      <img className="photoOk" src={makeNameFile} alt="" />
                    ) : (
                      <Link to={"#"} className="upload-photo mx-auto">
                        <span />
                      </Link>
                    )}
                  </label>
                </div>
              </div>
              <div className="border-top row pt-4">
                <div className="form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 required">
                  <label htmlFor="">
                    Company name
                    {props.errors.name?.type === "validate" && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    className="input-text form-control"
                    {...props?.register("name", {
                      validate: (cn: string) => {
                        if (!cn) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                    })}
                  />
                </div>
                <div className="form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 required">
                  <label htmlFor="">
                    Website
                    {props.errors.linkLogo?.type === "validate" && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">Url not valide</span>
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    className="input-text form-control"
                    {...props?.register("linkLogo", {
                      validate: (url: string) => {
                        if (url) {
                          if (url && isURL(url)) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      },
                    })}
                  />
                </div>
              </div>
              <div className="row pb-4">
                <div className="form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
                  <label htmlFor="hqlocation">
                    HQ location
                    {props.errors.hqLocation?.type === "validate" && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                  <select
                    id=""
                    className="custom-select"
                    {...props?.register("hqLocation", {
                      validate: (location: number) => {
                        if (!location) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                    })}
                  >
                    <option value="">Country...</option>
                    {dataCompleted?.country?.map((el: any) => (
                      <option key={el.id} value={el.id}>
                        {el.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row pb-4 selectSol">
                <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                  <div
                    className="btn btn-devinsider"
                    onClick={() => props.handleShowIndustry()}
                  >
                    Select solution category(ies)
                  </div>

                  <div className="listOf more">
                    {selectedSolutions.map((element: any, index: number) => (
                      <span className="topic" key={index}>
                        {element?.name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="d-flex mb-1 w-100 align-items-center">
                  <div
                    className="btn btn-devinsider"
                    onClick={() => props.showSolutionCategory()}
                  >
                    Select industry(ies)
                  </div>

                  <div className="listOf more">
                    {props?.companyTargetSelected.map(
                      (element: any, index: number) => (
                        <span className="topic" key={index}>
                          {element?.name}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="contentBorder formForEmail text-right">
                <button
                  className="btn btn-devinsider cancel"
                  onClick={() => props?.closeModal()}
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-devinsider px-5">
                  {props?.loader ? <Loader /> : "Save"}
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {props.showIndustry && (
        <Modal
          show={props.showIndustry}
          className="createCompanypopup large forStep5custom"
        >
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">Select up to 3 solutions categories</h2>
              <Link
                to="#"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  props.setShowIndustry();
                }}
              >
                <span className="icon-close-pop"></span>
              </Link>
            </div>
          </Modal.Header>
          <Modal.Body>
            <SofTwareSolution {...props} />
          </Modal.Body>
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right forPitch">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props.setShowIndustry()}
                type="button"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-devinsider px-5"
                onClick={() => solutionCategories()}
              >
                {props?.loader ? <Loader /> : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.showSpecialization && (
        <Modal
          show={props.showSpecialization}
          className="createCompanypopup large forStep5custom"
        >
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">Select all industries that apply</h2>
              <Link
                to="#"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  props.closeSpecialisation();
                }}
              >
                <span className="icon-close-pop"></span>
              </Link>
            </div>
          </Modal.Header>
          <Modal.Body>
            <SolutionSpecialization {...props} />
          </Modal.Body>
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right forPitch">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props.closeSpecialisation()}
                type="button"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-devinsider px-5"
                onClick={() => props?.saveSelectedIndustrie()}
              >
                {props?.loader ? <Loader /> : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
