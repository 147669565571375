import React, { ChangeEvent } from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import MatchInvestor from "../../../../resources/images/matchInvestor.png";
import UseGetStartedInvestor from "../../../../controller/get-started-ctr/investor/use-get-started-investor";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import { isEmail, between } from "../../../../utils/index";
import showcase from "../../../../resources/images/findInvestor/showcase.png";
import match from "../../../../resources/images/findInvestor/match.png";
import raise from "../../../../resources/images/findInvestor/raise.png";
import seedMoney from "../../../../resources/images/findInvestor/Seed-Money.svg";
import serieA from "../../../../resources/images/findInvestor/Serie-A-Money.svg";
import bridgeFunding from "../../../../resources/images/findInvestor/Bridge-Funding.svg";
import mergerAcquisition from "../../../../resources/images/findInvestor/Merger-Acquisition.svg";
import stockBuybacks from "../../../../resources/images/findInvestor/Stock-Buybacks.svg";
import ipSale from "../../../../resources/images/findInvestor/IP-Sales.svg";

export default function GetStartedInvestor() {
  const validPhone = (txt: string): boolean => {
    if (between(5, 13, txt)) {
      return true;
    } else {
      return false;
    }
  };

  const {
    createAccountInvestor,
    register,
    handleSubmit,
    onSub,
    dataCompleted,
    loader,
    errors,
    setMessage,
    message
  } = UseGetStartedInvestor();
  return (
    <div>
      <Header />
      <section className="joinHome invest">
        <div className="container mx-auto">
          {/* Design & Integration #45540 investor page text updates */}
          <h1 className="title">Join Devinsider as an investor</h1>
          <a onClick={createAccountInvestor} className="getStartedLink">
            Get started
          </a>
        </div>
      </section>
      <section className="invOrange">
        <div className="container mx-auto">
          Devinsider is a global community dedicated to helping disruptive B2B
          software vendors to solve their business challenges
          <br />
          and establish relevant connections with investors, technology
          partners, and resellers.
        </div>
      </section>
      <section className="inclinereseller">
        <div className="container mx-auto">
          <div className="texte long">
            <h1 className="title">
              Identify and track fast growing software vendors worldwide{" "}
            </h1>
            <p className="text-center pb-5">
              Devinsider offers the tools that VCs and other institutional
              investors need <br />
              to efficiently identify investment opportunities.
            </p>
            <div className="identifyList">
              <div className="item find">
                <h2 className="title">
                  Find software vendors that are <br />
                  actively seeking investments
                </h2>
                <ul>
                  <li>Seed and A round investment opportunities</li>
                  <li>
                    Initiate discussions with founders and/or management
                    directly on Devinsider
                  </li>
                </ul>
              </div>
              <div className="item exposure">
                <h2 className="title">
                  Create exposure for your
                  <br />
                  organization
                </h2>
                <ul>
                  <li>
                    Inbound inquiries of ISVs that match with your criteria
                  </li>
                  <li>Increase mindshare through community management</li>
                </ul>
              </div>
              <div className="item protect">
                <h2 className="title">
                  Protect your investments
                  <br />
                  <span className="hidden">a</span>
                </h2>
                <ul>
                  <li>Run diligence and benchmark investment opportunities</li>
                  <li>
                    Identify software trends and expand market intelligence
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inclinereseller">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">Match with early stage software vendors</h1>
            <p>
              Create your investor page and connect with early stage B2B
              software vendors based upon your preferences. Easily compare
              company profiles according to technology focus and vision. Build
              your network on Devinsider.
            </p>
            <ul className="listOfMatch">
              <li className="easy">Easy to use</li>.
              <li className="time">Time efficient</li>
              <li className="dedicated">Dedicated support</li>
            </ul>
            <a
              href="javascript:;"
              className="getStartedLink"
              onClick={(e) => {
                e.preventDefault();
                createAccountInvestor();
              }}
            >
              Get started
            </a>
          </div>
          <img src={MatchInvestor} alt="" />
        </div>
      </section>
      <section className="inclinereseller">
        <div className="container mx-auto">
          <div className="texte long">
            <h1 className="title">
              Devinsider is used by various investor types
            </h1>
            <ul className="variousInvestor">
              <li className="venture">Venture capitalist</li>
              <li className="businessAngel">Incubators</li>
              <li className="investement">Investment groups & funds</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="partener investorPage contactUs">
        <div className="container mx-auto ">
          <div className="getintouch mx-auto">
            <h1 className="title">Contact us </h1>
            <p>
              To learn more about the benefits of joining Devinsider, <br />
              please contact us.
            </p>
            <form onSubmit={handleSubmit(onSub)}>
              <div className="formGetInTouch row px-3">
                <div className="col-12 form-group px-0">
                  {errors.email?.type == "required" && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  {errors.email?.type == "validate" && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        Your email address is not valid
                      </span>
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    {...register("email", {
                      required: true,
                      validate: (v) => isEmail(v),
                    })}
                  />
                </div>
                <div className="col-12 form-group px-0">
                  {errors.phone?.type == "required" && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  {errors.phone?.type == "validate" && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        The phone number is not a valid format
                      </span>
                    </div>
                  )}
                  <input
                    type="number"
                    placeholder="Phone"
                    className="form-control"
                    {...register("phone", {
                      required: true,
                      validate: (v) => validPhone(v),
                    })}
                  />
                </div>
                <div className="lastandfirstname col-12 px-0">
                  <div className="form-group">
                    {errors.firstName && (
                      <div className={`container-error pl-2 "d-inline`}>
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                    <input
                      type="text"
                      placeholder="First name"
                      className="form-control"
                      {...register("firstName", { required: true })}
                    />
                  </div>

                  <div className="form-group">
                    {errors.lastName && (
                      <div className={`container-error pl-2 "d-inline`}>
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      {...register("lastName", { required: true })}
                    />
                  </div>
                </div>
                {/*<div className="col-12 form-group px-0">
                  {errors.jobTitle && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job title"
                    {...register("jobTitle", { required: true })}
                  />
                </div>*/}

                <div className="col-12 form-group px-0">
                  {errors.companyName && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Company"
                    className="form-control"
                    {...register("companyName", { required: true })}
                  />
                </div>

                <div className="col-12 form-group px-0">
                  {errors.region && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  <select
                    id="pet-select"
                    className="custom-select"
                    {...register("region", { required: true })}
                  >
                    <option value="">Country</option>
                    {dataCompleted?.country?.map(
                      (el: {
                        continent: string;
                        country_name: string;
                        id: string;
                      }) => (
                        <option value={el.id}>{el.country_name}</option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-12 form-group px-0 moreinfo">
                  <textarea
                    name="moreinfo"
                    id="more"
                    className="input-text form-control"
                    placeholder="Your message..."
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      setMessage(event.target.value)
                    }
                  />
                </div>

                <div className="form-group form-check px-0 col-12 checkContent">
                  {errors.acceptCgu && (
                    <div className={`container-error pl-2 "d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  <input
                    type="checkbox"
                    {...register("acceptCgu", { required: true })}
                  />
                  <label className="noabs">
                    Yes, Devinsider may contact me for further discussion.
                    <br /> (
                    <span
                      onClick={() => window.open("/privacy-policy", "_blank")}
                    >
                      Privacy policy
                    </span>
                    )
                  </label>
                </div>
                <div className="col-12 px-0">
                  <button
                    type="submit"
                    className="btn btn-devinsider large containerBtnBtn"
                  >
                    {loader ? <Loader /> : "Send"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
