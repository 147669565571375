import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { checkIfEmailExist } from "../../../service/applicatif/acount";
import {
  isEmail,
  setSessionStorageObject,
  getSStorage,
} from "../../../utils/index";
import { useForm } from "react-hook-form";
const queryString = require("query-string");

export type IPropsInputIsv = {
  firstNameIsv: string;
  lastNameIsv: string;
  passWord: string;
  emailIsv: string;
  acceptPrivacyTerms: boolean;
};
export default function useCompleteInfoCtr() {
  const history = useHistory();
  let params: any = useLocation();
  const refForm: any = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPropsInputIsv>({
    defaultValues: {
      firstNameIsv: getSStorage("firstName")
        ? getSStorage("firstName") || ""
        : "",
      lastNameIsv: getSStorage("lastName") ? getSStorage("lastName") || "" : "",
      passWord: getSStorage("password") ? getSStorage("password") || "" : "",
      emailIsv: getSStorage("email") ? getSStorage("email") || "" : "",
      acceptPrivacyTerms: JSON.parse(
        sessionStorage?.getItem("acceptPrivacyTerms") || "false"
      ),
    },
  });
  /* 
  Liste of all state in formumaire
  */
  const [firstName, setFirstName] = useState<string>(
    getSStorage("firstName") ? getSStorage("firstName") || "" : ""
  );
  const [lastName, setLastName] = useState<string>(
    getSStorage("lastName") ? getSStorage("lastName") || "" : ""
  );
  const [password, setPassword] = useState<string>(
    getSStorage("password") ? getSStorage("password") || "" : ""
  );
  const [email, setEmail] = useState<string>(
    getSStorage("email") ? getSStorage("email") || "" : ""
  );

  const [token, setToken] = useState<string>("");

  //token odoo
  const [tokenForOdoo, setTokenForOdoo] = useState(
    queryString.parse(params?.search)?.token ?? ""
  );

  /*
   *Error email alreasy use
   */
  const [emailSafe, setEmailSafe] = useState<string>("");

  /*
   * Loader Email check
   */
  const [loader, setloader] = useState<boolean>(false);

  /*
   * Email false to show
   */
  const [emailNotSafe, setEmailNotSafe] = useState<string>("");

  /*
   * Text message error
   */
  const [txtEmailError, setTxtEmailError] = useState("");
  const [txtPasswordError, setTxtPasswordError] = useState("");

  const checkIfAlreadyEmailExist = async (
    email: string
  ): Promise<boolean | undefined> => {
    if (isEmail(email)) {
      setloader(true);
      try {
        let response = await checkIfEmailExist(email, true);

        if (response?.status === 200 && response?.data?.details) {
          if (
            response?.status === 200 &&
            response?.data?.reason === "invalid domain"
          ) {
            setTxtEmailError("Professional email is required");
          } else {
            setTxtEmailError("This email address is already  used");
          }
          setloader(false);
          setEmailNotSafe("true");
          return false;
        } else if (
          response?.status === 200 &&
          response?.data?.details === false
        ) {
          setEmailNotSafe("false");
          setloader(false);
          return true;
        } else {
          setloader(false);
          setEmailNotSafe("true");
          setTxtEmailError("An error occurred");
          return false;
        }
      } catch (error: any) {
        return false;
      }
    }
  };

  /*
   * Show screen form adress
   * return void
   */
  const navigateToCompleteAdresse = async (): Promise<void> => {
    handleSubmit(onSubmit);
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      refForm?.current?.click();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const onSubmit = async (data: IPropsInputIsv) => {
    setloader(true);
    /**
     * Check if email not safe is empty (business email is not checking)
     * Check if email not is true
     */
    let statusEmail: boolean | undefined = true;

    if (emailNotSafe === "" || emailNotSafe === "true") {
      statusEmail = await checkIfAlreadyEmailExist(data?.emailIsv);
    }
    /**
     * email not safe == false , is business email
     */
    if (statusEmail) {
      let infoUser = {
        firstName: data?.firstNameIsv,
        lastName: data?.lastNameIsv,
        password: data?.passWord,
        email: data?.emailIsv,
        acceptCgu: data?.acceptPrivacyTerms,
        crmodtoken: tokenForOdoo,
      };
      setSessionStorageObject({
        ...infoUser,
      });
      history.push("/complete-adresse", {
        ...infoUser,
        completedInfo: true,
      });

      setloader(false);
    }
  };
  return {
    firstName,
    lastName,
    email,
    password,
    token,
    txtEmailError,
    txtPasswordError,
    emailSafe,
    loader,
    emailNotSafe,
    history,
    setToken,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    navigateToCompleteAdresse,
    checkIfAlreadyEmailExist,
    onSubmit,
    register,
    handleSubmit,
    errors,
    refForm,
  };
}
