import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import UseSettings from "../../../controller/settings-ctr/use-settings";

export default function ManageFeedsCtr() {
  const history = useHistory();
  const providerSettings = { ...UseSettings() };

  const goToNotification = () => {
    providerSettings?.setTabToShow("notifications");
    history?.push("/settings", {
      tabToShow: "notifications",
    });
  };
  return { goToNotification };
}
