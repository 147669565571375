import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { rootState } from "../../../redux/reducers";
import {
  deleteMethode,
  getDataService,
  postDataFormDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { fileUrl } from "../../../utils";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import { useLocation } from "react-router-dom";

type Iprops = {
  show: boolean;
  setShow: (p: boolean) => void;
  reloadFilter: () => void;
};
export default function ModalBlackListe(props: Iprops) {
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderRemove, setLoaderRemove] = useState<boolean>(false);

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);
  const investorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const [allSelectedFile, setAllSelectedFile] = useState<Array<any>>([]);

  const [curentFileSelected, setCurentFileSelected] = useState<File | null>();

  const [showAddOtherBlacklist, setShowAddOtherBlacklist] =
    useState<boolean>(false);

  const fileRef: any = useRef(null);
  const location: any = useLocation();

  useEffect(() => {
    async function getAllBlaclistFile() {
      if (oemProgram?.program?.id !== "0") {
        const response = await getDataService(
          url.findIsv.black_liste_file + oemProgram?.program?.id,
          {},
          userReducer.token
        );
        if (response?.status === 200) {
          setAllSelectedFile(response.data);
        }
      } else {
        const response = await getDataService(
          url.matching_investor_isv.black_liste_file_investor +
            investorPage?.id,
          {},
          userReducer.token
        );
        if (response?.status === 200) {
          setAllSelectedFile(response.data);
        }
      }
    }
    if (props.show) {
      getAllBlaclistFile();
    }
  }, [props.show]);

  const validateFileSave = async () => {
    if (curentFileSelected) {
      if (oemProgram?.program?.id !== "0") {
        const params = {
          blacklist_file: curentFileSelected,
          id_oem: oemProgram?.program?.id,
        };
        setLoader(true);
        const response = await postDataFormDataService(
          params,
          url.findIsv.save_black_list_file,
          userReducer.token
        );
        if (response?.status === 200) {
          setAllSelectedFile([...allSelectedFile, response?.data]);
          setCurentFileSelected(null);
          setLoader(false);
          setShowAddOtherBlacklist(true);
          const isInFilter = location?.pathname === "/isv/match";
          isInFilter && props?.reloadFilter();
        } else {
          setCurentFileSelected(null);
          setLoader(false);
          fileRef.current.value = "";
          if (allSelectedFile.length > 0) {
            setShowAddOtherBlacklist(true);
          }
          toast("Your Excel file does not match the requested file");
          const isInFilter = location?.pathname === "/isv/match";
          isInFilter && props?.reloadFilter();
        }
      } else {
        const params = {
          blacklist_file: curentFileSelected,
          id_investor: investorPage?.id,
        };
        setLoader(true);
        const response = await postDataFormDataService(
          params,
          url.matching_investor_isv.save_black_list_file_investor,
          userReducer.token
        );
        if (response?.status === 200) {
          setAllSelectedFile([...allSelectedFile, response?.data]);
          setCurentFileSelected(null);
          setLoader(false);
          setShowAddOtherBlacklist(true);
          const isInFilter = location?.pathname === "/isv/match";
          isInFilter && props?.reloadFilter();
        } else {
          setCurentFileSelected(null);
          setLoader(false);
          fileRef.current.value = "";
          if (allSelectedFile.length > 0) {
            setShowAddOtherBlacklist(true);
          }
          toast("Your Excel file does not match the requested file");
          const isInFilter = location?.pathname === "/isv/match";
          isInFilter && props?.reloadFilter();
        }
      }
    }
  };

  const downLoadFilExel = async () => {
    const result = await getDataService(
      url.findIsv.get_exel_file,
      {},
      userReducer.token
    );
    if (result?.status === 200) {
      const resulturl = result?.data?.url;
      // Construct the 'a' element
      var link = document.createElement("a");
      link.download = "filename";
      link.target = "_blank";
      // Construct the URI
      link.href = fileUrl(resulturl);
      document.body.appendChild(link);
      link.click();

      // Cleanup the DOM
      document.body.removeChild(link);
    }
  };
  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentFile = event?.target?.files?.[0];

    if (currentFile) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      let fileSize = currentFile?.size;
      const isUpLimit = fileSize > 1500000;

      if (currentFile?.type !== fileType) {
        return toast(
          "File type .xlsx and same format of template is only accepted"
        );
      }

      if (isUpLimit) {
        return toast("Max resources file size is 1.5 Mo ");
      }

      if (currentFile) {
        const fileName = currentFile?.name;
        const fileAllreadyExist = allSelectedFile?.find(
          (el) => el?.original_file_name === fileName
        );
        const isFileisRenamed = fileName !== "blacklist_template.xlsx";
        if (!fileAllreadyExist && isFileisRenamed) {
          setCurentFileSelected(currentFile);
        } else {
          return toast(
            "Rename the template blacklist or verifie if the file is not exist"
          );
        }
      }
    }
  };

  const fileClick = () => {
    // if (fileRef) {
    fileRef?.current?.click();
    // }
  };

  const removeBlackListeFile = async (id: number) => {
    setLoaderRemove(true);

    if (oemProgram?.program?.id !== "0") {
      const response = await deleteMethode(
        url.findIsv.remove_black_liste_file + id,
        userReducer.token
      );
      if (response?.status === 200) {
        const filterdAllFile = allSelectedFile?.filter(
          (temp) => temp?.id.toString() !== id?.toString()
        );
        setAllSelectedFile(filterdAllFile);
        const isInFilter = location?.pathname === "/isv/match";
        isInFilter && props?.reloadFilter();
      }
      setLoaderRemove(false);
    } else {
      const response = await deleteMethode(
        url.matching_investor_isv.remove_black_liste_file_investor + id,
        userReducer.token
      );
      if (response?.status === 200) {
        const filterdAllFile = allSelectedFile?.filter(
          (temp) => temp?.id.toString() !== id?.toString()
        );
        setAllSelectedFile(filterdAllFile);
        const isInFilter = location?.pathname === "/isv/match";
        isInFilter && props?.reloadFilter();
      }
      setLoaderRemove(false);
    }
  };

  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Blacklist</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props.setShow(false);
            }}
          >
            <span className="icon-close-pop" />
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="blacklistPopup">
          <h3 className="title">How a blacklist works</h3>
          <p>
            Uploading a blacklist of your current customers and/or leads
            prevents you and your representative(s) from unlocking ISVs that
            have been already identified by your company. <br />
            In order to successfully deduplicate your data, you must use the
            template below and{" "}
            <u>
              fill out the website column and at least one of the company name
              columns.
            </u>
            <br />
            The maximum number of line in each file is 1000.
          </p>
          <table className="listOfBlacklist">
            <tr className="title">
              <td>
                Company trade name{" "}
                <a onClick={(e) => e.preventDefault()}>
                  <span className="icon-help-circle"></span>
                  <span className="bulle">
                    Please enter the company’s trade name,
                    <br />
                    or the name commonly used for public reference
                    <br /> (commonly exludes “Inc.”, “LLC”, etc…)
                  </span>
                </a>
              </td>
              <td>
                Company legal name{" "}
                <a onClick={(e) => e.preventDefault()}>
                  <span className="icon-help-circle"></span>
                  <span className="bulle">
                    Please enter the company’s legal name <br />
                    (commonly ending in “Inc”, “LLC”, etc…) <br />
                    This is the name that is registered with the state of
                    incorporation/registration.
                  </span>
                </a>
              </td>
              <td>
                Website{" "}
                <a onClick={(e) => e.preventDefault()}>
                  <span className="icon-help-circle"></span>
                  <span className="bulle">
                    Begin with http:// or https:// or www.
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Devinsider</td>
              <td>Devinsider inc</td>
              <td>
                <a href="javascript:;" onClick={(e) => e.preventDefault()}>
                  www.test.com
                </a>
              </td>
            </tr>
          </table>
          <button
            className="btn btn-devinsider cancel downloadBtn"
            onClick={() => downLoadFilExel()}
          >
            Download blacklist template
          </button>
          <ul className="listAddFile">
            {allSelectedFile?.map((tempBlackListe: any, index: number) => (
              <li key={index} className="pointer">
                <div className="choseFile">
                  <div className="containerfilechoosed">
                    <div
                      onClick={() =>
                        window.open(
                          fileUrl(tempBlackListe?.file_name),
                          "_blank"
                        )
                      }
                    >
                      {tempBlackListe?.original_file_name}
                    </div>{" "}
                    {!loaderRemove && (
                      <a
                        href="javascript:;"
                        className="remove"
                        onClick={(e) => {
                          e.preventDefault();
                          removeBlackListeFile(tempBlackListe?.id);
                        }}
                      >
                        <span className="icon-close" />
                      </a>
                    )}
                  </div>
                </div>
              </li>
            ))}

            <li>
              <div className="choseFile">
                <button
                  className="btn btn-devinsider chooseFile"
                  onClick={() => {
                    !loader && fileClick();
                  }}
                >
                  <span className="icon-folder"></span>
                  Choose file
                </button>
                <div className="containerfilechoosed">
                  {curentFileSelected ? curentFileSelected?.name : ""}
                </div>
              </div>

              <input
                type="file"
                name=""
                id=""
                ref={fileRef}
                style={{ display: "none" }}
                onChange={(evt) => {
                  changeHandler(evt);
                }}
              />
              {curentFileSelected && (
                <button
                  className="btn btn-devinsider save"
                  onClick={() => validateFileSave()}
                  disabled={loader}
                >
                  {loader ? <Loader /> : " Upload blacklist"}
                </button>
              )}

              {showAddOtherBlacklist && (
                <a
                  href="javascript:;"
                  className="addFile"
                  onClick={(e) => {
                    e.preventDefault();
                    !loader && fileClick();
                  }}
                >
                  Add another blacklist
                </a>
              )}
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
