import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import {
  getSStorage,
  isHaveCompanyLogo,
  isRequired,
  validateCompleteInfo,
} from "../../utils";

export default function UseCompleteIsvForFindIsv() {
  const [dataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const companyPageObject = useSelector(
    (state: rootState) => state?.companyPageReducer
  );
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progression = 0;

    let isNotCompletedSummary = !isRequired(companyPageObject?.company_summary);
    let isNotCompletedLogo = !isHaveCompanyLogo(companyPageObject.photo_profil);
    let isNotCompletedDistributionAndLicencing =
      !isRequired(companyPageObject?.licensing_model) ||
      !isRequired(companyPageObject?.distribution_channel);
    let isNotCompletedPartenerAndProgramInterested = !isRequired(
      companyPageObject?.oem_program_segment_interest
    );
    let isNotCompletedAnnualTurnOver = !isRequired(
      companyPageObject?.annual_turnover
    );
    let isNotCompletedFundingRound = !isRequired(
      companyPageObject?.funding_round
    );
    let isNotCompletedProductDevelopment = !isRequired(
      companyPageObject?.future_milestones_product_development
    );
    let isNotCompletedAmountToRise = !isRequired(
      companyPageObject?.amount_want_to_raise
    );
    let isNotCompletePaintPointPitch = !isRequired(
      companyPageObject?.paint_point_pitch
    );

    let isNotCompleteKeyDifferent = !isRequired(
      companyPageObject?.key_competitive_differentiator_pitch
    );

    let isNotTeamUnique = !isRequired(
      companyPageObject?.team_differentiator_pitch
    );

    let isNotCompletedTargetedIndustry = !isRequired(
      companyPageObject?.targeted_industry
    );

    let isNotCompletedTargetedAndTypeOffice = !isRequired(
      companyPageObject?.targeted_country
    );

    let isNotCompletedExpressionStrategy = !isRequired(
      companyPageObject?.futur_milestones_expansion_strategy
    );

    let isNotWebsite = !isRequired(companyPageObject?.website);

    //if (isNotCompletedInfoCompany) progression += 1;
    if (isNotCompletedSummary) progression += 1;
    if (isNotCompletedPartenerAndProgramInterested) progression += 1;
    if (isNotCompletedTargetedAndTypeOffice) progression += 1;
    if (isNotCompletedAnnualTurnOver) progression += 1;
    if (isNotCompletedLogo) progression += 1;
    if (isNotCompletedDistributionAndLicencing) progression += 1;
    if (isNotCompletedTargetedIndustry) progression += 1;
    if (isNotCompletedFundingRound) progression += 1;
    if (isNotCompletedProductDevelopment) progression += 1;
    if (isNotCompletedExpressionStrategy) progression += 1;
    if (isNotCompletedAmountToRise) progression += 1;
    if (isNotCompletePaintPointPitch) progression += 1;
    if (isNotCompleteKeyDifferent) progression += 1;
    if (isNotTeamUnique) progression += 1;
    if (isNotWebsite) progression += 1;

    setProgress(progression);
  }, [companyPageObject]);

  return {
    dataCompleted,
    companyPageObject,
    progress,
  };
}
