import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { getData, getDataWithoutToken } from '../../../../service/api';
import {
  CONTRIBUTOR,
  GUEST,
  ISV,
  OEM_PROGRAM,
  operationBillingAmount,
  url,
} from '../../../../service/constant';
import { getMonth } from '../../../../utils';

export default function UsePublishPr(modalPr: boolean = false) {
  const now = new Date();

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isGuest = userReducer?.roles?.indexOf(GUEST) !== -1;
  const isExpert = userReducer?.roles?.indexOf(CONTRIBUTOR) !== -1;

  const programReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer
  );

  let entityId: string | undefined;
  if (isIsv) {
    entityId = userReducer?.id;
  }
  if (isOem) {
    entityId = programReducer.program.id;
  }
  if (isGuest) {
    entityId = userReducer.guestCompany?.id;
  }
  if (isExpert) {
    entityId = userReducer.expertCompany?.id;
  }

  const [modalPR, setmodalPR] = useState<boolean>(modalPr);
  const [amountPR, setAmountPR] = useState<any>({
    current_price: '',
    instead_of: '',
  });
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + ' ' + now.getFullYear()
  );

  const [statePR, setstatePR] = useState<
    | {
        number_left: number;
        number_max: number;
        next_grant: string;
        spent_by: Array<{
          id: string;
          first_name: string;
          last_name: string;
          user_uri: string;
          at: string;
          number: string;
          role: string;
        }>;
      }
    | undefined
  >();

  const closePRModal = () => {
    // setstatePR({ number_left: 0, number_max: 0, next_grant: '', spent_by: [] });
    setmodalPR(false);
    setChoiseValue(undefined);
  };

  const showmodalPR = () => {
    setmodalPR(true);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    setstatePR(undefined);
  }, [modalPR]);

  useEffect(() => {
    if (entityId && entityId !== '0') {
      (async () => {
        if (userReducer.token) {
          let response = await getData(
            url.settings.billingCenter +
              '/' +
              operationBillingAmount.POST_PRESS_RELEASE +
              '/' +
              entityId,
            userReducer.token
          );
          if (response && response.data) {
            setAmountPR(response.data);
          }
        }
      })();
    }
  }, [entityId]);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + '/information/plan_details',
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { isv_press_release }: any = response.data.free_status;
        setstatePR(isv_press_release);
        setChargingPlanDetails(false);
        const isHaveFree = isv_press_release?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue('FREE');
        } else {
          setChoiseValue('PAY');
        }
      }
    }
    if (modalPR) {
      getPlanDetails();
    }
  }, [currentMonth, modalPR]);

  return {
    modalPR,
    closePRModal,
    setmodalPR,
    chargingPlanDetails,
    statePR,
    handleChoise,
    choiseValue,
    showmodalPR,
    amountPR,
  };
}
