/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import "./financial.scss";
import { Bar } from "react-chartjs-2";
import {
  TpropsFinancial,
  ItemsGraph,
} from "../../../../../controller/company-ctr/types/types-company-page";
import ModalEditAnnualTurnover from "./modal/modal-edit-annual-turnover";
import ModalEditFundingRoundAndAmount from "./modal/modal-edit-funding-round-and-amount";
import { checkIfExist, refaCtoNullKey } from "../../../../../utils";
import ModalEditChart from "./modal/modal-edit-chart";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import { OEM_PROGRAM } from "../../../../../service/constant";

export default function Financial(props: TpropsFinancial) {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;
  const pathName = window?.location?.pathname;
  const isOtherUnlock = pathName.includes("/company-unlocked/");
  const user = useSelector((state: rootState) => state.userReducer);
  const isOem = user?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const state: any = {
    labels: props?.dataGrapH?.map((el: ItemsGraph) => el?.year),

    datasets: [
      {
        type: "bar",
        label: "Revenue in USD",
        backgroundColor: "#D0D2D3",
        data: props?.dataGrapH?.map((el: ItemsGraph) => el?.amount),
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  const BarView = useMemo(
    () => (
      <Bar
        data={state}
        options={{
          scales: {},
        }}
      />
    ),
    props.dataGrapH
  );

  const isAllEmptyInfo =
    checkIfExist(props.annualTurn) &&
    checkIfExist(props.fundingRound?.name) &&
    checkIfExist(props.amontWouldLike);
  return (
    <div className="financialcontainer">
      {isOtherUser && isAllEmptyInfo && (
        <h3 className="text-center font-weight-600 mt-5">Financial</h3>
      )}
      {!isOtherUser && (
        <h3 className="text-center font-weight-600 mt-5">Financial</h3>
      )}

      {isOtherUser && checkIfExist(props.annualTurn) && (
        <div className="bord mt-4">
          <h5 className="title">Annual revenue bracket</h5>
          <p>{props.annualTurn}</p>
        </div>
      )}

      {!isOtherUser && (
        <div className="bord mt-4">
          <a
            className="linkEditLeftCompany"
            onClick={() => props.setIsShowFinancialAnnual(true)}
          >
            Edit
            <span className="icon-edit-full"></span>
          </a>
          <h5 className="title">Annual revenue bracket</h5>
          <p>
            {checkIfExist(props.annualTurn) ? (
              props.annualTurn
            ) : (
              <div className="fauxText">
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l50"></div>
              </div>
            )}
          </p>
        </div>
      )}

      <div className="stat">
        {!isOtherUser && (
          <p>
            Show investors the annual revenue progression from the past two
            years and a projection of the three upcoming years.
          </p>
        )}

        <div className="contentGraphFinancial">
          <a
            className="linkEditLeftCompany"
            onClick={() => props.setFinancialChart(true)}
          >
            Edit
            <span className="icon-edit-full"></span>
          </a>
          {props.isShowGraph && BarView}
        </div>
      </div>

      {props.fundingRoundValue !== "5" ? (
        <>
          <div className="row">
            {isOtherUser &&
              checkIfExist(props.fundingRound?.name) &&
              (isOtherUnlock || !isOem) && (
                <div className="bord funding col-sm-6">
                  <h5 className="title">Funding round</h5>
                  <p>{props.fundingRound?.name}</p>
                </div>
              )}
            {!isOtherUser && (
              <div className="bord funding col-sm-6">
                <h5 className="title">Funding round</h5>
                <p>
                  {checkIfExist(props.fundingRound?.name) ? (
                    props.fundingRound?.name
                  ) : (
                    <div className="fauxText">
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l50"></div>
                    </div>
                  )}
                </p>
              </div>
            )}

            {isOtherUser &&
              checkIfExist(props.amontWouldLike) &&
              (isOtherUnlock || !isOem) && (
                <div className="bord amount col-sm-6">
                  <h5 className="title">Amount you would like to raise</h5>
                  <p>{refaCtoNullKey(props.amontWouldLike)}</p>
                </div>
              )}

            {!isOtherUser && (
              <div className="bord amount col-sm-6">
                <a
                  className="linkEditLeftCompany"
                  onClick={() =>
                    props.setIsShowModalFundingRoundAndAmount(true)
                  }
                >
                  Edit
                  <span className="icon-edit-full"></span>
                </a>
                <h5 className="title">Amount you would like to raise</h5>
                <p>
                  {checkIfExist(props.amontWouldLike) ? (
                    props.amontWouldLike
                  ) : (
                    <div className="fauxText">
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l50"></div>
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="row">
            {isOtherUser &&
              checkIfExist(props.fundingRound?.name) &&
              (isOtherUnlock || !isOem) && (
                <div className="bord mt-4">
                  <h5 className="title">Funding round</h5>
                  <p>{props.fundingRound?.name}</p>
                </div>
              )}

            {!isOtherUser && (
              <div className="bord funding col-sm-12">
                <a
                  className="linkEditLeftCompany"
                  onClick={() =>
                    props.setIsShowModalFundingRoundAndAmount(true)
                  }
                >
                  Edit
                  <span className="icon-edit-full"></span>
                </a>

                <h5 className="title">Amount you would like to raise</h5>
                <p>
                  {checkIfExist(props.fundingRound?.name) ? (
                    props.fundingRound?.name
                  ) : (
                    <div className="fauxText">
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l100"></div>
                      <div className="l50"></div>
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        </>
      )}
      <ModalEditAnnualTurnover
        annualTurnoverData={props.annualTurnoverData}
        isShowFinancialAnnual={props.isShowFinancialAnnual}
        setIsShowFinancialAnnual={(params: boolean) =>
          props.setIsShowFinancialAnnual(params)
        }
        annualTurnOverSelected={props.annualTurnOverSelected}
        setAnnulTurnover={(params: string) => props.setAnnulTurnover(params)}
        updateAnnualTurnOver={() => props.updateAnnualTurnOver()}
        loaderFinancial={props.loaderFinancial}
      />

      <ModalEditFundingRoundAndAmount
        isShowModalFundingRoundAndAmount={
          props.isShowModalFundingRoundAndAmount
        }
        setIsShowModalFundingRoundAndAmount={(params: boolean) =>
          props.setIsShowModalFundingRoundAndAmount(params)
        }
        fundingRound={props.fundingRoundData}
        fundingRoundValue={props.fundingRoundValue}
        setFundingRound={(params: string) => props.setFundingRound(params)}
        amontWouldRise={props.amontWouldRise}
        setAmountWouldRiseSelected={(params: any) =>
          props.setAmountWouldRiseSelected(params)
        }
        amountWouldRiseSelected={props.amountWouldRiseSelected}
        loaderFinancial={props.loaderFinancial}
        updateAnnualTurnOver={() => props.updateAnnualTurnOver()}
      />
      <ModalEditChart
        setFinancialChart={(p: boolean) => props.setFinancialChart(p)}
        showFinancialChart={props.showFinancialChart}
        dataGrapH={props.dataGrapH}
        updateChartForm={props.updateChartForm}
        setUpdateChartForm={(p: Array<ItemsGraph>) =>
          props.setUpdateChartForm(p)
        }
        updateGrapheData={() => props.updateGrapheData()}
        loaderChart={props.loaderChart}
        cancelUpdateChart={() => props.cancelUpdateChart()}
      />
    </div>
  );
}
