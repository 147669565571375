import { ICompanyPageInvestor } from "../../../interface/compnay-page-investor";
import { constante } from "../../actions/company-page-investor-action/other-investor-page-action";
import { IAction } from "../../types/type-action";

export const initialState: ICompanyPageInvestor = {
  id: "0",
  job_title: "",
  organization_name: "",
  phone_number: "",
  email: "",
  number_of_members: undefined,
  website: "",
  location: "",
  country: {
    id: undefined,
    country_name: "",
  },
  isv_company_size: undefined,
  isv_annual_turnover: null,
  min_company_size: 0,
  max_company_size: 0,
  min_annual_turnover: undefined,
  max_annual_turnover: undefined,
  min_fundraising_goal: 0,
  max_fundraising_gol: 0,
  isv_find_me: false,
  photo_profil: "",
  photo_couverture: "",
  status: false,
  published_at: "",
  description: "",
  about: "",
  community_profile: "",
  segments: [],
  targeted_industry: [],
  investor_type: {
    id: undefined,
    name: "",
  },
  institutional_investor_type: {
    id: undefined,
    name: "",
  },
  targeted_country: [],
  investorPostClassifiedAds: {
    number_left: undefined,
    next_grant_at: "",
    amount: undefined,
  },
  investor_strategic_partnership: [],
  investor_funding_round: [],
  linkedin: "",
  validate_by_devinsider: false,
  payment_method: "",
  membersInvitations: [],
  investorMembers: [],
  segment_details: [],
  investorPageResources: [],
  investorPagePortfolios: [],
  photo_profil_draft: null,
  photo_cover_draft: null,
  investor_page_draft: null,
  investorPageExpertises: [],
  phone_number_code: "",
  showUpdatedFlag: false,
  closed: false,
};

export const otherCompanyInvestorPageReducer = (
  state = initialState,
  action: IAction
): ICompanyPageInvestor => {
  switch (action.type) {
    case constante.GET_OTHER_PAGE_INVESTOR:
      return { ...state, ...action.payload };
    case constante.GET_OTHER_INVESTOR_ATTRIBUT:
      return {
        ...state,
        [action?.payload?.key]: action?.payload?.data,
      };
    case constante.CLEAR_OTHER_INVESTOR_CACHE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
