import "./FindIsv.scss";
import React, { ChangeEvent } from "react";
import Header from "../../widget/index";
//@ts-ignore
import UseFindMore from "../../../controller/find-isv/use-find-more";
import { IsvFindMore } from "../../../interface/isv-type";
import Pagination from "../../widget/use-pagination/pagination";
import { LoadingMatchResult } from "../../widget/loader";
import UseContactUsFindMoreIsv from "../../widget/contact-us-widget/use-contact-us-find-more-isv";
import ModalContactUsFindMoreIsv from "../../widget/contact-us-widget/contact-us-find-more-isv";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { INVESTOR, OEM_PROGRAM } from "../../../service/constant";
import MachingComponent from "../../../hooks/MachingComponent";
import { shuffle } from "../../../utils";

export default function FindMoreIsv() {
  const currentUser = useSelector((state: rootState) => state.userReducer);
  let images: Array<any> = [];
  for (let i = 1; i <= 24; i++) {
    images.push(require(`../../../resources/JoiningSoon/${i}.png`).default);
  }
  images = shuffle(images);

  console.log("images", images);

  if (
    !currentUser.roles.includes(INVESTOR) &&
    !currentUser.roles.includes(OEM_PROGRAM)
  ) {
    return <Redirect to={"/profil-not-found"} />;
  }

  const providerUseFindMore = UseFindMore();
  const providerUseContactUsWidget = UseContactUsFindMoreIsv();

  const getRandomNumberImage = () => {
    return Math.floor(Math.random() * 23) + 1;
  };

  return (
    <MachingComponent>
      <div className="findIsvcontainer">
        <Header />
        <div className="container mx-auto findMoreContainer">
          <div className="instruction">
            <h2 className="title">
              Get the most <br />
              out of Devinsider
            </h2>
            Devinsider is in touch with many Software vendors that we expect
            will join our platform soon. As a result, Devinsider can help you
            identify opportunities among the ISVs on our radar. For more
            information please get in touch with one of our representatives.
            <button
              className="btn-devinsider btn getintouch"
              onClick={() =>
                providerUseContactUsWidget.setIsShowContactSupportFindMoreIsv(
                  true
                )
              }
            >
              Get in Touch
            </button>
          </div>
          <div className="listOfIsv findMore">
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  <b>84 206</b> ISVs
                </div>
              </div>
            </div>
            <div className="scrollContainer">
              {providerUseFindMore?.loaderJoin ? (
                <LoadingMatchResult />
              ) : (
                <ul className="listOfIsvMatch">
                  {providerUseFindMore?.allJoinSoon?.isv?.map(
                    (el: IsvFindMore, index: number) => (
                      <li className="locked" key={index}>
                        <img
                          src={
                            images[index < 24 ? index : getRandomNumberImage()]
                          }
                          alt=""
                        />
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
            <div className="pagginationCategory fixePagination">
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={
                    providerUseFindMore?.allJoinSoon?.currentPage || 1
                  }
                  totalCount={
                    providerUseFindMore?.allJoinSoon?.totalDataPaginate
                  }
                  pageSize={providerUseFindMore?.limit}
                  onPageChange={(page: any) =>
                    providerUseFindMore?.hanldeNextPage(page)
                  }
                />
              </div>
            </div>
            <ModalContactUsFindMoreIsv
              {...providerUseContactUsWidget}
              required
            />
          </div>
        </div>
      </div>
    </MachingComponent>
  );
}
