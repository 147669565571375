import React, { useState, useEffect } from "react";
import useEditModalSF from "../../../screen/widget/software-solution/use-SF";
import useSliderCS from "../../../screen/widget/slider-company-size-slider/use-slider-company-size-slider";
import useSliderFD from "../../../screen/widget/slider-fundraising/use-slider-fundraising";
import useSliderAT from "../../../screen/widget/slider-annual-turnover/use-slider-annual-turnover";
import usePays from "../../../screen/widget/pays/use-Pays";
import useIL from "../../../screen/widget/industries-list/use-IL";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import {
  getInvestorAttributes,
  updateCPInvestorAction,
} from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

export default function UseEditSettingPageCtr() {
  const dispatch = useDispatch();
  const [showPageSettings, setShowPageSettings] = useState<boolean>(false);
  const [wantToShow, setWantToShow] = useState<boolean>(true);
  const [defaultValueCompanySize, setDefaultValueCompanySize] = useState<
    Array<number>
  >([]);
  const [defaultValueAnnualTurnover, setDefaultValueAnnualTurnover] = useState<
    Array<any>
  >([]);
  const [foundingRound, setFoundingRound] = useState<Array<any>>([]);
  const [valuesolutionChecked, setValuesolutionChecked] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const [valueFeaturedsolutionChecked, setValueFeaturedsolutionChecked] =
    useState<Array<{ id: number; name: string }>>([]);

  const [valuePays, setDefaultValuePays] = useState<
    Array<{ idContinent: string; countrId: string; countryName: string }>
  >([{ idContinent: "", countrId: "", countryName: "" }]);

  const [valueIL, setValueIL] = useState<
    Array<{
      id: number;
      name: string;
    }>
  >([]);

  const location = useLocation();
  const parsed = queryString.parse(location?.search);

  const CP_Investor = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const [valueFundraising, setValueFundraising] = useState<Array<number>>([]);
  const [loader, setLoader] = useState<boolean>(false);

  //Solution software
  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    filterSolutionChecked
  } = useEditModalSF({
    defaultValue: valuesolutionChecked,
    defaultValueFeatured: valueFeaturedsolutionChecked,
  });
  
  //Fundraising
  const {
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
  } = useSliderFD({ defaultValue: valueFundraising });

  //company size
  const {
    companySizeSlide,
    setCompanySizeSlide,
    customSetCompanySize,
    companySize,
    setCompanySize,
  } = useSliderCS({ defaultValue: defaultValueCompanySize });

  //annual turnover
  const {
    annualTurnover,
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    setAnnualTurnoverSlide,
    setAnnualTurnover,
    customSetAnnualSize,
  } = useSliderAT({ defaultValue: defaultValueAnnualTurnover });

  //pays
  const { addPaysInArray, paysChecked, allContinents, allContinentSelected } =
    usePays(valuePays);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
  } = useIL({ defaultSelected: valueIL });

  /**
   * Initialise segment details
   */
  useEffect(() => {
    setValuesolutionChecked(CP_Investor?.segments);
    setValueFeaturedsolutionChecked(CP_Investor?.segment_details);
    return () => {};
  }, [CP_Investor?.segments, CP_Investor?.segment_details]);

  /**
   * Initialise find me
   */
  useEffect(() => {
    setWantToShow(CP_Investor?.isv_find_me);
    return () => {};
  }, [CP_Investor?.isv_find_me]);

  /**
   * Initialise company size
   */
  useEffect(() => {
    setDefaultValueCompanySize([
      CP_Investor?.min_company_size,
      CP_Investor?.max_company_size,
    ]);
    return () => {};
  }, [CP_Investor?.min_company_size, CP_Investor?.max_company_size]);

  /**
   * Initialise annual turnover
   */
  useEffect(() => {
    setDefaultValueAnnualTurnover([
      CP_Investor?.min_annual_turnover,
      CP_Investor?.max_annual_turnover,
    ]);
    return () => {};
  }, [CP_Investor?.min_annual_turnover, CP_Investor?.max_annual_turnover]);

  /**
   * Initialise Founding round
   */
  useEffect(() => {
    setFoundingRound(
      CP_Investor?.investor_funding_round?.map((el: any) => ({
        id: el.id,
        label: el.name,
        value: el.name,
      }))
    );
    return () => {};
  }, [CP_Investor?.investor_funding_round]);

  /**
   * Initialise pays
   */
  useEffect(() => {
    setFoundingRound(
      CP_Investor?.investor_funding_round?.map((el: any) => ({
        id: el.id,
        label: el.name,
        value: el.name,
      }))
    );
    return () => {};
  }, [CP_Investor?.investor_funding_round]);

  /**
   * Initialise target industrie
   */
  useEffect(() => {
    setValueIL(CP_Investor?.targeted_industry);
    return () => {};
  }, [CP_Investor?.targeted_industry]);

  /**
   * Initialise foundraising goal
   */
  useEffect(() => {
    setValueFundraising([
      CP_Investor?.min_fundraising_goal,
      CP_Investor?.max_fundraising_gol,
    ]);
    return () => {};
  }, [CP_Investor?.min_fundraising_goal, CP_Investor?.max_fundraising_gol]);

  /**
   * Initialise default pays
   */
  useEffect(() => {
    setDefaultValuePays(
      CP_Investor?.targeted_country?.map((el: any) => ({
        idContinent: el.idContinent?.toString(),
        countrId: el?.countryId?.toString(),
        countryName: el?.countryName,
      }))
    );
    return () => {};
  }, [CP_Investor?.targeted_country]);

  const getCompletedData = () => {
    dispatch(getInvestorAttributes("segments", "segments", () => {}));
    dispatch(
      getInvestorAttributes("segment_details", "segmentDetails", () => {})
    );
    dispatch(
      getInvestorAttributes("targeted_industry", "targetedIndustry", () => {})
    );
    dispatch(
      getInvestorAttributes("targeted_country", "targetedCountry", () => {})
    );
  };

  let cb = (data: any) => {
    setLoader(false);
    setShowPageSettings(false);
    getCompletedData();
  };

  const investorValideUpdate = async () => {
    setLoader(true);
    let data = {
      targetedCountry: JSON.stringify(
        paysChecked?.map((el: any) => el?.countrId)
      ), // country id
      minCompanySize: companySizeSlide[0],
      maxCompanySize: companySizeSlide[1],
      minAnnualTurnover:
        unitTurnover === "K USD"
          ? annualTurnoverSlide[0] * 1000
          : annualTurnoverSlide[0] * 1000000,
      maxAnnualTurnover:
        unitTurnoverMax === "K USD"
          ? annualTurnoverSlide[1] * 1000
          : annualTurnoverSlide[1] * 1000000,
      minFundraisingGoal:
        unitFundraisingGoal == "K USD"
          ? fundraisingGoalSlide[0] * 1000
          : unitFundraisingGoal == "M USD"
          ? fundraisingGoalSlide[0] * 1000000
          : fundraisingGoalSlide[0] * 1000, //prix
      maxFundraisingGol:
        unitFundraisingGoalMax == "K USD"
          ? fundraisingGoalSlide[1] * 1000
          : unitFundraisingGoalMax == "M USD"
          ? fundraisingGoalSlide[1] * 1000000
          : fundraisingGoalSlide[1] * 1000, //prix
      segmentDetails: JSON.stringify(
        solutionCheckedFeatures.map((el: any) => el?.id)
      ),
      segments: JSON.stringify(solutionChecked?.map((el: any) => el?.id)),
      targetedIndustry: JSON.stringify(
        companyTargetSelected?.map((el: any) => el?.id)
      ),
      isvFindMe: wantToShow ? "1" : "0",
      investorFundingRound: JSON.stringify(
        foundingRound?.map((el: any) => el?.id)
      ),
    };
    dispatch(updateCPInvestorAction(CP_Investor?.id, data, cb));
  };

  const showPreview = () => {
    window.open("/profil/investor?preview=1", "_blank");
  };

  return {
    showPageSettings,
    setShowPageSettings,
    wantToShow,
    setWantToShow,
    companySizeSlide,
    setCompanySizeSlide,
    customSetCompanySize,
    unitTurnover,
    unitTurnoverMax,
    annualTurnoverSlide,
    defaultValueAnnualTurnover,
    setAnnualTurnoverSlide,
    setAnnualTurnover,
    customSetAnnualSize,
    companySize,
    annualTurnover,
    setCompanySize,
    foundingRound,
    setFoundingRound,
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
    addPaysInArray,
    paysChecked,
    allContinents,
    allContinentSelected,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    funMin: CP_Investor?.min_fundraising_goal,
    funMax: CP_Investor?.max_fundraising_gol,
    loader,
    investorValideUpdate,
    showPreview,
    isPreview: parsed?.preview ? true : false,
    filterSolutionChecked
  };
}
