import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateCompanyPageAction } from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import { rootState } from '../../../../redux/reducers';

export default function UseExpressionStrategyCtr() {
  const params: any = useParams();

  const dispatch = useDispatch();
  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  const [valueExpressionStrategic, setValueExpressionStrategic] =
    useState<string>(
      companyPageObject?.futur_milestones_expansion_strategy || ''
    );
  const [isShowExpressionStrategic, setIsShowExpressionStrategic] =
    useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const cbUpdateExpressionStrategic = (response: any) => {
    if (response && response?.status === 200) {
      setLoader(false);
      setIsShowExpressionStrategic(false);
    }
  };

  const updateExpressionStrategic = () => {
    let params = {
      futurMilestonesExpansionStrategy: valueExpressionStrategic,
    };
    setLoader(true);
    dispatch(
      updateCompanyPageAction(
        params,
        companyPageObject.id,
        cbUpdateExpressionStrategic
      )
    );
  };

  const closeModalExpressionStrategic = () => {
    setValueExpressionStrategic(
      companyPageObject?.futur_milestones_expansion_strategy || ''
    );
    setIsShowExpressionStrategic(false);
  };

  useEffect(() => {
    setValueExpressionStrategic(
      companyPageObject?.futur_milestones_expansion_strategy || ''
    );
    return () => {};
  }, [companyPageObject]);

  return {
    isShowExpressionStrategic,
    setIsShowExpressionStrategic,
    loader,
    closeModalExpressionStrategic,
    updateExpressionStrategic,
    valueExpressionStrategic,
    setValueExpressionStrategic,
  };
}
