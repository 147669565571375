import React from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { OEM_PROGRAM } from "../../../../service/constant";
import { checkType, fileUrl } from "../../../../utils";
type IProps = {
  showProfil: () => void;
  goToSettings: () => void;
  logOut: () => void;
  userState: any;
  goToBillings: () => void;
  goToSubscriptionPlan: () => void;
};

const renderMenuBillingOemProgram = (
  user: any,
  currentOemProgram: any
): boolean => {
  let roleUserInOemProgram = (() => {
    if (user?.roles?.indexOf(OEM_PROGRAM) !== -1) {
      let roleInCurrentProgram = user?.oemProgramMembers?.filter(
        (role: any) => role?.oem_program?.id === currentOemProgram.id
      )[0];
      return roleInCurrentProgram?.role;
    }
    return "";
  })();
  //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
  return !checkType([roleUserInOemProgram], []);
};

export default function BlocAccountProfil(props: IProps) {
  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  return (
    <div className="item">
      <a href="javascript:;" className="parent linkHeader">
        <div className={`profilImg`}>
          <img alt="Profile" src={`${fileUrl(props.userState.file_name)}`} />
        </div>
        <span className="mobile">My account</span>
      </a>
      <div className="submenu">
        <a
          href="javascript:;"
          onClick={(e: any) => {
            e?.preventDefault();
            props.showProfil();
          }}
        >
          Profile
        </a>
        <a
          href="javascript:;"
          onClick={(e: any) => {
            e?.preventDefault();
            props.goToSettings();
          }}
        >
          Account settings
        </a>
        {renderMenuBillingOemProgram(props.userState, currentOemProgram) ===
        true ? (
          <>
            <a
              href="javascript:;"
              onClick={(e: any) => {
                e?.preventDefault();
                props.goToBillings();
              }}
            >
              Billing
            </a>
          </>
        ) : (
          <></>
        )}
        <a
          href="javascript:;"
          onClick={(e) => {
            e?.preventDefault();
            props.logOut();
          }}
        >
          Sign out
        </a>
      </div>
    </div>
  );
}
