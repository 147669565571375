import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { constante, filterFavorites, filterUnlocked, likeInvestor } from "../../../../redux/actions/find-investor-action";
import { rootState } from "../../../../redux/reducers";
import { IAction, IInvestor, IInvestorMatching } from "../../../../redux/types/find-investor-interface";
import UseFilterControl from "../bloc-filter-find-investor/use-filter-control-find-investor";

const initialState : IInvestorMatching = {
    results : [],
    total_to_paginate : 0,
};

const treatFavorite = (id: number, array : Array<IInvestor>) => {
    return array?.map(el => {
      if(el?.investor?.id === id){
        return {
          ...el,
          is_favorite : !el?.is_favorite,
        }
      }else{
        return el;
      }
    });
  }
  
  const findInvestorReducer = (
    state = initialState,
    action : IAction
  ): IInvestorMatching => {
    switch(action?.type){
      case constante?.UNLOCKED:
        return {
          ...state,
          ...action?.payload,
        };
      case constante?.ADD_TO_FAVORITE:
        return {
          ...state,
          results : treatFavorite(action?.payload, state?.results),
        };
      default:
        return state;
    }
  } 

export default function UseUnlockedInvestor(){
    const [investors, dispatch] = useReducer<
      (state: IInvestorMatching, action:IAction) => IInvestorMatching
    >(findInvestorReducer, initialState);

    const userReducer = useSelector((state:rootState)=>state?.userReducer);

    const {
        match,
        selectedHqLocation,
        selectedIndustry,
        selectedSolution,
        selectedType,
        setMatch,
        setSelectedHqLocation,
        setSelectedIndustry,
        setSelectedSolution,
        setSelectedType,
        size,
        resetFilter,
    } = UseFilterControl();
    
    const [orderBy, setOrderBy] = useState("DESC");
    const [sortBy, setSortBy] = useState<"last_update" | "relevance" | "last_unlocked" | "investor_name" | "last_engagement_date">(
        "relevance"
    );
    const [search_name, setSearch_name] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setlimit] = useState<number>(9);

    const [loader, setLoader] = useState(false);

    const {register, handleSubmit} = useForm({
        defaultValues : {
            search : "",
        }
    });
    const filtered = [
        {
            value: "relevance",
            label: "Relevance",
        },
        {
            value: "last_update",
            label: "Last Update",
        },
        {
            value: "investor_name",
            label: "Investor name",
        },
        {
            value: "last_unlocked",
            label: "Last Unlocked",
        },
        {
            value: "last_engagement_date",
            label: "Last engagement date",
        }
    ];

    const callback = (data:any)=>{
        if(data?.status === 200){
            setLoader(false);
        }
    }  

    const filterInvestor = async (dataToChange : any = {})=>{
        setLoader(true);
          let params = {
              contacted_investor_only: "",
              unlocked_by: "",
              institutionalInvestorType: selectedType?.join(),
              minimumMatch: match[0]? match[0]:"",
              maximumMatch: match[1]? match[1]:"",
              hqlocation: selectedHqLocation?.join(),
              solutionSpecialization: selectedSolution?.join(),
              industrySpecialization: selectedIndustry?.join(),
              sortBy,
              orderBy,
              search_name,
              page: page,
              ...dataToChange,
          }
          await filterUnlocked(
            params,
            dispatch,
            callback,
          );
      }
  
      useEffect(()=>{
          (async ()=>{
              await filterInvestor();
          })();
          return () => {}
      },[]);
  
      const resetTri = ()=>{
          setPage(1);
          setOrderBy("DESC");
  
          return {
              page : 1,
              orderBy : "DESC",
          }
      }
  
      const handleResetFilter = async ()=>{
          resetFilter();
  
          setSearch_name("");
          setOrderBy("DESC");
          setPage(1);
  
          let params = {
              institutionalInvestorType: "",
              minimumMatch: 60,
              maximumMatch: 100,
              hqlocation: "",
              solutionSpecialization: "",
              industrySpecialization: "",
              orderBy : "DESC",
              search_name : '',
              page: 1,
          }
  
          await filterInvestor(params);
      };
  
      const filter = async ()=>{
          await filterInvestor(resetTri());
      };
  
      const onSubmitSearch = async (data:any)=>{
          setSearch_name(data?.search);
          setPage(1);
          filterInvestor({
              page : 1,
              search_name : data?.search,
          });
      }
  
    const loadMoreProgram = async (page: number) => {
        setPage(page);
        let paramsChange = { 
        page,
        };
        await filterInvestor(paramsChange);
    };
      
    const handleFilter = async (params: any) => {
        setSortBy(params?.value);
        let paramsChange = {
        sortBy: params?.value,
        page: 1,
        };
        await filterInvestor(paramsChange);
    };

    const addToFavorites = async (id:any) => {
        likeInvestor(
            id,
            dispatch,
            ()=>{},
        )
    }

    return {
        loader,
        investors,
        userReducer,

        match,
        selectedHqLocation,
        selectedIndustry,
        selectedSolution,
        selectedType,
        size,
        setMatch,
        setSelectedHqLocation,
        setSelectedIndustry,
        setSelectedSolution,
        setSelectedType,
        resetFilter : handleResetFilter,
        filter,

        register, 
        handleSubmit,
        onSubmitSearch,
        loadMoreProgram,
        handleFilter,
        filtered,
        page,
        limit,
        sortBy,

        addToFavorites,
    }
}