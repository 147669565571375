import React, { useState } from 'react';
import Header from '../../../widget/index';
import Footer from '../../../widget/footer/footer';
import './home-find-program.scss';
import time from '../../../../resources/images/homeFindProgram/Time.png';
import discover from '../../../../resources/images/homeFindProgram/Discover.png';
import mongo from '../../../../resources/images/homeFindProgram/mongo.png';
import docu from '../../../../resources/images/homeFindProgram/docu.png';
import access from '../../../../resources/images/homeFindProgram/getAcces.png';
import apply from '../../../../resources/images/homeFindProgram/Apply.png';
import browse from '../../../../resources/images/homeFindProgram/browse.png';

export default function HomeFindProgram() {
  return (
    <div className='findProgram'>
      <Header />
      <section className='HomeGetStarted findPro d-flex align-items-stretch'>
        <div className='container mx-auto d-flex align-items-center'>
          <div className='moveyoursoftware'>
            <h1 className='title'>
              Get access to hundreds of ISV partnership programs
            </h1>
            <p>
              Find the right partner and people to achieve your business goals
            </p>
            <a onClick={(e) => {}} className='getStartedLink'>
              Get started
            </a>
          </div>
        </div>
      </section>
      <section className='hubSoftwareVendor'>
        <div className='container mx-auto'>
          <ul className='listsoftwarevendor'>
            <li>
              <img src={time} alt='' />
              <h2 className='title'>Save valuable time</h2>
              <div className='textforitems'>
                <p>
                  Within a few clicks apply to the most relevant partnership
                  programs for your business
                </p>
              </div>
            </li>
            <li>
              <img src={discover} alt='' />
              <h2 className='title'>
                Discover every ISV program in your niche
              </h2>
              <div className='textforitems'>
                <p>Get a granular overview of ISV programs accros the globe</p>
              </div>
            </li>
          </ul>
          <ul className='listofpartnership'>
            <li>
              <img src={mongo} alt='' />
            </li>
            <li>
              <img src={docu} alt='' />
            </li>
            <li>
              <img src={mongo} alt='' />
            </li>
            <li>
              <img src={docu} alt='' />
            </li>
            <li>
              <img src={mongo} alt='' />
            </li>
            <li>
              <img src={docu} alt='' />
            </li>
          </ul>
        </div>
      </section>
      <section className='access'>
        <div className='container mx-auto'>
          <ul className='listofaccess'>
            <li>
              <h2 className='title'>
                Get access to hundreds of partnership programs
              </h2>
              <p>
                Match with OEM, Ecosystem and ISV channel Programs based on your
                company profile and preferences. Devinsider keeps you up to date
                on the latest partnership opportunities in your market.
              </p>
              <a className='getStartedLink'>Get started</a>
            </li>
            <li>
              <img src={access} alt='' />
            </li>
          </ul>
        </div>
      </section>
      <section className='apply access'>
        <div className='container mx-auto'>
          <ul className='listofaccess'>
            <li>
              <img src={apply} alt='' />
            </li>
            <li>
              <h2 className='title'>
                Apply the most relevant programs for your business
              </h2>
              <p>
                Easily compare different partnership offers, including the
                benefits and how to get started. Directly contact the right
                program representative for onboarding.
              </p>
              <a className='getStartedLink'>Get started</a>
            </li>
          </ul>
        </div>
      </section>
      <div className='container mx-auto getStartedHome'>
        <h1 className='title'>Get started with Devinsider</h1>
        <p>
          Browse through the partnership programs on Devinsider to find
          technology partners that move your software business forward.
        </p>
        <a
          onClick={(e) => {
            e.preventDefault();
          }}
          className='getStartedLink'
        >
          Browse programs
        </a>
      </div>
      <section className='browse access'>
        <div className='container mx-auto'>
          <ul className='listofaccess'>
            <li>
              <h2 className='title'>
                Browse program reviews in the Devinsider community
              </h2>
              <p>
                Learn about the experiences of other ISVs, ask questions and
                share your own reviews with Devinsider community members
              </p>
              <a className='getStartedLink'>Join the Community</a>
            </li>
            <li>
              <img src={browse} alt='' />
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
}
