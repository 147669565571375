import React from "react";
import Modal from "react-bootstrap/Modal";
import "./permission.scss";

export type IpropsPersmission = {
  show: boolean;
  handleShow: (p: boolean) => void;
};

export default function Index(props: IpropsPersmission) {
  return (
    <Modal
      show={props?.show}
      onHide={() => props?.handleShow(false)}
      className="small forDesactivate"
    >
      <Modal.Header>
        <div className="headerpermission noStep">
          <h2 className="title">Permission</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.handleShow(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            Warning
          </h3>

          <p>Your cannot remove a billing owner</p>
          <p>Select an other billing owner if you want to remove this user</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
