/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import {
  ISolutionListe,
  ItemsSolution,
} from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-solution-offert-reseller";
import UseCompleteCategoryInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-category-investor";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import useGoBack from "../../../../../hooks/use-navigate";

export default function CompleteCategoryInvestor() {
  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    loader,
    featuredSolution,
    errorData,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    navigateToIndustrieInvestor,
  } = UseCompleteCategoryInvestor();

  const [activeId, setActiveId] = useState("0");

  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  const { goBack } = useGoBack();

  return (
    <div>
      <Header />
      <div className="getstartedContainer largeContainer">
        <div className="head-getstarted">
          <a
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
            className="link-back"
          >
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done ">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        {loader && <Loader />}
        {!loader && (
          <div className="contentBorder border-top row pt-3 pb-4">
            <div className="form-group required col-xl-12 pr-xl-2 pr-md-2 p-0 m-0">
              <label htmlFor="">
                What ISV solution categor(ies) are you interested in ?
                {errorData && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select at least one category
                    </span>
                  </div>
                )}
              </label>
              <span className="selectalloverstyle">Select all that apply.</span>
            </div>

            <div className="contentCheckboxsolution ">
              <h2 className="title">
                {featuredSolution && featuredSolution.name}
              </h2>
              <div className="flex-checkbox ">
                {featuredSolution &&
                  featuredSolution.segmentDetails.map((el: ItemsSolution) => (
                    <Form.Check
                      custom
                      checked={
                        solutionCheckedFeatures?.find((id: any) => id == el.id)
                          ? true
                          : false
                      }
                      label={el.name}
                      id={el.id.toString()}
                      onClick={() => addSolutionChechedFeatured(el.id)}
                    />
                  ))}
              </div>
              <div className="listAccordionSolution">
                <Accordion defaultActiveKey={activeId}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                      {solutionLeftColumnData &&
                        solutionLeftColumnData.map(
                          (solution: ISolutionListe) => {
                            return (
                              <div
                                className={"panel-wrap active-panel"}
                                onClick={() => addSolutionCheched(solution.id)}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() => toggleActive(solution.id)}
                                    className={`panel-toggle noicon ${
                                      solutionChecked?.find(
                                        (el: number) => el == solution.id
                                      )
                                        ? "colored"
                                        : ""
                                    } `}
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}
                                  </Accordion.Toggle>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                      {solutionRigthColumnData &&
                        solutionRigthColumnData.map(
                          (solution: ISolutionListe) => {
                            return (
                              <div
                                className={"panel-wrap active-panel"}
                                onClick={() => addSolutionCheched(solution.id)}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() => toggleActive(solution.id)}
                                    className={`panel-toggle noicon ${
                                      solutionChecked?.find(
                                        (el: number) => el == solution.id
                                      )
                                        ? "colored"
                                        : ""
                                    } `}
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}
                                  </Accordion.Toggle>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        )}
        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToIndustrieInvestor()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
