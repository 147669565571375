import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { timeRange } from "../../../../utils/time-range";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import { getTimeZoneUtc, makeOptions } from "../../../../utils";
import { Link } from "react-router-dom";
import Select from "react-select";
import timeZone from "../../../../utils/timezones.json";
import { Controller } from "react-hook-form";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import SearchFormEvent, {
  IPropsSearchEvent,
} from "../../../widget/SearchFormEvent";
import TimezoneSelect, { ITimezone } from "react-timezone-select";

export interface IPropsEvents extends IPropsSearchEvent {
  showNext: boolean;
  setShowNext: (p: boolean) => void;
  handleSubmit: any;
  onSubmit: any;
  startDate: any;
  setStartDate: (p: any) => void;
  isAllTime: boolean;
  isShowEndTime: boolean;
  register: any;
  setisShowEndTime: any;
  setisAllTime: any;
  errors: any;
  loaderCreateEvent: boolean;
  getValue: any;
  isErrorInfo: boolean;
  txtError: string;
  isSelected: (data: any, value: any) => boolean;
  control: any;
  eventDescription: EditorState;
  setEventDescription: (wswgValue: EditorState) => void;
  isValid: boolean;
  timezone: ITimezone;
  setTimezone: (value: ITimezone) => void;
}

export default function ModalEditEvent(props: IPropsEvents) {
  const IsVirtualEvent = props?.getValue("eventType") === "1";
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const countCharacters = () => {
    const contentState = props?.eventDescription?.getCurrentContent();
    const plainText = contentState.getPlainText("");
    const characterCount = plainText.length;
    return characterCount;
  };

  const countNbr = countCharacters();

  const subTextEdit = IsVirtualEvent ? "VIRTUAL EVENT" : "PHYSICAL EVENT";

  const selectedTags = (props.txtFilter?.split(",") || []).filter((it) => it);

  const isEmptyDescription = () => countNbr < 1;

  return (
    <Modal
      show={props.showNext}
      className="createCompanypopup large forStep5custom "
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">EDIT {subTextEdit}</h2>
          <Link
            to="#"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props.setShowNext(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </Link>
        </div>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <div className="eventCreation mx-auto">
            <div className="form-control">
              <label htmlFor="">
                When?{" "}
                {props?.isErrorInfo && (
                  <div className={`container-error pl-2  d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">{props?.txtError}</span>
                  </div>
                )}
              </label>
              <div className="dateEvent">
                <DatePicker
                  selected={props.startDate}
                  onChange={(date: Date) => {
                    props.setStartDate(date);
                  }}
                  minDate={new Date(Date.now() + 3600 * 1000 * 24)}
                  className="datePicker"
                  dateFormat="d/MM/yyyy"
                />

                {!props.isAllTime && (
                  <select
                    className="timeEvent"
                    {...props.register("startTime", {})}
                  >
                    <option value={""}>Select start time</option>
                    {timeRange?.map(
                      (
                        el: { label: string; value: string; id: number },
                        index: number
                      ) => (
                        <option
                          value={el?.id}
                          key={index}
                          selected={
                            el?.id?.toString() ===
                            props?.getValue("startTime")?.toString()
                          }
                        >
                          {el?.label}
                        </option>
                      )
                    )}
                  </select>
                )}

                {!props.isShowEndTime && !props.isAllTime && (
                  <label
                    className="addEndTime"
                    htmlFor=""
                    onClick={() => props.setisShowEndTime(true)}
                  >
                    Add end time
                  </label>
                )}

                {props.isShowEndTime && !props.isAllTime && (
                  <>
                    <select
                      className="timeEvent"
                      {...props.register("endTime", {})}
                    >
                      <option value={""}>Select end time</option>
                      {timeRange?.map(
                        (
                          el: { label: string; value: string; id: number },
                          index: number
                        ) => (
                          <option
                            value={el?.id}
                            key={index}
                            selected={
                              el?.id?.toString() ===
                              props?.getValue("endTime")?.toString()
                            }
                          >
                            {el?.label}
                          </option>
                        )
                      )}
                    </select>
                  </>
                )}

                {(props.isAllTime || props.isShowEndTime) && (
                  <div className="custom-control custom-checkbox orangecheck">
                    <input
                      type="checkbox"
                      id="ifreplies"
                      className="custom-control-input"
                      onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                        props.setisAllTime(evt?.target?.checked)
                      }
                      checked={props.isAllTime}
                    />
                    <label htmlFor="ifreplies" className="custom-control-label">
                      All day event
                    </label>
                  </div>
                )}
                <div className="mt-2 w-100">
                  <TimezoneSelect value={props?.timezone} onChange={props?.setTimezone} />
                </div>
              </div>
            </div>

            <div className={`form-control ${IsVirtualEvent ? "d-none" : ""}`}>
              <label htmlFor="">Event location </label>
              <div className="d-flex" style={{ alignItems: "baseline" }}>
                <select
                  className="custom-select"
                  {...props?.register("country", {})}
                >
                  <option value="">Country</option>
                  {dataCompleted?.country?.map(
                    (element: any, index: number) => (
                      <option
                        value={element.id}
                        selected={
                          element.id.toString() ==
                          sessionStorage.getItem("companyLocation")
                        }
                        key={index}
                      >
                        {`${element.country_name}`}
                      </option>
                    )
                  )}
                </select>

                <div className="form-control pl-4" style={{ paddingBottom: 0 }}>
                  <input
                    type="text"
                    placeholder="City"
                    {...props?.register("city", {})}
                  />
                </div>
              </div>
            </div>

            <div className="form-control">
              <label htmlFor="">
                Event title{" "}
                {props.errors?.eventTitle && (
                  <div className={`container-error pl-2  d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                {...props.register("eventTitle", {
                  required: true,
                })}
              />
            </div>
            <div className="form-control">
              <label htmlFor="">
                Event url{" "}
                {props.errors?.eventUrl && (
                  <div className={`container-error pl-2  d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                {...props.register("eventUrl", { required: true })}
              />
            </div>
            <div className="form-control">
              <SearchFormEvent
                dataLabelFilter={props.dataLabelFilter}
                handleFilterSelect={props.handleFilterSelect}
                handleSelectedLabels={props.handleSelectedLabels}
                setTxtFilter={props.setTxtFilter}
                txtFilter={props.txtFilter}
                defaultDataLabel={props.defaultDataLabel}
                isFocused={props.isFocused}
                setIsFocused={props.setIsFocused}
              />
            </div>
            <div className="form-control">
              <label htmlFor="">
                Event description{" "}
                {countNbr > 2500 && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      max length is 2500 characters
                    </span>
                  </div>
                )}
              </label>
              <Editor
                editorState={props?.eventDescription}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={(editorState: EditorState) => {
                  props.setEventDescription(editorState);
                }}
                stripPastedStyles={true}
                toolbar={{
                  options: [
                    "inline",
                    "fontSize",
                    "list",
                    "history",
                    // 'image',
                    // 'link',
                    "remove",
                    "textAlign",
                  ],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                }}
              />
              <div className="caractercount text-right">{countNbr} / 2500</div>
            </div>
            <div className="contentBorder formForEmail text-right forPitch py-3">
              <button
                type="button"
                className="btn btn-devinsider cancel"
                onClick={() => props.setShowNext(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-devinsider px-5"
                type="submit"
                disabled={
                  countNbr > 2500 ||
                  !props.isValid ||
                  props?.isErrorInfo ||
                  props?.loaderCreateEvent ||
                  selectedTags?.length > 3 ||
                  selectedTags?.length < 1 ||
                  isEmptyDescription()
                }
              >
                {props.loaderCreateEvent ? <Loader /> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
