import React, { ChangeEvent } from "react";

export type IPropsSearchEvent = {
    handleSelectedLabels: (str: string) => void;
    txtFilter: string;
    setTxtFilter: (p: string) => void;
    dataLabelFilter: Array<string>;
    defaultDataLabel: Array<string>;
    handleFilterSelect: (str: string) => void;
    setIsFocused: (p: boolean) => void;
    isFocused: boolean;
};

export default function SearchFormEvent(props: IPropsSearchEvent) {
  const selectedTags =
    props.txtFilter?.trim()?.replace(/,$/, "")?.split(",") || [];
  const isActive = (texte: string) => {
    const parties = props.txtFilter?.split(",");
    const resultat = parties.find((partie) => partie.trim() === texte?.trim());
    return !!resultat;
  };

  const handleSelectedLabels = (label: string) => {
    const isSelected = isActive(label);
    let updatedTags: string[];
    if (isSelected) {
      // If the tag is already selected, we deselect it
      updatedTags = selectedTags.filter((tag) => tag.trim() !== label.trim());
    } else {
      // Otherwise, we add it to the list of selected tags
      updatedTags = [...selectedTags, label.trim()];
    }
    // Update the txtFilter with the new list of tags
    const formattedTags = updatedTags.join(", ").replace(/^,/, "");
    props.setTxtFilter(formattedTags);
  };

  const transformerTexte = (texte: string) => {
    const parties = texte.split(",")?.filter((it) => it !== " ");
    const resultat = parties.map((partie) => partie.trim()).join(", ");
    props.setTxtFilter(resultat);
  };

  return (
    <div className="form-control relative">
      <label htmlFor="">Choose a tag : (3 tags max) </label>{" "}
      <div className="listOfTopic">
        <span className="label">Suggestion: </span>
        {props.defaultDataLabel?.map((element: any, index: number) => (
          <span
            className={`topic ${isActive(element?.value) ? "active" : ""} `}
            key={index}
            onClick={() => handleSelectedLabels(element?.value)}
          >
            {element?.label}
          </span>
        ))}
        {selectedTags.length > 3 && (
          <div className={`container-error pl-2  d-inline`}>
            <span className="error-red"> error </span>
            <span className="txt-error">3 tags max</span>
          </div>
        )}
      </div>
      <input
        type="text"
        placeholder=""
        value={props.txtFilter}
        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
          props.setTxtFilter(evt.target.value)
        }
        onBlur={(evt) => {
          props.setIsFocused(false);
          transformerTexte(evt.target.value);
        }}
        onFocus={() => props.setIsFocused(true)}
      />
      <div
        className={`listOfChoice ${
          props.dataLabelFilter?.length < 1 ? "d-none" : ""
        }`}
      >
        <ul>
          {props.dataLabelFilter?.map((el: any) => (
            <li onClick={() => props.handleFilterSelect(el?.value)}>
              {el?.value}
            </li>
          ))}
        </ul>
      </div>
      <label htmlFor="" className="mt-2">
        <b className="miniBold ">Use commas between tags</b>
      </label>
    </div>
  );
}
