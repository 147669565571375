import { fileUrl } from "../../../../../../utils";

type Iprops = {
  profil: string;
  userName: string;
  roleDiscussion: string;
  profil_current_user: string;
  companyName_current_user: string;
  roleDiscussion_current: string;
};
export default function CorporateAds(props: Iprops) {
  return (
    <>
      <div className="titleRepH">Corporate Development</div>
      <div className="d-flex actorAndParam">
        <div className="d-flex align-items-center infoActor">
          <div className="photo-title">
            {props.profil && <img src={fileUrl(props.profil)} alt="profil" />}
          </div>
          <div className="info-title">
            <div className="company-title">
              <a className="disable-click-name" href="javascript:void(0);">
                {props?.userName || "-"}
              </a>
            </div>
            <div className="company-type">{props?.roleDiscussion || "-"}</div>
          </div>
        </div>
      </div>
    </>
  );
}
