import NotFound from '../screen/container/not-found/not-found';

export const routesBS = [
  {
    // component: BS,
    // Dev #51628 - OEM Upgrade plan page includes Business service ads ribbon
    component: NotFound,
    exact: true,
    path: '/bs/home',
    guard: 'public',
  },

  {
    // component: Favorites,
    // Dev #51628 - OEM Upgrade plan page includes Business service ads ribbon
    component: NotFound,
    exact: true,
    path: '/bs/favorites',
    guard: 'public',
  },

  {
    // component: Listings,
    // Dev #51628 - OEM Upgrade plan page includes Business service ads ribbon
    component: NotFound,
    exact: true,
    path: '/bs/listings',
    guard: 'public',
  },

  {
    // component: Post,
    // Dev #51628 - OEM Upgrade plan page includes Business service ads ribbon
    component: NotFound,
    exact: true,
    path: '/bs/post',
    guard: 'public',
  },

  {
    // component: Ads,
    // Dev #51628 - OEM Upgrade plan page includes Business service ads ribbon
    component: NotFound,
    exact: true,
    path: '/bs/ads',
    guard: 'public',
  },
];
