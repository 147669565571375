/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { IPropsAbout } from "../type";

export default function BlockTargetedIndustry(props: IPropsAbout) {
  const [index2, setindex2] = useState<number>(6);
  const targetedIndustryLength =
    props?.dataSource?.companyResellerState?.targetedIndustry?.length;

  return (
    <div className="col-lg-4 col-12 contactInfo mt-4">
      <div className="boxEditProfil">
        <a
          className="editItem"
          onClick={(e) => {
            e.preventDefault();
            props?.dataSource?.setIndustryShow(true);
          }}
        >
          Edit
          <span className="icon-edit-full"></span>
        </a>
        <h3 className="title">Targeted industry</h3>
        <ul className="listnamelabel">
          {props?.dataSource?.companyResellerState?.targetedIndustry
            ?.filter((el: { id: number; name: string }, index: number) => {
              return index < index2;
            })
            ?.map((element: { id: number; name: string }, index: number) => (
              <li key={index}>
                <div className="name">{element?.name}</div>
              </li>
            ))}
          {targetedIndustryLength > 6 && (
            <li className="link">
              <a
                onClick={(evt) => {
                  evt.preventDefault();
                  setindex2(
                    index2 === targetedIndustryLength
                      ? 6
                      : targetedIndustryLength
                  );
                }}
              >
                See {index2 === targetedIndustryLength ? "less" : "more"}
                {index2 === targetedIndustryLength
                  ? ""
                  : `(${targetedIndustryLength - index2})`}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
