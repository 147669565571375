import React, { useState, useEffect } from 'react';

export type IPropsSlide = {
  defaultValue: Array<number>;
};
export default function UseSliderFundraising(props: IPropsSlide) {
  const [fundraisingGoalSlide, setFundraisingGoalSlide] = useState<
    Array<number>
  >(props?.defaultValue);

  const [unitFundraisingGoal, setUnitFundraisingGoal] = useState(
    props?.defaultValue[0] < 1000000 ? 'K USD' : 'M USD'
  );
  const [unitFundraisingGoalMax, setUnitFundraisingGoalMax] = useState(
    props?.defaultValue[1] < 1000000 ? 'K USD' : 'M USD'
  );
  const [fundraisingGoal, setFundraisingGoal] = useState<Array<number>>([]);

  /**
   * @returns initialise Fundraising selected
   */
  useEffect(() => {
    if (props?.defaultValue.length > 0) {
      let tempMin: number = 0;
      let tempMax: number = 0;

      if (props?.defaultValue[0] < 1000000) {
        tempMin = props?.defaultValue[0] / 1000;
      } else {
        tempMin = props?.defaultValue[0] / 1000000;
      }

      if (props?.defaultValue[1] < 1000000) {
        tempMax = props?.defaultValue[1] / 1000;
      } else {
        tempMax = props?.defaultValue[1] / 1000000;
      }
      setFundraisingGoalSlide([tempMin, tempMax]);
      reverseFundraisingGoalSlide(tempMin, tempMax);
    }
    return () => {};
  }, [props?.defaultValue]);

  const customFundraisingGoalSlide = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
      case 1:
        tempMin = 0;
        setUnitFundraisingGoal('');
        break;
      case 10:
      case 11:
        tempMin = 50;
        setUnitFundraisingGoal('K USD');
        break;
      case 20:
      case 21:
        tempMin = 250;
        setUnitFundraisingGoal('K USD');
        break;
      case 30:
      case 31:
        tempMin = 500;
        setUnitFundraisingGoal('K USD');
        break;
      case 40:
      case 41:
        tempMin = 1;
        setUnitFundraisingGoal('M USD');
        break;
      case 50:
      case 51:
        tempMin = 2;
        setUnitFundraisingGoal('M USD');
        break;
      case 60:
      case 61:
        tempMin = 5;
        setUnitFundraisingGoal('M USD');
        break;
      case 70:
      case 71:
        tempMin = 10;
        setUnitFundraisingGoal('M USD');
        break;
      case 80:
      case 81:
        tempMin = 15;
        setUnitFundraisingGoal('M USD');
        break;
      case 90:
      case 91:
        tempMin = 25;
        setUnitFundraisingGoal('M USD');
        break;
      case 100:
      case 101:
        tempMin = 50;
        setUnitFundraisingGoal('M USD');
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
      case 1:
        tempMax = 60;
        break;
      case 10:
      case 11:
        tempMax = 50;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 20:
      case 21:
        tempMax = 250;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 30:
      case 31:
        tempMax = 500;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 40:
      case 41:
        tempMax = 1;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 50:
      case 51:
        tempMax = 2;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 60:
      case 61:
        tempMax = 5;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 70:
      case 71:
        tempMax = 10;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 80:
      case 81:
        tempMax = 15;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 90:
      case 91:
        tempMax = 25;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 100:
      case 101:
        tempMax = 50;
        setUnitFundraisingGoalMax('M USD');
        break;
      //   case 100:
      case 110:
        tempMax = 60;
        setUnitFundraisingGoalMax('M USD');
        break;
      default:
        break;
    }
    setFundraisingGoalSlide([tempMin, tempMax]);
    setFundraisingGoal([min, max]);
  };

  const reverseFundraisingGoalSlide = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
        tempMin = 0;
        setUnitFundraisingGoal('');
        break;
      case 50:
        tempMin = 10;
        setUnitFundraisingGoal('K USD');
        break;
      case 250:
        tempMin = 20;
        setUnitFundraisingGoal('K USD');
        break;
      case 500:
        tempMin = 30;
        setUnitFundraisingGoal('K USD');
        break;
      case 1:
        tempMin = 40;
        setUnitFundraisingGoal('M USD');
        break;
      case 2:
        tempMin = 50;
        setUnitFundraisingGoal('M USD');
        break;
      case 5:
        tempMin = 60;
        setUnitFundraisingGoal('M USD');
        break;
      case 10:
        tempMin = 70;
        setUnitFundraisingGoal('M USD');
        break;
      case 15:
        tempMin = 80;
        setUnitFundraisingGoal('M USD');
        break;
      case 25:
        tempMin = 90;
        setUnitFundraisingGoal('M USD');
        break;
      // case 50:
      //   tempMin = 100;
      //   setUnitFundraisingGoal("M USD");
      //   break;
      default:
        break;
    }
    switch (max) {
      // case 60:
      //   tempMax = 0;
      //   break;
      case 50:
        tempMax = 10;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 250:
        tempMax = 20;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 500:
        tempMax = 30;
        setUnitFundraisingGoalMax('K USD');
        break;
      case 1:
        tempMax = 40;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 2:
        tempMax = 50;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 5:
        tempMax = 60;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 10:
        tempMax = 70;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 15:
        tempMax = 80;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 25:
        tempMax = 90;
        setUnitFundraisingGoalMax('M USD');
        break;
      case 60:
        tempMax = 1100;
        setUnitFundraisingGoalMax('M USD');
        break;
      default:
        break;
    }
    setFundraisingGoal([tempMin, tempMax]);
  };

  return {
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
  };
}
