import React, { useState, useEffect, useReducer } from "react";
import UseEventFilters from "./use-handle-event-filter-ctr";
import { useSelector, useDispatch } from "react-redux";
import { url } from "../../service/constant";
import { rootState } from "../../redux/reducers";
import { IsvProgramType } from "../../interface/isv-type";
import { IAction } from "../../redux/types/acount-interface";
import {
  patchDataService,
  postJsonDataService,
} from "../../service/applicatif/back-end-service";
import { OEM_PROGRAM } from "../../service/constant";
import useEventListener from "../../hooks/event-handler";
import { useHistory } from "react-router-dom";
import { conversationType } from "../../utils";

const filtered = [
  {
    value: "relevance",
    label: "Relevance",
  },
  {
    value: "last_update",
    label: "Last Update",
  },
  {
    value: "company_name",
    label: "Company name",
  },
  {
    value: "last_unlocked",
    label: "Last Unlocked",
  },

  {
    value: "engagement_date",
    label: "Engagement Date",
  },
];

const GET_MATCHING_ISV_UNLOCKED = "GET_MATCHING_ISV_UNLOCKED";
const HANDLE_FAVORITE_ISV = "HANDLE_FAVORITE_ISV";

type IPropsInitialState = {
  isv: Array<IsvProgramType>;
  currentPage: number;
  totalDataPaginate: number;
  unlocked_left: number;
};
const initialState: IPropsInitialState = {
  isv: [],
  totalDataPaginate: 0,
  currentPage: 0,
  unlocked_left: 0,
};

const helperAddFavorie = (state: Array<any>, id: number) => {
  return state?.map((el: any) => {
    if (el?.isv?.id?.toString() === id?.toString()) {
      return {
        ...el,
        is_favorite: !el?.is_favorite,
      };
    } else return el;
  });
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_MATCHING_ISV_UNLOCKED:
      return {
        ...state,
        isv: action.payload?.isvListe,
        currentPage: action?.payload?.currentPage,
        totalDataPaginate: action.payload?.totalDataPaginate,
      };

    case HANDLE_FAVORITE_ISV:
      return {
        ...state,
        isv: helperAddFavorie(state?.isv, action.payload),
      };
    default:
      return state;
  }
};

export default function UseIsvUnlocked() {
  const [orderBy, setOrderBy] = useState("DESC");

  const [search_name, setSearch_name] = useState("");

  const [page, setPage] = useState(1);

  const [sortBy, setSortBy] = useState<string>("relevance");

  const userState = useSelector((state: rootState) => state.userReducer);

  const [loaderMatchingUnlocked, setLoaderMatchingUnlocked] =
    useState<boolean>(false);

  const selectedOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const history = useHistory();

  const [allMatchingUnlocked, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);

  const isOemUser = userState?.roles?.[0] === OEM_PROGRAM;

  const loadMoreIsvUnlocked = async (p: number) => {
    setPage(p);
    let paramsChange = {
      ...defaultParams,
      page: p,
    };
    await getAllUnlockedFilter(paramsChange, dispatch);
  };

  const {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
    handleIsCheched,
    isChechedContacted,
    unlockedByUser,
    handleUserSeleted,
  } = UseEventFilters();

  let oemParams = {
    oem: selectedOemProgram?.program?.id,
  };

  let investoPrams = {
    funding_round: selectedFunding?.join(),
    fundraising_goal: selectedFundraising?.join(),
  };

  let defaultCombine = {
    minimumMatch: sliderValue[0],
    maximumMatch: sliderValue[1],
    country: selectedCountry?.join(),
    segment: selectedSegment?.join(),
    targeted_industry: selectedTargeted?.join(),
    company_size: selectedCompanySize?.join(),
    annual_turnover: selectedAnnual?.join(),
    sortBy,
    orderBy,
    searchCompanyName: search_name,
    page,
    contacted_isv_only: isChechedContacted ? "yes" : "no",
    unlocked_by: unlockedByUser,
  };

  let defaultParams = isOemUser
    ? { ...defaultCombine, ...oemParams }
    : { ...defaultCombine, ...investoPrams };

  const getAllUnlockedFilter = async (params: any, dispatch: any) => {
    setLoaderMatchingUnlocked(true);

    let urlValue = isOemUser
      ? url?.findIsv?.matching_unlock_oem_isv
      : url?.matching_investor_isv?.matching_unlock_investor_isv;

    const response = await postJsonDataService(
      urlValue,
      params,
      userState?.token
    );

    if (response?.status === 200) {
      const result = {
        isvListe: response?.data?.results,
        totalDataPaginate: response?.data?.total_to_paginate,
        currentPage: params?.page,
      };
      dispatch({ type: GET_MATCHING_ISV_UNLOCKED, payload: result });
    }
    setLoaderMatchingUnlocked(false);
  };

  useEffect(() => {
    (async () => {
      await getAllUnlockedFilter(defaultParams, dispatch);
    })();
  }, []);

  const resetFilter = async () => {
    resetFilterEvent();
    if (search_name !== "") setSearch_name("");
    if (page !== 1) setPage(1);
    if (sortBy !== "relevance") setSortBy("relevance");
    let paramsChange = {
      ...defaultParams,
      contacted_isv_only: "no",
      unlocked_by: "",
      funding_round: "",
      fundraising_goal: "",
      minimumMatch: 60,
      maximumMatch: 100,
      country: "",
      segment: "",
      targeted_industry: "",
      company_size: "",
      annual_turnover: "",
      sortBy,
      orderBy,
      searchCompanyName: "",
      page: 1,
    };
    await getAllUnlockedFilter(paramsChange, dispatch);
  };

  const lunchFilter = async () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      page: 1,
    };
    await getAllUnlockedFilter(paramsChange, dispatch);
  };

  const handleSearche = async () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      searchCompanyName: search_name,
      page: 1,
    };
    await getAllUnlockedFilter(paramsChange, dispatch);
  };

  const keyEnter = async ({ key }: any) => {
    if (key == "Enter") {
      handleSearche();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const handleFilter = async (params: any) => {
    setSortBy(params?.value);
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      sortBy: params?.value,
      page: 1,
    };
    await getAllUnlockedFilter(paramsChange, dispatch);
  };

  const addFavorite = async (id: number) => {
    let urlFavorite = isOemUser
      ? url?.findIsv?.mark_as_favorite +
        "/" +
        selectedOemProgram?.program?.id +
        "/" +
        id
      : url?.matching_investor_isv?.add_favorie + id;

    let result: any = await patchDataService(urlFavorite, {}, userState?.token);
    if (result?.status === 200) {
      dispatch({ type: HANDLE_FAVORITE_ISV, payload: id });
    }
  };

  const goToIsvPage = (id: number, isLocked: boolean = false) => {
    history.push("/company/company-unlocked/" + id);
  };

  const constactIsv = (idIsv: number) => {
    history.push("/contact/discussion", {
      userId: idIsv,
      discussionType:
        userState.roles[0] === OEM_PROGRAM
          ? conversationType.oem_to_isv
          : conversationType.investor_to_isv,
    });
  };

  return {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    resetFilter,
    lunchFilter,
    userState,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
    handleIsCheched,
    isChechedContacted,
    allMatchingUnlocked,
    loaderMatchingUnlocked,
    handleUserSeleted,
    unlockedByUser,
    setSearch_name,
    search_name,
    handleSearche,
    filtered,
    handleFilter,
    addFavorite,
    goToIsvPage,
    constactIsv,
    isvMatchingLeft:
      selectedOemProgram?.program?.oemProgramBillingDetails?.unlock_isv_number,
    loadMoreIsvUnlocked,
  };
}
