import CompleteInfoInvestor from '../screen/container/acount/complete-create-account-investor/complete-info-investor/complete-info-investor';
import CompleteInvestorPage from '../screen/container/acount/complete-create-account-investor/complete-page-investor/complete-page-investor';
import CompleteInvestorTypeof from '../screen/container/acount/complete-create-account-investor/complete-typeof-investor/complete-typeof-investor';
import CompleteInvestorContry from '../screen/container/acount/complete-create-account-investor/complete-contry-investor/complete-contry-ivestor';
import CompleteInvestorTarget from '../screen/container/acount/complete-create-account-investor/complete-target-investor/complete-target-investor';
import CompleteCategoryInvestor from '../screen/container/acount/complete-create-account-investor/complete-category-investor/complete-category-investor';
import completeCompanyInvestor from '../screen/container/acount/complete-create-account-investor/complete-indistrue-investor/complete-indistrue-investor';
import completePublishInvestor from '../screen/container/acount/complete-create-account-investor/complete-publish-investor/complete-publish-ivestor';
import GetStartedInvestor from '../screen/container/get-started/investor/get-started-investor';
import ProfilInvestor from '../screen/container/investor/profil-investor/index';
import { FindInvestor } from '../screen/container/investor/find-investor/find-investor';
import FindInvestorIndex from '../screen/container/investor/find-investor';
import InvestorUnlocked from '../screen/container/investor/investor-unlocked/investor-unlocked';
import InvestorFavorite from '../screen/container/investor/find-investor/favorite-investor/favorite';
import FindInvestorLanding from '../screen/container/investor/find-investor/find-investor-landing';
import { FindInvestorWrapper } from '../screen/container/investor/find-investor/find-investor-wrapper';
import { INVESTOR } from '../service/constant';

export const routesInvestors = [
  {
    path: '/complete-info-investor',
    exact: true,
    component: CompleteInfoInvestor,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-page-investor',
    exact: true,
    component: CompleteInvestorPage,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-typeof-investor',
    exact: true,
    component: CompleteInvestorTypeof,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-contry-investor',
    exact: true,
    component: CompleteInvestorContry,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-target-investor',
    exact: true,
    component: CompleteInvestorTarget,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-category-investor',
    exact: true,
    component: CompleteCategoryInvestor,
    guard: 'not-logged-page',
  },

  {
    path: '/complete-indistrue-investor',
    exact: true,
    component: completeCompanyInvestor,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-publish-investor',
    exact: true,
    component: completePublishInvestor,
    guard: 'not-logged-page',
  },
  {
    path: '/get-started-inv',
    exact: true,
    component: GetStartedInvestor,
    guard: 'public',
  },
  {
    path: '/profil/investor',
    exact: true,
    component: ProfilInvestor,
    guard: 'private',
    allowedRole: [INVESTOR],
  },
  {
    path: '/investor/find-investor-wrapper',
    exact: true,
    component: FindInvestorWrapper,
    guard: 'private',
  },
  {
    path: '/investor/find-investor',
    exact: true,
    component: FindInvestor,
    guard: 'private',
  },
  {
    path: '/find-investors',
    exact: true,
    guard: 'private',
    component: FindInvestorIndex,
  },
  {
    path: '/investor/investor-unlocked',
    exact: true,
    component: InvestorUnlocked,
    guard: 'private',
  },
  {
    path: '/investor/favorite',
    exact: true,
    component: InvestorFavorite,
    guard: 'private',
  },
  {
    path: '/inv-page/:id',
    exact: true,
    component: ProfilInvestor,
    guard: 'private',
  },
  {
    path: '/landing-find-inv',
    exact: true,
    component: FindInvestorLanding,
    guard: 'public',
  },
];
