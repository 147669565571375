import React, { useState, useEffect } from "react";
import { getAffiliatedMembers } from "../../../../../../service/applicatif/acount";
import { refaCtoNullKey, setInternationalPhoneNumber } from "../../../../../../utils";
import MemberCard from "../../../member-card/member-card";
import { contact } from "../../../../../../utils/country_phone";

const ProfilBodyIsv = (props: any) => {
  const [isvInCompanyPage, setIsvInCompanyPage] = useState<Array<any>>([]);
  const [dialCode, setDialCode] = useState<string | null>(null);
  const [internationalPhone, setInternationalPhone] = useState<string>("");
  useEffect(() => {
    async function getAffilliated() {
      const result = await getAffiliatedMembers(
        props.userState?.isvInCompanyPage?.company_page?.id,
        props.userState.token
      );
      if (result?.status === 200) {
        setIsvInCompanyPage(result?.data?.users);
      }
    }

    if (props.userState?.isvInCompanyPage) {
      getAffilliated();
    } else {
      setIsvInCompanyPage([]);
    }
    if (
      props?.userState?.phone_number_code !== "" &&
      props?.userState?.phone_number_code !== null
    ) {
      setDialCode(
        contact.filter(
          (element) => element.code === props?.userState?.phone_number_code
        )[0].dial_code
      );
    }
  }, []);

  return (
    <>
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">Contact information</h2>
          <ul className="ulListInfo">
            <li>
              <div className="label">Primary email</div>
              <div className="infoLabel">
                {props?.userState?.email}
                <span className="icon-userIcon"></span>
              </div>
            </li>
            <li>
              <div className="label">Work email</div>
              <div className="infoLabel">
                {refaCtoNullKey(props?.userState?.primary_email, "-")}
                <span className="icon-userIcon"></span>
              </div>
            </li>
            <li>
              <div className="label">Phone number</div>
              <div className="infoLabel">
                {/* {dialCode ? <>{"(" + dialCode + ") "}</> : <></>} */}
                {/* {refaCtoNullKey(props?.userState?.phone, "-")} */}
                {setInternationalPhoneNumber(props?.userState?.phone, dialCode)}
                {props?.userState?.lock_phone_number === false ? (
                  <>
                    <span className="icon-userIcon"></span>
                  </>
                ) : (
                  <>
                    <span className="icon-locked"></span>
                  </>
                )}
              </div>
            </li>
            <li>
              <div className="label">Linkedin</div>
              <div className="infoLabel">
                <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/in/" +
                    refaCtoNullKey(props?.userState?.linkedin, "-")
                  }
                >
                  {refaCtoNullKey(props?.userState?.linkedin, "-").length > 36
                    ? refaCtoNullKey(props?.userState?.linkedin, "-").substring(
                        0,
                        33
                      ) + "..."
                    : refaCtoNullKey(props?.userState?.linkedin, "-")}
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          <div className="infoLabel">
            {refaCtoNullKey(props?.userState?.plain_text, "-")}
          </div>
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <h2 className="title">Company page - Affiliated insiders</h2>
        <ul className="listAffiliated">
          <MemberCard
            members={isvInCompanyPage}
            company_page={
              props?.userState?.isvInCompanyPage?.company_page.company_name
            }
          />
        </ul>
      </div>
    </>
  );
};

export default ProfilBodyIsv;
