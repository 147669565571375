import { useHistory } from "react-router-dom";
import { fileUrl } from "../../../../../utils";

type Iprops = {
  profil: string;
  userName: string;
  roleDiscussion: string;
  profil_current_user: string;
  companyName_current_user: string;
  roleDiscussion_current: string;
  ma_id: number;
};
export default function MAheaderMessage(props: Iprops) {
  const history = useHistory();
  const goToMA = () => {
    history.push("/ma/ads/" + props?.ma_id);
  };
  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          {props.profil && <img src={fileUrl(props.profil)} alt="profil" />}
        </div>
        <div className="info-title">
          <div className="company-title">
            <a href="javascript:;" className="disable-click-name">
              {props?.userName || "-"}
            </a>
          </div>
          <div className="company-type">{props?.roleDiscussion || "-"}</div>
          <a className="read_add" onClick={goToMA}>
            {"» Read the ad"}
          </a>
        </div>
      </div>
    </>
  );
}
