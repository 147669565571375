import { IProgramReview } from "../../controller/community-ctr/bloc-community-ctr/use-program-review-ctr";
import store from "../../redux/store";
import { getDataWithoutToken, postData, postPhoto } from "../api";
import { url } from "../constant";
import { getDataService } from "./back-end-service";

/**
 * @function get Filtered Programs
 *
 */
export const getFilteredProgram = async (filter: string = "") => {
  filter = filter === "" ? "" : ("/" + filter);
  let result = await getDataService(
    url?.community?.get_filtered_programs + "IMPORT" + filter,
    {},
    store && store?.getState()?.userReducer?.token
  );
  if (result?.status === 200 || result?.status === 201) {
    return result?.data;
  } else {
    return [];
  }
};

/**
 * @function get Suggestion Programs
 *
 */
export const getSuggestionsPrograms = async (filter: string = "") => {
  filter = filter === "" ? "" : ("/" + filter);
  let result = await getDataService(
    url?.community?.get_suggestions + "IMPORT" + filter,
    {},
    store && store?.getState()?.userReducer?.token
  );
  if (result?.status === 200 || result?.status === 201) {
    const transformedData = result?.data.map((item: any) => ({
      label: item.program_name,
      value: item.id,
    }));
    return transformedData;
  } else {
    return [];
  }
};

/**
 * @function create a post
 *
 */
export const createPost = async (data: any, callback: any) => {
  try {
    const result = await postPhoto(
      {
        url: url?.community?.create_post,
        data: data,
      },
      store && store?.getState()?.userReducer?.token
    );
    if (result?.status === 201) {
      callback(true, result?.data);
      return;
    } else {
      callback(false, null);
    }
  } catch (error: any) {
    callback(false, null);
  }
};

/**
 * @function get tags for press release
 *
 */
export async function getTags() {
  try {
    const result = await getDataWithoutToken(url?.community?.get_tags, {
      itemsPerPage: 5,
    });
    if (result?.status === 200 || result?.status === 201) {
      return result?.data;
    }
  } catch (error: any) {}
}

/**
 * @function get PressRelease
 *
 */
export async function getPost(data: any, callback: any) {
  let posts: any[] = [];
  try {
    const result = await getDataService(
      url?.community?.get_posts,
      data,
      store && store?.getState()?.userReducer?.token
    );
    if (result?.status === 200) {
      return result;
    }
    callback(result);
  } catch (error: any) {
    callback(error);
  }
}

export async function postFollowCategory(id: number, token: string) {
  return await postData(
    url.community.follow_category,
    {
      category: "/api/categories/" + id,
    },
    token
  );
}
