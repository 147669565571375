import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePrivateKey } from '../../../../../service/constant';
import CardSetupPremiumForm from '../../../../widget/billing-center/card-premium-registration/card-setup-form';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../../redux/reducers';
import { Stripe } from '@stripe/stripe-js';

type Iprops = {
  firstName: string;
  lastName: string;
  setFirstName: any;
  setLastName: any;
  showCardRegistrationForm: boolean;
  setShowCardRegistrationForm: () => void;
  setShowPaymentSuccessfullModal: (value: boolean) => void;
  setShowPaymentErrorModal: (value: boolean) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  handleErrorCard: () => void;
};
const CardRegistrationPremiumInput = (props: Iprops) => {
  const user = useSelector((state: rootState) => state.userReducer);
  let stripePromise: Stripe | PromiseLike<Stripe | null> | null = null;
  if (user.token) {
    stripePromise = loadStripe(stripePrivateKey);
  }

  return (
    <>
      {user.token && <Elements stripe={stripePromise}>
      <CardSetupPremiumForm
        firstName={props.firstName}
        lastName={props.lastName}
        setFirstName={props.setFirstName}
        setLastName={props.setLastName}
        showCardRegistrationForm={props.showCardRegistrationForm}
        setShowCardRegistrationForm={props.setShowCardRegistrationForm}
        setShowPaymentSuccessfullModal={props.setShowPaymentSuccessfullModal}
        setShowPaymentErrorModal={props.setShowPaymentErrorModal}
        invoiceNumber={props.invoiceNumber}
        setInvoiceNumber={props.setInvoiceNumber}
        handleErrorCard={() => props.handleErrorCard()}
      />
    </Elements>}
    </>
  );
};
export default CardRegistrationPremiumInput;
