import React from "react";
import { fileUrl, mounths } from "../../../../../utils";
import UseIsvMemberPlan, { IPropsMemberBilling } from "./use-isv-member-plan";
import Carousel from "react-bootstrap/Carousel";

const renderLineMember = (members: Array<IPropsMemberBilling>) =>
  members?.map((member: any) => (
    <div className="row justify-content-around color-grey">
      <div className="d-flex align-items-center p-3">
        <div className="profilImg empty">
          <img src={fileUrl(member?.user_photo)} alt="" />
        </div>
        <div className="infoAffiliated">
          <a>
            {member.user_last_name} {member.user_first_name}
          </a>
          <span className="role">{member.user_role}</span>
        </div>
      </div>
      <div className="d-flex p-3 flex-column">
        <div className="font-weight-600 font-size-medium color-black">{member.UNLOCK_INVESTOR === 0 ? "- " : member.UNLOCK_INVESTOR} </div>
        Investor <br />unlock(s)
      </div>
      <div className="d-flex p-3 flex-column">
        <div className="font-size-medium color-black">{member.POST_PRESS_RELEASE === 0 ? "- " : member.POST_PRESS_RELEASE}{" "}</div>
        PR <br />published
      </div>
      {/* <div className="d-flex p-3 flex-column">
        <div className="font-size-medium color-black">{member.POST_CLASSIFIED_ADS === 0 ? "- " : member.POST_CLASSIFIED_ADS}{" "}</div>
        Marketplace <br />ads published
      </div> */}
      <div className="d-flex p-3 flex-column">
          <div className="font-size-medium color-black">{member.POST_CORPORATE_DEVELOPMENT === 0 ? "- " : member.POST_CORPORATE_DEVELOPMENT}{" "}</div>
        Corp. development <br />ads published
      </div>
      <div className="d-flex p-3 flex-column">
          <div className="font-size-medium color-black">{member.POST_EVENT === 0 ? "- " : member.POST_EVENT}{" "}</div>
        Events <br />published
      </div>
    </div>
  ));

const MembersPlanDetails = () => {
  const User = UseIsvMemberPlan();
  return (
    <>
      {/* Charging members details */}
      <div>
        <div className="row">
          <div className="col-md-3 selectMonth">
            <Carousel
              interval={null}
              activeIndex={User.index}
              onSelect={User.handleSelect}
            >
              {mounths?.map((el: { id: number; value: string }) => (
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>
                      {el.value} {User.currentYears}
                    </h3>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="contentBorder affiliatedPopup p-0">
        {renderLineMember(User.userInfo)}
      </div>
    </>
  );
};

export default MembersPlanDetails;
