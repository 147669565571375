import Tab from "react-bootstrap/Tab";
import { LoadingFavoriteProgram } from "../../../widget/loader/index";
import Pagination from "../../../widget/use-pagination/pagination";
import TimeRange from "./time-range";
import { ITransaction } from "./transaction-type";
import Transaction from "./use-transaction";

type Iprops = {
  tabType: string;
};
export default function TabPanTransaction(props: Iprops) {
  const providerTransaction = Transaction({ tabType: props?.tabType });

  return (
    <Tab.Pane eventKey="history_list">
      <TimeRange
        endDate={providerTransaction.endDate}
        lowDate={providerTransaction.lowDate}
        setEndDate={providerTransaction.setEndDate}
        setStartDate={providerTransaction.setStartDate}
      />
      <div className="contentBorder radius p-0 transaction">
        <div className="border-bottom">
          <div className="row">
            <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
              <div className="title"></div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Date</div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Descriptions</div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
              <div className="title">Status</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Amount (ex. VAT)</div>
            </div>
          </div>
        </div>

        {!providerTransaction.transaction
          ? LoadingFavoriteProgram()
          : providerTransaction?.transaction?.items?.map((el: ITransaction) => (
              <div className="row">
                <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
                  <div className="img_receipts"></div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">{el.created.split("-")[0]}</div>
                  <div className="transactionHours">
                    <span className="icon-clock"></span>
                    {el.created.split("-")[1]}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                  <div className="">{el.product}</div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
                  <div className="">
                    {el.status === "PAID" ? "PAID" : "UNPAID"}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">{`${el.currency === "usd" ? "$" : "€"} ${
                    el.amount
                  }${el.currency?.toUpperCase()}`}</div>
                </div>
              </div>
            ))}

        <div className="pagginationCategory">
          {providerTransaction.transaction && (
            <Pagination
              className="pagination"
              currentPage={providerTransaction?.transaction?.meta?.pages}
              totalCount={providerTransaction?.transaction?.meta?.total_count}
              pageSize={5}
              onPageChange={(page: any) =>
                providerTransaction?.handlePageClick(page)
              }
            />
          )}
        </div>
      </div>
    </Tab.Pane>
  );
}
