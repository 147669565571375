import recruitement from '../screen/container/recruitement/index';
// import Favorites from '../screen/container/software-labeling/favorites';
// import Listings from '../screen/container/software-labeling/mylistings';
// import Post from '../screen/container/software-labeling/post-software-labeling';
// import Ads from '../screen/container/software-labeling/ads';

export const routesRecruitement = [
    {
        component: recruitement,
        exact: true,
        path: "/recruitement/home",
        guard: "public",
    },

    // {
    //     component: Favorites,
    //     exact: true,
    //     path: "/softwareLabeling/favorites",
    //     guard: "public",
    // },

    // {
    //     component: Listings,
    //     exact: true,
    //     path: "/softwareLabeling/listings",
    //     guard: "public",
    // },

    // {
    //     component: Post,
    //     exact: true,
    //     path: "/softwareLabeling/post",
    //     guard: "public",
    // },

    // {
    //     component: Ads,
    //     exact: true,
    //     path: "/softwareLabeling/ads",
    //     guard: "public",
    // },
];