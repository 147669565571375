import React from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { IPropsSettings } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import moment from "moment";
import { conversationType } from "../../../../../utils";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UseUnpublishProgram from "../../../../../controller/investor-ctr/use-unpublish-investor-ctr";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import warning from "../../../../../resources/images/warning.svg";
const queryString = require("query-string");

export default function SettingProfil(props: IPropsSettings) {
  //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
  const unpublishProgramCtr = UseUnpublishProgram();
  const history = useHistory();
  const location = useLocation();
  let queryParameter = queryString.parse(location?.search);
  const selectedStage = queryParameter ? queryParameter.stage : "all";

  const txtAnonymous =
    props?.isAnonyme || props?.investorPage?.status === false
      ? "Publish"
      : "Update";

  const closeAndShowPopup = () => {
    unpublishProgramCtr.setShowBtnUnpublish(false);
    unpublishProgramCtr.setShowPopupUnpublish(true);
  };

  const gearUnpublish = () => {
    unpublishProgramCtr.setShowBtnUnpublish(true);
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick + 1);
  };
  const cancelPopUp = () => {
    unpublishProgramCtr.setShowPopupUnpublish(false);
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick + 1);
  };

  return (
    <>
      {!props.isOtherUser && !props.isPreview && (
        <div className="contentRightInvestor">
          <div className="editShow ">
            {!props.isOnEditWysiwyg && (
              <button
                className="btnforEdit doneEdit"
                onClick={() => {
                  props.doneEditing();
                }}
              >
                Done editing
              </button>
            )}

            <button
              className="btnforEdit preview"
              onClick={() => props?.showPreview()}
            >
              Preview
            </button>
          </div>
          <button
            className="btnSetting justForProfil"
            onClick={() => {
              props.showPageSetting();
            }}
          >
            Page settings
          </button>

          <span className="separator justForProfil"></span>
          <button
            className="btnSetting justForProfil"
            onClick={() => {
              props.showEditMode();
            }}
          >
            Edit page
          </button>
          <div className="infoPublication position-relative">
            <div className="infoItem">
              <span className="icon-mini-key"></span>
              <label>
                Status :{" "}
                <b>
                  {props?.investorPage?.isv_find_me
                    ? props?.editMode
                      ? "Draft"
                      : props?.investorPage?.validate_by_devinsider
                      ? props?.investorPage?.status
                        ? "Published"
                        : "Unpublished"
                      : "Inactive"
                    : props?.editMode
                    ? "Draft (unpublished)"
                    : props?.investorPage?.validate_by_devinsider
                    ? props?.investorPage?.status
                      ? "Active"
                      : props?.checkAnonymeCompleted()
                      ? "Active"
                      : "Deactived"
                    : "Unpublished"}
                </b>
              </label>
              {
                //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
                props?.investorPage?.status === true &&
                  props?.investorPage?.validate_by_devinsider === true &&
                  !props?.editMode && (
                    <span
                      className="icon-settings ml-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => gearUnpublish()}
                    ></span>
                  )
              }
            </div>
            <div className="infoItem">
              <span className="icon-calendar"></span>
              <label>Last update: </label>
              {unpublishProgramCtr.showBtnUnpublish &&
                unpublishProgramCtr.gearClick % 2 === 0 && (
                  <div
                    className="btnUnpublish"
                    onClick={() => closeAndShowPopup()}
                  >
                    Unpublish
                  </div>
                )}
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <b>
                      {props?.lastUpdate &&
                        moment(new Date(props?.lastUpdate)).format(
                          "MMM D , YYYY [at] h:mm A"
                        )}{" "}
                    </b>
                  </Tooltip>
                }
                defaultShow={false}
              >
                <b className="justForProfil">
                  {props?.lastUpdate &&
                    moment(new Date(props?.lastUpdate)).format(
                      "MMM D, YYYY [at] h:mm A"
                    )}{" "}
                </b>
              </OverlayTrigger>
            </div>
          </div>
          {(props?.isNullDraft || props?.isAnonyme) && (
            <div className="cttbtnUpdate justForProfil">
              {props?.validateBydevinsider && props?.isAnonyme === false ? (
                props?.investorPage?.status === true &&
                props?.investorPage?.isupdated === true ? (
                  <button onClick={() => props?.updateDraft()}>
                    {props?.loader ? <Loader /> : txtAnonymous}
                  </button>
                ) : props?.investorPage?.status === false ? (
                  <button onClick={() => props?.updateDraft()}>
                    {props?.loader ? <Loader /> : txtAnonymous}
                  </button>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <button
                    style={
                      props?.isAnonyme
                        ? {
                            cursor: "not-allowed",
                            backgroundColor: "#E7E7E7",
                            border: "1px solid #E7E7E7",
                            color: "#4F4F4F",
                          }
                        : { cursor: "pointer" }
                    }
                    onClick={() =>
                      props?.isAnonyme ? null : props?.updateDraft()
                    }
                  >
                    {props?.loader ? <Loader /> : "Publish"}
                  </button>
                </>
              )}
            </div>
          )}
          {props?.isAnonyme && (
            <div className="visibility">
              Your visibility settings are set to remain invisible. To publish
              your investor page and receive inquiries from ISVs, change your
              visibility settings under "page settings".
            </div>
          )}
          {!props?.editMode &&
            props?.validateBydevinsider &&
            props?.isAnonyme === false &&
            props?.investorPage?.isupdated &&
            props?.investorPage?.status !== false && (
              <div className="visibility" style={{ color: "red" }}>
                You have modified the content on your page. Click on "Update" to
                publish this modification towards ISVs in the matchmaking
                results.
              </div>
            )}
        </div>
      )}

      {props.isOtherUser && (
        <div className="contentRightInvestor cont">
          {props.matching !== 0 && props.matching && props.matching >= 60 && (
            <div className="matchValue m90 mb-3">{props.matching}% Match</div>
          )}
          {(props.matching === 0 ||
            (props.matching && props.matching < 60)) && (
            <div className="matchValue m60 mb-3"> {"<60"}% Match</div>
          )}
          {!props.isLockedPage && (
            <div className="contact">
              <Link
                to={{
                  pathname: "/contact/discussion",
                  state: {
                    userId: props?.investorPage?.id,
                    discussionType: conversationType.isv_to_investor,
                  },
                }}
                className="btn btn-devinsider px-5 save"
              >
                Contact
              </Link>
            </div>
          )}

          <span className="separator justForProfil"></span>
          <div className="favorites">
            <Link
              to="#"
              className="btn-devinsider px-5 cancel"
              onClick={props.addFavorite}
            >
              <span
                className={`${
                  props.is_favorite ? "icon-star-on" : "icon-star-off"
                } star`}
              ></span>{" "}
              Favorites
            </Link>
            <Link
              to="#"
              className="btn-devinsider px-5 cancel"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/investor/find-investor-wrapper",
                  search: "?stage=" + selectedStage,
                });
              }}
            >
              Back to the Results panel
            </Link>
          </div>
        </div>
      )}

      {/* MODAL CONFIRMATION FOR UNPUBLISHING PROGRAM */}
      <Modal
        show={unpublishProgramCtr.showPopupUnpublish}
        className="createCompanypopup large forStep5custom forUnpublish"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Unpublish</h2>
            <NavLink to="#" className="close" onClick={() => cancelPopUp()}>
              <span className="icon-close-pop"></span>
            </NavLink>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder">
            <img src={warning} alt="" />
            <p className="mb-5 mt-5 text-center">
              Are you sure you want to unpublish your page?{" "}
            </p>
            <p>
              When you unpublish your page, the status will be changed to draft
              and your page will be removed from the matchmaking results. <br />
              You can always re-publish your page at a later point.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => cancelPopUp()}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => unpublishProgramCtr.handleUnpublish()}
            >
              <BtnNext
                text="Confirm"
                showLoader={unpublishProgramCtr.processing}
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
