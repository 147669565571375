import React, { useEffect, useState } from "react";
import Header from "../../../widget/index";
import "./complete-investor-info.scss";
import ModalLicensingModel from "./complete-company-page-modals/licensing-model";
import ModalSummary from "./complete-company-page-modals/company-overview";
import ModalAnnualTurnover from "./complete-company-page-modals/annual-turnover";
import ModalFundingRound from "./complete-company-page-modals/modal-funding-round";
import ModalPaintPoint from "./complete-company-page-modals/modal-paint-point";
import ModalKeyCompetitif from "./complete-company-page-modals/modal-key-competitif";
import ModalTeamUnique from "./complete-company-page-modals/modal-team-unique";

import ModalPhoto from "../../../widget/modal/modal-photo";
import ModalEditBasicInfo from "../../company/profil/modal-edit-bloc-company/edit-company-information";
import ModalTechnologyPartner from "./complete-company-page-modals/partnership-company";
import ModalEditTargetIndustry from "../../company/profil/modal-edit-bloc-company/edit-target-industry";
import UseTargetIndustrieCtr from "../../../../controller/company-ctr/profil/block-profil-company-controller/use-target-industrie-ctr";
import ModalGeographyTarget from "./complete-company-page-modals/geography-target";

import UseModalPhoto from "../../../widget/modal/use-modal-photo";
import {
  UseFinancialCtr,
  UseLicencingModelDistributionChannel,
  UseOverview,
  usePitch,
  UseGeographieTarget,
} from "../../../../controller/company-ctr/profil/block-profil-company-controller";
import UseCompleteIsvForInvestor from "../../../../controller/investor-ctr/index";
import UseInfoCompanyCtr from "../../../../controller/company-ctr/profil/block-profil-company-controller/use-user-info-ctr";
import UsePartenerShipCtr from "../../../../controller/company-ctr/profil/block-profil-company-controller/use-partener-ship-ctr";
import {
  isHaveCompanyLogo,
  isRequired,
  validateCompleteInfo,
} from "../../../../utils";
import Footer from "../../../widget/footer/footer";
import ModalEditProductDevelopment from "../../../container/company/profil/strategy/modal/modal-edit-product-development";
import UseProductDevelopment from "../../../../controller/company-ctr/profil/block-profil-company-controller/use-product-development";
import ModalEditExpressionStrategy from "../../../container/company/profil/strategy/modal/modal-edit-strategy";
import UseExpressionStrategyCtr from "../../../../controller/company-ctr/profil/block-profil-company-controller/use-expression-strategy-ctr";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { LoadingAllPage } from "../../../widget/loader";

export default function TwoCase(props: any) {
  const providerCompleteCompanyPage = UseCompleteIsvForInvestor();
  const providerUseLicencingDistributionC =
    UseLicencingModelDistributionChannel();
  const providerUseOverview = UseOverview();
  const providerUseFinancialCtr = UseFinancialCtr();
  const providerPich = usePitch();
  const providerPhoto = UseModalPhoto();
  const providerUseInfoCompanyCtr = UseInfoCompanyCtr();
  const providerPartenerShip = UsePartenerShipCtr();
  const providerIndustry = UseTargetIndustrieCtr();
  const providerUseGeographieTarget = UseGeographieTarget();
  const providerProductDevelopment = UseProductDevelopment();
  const providerUseExpressionStrategy = UseExpressionStrategyCtr();

  const companyPageReducer = useSelector(
    (state: rootState) => state?.userReducer?.isvInCompanyPage
  );

  const [disable, setDisable] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (companyPageReducer?.role === "MEMBER") {
      setDisable(true);
    }
  }, [disable]);
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (providerCompleteCompanyPage?.progress !== undefined) {
      setIsLoading(false);
    }
  }, [providerCompleteCompanyPage?.progress]);

  if (isLoading) {
    return <LoadingAllPage />;
  }

  if (providerCompleteCompanyPage?.progress === 0 && !isEdited) {
    return <Redirect to={`/investor/find-investor-wrapper?stage=${props.selectedStage}`} />;
  }

  return (
    <>
      {
        // (!providerCompleteCompanyPage?.progress) ? (
        //   <LoadingAllPage />
        // ) : (
        <>
          <div className="TwoCaseContainer">
            <Header />
            {providerCompleteCompanyPage?.progress > 0 && (
              <div className={"container mx-auto"}>
                <div className="contentBorder">
                  <h3 className="title text-center">
                    Complete your company page
                  </h3>
                  <div className="sTitle">
                    <p className="text-center">
                      To match with investors we need some additional
                      information.
                    </p>
                  </div>
                  {disable && (
                    <div className="sTitle">
                      <p className="text-center" style={{ color: "red" }}>
                        Your company page's matchmaking information is
                        incomplete, please contact your company administrator.
                      </p>
                    </div>
                  )}
                  <div className="list">
                    <ul className="listCompletition">
                      {/* Dev #45666,V2.1 Bug lorsque je clique sur "Program Matchmaking" et "Investor Matchmaking" */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject?.website
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseInfoCompanyCtr?.setShowRequestBasicInformation(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.website
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            />
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Company information</span>
                            <div>Add your company website.</div>
                          </div>
                        </li>
                      )}
                      {/* Company description */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.company_summary
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseOverview?.setShowOverview(true);
                              }
                              return;
                            }}
                          >
                            Edit
                            <span className="icon-edit-full" />
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.company_summary
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Company description</span>
                            <div>Outline a brief overview of your company.</div>
                          </div>
                        </li>
                      )}
                      {/* lOGO */}
                      {!isHaveCompanyLogo(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.photo_profil
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerPhoto?.setIsShowUpdateProfil(true);
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isHaveCompanyLogo(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.photo_profil
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            />
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Company logo </span>
                            <div>Upload your company logo.</div>
                          </div>
                        </li>
                      )}
                      {/* Licencing model and Distribution Channel*/}
                      {(!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.licensing_model
                      ) ||
                        !isRequired(
                          providerCompleteCompanyPage?.companyPageObject
                            ?.distribution_channel
                        )) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseLicencingDistributionC?.setIsShowLicencingModel(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.licensing_model
                                ) &&
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.distribution_channel
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Licensing model</span>
                            <div>Select your licensing model(s).</div>
                          </div>
                        </li>
                      )}
                      {/* Segment */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.oem_program_segment_interest
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerPartenerShip?.setisShowPartenerShipModal(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.oem_program_segment_interest
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">
                              Fields of technology partnership you are
                              interested in
                            </span>
                            <div>
                              Helps you find the most relevant programs based on
                              your interests.
                            </div>
                          </div>
                        </li>
                      )}
                      {/* Targeted industry */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.targeted_industry
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerIndustry.setShowRequestTargetIndustry(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.targeted_industry
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Target industry</span>
                            <div>Select your target industry(s).</div>
                          </div>
                        </li>
                      )}
                      {/* Targeted Country */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.targeted_country
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseGeographieTarget?.setshowStrategyGeographic(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.targeted_country
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">
                              Office in North America
                            </span>
                            <div>Do you have an entity in North America.</div>
                          </div>
                        </li>
                      )}
                      {/* Annual Turover */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.annual_turnover
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseFinancialCtr?.setIsShowFinancialAnnual(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.annual_turnover
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Annual revenue</span>
                            <div>
                              Select one of the brackets to indicate your
                              approximate revenue.
                            </div>
                          </div>
                        </li>
                      )}
                      {/* funding_round */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject?.funding_round?.toString()
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseFinancialCtr?.setIsShowModalFundingRoundAndAmount(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject?.funding_round?.toString()
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Funding round</span>
                            <div>What stage of funding are you in?</div>
                          </div>
                        </li>
                      )}
                      {/* Product developement*/}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.future_milestones_product_development
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerProductDevelopment?.setIsShowModalProductDeveloppement(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.future_milestones_product_development
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Product development</span>
                            <div>
                              What are the key short-term and long-term
                              technical milestones?
                            </div>
                          </div>
                        </li>
                      )}
                      {/*Expression Strategy*/}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.futur_milestones_expansion_strategy
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseExpressionStrategy?.setIsShowExpressionStrategic(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.futur_milestones_expansion_strategy
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Expansion strategy</span>
                            <div>What is your geographic expansion plan?</div>
                          </div>
                        </li>
                      )}
                      {/* Amount  you would like to raise */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.amount_want_to_raise
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerUseFinancialCtr?.setIsShowModalFundingRoundAndAmount(
                                  true
                                );
                              }
                              return;
                            }}
                          >
                            Edit
                            <span className="icon-edit-full" />
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.amount_want_to_raise
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">
                              Amount you would like to raise
                            </span>
                            <div>
                              Select one of the brackets to indicate the amount
                              you would to raise.
                            </div>
                          </div>
                        </li>
                      )}
                      {/* What Pain Point */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.paint_point_pitch
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerPich?.setIsShowPitchPoint(true);
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.paint_point_pitch
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">
                              Paint point you are solving
                            </span>
                            <div>
                              What challenges do you help your customers with?
                            </div>
                          </div>
                        </li>
                      )}
                      {/* key competitive differentiator */}
                      {!isRequired(
                        providerCompleteCompanyPage?.companyPageObject
                          ?.key_competitive_differentiator_pitch
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerPich?.setIsShowKeyCompetitive(true);
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                isRequired(
                                  providerCompleteCompanyPage?.companyPageObject
                                    ?.key_competitive_differentiator_pitch
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            ></span>
                          </div>
                          <div className="completitionInfo">
                            <span className="title">
                              Key competitive differentiators
                            </span>
                            <div>
                              What is the edge you have over your competition?
                            </div>
                          </div>
                        </li>
                      )}

                      {!validateCompleteInfo(
                        providerCompleteCompanyPage?.companyPageObject
                      ) && (
                        <li>
                          <Link
                            to="#"
                            className={`linkEditLeftCompany${
                              disable ? " disabled" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdited(true);
                              if (!disable) {
                                providerPich.setIsShowTeamUnique(true);
                              }
                              return;
                            }}
                          >
                            Edit<span className="icon-edit-full"></span>
                          </Link>
                          <div className="completitionLogo">
                            <span
                              className={`icon-${
                                validateCompleteInfo(
                                  providerCompleteCompanyPage?.companyPageObject
                                )
                                  ? "check"
                                  : "warning"
                              }`}
                            />
                          </div>
                          <div className="completitionInfo">
                            <span className="title">Company information</span>
                            <div>What makes your team unique?</div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {providerCompleteCompanyPage?.progress === 0 && isEdited && (
              <div className="pageCompleted">
                <div className="container mx-auto">
                  <div className="contentBorder text-center">
                    <span className="icon-check"></span>
                    <h3 className="title text-center mt-5">
                      Company page successfully completed.
                    </h3>
                    <div className="sTitle">
                      <p className="text-center">
                        You can match with Institutional Investor.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="short-height"></div>
              </div>
            )}

            {/*Modal Edit Summary*/}
            <ModalSummary
              isShowOverView={providerUseOverview?.showOverview}
              setIsShowOverView={providerUseOverview?.setShowOverview}
              modalValueSummary={providerUseOverview?.modalValueSummary}
              setModalValueSummary={providerUseOverview?.setModalValueSummary}
              updateCompanySummary={providerUseOverview?.updateCompanySummary}
              cancelUpdateSummary={providerUseOverview?.cancelUpdateSummary}
              isLoader={providerUseOverview?.loaderOverView}
              summaryLength={providerUseOverview?.summaryLength}
              setSummaryLength={providerUseOverview?.setSummaryLength}
            />

            {/*Modal edit licensing and distribution*/}
            <ModalLicensingModel
              isShowLicencingModel={
                providerUseLicencingDistributionC?.isShowLicencingModel
              }
              cancelUpdateCategorie={
                providerUseLicencingDistributionC?.cancelUpdateCategorie
              }
              licencingModelSelected={
                providerUseLicencingDistributionC?.licenCingModelSelected
              }
              licencingModelData={
                providerCompleteCompanyPage?.dataCompleted?.licensing_model
              }
              distributionModelData={
                providerCompleteCompanyPage?.dataCompleted?.distribution_channel
              }
              distributionModelSelected={
                providerUseLicencingDistributionC?.distributionModelSelected
              }
              addDistributionChannel={
                providerUseLicencingDistributionC?.addDistributionChannel
              }
              addLicencingChecked={
                providerUseLicencingDistributionC?.addLicencingChecked
              }
              updateLicencingModel={
                providerUseLicencingDistributionC?.updateLicencingModel
              }
              loaderlicencingModel={
                providerUseLicencingDistributionC?.loaderlicencingModel
              }
              isHaveError={
                providerUseLicencingDistributionC?.isHaveErrorCategorie
              }
            />

            {/*Modal annual turnover*/}
            <ModalAnnualTurnover
              annualTurnoverData={
                providerCompleteCompanyPage?.dataCompleted?.annual_turnover
              }
              isShowFinancialAnnual={
                providerUseFinancialCtr?.isShowFinancialAnnual
              }
              setIsShowFinancialAnnual={
                providerUseFinancialCtr?.setIsShowFinancialAnnual
              }
              annualTurnOverSelected={
                providerUseFinancialCtr?.annualTurnOverSelected
              }
              setAnnulTurnover={providerUseFinancialCtr?.setAnnulTurnover}
              updateAnnualTurnOver={
                providerUseFinancialCtr?.updateAnnualTurnOver
              }
              loaderFinancial={providerUseFinancialCtr?.loaderFinancial}
            />

            {/*Modal Funding round*/}
            <ModalFundingRound
              amontWouldRise={
                providerCompleteCompanyPage?.dataCompleted
                  ?.amount_you_would_like_to_raise
              }
              setAmountWouldRiseSelected={(params: any) =>
                providerUseFinancialCtr?.setAmountWouldRiseSelected(params)
              }
              amountWouldRiseSelected={
                providerUseFinancialCtr?.amountWouldRiseSelected
              }
              fundingRound={
                providerCompleteCompanyPage?.dataCompleted?.fundingRound
              }
              isShowModalFundingRoundAndAmount={
                providerUseFinancialCtr.isShowModalFundingRoundAndAmount
              }
              loaderFinancial={providerUseFinancialCtr?.loaderFinancial}
              setIsShowModalFundingRoundAndAmount={(params: boolean) =>
                providerUseFinancialCtr?.setIsShowModalFundingRoundAndAmount(
                  params
                )
              }
              updateAnnualTurnOver={() =>
                providerUseFinancialCtr?.updateAnnualTurnOver()
              }
              fundingRoundValue={providerUseFinancialCtr?.fundingRoundValue}
              setFundingRound={(params: string) =>
                providerUseFinancialCtr?.setFundingRoundValue(params)
              }
            />

            <ModalPaintPoint
              isShowPitchPoint={providerPich.isShowPitchPoint}
              loader={providerPich.loaderPitch}
              pitchPointValueForm={providerPich.pitchPointValueForm}
              setIsShowPitchPoint={(p: boolean) =>
                providerPich.setIsShowPitchPoint(p)
              }
              setPitchPointValueForm={(t: string) =>
                providerPich.setPitchPointValueForm(t)
              }
              updatePicth={() => providerPich.updatePicth()}
            />

            <ModalKeyCompetitif
              isShowKeyCompetitive={providerPich.isShowKeyCompetitive}
              loader={providerPich.loaderPitch}
              setIsShowKeyCompetitive={(p: boolean) =>
                providerPich.setIsShowKeyCompetitive(p)
              }
              setValueOfKeyCompetitiveForm={(t: string) =>
                providerPich.setValueOfKeyCompetitiveForm(t)
              }
              valueOfKeyCompetitiveForm={providerPich.valueOfKeyCompetitiveForm}
              updatePicth={() => providerPich.updatePicth()}
            />
            <ModalTeamUnique
              isShowTeamUnique={providerPich.isShowTeamUnique}
              loader={providerPich.loaderPitch}
              setIsShowTeamUnique={(p: boolean) =>
                providerPich.setIsShowTeamUnique(p)
              }
              setValueOfTeamUniqueForm={(t: string) =>
                providerPich.setValueOfTeamUniqueForm(t)
              }
              updatePicth={() => providerPich.updatePicth()}
              valueOfTeamUniqueForm={providerPich.valueOfTeamUniqueForm}
            />
            <ModalPhoto {...providerPhoto} />

            <ModalEditBasicInfo
              show={providerUseInfoCompanyCtr?.showRequestBasicInformation}
              handleSubmit={(p: any) =>
                providerUseInfoCompanyCtr?.handleSubmit(p)
              }
              submitBasicInfo={(p: any) =>
                providerUseInfoCompanyCtr?.submitBasicInfo(p)
              }
              cancelUpdateform={providerUseInfoCompanyCtr?.cancelUpdateform}
              register={providerUseInfoCompanyCtr?.register}
              getValues={providerUseInfoCompanyCtr?.getValues}
              errors={providerUseInfoCompanyCtr?.errors}
              loaderProfil={providerUseInfoCompanyCtr?.loaderProfil}
            />

            {/*Modal technology partnership*/}
            <ModalTechnologyPartner
              cancelTechnologieParteners={
                providerPartenerShip.cancelTechnologieParteners
              }
              loaderPartenership={providerPartenerShip.loaderPartenership}
              providerTechnologyPartnerShip={providerPartenerShip}
              show={providerPartenerShip.isShowPartenerShipModal}
              updateTechnologyPartnerShip={
                providerPartenerShip.updateTechnologiePartener
              }
            />

            {/* Modal Target industry */}
            <ModalEditTargetIndustry
              show={providerIndustry.show}
              cancelUpdataTargeted={providerIndustry.cancelUpdataTargeted}
              updateTargetIndustrie={providerIndustry.updateTargetIndustrie}
              loaderTargetIndustrie={providerIndustry.loaderTargetIndustrie}
              providerUseTargetIndustrieCtr={providerIndustry}
            />

            {/*Modal office in north america*/}
            <ModalGeographyTarget
              allContinents={
                providerCompleteCompanyPage?.dataCompleted?.targetedCountry
              }
              isShowErrorOffice={false}
              dataCompleted={providerCompleteCompanyPage?.dataCompleted}
              {...providerUseGeographieTarget}
            />
            <ModalEditProductDevelopment {...providerProductDevelopment} />
            <ModalEditExpressionStrategy {...providerUseExpressionStrategy} />
            <Footer />
          </div>
        </>
        // )
      }
    </>
  );
}
