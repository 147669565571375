import React from "react";
type IPropsRenderPercent = {
  number: number;
  isUnlock: boolean;
};
export default function RenderPercent(props: IPropsRenderPercent) {
  return (
    <>
      {props.number < 60 && (
        <div className={`matchValue m60 ${!props?.isUnlock ? " locked" : ""}`}>
          <span className="icon-nextbtn" /> 60% Match
        </div>
      )}
      {props?.number >= 60 && props?.number < 65 && (
        <>
          <div
            className={`matchValue m80 ${!props?.isUnlock ? " locked" : ""}`}
          >
            {props?.number} % Match
          </div>
        </>
      )}
      {props?.number >= 65 && props?.number < 75 && (
        <>
          <div
            className={`matchValue m80 ${!props?.isUnlock ? " locked" : ""}`}
          >
            {props?.number} % Match
          </div>
        </>
      )}
      {props?.number >= 75 && props?.number < 90 && (
        <>
          <div
            className={`matchValue m80 ${!props?.isUnlock ? " locked" : ""}`}
          >
            {props?.number} % Match
          </div>
        </>
      )}
      {props?.number >= 90 && (
        <>
          <div
            className={`matchValue m90 ${!props?.isUnlock ? " locked" : ""}`}
          >
            {props?.number} % Match
          </div>
        </>
      )}
    </>
  );
}
