import React, { useEffect, useState } from "react";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { useDebounce } from "use-debounce";
import { url } from "../../../service/constant";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";

type IProps = {
  defaultLabelValue: string;
};

export default function UseSearchEventForm(props: IProps) {
  const [selectedLabel, setSelectedLabel] = useState<string>(
    props.defaultLabelValue
  );

  const [txtFilter, setTxtFilter] = useState<string>("");
  const [debouncedText] = useDebounce(txtFilter, 100);
  const [dataLabelFilter, setDataLabelFilter] = useState<Array<string>>([]);
  const [defaultDataLabel, setDefaultDataLabel] = useState<Array<string>>([]);
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [isLoadFilter, setIsLoadFilter] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const promiseOptions = async (inputValue: string) => {
    let labels = await getDataService(
      url.events?.getSearchTags + "?title=" + inputValue + "&itemPerPage=" + 10,
      {},
      userReducer?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.title,
      value: el?.title,
    }));
    setDataLabelFilter(tempResult);
    return tempResult;
  };

  const defaultOptions = async () => {
    let labels = await getDataService(
      url.events?.getDefaultTags,
      {},
      userReducer?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.title,
      value: el?.title,
    }));
    setDefaultDataLabel(tempResult);
    return tempResult;
  };

  useEffect(() => {
    defaultOptions();
  }, []);

  useEffect(() => {
    async function filterSearch() {
      if (debouncedText) {
        const handleString = txtFilter?.split(",");
        const lastString = handleString?.pop() || "";
        if (lastString) {
          promiseOptions(lastString);
        }
      }
    }

    if (debouncedText && isFocused) {
      if (isLoadFilter) {
        dataLabelFilter?.length > 0 && setDataLabelFilter([]);
        filterSearch();
      } else {
        setIsLoadFilter(true);
      }
    }

    return () => {};
  }, [debouncedText]);

  const handleSelectedLabels = (p: string) => {
    if (isLoadFilter) {
      setIsLoadFilter(false);
    }
    setSelectedLabel(p);
  };

  const handleFilterSelect = (txt: string) => {
    setDataLabelFilter([]);
    const convertArray = txtFilter.split(",");

    //Check if txt isAlreadyExist
    const isExist = convertArray?.find((el: string) => el === txt);
    //remove last string
    convertArray.pop();
    const reformToString = convertArray.join(",");
    if (!isExist) {
      //Make a ',' in end to handlea auther recherche
      const concatString =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + "," + txt + ","
          : txt + ",";
      setTxtFilter(concatString);
    } else {
      const concat =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + ","
          : "";
      setTxtFilter(concat);
    }
  };

  useEffect(() => {
    if (selectedLabel) {
      //Convert to Array
      const handleString = txtFilter?.split(",");

      const findTxt = handleString?.find(
        (el: string) => el?.trim() === selectedLabel?.trim()
      );

      if (findTxt) {
        const tempArray = handleString?.filter(
          (el) => el?.trim() !== selectedLabel?.trim()
        );
        setTxtFilter(tempArray?.join(","));
      } else {
        const concatString =
          txtFilter?.length > 0
            ? txtFilter + ", " + selectedLabel?.trim()
            : selectedLabel;
        const dataArr = concatString?.split(",");
        //Remove occurence
        const toStringOccurence = dataArr?.filter((el) => el !== "");
        setTxtFilter(toStringOccurence?.join(","));
      }
    }
  }, [selectedLabel]);

  useEffect(() => {
    setSelectedLabel(props.defaultLabelValue);
  }, [props.defaultLabelValue]);

  return {
    handleFilterSelect,
    handleSelectedLabels,
    txtFilter,
    setTxtFilter,
    dataLabelFilter,
    setDataLabelFilter,
    defaultDataLabel,
    isFocused,
    setIsFocused,
  };
}
