import React from "react";
import { useHistory } from "react-router";

const urlType = {
  favorites: "/favorite",
  listing: "/listing",
};

export default function SecondHeaderSoftwareLabeling() {
  const history = useHistory();
  const goToHomeSoftware = () => {
    history.push("/softwareLabeling/home");
  };
  const goToFavoritesSoftware = () => {
    history.push("/softwareLabeling/favorites");
  };
  const goToMylistingsSoftware = () => {
    history.push("/softwareLabeling/listings");
  };
  const goToPostAd = () => {
    history.push("/softwareLabeling/post");
  };
  const url = window.location.href;

  const renderActive = (type: string) => {
    return url?.includes(type) ? "active" : "";
  };
  return (
    <div className="secondMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <h2 className="title">
            <a
              className="colorInherit"
              href="javascript:;"
              onClick={(e) => {
                e.preventDefault();
                goToHomeSoftware();
              }}
            >
              Software white labeling
            </a>
          </h2>
          <div className="menuDynamic bl">
            <div className="item">
              <a
                href="javascript:;"
                onClick={(e) => {
                  e.preventDefault();
                  goToFavoritesSoftware();
                }}
                className={renderActive(urlType?.favorites)}
              >
                Favorites
              </a>
            </div>
            <div className="item">
              <a
                href="javascript:;"
                onClick={(e) => {
                  e.preventDefault();
                  goToMylistingsSoftware();
                }}
                className={renderActive(urlType?.listing)}
              >
                My listings
              </a>
            </div>
            <div className="item">
              <div className="searchWord classified">
                <input type="text" className="keyword" value="" />
                <button>
                  <span className="icon-search"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="rightItemHeader">
          <div className="item">
            <a
              href="javascript:;"
              className="startPost"
              onClick={(e) => {
                e.preventDefault();
                goToPostAd();
              }}
            >
              Post an ad
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
