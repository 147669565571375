import "./FindIsv.scss";
import React, { ChangeEvent } from "react";
import Header from "../../widget/index";
//@ts-ignore
import Select from "react-select";
import FilterListe from "./bloc-find-isv/filter";
import UseIsvUnlocked from "../../../controller/find-isv/use-isv-unlocked";
import { LoadingFavoriteProgram } from "../../widget/loader";
import { IsvProgramType } from "../../../interface/isv-type";
import { fileUrl } from "../../../utils";
import moment from "moment";
import MachingComponent from "../../../hooks/MachingComponent";
import Pagination from "../../widget/use-pagination/pagination";

export default function UnlockedIsvOEM() {
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerUseIsvUnlocked = UseIsvUnlocked();

  const renderPercent = (number: string, hasclosed: boolean) => {
    let value = parseInt(number);
    if (hasclosed) {
      value = 59;
    }

    if (value < 60) {
      return (
        <div className="matchValue m60">
          <b>Match</b>
          <span className="icon-nextbtn" />
          {"60 %"}
        </div>
      );
    } else if (value >= 60 && value < 65) {
      return (
        <>
          <div className="matchValue m60">
            <span className="icon-nextbtn" /> <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 65 && value < 75) {
      return (
        <>
          <div className="matchValue m80">
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 75 && value < 90) {
      return (
        <>
          <div className="matchValue m80">
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 90) {
      return (
        <>
          <div className="matchValue m90">
            <b>Match</b> {value}%
          </div>
        </>
      );
    }
  };

  return (
    <MachingComponent>
      <div className="findIsvcontainer">
        <Header />
        <FilterListe
          sliderValue={providerUseIsvUnlocked?.sliderValue}
          setSliderValue={(value: Array<number>) =>
            providerUseIsvUnlocked?.setSliderValue(value)
          }
          lunchFilter={() => providerUseIsvUnlocked?.lunchFilter()}
          setHideType={(p: string) => providerUseIsvUnlocked?.handleHideType(p)}
          hideType={providerUseIsvUnlocked?.selectedHideType}
          setCountryType={(p: string) =>
            providerUseIsvUnlocked?.handleCountry(p)
          }
          selectedCountry={providerUseIsvUnlocked?.selectedCountry}
          selectedTargetedIndustry={providerUseIsvUnlocked?.selectedTargeted}
          setSelectedTargeted={(p: string) =>
            providerUseIsvUnlocked?.handleTargeted(p)
          }
          selectedSegment={providerUseIsvUnlocked?.selectedSegment}
          setSelectedSegment={(p: string) =>
            providerUseIsvUnlocked?.handleSegment(p)
          }
          selectedAnnual={providerUseIsvUnlocked?.selectedAnnual}
          setSelectedAnnual={(p: string) =>
            providerUseIsvUnlocked?.handleAnnual(p)
          }
          selectedCompanySize={providerUseIsvUnlocked?.selectedCompanySize}
          setSelectedCompanySize={(p: string) =>
            providerUseIsvUnlocked?.handleCompanySize(p)
          }
          resetFilter={() => providerUseIsvUnlocked?.resetFilter()}
          userType={providerUseIsvUnlocked?.userState?.roles?.[0]}
          selectedFundingRound={providerUseIsvUnlocked?.selectedFunding}
          setSelectedFundingRound={(p: string) =>
            providerUseIsvUnlocked?.handleFundingRound(p)
          }
          selectedFundraising={providerUseIsvUnlocked?.selectedFundraising}
          setSelectedFundraising={(p: string) =>
            providerUseIsvUnlocked?.handleFundraising(p)
          }
          handleUserUnlocked={(p: string) =>
            providerUseIsvUnlocked?.handleUserSeleted(p)
          }
          unlockedByUser={providerUseIsvUnlocked?.unlockedByUser}
          handleIsvContacted={() => providerUseIsvUnlocked?.handleIsCheched()}
          isCheckedContactedIsv={providerUseIsvUnlocked?.isChechedContacted}
          filter_type="unlocked"
          unlocked_left={
            providerUseIsvUnlocked.allMatchingUnlocked.unlocked_left
          }
        />
        <div className="container Isv">
          <div className="container mx-auto minHeightwin listIsv">
            <div className="listOfIsv">
              <div className="headerOfList">
                <div className="leftHead">
                  <div className="number">
                    Showing{" "}
                    <b>
                      {providerUseIsvUnlocked?.allMatchingUnlocked?.isv?.length}
                    </b>{" "}
                    ISVs
                  </div>
                  <form
                    action=""
                    onClick={(e) => openBlock(e)}
                    onSubmit={(e) => e?.preventDefault()}
                  >
                    <input
                      placeholder="Search..."
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        providerUseIsvUnlocked?.setSearch_name(e?.target?.value)
                      }
                      onBlur={() =>
                        providerUseIsvUnlocked?.search_name?.length <= 0 &&
                        providerUseIsvUnlocked?.handleSearche()
                      }
                      value={providerUseIsvUnlocked.search_name}
                    />
                  </form>
                </div>
                <div className="rightHead">
                  <div className="sortedby labelSelect">
                    <label>Sorted by :</label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={providerUseIsvUnlocked?.filtered}
                      classNamePrefix="tri"
                      defaultValue={providerUseIsvUnlocked?.filtered[0]}
                      onChange={(filter: any) =>
                        providerUseIsvUnlocked?.handleFilter(filter)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="scrollContainer">
                {providerUseIsvUnlocked?.loaderMatchingUnlocked ? (
                  <LoadingFavoriteProgram />
                ) : (
                  <ul className="listOfIsvMatch listUnlocked">
                    {providerUseIsvUnlocked?.allMatchingUnlocked?.isv?.map(
                      (infoIsv: IsvProgramType, index: number) => (
                        <li key={index}>
                          <div className="isvLogo">
                            <img
                              src={fileUrl(infoIsv?.isv?.photo_profil)}
                              alt=""
                            />
                          </div>
                          <div className="isvInfo">
                            <div className="infoUnlocked">
                              <h3 className="title">
                                <span className="name">
                                  {infoIsv?.isv?.company_name}
                                </span>
                                <span
                                  className={
                                    infoIsv?.is_favorite
                                      ? "icon-star-on"
                                      : "icon-star-off"
                                  }
                                  onClick={() =>
                                    providerUseIsvUnlocked?.addFavorite(
                                      infoIsv?.isv?.id
                                    )
                                  }
                                ></span>
                              </h3>
                              {renderPercent(
                                infoIsv?.matching_value?.toString(),
                                infoIsv?.isv?.has_closed_company
                              )}

                              <div className="country">
                                <b>Location</b>
                                {infoIsv?.isv?.country?.country_name}
                              </div>
                            </div>
                            <div className="actionUnlocked">
                              <div className="btnContent">
                                <button
                                  className="btn btn-devinsider view"
                                  onClick={() =>
                                    providerUseIsvUnlocked?.goToIsvPage(
                                      infoIsv?.isv?.id
                                    )
                                  }
                                >
                                  View
                                </button>
                                <button
                                  className="btn btn-devinsider save"
                                  onClick={() => {
                                    providerUseIsvUnlocked?.constactIsv(
                                      infoIsv?.isv?.id
                                    );
                                  }}
                                  disabled={infoIsv?.isv?.has_closed_company}
                                  style={{
                                    cursor: infoIsv?.isv?.has_closed_company
                                      ? "not-allowed"
                                      : undefined,
                                  }}
                                >
                                  Contact
                                </button>
                              </div>
                              {infoIsv?.is_unlocked && (
                                <div className="unlockedBy">
                                  {infoIsv?.isIsvUnlocked ? (
                                    <b>ISV request</b>
                                  ) : (
                                    <>
                                      <b>
                                        Unlocked by {infoIsv?.user_who_unlocks}:
                                      </b>
                                    </>
                                  )}
                                  {infoIsv?.unlocked_at &&
                                    moment(
                                      new Date(infoIsv?.unlocked_at)
                                    ).format("MMMM D, yyyy")}
                                </div>
                              )}

                              {infoIsv.status_discussion ===
                                "NOT_CONTACTED" && (
                                <div className="statusContact unlockedBy notConnected">
                                  <b>Not contacted</b>
                                  <span className="icon-message"></span>
                                  {infoIsv.status_discussion ===
                                    "NOT_CONTACTED" && (
                                    <>
                                      {infoIsv?.time_message_status &&
                                        moment(
                                          new Date(infoIsv?.time_message_status)
                                        ).format("MMMM DD, yyyy")}
                                    </>
                                  )}
                                </div>
                              )}

                              {infoIsv.status_discussion ===
                                "PENDING_DISCUSSION" && (
                                <div className="statusContact unlockedBy hold">
                                  <b>Pending discussion</b>
                                  <span className="icon-message"></span>

                                  {infoIsv.status_discussion ===
                                    "PENDING_DISCUSSION" && (
                                    <>
                                      {infoIsv?.time_message_status &&
                                        moment(
                                          new Date(infoIsv?.time_message_status)
                                        ).format("MMMM DD, yyyy")}
                                    </>
                                  )}
                                </div>
                              )}

                              {infoIsv.status_discussion ===
                                "IN_DISCUSSION" && (
                                <div className="statusContact unlockedBy connected">
                                  <b>In discussion</b>
                                  <span className="icon-message"></span>
                                  {infoIsv?.time_message_status &&
                                    infoIsv.status_discussion ===
                                      "IN_DISCUSSION" && (
                                      <>
                                        {moment(
                                          new Date(infoIsv?.time_message_status)
                                        ).format("MMMM DD, yyyy")}
                                      </>
                                    )}
                                </div>
                              )}
                              {infoIsv.status_discussion ===
                                "ARCHIVED_DISCUSSION" && (
                                <div className="statusContact unlockedBy archived">
                                  <b>Archived Discussion</b>
                                  <span className="icon-message"></span>
                                  {infoIsv?.time_message_status &&
                                    infoIsv.status_discussion ===
                                      "ARCHIVED_DISCUSSION" && (
                                      <>
                                        {moment(
                                          new Date(infoIsv?.time_message_status)
                                        ).format("MMMM DD, yyyy")}
                                      </>
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pagginationCategory fixePagination container">
          <div className="pagination">
            <Pagination
              className="pagination"
              currentPage={
                providerUseIsvUnlocked?.allMatchingUnlocked?.currentPage || 1
              }
              totalCount={
                providerUseIsvUnlocked?.allMatchingUnlocked?.totalDataPaginate
              }
              pageSize={10}
              onPageChange={(page: any) =>
                providerUseIsvUnlocked?.loadMoreIsvUnlocked(page)
              }
            />
          </div>
        </div>
      </div>
    </MachingComponent>
  );
}
