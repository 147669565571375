import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import kc, { initOptions } from "./keycloak";

const eventsHandler = (eventNames: any) => {
  switch (eventNames) {
    case "onAuthSuccess":
      console.log(kc.tokenParsed);
      break;
    case "onTokenExpired":
      console.log("token expired");
      kc.updateToken(50)
        ?.then((refreshed) => {
          if (refreshed) {
            // saveToLocalStorage("refreshToken", kc.refreshToken);
          } else {
            console.log("Failed to refresh token");
            // saveToLocalStorage("auth", false);
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
          // saveToLocalStorage("auth", false);
        });
      break;
    default:
      return;
  }
};

const renderApp = function () {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <ReactKeycloakProvider
      authClient={kc}
      initOptions={initOptions}
      onTokens={(token: any) => {}}
      onEvent={(eventNames) => eventsHandler(eventNames)}
    >
      <App />
    </ReactKeycloakProvider>
  );
};

renderApp();

/*
 * If you want to start measuring performance in your app, pass a function
 * To log results (for example: reportWebVitals(console.log))
 * Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */

// Server : 6LffKosaAAAAAOca4THx6w3d6UbDa505oD2g12gr
