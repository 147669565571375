import React from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import UseGetStartedInvestor from "../../../../controller/get-started-ctr/investor/use-get-started-investor";
import showcase from "../../../../resources/images/findInvestor/showcase.png";
import match from "../../../../resources/images/findInvestor/match.png";
import raise from "../../../../resources/images/findInvestor/raise.png";
import attractInvestor from "../../../../resources/images/findInvestor/attract.png";
import hostInvestor from "../../../../resources/images/findInvestor/host.png";
import seedMoney from "../../../../resources/images/findInvestor/Seed-Money.svg";
import serieA from "../../../../resources/images/findInvestor/Serie-A-Money.svg";
import bridgeFunding from "../../../../resources/images/findInvestor/Bridge-Funding.svg";
import mergers from "../../../../resources/images/findInvestor/Mergers-and-Acquisitions.svg";
import stock from "../../../../resources/images/findInvestor/Stock-BuyBack-New.svg";
import ipSale from "../../../../resources/images/findInvestor/IP-Sales.svg";
import learnInv from "../../../../resources/images/findInvestor/learn.png";

export default function GetStartedInvestor() {
  const { goToIsvCreation, goToCommunity, goToFindInvestor } =
    UseGetStartedInvestor();
  return (
    <div>
      <Header />
      <section className="joinHome findInvestor">
        <div className="container mx-auto">
          <h1 className="title">
            Find investors with
            <br />
            software industry
            <br />
            expertise
          </h1>
          <a onClick={() => goToIsvCreation()} className="getStartedLink">
            Get started
            <span className="itsfree">(It's free!)</span>
          </a>
        </div>
      </section>
      <section className="homeDescription">
        <div className="container mx-auto">
          <ul className="showcase">
            <li>
              <img src={showcase} alt="" />
              <p className="description">
                Showcase your company among investors
              </p>
            </li>
            <li>
              <img src={match} alt="" />
              <p className="description">
                Match and proactively pitch relevant investor profiles
              </p>
            </li>
            <li>
              <img src={raise} alt="" />
              <p className="description">Raise Seed and Series A Funding</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="inclinereseller attractInvest">
        <div className="container mx-auto">
          <img src={attractInvestor} alt="" />
          <div className="texte">
            <h1 className="title">Attract investors </h1>
            <p className="my-4">
              Are you open to opportunities even though you may not be actively{" "}
              <br />
              looking for funding? Devinsider allows you to showcase your <br />
              company and receive inbound inquiries from interested investors.
            </p>
            <a
              onClick={() => goToIsvCreation()}
              href="javascript:;"
              className="getStartedLink mt-4"
            >
              Get started
            </a>
          </div>
        </div>
      </section>
      <section className="inclinereseller attractInvest">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">
              Devinsider hosts a curated pool of investors{" "}
            </h1>
            <p className="my-4">
              Match with VCs and other institutional investors based on <br />
              your company page and preferences. Easily compare different
              investor <br />
              profiles, including their specialism and experience. Directly
              contact <br />
              investors that you want to pitch.
            </p>
            <a
              onClick={() => goToIsvCreation()}
              href="javascript:;"
              className="getStartedLink mt-4"
            >
              Get started
            </a>
          </div>
          <img src={hostInvestor} alt="" />
        </div>
      </section>
      <section className="solvebusiness helpInv">
        <div className="container mx-auto">
          <h1 className="title">What Devinsider investors help you with</h1>
          <ul className="listLabelLink">
            <li>
              <img src={seedMoney} />
              <p>Seed Money</p>
            </li>
            <li>
              <img src={serieA} />
              <p>Series A Money </p>
            </li>
            <li>
              <img src={bridgeFunding} />
              <p>Alternative financing</p>
            </li>
            <li>
              <img src={mergers} />
              <p>Mergers & Acquisitions</p>
            </li>
            <li>
              <img src={stock} />
              <p>Stock Buybacks</p>
            </li>
            <li>
              <img src={ipSale} />
              <p>IP and Asset Sale</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="getStartedHome matchInv">
        <div className="container mx-auto">
          <h1 className="title">Match with relevant investors</h1>
          <p>
            Browse through our investor pool to find top-notch investors that
            move your software business forward.
          </p>
          <a
            href="javascript:;"
            className="getStartedLink matchrel"
            style={{ width: "250px" }}
            onClick={() => goToFindInvestor()}
          >
            Find an investor
            <p className="itsfree"></p>
          </a>
        </div>
      </section>
      <section className="inclinereseller learnInv">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">
              Learn from experiences of your peers in the Devinsider community{" "}
            </h1>
            <p className="my-4">
              Learn about the experiences of insiders. Get tips and insights on
              pitching <br />
              strategies, corporate life cycle management, and alternative
              financing.
            </p>
            <a
              onClick={() => goToCommunity()}
              href="javascript:;"
              className="getStartedLink mt-4"
            >
              Join the community
            </a>
          </div>
          <img src={learnInv} alt="" />
        </div>
      </section>
      <Footer />
    </div>
  );
}
