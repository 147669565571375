import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { postData } from '../../../service/api';
import {
  url,
  RESELLER,
  OEM_PROGRAM,
  INVESTOR,
  ISV,
  GUEST,
  CONTRIBUTOR,
} from '../../../service/constant';
import { updateBillingCenterOwner } from '../../../redux/actions/company-page-isv-action/current_isv_action';
import { useHistory, useLocation } from 'react-router-dom';
import { postJsonDataService } from '../../../service/applicatif/back-end-service';
import { toast } from 'react-toastify';
import { updateBillingCenterOwnerProgram } from '../../../redux/actions/oem-program-action';
//IMPORT ACTION GETTING ENTITY OBJECT

export type receiptTransaction = {
  last_receipt: string;
  transaction_history: {
    begin: string;
    end: string;
  };
};
export default function BillingCenterSetting() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  let userObject = useSelector((state: rootState) => state.userReducer);
  let companyPageIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const isIsv = userObject?.roles?.indexOf(ISV) !== -1;
  let oemProgramInStore = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const isOem = userObject?.roles?.indexOf(OEM_PROGRAM) !== -1;

  // let resellerCompanyInStore = useSelector(
  //   (state: rootState) => state.resellerCompanyReducer
  // );
  let resellerCompanyInStore = useSelector(
    (state: rootState) => state.userReducer?.resellerCompanies
  );
  const isReseller = userObject?.roles?.indexOf(RESELLER) !== -1;

  let investorCompanyInStore = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const isGuest = userObject?.roles?.indexOf(GUEST) !== -1;
  const guestCompanyReducer = useSelector(
    (state: rootState) => state.userReducer?.guestCompany
  );

  const isExpert = userObject?.roles?.indexOf(CONTRIBUTOR) !== -1;
  const expertCompanyReducer = useSelector(
    (state: rootState) => state?.userReducer?.expertCompany
  );

  const [planDetailDate, setPlanDetailDate] = useState<receiptTransaction>({
    last_receipt: '',
    transaction_history: {
      begin: '',
      end: '',
    },
  });

  const isInvestor = userObject?.roles?.indexOf(INVESTOR) !== -1;

  //COMPANY PAGE
  const [companyObject, setCompanyObject] = useState<any>();
  const [loadingCompanyPage, setLoadingCompanyPage] = useState<boolean>(false);
  //INVESTOR PAGE
  const [loadingInvestorPage, setLoadingInvestorPage] =
    useState<boolean>(false);
  //MEMBERS ADMIN FOR EACH ENTITY PAGE
  const [membersAdmin, setMembersAdmin] = useState<any>();
  //AFFILIATED LIST FOR OEM / ISV

  //BILLING OWNER
  const [billingOwnerEmail, setBillingOwnerEmail] = useState<string>('');
  const [billingOwnerUsername, setBillingOwnerUsername] = useState<string>('');
  //COMPANY ID
  const [companyId, setCompanyId] = useState<string>('0');
  //COMPANY NAME
  const [companyName, setCompanyName] = useState<string>('');

  const [tabNavigate, setTabNavigate] = useState('plan');
  const [tabNavHistory, setTabNavHistory] = useState('history_list');
  const [showMonthlyBudget, setShowMonthlyBudget] = useState<boolean>(false);

  const [registerBillingOwner, setRegisterBillingOwner] =
    useState<boolean>(false);

  useState<boolean>(false);
  const now = new Date();

  //Payement detail
  const [showBillingCenter, setShowBillingCenter] = useState(
    location?.state?.tabToShow == 'billing' ? true : false
  );

  const billingOwnerRegistration = async (
    companyPageId: number,
    userId: string,
    token: string = userObject.token
  ) => {
    //register billing owner
    let type = 'COMPANY_PAGE';
    if (userObject.roles.indexOf(OEM_PROGRAM) !== -1) type = 'OEM_PROGRAM';
    setRegisterBillingOwner(true);
    const response = await postData(
      url.settings.billingCenter + '/register_billing_owner',
      {
        company_id: companyPageId,
        type: type,
        user_id: userId,
      },
      token
    );
    if (response?.status === 200) {
      //Dev #45972,V2.1 [OEM/Ecosystem user interface] le pop-up "change billing owner" n'est pas du tout comme sur le mockup
      //update state company page
      if (type === 'COMPANY_PAGE')
        dispatch(updateBillingCenterOwner(response.data));
      else dispatch(updateBillingCenterOwnerProgram(response.data));
      toast('The billing owner has been updated successfully!');
    } else {
      toast('An error occure please try again!');
    }
    setRegisterBillingOwner(false);
  };

  useEffect(() => {
    if (isOem) {
      if (oemProgramInStore?.id?.toString() !== '0') {
        //set members admin
        setMembersAdmin(
          oemProgramInStore?.oemProgramMembers?.filter((member: any) => {
            return member.role === 'PROGRAM_OWNER' || member.role === 'ADMIN';
          })
        );
        //set billing owner
        setBillingOwnerEmail(oemProgramInStore?.billing_owner_email);
        setBillingOwnerUsername(oemProgramInStore?.billing_owner_username);
        //set company id
        setCompanyId(oemProgramInStore?.id);
        //set company name
        setCompanyName(oemProgramInStore?.program_name);
      }
    }
    if (isIsv) {
      if (companyPageIsv?.id?.toString() !== '0') {
        //set members admin
        setMembersAdmin(
          companyPageIsv?.isvInCompanyPage?.filter(
            (member: any) => member.role === 'ADMIN'
          )
        );

        //set billing owner
        setBillingOwnerEmail(companyPageIsv?.billing_owner_email);
        setBillingOwnerUsername(companyPageIsv?.billing_owner_username);
        //set company id
        setCompanyId(companyPageIsv?.id);
        //set company name
        setCompanyName(companyPageIsv?.company_name);
      }
    }
    if (isInvestor) {
      if (investorCompanyInStore?.id?.toString() !== '0') {
        //set members admin
        setMembersAdmin(
          investorCompanyInStore?.investorMembers.filter((member: any) => {
            return member.role === 'PROGRAM_OWNER' || member.role === 'ADMIN';
          })
        );
        //set billing owner
        setBillingOwnerEmail('');
        setBillingOwnerUsername('');
        //set company id
        setCompanyId(investorCompanyInStore?.id);
        //set company name
        setCompanyName(investorCompanyInStore?.organization_name);
      }
    }

    if (isReseller) {
      setBillingOwnerEmail('');
      setBillingOwnerUsername('');
      //set company id
      setCompanyId(resellerCompanyInStore?.[0]?.id);
      //set company name
      setCompanyName(resellerCompanyInStore?.[0]?.company_trade_name);
    }
  }, [
    //Dev #45972,V2.1 [OEM/Ecosystem user interface] le pop-up "change billing owner" n'est pas du tout comme sur le mockup
    oemProgramInStore?.id,
    investorCompanyInStore?.id,
    companyPageIsv?.id,
    resellerCompanyInStore?.[0]?.id,
    oemProgramInStore?.billing_owner_email,
    companyPageIsv?.billing_owner_email,
  ]);

  useEffect(() => {
    async function getDatePlanDetail() {
      let params = isIsv
        ? {
            entity_type: 'COMPANY_PAGE',
            entity_id: companyPageIsv.id,
          }
        : isOem
        ? {
            entity_type: 'OEM_PROGRAM',
            entity_id: oemProgramInStore.id,
          }
        : isInvestor
        ? {
            entity_type: 'INVESTOR_PAGE',
            entity_id: investorCompanyInStore.id,
          }
        : isGuest
        ? {
            entity_type: 'GUEST_COMPANY',
            entity_id: guestCompanyReducer.id,
          }
        : isExpert
        ? {
            entity_type: 'EXPERT_COMPANY',
            entity_id: expertCompanyReducer.id,
          }
        : {
            entity_type: 'RESELLER_COMPANY',
            entity_id: resellerCompanyInStore[0]?.id,
          };

      const result = await postJsonDataService(
        url.settings?.get_date_plan,
        params,
        userObject.token
      );
      if (result?.status === 200) {
        setPlanDetailDate(result?.data);
      }
    }
    getDatePlanDetail();
  }, []);

  return {
    role: userObject.roles,
    companyObject,
    loadingCompanyPage,
    loadingInvestorPage,
    oemProgramObject: oemProgramInStore,
    membersAdmin,
    billingOwnerRegistration,
    registerBillingOwner,
    showBillingCenter,
    setShowBillingCenter,
    userObject,
    tabNavigate,
    setTabNavigate,
    tabNavHistory,
    setTabNavHistory,
    companyName,
    billingOwnerEmail,
    billingOwnerUsername,
    companyId,
    showMonthlyBudget,
    setShowMonthlyBudget: () => {
      setShowMonthlyBudget(!showMonthlyBudget);
    },
    planDetailDate,
  };
}
