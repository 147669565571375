import { dataWithToken, postData, postPhoto } from "../api";
import { OEM_PROGRAM, url } from "../constant";
import { OemResourceFile } from "../../controller/oem-ctr/type";

export const createUserAndProgram = async (
  data: any,
  isAffilliated: boolean = false
): Promise<any> => {
  const formData = {
    roles: OEM_PROGRAM,
    ...data,
  };
  let params: dataWithToken = {
    url: isAffilliated ? url?.acount?.create_acount : url.oem.create_user_oem,
    data: formData,
  };

  return await postPhoto(params);
};

export const updatePhotoBackgroundDraft = async (
  file: File,
  id: string,
  token: string
): Promise<any> => {
  let params: dataWithToken = {
    url: url.oem.update_draft + id,
    data: { photoCouvertureDraft: file },
  };

  return await postPhoto(params, token);
};

export const updatePhotoProfilDraft = async (
  file: File,
  id: string,
  token: string
): Promise<any> => {
  let params: dataWithToken = {
    url: url.oem.update_draft + id,
    data: { photoProfilDraft: file },
  };

  return await postPhoto(params, token);
};

/**
 * Handle upload file with for await
 * @param {Array<OemResourceFile>} data
 * @param {number} id
 * @param {string} token
 * @returns {Promise<any[]>}
 */
export const handleResourceUpload = async (
  data: Array<OemResourceFile>,
  id: string,
  token: string
) => {
  const response = [];
  for await (let i of data) {
    if (i.file) {
      let params: dataWithToken = {
        url: url.oem.resource + id,
        data: { file1: i.file },
      };
      const res = await postPhoto(params, token);
      if (res.status === 200) {
        response.push({ index: i.index, url: res.data?.[0].file_location });
      }
    }
  }

  return response;
};

/*************************************
 * update role in oem program
 */
export const updateRoleAffiliated = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.oem.update_role_member,
    data: params,
  };
  let response: any = await postData(tempData.url, tempData?.data, token);
  return response;
};

/*************************************
 * update role in oem program
 */
export const checkUnlockPayment = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.oem.check_unlock_payment,
    data: params,
  };
  let response: any = await postData(tempData.url, tempData?.data, token);
  return response;
};
