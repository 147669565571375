import { constante } from "../../actions/alternative-financing-action"
import { AlternativeObject, IAction } from "../../types/alternative-interface"

export const initialState: AlternativeObject = {
    all_alternatives: {
        data: [],
        pagination: {
            first: 1,
            current: 1,
            last: 1,
            previous: 1,
            next: 1,
            totalItems: 1,
            parPage: 1
        },
    },
    my_alternatives: {
        data: [],
        pagination: {
            first: 1,
            current: 1,
            last: 1,
            previous: 1,
            next: 1,
            totalItems: 1,
            parPage: 1
        },
    },
    alternative: {
        id: 1,
        liked: false,
        company_name: '',
        logo: '',
        title: '',
        content: '',
        display_author_name: true,
        display_company_name: true,
        user: {
            id: '',
            last_name: '',
            first_name: '',
            file_name: '',
            job_title: '',
        },
        alternativeFinancingFiles: [],
        country: {
            id: 1,
            country_name: '',
        },
        entity_type: '',
        entity_id: '',
        favoriteAlternativeFinancing: [],
        software_solution: [],
        targeted_industry: [],
        annual_raise_min: 1,
        annual_raise_max: 1,
        featured_solution: [],
        hasMessage: false,
    },
    favorite_alternative: {
        data: [],
        pagination: {
            first: 1,
            current: 1,
            last: 1,
            previous: 1,
            next: 1,
            totalItems: 1,
            parPage: 1
        },
    },
}

export const alternativeReducer = (
    state = initialState,
    action: IAction
): AlternativeObject => {
    switch (action?.type) {
        case constante.GET_ALL_ALTERNATIVES:
            return {
                ...state,
                all_alternatives: action?.payload,
            }
        case constante.MY_ALTERNATIVES:
            return {
                ...state,
                my_alternatives: action?.payload,
            }
        case constante.ALTERNATIVE:
            return {
                ...state,
                alternative: action?.payload,
            }
        case constante.ADD_FAVORITE_ALTERNATIVE:
            return {
                ...state,
                ...action?.payload,
            }
        case constante.FAVORITE_ALTERNATIVE:
            return {
                ...state,
                favorite_alternative: action?.payload,
            }
        default:
            return state;
    }
};