import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import { getDataService } from "../../../../../service/applicatif/back-end-service";
import { url } from "../../../../../service/constant";
import {mounths} from "../../../../../utils";

export type IPropsMemberBilling = {
  user_uri: string;
  user_first_name: string;
  user_last_name: string;
  user_role: string;
  user_photo: string;
  POST_PRESS_RELEASE: number;
  UNLOCK_INVESTOR: number;
  POST_CLASSIFIED_ADS: number;
};
type IProps = {
  tabShow: string;
};
export default function UseOemMemberPlan(props: IProps) {
  const [userInfo, setUserInfo] = useState<Array<IPropsMemberBilling>>([]);
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemReducer = useSelector((state: rootState) => state.oemProgramReducer);
  const mounthIndex = new Date().getMonth();
  const currentYears = new Date().getFullYear();
  const selectedIndex = mounths?.find(
      (el: { id: number; value: string }) => el?.id === mounthIndex
  );
  const [index, setIndex] = useState<number>(selectedIndex?.id || 0);

  const handleSelect = async (selectedIndex: number, e: any) => {
    const mounthSelected = mounths.find(
        (el: { id: number; value: string }) => el.id === selectedIndex
    );
    setIndex(selectedIndex);
  };

  useEffect(() => {

    const year = new Date().getFullYear();
    const defaultDate = year + "-" + (index + 1) + "-01";
    async function getMember() {
      const data = {
        month: defaultDate,
        oem: oemReducer.program.id,
      };
      const result = await getDataService(
          url.settings.member_company_billing,
          data,
          userReducer.token
      );
      if (result?.status === 200) {
        setUserInfo(result?.data?.member_information?.data);
      }
    }
    if (props.tabShow === "plan") {
      getMember();
    }
  }, [props.tabShow, index]);
  return { userInfo, currentYears, index, handleSelect };
}