/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./template-website.scss";
import React, { ChangeEvent } from "react";
import { Editor } from "react-draft-wysiwyg";
import AddImage from "../../../resources/images/photo-add.jpg";
import { IPropsTemplate } from "./type";
import { fileUrl } from "../../../utils";
import { asyncPostPhoto } from "../../../service/api";
import constant, { url } from "../../../service/constant";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { EditorState } from "draft-js";

export default function TemplateWebsite(props: IPropsTemplate) {
  const customFileToShow = () =>
    props?.dataFile != ""
      ? URL.createObjectURL(props?.dataFile)
      : props?.fileUrl
      ? fileUrl(props?.fileUrl)
      : AddImage;
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const uploadCallback = (file: string | Blob) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      asyncPostPhoto(
        formData,
        url.post.upload_file_url + "community",
        userReducer.token
      )
        .then((response: { data: string }) => {
          resolve({ data: { link: constant.baseUrl + response.data } });
        })
        .catch((error: any) => {
          if (error.response) {
            const errorMessage = `Error ${error.response.status}: ${error.response.data}`;
            reject(errorMessage);
          } else if (error.request) {
            const errorMessage = "No response";
            reject(errorMessage);
          } else {
            const errorMessage = `Request error: ${error.message}`;
            reject(errorMessage);
          }
        });
    });
  };

  return (
    <article className="investorArticle bgGris">
      <div className="mx-auto container cont3">
        {props?.isHaveError &&
          props?.editorState?.getCurrentContent()?.getPlainText("\u0001") ==
            "" && (
            <div className="container-error pl-2 d-inline">
              <span className="error-red"> error </span>
              <span className="txt-error">
                You have some incomplete field, please verify and submit again.
              </span>
            </div>
          )}
        <div className="contentLeftInvestor">
          <div className="editShow">
            <div className="elementText">
              <div className="imageCtt">
                <label htmlFor="">
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/*"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      props.setFileExpertise(event);
                    }}
                  />
                </label>

                <a
                  className="removeImg"
                  onClick={(e) => {
                    e.preventDefault();
                    props?.removeFile();
                  }}
                >
                  <span className="icon-close-pop" />
                </a>
                <img src={customFileToShow()} alt="" className="noBg2" />
              </div>
              <Editor
                editorState={props?.editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                stripPastedStyles={true}
                onEditorStateChange={(editorState) =>
                  props.setEditorState(editorState)
                }
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "link",
                    "image",
                    "remove",
                    "history",
                  ],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                  image: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                    uploadCallback: uploadCallback,
                  },
                }}
              />

              <div className="disposition">
                <p>Choose disposition: </p>
                <a
                  className={`${
                    props?.disposition?.toString() === "upTextPhoto"
                      ? "active"
                      : ""
                  } disposition upTextPhoto`}
                  onClick={(e) => {
                    e.preventDefault();
                    props?.choseDisposition("upTextPhoto");
                  }}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "upPhotoText"
                      ? "active"
                      : ""
                  } disposition upPhotoText `}
                  onClick={(e) => {
                    e.preventDefault();
                    props?.choseDisposition("upPhotoText");
                  }}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "photoText"
                      ? "active"
                      : ""
                  } disposition photoText `}
                  onClick={(e) => {
                    e.preventDefault();
                    props?.choseDisposition("photoText");
                  }}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "textPhoto"
                      ? "active"
                      : ""
                  } disposition textPhoto `}
                  onClick={(e) => {
                    e.preventDefault();
                    props?.choseDisposition("textPhoto");
                  }}
                />
              </div>
              <a
                className="removeElement"
                onClick={(e) => {
                  e.preventDefault();
                  props?.removeElement();
                }}
              >
                Remove this element
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
