/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from "react-bootstrap/Modal";
import { BtnNext } from "../../../../widget/bouton/btn-next";


const PurchaseForm = (props: any) => {
  return (
    <>
      <Modal
        show={props?.showPurchaseForm}
        className="createCompanypopup medium forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Premium subscription</h2>
            <a
              className="close"
              onClick={() => {
                props?.setShowPurchaseForm(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder forBillingCenter contact">
            <div className="row forAds">
              <div className="border radius col-12 d-flex align-items-baseline">
                <h5>
                  <p>Premium subscription</p>
                  <p className="font-medium">Yearly renewable</p>
                </h5>
                <h5 className="color-orange text-align-right pr-0">
                  $1,500.00 USD
                </h5>
              </div>
              <div className="border radius col-12 d-flex">
                <input type="radio" name="choice" value="PAY" />
                  <h5 className="d-flex flex-column">
                    {props?.cardInformation?.brand && (
                      <>{props?.cardInformation?.brand}</>
                    )}{' '}
                    ***********
                    {props?.cardInformation?.last4 && (
                      <>{props?.cardInformation?.last4}</>
                    )}
                    <p className='small cdt-dbt color-light-gray'>
                      Credit/Debit Card
                    </p>
                  </h5>
              </div>
            </div>
            <p className="mt-3">
              {" "}
              By clicking "Confirm", you agree to our <a href="javascript:;">terms & conditions</a>.
              You will be automatically charged for your purchase.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail row">
            <div className="affiliatedPopup contentBorder formForEmail text-left col-lg-6"></div>
            <div className="contentBorder formForEmail text-right col-lg-6">
              <p>&nbsp;</p>
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props.setShowPurchaseForm(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => props.postPremium()}
                disabled={props.settingSetupIntentProcess}
                className={`${
                  props.settingSetupIntentProcess ? "disabled-btn-premium" : ""
                } btn btn-devinsider px-5 save`}
              >
                <BtnNext
                  text={"Confirm"}
                  showLoader={props.settingSetupIntentProcess}
                />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PurchaseForm;
