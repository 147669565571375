import React from "react";
import "./login.scss";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

type IModalLoginProps = {
  show: boolean;
  setShow: (a: boolean) => void;
  urlType?: string;
  getStarted?: () => void;
  goLogin?: () => void;
  started?: boolean;
};

export function LoginModal(props: IModalLoginProps) {
  const history = useHistory();
  const getStarted = props?.getStarted
    ? props?.getStarted
    : () => history.push("/account-choices");
  const goLogin = props?.goLogin
    ? props?.goLogin
    : () => history?.push("/signin");

  const guest_signup = () => {
    history.push(
      props?.urlType === "community" ? "/start-expert" : "/complete-info-guest"
    );
  };

  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom logincomp"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">
            {props?.started
              ? "Get started to Devinsider"
              : "Log in to Devinsider"}
          </h2>
          <a
            href="javascript:;"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props.setShow(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row">
          <div className="col-12">
            <span className="labeled">
              Are you a software publisher (ISV), or an investor?
            </span>
            <button className="orc" onClick={() => goLogin()}>
              Login
            </button>
            <span className="labeled int">OR </span>
            <button className="orc" onClick={() => getStarted()}>
              Create an account
            </button>
          </div>
          <br />
          <div className="col-12">
            <span className="labeled mt-5">
              {props?.urlType === "community"
                ? "Join the Community as an Expert contributor to help software publishers with their business challenges"
                : "  Not a software vendor, reseller, or investor? Create a guest account to post a classified ad."}
            </span>
            <button className="blexp mb-5" onClick={guest_signup}>
              {props?.urlType === "community"
                ? "Apply as an Expert contributor"
                : "  Create guest account"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
