import React, { useState, useEffect } from 'react';
import { exportedCompanySize } from '../../../utils';

export type IPropsSlide = {
  defaultValue: Array<number>;
};
export default function UseSliderCompanySize(props: IPropsSlide) {
  const [companySizeSlide, setCompanySizeSlide] = useState<Array<number>>(
    props?.defaultValue
  );
  const [companySize, setCompanySize] = useState<Array<number>>([0, 10]);
  /**
   * @returns initialise Fundraising selected
   */
  useEffect(() => {
    const [min, max] = props?.defaultValue;
    if (props?.defaultValue.length > 0) {
      setCompanySizeSlide(props?.defaultValue);
      if (max != 0) {
        reverseSelected(min, max);
      }
    }
    return () => {};
  }, [props?.defaultValue]);

  const customSetCompanySize = (min: number, max: number) => {
    const { tempMin, tempMax } = exportedCompanySize(min, max);
    setCompanySizeSlide([tempMin, tempMax]);
    setCompanySize([min, max]);
  };

  const reverseSelected = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
        tempMin = 0;
        break;
      case 5:
        tempMin = 1;
        break;
      case 15:
        tempMin = 2;
        break;
      case 25:
        tempMin = 3;
        break;
      case 50:
        tempMin = 4;
        break;
      case 100:
        tempMin = 5;
        break;
      case 250:
        tempMin = 6;
        break;
      case 500:
        tempMin = 7;
        break;
      case 1000:
        tempMin = 8;
        break;
      case 3000:
        tempMin = 9;
        break;
      case 3200:
        tempMin = 10;
        break;
      default:
        break;
    }
    switch (max) {
      // case 3200:
      //   tempMax = 0;
      //   break;
      case 5:
        tempMax = 1;
        break;
      case 15:
        tempMax = 2;
        break;
      case 25:
        tempMax = 3;
        break;
      case 50:
        tempMax = 4;
        break;
      case 100:
        tempMax = 5;
        break;
      case 250:
        tempMax = 6;
        break;
      case 500:
        tempMax = 7;
        break;
      case 1000:
        tempMax = 8;
        break;
      case 3000:
        tempMax = 9;
        break;
      case 3200:
        tempMax = 10;
        break;
      default:
        break;
    }

    setCompanySize([tempMin, tempMax]);
  };

  return {
    companySizeSlide,
    setCompanySizeSlide,
    customSetCompanySize,
    companySize,
    setCompanySize,
  };
}
