import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../../redux/reducers';
import { concatString, dateDiff, isModerator } from '../../../../../utils';
import { LoadingArticle } from '../../../../widget/loader/index';
import { IPropsLatest, labelsLatest, LatestType } from './type';
import HeaderLatest from './header-latest';
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg"

const LatestDiscussion = (props: IPropsLatest) => {
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const userReducer = useSelector(
    (state: rootState) => state.userReducer
  );

  const isUserPost = (idUser: string) => 
  idUser === userReducer?.id?.toString();

  const pinnedAndLocked = (userId: string, roles: any) => {
    return (isUserPost(userId) || isModerator(roles))
  }

  return (
    <>
      <HeaderLatest
        communityReducer={communityReducer}
        selectedCategorie={props?.selectedCategorie}
        setSelectedCategorie={props?.setSelectedCategorie}
      />
      {props?.loader ? (
        <LoadingArticle />
      ) : (
        <ul className="listBlockright">
          <li className="title">
            <div className="itemBlock">Subject</div>
            <div className="itemBlock">Author</div>
            <div className="itemBlock">Last activity</div>
          </li>

          {props?.latestProvider?.map((el: LatestType, index: number) => (
            <li 
              key={index} 
              className={`${
                el?.post_type?.id == 5 ? "pin featArt" : ""
              }`}
            >
              {/* ADD BANNER LOCKED OR PINNED */}
              {el?.isLocked && pinnedAndLocked(el?.author?.id?.toString(), userReducer.roles) && (
                <img className="pinned-item" src={Locked} alt="" />
              )}
              {el?.isPinned && !el?.isLocked && (
                <img className="pinned-item" src={Pin} alt="" />
              )}
              {el?.isLocked && el?.isPinned && pinnedAndLocked(el?.author?.id?.toString(), userReducer.roles) && (
                <img className="pinned-item" src={Locked} alt="" />
              )}
              {el?.isLocked && el?.isPinned && !pinnedAndLocked(el?.author?.id?.toString(), userReducer.roles) && (
                <img className="pinned-item" src={Pin} alt="" />
              )}

              <div className="itemBlock txtLabel infoFeed">
                <div className="titlePost">
                  {(el?.post_type?.id?.toString() === "2" || el?.post_type?.id?.toString() === "5")&& (
                    <span className="article">{el?.post_type?.type}</span>
                  )}
                  <span
                    className="title"
                    onClick={(e) => {
                      e?.preventDefault();
                      props?.goReply({
                        ...el,
                      });
                    }}
                  >
                    {el.title}
                  </span>
                </div>
                {el?.labels?.map((labels: labelsLatest, index: number) => (
                  <span
                    className="topic"
                    key={index}
                    onClick={() => {
                      props.goToLabelCategory(el, labels.id?.toString());
                    }}
                  >
                    {labels?.title}
                  </span>
                ))}
                {/* {el?.tags?.map((labels: any, index: number) => (
                  <span
                    className="topic"
                    key={index}
                    onClick={() => {
                      props.goToLabelCategory(el, labels.id?.toString());
                    }}
                  >
                    {labels?.name}
                  </span>
                ))} */}
              </div>
              <div className="itemBlock">
                <a
                  href="javascript:;"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    props?.goActivity(el?.author?.id?.toString());
                  }}
                >
                  {concatString(
                    el?.author?.first_name,
                    el?.author?.last_name,
                    ""
                  )}
                </a>
              </div>
              <div className="itemBlock">
                <span className="time">{dateDiff(el?.updated_at)}</span>
                <div className="authorlink">
                  by:{" "}
                  <a
                    href="javascript:;"
                    onClick={(e: any) => {
                      e?.preventDefault();
                      props?.goActivity(el?.last_activity?.id?.toString());
                    }}
                  >
                    {concatString(
                      el?.last_activity?.first_name,
                      el?.last_activity?.last_name,
                      ""
                    )}
                  </a>
                </div>
              </div>
            </li>
          ))}

          {props?.latestProvider?.length > 0 && (
            <li
              className="linkLi"
              onClick={() => props?.showAllListe(props?.selectedCategorie)}
            >
              <a
                href="javascript"
                className="viewAll"
                onClick={(e: any) => e?.preventDefault()}
              >
                View all
              </a>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default LatestDiscussion;
