import { useCallback, useEffect, useState } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getSStorage } from "../../../utils";
import usePostWysiwig from "../../../screen/widget/post-wysiwig/use-post-wysiwig";
import { rootState } from "../../../redux/reducers";
import {
  createPost,
  getFilteredProgram,
  getSuggestionsPrograms,
} from "../../../service/applicatif/community-service";
import { toast } from "react-toastify";
import { postData } from "../../../service/api";
import { url } from "../../../service/constant";

export type IProgramReview = {
  value: string;
  label: string;
};

export default function ProgramReviewCtr() {
  const history = useHistory();

  const userObject = useSelector((state: rootState) => state?.userReducer);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [postType, setPostType] = useState<number>(0);
  const [errorAffiliated, setErrorAffiliated] = useState<boolean>(false);
  const { object, setEditorStatePost, setTxtObject, stateFormWysywig } =
    usePostWysiwig({ initialWysiwygTemplate: "", initialeObjectText: "" });

  const [loader, setLoader] = useState<boolean>(false);
  const [titleError, settitleError] = useState<boolean>(false);
  const [programError, setProgramError] = useState<boolean>(false);
  const [programError2, setProgramError2] = useState<boolean>(false);
  const [programError3, setProgramError3] = useState<boolean>(false);

  const [programs, setPrograms] = useState<IProgramReview[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<IProgramReview>();
  const [emailMe, setEmailMe] = useState<boolean>(false);
  const tempState = sessionStorage.getItem("_previewPost");
  let parsedData: any = tempState ? JSON.parse(tempState) : null;

  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  const [otherValue, setOtherValue] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOtherChange = (value: string) => {
    setOtherValue(value);
    generateSuggestions(value);
  };

  const handleSuggestionClick = (suggestion: IProgramReview) => {
    setOtherValue(suggestion.label);
    setSuggestions([]);
  };

  const generateSuggestions = async (value: string) => {
    setIsLoading(true);
    if (value) {
      const data = await getSuggestionsPrograms(value);
      const transformedData = data.map((item: any) => ({
        label: item.label,
        value: item.value,
      }));
      setSuggestions(transformedData);
    } else {
      setSuggestions([]);
    }
    setIsLoading(false);
  };
  // set the post type
  useEffect(() => {
    setPostType(
      dataCompleted?.category_and_post_type?.post_type?.filter(
        (el: any) => el.type === "Partner Program Review"
      )[0]?.id
    );
  }, []);

  // set programs
  useEffect(() => {
    let data = getFilteredProgram("");
    let temp: IProgramReview[] = [];
    data
      ? data.then((value) => {
          value?.map(
            (el: any) =>
              (temp = [...temp, { label: el?.program_name, value: el?.id }])
          );
          setPrograms(temp);
        })
      : setPrograms([]);
  }, []);

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const cb = async (b: boolean, metadata: any) => {
    setLoader(false);
    if (b) {
      let postId = metadata;
      const response = await postData(
        url?.community?.mention + "s/register",
        {
          postId: postId,
          postType: "FORUM_DISCUSSION",
          userId: registeredMentions,
        },
        userObject.token
      );
      console.log("response", response.data);
      history.push(`/community/reply-post/0/${postId.id}`);
    } else {
      toast("An error occurred");
    }
  };

  useEffect(() => {
    if (titleError) settitleError(false);
    if (programError) setProgramError(false);
    if (programError2) setProgramError2(false);
    if (programError3) setProgramError3(false);
    if (isLoading) setIsLoading(false);
    return () => {};
  }, [object, selectedProgram?.value, otherValue, isLoading]);

  const post = async () => {
    if (object?.length < 1) {
      settitleError(true);
      return;
    }
    if (
      selectedProgram?.value === null ||
      selectedProgram?.value === undefined
    ) {
      setProgramError(true);
      return;
    }

    setLoader(true);
    setSuggestions([]);

    if (selectedProgram?.value === "99" && otherValue !== "") {
      const data = await getSuggestionsPrograms("");
      const transformedData = data.map((item: any) => ({
        label: item.label,
        value: item.value,
      }));

      const filteredData = transformedData.filter(
        (item: any) => item.label === otherValue
      );

      if (filteredData.length < 1) {
        let data = {
          title: object,
          description: JSON.stringify(
            convertToRaw(stateFormWysywig.getCurrentContent())
          ),
          slug: "",
          emailMe: emailMe ? 1 : 0,
          postType: postType,
          oemProgram: "",
          paymentId: "",
          programOther: otherValue,
        };
        createPost(data, cb);
      } else {
        let data = {
          title: object,
          description: JSON.stringify(
            convertToRaw(stateFormWysywig.getCurrentContent())
          ),
          slug: "",
          emailMe: emailMe ? 1 : 0,
          postType: postType,
          oemProgram: filteredData[0]?.value,
          paymentId: "",
        };
        createPost(data, cb);
      }
    } else if (selectedProgram?.value === "99" && otherValue === "") {
      setProgramError3(true);
      setLoader(false);
      return;
    } else {
      let data = {
        title: object,
        description: JSON.stringify(
          convertToRaw(stateFormWysywig.getCurrentContent())
        ),
        slug: "",
        emailMe: emailMe ? 1 : 0,
        postType: postType,
        oemProgram: selectedProgram?.value,
        paymentId: "",
      };

      createPost(data, cb);
    }
  };

  // handle select
  const handleChange = (value: IProgramReview | null) => {
    setSelectedProgram(value ? value : undefined);
  };

  // handle input select
  const handleInputChange = (value: string) => {
    let data = getFilteredProgram(value);
    let temp: IProgramReview[] = [];
    data
      ? data.then((value) => {
          value?.map(
            (el: any) =>
              (temp = [...temp, { label: el?.program_name, value: el?.id }])
          );
          setPrograms(temp);
        })
      : setPrograms([]);
  };

  //emailMe
  const toogleEmailMe = useCallback(() => {
    setEmailMe(!emailMe);
  }, [emailMe]);

  const cancelPost = () => {
    setSelectedProgram(undefined);
    setEditorStatePost(EditorState?.createEmpty());
    setTxtObject("");
  };

  const promiseOptions = async (inputValue: string = "") => {
    let response = await getFilteredProgram(inputValue);
    let tempData = response?.map((el: any) => ({
      label: el?.program_name,
      value: el?.id,
    }));

    return tempData;
  };

  useEffect(() => {
    if (tempState) {
      if (parsedData && parsedData?.content) {
        setEditorStatePost(
          EditorState?.createWithContent(convertFromRaw(parsedData?.content))
        );
      }
      if (parsedData && parsedData.object) setTxtObject(parsedData?.object);
      if (parsedData && parsedData?.category)
        setSelectedProgram(parsedData?.category);
      if (parsedData && parsedData?.otherValue)
        setOtherValue(parsedData?.otherValue);
    }
    sessionStorage.removeItem("_previewPost");
    return () => {};
  }, []);

  const showPreview = () => {
    const data = JSON.stringify({
      category: selectedProgram,
      content: convertToRaw(stateFormWysywig?.getCurrentContent()),
      otherValue: otherValue,
      object: object,
      type: "Partner program",
    });
    sessionStorage.setItem("_previewPost", data);
  };

  return {
    stateFormWysywig,
    setEditorStatePost,
    post,
    loader,
    programs,
    handleInputChange,
    emailMe,
    toogleEmailMe,
    object,
    setTxtObject,
    errorAffiliated,
    selectedProgram,
    handleChange,
    cancelPost,
    setErrorAffiliated,
    titleError,
    programError,
    user: {},
    promiseOptions,
    otherValue,
    suggestions,
    handleOtherChange,
    handleSuggestionClick,
    programError2,
    programError3,
    isLoading,
    mentions,
    showPreview,
    updateMention: async (value: any) => {
      let keyWord =
        value?.blocks[0]?.text.includes("@") &&
        value?.blocks[0]?.text?.split("@");
      let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? "";
      if (keyWordText !== "") {
        let response = await postData(
          url?.community.mention + "/create",
          {
            postType: "",
            keyWord: keyWordText,
          },
          userObject.token
        );
        if (response?.status === 200) {
          setMentions(
            response?.data?.map((rep: any) => {
              return {
                text: rep?.first_name + " " + rep?.last_name,
                value: rep?.first_name + " " + rep?.last_name,
                url: "/community/activity-user/" + rep?.id,
              };
            })
          );
        }
      }

      //register current mention
      let mentionsKeyArray = Object?.keys(value?.entityMap);
      let mentionsData: Array<any> = [];
      mentionsKeyArray.map((key: string) => {
        if (value?.entityMap[key]?.type === "MENTION") {
          mentionsData.push(value?.entityMap[key]?.data?.url?.split("/").pop());
        }
      });
      setRegisteredMentions(mentionsData);
    },
  };
}
