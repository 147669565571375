import Tab from "react-bootstrap/Tab";
import receipts from "../../../../resources/images/receipts.png";
import { LoadingFavoriteProgram } from "../../loader";
import Pagination from "../../../widget/use-pagination/pagination";
import {IReceipt} from "./transaction-type";
import Receipt from "./use-receipt";
import {getDataMicroService} from "../../../../service/applicatif/back-end-service";
import {url} from "../../../../service/constant";
import {toast} from "react-toastify";
import {useState} from "react";
type Iprops = {
  tabType: string;
};
export default function TabPanReceipts(props: Iprops) {
  const providerReceipt = Receipt({ tabType: props?.tabType });

  return (
    <Tab.Pane eventKey="receipts_list">
      <div className="contentBorder radius p-0">
        <div className="border-bottom">
          <div className="row">
            <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
              <div className="title"></div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Date</div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Descriptions</div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
              <div className="title">Status</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Amount</div>
            </div>
          </div>
        </div>
        {!providerReceipt.receipt
          ? LoadingFavoriteProgram()
          : providerReceipt.receipt?.items?.map((el: IReceipt) => (
              <div className="row">
                <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
                  <div className="img_receipts">
                      <a
                          target='_blank'
                          href={el.invoice_url}
                      >
                        <img
                          src={receipts}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">
                    {el.paid_at.split('-')[0]}
                  </div>
                  <div className="transactionHours">
                    <span className="icon-clock"></span>{el.paid_at.split('-')[1]}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                  <div className="">{el.product}</div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
                  <div className="">{el.invoice_status}</div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">{`${el.currency === 'usd' ? '$' : '€'} ${el.amount_due}${el.currency?.toUpperCase()}`}</div>
                </div>
              </div>
            ))}

        <div className="pagginationCategory">
          {providerReceipt.receipt &&
              <Pagination
                  className="pagination"
                  currentPage={providerReceipt.receipt.meta.pages}
                  totalCount={providerReceipt.receipt.meta.total_count}
                  pageSize={5}
                  onPageChange={(page: any) =>
                      providerReceipt?.handleClickReceipt(page)
                  }
              />
          }
        </div>
      </div>
    </Tab.Pane>
  );
}
