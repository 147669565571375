import { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";

type Iprops = {
  modalPostFeaturedArticle: boolean;
  closeModalPostFeaturedArticle: () => void;
  chargingPlanDetails: boolean;
  statePostFeaturedArticle: {
    number_left: number;
    number_max: number;
    next_grant_at: string;
  };
  handleChoise: (p: string) => void;
  choiseValue: string | undefined;
  continuePostFeaturedArticle: () => void;
  loading: boolean;
};
export default function UnlockInvestor(props: Iprops) {
  const history = useHistory();
  const avaibleSubscription = props.statePostFeaturedArticle?.number_left;
  const isActiveBtn =
    avaibleSubscription > 0 && props.choiseValue ? true : false;

  return (
    <Modal
      show={props?.modalPostFeaturedArticle}
      className="createCompanypopup small forStep5custom"
      style={{ height: "100%" }}
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Publish featured article</h2>
          <Link
            to={"#"}
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.closeModalPostFeaturedArticle();
            }}
          >
            <span className="icon-close-pop"></span>
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row forAds">
          <div className="border radius col-12 d-flex">
            <input
              type="radio"
              name="choice"
              value="PAY"
              disabled={props.statePostFeaturedArticle?.number_left < 1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.handleChoise(e.target.value)
              }
            />
            <h5>
              {props.statePostFeaturedArticle?.number_left}{" "}
              {/* out of{' '} {props.statePostFeaturedArticle?.number_max}  */}
              credits available
            </h5>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.statePostFeaturedArticle?.number_left === 0 ? (
          <>
            <div className="contentBorder formForEmail text-center">
              <span style={{ color: "red" }}>
                No more credit available,{" "}
                <u>
                  <Link
                    to={"#"}
                    style={{ color: "blue" }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/billing-center/subscription-plan");
                    }}
                  >
                    upgrade
                  </Link>
                </u>{" "}
                your subscription to get more
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.closeModalPostFeaturedArticle()}
          >
            Cancel
          </button>

          <button
            className="btn btn-devinsider px-5 save"
            disabled={isActiveBtn ? false : true}
            onClick={() => props.continuePostFeaturedArticle()}
          >
            {props.loading ? <Loader /> : "Continue"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
