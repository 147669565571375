import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import {
  getDataService,
  postJsonDataService,
} from '../../../service/applicatif/back-end-service';

import {
  updateBillingCenterCardExpert,
  updateBillingCenterCardGuest,
  updateBillingCenterCardIsv,
} from '../../../redux/actions/acounts-action';
import { updatePaymentMethod as updateBillingCenterCardInvestor } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { updateBillingCenterCardResellerCompany } from '../../../redux/actions/company-page-reseller';
import { updateBillingCardOemInformation } from '../../../redux/actions/oem-program-action';
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from '../../../service/constant';

export interface ICardInformation {
  brand: string;
  exp_month: string;
  exp_year: string;
  last4: string;
}

export default function UseGlogalBilling() {
  const state = useSelector((state: rootState) => state);
  const [cardInformation, setCardInformation] = useState<ICardInformation>({
    brand: '',
    exp_month: '',
    exp_year: '',
    last4: '',
  });

  const [loadingCard, setLoadingCard] = useState<boolean>(false);
  const undefined = 'undefined';

  const isIsv = state.userReducer?.roles?.indexOf(ISV) !== -1;
  const isReseller = state.userReducer?.roles?.indexOf(RESELLER) !== -1;
  const isOem = state.userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const isInvestor = state.userReducer?.roles?.indexOf(INVESTOR) !== -1;
  const isGuest = state.userReducer?.roles?.indexOf(GUEST) !== -1;
  const isExpert = state.userReducer?.roles?.indexOf(CONTRIBUTOR) !== -1;

  const dispatch = useDispatch();

  const getPaymentMethod = () => {
    const role = state.userReducer.roles;
    // if (role.indexOf(ISV)!==-1){
    //   return {
    //     payment_method : state.companyPageReducer.payment_method !== "" ? state.companyPageReducer.payment_method : undefined
    //   };
    // }
    if (role.indexOf(ISV) !== -1) {
      return {
        payment_method:
          state.userReducer.payment_method !== ''
            ? state.userReducer.payment_method
            : undefined,
      };
    }
    if (role.indexOf(OEM_PROGRAM) !== -1) {
      return {
        payment_method:
          state.oemProgramReducer.program.payment_method !== ''
            ? state.oemProgramReducer.program.payment_method
            : undefined,
      };
    }
    if (role.indexOf(INVESTOR) !== -1) {
      return {
        payment_method:
          state.companyInvestorPageReducer.payment_method !== ''
            ? state.companyInvestorPageReducer.payment_method
            : undefined,
      };
    }
    if (role.indexOf(RESELLER) !== -1) {
      return {
        payment_method:
          state.resellerCompanyReducer.payment_method !== ''
            ? state.resellerCompanyReducer.payment_method
            : undefined,
      };
    }
    if (role.indexOf(GUEST) !== -1) {
      return {
        payment_method:
          state.userReducer?.guestCompany?.payment_method !== ''
            ? state.userReducer?.guestCompany?.payment_method
            : undefined,
      };
    }
    if (role.indexOf(CONTRIBUTOR) !== -1) {
      return {
        payment_method:
          state.userReducer?.expertCompany?.payment_method !== ''
            ? state.userReducer?.expertCompany?.payment_method
            : undefined,
      };
    }
    return {
      payment_method: '',
    };
  };

  // const updatePaymentMethodReducer = async () => {
  //   (async () => {
  //     let params = {};
  //     if (isOem) params = { program: state?.oemProgramReducer?.program?.id };
  //     let response = null;
  //     if (state.userReducer.token) {
  //       response = await getPaymentMethodByUser(params);
  //       if (response?.status === 200) {
  //         if (isIsv) dispatch(updateBillingCenterCardIsv(response?.data));
  //         if (isOem) dispatch(updateBillingCardOemInformation(response?.data));
  //         if (isInvestor)
  //           dispatch(updateBillingCenterCardInvestor(response?.data));
  //         if (isReseller)
  //           dispatch(updateBillingCenterCardResellerCompany(response?.data));
  //         if (isGuest) dispatch(updateBillingCenterCardGuest(response?.data));
  //         if (isExpert) dispatch(updateBillingCenterCardExpert(response?.data));
  //       }
  //     }
  //   })();
  // };
  const getPaymentMethodByUser = async (params: any) => {
    if (!isExpert) {
      const response: any = await postJsonDataService(
        url.settings.billingCenter + '/get/payment_method',
        params,
        state.userReducer.token
      );
      return response;
    }
  };

  const getCardInformationByPaymentMethod = async (paymentMethod: string) => {
    if (paymentMethod && state.userReducer.token) {
      const response: any = await getDataService(
        url.settings.billingCenter +
          '/payment/get_payment_method_information/' +
          paymentMethod,
        {},
        state.userReducer.token
      );
      return response;
    }
  };

  // const getCardInformationFromUser = async () => {
  //   let params = {};
  //   const role = state.userReducer.roles;
  //   if (role.indexOf(OEM_PROGRAM) !== -1) {
  //     params = { program: state.oemProgramReducer.program?.id };
  //   }
  //   if (state.userReducer.token) {
  //     const response: any = await postJsonDataService(
  //       url.settings.billingCenter +
  //         '/payment/get_payment_method_information_from_user',
  //       params,
  //       state.userReducer.token
  //     );
  //
  //     if (response?.status == 200) {
  //       return response?.data;
  //     } else {
  //       return cardInformation;
  //     }
  //   }
  // };

  return {
    cardInformation,
    loadingCard,
    getCardInformationByPaymentMethod,
    currentPaymentMethod: () => getPaymentMethod(),
    getPaymentMethodByUser,
    // getCardInformationFromUser,
    // updatePaymentMethodReducer,
  };
}
