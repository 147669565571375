import { combineReducers } from "redux";
import { userReducer } from "./reducers/acounts-reducer/index";
import { companyPageReducer } from "./reducers/company-page-isv-reducer/current_investor_reducer";
import { otherUserCompanyIsvReducer } from "./reducers/company-page-isv-reducer/other_user_investor_reducer";
import { companyInvestorPageReducer } from "./reducers/company-page-investor-reducer/my-investor-page-reducer";
import { settingsReducer } from "./reducers/settings-reducer/index";
import { UserObject } from "../redux/types/acount-interface";
import { ISettings } from "../redux/types/settings-interface";
import { IcompanyPage } from "../interface/company-page-isv";
import { ICompanyPageInvestor } from "../interface/compnay-page-investor";
import { IcompanyPageReseller } from "../interface/company-reseller";
import oemProgramReducer from "./reducers/oem-program-reducer";
import { OemProgramReducer } from "../controller/oem-ctr/type";
import resellerCompanyReducer from "./reducers/company-page-reseller-reducer/index";
import { communityReducer } from "./reducers/community-reducer/index";
import { ICommunity } from "./types/community-interface";
import { findProgramReducer } from "./reducers/find-program-reducer";
import { IFindProgram } from "./types/find-program-interface";
import isvReducer from "./reducers/isv-reducer";
import { IsvReducer } from "./types/isv-interface";
import { IClassifiedAds } from "./types/classified-ads-interface";
import { classifiedAdsReducer } from "./reducers/classified-ads-reducer";
import { eventReducer } from "./reducers/event-reducer";
import { alternativeReducer } from "./reducers/alternative-reducer/alternative_reducer";
import { EventObject } from "./types/event-interface";
import { findInvestorReducer } from "./reducers/find-investor-reducer";
import { IFindInvestor } from "./types/find-investor-interface";
import { otherCompanyInvestorPageReducer } from "./reducers/company-page-investor-reducer/other-investor-page-reducer";
import notificationReducer from "./reducers/notification-reducer";
import { INotification } from "./types/notification-interface";
import {
  billingReducer,
  IBilling,
} from "./reducers/billing-reducer/billing-reducer";
import { AlternativeObject } from "./types/alternative-interface";

const appReducer = combineReducers({
  log: (_, action) => {
    return {};
  },
  userReducer,
  settingsReducer,
  companyPageReducer,
  companyInvestorPageReducer,
  oemProgramReducer,
  resellerCompanyReducer,
  communityReducer,
  findProgramReducer,
  isvReducer,
  classifiedAdsReducer,
  eventReducer,
  findInvestorReducer,
  otherUserCompanyIsvReducer,
  otherCompanyInvestorPageReducer,
  notificationReducer,
  billingReducer,
  alternativeReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = {};
  }
  return appReducer(state, action);
};

export type rootState = {
  userReducer: UserObject;
  settingsReducer: ISettings;
  companyPageReducer: IcompanyPage;
  otherUserCompanyIsvReducer: IcompanyPage;
  companyInvestorPageReducer: ICompanyPageInvestor;
  otherCompanyInvestorPageReducer: ICompanyPageInvestor;
  oemProgramReducer: OemProgramReducer;
  resellerCompanyReducer: IcompanyPageReseller;
  communityReducer: ICommunity;
  findProgramReducer: IFindProgram;
  isvReducer: IsvReducer;
  classifiedAdsReducer: IClassifiedAds;
  eventReducer: EventObject;
  findInvestorReducer: IFindInvestor;
  notificationReducer: INotification;
  billingReducer: IBilling;
  alternativeReducer: AlternativeObject;
};

export default rootReducer;
