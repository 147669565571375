import React, { useState } from 'react';
import Header from '../../../widget/index';
import '../mergers-acquisitions.scss';
import UseDetails from './useDetails';
import { LoadingAllPage } from '../../../widget/loader';
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  isModerator,
  paginate,
} from '../../../../utils';
import SeeBtn from '../SeeBtn';
import { LoginModal } from '../../../widget/login/login';

export default function ads() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [show, setShow] = useState(false);
  const provider = UseDetails();

  const dataSolution = [
    ...(provider?.announcing?.softwareSolution || []),
    ...(provider?.announcing?.featuredSolution || []),
  ];

  return (
    <>
      <Header />

      {provider.showLoading && <LoadingAllPage />}

      <section className='classified mergers details'>
        <div className='container mx-auto'>
          <div className='leftSection'>
            <div className='block'>
              <div className='leftItem itemAuthor'>
                <div className='imageAffiliatedcompany empty'>
                  <img
                    src={fileUrl(provider.announcing?.author?.file_name)}
                    alt=''
                  />
                </div>
                <div className=''>
                  <div className='post'>Author:</div>
                  <h3 className='title'>
                    {provider.announcing?.author?.first_name}
                  </h3>
                  <h3 className='title'>
                    {provider.announcing?.author?.last_name}
                  </h3>
                </div>
              </div>
            </div>
            <div className='block infoCompanyAds'>
              <div className='imgclassified empty'>
                <img src={fileUrl(provider.announcing?.logo)} alt='' />
              </div>
              <div className='infoAds'>
                Company:
                <h3 className='title'>{provider.announcing?.companyName}</h3>
                {/*<div className="role">ISV</div>*/}
                <div className='contry'>
                  {provider.announcing?.country?.country_name}
                </div>
              </div>
            </div>
            <div className='block solInd'>
              <h3 className='title'>Solution</h3>
              {paginate(dataSolution, provider.limitSolution)?.map(
                (el: any, index: number) => (
                  <div key={index}>{el?.name}</div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeSolution(p)}
                dataSource={dataSolution}
                limit={provider.limitSolution}
              />
            </div>
            <div className='block solInd'>
              <h3 className='title'>Industry</h3>
              {paginate(
                provider.announcing?.targetedIndustry,
                provider.limitIndustry
              )?.map((el: any, index: number) => (
                <div key={index}>{el?.name}</div>
              ))}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeIndustry(p)}
                dataSource={provider.announcing?.targetedIndustry || []}
                limit={provider.limitIndustry}
              />
            </div>
          </div>
          <div className='rightSection ads'>
            <h3>{provider.announcing?.title}</h3>
            {/* <span className='mini-title'>Alternative Financing</span> */}
            <ul className={`btn `}>
              {!isModerator(provider.userReducer?.roles) && (
                <>
                  <li
                    className={`${
                      provider.isDisabled(provider?.announcing?.hasMessage)
                        ? 'disabled-ma-btn'
                        : ''
                    }`}
                    style={{
                      display:
                        provider?.announcing?.hasMessage === false &&
                        provider.isMyPost
                          ? 'none'
                          : 'block',
                    }}
                    onClick={() =>
                      provider.sendMessage(provider?.announcing?.hasMessage)
                    }
                  >
                    {provider.isMyPost ? 'View all Messages' : 'Send a Message'}
                  </li>
                </>
              )}

              <li
                className='pl-2 pr-2'
                onClick={() => provider.handleAddFavorites()}
              >
                <span
                  className={
                    provider.announcing?.liked
                      ? 'icon-fav-full'
                      : 'icon-fav-line'
                  }
                />
              </li>
              {provider.isMyPost && (
                <li className='pl-1 pr-1'>
                  <div onClick={() => setShow(!show)}>
                    <span className='icon-inprogress'></span>
                  </div>
                  {show && (
                <ul className='editListing'>
                  <li onClick={() => provider.editPost()}>Edit listing</li>
                  <li onClick={() => provider.handleDeletePost()}>Delete</li>
                </ul>
              )}
                </li>
              )}
            </ul>
            <div
              className='detailsAds'
              dangerouslySetInnerHTML={createMarkup(
                convertCommentFromJSONToHTML(
                  getDescriptionEditorState(provider.announcing?.content)
                )
              )}
            />
            {/* <div>{provider.announcing?.content}</div> */}
          </div>
        </div>
        <LoginModal
          urlType={'ma'}
          show={provider?.showLogin}
          setShow={provider?.setShowLogin}
        />
      </section>
    </>
  );
}
