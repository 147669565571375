/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import UseCompleteCompanyDescriptionReseller from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-description-reseller";

export default function CompleteCompanyDescriptionReseller() {
  const { navigateToKeyCompetitive, description, setDescription, goBack } =
    UseCompleteCompanyDescriptionReseller();
  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
          </div>
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Company information</h1>
        </div>
        <div className="contentBorder border-top row pt-3 pb-4">
          <div className="form-group col-xl-12 p-0 m-0 descCompany">
            <label htmlFor="">Outline a brief overview of your company.</label>
            <textarea
              value={description}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(event.target.value)
              }
              maxLength={2000}
            />
            <span className="caractercount">
              {0 + description.length || 0} / 2000
            </span>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToKeyCompetitive()}
          >
            Next
          </button>
          <a
            className="skip"
            onClick={(e) => {
              e.preventDefault();
              navigateToKeyCompetitive(true);
            }}
          >
            Skip
          </a>
        </div>
      </div>
    </div>
  );
}
