import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardRegistrationInput,
  CardRegistrationInputProps,
} from "./card-registration-input";
import { stripePrivateKey } from "../../../../../service/constant";

const stripePromise = loadStripe(stripePrivateKey);

export const CardRegistrationInputWrapper: React.FC<
  CardRegistrationInputProps
> = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CardRegistrationInput {...props} />
    </Elements>
  );
};
