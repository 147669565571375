import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import UserGlobalSettingController, { ICardInformation } from '../../../../../controller/settings-ctr/billing-center/use-global-billing';
import { updateOemPageSettings } from '../../../../../redux/actions/oem-program-action';
import { rootState } from '../../../../../redux/reducers';

export default function UseModalMounthlyBudget() {
  const dispatch = useDispatch();
  const history = useHistory();
  const globalSettingProvider = UserGlobalSettingController();
  const [loadingCardInformation, setLoadingCardInformation] =
    useState<boolean>(false);
  const [cardInformation, setCardInformation] = useState<ICardInformation>({
    brand: '',
    exp_month: '',
    exp_year: '',
    last4: '',
  });
  const userObject = useSelector((state: rootState) => state.userReducer);
  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const subscriptionPlan = oemProgramObject?.oem_subscription?.name;
  const subscriptionBeginAt = moment(oemProgramObject?.subscription_begin_at).format("LL");
  const subscriptionEndAt = moment(oemProgramObject?.subscription_end_at).format("LL");
  const numberOfMonthlyBudget =
    oemProgramObject?.oemProgramBillingDetails?.max_unlock_number;
  const isvUnlockAmount = oemProgramObject?.oem_subscription?.isv_unlock_amount;

  const [isvUnlockAmout, setIsvUnlockAmout] = useState<number>(
    isvUnlockAmount || 0
  );
  const [numberIsvToUnlock, setNumberIsvToUnlock] = useState<number>(
    numberOfMonthlyBudget || 0
  );
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [registeringNumberOfUnlock, setRegisteringNumberOfUnlock] =
    useState<boolean>(false);
  const [showMonthlyBudget, setShowMonthlyBudget] = useState<boolean>(false);

  useEffect(() => {
    //caclulate number of monthly budget
    isvUnlockAmout &&
      numberIsvToUnlock &&
      setTotalBudget(Number((isvUnlockAmout * numberIsvToUnlock).toFixed(2)));
  }, [numberIsvToUnlock]);

  // useEffect(() => {
  //   setLoadingCardInformation(true);
  //   (async () => {
  //     setCardInformation(await globalSettingProvider.getCardInformationFromUser());
  //
  //     setLoadingCardInformation(false);
  //   })();
  // }, [showMonthlyBudget]);

  const handleRegistration = () => {
    setRegisteringNumberOfUnlock(true);
    dispatch(
      updateOemPageSettings(
        oemProgramObject.id,
        { numberOfMonthlyBudget: numberIsvToUnlock },
        () => {
          setRegisteringNumberOfUnlock(false);
        }
      )
    );
    setShowMonthlyBudget(false);
  };

  const closeMonthlyBudget = () => {
    setNumberIsvToUnlock(numberOfMonthlyBudget);
    setShowMonthlyBudget(false);
  };

  const showModalMounthly = () => {
    registeringNumberOfUnlock && setRegisteringNumberOfUnlock(false);
    setShowMonthlyBudget(true);
  };

  const upGradePlan = () => {
    history.push('/billing-center/subscription-plan');
  };
  return {
    loadingCardInformation,
    cardInformation,
    handleRegistration,
    showMonthlyBudget,
    closeMonthlyBudget,
    upGradePlan,
    setNumberIsvToUnlock,
    numberIsvToUnlock,
    registeringNumberOfUnlock,
    setShowMonthlyBudget,
    subscriptionPlan,
    subscriptionBeginAt,
    subscriptionEndAt,
    totalBudget,
    showModalMounthly,
    numberOfMonthlyBudget,
    oemProgramObject,
    hasCardRegistered: oemProgramObject.payment_method,
  };
}
