import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateInfoUserAction } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";
import { contact } from "../../../../../../../../utils/country_phone";

export default function UseEditContactExpert() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [stateLinkDean, setStateLinkDean] = useState<boolean>(false);
  const [statePhone, setstatePhone] = useState<boolean>(true);
  const [codeFlags, setcodeFlags] = useState(
    userState?.phone_number_code || "US"
  );
  const [phone, setPhone] = useState<string>(userState.phone);
  const [linkedin, setLinkedin] = useState<string>(userState.linkedin);
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [contactShow, setContactShow] = useState<boolean>(false);
  const dispatch = useDispatch();

  //Valid phone number if exist
  const validPhoneNumber = (): boolean => {
    if (phone?.trim()) {
      const isValidPhone = isValidPhoneNumber(
        contact?.find((el: any) => el.code == codeFlags)?.dial_code +
          phone?.trim()
      );
      if (isValidPhone) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const callBack = (data: any) => {
    setIsLoaderShow(false);
    setContactShow(false);
    if (data) {
      toast("Successfully updated");
    }
  };

  const cancelChange = () => {
    setIsLoaderShow(false);
  };

  const validAfterUpdate = () => {
    if (!validPhoneNumber()) {
      setstatePhone(true);
    }
    if (validPhoneNumber()) {
      setIsLoaderShow(true);
      let data = {
        linkedin: linkedin?.trim(),
        phone: phone?.trim(),
        phoneNumberCode: codeFlags,
        fonction: "updateUser"
      };
      dispatch(updateInfoUserAction(data, callBack));
    }
  };

  useEffect(() => {
    if (statePhone) setstatePhone(false);
  }, [phone]);

  useEffect(() => {
    if (contactShow) {
      setLinkedin(userState.linkedin);
      setPhone(userState.phone);
      setcodeFlags(userState?.phone_number_code || "US");
      statePhone && setstatePhone(false);
      stateLinkDean && setStateLinkDean(false);
    }
  }, [contactShow]);

  return {
    stateLinkDean,
    setStateLinkDean,
    statePhone,
    setstatePhone,
    codeFlags,
    setcodeFlags,
    isLoaderShow,
    cancelChange,
    validAfterUpdate,
    phone,
    setPhone,
    linkedin,
    setLinkedin,
    contactShow,
    setContactShow,
    email: userState.email,
  };
}
