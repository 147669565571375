import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { rootState } from "../../../../../redux/reducers";
import {
  bolderedText,
  checkIfExist,
  refaCtoNullKey,
} from "../../../../../utils";
import ReactHtmlParser from "react-html-parser";
import { HIDDEN_INFORMATION } from "../../../../../service/constant";

export type IProps = {
  showEditInfo: () => void;
};
export default function Index(props: IProps) {
  const params: any = useParams();

  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  const isOtherUser = params?.id ? true : false;

  const isLocked =
    companyPageObject?.company_legal_name &&
    companyPageObject?.company_legal_name.includes(HIDDEN_INFORMATION);

  return (
    <div className="containerLeftItem py-3">
      <Link
        className="linkEditLeftCompany"
        onClick={(e) => {
          e.preventDefault();
          props?.showEditInfo();
        }}
        to="#"
      >
        Edit
        <span className="icon-edit-full"></span>
      </Link>
      <ul className="listnamelabel">
        {isOtherUser && checkIfExist(companyPageObject?.company_legal_name) && (
          <li>
            <div className="label">Legal name</div>
            <div className="name">
              {isLocked
                ? ReactHtmlParser(
                    bolderedText(companyPageObject?.company_legal_name)
                  )
                : companyPageObject?.company_legal_name}
            </div>
          </li>
        )}
        {!isOtherUser && (
          <li>
            <div className="label">Legal name</div>
            <div className="name">{companyPageObject?.company_legal_name}</div>
          </li>
        )}

        {isOtherUser && checkIfExist(companyPageObject?.website) && (
          <li>
            <div className="label">Website</div>
            <div className="name">
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {isLocked
                  ? ReactHtmlParser(
                      bolderedText(
                        refaCtoNullKey(companyPageObject?.website, "-")
                      )
                    )
                  : refaCtoNullKey(companyPageObject?.website, "-")}
              </Link>
            </div>
          </li>
        )}
        {!isOtherUser && (
          <li>
            <div className="label">Website</div>
            <div className="name">
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {refaCtoNullKey(companyPageObject?.website, "-")}
              </Link>
            </div>
          </li>
        )}

        {isOtherUser &&
          checkIfExist(companyPageObject?.country?.country_name) && (
            <li>
              <div className="label">Headquarters location</div>

              <div className="name">
                {companyPageObject?.country?.country_name}
                {companyPageObject?.city && ", "}
                {refaCtoNullKey(companyPageObject?.city, "")}
              </div>
            </li>
          )}
        {!isOtherUser && (
          <li>
            <div className="label">Headquarters location</div>

            <div className="name">
              {companyPageObject?.country?.country_name}
              {companyPageObject?.city && ", "}
              {refaCtoNullKey(companyPageObject?.city, "")}
            </div>
          </li>
        )}

        {isOtherUser && checkIfExist(companyPageObject?.company_size) && (
          <li>
            <div className="label">Company size</div>
            {companyPageObject?.company_size && (
              <div className="name">{companyPageObject?.company_size}</div>
            )}
          </li>
        )}

        {!isOtherUser && (
          <li>
            <div className="label">Company size</div>
            {companyPageObject?.company_size && (
              <div className="name">{companyPageObject?.company_size}</div>
            )}
          </li>
        )}

        {isOtherUser && checkIfExist(companyPageObject?.founding_date) && (
          <li>
            <div className="label">Founded</div>
            <div className="name">
              {refaCtoNullKey(companyPageObject?.founding_date)}
            </div>
          </li>
        )}

        {!isOtherUser && (
          <li>
            <div className="label">Founded</div>
            <div className="name">
              {refaCtoNullKey(companyPageObject?.founding_date)}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
