import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BtnNext } from "../../widget/bouton/btn-next";
import { PaymentInfoWrapper } from "./payment-info/payment-info-wrapper";
import { PaymentStatus } from "./payment-status/payment-status";
import { ProductInfo } from "./product-info/product-info";
import usePaymentHook from "./use-payment-hook";

type Props = {
  product?: {
    lookupKey: string;
    name: string;
  }; // detail produit
  title: string; // titre popup
  free?: number; // 0 ou 1 à n | nombre free unlock/post | facultatif: 0
  successCallback?: (
    // le callback appelé après confirm
    isFree: boolean, // true si c'était free
    paymentMethodId: string | null, //le pm_id
    setLoading: (l: boolean) => void, //set loader button confirm
    savePaymentMethod?: boolean, // true si save a été , sinon false
    setShowPaymentStatus?: (
      // à appeler pour afficher les popus payment succes et error
      show: boolean, //true pour afficher,
      data: {
        status: "success" | "error"; //status renvoyé par le backend
        invoiceNumber?: string; // numero facture renvoyé par le backend
        invoiceUrl?: string; // url facture renvoyé par le backend
      }
    ) => void
  ) => void;
  show: boolean; // afficher le popup paiement
  setShowPaymentModal: (show: boolean) => void; // fermer le popup
  isPriceInfo?: boolean; // true pour juste afficher le prix et pour fermer le popup ensuite
  priceInfoCallback?: () => void; // action à faire apres affichage du prix si isPriceInfo = true
  handleStatusOk?: () => void; // action à faire au click du OK/Continuer sur le popup success paiement (ex: redirection vers la page unlocké)
  monthlyBudgetReached?: boolean; //si oem, valeur du check si l'user a atteint ou non son monthly budget
  unlockAllowed?: boolean; //si le paiement 300$ a été échoué, la valeur est à true
  showErrorOemEventActivate?: boolean;
  cancelPostCallBack?: () => void;
};

export const PaymentPopup: React.FC<Props> = ({
  show,
  title,
  free,
  setShowPaymentModal,
  product,
  successCallback,
  isPriceInfo,
  priceInfoCallback,
  handleStatusOk,
  monthlyBudgetReached,
  showErrorOemEventActivate,
  cancelPostCallBack,
}) => {
  const {
    prices,
    paymentMethod,
    currency,
    setCurrency,
    getProductPriceWithTax,
    getCurrencySymbol,
    productPrice,
    setProductPrice,
    userEmail,
    step,
    setStep,
    isOem,
    modalSize,
    setModalSize,
    unlockAllowed,
    buttonLoader,
    setButtonLoader,
  } = usePaymentHook(show, product, isPriceInfo, free, monthlyBudgetReached);

  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState<"success" | "error">("success");
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [invoicePdf, setInvoicePdf] = useState<string>("");

  const handleStatus = (
    show: boolean,
    data: {
      status: "success" | "error";
      invoiceNumber?: string;
      invoiceUrl?: string;
    }
  ) => {
    setStatus(data.status);
    setInvoiceNumber(data.invoiceNumber || "");
    setInvoicePdf(data.invoiceUrl || "");
    if (show) setShowPaymentModal(false);
    setShowStatus(show);
  };

  const handleStep = () => {
    if (step === "product-info") {
      if (!isPriceInfo) {
        if (!paymentMethod) {
          setStep("payment-info");
          setModalSize("large");
        } else {
          setButtonLoader(true);
          successCallback &&
            successCallback(false, null, setButtonLoader, true, handleStatus);
        }
      } else {
        setShowPaymentModal(false);
      }
    }
  };

  const handleSuccessStatus = () => {
    setShowStatus(false);
    handleStatusOk && handleStatusOk();
  };

  const handleFreeConfirm = () => {
    setButtonLoader(true);
    successCallback && successCallback(true, "FREE", setButtonLoader);
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          className={`createCompanypopup ${modalSize} forStep5custom`}
          style={{ height: "100%" }}
        >
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">{title}</h2>
              <a
                href="javascript:;"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPaymentModal(false);
                }}
              >
                <span className="icon-close-pop"></span>
              </a>
            </div>
          </Modal.Header>
          <Modal.Body className="">
            {step === "product-info" && (
              <ProductInfo
                cancel={() => setShowPaymentModal(false)}
                prices={prices}
                paymentMethod={paymentMethod}
                setCurrency={setCurrency}
                currency={currency}
                getCurrencySymbol={getCurrencySymbol}
                productPrice={productPrice}
                setProductPrice={setProductPrice}
                free={free}
                productName={product?.name}
                isPriceInfo={isPriceInfo}
                isOem={isOem}
                monthlyBudgetReached={monthlyBudgetReached}
                unlockAllowed={unlockAllowed}
                showErrorOemEventActivate={showErrorOemEventActivate || false}
              />
            )}
            {step === "payment-info" && (
              <PaymentInfoWrapper
                product={product}
                currency={currency}
                setCurrency={setCurrency}
                getProductPriceWithTax={getProductPriceWithTax}
                getCurrencySymbol={getCurrencySymbol}
                productPrice={productPrice}
                setProductPrice={setProductPrice}
                userEmail={userEmail}
                successCallback={successCallback}
                handleStatus={handleStatus}
              />
            )}
          </Modal.Body>
          {!!free && !isPriceInfo && (
            <Modal.Footer>
              <div className="contentBorder formForEmail text-right forPitch">
                <button
                  className="btn btn-devinsider cancel"
                  onClick={() => {
                    setShowPaymentModal(false);
                  }}
                  disabled={buttonLoader}
                >
                  Cancel
                </button>
                <button
                  onClick={handleFreeConfirm}
                  type="submit"
                  className="btn btn-devinsider px-5 save"
                  disabled={buttonLoader}
                >
                  <BtnNext text={"Confirm"} showLoader={buttonLoader} />
                </button>
              </div>
            </Modal.Footer>
          )}
          {(((!free || isPriceInfo) && prices) || (!!free && isPriceInfo)) &&
            step !== "payment-info" && (
              <Modal.Footer>
                <div className="contentBorder formForEmail text-right forPitch">
                  <button
                    className="btn btn-devinsider cancel"
                    onClick={() => {
                      console.log("cancelPostCallBack");
                      if (cancelPostCallBack) {
                        cancelPostCallBack();
                      }
                      setShowPaymentModal(false);
                    }}
                    disabled={buttonLoader}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={
                      !isPriceInfo
                        ? handleStep
                        : priceInfoCallback ||
                          (() => setShowPaymentModal(false))
                    }
                    type="submit"
                    className="btn btn-devinsider px-5 save"
                    disabled={
                      (isOem &&
                        ((!free && !paymentMethod) ||
                          monthlyBudgetReached ||
                          !unlockAllowed ||
                          showErrorOemEventActivate)) ||
                      (!isPriceInfo && buttonLoader)
                    }
                  >
                    <BtnNext
                      text={
                        (paymentMethod && !isPriceInfo) || isPriceInfo
                          ? "Continue"
                          : !isOem
                          ? "Add new card"
                          : "Confirm"
                      }
                      showLoader={buttonLoader}
                    />
                  </button>
                </div>
              </Modal.Footer>
            )}
        </Modal>
      </div>
      <PaymentStatus
        title={title}
        invoiceNumber={invoiceNumber}
        invoiceUrl={invoicePdf}
        show={showStatus}
        status={status}
        handleClose={() => setShowStatus(false)}
        handleOk={handleSuccessStatus}
        paymentMethod={paymentMethod}
        tryAgain={() => {
          setModalSize("large");
          setStep("payment-info");
          setShowStatus(false);
          setShowPaymentModal(true);
        }}
      />
    </>
  );
};
