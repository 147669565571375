import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as accountAction from "../../../redux/actions/acounts-action/index";
import { Label } from "../../../interface/interface-acount/index";
import useEventListener from "../../../hooks/event-handler";
import { getSStorage } from "../../../utils";

export default function useCompleteAdresse() {
  const history = useHistory();
  let params: any = useLocation();
  const dispatch = useDispatch();

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [dataInterested, setDataInterested] = useState<Array<Label>>([]);
  const [dataChecked, setdataChecked] = useState<Array<number>>(
    JSON.parse(getSStorage("labels") || "[]") || []
  );
  const [loader, setLoader] = useState(true);

  const callback = (data: Array<Label>) => {
    setDataInterested(data);
    setLoader(false);
  };

  const checkedRadio = (data: number) => {
    let tempData;
    if (dataChecked?.find((el) => el == data)) {
      tempData = dataChecked?.filter((e) => e != data);
      setdataChecked(tempData);
    } else {
      setdataChecked([...dataChecked, data]);
    }
  };

  const navigateCompletePicture = () => {
    sessionStorage.setItem("labels", JSON.stringify(dataChecked));
    history.push("./complete-profil-picture", {
      labels: JSON.stringify(dataChecked),
      ...params.state,
    });
  };

  useEffect(() => {
    dispatch(accountAction.getInterestedAction(callback));
    return () => {};
  }, []);

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateCompletePicture();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    dataInterested,
    dataChecked,
    params,
    history,
    loader,
    navigateCompletePicture,
    checkedRadio,
    dataCompleted,
  };
}
