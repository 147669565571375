import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import useOemBackground from "../../../../../controller/oem-ctr/block-component/use-oem-background";
import {
  checkType,
  fileUrl,
  getUserContected,
  refaCtoNullKey,
  toCapitalize,
} from "../../../../../utils";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";
import {
  ISV,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from "../../../../../service/constant";

const BillingOwner = (props: any) => {
  const currentProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const { defaultValue } = useOemBackground();
  const isvCompany = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [showOwner, setShowOwner] = useState(false);
  const closePopOwner = () => setShowOwner(false);
  const showPopOwner = () => setShowOwner(true);
  const [changeBillingOwner, setChangeBillingOwner] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<string>("0");
  const allPrograms = useSelector(
    (state: rootState) => state.userReducer.oemProgramMembers
  );

  let name = "";
  if (getUserContected(userReducer.roles, ISV)){
    name = isvCompany.company_legal_name;
  }else{
    let programNumber = 0;
   
    programNumber =
      allPrograms
        ?.filter((el) => el?.oem_program?.uuid != "")
        .findIndex((p) => p.oem_program.id == currentProgram.id) + 1;
    name = "Program " + programNumber + ": " +currentProgram.program_name;
  } 

  const renderAdminMembers = (members: Array<any>) => {
    if (members === undefined) return <></>;
    return (
      <div className="changeMember">
        <select
          name=""
          id="changeMember"
          className="custom-select mr-3"
          onChange={(elem) => setSelectedAdmin(elem.target.value)}
          defaultValue="0"
        >
          <option value="0" key="0">
            Select a team member
          </option>
          {/* ALL MEMBERS ADMIN */}
          {members.map((member: any) => {
            return (
              <option key={member.user.id} value={member.user.id}>
                {member.user.last_name} {member.user.first_name} -{" "}
                {toCapitalize(member?.role)}
                {" " + refaCtoNullKey(member?.user?.email)}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleBillingOwnerRegistration = (
    companyPageId: number,
    selected_admin: string
  ) => {
    //get email selected admin
    const adminId = props.admin_members?.filter(
      (admin: any) => admin?.user?.id?.toString() === selected_admin?.toString()
    )[0]?.user.id;
    props.billing_owner_registration(companyPageId, adminId);
    setShowOwner(false);
  };

  const fileName = getUserContected(userReducer.roles, ISV)
    ? isvCompany.photo_profil
    : defaultValue?.profil;

  return (
    <>
      <div className="itemBilling">
        <h2 className="title">Billing owner</h2>
        <div className="contentItem">
          <div className="itemctt">
            <b>
              {props.billing_owner_username
                ? props.billing_owner_username
                : "Loading..."}
            </b>
            <p>
              <small style={{ fontSize: "14px" }}>
                {props.billing_owner_email}
              </small>
            </p>
          </div>
          <div className="footerItem" style={{ fontSize: "14px" }}>
            {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
            <a
              onClick={
                [
                  OEM_PROGRAM_EDITOR,
                  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                ].indexOf(props?.roleUserIncurrentProgram) !== -1
                  ? props?.setShowModalPermissionOem
                  : showPopOwner
              }
            >
              Change billing owner
            </a>
          </div>
        </div>
      </div>
      {/* Popup Billing owner */}
      <Modal
        show={showOwner}
        className="createCompanypopup medium forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Change billing owner</h2>
            <a className="close" onClick={closePopOwner}>
              <span className="icon-close-pop" />
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder forBillingCenter">
            <div className="continue mb-5">
              <span className="icon-exclamation-circle"></span>
              <p className="mb-4">Are you sure you want to continue?</p>
              <div>
                You are about to change the billing owner of this current
                program :
              </div>
            </div>
            <div className="program mb-5">
              <div className="image">
                <img src={fileUrl(fileName)} className="Image" />
              </div>
              <div className="desc">
                <ul>
                  <li className="titleProgram">{name}</li>
                  <li>
                    {getUserContected(userReducer.roles, ISV)
                      ? isvCompany.company_name
                      : currentProgram.company_name}
                  </li>
                </ul>
              </div>
            </div>
            {renderAdminMembers(props.admin_members)}
            <div className="p-4">
              <div className="form-group form-check px-0 col-12 checkContent">
                <input
                  type="checkbox"
                  className="form-check-input regular-checkbox big-checkbox"
                  onChange={() => setChangeBillingOwner(!changeBillingOwner)}
                  checked={changeBillingOwner}
                />
                By changing the billing owner, you acknowledge that you will no
                longer be able to edit the payment information. To make changes
                in the future, you will need to contact the new billing owner.{" "}
                <br />
                <br />
                Only program owner and admin roles can be selected as the
                billing owner. There can only be one billing owner.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              onClick={closePopOwner}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                handleBillingOwnerRegistration(
                  props.company_page_id,
                  selectedAdmin
                )
              }
              type="submit"
              className="btn btn-devinsider px-5 save"
              disabled={changeBillingOwner === false || selectedAdmin === "0"}
            >
              <BtnNext
                text={"Change billing owner"}
                showLoader={props.register_billing_owner}
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BillingOwner;
