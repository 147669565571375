import React from "react";
import { makeClass } from "../../../../../../utils";
import { LoadingArticle, LoadingLabel } from "../../../../../widget/loader";

type IProps = {
  loaderGetTags: boolean;
  paginate: (
    array: Array<any>,
    page_size: number,
    page_number: number
  ) => Array<any>;
  labels: Array<any>;
  limitTopLabel: number;
  currentPage: number;
  allSelectedLabel: Array<any>;
  allNotSelectedLabel: Array<any>;
  useSelected: string;
  successFetchPaginate: boolean;
  setLabelsFilter: (id: number) => void;
  totalLabel: number;
  clikPrev: (p: number) => void;
  nextPage: (p: number) => void;
  resetFilter: () => void;
};
export default function Tags(props: IProps) {
  return (
    <div className="labelLeft">
      <div className="block">
        <h2 className="title">Tags</h2>
        <ul className="labelList">
          {props?.loaderGetTags && <LoadingLabel />}
          {props
            ?.paginate(props?.labels, props?.limitTopLabel, props?.currentPage)
            ?.map(
              (
                el: {
                  tag: string;
                  number_post: number;
                  id: number;
                },
                index: number
              ) => (
                <li
                  key={index}
                  className={makeClass(
                    props?.allSelectedLabel,
                    props.allNotSelectedLabel,
                    el?.id,
                    props.useSelected
                  )}
                  onClick={() =>
                    props?.successFetchPaginate &&
                    props?.setLabelsFilter(el?.id)
                  }
                >
                  {el?.tag}
                  {el?.number_post >= 0 && <span>{el?.number_post}</span>}
                </li>
              )
            )}

          {props?.totalLabel > props?.limitTopLabel && (
            <li className="linkForLi nextPrev">
              <a
                className={props?.currentPage === 1 ? "inactive" : ""}
                onClick={(e) => {
                  e.preventDefault();

                  props?.currentPage !== 1 &&
                    props?.clikPrev(props?.currentPage);
                }}
              >
                Previous
              </a>
              <span>
                {props?.currentPage} of{" "}
                {Math.ceil(props?.totalLabel / props?.limitTopLabel)}
              </span>
              <a
                className={
                  props?.currentPage ===
                  Math.ceil(props?.totalLabel / props?.limitTopLabel)
                    ? "inactive"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  props?.currentPage !==
                    Math.ceil(props?.totalLabel / props?.limitTopLabel) &&
                    props?.nextPage(props?.currentPage + 1);
                }}
              >
                Next
              </a>
            </li>
          )}
          <li className="linkForLi reset">
            <a
              href="javascript:;"
              className="resetFilter"
              onClick={(e) => {
                e.preventDefault();
                props?.resetFilter();
              }}
            >
              <span className="icon-refresh"></span>
              Reset filter
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
