import React from "react";
import {
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from "../../../../../service/constant";
import ModalMounthlyBuget from "./modal-mounthly-buget";
export default function Index(props: any) {
  return (
    <>
      <div className="itemBilling">
        <h2 className="title">Monthly budget</h2>
        <div className="contentItem">
          <div className="itemctt">
            <b>
              {props?.providerMounthLy?.numberIsvToUnlock > 0
                ? "You have a registered monthly budget"
                : "You haven't set a monthly budget"}
            </b>
          </div>
          <div className="footerItem" style={{ fontSize: "14px" }}>
            {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
            <a
              onClick={() => {
                if (
                  [
                    OEM_PROGRAM_EDITOR,
                    OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                  ].indexOf(props?.roleUserIncurrentProgram) !== -1
                ) {
                  props.setShowModalPermissionOem(true);
                } else {
                  props?.providerMounthLy?.showModalMounthly();
                }
              }}
            >
              Manage monthly budget
            </a>
          </div>
        </div>
      </div>
      <ModalMounthlyBuget {...props?.providerMounthLy} />
    </>
  );
}
