import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { contactSupportFindMoreIsv } from "../../../service/applicatif/company-page-isv";
import { rootState } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { FormContactSupportFindMoreIsv } from "./type";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function UseContactSupportFindMoreIsvCtr() {
  const history = useHistory();
  const [isShowContactSupportFindMoreIsv, setIsShowContactSupportFindMoreIsv] =
    useState<boolean>(false);

  let userObject = useSelector((state: rootState) => state.userReducer);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormContactSupportFindMoreIsv>({
    mode: "onSubmit",
  });
  const [loaderContact, setloaderContact] = useState<boolean>(false);

  const submitFormContactSupportFindMoreIsv = async (
    p: FormContactSupportFindMoreIsv
  ) => {
    setloaderContact(true);
    let params = {
      firstName: p?.firstNameContactUs,
      lastName: p?.lastNameContactUs,
      email: p?.emailContactUs,
      companyName: p?.companyNameContactUs,
      message: p?.messageContactUs,
    };
    let response = await contactSupportFindMoreIsv(params, userObject?.token);
    if (response?.status === 200) {
      setloaderContact(false);
      setIsShowContactSupportFindMoreIsv(false);
      toast("You request has been sent to Devinsider!");
    } else {
      toast(response.message);
      setloaderContact(false);
      setIsShowContactSupportFindMoreIsv(false);
      history.push("/isv/find-more");
    }
  };

  return {
    isShowContactSupportFindMoreIsv,
    setIsShowContactSupportFindMoreIsv,
    register,
    handleSubmit,
    setValue,
    errors,
    submit: submitFormContactSupportFindMoreIsv,
    getValues,
    loader: loaderContact,
    setloaderContact,
  };
}
