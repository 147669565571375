import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ISpentBy, InvestorPlanDetail } from "../type";
import { useHistory } from "react-router-dom";
import moment from "moment";

const InvestorPlanDetails = (props: InvestorPlanDetail) => {
  var numberTotalSpent = 0;
  const [key, setkey] = useState({
    activeId: "1",
    eventId: "1",
  });

  // render spent by FREE ADS
  const spentBy = (spent: Array<ISpentBy> = [], showUsers: boolean=true) =>
    spent.map((user) => (
      <>
        {
          showUsers && (
            <p key={user.first_name + " " + user.last_name}>
            {/* TODO define the url to redirect user */}
            {user.operation_number} free credit spent by{" "}
            <small>
              {user.first_name} {user.last_name}
            </small>
          </p>
        )}
        <small>Spent on { moment(user.created_at, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY")}</small>
      </>
    ));
  // spent.map((detail: ISpentBy) => (
  //   <p>
  //     {" "}
  //     <small>Spent on {detail.created_at}</small>
  //   </p>
  // ));
  const totalSpent = (spent: Array<ISpentBy> = []) => {
    spent.map(
      (detail: ISpentBy) =>
        (numberTotalSpent += parseInt(detail.operation_number))
    );
    return numberTotalSpent;
  };

  console.log("TEST", props?.investor_unlock_isv);

  return (
    <div className="contentBorder radius plan_details invest p-0">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 info">
          <h5>Find ISVs</h5>
          <h5 className="color-orange">
            {props?.investor_unlock_isv?.number_left} out of{" "}
            {props?.investor_unlock_isv?.number_max}
          </h5>
          <p>Free ISV credits available</p>
        </div>
      </div>
      <Accordion className="accordionForSetting" activeKey={key.eventId}>
        <div className="lineAccordion">
          {(props.investor_unlock_isv.spent_by &&
            props.investor_unlock_isv.spent_by.length > 0) ||
          (props?.investor_classified_ads?.spent_by &&
            props?.investor_classified_ads?.spent_by.length > 0) ? (
            <Accordion.Toggle
              eventKey="1"
              onClick={() => {
                if (key.activeId == "1" && key.activeId == "1") {
                  setkey({ eventId: "0", activeId: "0" });
                } else {
                  setkey({ activeId: "1", eventId: "1" });
                }
              }}
              className={`btnAccordion ${key.activeId === "1" ? "active" : ""}`}
            ></Accordion.Toggle>
          ) : (
            <></>
          )}

          <Accordion.Collapse eventKey="1">
            <div className="panel-body row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 info">
                <div className="spent">
                  {totalSpent(props?.investor_unlock_isv?.spent_by)} credit
                  {totalSpent(props?.investor_unlock_isv?.spent_by) > 1
                    ? "(s)"
                    : ""}{" "}
                  spent
                </div>

                {spentBy(props?.investor_unlock_isv?.spent_by, false)}
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default InvestorPlanDetails;
