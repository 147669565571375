import React from "react";
import Header from "../../widget/index";
import { useHistory } from "react-router";
import company from "../../../resources/images/company2.png";
import defaultCompany from "../../../resources/images/defaultCompany.png";
import "./software-labeling.scss";

export default function favorites() {
    return (
        <div className="findIsvcontainer programContainer softwareContainer">
        <Header />
            <div className="filterFindIsv">
                <div className="contentFilter">
                    <div className="containerBtn block infoProgramContainer">
                        <button className="btn btn-devinsider cancel">Filter</button>
                        <a href="javascript:;" className="resetFilter"><span className="icon-refresh"></span>Reset filter</a>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">Solution</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">Blockchain</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">IOT</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(11)</a>
                        </div>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">Industry</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">Aerospace</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">IOT</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(10)</a>
                        </div>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">HQ location</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">France</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">Germany</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(2)</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto minHeightwin">
                <div className="listOfIsv">
                    <div className="scrollContainer fav">
                        <ul className="listOfIsvMatch soft">
                            <li>
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-full"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="isvLogo">
                                    <img src={defaultCompany} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-full"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-full"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-full"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}