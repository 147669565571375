import "./Events.scss";
import React from "react";
import Header from "../../widget/index";
import UseMyEvents from "../../../controller/event-ctr/use-my-events-ctr";
import BlocFilterEvent from "./bloc-filter/bloc-filter-event";
import ModalEditEvents from "./bloc-filter/modal-edit-event";
import eventSections from "./event-sections";
import Pagination from "../../widget/use-pagination/pagination";

export default function Myevents() {
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerEvents = UseMyEvents();

  return (
    <div className="findIsvcontainer">
      <Header handleInitEvent={() => providerEvents.resetFilter()} />
      <BlocFilterEvent {...providerEvents} tagFilterIn={1} />
      <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          <div className="headerOfList">
            <div className="leftHead">
              <div className="number">
                Showing <b>{providerEvents?.eventObject?.total || "0"}</b>{" "}
                Events
              </div>
              <div className="formContentOpen" onClick={(e) => openBlock(e)}>
                <input
                  placeholder="Search..."
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  value={providerEvents?.search}
                  onChange={(e) => providerEvents?.setSearch(e?.target?.value)}
                  onKeyPress={providerEvents?.handleSearch}
                />
              </div>
            </div>
          </div>

          <div className="scrollContainer" id="scrollContainer">
            <eventSections.MyEvent
              events={providerEvents?.eventObject?.results}
              target="scrollContainer"
              showModalEdit={providerEvents?.showModalEdit}
              deleteEvent={providerEvents?.deleteEvent}
              loadingDelete={providerEvents.loadingDelete.loading}
              idTodelete={providerEvents.loadingDelete.idToDelete}
            />
          </div>
          <div className="pagginationCategory">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={providerEvents?.page}
                totalCount={providerEvents?.eventObject?.total || 0}
                pageSize={10}
                onPageChange={(page: any) =>
                  providerEvents.handlePageChange(page)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ModalEditEvents
        showNext={providerEvents?.showNext}
        setShowNext={(p: boolean) => providerEvents?.setShowNext(p)}
        handleSubmit={providerEvents?.handleSubmit}
        onSubmit={providerEvents?.onSubmit}
        startDate={providerEvents?.startDate}
        setStartDate={(p: any) => providerEvents?.setStartDate(p)}
        isAllTime={providerEvents?.isAllTime}
        isShowEndTime={providerEvents?.isShowEndTime}
        register={providerEvents?.register}
        setisShowEndTime={providerEvents?.setisShowEndTime}
        setisAllTime={providerEvents?.setisAllTime}
        errors={providerEvents?.errors}
        loaderCreateEvent={providerEvents?.loaderEdit}
        getValue={providerEvents?.getValues}
        isErrorInfo={providerEvents?.isErrorInfo}
        txtError={providerEvents?.txtError}
        isSelected={providerEvents?.isSelected}
        control={providerEvents.control}
        eventDescription={providerEvents.eventDescription}
        setEventDescription={providerEvents.setEventDescription}
        isValid={providerEvents.isValid}
        dataLabelFilter={providerEvents.dataLabelFilter}
        defaultDataLabel={providerEvents.defaultDataLabel}
        handleFilterSelect={providerEvents.handleFilterSelect}
        handleSelectedLabels={providerEvents.handleSelectedLabels}
        setTxtFilter={providerEvents.setTxtFilter}
        txtFilter={providerEvents.txtFilter}
        isFocused={providerEvents.isFocused}
        setIsFocused={providerEvents.setIsFocused}
        timezone={providerEvents.timezone}
        setTimezone={providerEvents.setTimezone}
      />
    </div>
  );
}
