import React, { Fragment, useEffect } from "react";
import { FindInvestor } from "./find-investor";
import MatchingInvestor from "../../../../hooks/MatchingInvestor";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { INVESTOR } from "../../../../service/constant";
import { clearOtherInvestorCahe } from "../../../../redux/actions/company-page-investor-action/other-investor-page-action";
import { checkIsCompletedInvestorCompanyMatching } from "../../../../utils";
const queryString = require("query-string");

export const FindInvestorWrapper: React.FC = () => {
  const location = useLocation();
  const userObject = useSelector((state: rootState) => state?.userReducer);
  const dispatch = useDispatch();

  const isNotINvestor = userObject?.roles?.indexOf(INVESTOR) === -1;

  let queryParameter = queryString.parse(location?.search);

  const selectedStage = queryParameter ? queryParameter.stage : "all";
  useEffect(() => {
    if (isNotINvestor) {
      dispatch(clearOtherInvestorCahe());
    }
  }, []);

  const pageIsv = useSelector((state: rootState) => state?.companyPageReducer);

  if (!checkIsCompletedInvestorCompanyMatching(pageIsv)) {
    return <Redirect to={`/find-investors?stage=${selectedStage}`} />;
  }

  return (
    <MatchingInvestor>
      <Fragment key={selectedStage}>
        <FindInvestor stage={selectedStage} />
      </Fragment>
    </MatchingInvestor>
  );
};
