/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { IPropsAbout } from "../type";

export default function BlockSolution(props: IPropsAbout) {
  const [index1, setindex1] = useState<number>(6);
  const segmentDetailsLength =
    props?.dataSource?.companyResellerState?.segmentDetails?.length;
  return (
    <div className="col-lg-4 col-12 contactInfo mt-4">
      <div className="boxEditProfil">
        <a
          className="editItem"
          onClick={(e) => {
            e.preventDefault();
            props?.dataSource?.setSolutionShow(true);
          }}
        >
          Edit
          <span className="icon-edit-full"></span>
        </a>
        <h3 className="title">Software solution</h3>
        <ul className="listnamelabel">
          {props?.dataSource?.companyResellerState?.segmentDetails
            .filter((el: { id: number; name: string }, index: number) => {
              return index < index1;
            })
            ?.map((element: { id: number; name: string }, index: number) => (
              <li key={index}>
                <div className="name">{element?.name}</div>
              </li>
            ))}
          {segmentDetailsLength > 6 && (
            <li className="link">
              <a
                onClick={(evt) => {
                  evt.preventDefault();
                  setindex1(
                    index1 === segmentDetailsLength ? 6 : segmentDetailsLength
                  );
                }}
              >
                See {index1 === segmentDetailsLength ? "less" : "more"}
                {index1 === segmentDetailsLength
                  ? ""
                  : `(${segmentDetailsLength - index1})`}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
