import React from "react";
import Header from "../../../widget/header-logo-center/header-logo-center";
import UseCreate from "../../../../controller/account-ctr/create-oem-account/complete-process-affilliated-account/use-adress";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../utils/country_phone";

export default function Index2() {
  const createCtr = UseCreate();
  const handleSelect = (countryCode: any) => {
    createCtr.setselected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    createCtr.setDialCodeSelected(dialCode ? dialCode : "");
  };
  return (
    <div>
      <Header />
      <form
        className="getstartedContainer"
        onSubmit={createCtr.handleSubmit(createCtr.onSubmit)}
      >
        <div className="head-getstarted">
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              createCtr.onBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Let's get started, {createCtr.first_name}</h1>
        </div>
        <div className="contentBorder border-top row pb-2">
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label>
              Country /Region
              {createCtr.errors.country && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <select
              id=""
              className="custom-select"
              placeholder="Select an option..."
              {...createCtr?.register("country", {
                required: true,
              })}
            >
              <option value="">Select an option...</option>
              {createCtr.country?.map((el: any) => (
                <option value={el.id} key={el.id}>
                  {el.country_name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 numberphone">
            <label>
              Phone number
              {createCtr.errors.phoneNumber?.type === "required" && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              {createCtr.errors.phoneNumber?.type === "validate" && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">Phone number is not valid</span>
                </div>
              )}
            </label>
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                style={{
                  textIndent:
                    createCtr?.dialCodeSelected?.length === 2
                      ? 35
                      : createCtr?.dialCodeSelected?.length === 3
                      ? 40
                      : createCtr?.dialCodeSelected?.length === 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                {...createCtr?.register("phoneNumber", {
                  required: true,
                  validate: (phone: any) =>
                    isValidPhoneNumber(
                      createCtr?.dialCodeSelected + phone?.toString()
                    ),
                })}
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                (
                {contact &&
                  contact.find((el: any) => el.code == createCtr?.selected)
                    ?.dial_code}
                )
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={createCtr?.selected}
                onSelect={handleSelect}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="company-name required">
              Company name{" "}
              {createCtr.errors.company_name?.type === "required" && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>

            <input
              type="text"
              className="input-text form-control"
              {...createCtr.register("company_name", {
                required: true,
              })}
              disabled={
                sessionStorage?.getItem("token_affiliated") ? true : false
              }
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="job-title required">
              Job title{" "}
              {createCtr.errors.job_title?.type === "required" && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>

            <input
              type="text"
              className="input-text form-control"
              {...createCtr.register("job_title", {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="contentBorder border-top row py-6 align-items-stretch">
          <div className="col-xl-12 col-md-12 col-sm-12 d-flex align-items-center p-0">
            <button className="btn btn-devinsider large containerBtnBtn">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
