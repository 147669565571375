import React from "react";
import Header from "../../widget/index";
import { useHistory } from "react-router";
import company from "../../../resources/images/company2.png";
import defaultCompany from "../../../resources/images/defaultCompany.png";
import "./software-labeling.scss";

export default function listings() {
    return (
        <div className="findIsvcontainer programContainer softwareContainer listings">
        <Header />
            <div className="container mx-auto">
                <div className="listOfIsv">
                    <div className="shadow p-3 mt-4">
                        <ul className="listOfIsvMatch soft">
                            <li>
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="isvLogo">
                                    <img src={defaultCompany} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}