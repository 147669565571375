import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import UseHeaderCtr from "../../../../src/controller/community-ctr/header-ctr";
import { rootState } from "../../../redux/reducers";
import UsePayementPr from "../../../screen/widget/payement-isv/publish-pr/use-publish-press-release";
import usePostWysiwig from "../../../screen/widget/post-wysiwig/use-post-wysiwig";
import { postData } from "../../../service/api";
import { getDataService } from "../../../service/applicatif/back-end-service";
import {
  createPost,
  getTags,
} from "../../../service/applicatif/community-service";
import { ISV, OEM_PROGRAM, url } from "../../../service/constant";
import { getSStorage, isModerator, urlCommunity } from "../../../utils";
import { ICardInformation } from "../../settings-ctr/billing-center/use-global-billing";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

export type ITagsProps = {
  id: string;
  name: string;
};

export default function PressReleaseCtr() {
  const history = useHistory();

  const userObject = useSelector((state: rootState) => state?.userReducer);
  const [currentPost, setCurrentPost] = useState<any>(null);

  const programReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer
  );

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const providerHeaderProps = { ...UseHeaderCtr() };

  const [cardInformation, setCardInformation] = useState<ICardInformation>({
    brand: "",
    exp_month: "",
    exp_year: "",
    last4: "",
  });
  const [postType, setPostType] = useState<number>(0);
  const [tags, setTags] = useState<ITagsProps[]>([]);
  const [errorTags, setErrorTags] = useState<boolean>(false);
  const [errorAffiliated, setErrorAffiliated] = useState<boolean>(false);
  const { object, setEditorStatePost, setTxtObject, stateFormWysywig } =
    usePostWysiwig({ initialWysiwygTemplate: "", initialeObjectText: "" });
  const [titleError, settitleError] = useState<boolean>(false);

  const providerUsePayementPr = UsePayementPr();
  const [loading, setLoading] = useState<boolean>(false);

  const [txtFilter, setTxtFilter] = useState<string>("");
  const [debouncedText] = useDebounce(txtFilter, 100);
  const [dataTagsFilter, setDataTagsFilter] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<string>("");
  const [isLoadFilter, setIsLoadFilter] = useState(true);
  const [dataListe, setDataListe] = useState<Array<string>>([]);
  const [labelError, setlabelError] = useState<boolean>(false);
  const [showCardInfo, setShowCardInfo] = useState(false);
  const tempState = sessionStorage.getItem("_previewPost");
  let parsedData: any = tempState ? JSON.parse(tempState) : null;

  const isOem = userObject?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const isIsv = userObject?.roles?.indexOf(ISV) !== -1;

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  useEffect(() => {
    const getTagsWs = async () => {
      let data = await getTags();
      setTags(data || []);
    };
    getTagsWs();
  }, []);

  // set the post type
  useEffect(() => {
    setPostType(
      dataCompleted?.category_and_post_type?.post_type?.filter(
        (el: any) => el.type === "Press Release"
      )[0]?.id
    );
  }, []);

  useEffect(() => {
    if (errorTags) {
      setErrorTags(false);
    }
  }, [txtFilter, errorTags]);

  const showPr = (cc?: any) => {
    const id = currentPost?.id || cc?.id;
    const url = id
      ? "/community/reply-post/0/" + id
      : "/community/article-category/0/0";
    history.push(url);
  };

  const cancelPost = () => {
    setTxtFilter("");
    setSelectedTags("");
    setTxtObject("");
    setDataListe([]);
    setEditorStatePost(EditorState?.createEmpty());
    history.goBack();
  };

  useEffect(() => {
    if (titleError) settitleError(false);
    return () => {};
  }, [object]);

  const publishPR = async (
    isFree: boolean,
    paymentId: string | null,
    setLoading: (l: boolean) => void,
    setShowPaymentModal: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    if (object?.length < 1) {
      settitleError(true);
      setLoading(false);
      return;
    }

    if (isIsv && !userObject?.isvInCompanyPage) {
      setErrorAffiliated(true);
      return;
    }
    if (
      isOem &&
      userObject?.oemProgramMembers.filter(
        (oemp) => oemp.oem_program.id === programReducer.program.id
      ).length < 1
    ) {
      setErrorAffiliated(true);
      return;
    }

    setLoading(true);
    let tempLabels = txtFilter?.split(",");
    const removeAllSpace = tempLabels?.filter((item: string) => item !== " ");
    let data = {
      title: object,
      description: JSON.stringify(
        convertToRaw(stateFormWysywig.getCurrentContent())
      ),
      slug: "",
      emailMe: 0,
      postType:
        postType !== undefined
          ? postType
          : dataCompleted?.category_and_post_type?.post_type?.filter(
              (el: any) => el.type === "Press Release"
            )[0]?.id,
      tags: removeAllSpace?.join()?.trim(),
      choice: isFree ? "FREE" : "PAY",
      oemProgram: isOem ? programReducer.program.id : null,
      paymentId: paymentId || "",
      savePaymentMethod: savePaymentMethod ?? false,
    };
    createPost(data, (result: boolean, responseData?: any) => {
      if (result) {
        setShowPaymentModal(false);
        if (isFree || isOem || isModerator(userObject?.roles)) {
          toast("successfully  published");
          setCurrentPost(responseData);
          showPr(responseData);
        } else {
          setCurrentPost(responseData);
          setShowPaymentStatus &&
            setShowPaymentStatus(true, {
              status: "success",
              invoiceNumber: responseData.invoice_number,
              invoiceUrl: responseData.invoice_url,
            });
        }
      } else {
        setShowPaymentModal(false);
        if (isFree || isOem || isModerator(userObject?.roles)) {
          toast("An Error Occurred");
        } else {
          setShowPaymentStatus &&
            setShowPaymentStatus(true, {
              status: "error",
            });
        }
      }
      setLoading(false);
    });
  };

  const pbPR = async (paymentId: string | null, isFree = true) => {
    if (object?.length < 1) {
      settitleError(true);
      setLoading(false);
      return;
    }

    if (isIsv && !userObject?.isvInCompanyPage) {
      setErrorAffiliated(true);
      return;
    }
    if (
      isOem &&
      userObject?.oemProgramMembers.filter(
        (oemp) => oemp.oem_program.id === programReducer.program.id
      ).length < 1
    ) {
      setErrorAffiliated(true);
      return;
    }

    setLoading(true);
    let tempLabels = txtFilter?.split(",");
    const removeAllSpace = tempLabels?.filter((item: string) => item !== " ");
    let data = {
      title: object,
      description: JSON.stringify(
        convertToRaw(stateFormWysywig.getCurrentContent())
      ),
      slug: "",
      emailMe: 0,
      postType:
        postType !== undefined
          ? postType
          : dataCompleted?.category_and_post_type?.post_type?.filter(
              (el: any) => el.type === "Press Release"
            )[0]?.id,
      tags: removeAllSpace?.join()?.trim(),
      choice: "FREE",
      oemProgram: isOem ? programReducer.program.id : null,
      paymentId: paymentId || "",
      savePaymentMethod: false,
    };
    createPost(data, (result: boolean, responseData?: any) => {
      if (result) {
        if (isFree || isOem || isModerator(userObject?.roles)) {
          toast("successfully  published");
          setCurrentPost(responseData);
          showPr(responseData);
        }
      } else {
        if (isFree || isOem || isModerator(userObject?.roles)) {
          toast("An Error Occurred");
          setCurrentPost(responseData);
          showPr(responseData);
        }
      }
      setLoading(false);
    });
  };

  // post pr
  const postPr = (categorie: number, left?: number) => {
    providerHeaderProps?.createDiscution(categorie);
    if (left !== undefined) {
      sessionStorage.setItem("left", left.toString());
    }
  };

  // load tags
  const promiseOptions = async (inputValue: string) => {
    let labels = await getDataService(
      url.community?.get_tags,
      { name: inputValue },
      userObject?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.name,
      value: el?.name,
    }));

    setDataTagsFilter(tempResult);
  };

  const handleSelectedLabels = (p: string) => {
    if (labelError) {
      setlabelError(false);
    }
    if (isLoadFilter) {
      setIsLoadFilter(false);
    }
    setSelectedTags(p);
  };

  const handleFilterSelect = (txt: string) => {
    setDataTagsFilter([]);
    const convertArray = txtFilter.split(",");

    //Check if txt isAlreadyExist
    const isExist = convertArray?.find((el: string) => el === txt);
    //remove last string
    convertArray.pop();
    const reformToString = convertArray.join(",");
    if (!isExist) {
      //Make a ';' in end to handlea auther recherche
      const concatString =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + "," + txt + ","
          : txt + ",";
      setTxtFilter(concatString);
    } else {
      const concat =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + ","
          : "";
      setTxtFilter(concat);
    }
  };

  useEffect(() => {
    async function filterSearch() {
      if (debouncedText) {
        //Convert to Array
        const handleString = txtFilter?.split(",");
        const removeSpace = handleString?.filter((el) => el != " ");
        //Change Liste selected
        const setselectedTagsInChoose = tags.filter((el: ITagsProps) => {
          const found = removeSpace?.find(
            (item: string) => item?.trim() === el?.name?.trim()
          );
          if (found) {
            return el;
          }
        });
        const tempDataList = setselectedTagsInChoose?.map((i) => i.name);

        setDataListe(tempDataList);

        //Get last Value
        const lastString = removeSpace?.pop() || "";
        if (lastString) {
          promiseOptions(lastString);
        }
      }
    }

    if (isLoadFilter) {
      dataTagsFilter?.length > 0 && setDataTagsFilter([]);
      filterSearch();
    } else {
      setIsLoadFilter(true);
    }

    if (txtFilter === "") {
      setDataListe([]);
      setTxtFilter("");
    }
    return () => {};
  }, [debouncedText, tags]);

  useEffect(() => {
    //Convert to Array
    const handleString = txtFilter?.split(",");

    const findTxt = handleString?.find((el: string) => el === selectedTags);

    //Remove txt tags if exist
    if (findTxt) {
      const tempArray = handleString?.filter((el) => el !== selectedTags);
      setTxtFilter(tempArray?.join(","));
      setDataListe(tempArray);
    } else {
      //Add new string when not found
      const concatString =
        txtFilter?.length > 0 ? txtFilter + "," + selectedTags : selectedTags;
      const dataArr = concatString?.split(",");
      //Remove occurence
      const toStringOccurence = dataArr?.filter((el) => el !== "");

      setTxtFilter(toStringOccurence?.join(","));
      setDataListe(dataArr);
    }
  }, [selectedTags]);

  const showPreview = () => {
    const data = JSON.stringify({
      content: convertToRaw(stateFormWysywig?.getCurrentContent()),
      object: object,
      txtFilter: txtFilter,
      type: "Press release",
    });
    sessionStorage.setItem("_previewPost", data);
  };

  useEffect(() => {
    if (tempState) {
      if (parsedData && parsedData?.content) {
        setEditorStatePost(
          EditorState?.createWithContent(convertFromRaw(parsedData?.content))
        );
      }
      if (parsedData && parsedData.object) setTxtObject(parsedData?.object);
      if (parsedData && parsedData.txtFilter) {
        setTxtFilter(parsedData?.txtFilter);
        setDataListe(parsedData?.txtFilter?.split(","));
      }
    }
    const routeUrl = history?.location?.pathname;
    const isInComminity = urlCommunity.includes(routeUrl);

    if (!isInComminity) {
      sessionStorage.removeItem("_previewPost");
    }
    return () => {};
  }, []);

  return {
    tags,
    selectedTags,
    errorTags,
    stateFormWysywig,
    setEditorStatePost,
    // post,
    object,
    setTxtObject,
    errorAffiliated,
    cancelPost,
    setErrorAffiliated,
    promiseOptions,
    titleError,
    user: {},
    providerUsePayementPr,
    loading,
    publishPR,
    txtFilter,
    setTxtFilter,
    dataTagsFilter,
    handleFilterSelect,
    dataListe,
    handleSelectedLabels,
    cardInformation,
    showPr,
    postPr,
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    mentions,
    updateMention: async (value: any) => {
      let keyWord =
        value?.blocks[0]?.text.includes("@") &&
        value?.blocks[0]?.text?.split("@");
      let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? "";
      if (keyWordText !== "") {
        let response = await postData(
          url?.community.mention + "/create",
          {
            postType: "",
            keyWord: keyWordText,
          },
          userObject.token
        );
        if (response?.status === 200) {
          setMentions(
            response?.data?.map((rep: any) => {
              return {
                text: rep?.first_name + " " + rep?.last_name,
                value: rep?.first_name + " " + rep?.last_name,
                url: "/community/activity-user/" + rep?.id,
              };
            })
          );
        }
      }

      //register current mention
      let mentionsKeyArray = Object?.keys(value?.entityMap);
      let mentionsData: Array<any> = [];
      mentionsKeyArray.map((key: string) => {
        if (value?.entityMap[key]?.type === "MENTION") {
          mentionsData.push(value?.entityMap[key]?.data?.url?.split("/").pop());
        }
      });
      setRegisteredMentions(mentionsData);
    },
    showCardInfo,
    setShowCardInfo,
    showPreview,
    pbPR,
  };
}
