import React from "react";
import useProgramInfoCtr from "../../../../../controller/oem-ctr/block-component/use-program-info-ctr";
import ModalOemSegmentDetail from "../modal-edit-oem/oem-edit-type-page";

export default function OemProgramInfo(props: any) {
  const {
    oemProgram,
    onSubmit,
    modal,
    closeModal,
    openModal,
    loading,
    dataSelected,
    typeProgram,
    setTypeProgram,
    dropDownItems,
    setDataSelected,
    programName,
    setprogramName,
    errorsProgramName,
    segmentError,
    typeError,
    oemProgramDraft,
    programType,
  } = useProgramInfoCtr();
  return (
    <>
      <ul className="investorInfoList half">
        <a
          className="linkEditLeftCompany"
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            openModal();
          }}
        >
          Edit
          <span className="icon-edit-full" />
        </a>
        <li className="editShow">
          <label>Program name</label>
          {oemProgramDraft?.programName || oemProgram?.program_name}
        </li>
        <li className="justForProfil">
          <label>Website</label>
          <a
            target={"_blank"}
            href={
              "https://" + (oemProgramDraft?.website || oemProgram?.website)
            }
          >
            {oemProgramDraft?.website || oemProgram?.website}
          </a>
        </li>
        <li>
          <label>Program type</label> {programType}
        </li>
        <li className="editShow">
          <label>Program segment(s)</label>
          {dataSelected?.length + " "}
          segment{dataSelected?.length > 1 ? "s" : ""} listed
        </li>
      </ul>
      <ModalOemSegmentDetail
        dropDownItems={dropDownItems}
        dataSelected={dataSelected}
        typeProgram={typeProgram}
        setDataType={(p: string) => setTypeProgram(p)}
        onSubmit={() => onSubmit()}
        modal={modal}
        closeModal={closeModal}
        openModal={openModal}
        loading={loading}
        setDataSelected={(p: any) => setDataSelected(p)}
        errorsProgramName={errorsProgramName}
        programName={programName}
        segmentError={segmentError}
        typeError={typeError}
        setProgramName={(value: string) => setprogramName(value)}
      />
    </>
  );
}
