import React from "react";
import { Link } from "react-router-dom";

import {
  checkIfExist,
  concatString,
  fileUrl,
  refaCtoNullKey,
  uppercaseFirstLetter,
} from "../../../../../utils";
import url, {
  ISV,
  INVESTOR,
  RESELLER,
  GUEST,
  OEM_PROGRAM,
  CONTRIBUTOR,
} from "../../../../../service/constant/index";
import * as CardRigth from "./company-card-header/index";

const ProfilHeader = (props: any) => {
  const renderCompanyCard = (arg: any) => {
    //get role
    const role = arg.userState.roles.filter(
      (role: string) => role !== "ROLE_USER"
    )[0];
    if (role === ISV) return <CardRigth.CompanyCardIsv {...arg} />;
    if (role === INVESTOR) return <CardRigth.CompanyCardInvestor {...arg} />;
    if (role === RESELLER) return <CardRigth.CompanyCardReseller {...arg} />;
    if (role === GUEST) return <CardRigth.CompanyCardGuest {...arg} />;
    if (role === OEM_PROGRAM) return <CardRigth.CardOemProgram {...arg} />;
    if (role === CONTRIBUTOR) return <CardRigth.CompanyCardExpert {...arg} />;

    return <></>;
  };
  const isActiveEmailNotExpertGuestResseller =
    props?.userState?.primary_email &&
    props?.userState.roles.indexOf(INVESTOR) == -1 &&
    props?.userState.roles.indexOf(CONTRIBUTOR) == -1 &&
    props?.userState.roles.indexOf(RESELLER) == -1 &&
    props?.userState.roles.indexOf(CONTRIBUTOR) == -1;

  return (
    <div className="row profilHead m-0">
      <div className="col-xl-8 col-lg-8 col-md-12 col-12 infoProfil d-flex">
        <div
          className={`imageProfil ${props?.userState?.file_name ? "noBg" : ""}`}
        >
          <img src={`${fileUrl(props?.userState?.file_name)}`} alt="" />
        </div>
        <div className="aboutProfil">
          <h1 className="title">
            {/* Dev #48040 Guest - Page profile */}
            {uppercaseFirstLetter(props?.userState?.real_first_name)}&nbsp;{uppercaseFirstLetter(props?.userState?.real_last_name)}
            {isActiveEmailNotExpertGuestResseller &&
              props?.userState.roles.indexOf(GUEST) == -1 && (
                <div className="verified">
                  {" "}
                  <span className="icon-check"></span>
                </div>
              )}
          </h1>
          <p>
            {concatString(
              props?.userState?.country?.country_name,
              props?.userState?.city
            )}
          </p>
          <p>
            {concatString(
              props?.userState.real_job_title,
              props?.userState.real_company_name,
              " at "
            )}
          </p>
          <div className="contentButtonEdit">
            <button className="editProfilbtn" onClick={props?.editProfile}>
              <span className="icon-edit"></span>
              Edit profile
            </button>
            <button className="editProfilbtn" onClick={props?.goSettings}>
              <span className="icon-settings"></span>
            </button>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 col-12 infoMyIsv">
        {/* COMPANY CARD */}
        {renderCompanyCard(props)}
      </div>
    </div>
  );
};

export default ProfilHeader;
