import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { rootState } from "../../../redux/reducers";
import { IDiscutionCategorie } from "../../../redux/types/community-interface";
import usePostWysiwig from "../../../screen/widget/post-wysiwig/use-post-wysiwig";
import {
  postDataFormDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { MODERATOR, OEM_PROGRAM, url } from "../../../service/constant";
import UsePostFeturedArticle from "../../../screen/widget/payement-oem/post-featured-article/use-post-fetured-article";
import { useDebounce } from "use-debounce";
import { getData, postData } from "../../../service/api";
import { toast } from "react-toastify";

export default function PostDiscution() {
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgramReducer = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const [labelError, setlabelError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [selectedCategorie, setselectedCategorie] = useState<string>("0");
  const [titleError, settitleError] = useState<boolean>(false);
  const [isNotCategorySelected, setisNotCategorySelected] =
    useState<boolean>(false);
  const location: any = useLocation();
  const history = useHistory();
  const { object, setEditorStatePost, setTxtObject, stateFormWysywig } =
    usePostWysiwig({ initialWysiwygTemplate: "", initialeObjectText: "" });

  const makeCategorie = (params: Array<IDiscutionCategorie>) =>
    params?.map((el: IDiscutionCategorie) => ({
      value: el?.id,
      label: el?.title,
    }));

  const [labelsCustom, setlabelsCustom] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [txtFilter, setTxtFilter] = useState<string>("");
  const [debouncedText] = useDebounce(txtFilter, 100);
  const [isLoadFilter, setIsLoadFilter] = useState(false);
  const [emailMe, setEmailMe] = useState<boolean>(true);
  const [dataListe, setDataListe] = useState<Array<string>>([]);
  const [dataLabelFilter, setDataLabelFilter] = useState<Array<string>>([]);
  const tempState = sessionStorage.getItem("_previewPost");
  let parsedData: any = tempState ? JSON.parse(tempState) : null;
  if (location?.state != undefined) {
    sessionStorage.setItem("postType", location?.state?.post_type);
  }
  const [postType, setPostType] = useState<string | null>(
    sessionStorage.getItem("postType")
  );

  const isArticle = postType === "2";
  const isFeaturedArticle = postType === "5";

  console.log("postType", postType);

  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;
  //full information category / label
  const [fullCategoryLabel, setFullCategoryLabel] = useState<any>([]);
  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  const cancelPostDuscussion = () => {
    setTxtFilter("");
    setSelectedLabel("");
    setTxtObject("");
    setEditorStatePost(EditorState?.createEmpty());
    history.goBack();
  };

  const providerUsePostFeturedArticle = UsePostFeturedArticle();

  useEffect(() => {
    if (titleError) settitleError(false);
    return () => {};
  }, [object]);

  useEffect(() => {
    if (selectedCategorie !== "0") setisNotCategorySelected(false);
    return () => {};
  }, [selectedCategorie]);

  const completePostForum = async () => {
    try {
      let tempLabels = txtFilter?.split(",");
      //IF USER OEM => PUBLISH FEATURED ARTICLE, ELSE ARTICLE

      let params = {
        title: object,
        category: selectedCategorie,
        description: JSON.stringify(
          convertToRaw(stateFormWysywig?.getCurrentContent())
        ),
        emailMe: emailMe,
        labels: tempLabels.join()?.trim(),
        //if OEM posting article, change type of post type FEATURED ARTICLE
        postType: isOem && isFeaturedArticle ? 5 : postType,
        //Send oem program key when publication is Article or Program review
        oemProgram:
          isOem && (postType === "4" || isArticle || isFeaturedArticle)
            ? oemProgramReducer.id
            : null,
      };
      setLoader(true);
      let response: any = await postDataFormDataService(
        params,
        url.community.create_post,
        userReducer?.token
      );
      setLoader(false);
      if (response?.status === 201 || response?.data?.id) {
        //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
        let postId = response?.data && response?.data?.id;

        await postData(
          url?.community?.mention + "s/register",
          {
            postId: postId,
            postType: "FORUM_DISCUSSION",
            userId: registeredMentions,
          },
          userReducer.token
        );

        const postTypeParams = isArticle
          ? response?.data?.post?.category?.id
          : postType;

        history.push(
          `/community/reply-post/${
            isOem && isFeaturedArticle ? 5 : postTypeParams
          }/${postId}`
        );
      }
    } catch (error) {
      setLoader(false);
      toast("An Error Occurred");
    }
  };

  const postDiscussion = async () => {
    if (object?.length < 1) {
      settitleError(true);
      return;
    }
    if (selectedCategorie?.toString() === "0") {
      setisNotCategorySelected(true);
      return;
    }

    let tempLabels = txtFilter.split(",");

    const removeSpacer = tempLabels?.filter((el) => el !== "");

    let isInIntervale =
      removeSpacer?.length > 0 && removeSpacer?.length <= 3 && txtFilter !== "";

    if (isInIntervale) {
      completePostForum();
    } else {
      setlabelError(true);
    }
  };

  const handleSelectedLabels = (p: string) => {
    if (labelError) {
      setlabelError(false);
    }
    if (isLoadFilter) {
      setIsLoadFilter(false);
    }
    setSelectedLabel(p);
  };

  const promiseOptions = async (inputValue: string) => {
    let parmams = {
      category: "/api/categories/" + selectedCategorie,
      search: inputValue,
    };
    let labels = await postJsonDataService(
      url.community?.auto_complete_label,
      parmams,
      userReducer?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.title,
      value: el?.title,
    }));
    setDataLabelFilter(tempResult);
  };

  //get full information category label
  useEffect(() => {
    (async () => {
      const response = await getData(url.community.full_category_label, "");
      if (response && response?.status === 200)
        setFullCategoryLabel(response.data);
      else setFullCategoryLabel([]);
    })();
  }, []);

  useEffect(() => {
    if (labelError) {
      setlabelError(false);
    }
    return () => {};
  }, [labelsCustom]);

  useEffect(() => {
    setlabelsCustom([]);
    return () => {};
  }, [selectedCategorie]);

  const continuePostFeaturedArticle = () => {
    completePostForum();
  };

  const getLables = (): Array<any> => {
    const selectedCategory = fullCategoryLabel?.find(
      (el: any) => el?.id?.toString() === selectedCategorie?.toString()
    );

    return selectedCategory?.labels;
  };

  useEffect(() => {
    if (tempState) {
      if (parsedData && parsedData?.content) {
        setEditorStatePost(
          EditorState?.createWithContent(convertFromRaw(parsedData?.content))
        );
      }
      if (parsedData && parsedData.object) setTxtObject(parsedData?.object);
      if (parsedData && parsedData?.category)
        setselectedCategorie(parsedData?.category);
      if (parsedData && parsedData?.labels) {
        setDataListe(parsedData?.labels);
        setTxtFilter(parsedData?.labels?.join(","));
      }
    }
    sessionStorage.removeItem("_previewPost");
    return () => {};
  }, []);

  useEffect(() => {
    async function filterSearch() {
      if (debouncedText) {
        //Convert to Array
        const handleString = txtFilter?.split(",");

        //Change Liste selected
        const setselectedLabelInChoose = getLables()?.filter((el: any) => {
          const found = handleString?.find(
            (item: string) => item?.trim() === el?.title?.trim()
          );
          if (found) {
            return el;
          }
        });

        const tempDataList = setselectedLabelInChoose?.map((i) => i.title);
        setDataListe(tempDataList);

        //Get last Value
        const lastString = handleString?.pop() || "";
        if (lastString) {
          promiseOptions(lastString);
        }
      }
    }
    if (debouncedText) {
      if (isLoadFilter) {
        dataLabelFilter?.length > 0 && setDataLabelFilter([]);
        filterSearch();
      } else {
        setIsLoadFilter(true);
      }
    }
    const isEmptyTextFilter =
      txtFilter === "" && parsedData?.labels?.length < 1;
    if (isEmptyTextFilter) {
      setDataListe([]);
      setTxtFilter("");
    }

    return () => {};
  }, [debouncedText]);

  useEffect(() => {
    if (selectedLabel) {
      //Convert to Array
      const handleString = txtFilter?.split(",");

      const findTxt = handleString?.find((el: string) => el === selectedLabel);

      if (findTxt) {
        const tempArray = handleString?.filter((el) => el !== selectedLabel);
        setTxtFilter(tempArray?.join(","));
        setDataListe(tempArray);
      } else {
        const concatString =
          txtFilter?.length > 0
            ? txtFilter + "," + selectedLabel
            : selectedLabel;
        const dataArr = concatString?.split(",");
        //Remove occurence
        const toStringOccurence = dataArr?.filter((el) => el !== "");

        setTxtFilter(toStringOccurence?.join(","));
        setDataListe(dataArr);
      }
    }
  }, [selectedLabel]);

  const handleFilterSelect = (txt: string) => {
    setDataLabelFilter([]);
    const convertArray = txtFilter.split(",");

    //Check if txt isAlreadyExist
    const isExist = convertArray?.find((el: string) => el === txt);
    //remove last string
    convertArray.pop();
    const reformToString = convertArray.join(",");
    if (!isExist) {
      //Make a ',' in end to handlea auther recherche
      const concatString =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + "," + txt + ","
          : txt + ",";
      setTxtFilter(concatString);
    } else {
      const concat =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + ","
          : "";
      setTxtFilter(concat);
    }
  };

  return {
    communityReducer,
    categorieListeTransorm:
      userReducer?.roles?.indexOf(MODERATOR) !== -1
        ? [
            // initialCategorie,
            ...makeCategorie(communityReducer?.discussionCategories),
            ...makeCategorie(communityReducer?.category_about_community),
          ]
        : [
            // initialCategorie,
            ...makeCategorie(communityReducer?.discussionCategories),
          ],
    categorieList: communityReducer?.discussionCategories,
    setselectedCategorie,
    selectedCategorie,
    object,
    setEditorStatePost,
    setTxtObject,
    stateFormWysywig,
    postDiscussion,
    cancelPostDuscussion,
    labelsCustom,
    emailMe,
    setEmailMe,
    loader,
    selectedLabel,
    handleSelectedLabels,
    userReducer,
    setlabelsCustom,
    promiseOptions,
    labelError,
    postType: postType ? postType : undefined,
    titleError,
    user: {},
    isNotCategorySelected,
    providerUsePostFeturedArticle,
    continuePostFeaturedArticle,
    txtFilter,
    setTxtFilter,
    dataListe,
    dataLabelFilter,
    fullCategoryLabel,
    handleFilterSelect,
    getLables,
    isArticleAndOem: isArticle && isOem,
    mentions,
    history,
    setDataListe,
    updateMention: async (value: any) => {
      let keyWord =
        value?.blocks[0]?.text.includes("@") &&
        value?.blocks[0]?.text?.split("@");
      let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? "";
      if (keyWordText !== "") {
        let response = await postData(
          url?.community.mention + "/create",
          {
            postType: "",
            keyWord: keyWordText,
          },
          userReducer.token
        );
        if (response?.status === 200) {
          setMentions(
            response?.data?.map((rep: any) => {
              return {
                text: rep?.first_name + " " + rep?.last_name,
                value: rep?.first_name + " " + rep?.last_name,
                url: "/community/activity-user/" + rep?.id,
              };
            })
          );
        }
      }

      //register current mention
      let mentionsKeyArray = Object?.keys(value?.entityMap);
      let mentionsData: Array<any> = [];
      mentionsKeyArray.map((key: string) => {
        if (value?.entityMap[key]?.type === "MENTION") {
          mentionsData.push(value?.entityMap[key]?.data?.url?.split("/").pop());
        }
      });
      setRegisteredMentions(mentionsData);
    },
  };
}
