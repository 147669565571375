import React from "react";
import Header from "../../../widget/index";
import "../mergers-acquisitions.scss";
import { NavLink, useHistory } from "react-router-dom";
import UseListing from "./useListing";
import SeeBtn from "../SeeBtn";
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  paginate,
} from "../../../../utils";
import { LoadingAllPage } from "../../../widget/loader";
import { EntityMa } from "../../../../types/Ma";
import Pagination from "../../../widget/use-pagination/pagination";

export default function Listings() {
  const provider = UseListing();
  const history = useHistory();

  const goToAds = (item: EntityMa) => {
    history.push("/ma/ads/" + item.id);
  };
  const dataIndustry = provider.filteredData?.targetedIndustry || [];

  const parseContent = (donnees: any) => {
    const d = JSON.parse(donnees);
    const blocks = d.blocks;

    let allText = "";

    // Parcours de chaque tableau dans blocks
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      const text = block.text;

      // Concaténation des textes
      allText += text;
    }

    const sentenceDelimiters = [".", "!", "?"];
    const customDelimiters = ["..."];
    const delimiterPattern = `[${sentenceDelimiters.join(
      ""
    )}${customDelimiters.join("")}]`;
    const sentences = allText.split(new RegExp(delimiterPattern));
    const filteredSentences = sentences.filter(
      (sentence) => sentence.trim() !== ""
    );

    return filteredSentences.length;
  };
  return (
    <div className="findIsvcontainer programContainer maContainer">
      <Header
        searchText={provider?.searchText}
        handleChange={provider?.setSearchText}
        handleClick={provider?.search}
      />
      {provider.showLoading && <LoadingAllPage />}

      <div className="filterFindIsv">
        <div className="contentFilter">
          <div className="containerBtn block infoProgramContainer">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => provider.lunchFilter()}
            >
              Filter
            </button>
            <NavLink
              to="#"
              className="resetFilter"
              onClick={() => provider.resetFilter()}
            >
              <span className="icon-refresh" />
              Reset filter
            </NavLink>
          </div>
          <div className="block">
            <h3 className="title withChild open">Solution</h3>
            <div className="listCheck">
              {paginate(provider.dataSolution, provider.limitSolution)?.map(
                (el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      id={`sol${index}`}
                      className="custom-control-input"
                      onChange={() => provider.handleSelect(el?.id)}
                      checked={
                        provider.selectedSolution?.find(
                          (selected: any) => selected === el?.id
                        )
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={`sol${index}`}
                      className="custom-control-label"
                    >
                      {el.name}
                    </label>
                  </div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeSolution(p)}
                dataSource={provider.dataSolution}
                limit={provider.limitSolution}
              />
            </div>
          </div>
          <div className="block">
            <h3 className="title withChild open">Industry</h3>
            <div className="listCheck">
              {paginate(
                provider.filteredData?.targetedIndustry,
                provider.limitIndustry
              )?.map((el: any, index: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={`ind${index}`}
                    className="custom-control-input"
                    onChange={() => provider.handleSelectedIndustry(el?.id)}
                    checked={
                      provider.selectedIndustry?.find(
                        (selected: any) => selected === el?.id
                      )
                        ? true
                        : false
                    }
                  />
                  <label
                    htmlFor={`ind${index}`}
                    className="custom-control-label"
                  >
                    {el.name}
                  </label>
                </div>
              ))}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeIndustry(p)}
                dataSource={dataIndustry}
                limit={provider.limitIndustry}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          {(provider?.pagination?.totalItems || 0) > 0 && (
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  Showing <b>{provider?.pagination?.totalItems || 0}</b> ads
                </div>
              </div>
            </div>
          )}
          <div className="scrollContainer">
            <ul className="listOfIsvMatch listEvents">
              {provider.announcing?.map((el: any, index) => (
                <li key={index} onClick={() => goToAds(el)}>
                  <div className="isvLogo">
                    <img src={fileUrl(el?.logo)} alt="" />
                    <div className="descAds">
                      <h6>{el?.companyName}</h6>
                      <span>{el?.country?.country_name}</span>
                    </div>
                  </div>
                  <div className="isvInfo">
                    <div className="infoUnlocked">
                      <div className="calendar">
                        <div className="item ma">
                          <div className="adsInfos">
                            <h3 className="title DIMemberTitle">{el.title}</h3>
                            <div className="infoProgram ma">
                              <div
                                className="detailsAds"
                                dangerouslySetInnerHTML={createMarkup(
                                  convertCommentFromJSONToHTML(
                                    getDescriptionEditorState(el?.content)
                                  )
                                )}
                              />
                            </div>
                            {parseContent(el?.content) > 2 && (
                              <a href="javascript:;" className="seemore ma">
                                <span className="colorInitial">... </span>See
                                more
                              </a>
                            )}
                            <div className="topicAds">
                              {[
                                ...el.featuredSolution,
                                ...el.softwareSolution,
                              ]?.map((soft) => (
                                <span className="topic">{soft.name}</span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoEventContainer">
                        <div>
                          <div className="loader">
                            <div className="loader-result"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="pagginationCategory">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={provider?.paramsAnnounce.page}
                totalCount={provider?.pagination?.totalItems || 0}
                pageSize={10}
                onPageChange={(page: any) => provider?.handlePageClick(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
