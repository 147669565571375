import { useState } from 'react';
import HeaderDiscussion from '../../widget/index';
import './Discussion.scss';
import DiscussionListe from './bloc-discussion/conversation-liste';
import InfoDiscussion from './bloc-discussion/info-discussion/info-discussion';
import Conversation from './bloc-discussion/message/index.';

import UseDiscussion from '../../../controller/tchat-ctr/index';
import { conversationType, getAddMember } from '../../../utils';
import MenuSelectDiscussion from './bloc-discussion/menu-select-discussion';
var _ = require('lodash');

export default function Discussion() {
  const [blockIsShown, setBlockIsShown] = useState(false);
  const [isOpen, setisOpen] = useState(false);

  const UseDiscussionProvider = UseDiscussion();

  const toggleBlock = () => {
    setBlockIsShown(!blockIsShown);
  };

  const discussionType = UseDiscussionProvider?.discussionType;

  const isvMember =
    discussionType === conversationType.isv_to_oem
      ? UseDiscussionProvider.discussionReducer?.isv_to_oem?.infoConversation
          .informations_send_to?.company_to_send?.isv_members
      : discussionType === conversationType.oem_to_isv
      ? UseDiscussionProvider.discussionReducer?.oem_to_isv?.infoConversation
          .informations_send_to?.company_to_send?.isv_members
      : UseDiscussionProvider?.discussionReducer?.isv_to_investor
          ?.infoConversation.informations_send_to?.company_to_send?.isv_members;

  const getCompanyPageMember =
    discussionType === conversationType?.oem_to_isv
      ? UseDiscussionProvider?.companyOemMember
      : UseDiscussionProvider?.companyInvestorMember;

  const getRepresentative =
    discussionType === conversationType?.oem_to_isv
      ? UseDiscussionProvider?.discussionReducer?.oem_to_isv?.infoConversation
          ?.informations_send_to?.program_representatives
      : UseDiscussionProvider?.discussionReducer?.investor_to_isv
          ?.infoConversation?.informations_send_to?.investor_representatives;

  const infoClassificAdds =
    discussionType === conversationType?.entity_classific_adds
      ? UseDiscussionProvider?.discussionReducer?.entity_to_classific_adds
          ?.infoConversation?.informations_send_to?.company_to_send
      : UseDiscussionProvider?.discussionReducer?.classific_adds_to_entity
          ?.infoConversation?.informations_send_to?.company_to_send;

  const infoCorporate =
    discussionType === conversationType?.MA
      ? UseDiscussionProvider?.discussionReducer?.entity_to_corporate
          ?.infoConversation?.informations_send_to?.user_to_send
      : UseDiscussionProvider?.discussionReducer?.entity_to_corporate
          ?.infoConversation?.informations_send_to?.user_to_send;

  const infoAlternative =
    discussionType === conversationType?.alternative
      ? UseDiscussionProvider?.discussionReducer?.alternative_financing
          ?.infoConversation?.informations_send_to?.user_to_send
      : UseDiscussionProvider?.discussionReducer?.alternative_financing
          ?.infoConversation?.informations_send_to?.user_to_send;

  const infoAddsInRigthBloc =
    discussionType === conversationType?.entity_classific_adds
      ? UseDiscussionProvider?.discussionReducer?.entity_to_classific_adds
      : UseDiscussionProvider?.discussionReducer?.classific_adds_to_entity;

  const infoCorporateInRigthBloc =
    discussionType === conversationType?.MA || conversationType?.MA_entity
      ? UseDiscussionProvider?.discussionReducer?.entity_to_corporate
      : UseDiscussionProvider?.discussionReducer?.entity_to_corporate;

  const haveNotDiscussion =
    (UseDiscussionProvider.discussionReducer?.allDiscussion?.length < 1 &&
      UseDiscussionProvider.discussionReducer?.currentInfoToSendMessage
        ?.idConversation === 0) ||
    UseDiscussionProvider.isInSearchFilter;

  const haveNotConversatiionSelected =
    UseDiscussionProvider.discussionReducer?.currentInfoToSendMessage
      ?.idConversation === 0;

  return (
    <div className='discussion-tchat'>
      <HeaderDiscussion
        unreadeTypeNbr={
          UseDiscussionProvider?.discussionReducer.unreadeInCategory
        }
      />
      <div className='container-full head-tchat'>
        <MenuSelectDiscussion
          activeTab={UseDiscussionProvider.activeTab}
          setActiveTab={(p: number) => UseDiscussionProvider.handleTabHeader(p)}
          unreadeTypeNbr={
            UseDiscussionProvider?.discussionReducer.unreadeInCategory
          }
          userType={UseDiscussionProvider.userType}
          nbrRequest={
            UseDiscussionProvider?.discussionReducer.unreadeInCategory?.[
              'NUMBER_REQUEST'
            ]
          }
          nbrArchive={
            UseDiscussionProvider?.discussionReducer.unreadeInCategory?.[
              'NUMBER_ARCHIVE'
            ]
          }
          handleFilter={(type: any) => {
            UseDiscussionProvider?.handleFilter(type);
          }}
          isLoadingConversationMessage={
            UseDiscussionProvider.isLoadingConversationMessage
          }
          isLoadingFetchMessage={UseDiscussionProvider.isLoadingFetchMessage}
          loaderGetInfoDiscussion={
            UseDiscussionProvider.loaderGetInfoDiscussion
          }
        />
        <span
          className={'icon-return open-info' + (!isOpen ? ' active' : '')}
          onClick={() => {
            setisOpen(!isOpen);
            toggleBlock();
          }}
        />
      </div>

      <div className='mx-auto m-t-5 body-tchat-container'>
        <div className={'row' + (blockIsShown ? ' pm-col' : '')}>
          <DiscussionListe
            allDiscussion={
              UseDiscussionProvider?.isSearch
                ? UseDiscussionProvider?.filteConversation
                : UseDiscussionProvider.discussionReducer.allDiscussion
            }
            isSearch={UseDiscussionProvider?.isSearch}
            currentIdConversation={
              UseDiscussionProvider.discussionReducer.currentInfoToSendMessage
                .idConversation
            }
            hasMore={UseDiscussionProvider.hasMoreDiscussion}
            handleClickConversation={(
              type: string,
              userId: string,
              idConversation: number
            ) => {
              if (UseDiscussionProvider.isLoadingConversationMessage) return;
              UseDiscussionProvider?.clickConversationListe(
                type,
                userId,
                idConversation
              );
            }}
            fetchMoreConversation={UseDiscussionProvider?.fetchMoreConversation}
            txtFilter={UseDiscussionProvider?.txtFilter}
            handleFilter={(evt: string) => UseDiscussionProvider.searchTxt(evt)}
            focusSeachInput={() => UseDiscussionProvider?.focusSeachInput()}
            clausedSearch={() => UseDiscussionProvider?.clausedSearch()}
            refConv={UseDiscussionProvider?.refConv}
            searchForMessage={() => UseDiscussionProvider.searchForMessage()}
            isLoadingConversationMessage={
              UseDiscussionProvider.isLoadingConversationMessage
            }
            totalDiscussion={UseDiscussionProvider?.totalDiscussion}
          />

          {(UseDiscussionProvider.discussionReducer.allDiscussion.length > 0 ||
            (UseDiscussionProvider.discussionReducer.allDiscussion.length ==
              0 &&
              !UseDiscussionProvider.discussionReducer.currentInfoToSendMessage
                .isNull)) && (
            <div
              className={`bodyTchat-col ${
                (haveNotDiscussion || haveNotConversatiionSelected) && 'd-none'
              }`}
              id='bodytchat'
            >
              <Conversation
                conversationId={
                  UseDiscussionProvider?.discussionReducer
                    ?.currentInfoToSendMessage?.idConversation
                }
                discussionType={discussionType}
                infoCompanyIsvToOem={
                  UseDiscussionProvider?.discussionReducer?.isv_to_oem
                    .infoConversation?.informations_send_to?.company_to_send
                }
                infoCompanyOemToIsv={
                  UseDiscussionProvider?.discussionReducer?.oem_to_isv
                    .infoConversation?.informations_send_to?.company_to_send
                }
                infoCompanyIsvToInvestor={
                  UseDiscussionProvider?.discussionReducer?.isv_to_investor
                    ?.infoConversation?.informations_send_to?.company_to_send
                }
                infoCompanyInvestorIsv={
                  UseDiscussionProvider?.discussionReducer?.investor_to_isv
                    ?.infoConversation?.informations_send_to?.company_to_send
                }
                infoComunity={
                  UseDiscussionProvider?.discussionReducer?.community
                    ?.infoConversation?.informations_send_to?.user_to_send
                }
                infoCompanyClassificAdds={infoClassificAdds}
                infoCorporate={infoCorporate}
                infoAlternative={infoAlternative}
                openBlock={toggleBlock}
                msgTxt={UseDiscussionProvider.msgTxt}
                handleMsg={(txt: string) =>
                  UseDiscussionProvider.setMsgTxt(txt)
                }
                sendMsg={() => UseDiscussionProvider.sendMsg()}
                allMessage={
                  UseDiscussionProvider?.discussionReducer?.paginateMessage
                    ?.messages
                }
                seeMessage={() => UseDiscussionProvider?.seeLastMessage()}
                chatParent={UseDiscussionProvider.chatParent}
                refFile={UseDiscussionProvider?.refFileMessage}
                handleAddFile={() => UseDiscussionProvider?.handleAddFile()}
                changeHandler={(evt: any) =>
                  UseDiscussionProvider?.changeHandler(evt)
                }
                selectedFile={UseDiscussionProvider?.selectedFile}
                removeFileSelected={(index: number, file: File) =>
                  UseDiscussionProvider?.removeFileSelected(index, file)
                }
                loaderSendFile={UseDiscussionProvider?.loaderSendFile}
                matchingTxt={
                  UseDiscussionProvider.isFocusMainResearch
                    ? UseDiscussionProvider.txtFilterInMessage
                    : UseDiscussionProvider?.txtFilter
                }
                loadOtherMessge={() => UseDiscussionProvider.loadOtherMessge()}
                isLoadingFetchMessage={
                  UseDiscussionProvider.isLoadingFetchMessage
                }
                loadOtherMessageInSearch={(scrollType: string) =>
                  UseDiscussionProvider.loadOtherMessageInSearch(scrollType)
                }
                isSearchTxtMessage={UseDiscussionProvider?.isSearch}
                allFilterTxtMessage={UseDiscussionProvider?.indexOfMatchingMes}
                handleClickUpDown={(p: string) =>
                  UseDiscussionProvider.hanleUPdownClick(p)
                }
                currentIndexFilter={UseDiscussionProvider?.currentIdConvFilter}
                lineRefs={UseDiscussionProvider?.lineRefs}
                txtFilterInMessage={UseDiscussionProvider.txtFilterInMessage}
                setTxtFilterInMessage={(p: string) =>
                  UseDiscussionProvider.setTxtFilterInMessage(p)
                }
                setIsFocusMainResearch={(p: boolean) =>
                  UseDiscussionProvider.setIsFocusMainResearch(p)
                }
                parentHeigth={UseDiscussionProvider.parentHeigth}
                showProfil={(id: string) =>
                  UseDiscussionProvider.showProfil(id)
                }
                isSending={UseDiscussionProvider.isSending}
              />
            </div>
          )}

          {(UseDiscussionProvider.discussionReducer.allDiscussion.length > 0 ||
            (UseDiscussionProvider.discussionReducer.allDiscussion.length ==
              0 &&
              !UseDiscussionProvider.discussionReducer.currentInfoToSendMessage
                .isNull)) && (
            <div className={`${haveNotConversatiionSelected ? 'd-none' : ''}`}>
              <InfoDiscussion
                discussionType={discussionType}
                infoIsvToOem={
                  UseDiscussionProvider?.discussionReducer?.isv_to_oem
                }
                infoOemToIsv={
                  UseDiscussionProvider?.discussionReducer?.oem_to_isv
                }
                isvMember={isvMember}
                infoIsvToInvestor={
                  UseDiscussionProvider?.discussionReducer?.isv_to_investor
                }
                infoInvestorToIsv={
                  UseDiscussionProvider?.discussionReducer?.investor_to_isv
                }
                infoClassificAdds={infoAddsInRigthBloc}
                infoCorporate={infoCorporateInRigthBloc}
                infoCommunity={
                  UseDiscussionProvider?.discussionReducer?.community
                }
                infoAlternative={infoAlternative}
                handleModalAddMember={UseDiscussionProvider?.handleShowModal}
                isShowModalAddMember={
                  UseDiscussionProvider?.isShowModalAddMember
                }
                allUser={getAddMember(getCompanyPageMember, getRepresentative)}
                ckeckUser={UseDiscussionProvider?.ckeckUser}
                allChecked={UseDiscussionProvider?.allIdrepresentatifChecked}
                validAddMember={UseDiscussionProvider?.addNewConversation}
                isLoadingAddMember={UseDiscussionProvider?.isLoadingAddMember}
                removeConversation={() =>
                  UseDiscussionProvider.removeDiscussion()
                }
                currentInfoToSendMessage={
                  UseDiscussionProvider?.discussionReducer
                    ?.currentInfoToSendMessage
                }
                isMuted={
                  UseDiscussionProvider?.currentSelectedConversation?.is_muted
                }
                mutedConversation={UseDiscussionProvider.mutedConversation}
                handleRead={UseDiscussionProvider?.handleRead}
                class={haveNotDiscussion ? 'd-none' : ''}
                setArchive={() => UseDiscussionProvider?.setArchive()}
                allFile={
                  UseDiscussionProvider?.discussionReducer?.allFilePerDiscussion
                }
                showProfil={(id: string) =>
                  UseDiscussionProvider.showProfil(id)
                }
                isArchive={UseDiscussionProvider?.isArchived}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
