import { split, join } from "lodash";
import React from "react";
import getUnanswerdDiscussion from "../../../../../controller/community-ctr/home-page/unanswered-discussion";
import { LoadingArticle } from "../../../../widget/loader";
import { dateDiff } from "./../../../../../utils/index";

const UnanswerdDiscussion = () => {
  const renderLastActivity = (last_activity: string) => {
    const last_splitted = split(last_activity, " ");
    if (parseInt(last_splitted[0]) > 1) last_splitted[1] += "s";
    return join(last_splitted, " ");
  };
  const getUnanswerdDiscussionCtr = { ...getUnanswerdDiscussion() };
  return (
    <>
      <h2 className="title">Unanswered discussions</h2>
      <ul className="separateElement feedList">
        {getUnanswerdDiscussionCtr?.loaderUnanswered ? (
          <LoadingArticle />
        ) : (
          getUnanswerdDiscussionCtr?.unanswerdDiscussion?.map(
            (discussion: any, index: number) => (
              <li key={index}>
                <div className="leftItem txtLabel infoFeed">
                  <div className="titlePost">
                    {discussion?.post_type?.id?.toString() === "2" && (
                      <span className="article">Article</span>
                    )}
                    <span
                      className="title"
                      onClick={() =>
                        getUnanswerdDiscussionCtr?.goToreplayPost(
                          discussion?.category?.id?.toString(),
                          discussion?.id
                        )
                      }
                    >
                      {discussion.title}
                    </span>
                  </div>
                  {discussion?.labels?.map((label: any, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      onClick={() =>
                        getUnanswerdDiscussionCtr.goToLabelCategory(
                          discussion?.category?.id,
                          label?.id,
                          discussion.post_type?.id?.toString()
                        )
                      }
                    >
                      {label?.title}
                    </span>
                  ))}
                </div>
                <div className="rightItem solutionContent unanswered">
                  <b>{dateDiff(discussion.updated_at?.toString())}</b>
                </div>
              </li>
            )
          )
        )}

        {getUnanswerdDiscussionCtr?.unanswerdDiscussion?.length > 0 && (
          <li
            className="linkForLi"
            onClick={() => getUnanswerdDiscussionCtr?.viewAllUnanswered()}
          >
            <a
              href="javascript"
              onClick={(e) => e?.preventDefault()}
              className="viewAll"
            >
              View all
            </a>
          </li>
        )}
      </ul>
    </>
  );
};

export default UnanswerdDiscussion;
