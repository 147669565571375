import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { concatString, isURL, refaCtoNullKey } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export type IpropsModal = {
  dataSource: any;
};
export default function ModalEditAbout(props: IpropsModal) {
  return (
    <Modal
      show={props?.dataSource?.lgShow}
      onHide={() => props?.dataSource?.setLgShow(false)}
      className="modalDevinsider large"
    >
      <Modal.Header closeButton>
        <Modal.Title>About</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0">
          <div className="col-12 descCompany">
            <textarea
              name=""
              value={refaCtoNullKey(
                props?.dataSource?.infoUserToEdit.plain_text,
                ""
              )}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                props?.dataSource?.setInfoUserToEdit({
                  ...props?.dataSource?.infoUserToEdit,
                  plain_text: evt.target.value,
                })
              }
              maxLength={450}
            />
            {props?.dataSource?.infoUserToEdit.plain_text != "null" && (
              <div className="caractercount">
                {`${
                  0 +
                  (props?.dataSource?.infoUserToEdit.plain_text?.length || 0)
                } / 450`}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancelbtn"
          onClick={() => props?.dataSource?.cancelChange()}
        >
          Cancel
        </Button>
        <Button
          className="validate"
          onClick={() => props?.dataSource?.updateUserDetail()}
          disabled={props?.dataSource?.isLoaderShow}
        >
          {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
