import store from "../../redux/store";
import { getDataWithoutToken, postData } from "../api";
import { url } from "../constant";

export async function getSolutions() {
    try {
        const result = await getDataWithoutToken(url?.Ma?.get_solution, {
            itemsPerPage: 5,
          });
          if (result?.status === 200 || result?.status === 201) {
            return result?.data?.segments;
          }
    }catch (error: any) {}
}

export async function getIndustries() {
    try {
        const result = await getDataWithoutToken(url?.Ma?.get_industry, {
            itemsPerPage: 5,
          });
          if (result?.status === 200 || result?.status === 201) {
            return result?.data?.industries;
          }
    }catch (error: any) {}
}

export async function createAlternative(data: any) {
  try {
    const result = await postData(
      url?.alternative?.addAlternative,
      data,
      store && store?.getState()?.userReducer?.token
    );
      
    return result;
  } catch (error: any) {
    return null;
  }
}

export async function updateAlternative(data: any, id:any, callback: any) {
  try {
    const result = await postData(
      url?.alternative?.updateAlternative+'/'+id,
      data,
      store && store?.getState()?.userReducer?.token
    );
    if(result?.status === 200 || result?.status === 201) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error: any) {
    callback(false);
  }
}