import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import "../Top.scss";
import UseViewAllCommunity from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-community";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  isModerator,
} from "../../../../../utils";
import moment from "moment";
// import InfiniteScroll from "react-infinite-scroll-component";
import { ItemDiscussion } from "../../../../../redux/types/community-interface";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import { Link, useHistory } from "react-router-dom";
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg";
import Pagination from "../../../../widget/use-pagination/pagination";
import { LoadingArticle } from "../../../../widget/loader";

export default function ViewAllCommunity() {
  const providerUseViewAllCommunity = UseViewAllCommunity();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isUserPost = (idUser: string) =>
    idUser === providerUseViewAllCommunity?.userObject?.id?.toString();

  const pinnedAndLocked = (userId: string, roles: any, isDelete: boolean) => {
    return (isUserPost(userId) || isModerator(roles)) && !isDelete;
  };

  const history = useHistory();

  const goHome = () => {
    history?.push("/community/home");
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li onClick={() => goHome()}>
            <a href="javascript:;" onClick={(e) => e?.preventDefault()}>
              Community
            </a>
          </li>
          <li>Discussion categories</li>
        </ul>
        <div className="searchTopic">
          <SearchForm
            categories={
              providerUseViewAllCommunity?.communityReducer
                ?.discussionCategories
            }
            filterSearchText={providerUseViewAllCommunity?.filterSearchText}
            handleFilterTxt={providerUseViewAllCommunity?.handleFilterTxt}
            searchText={providerUseViewAllCommunity?.inputText}
            handleCateGory={providerUseViewAllCommunity?.handleCateGory}
          />
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="block">
            <h2 className="title withLink">
              All{" "}
              {providerUseViewAllCommunity?.communityType === "1"
                ? "discussions"
                : providerUseViewAllCommunity?.communityType === "2"
                ? "articles"
                : providerUseViewAllCommunity?.communityType === "3"
                ? "press releases"
                : "program reviews"}
            </h2>
            <div className="blockOfList">
              <ul className="feedList categoryList">
                  {providerUseViewAllCommunity?.loadingPost ? (
                    <LoadingArticle />
                  ) : (
                    providerUseViewAllCommunity?.postsByCurrentPage?.posts.map(
                      (post: ItemDiscussion, index: number) => (
                        <li key={index}>
                          {/* ADD BANNER LOCKED OR PINNED */}
                          {post?.isLocked &&
                            pinnedAndLocked(
                              post?.user?.id?.toString(),
                              providerUseViewAllCommunity.userObject.roles,
                              post?.isDeleted
                            ) && (
                              <img
                                className="pinned-item"
                                src={Locked}
                                alt=""
                              />
                            )}
                          {post?.isPinned && !post?.isLocked && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                          {post?.isLocked &&
                            post?.isPinned &&
                            pinnedAndLocked(
                              post?.user?.id?.toString(),
                              providerUseViewAllCommunity.userObject.roles,
                              post?.isDeleted
                            ) && (
                              <img
                                className="pinned-item"
                                src={Locked}
                                alt=""
                              />
                            )}
                          {post?.isLocked &&
                            post?.isPinned &&
                            !pinnedAndLocked(
                              post?.user?.id?.toString(),
                              providerUseViewAllCommunity.userObject.roles,
                              post?.isDeleted
                            ) && (
                              <img className="pinned-item" src={Pin} alt="" />
                            )}
                          {post?.solved && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check"></span>
                              </div>
                            </div>
                          )}

                          <div className="infoAbout">
                            <div className="withPost">
                              <div className="imageAffiliatedcompany empty">
                                <img
                                  src={fileUrl(post?.user?.file_name)}
                                  alt=""
                                />
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  post?.user?.roles?.[0]
                                )}`}
                              />
                            </div>
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerUseViewAllCommunity.goToreplayPost(
                                    post?.category?.id?.toString(),
                                    post?.id?.toString()
                                  )
                                }
                              >
                                {post?.post_type?.id == 2 && (
                                  <span className="article">Article</span>
                                )}
                                <span className="title">{post?.title}</span>
                              </div>
                              <div className="postBy">
                                by{" "}
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    providerUseViewAllCommunity?.goActivity(
                                      post?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    post?.user?.first_name,
                                    post?.user?.last_name,
                                    " "
                                  )}
                                </Link>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {" "}
                                  {moment(new Date(post?.created_at)).format(
                                    "MMM D, YYYY h:mm a"
                                  )}
                                </Link>{" "}
                                on
                              </div>
                              <div className="postBy">
                                Latest activity {dateDiff(post?.updated_at)} by{" "}
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    providerUseViewAllCommunity?.goActivity(
                                      post?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    post?.last_activity_by?.first_name,
                                    post?.last_activity_by?.last_name,
                                    " "
                                  )}
                                </Link>
                              </div>
                              <div className="subCategory">
                                {post?.labels?.map(
                                  (element: any, index: number) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        providerUseViewAllCommunity.goToLabelCategory(
                                          post?.category?.id?.toString(),
                                          element?.id?.toString()
                                        )
                                      }
                                    >
                                      {element?.title}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{post?.numberOfLikes}</b>Likes
                          </div>
                          <div className="viewInfo">
                            <b>{post?.numberOfViews}</b>views
                          </div>
                        </li>
                      )
                    )
                  )}
                <div className="pagginationCategory fixePagination">
                  <Pagination
                    totalCount={
                      providerUseViewAllCommunity?.postsByCurrentPage.total
                    }
                    currentPage={providerUseViewAllCommunity?.currentPage}
                    className="pagination"
                    idContainer="postByUser"
                    pageSize={providerUseViewAllCommunity?.limit}
                    onPageChange={(response: any) => {
                      providerUseViewAllCommunity?.handlePageChange(response);
                    }}
                  />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
