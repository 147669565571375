import moment from "moment";
import { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import toggleoff from "../../../resources/images/toggle.svg";
import toggleon from "../../../resources/images/toggleon.svg";
import { fileUrl } from "../../../utils";
import { PREMIUM } from "../manage-company-and-billing";
import "./cancel-subscription.scss";

export type IpropsCancelSubscription = {
  show?: boolean;
  program?: any;
  handleShow: (p: boolean) => void;
  handleShowConfirm: (p: boolean) => void;
};

export default function CancelSubscription(props: IpropsCancelSubscription) {
  const allPrograms = useSelector(
    (state: rootState) => state.userReducer.oemProgramMembers
    );
  const indexProgram = allPrograms?.findIndex(object => {
    return object.oem_program?.id === props?.program?.id;
  })+1;
  const confirmUpdate = () => {
    props?.handleShow(false);
    props?.handleShowConfirm(true);
  }

  const priceSplitter = (number: string) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));


  return (
    <Modal
    show={props?.show}
    onHide={() => props?.handleShow(false)}
    className="modalDevinsider medium forDeactivate"
    id="desactivate-subscription"
    >
    <Modal.Header closeButton>
      <Modal.Title>Manage plan</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="contentBorder col m-0 p-0 desactivepopup">
        <h3 className="title-cancel">
          {props?.program?.oem_subscription?.name}{" "}subscription
        </h3>
        <div className="programtodeactivate">
          <div className="imgSv">
            <img
            src={fileUrl(props?.program.photo_profil)}
            alt=""
            />
          </div>
          <div className="infoSv">
            <h3 className="title">
              Program {indexProgram}: {props?.program.program_name}
            </h3>
            <h3 className="subtitle">{props?.program.company_name}</h3>
          </div>
        </div>
        <div className="manage-text">
          {props?.program?.oem_subscription?.amount && <p>${priceSplitter(props?.program?.oem_subscription?.amount)}{" "} USD / Year </p>}
          <p>Start date: {moment(props?.program?.subscription_begin_at).format("DD MMMM YYYY")} </p>
          <p>{props?.program?.oem_subscription?.name == PREMIUM && props?.program?.renew_subscription_enabled ? "Renewal date": "End date"}: {moment(props?.program?.subscription_end_at).format("DD MMMM YYYY")} </p>
          {props?.program?.oem_subscription?.name == PREMIUM && 
            <div className='slidecheck'>
              <div className='custom-checkbox d-flex justify-content-center'>
                <input
                  type='checkbox'
                  id='hideAuthor'
                  className='custom-control-input'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => confirmUpdate()}
                  checked={props?.program?.renew_subscription_enabled}
                />
                <label htmlFor='hideAuthor'>
                  <span role='button' className="d-flex flex-row justify-content-center align-items-center">
                    <span className="mr-2">Automatic renewal : </span>
                    <img className="d-block mx-auto" src={props?.program?.renew_subscription_enabled ? toggleon : toggleoff}  style={{width:"70px"}} alt="toggle-disabled"/>
                  </span>
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
        {/* {props?.program?.renew_subscription_enabled && <Button className="cancelbtn mx-auto" onClick={() => confirmCancel()}>
          Cancel subscription
        </Button>} */}
    </Modal.Footer>
    </Modal>
    );
}
