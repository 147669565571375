import React from 'react';
import PaymentSuccess from "./payment-success";
import PaymentError from "./payment-error";
import {PaymentMethod} from "../use-payment-hook";

type Props = {
    title: string
    invoiceNumber?: string
    invoiceUrl?: string
    show: boolean
    status: 'success' | 'error'
    handleOk?: () => void
    handleClose?: () => void
    paymentMethod: PaymentMethod | undefined
    tryAgain: () => void
}

export const PaymentStatus: React.FC<Props> = ({
    title,
    invoiceNumber,
    show,
    handleOk,
    status,
    handleClose,
    invoiceUrl,
    tryAgain,
    paymentMethod
}) => {
    return (
        <>
            <PaymentSuccess
                title={title}
                invoiceNumber={invoiceNumber}
                invoiceUrl={invoiceUrl}
                show={show && status === 'success'}
                handleOk={handleOk}
            />
            <PaymentError
                title={title}
                show={show && status === 'error'}
                handleClose={handleClose}
                tryAgain={tryAgain}
                paymentMethod={paymentMethod}
            />
        </>
    )
}