/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import "./manage-budget.scss";
import "./monthly-budget.scss";

import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { OemProgram } from "../../../../../controller/oem-ctr/type";
import { ICardInformation } from "../../../../../controller/settings-ctr/billing-center/use-global-billing";
import { rootState } from "../../../../../redux/reducers";
import { getDataMicroService } from "../../../../../service/applicatif/back-end-service";
import { url } from "../../../../../service/constant";
import { BtnNext } from "../../../../widget/bouton/btn-next";
type Iprops = {
  showMonthlyBudget: boolean;
  closeMonthlyBudget: () => void;
  cardInformation: any;
  upGradePlan: () => void;
  handleRegistration: () => void;
  subscriptionPlan: any;
  subscriptionBeginAt: any;
  subscriptionEndAt: any;
  setNumberIsvToUnlock: (p: any) => void;
  numberIsvToUnlock: any;
  totalBudget: any;
  registeringNumberOfUnlock: boolean;
  loadingCardInformation: boolean;
  hasCardRegistered: any;
  oemProgramObject: OemProgram;
};
export default function ModalMounthlyBuget(props: Iprops) {
  const currencies: { [key: string]: string } = {
    usd: "$",
    eur: "€",
  };

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const program = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const usedUnlock =
    props?.oemProgramObject.oemProgramBillingDetails
      .number_unlock_isv_not_in_subscription || 0;

  const [valeurPrecedente, setValeurPrecedente] = useState(
    usedUnlock > props?.numberIsvToUnlock
      ? usedUnlock
      : props?.numberIsvToUnlock
  );
  const [checked, isChecked] = useState(false);
  const [cardInformation, setCardInformation] = useState<ICardInformation>({
    brand: "",
    exp_month: "",
    exp_year: "",
    last4: "",
  });
  const [currentUserHasRegisteredCard, setCurrentUserHasRegisteredCard] =
    useState<boolean>();
  const [currency, setCurrency] = useState<string>("");
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [unlockAmount, setUnlockAmount] = useState<number>(0);
  const [isUnlockAllowed, setIsUnlockAllowed] = useState<boolean>(true);

  useEffect(() => {
    if (props?.showMonthlyBudget) {
      getDataMicroService(
        url.microservice.payment.payment_method,
        { oem_program_id: program.id },
        userReducer.token
      )
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.data) {
              setCurrentUserHasRegisteredCard(true);
              setCardInformation(response.data.data.card);
              setCurrency(response.data.data.currency);
            } else {
              setCurrentUserHasRegisteredCard(false);
            }
          } else {
            throw response.data.data.detail;
          }
        })
        .catch((error) => {
          toast("Error on getting payment information");
          console.log(error);
        });
    }
  }, [program.id, userReducer.token, props?.showMonthlyBudget]);

  useEffect(() => {
    getUnlockAmount(currency);
  }, [currency]);

  useEffect(() => {
    setTotalBudget(0);
    setCurrentUserHasRegisteredCard(undefined);
    setUnlockAmount(0);
    setCurrency("");
  }, [props?.showMonthlyBudget]);

  const getCurrencySymbol = () => {
    return currency ? currencies[currency] : currencies[currency || "usd"];
  };

  const getUnlockAmount = (currency: string) => {
    var lookupKey =
      "OEM_" + program?.oem_subscription?.name?.toUpperCase() + "_UNLOCK_ISV";

    getDataMicroService(
      url.microservice.payment.get_product_prices + lookupKey,
      { oem_program_id: program.id, currency: currency },
      userReducer.token
    )
      .then((response) => {
        if (response.data.message === "success") {
          if (response.data.data) {
            setUnlockAmount(response.data.data.prices[currency]);
            setIsUnlockAllowed(response.data.data.allow_unlock);
          }
        } else {
          throw response.data.data.detail;
        }
      })
      .catch((error) => {
        toast("Error on getting product price");
      });
  };

  useEffect(() => {
    setTotalBudget(props?.numberIsvToUnlock * unlockAmount);

    if (props?.numberIsvToUnlock === 0) {
      isChecked(true);
    }
  }, [props?.numberIsvToUnlock, unlockAmount]);

  const handleDecreaseMaxNumberOfUnlock = () => {
    if (props?.numberIsvToUnlock > 0) {
      if (usedUnlock >= props?.numberIsvToUnlock) {
        return;
      }
      props?.setNumberIsvToUnlock(props?.numberIsvToUnlock - 1);
    }
    if (usedUnlock >= props?.numberIsvToUnlock || checked || !isUnlockAllowed) {
      return;
    }
  };
  const handleIncreaseMaxNumberOfUnlock = () => {
    if (checked || !isUnlockAllowed) {
      return;
    } else {
      props?.setNumberIsvToUnlock(props?.numberIsvToUnlock + 1);
    }
  };
  const handleCheckLimit = () => {
    if (checked) {
      isChecked(false);
      props?.setNumberIsvToUnlock(valeurPrecedente);
    } else {
      isChecked(true);
      if (usedUnlock > props?.numberIsvToUnlock) {
        setValeurPrecedente(usedUnlock);
      } else {
        setValeurPrecedente(props?.numberIsvToUnlock);
      }
      props?.setNumberIsvToUnlock(0);
    }
  };

  return (
    <Modal
      show={props?.showMonthlyBudget}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Monthly budget</h2>
          <a
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.closeMonthlyBudget();
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder forBillingCenter contact">
          <div className="container-head-manage">
            <div className="txt">
              <p>
                {/* Evolution #45976,V2.1 [OEM/Ecosystem user interface] Quelques améliorations d'affichage sur la page "Monthly budget" */}
                Keep control over your expenses with a monthly budget limit. You
                or your representative(s) will be able to unlock ISV pages until
                you reach the maximum budget. You will never be charged more
                than your budget limit.
              </p>
            </div>

            {currentUserHasRegisteredCard === false ? (
              <div className="payement-info">
                Add payment information to set a monthly budget
              </div>
            ) : (
              <>
                <div className="payement-info">
                  <p className="bold title">Payment info</p>
                  {currentUserHasRegisteredCard === undefined ? (
                    " Loading..."
                  ) : (
                    <>
                      <p className="bold">
                        {cardInformation?.brand} XXXX {cardInformation?.last4}
                      </p>
                      <small>
                        Expire {cardInformation?.exp_month}/
                        {cardInformation?.exp_year?.toString().charAt(2)}
                        {cardInformation?.exp_year?.toString().charAt(3)}
                      </small>
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="contentBorder radius p-0 planOem">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 border-bottom info">
                <h6>Subscription plan : {props?.subscriptionPlan} </h6>
                <small>
                  {props?.subscriptionPlan !== "Free"
                    ? props?.subscriptionBeginAt +
                      " - " +
                      props?.subscriptionEndAt
                    : ""}{" "}
                </small>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 border-bottom info">
                <button className="float-right" onClick={props?.upGradePlan}>
                  UPGRADE
                </button>
              </div>
            </div>

            <div className="row unlock-limit">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 border-right info">
                <h6>
                  Monthly unlock limit (renewed on the first day of each month)
                </h6>
                <div className="row unlock-limit">
                  <div className="selector col-lg-3 pl-0">
                    <span
                      className={`input-number-decrement${
                        usedUnlock >= props?.numberIsvToUnlock
                          ? " disabled"
                          : checked
                          ? " disabled"
                          : !isUnlockAllowed
                          ? " disabled"
                          : ""
                      }`}
                      onClick={handleDecreaseMaxNumberOfUnlock}
                    >
                      –
                    </span>
                    <input
                      className="input-number"
                      type="text"
                      value={props?.numberIsvToUnlock}
                      min="0"
                      max="100"
                    />
                    <span
                      className={`input-number-increment${
                        checked
                          ? " disabled"
                          : !isUnlockAllowed
                          ? " disabled"
                          : ""
                      }`}
                      onClick={handleIncreaseMaxNumberOfUnlock}
                    >
                      +
                    </span>
                  </div>
                  <div className="col-lg-9">
                    {" "}
                    {props?.numberIsvToUnlock > 0 ? (
                      <>unlock{props?.numberIsvToUnlock > 1 ? "s" : ""}</>
                    ) : (
                      <>unlock(s) (No limit)</>
                    )}
                  </div>
                  <div className="form-group form-check col-xl-6 col-md-6 col-sm-12">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={checked}
                      onChange={handleCheckLimit}
                      disabled={!isUnlockAllowed ? true : false}
                    />
                    <label htmlFor="" className="form-check-label termsLink">
                      No limit unlock
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 info">
                <h6>Total monthly budget</h6>
                {isNaN(totalBudget) ||
                currentUserHasRegisteredCard === undefined ||
                (currentUserHasRegisteredCard === true && totalBudget == 0) ? (
                  " Loading..."
                ) : (
                  <h5>
                    {props?.numberIsvToUnlock > 0 && totalBudget > 0 ? (
                      <p className="text-align-right">
                        {getCurrencySymbol()} {totalBudget.toFixed(2)}{" "}
                        {currency?.toUpperCase()}{" "}
                        <span className="small color-light-gray">
                          (ex. VAT)
                        </span>
                      </p>
                    ) : (
                      "-"
                    )}
                  </h5>
                )}
                <p className="hidden">visibility:hidden</p>
              </div>
            </div>
          </div>

          <div className="contentBorder text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={props?.closeMonthlyBudget}
            >
              Cancel
            </button>
            {/* Evolution #45976,V2.1 [OEM/Ecosystem user interface] Quelques améliorations d'affichage sur la page "Monthly budget" */}
            <button
              disabled={
                props?.cardInformation === "" ||
                // props.hasCardRegistered === null ||
                // props.hasCardRegistered === ""
                !currentUserHasRegisteredCard
              }
              className="btn btn-devinsider ml-1 px-4 save"
              onClick={() =>
                // props.hasCardRegistered === null ||
                // props.hasCardRegistered === ""
                currentUserHasRegisteredCard
                  ? props?.handleRegistration()
                  : null
              }
            >
              <BtnNext
                text={"Save"}
                showLoader={props?.registeringNumberOfUnlock}
              />
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail row">
          <div className="contentBorder formForEmail text-right col-lg-6"></div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
