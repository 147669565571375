import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getData, getDataWithoutToken } from "../../../../service/api";
import { operationBillingAmount, url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

const OEM_UNLOCK_ISV = "OEM_UNLOCK_ISV";
const UNLOCK_ISV = "UNLOCK_ISV";

export default function UseOemUnlockIsv() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();

  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [amountUnlockIsv, setAmountUnlockIsv] = useState<any>({
    current_price: "",
    instead_of: "",
  });
  const [modalUnlock, setModalUnlock] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );

  // const [buttonUnlockLoader, setButtonUnlockLoader] = useState<boolean>(false);

  const closeUnlockModal = () => {
    setStateUnlock({
      number_left: 0,
      number_max: 0,
      number_unlock_isv_not_in_subscription: 0,
      max_unlock_number: 0,
      number_unlock_isv_max_in_subscription: 0,
      is_monthly_bugdet_setted: 0,
      monthly_budget_reached: false,
    });
    setShowPaymentModal(false);
    setChoiseValue(undefined);
  };

  const [idIsv, setIdIsv] = useState<number | undefined>();

  const showModalUnlock = (id: number) => {
    setShowPaymentModal(true);
    setIdIsv(id);
  };

  const [stateUnlock, setStateUnlock] = useState<{
    number_left: number;
    number_max: number;
    number_unlock_isv_not_in_subscription: number;
    max_unlock_number: number;
    number_unlock_isv_max_in_subscription: number;
    is_monthly_bugdet_setted: number;
    monthly_budget_reached?: boolean;
  }>({
    number_left: 0,
    number_max: 0,
    number_unlock_isv_not_in_subscription: 0,
    max_unlock_number: 0,
    number_unlock_isv_max_in_subscription: 0,
    is_monthly_bugdet_setted: 0,
    monthly_budget_reached: false,
  });

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    if (oemProgram.id && oemProgram.id !== "0")
      (async () => {
        let response = await getData(
          url.settings.billingCenter +
            "/" +
            operationBillingAmount.OEM_UNLOCK_ISV +
            "/" +
            oemProgram.id,
          userReducer.token
        );
        if (response) {
          setAmountUnlockIsv(response.data);
        }
      })();
  }, [oemProgram.id]);

  const goToPaymentModal = (isvId: number) => {
    const lookupKey =
      "OEM" +
      "_" +
      oemProgram?.oem_subscription?.name?.toUpperCase() +
      "_" +
      UNLOCK_ISV;
    setIdIsv(isvId);

    setProduct({
      lookupKey: lookupKey,
      name: "ISV unlock",
    });

    setShowPaymentModal(true);
  };

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );
      if (response?.status === 200) {
        const { oem_program_unlock_isv, oemProgramBillingDetails }: any =
          response.data.free_status;
        const { monthly_budget_reached } = response.data;

        setStateUnlock({
          ...oem_program_unlock_isv,
          ...oemProgramBillingDetails,
          monthly_budget_reached,
        });

        setChargingPlanDetails(false);
        const isHaveFree = oem_program_unlock_isv?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue("FREE");
        } else {
          setChoiseValue("PAY");
        }
      }
    }
    if (showPaymentModal) {
      getPlanDetails();
    }
  }, [currentMonth, showPaymentModal]);

  return {
    modalUnlock,
    closeUnlockModal,
    setModalUnlock,
    showPaymentModal,
    setShowPaymentModal,
    chargingPlanDetails,
    stateUnlock,
    handleChoise,
    choiseValue,
    showModalUnlock,
    idIsv,
    amountUnlockIsv,
    max_unlock_number: stateUnlock.max_unlock_number,
    number_unlock_isv_not_in_subscription:
      stateUnlock.number_unlock_isv_not_in_subscription,
    is_monthly_bugdet_setted: stateUnlock.is_monthly_bugdet_setted,
    monthly_budget_reached: stateUnlock.monthly_budget_reached,
    goToPaymentModal,
    product,
    // buttonUnlockLoader,
    // setButtonUnlockLoader
  };
}
