import { useEffect, useState } from "react";
import { OemProgram } from "../type";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import UseEditRessource from "../../../screen/widget/modal-edit-ressources/use-edit-ressource";
import { updateDrafResourceAction } from "../../../redux/actions/oem-program-action";
import { useLocation } from "react-router-dom";
import { OEM_PROGRAM } from "../../../service/constant";
const queryString = require("query-string");

export default function useOemResources() {
  const [allResources, setAllResources] = useState<any[]>([]);
  const dispatch = useDispatch();
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const location: any = useLocation();
  const parsed = queryString.parse(location.search);
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setIsShowRessource(false);
  };
  const validate = (params: any) => {
    dispatch(updateDrafResourceAction(params, oemProgram.id, cbUpdateDraft));
  };

  const isOtherUserType = userReducer?.roles?.[0] !== OEM_PROGRAM;

  const {
    isEdit,
    setisEdit,
    saveUpdate,
    fileDataSource,
    setFileResource,
    loader,
    setLoader,
    setIsShowRessource,
    removeFile,
    isShowRessource,
    showModalEditRessource,
    closeRessourceModal,
  } = UseEditRessource({
    initialProps: allResources,
    validate: validate,
  });

  useEffect(() => {
    setAllResources(oemProgram?.oemProgramResources);
    return () => {};
  }, [oemProgram?.oemProgramResources, oemProgram.id]);

  return {
    allResources,
    isEdit,
    setisEdit,
    saveUpdate,
    fileDataSource,
    setFileResource,
    loader,
    setLoader,
    setIsShowRessource,
    removeFile,
    isShowRessource,
    showModalEditRessource,
    closeRessourceModal,
    isOtherUserType,
    isPreview: parsed?.preview,
    isEmptyFile: fileDataSource.every(
      (file: any) => file?.file_location === "" && file?.file_name === ""
    ),
  };
}
