import Myevents from "../screen/container/events/myevents";
import Allevents from "../screen/container/events/allevents";
import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from "../service/constant";
export const routesEvents = [
  {
    path: "/events/my-events",
    guard: "private",
    exact: true,
    component: Myevents,
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },
  {
    path: "/events/all-events",
    guard: "public",
    exact: true,
    component: Allevents,
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },
  {
    path: "/events/all-event-moderator",
    guard: "private",
    exact: true,
    component: Myevents,
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },
];
