import { ChangeEvent } from "react";
import Header from "../../widget/index";
import "./FindIsv.scss";
//@ts-ignore
import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import UseMatchingIsv from "../../../controller/find-isv/use-matching-isv-ctr";
import MachingComponent from "../../../hooks/MachingComponent";
import { IsvProgramType } from "../../../interface/isv-type";
import { rootState } from "../../../redux/reducers";
import { INVESTOR, OEM_PROGRAM } from "../../../service/constant";
import { changeNumberValue, fileUrl } from "../../../utils";
import { LoadingMatchResult } from "../../widget/loader";
import Pagination from "../../widget/use-pagination/pagination";
import { PaymentPopup } from "../payment-popup/payment-popup";
import FilterListe from "./bloc-find-isv/filter";
import RenderPercent from "./bloc-find-isv/render-percent";

export default function MatchIsv() {
  const providerMatching = UseMatchingIsv();

  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );
  const currentUser = useSelector((state: rootState) => state.userReducer);

  const userProvider = useSelector((state: rootState) => state.userReducer);
  const hasActiveProgram = userProvider?.oemProgramMembers?.find(
    (program: any) =>
      (program.oem_program?.status === true ||
        program.oem_program?.status === "1") &&
      program.oem_program?.uuid !== ""
  );

  const currentInvestorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  if (
    currentUser.roles.includes(OEM_PROGRAM) &&
    currentOemProgram.program.status &&
    (!providerMatching.isAcceptedMatching ||
      !currentOemProgram.program.validate_by_devinsider)
  ) {
    return <Redirect to={"/profil-not-found"} />;
  }

  if (
    currentUser.roles.includes(OEM_PROGRAM) &&
    (!hasActiveProgram ||
      !currentOemProgram.program.status ||
      currentOemProgram.program.uuid === "")
  ) {
    return <Redirect to={"/profil-not-found"} />;
  }

  if (
    currentUser.roles.includes(INVESTOR) &&
    currentInvestorPage.isv_find_me === true &&
    (!currentInvestorPage.status ||
      (currentInvestorPage.status &&
        (!providerMatching.isAcceptedMatching ||
          !currentInvestorPage.validate_by_devinsider)))
  ) {
    return <Redirect to={"/profil-not-found"} />;
  }

  const totalPercent = (number: number, hasclosed: boolean) => {
    let value = number;

    if (hasclosed) {
      value = 59;
    }

    return value;
  };

  return (
    <MachingComponent>
      <div className="findIsvcontainer">
        <Header reloadFilter={providerMatching.lunchFilter} />
        {providerMatching?.isInvestor && (
          <PaymentPopup
            show={providerMatching?.ProviderinvestorUnlockIsv?.showPaymentModal}
            title={"Unlock isv"}
            setShowPaymentModal={
              providerMatching?.ProviderinvestorUnlockIsv?.setShowPaymentModal
            }
            product={providerMatching?.ProviderinvestorUnlockIsv?.product}
            successCallback={providerMatching?.paymentMethodCallBack}
            free={
              providerMatching?.ProviderinvestorUnlockIsv?.stateUnlock
                ?.number_left
            }
            isPriceInfo={false}
          />
        )}

        {providerMatching?.isOemUser && (
          <PaymentPopup
            show={providerMatching?.ProviderOemUnlockIsv?.showPaymentModal}
            title={"Unlock isv"}
            setShowPaymentModal={
              providerMatching?.ProviderOemUnlockIsv?.setShowPaymentModal
            }
            product={providerMatching?.ProviderOemUnlockIsv?.product}
            successCallback={providerMatching?.paymentMethodCallBack}
            free={
              providerMatching?.ProviderOemUnlockIsv?.stateUnlock.number_left
            }
            isPriceInfo={false}
            monthlyBudgetReached={
              providerMatching?.ProviderOemUnlockIsv?.stateUnlock
                ?.monthly_budget_reached
            }
          />
        )}
        <FilterListe
          sliderValue={providerMatching?.sliderValue}
          setSliderValue={(value: Array<number>) =>
            providerMatching?.setSliderValue(value)
          }
          lunchFilter={providerMatching?.lunchFilter}
          setHideType={(p: string) => providerMatching?.handleHideType(p)}
          hideType={providerMatching?.selectedHideType}
          setCountryType={(p: string) => providerMatching?.handleCountry(p)}
          selectedCountry={providerMatching?.selectedCountry}
          selectedTargetedIndustry={providerMatching?.selectedTargeted}
          setSelectedTargeted={(p: string) =>
            providerMatching?.handleTargeted(p)
          }
          selectedSegment={providerMatching?.selectedSegment}
          setSelectedSegment={(p: string) => providerMatching?.handleSegment(p)}
          selectedAnnual={providerMatching?.selectedAnnual}
          setSelectedAnnual={(p: string) => providerMatching?.handleAnnual(p)}
          selectedCompanySize={providerMatching?.selectedCompanySize}
          setSelectedCompanySize={(p: string) =>
            providerMatching?.handleCompanySize(p)
          }
          resetFilter={() => providerMatching?.resetFilter()}
          userType={providerMatching?.userState?.roles?.[0]}
          selectedFundingRound={providerMatching?.selectedFunding}
          setSelectedFundingRound={(p: string) =>
            providerMatching?.handleFundingRound(p)
          }
          selectedFundraising={providerMatching?.selectedFundraising}
          setSelectedFundraising={(p: string) =>
            providerMatching?.handleFundraising(p)
          }
          filter_type="matching"
          unlocked_left={providerMatching.allIsvMatching.unlocked_left}
        />

        <div className="container mx-auto minHeightwin">
          {providerMatching?.isAccountNotActive ? (
            <div>
              <h1>
                {` You must wait for your ${
                  providerMatching?.isOemUser ? "program" : "investor"
                } page to be validated by a
                devinsider admin before you can access this feature`}
              </h1>
            </div>
          ) : (
            <div className="listOfIsv">
              <div className="headerOfList">
                <div className="leftHead">
                  {typeof providerMatching?.allIsvMatching?.isv !== "string" ? (
                    <div className="number">
                      Showing{" "}
                      <b>
                        {changeNumberValue(
                          providerMatching?.allIsvMatching?.totalDataPaginate
                        )}
                      </b>{" "}
                      ISVs
                    </div>
                  ) : (
                    <></>
                  )}
                  <form
                    action=""
                    onClick={(e) => openBlock(e)}
                    onSubmit={(e) => e?.preventDefault()}
                  >
                    <input
                      placeholder="Search..."
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        providerMatching?.setSearch_name(e?.target?.value)
                      }
                      value={providerMatching?.search_name}
                      onBlur={() =>
                        providerMatching?.search_name?.length <= 0 &&
                        providerMatching?.handleSearche()
                      }
                    />
                  </form>
                </div>
                <div className="rightHead">
                  <div className="sortedby labelSelect">
                    <label>Sorted by :</label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={providerMatching?.filtered}
                      defaultValue={providerMatching?.filtered[0]}
                      classNamePrefix="tri"
                      onChange={(filter: any) =>
                        providerMatching?.handleFilter(filter)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="scrollContainer">
                {providerMatching?.loaderMatching ? (
                  <LoadingMatchResult />
                ) : typeof providerMatching?.allIsvMatching?.isv ===
                  "string" ? (
                  <ul className="listOfIsvMatch">
                    {providerMatching?.allIsvMatching?.isv}
                  </ul>
                ) : (
                  <ul className="listOfIsvMatch">
                    {providerMatching?.allIsvMatching?.isv?.map(
                      (isvMatching: IsvProgramType, index: number) => (
                        <li key={index}>
                          <div
                            className={`isvLogo ${
                              isvMatching?.isBlacklisted &&
                              !isvMatching?.is_unlocked
                                ? "blacklisted"
                                : ""
                            }`}
                          >
                            <img
                              src={fileUrl(isvMatching?.isv?.photo_profil)}
                              alt=""
                            />
                          </div>

                          <div className="isvInfo">
                            <h3 className="title">
                              <span className="name">
                                {isvMatching?.isv?.company_name}
                              </span>
                              {!isvMatching?.is_joining_soon && (
                                <span
                                  className={`${
                                    isvMatching?.is_favorite
                                      ? "icon-star-on"
                                      : "icon-star-off"
                                  }`}
                                  onClick={() =>
                                    providerMatching?.addFavorite(
                                      isvMatching?.isv.id
                                    )
                                  }
                                />
                              )}
                            </h3>
                            <div className="country">
                              <ReactCountryFlag
                                countryCode={isvMatching?.isv?.country?.cc_iso}
                                svg
                                title={isvMatching?.isv?.country?.country_name}
                              />
                              {isvMatching?.isv?.country?.country_name}
                            </div>
                            {isvMatching?.is_joining_soon ? (
                              <>
                                {" "}
                                <div className="matchValue mJoining">
                                  Joining soon
                                </div>
                                <div className="containerAction">
                                  <span className="icon-locked"></span>
                                  Page locked
                                </div>
                              </>
                            ) : (
                              <>
                                <RenderPercent
                                  isUnlock={isvMatching?.is_unlocked}
                                  number={totalPercent(
                                    isvMatching?.matching_value,
                                    isvMatching?.isv?.has_closed_company
                                  )}
                                />

                                {isvMatching?.is_unlocked ? (
                                  <div className="containerAction">
                                    {isvMatching?.isIsvUnlocked ? (
                                      <div className="unlocked ">
                                        ISV request
                                        <br />
                                        {isvMatching?.unlocked_at &&
                                          moment(
                                            new Date(isvMatching?.unlocked_at)
                                          ).format("MMMM D, yyyy")}
                                      </div>
                                    ) : (
                                      <div className="unlocked ">
                                        Unlocked by{" "}
                                        {isvMatching?.user_who_unlocks}
                                        <br />
                                        {isvMatching?.unlocked_at &&
                                          moment(
                                            new Date(isvMatching?.unlocked_at)
                                          ).format("MMMM D, yyyy")}
                                      </div>
                                    )}

                                    {!isvMatching?.isBlacklisted ? (
                                      <div className="btn-group" role="group">
                                        <button
                                          className="cancel btn btn-devinsider"
                                          onClick={() =>
                                            providerMatching?.contactIsv(
                                              isvMatching?.isv?.id
                                            )
                                          }
                                          disabled={
                                            isvMatching?.isv?.has_closed_company
                                          }
                                        >
                                          Contact
                                        </button>
                                        <button
                                          className="cancel btn btn-devinsider"
                                          onClick={() =>
                                            providerMatching?.goToIsvPage(
                                              isvMatching?.isv.id.toString()
                                            )
                                          }
                                        >
                                          View
                                        </button>
                                      </div>
                                    ) : isvMatching?.is_unlocked ? (
                                      <div className="btn-group" role="group">
                                        <button
                                          className="cancel btn btn-devinsider"
                                          onClick={() =>
                                            providerMatching?.contactIsv(
                                              isvMatching?.isv?.id
                                            )
                                          }
                                          disabled={
                                            isvMatching?.isv?.has_closed_company
                                          }
                                        >
                                          Contact
                                        </button>
                                        <button
                                          className="cancel btn btn-devinsider"
                                          onClick={() =>
                                            providerMatching?.goToIsvPage(
                                              isvMatching?.isv.id.toString()
                                            )
                                          }
                                        >
                                          View
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="containerAction">
                                    <div className="lockedPage">
                                      <span className="icon-locked"></span>Page
                                      Locked
                                    </div>
                                    {!isvMatching?.isBlacklisted && (
                                      <div className="btn-group" role="group">
                                        <button
                                          className="cancel btn btn-devinsider"
                                          onClick={() =>
                                            providerMatching?.goToIsvPage(
                                              isvMatching?.isv?.id.toString(),
                                              true
                                            )
                                          }
                                        >
                                          Preview
                                        </button>
                                        <button
                                          className={`cancel btn btn-devinsider ${
                                            isvMatching?.matching_value < 60 &&
                                            "disabled"
                                          } `}
                                          disabled={
                                            isvMatching?.matching_value < 60
                                          }
                                          onClick={() =>
                                            providerMatching?.isOemUser
                                              ? providerMatching?.ProviderOemUnlockIsv.goToPaymentModal(
                                                  isvMatching?.isv?.id
                                                )
                                              : providerMatching?.ProviderinvestorUnlockIsv.goToPaymentModal(
                                                  isvMatching?.isv?.id
                                                )
                                          }
                                        >
                                          Unlock
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              <div className="pagginationCategory fixePagination">
                <div className="pagination">
                  <Pagination
                    className="pagination"
                    currentPage={
                      providerMatching?.allIsvMatching?.currentPage || 1
                    }
                    totalCount={
                      providerMatching?.allIsvMatching?.totalDataPaginate
                    }
                    pageSize={providerMatching?.limit}
                    onPageChange={(page: any) =>
                      providerMatching?.loadMoreIsvMatching(page)
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MachingComponent>
  );
}
