/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import ModalMounthlyBuget from "../../../../screen/container/settings/billing-center/mounthly-budget/modal-mounthly-buget";
import UseModalMounthlyBudget from "../../../../screen/container/settings/billing-center/mounthly-budget/use-modal-mounthly-budget";
import moment from "moment";

interface ISpentBy {
  created_at: string;
  operation_number: string;
  first_name: string;
  last_name: string;
  user_id: string;
}
const OemProgramPlanDetails = (props: any) => {
  const providerMounthly = UseModalMounthlyBudget();
  const history = useHistory();
  const [key, setkey] = useState({
    activeId: "1",
    eventId: "1",
  });

  const nameSubscription =
    props?.free_status?.oem_program_subscription_plan?.subscription_name;

  const renderSpentBy = (spentBy: any) => {
    return spentBy.map((spent: ISpentBy) => {
      return (
        <>
          {spent.operation_number} credit
          {parseInt(spent.operation_number) > 1 ? "s" : ""} spent by{" "}
          <a
            style={{ color: "#1897c8" }}
            onClick={(e) => {
              e.preventDefault();
              history.push("/community/activity-user/" + spent.user_id);
            }}
          >
            {" "}
            {spent.last_name + " " + spent.first_name}
          </a>
          <p>
            <small>
              Spent on{" "}
              {spent.created_at &&
                moment(spent?.created_at).format("MMM D, yyyy")}
            </small>
          </p>
        </>
      );
    });
  };

  const isShowToogle =
    (props?.free_status?.oem_program_unlock_isv?.spent_by &&
      props?.free_status?.oem_program_unlock_isv?.spent_by.length > 0) ||
    (props?.free_status?.oem_program_featured_article?.spent_by &&
      props?.free_status?.oem_program_featured_article?.spent_by.length > 0) ||
    (props?.free_status?.oem_program_event?.spent_by &&
      props?.free_status?.oem_program_event?.spent_by.length > 0) ||
    (props?.free_status?.oem_press_release.spent_by &&
      props?.free_status?.oem_press_release.spent_by.length > 0) ||
    (props?.free_status?.oem_program_corporate_ads.spent_by &&
      props?.free_status?.oem_program_subscription_plan?.subscription_name ===
        "Custom" &&
      props?.free_status?.oem_program_corporate_ads.spent_by.length > 0) ||
    (props?.free_status?.oem_program_marketplace.spent_by &&
      props?.free_status?.oem_program_subscription_plan?.subscription_name ===
        "Custom" &&
      props?.free_status?.oem_program_marketplace.spent_by.length > 0);

  console.log("FREEE", props?.free_status?.oem_program_free_event);
  return (
    <div className="contentBorder radius p-0 planOem position-relative">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 border-bottom info">
          <h6>
            Subscription plan :{" "}
            {
              props?.free_status?.oem_program_subscription_plan
                ?.subscription_name
            }{" "}
            {props?.free_status?.oem_program_subscription_plan
              ?.subscription_name !== "Custom" && (
              <button
                onClick={() => {
                  history.push("/billing-center/subscription-plan");
                }}
              >
                UPGRADE
              </button>
            )}
          </h6>
          {props?.free_status?.oem_program_subscription_plan
            ?.subscription_name !== "Basic" && (
            <small>
              {
                props?.free_status?.oem_program_subscription_plan
                  ?.subscription_duration
              }
            </small>
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-3 border-bottom  info"></div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 p-3 border-right info">
          <div className="tit">
            <h5 className="mb-2">Find ISVs</h5>
            <h5 className="color-orange">
              {" "}
              {props.free_status?.oem_program_unlock_isv?.number_left} out of{" "}
              {props.free_status?.oemProgramBillingDetails
                ?.number_unlock_isv_max_in_subscription &&
              props.free_status?.oemProgramBillingDetails
                ?.number_unlock_isv_max_in_subscription >
                props.free_status?.oem_program_unlock_isv?.number_left
                ? props.free_status?.oemProgramBillingDetails
                    ?.number_unlock_isv_max_in_subscription
                : props.free_status?.oem_program_unlock_isv?.number_left}{" "}
            </h5>
            <p>Free ISV unlocks available</p>
          </div>
          <Accordion className="accordionForSetting" activeKey={key.eventId}>
            <div className="spentISV spen">
              <Accordion.Collapse eventKey="1">
                <div className="panel-body row">
                  <div className="info">
                    <div className="spent">
                      {props?.free_status?.oem_program_unlock_isv.spent_by ? (
                        renderSpentBy(
                          props?.free_status?.oem_program_unlock_isv.spent_by
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <br />
          <div>
            <h5 className="color-orange">
              {" "}
              {
                props.free_status?.oem_program_unlock_isv
                  ?.number_unlock_isv_not_in_subscription
              }{" "}
            </h5>
            <p>ISV pages unlocked on a pay-per-unlock basis this month</p>
            <button
              className="m-0 p-0"
              style={{ border: "0px" }}
              onClick={() => providerMounthly.showModalMounthly()}
            >
              Manage monthly budget
            </button>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 p-3 info">
          <div className="tit">
            <h5 className="mb-2">Featured articles</h5>
            <p>
              {props?.free_status?.oem_program_featured_article?.length !== 0 &&
              nameSubscription != "Basic" ? (
                <>
                  <h5 className="color-orange">
                    {props?.free_status?.oem_program_featured_article
                      ?.number_left +
                      " out of " +
                      (props?.free_status?.oem_program_featured_article
                        ?.number_max &&
                      props?.free_status?.oem_program_featured_article
                        ?.number_max >
                        props?.free_status?.oem_program_featured_article
                          ?.number_left
                        ? props?.free_status?.oem_program_featured_article
                            ?.number_max
                        : props?.free_status?.oem_program_featured_article
                            ?.number_left)}
                  </h5>
                  <p>Featured article credits available</p>
                </>
              ) : (
                <>
                  Upgrade your plan to publish <br />
                  featured articles
                </>
              )}
            </p>

            <p>
              {props?.free_status?.oem_program_featured_article?.length !== 0 &&
              nameSubscription !== "Basic" ? (
                <small />
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-0 mt-3 info">
                    <h6>
                      {props?.free_status?.oem_program_subscription_plan
                        ?.subscription_name !== "Custom" && (
                        <button
                          className="ml-0 mt-2"
                          onClick={() => {
                            history.push("/billing-center/subscription-plan");
                          }}
                        >
                          UPGRADE
                        </button>
                      )}
                    </h6>
                  </div>
                </>
              )}
            </p>
          </div>
          <Accordion className="accordionForSetting" activeKey={key.eventId}>
            <div className="spentFeatured spen">
              <Accordion.Collapse eventKey="1">
                <div className="panel-body row">
                  <div className="info">
                    <div className="spent">
                      {props?.free_status?.oem_program_featured_article
                        .spent_by ? (
                        renderSpentBy(
                          props?.free_status?.oem_program_featured_article
                            .spent_by
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>

          {props?.free_status?.oem_program_subscription_plan
            ?.subscription_name === "Custom" && (
            <>
              <div className="tit mt-4">
                <h5 className="mb-2">Marketplace ads</h5>
                <p>
                  <h5 className="color-orange">
                    {props?.free_status?.oem_program_marketplace?.number_left +
                      " out of " +
                      (props?.free_status?.oem_program_marketplace
                        ?.number_max &&
                      props?.free_status?.oem_program_marketplace?.number_max >
                        props?.free_status?.oem_program_marketplace?.number_left
                        ? props?.free_status?.oem_program_marketplace
                            ?.number_max
                        : props?.free_status?.oem_program_marketplace
                            ?.number_left)}
                  </h5>
                  <p>Marketplace ad credits available</p>{" "}
                </p>
              </div>
              <Accordion
                className="accordionForSetting"
                activeKey={key.eventId}
              >
                <div className="">
                  <Accordion.Collapse eventKey="1">
                    <div className="panel-body row">
                      <div className="info">
                        <div className="spent">
                          {props?.free_status?.oem_program_marketplace
                            .spent_by ? (
                            renderSpentBy(
                              props?.free_status?.oem_program_marketplace
                                .spent_by
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </>
          )}

          <div className="tit mt-4">
            <h5 className="mb-2">Events</h5>

            <p>
              {props?.free_status?.oem_program_free_event?.length !== 0 ? (
                <>
                  <h5 className="color-orange">
                    {props?.free_status?.oem_program_free_event?.number_left +
                      " out of " +
                      (props?.free_status?.oem_program_free_event?.number_max &&
                      props?.free_status?.oem_program_free_event?.number_max >
                        props?.free_status?.oem_program_free_event?.number_left
                        ? props?.free_status?.oem_program_free_event?.number_max
                        : props?.free_status?.oem_program_free_event
                            ?.number_left)}
                  </h5>
                  <p>Event credits available</p>{" "}
                </>
              ) : (
                <>
                  Upgrade your plan or purchase an event slot to publish your
                  event(s)
                </>
              )}
            </p>
            <small>
              {props?.free_status?.oem_program_featured_article?.length !==
              0 ? (
                <></>
              ) : (
                <>
                  <div className="col-md-12 col-12 pl-0 mt-3 info">
                    <h6 className="d-flex">
                      <button
                        className="ml-0 mt-2"
                        onClick={() => {
                          history.push("/billing-center/subscription-plan");
                        }}
                      >
                        {props?.free_status?.oem_program_subscription_plan
                          ?.subscription_name === "Custom"
                          ? ""
                          : "UPGRADE"}
                      </button>
                      <button
                        className="ml-2 mt-2"
                        onClick={() =>
                          history.push("/events/all-events?post=1")
                        }
                      >
                        PURCHASE
                      </button>
                    </h6>
                  </div>
                </>
              )}
            </small>
          </div>
          <Accordion className="accordionForSetting" activeKey={key.eventId}>
            <div className="">
              <Accordion.Collapse eventKey="1">
                <div className="panel-body row">
                  <div className="info">
                    <div className="spent">
                      {props?.free_status?.oem_program_free_event?.spent_by ? (
                        renderSpentBy(
                          props?.free_status?.oem_program_free_event?.spent_by
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 p-3 info">
          <div className="tit">
            <h5 className="mb-2">Press releases</h5>

            <p>
              {props?.free_status?.oem_press_release.length !== 0 &&
              nameSubscription != "Basic" ? (
                <>
                  <h5 className="color-orange">
                    {props?.free_status?.oem_press_release?.number_left +
                      " out of " +
                      (props?.free_status?.oem_press_release?.number_max &&
                      props?.free_status?.oem_press_release?.number_max >
                        props?.free_status?.oem_press_release?.number_left
                        ? props?.free_status?.oem_press_release?.number_max
                        : props?.free_status?.oem_press_release?.number_left)}
                  </h5>
                  <p>Press release credits available</p>
                </>
              ) : (
                <>
                  Upgrade your plan to <br />
                  publish press releases
                </>
              )}
            </p>
            <small>
              {props?.free_status?.oem_program_featured_article?.length !== 0 &&
              nameSubscription != "Basic" ? (
                <></>
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-0 mt-3 info">
                    <h6>
                      <button className="ml-0">UPGRADE</button>
                    </h6>
                  </div>
                </>
              )}
            </small>
          </div>
          <Accordion className="accordionForSetting" activeKey={key.eventId}>
            <div className="spentPR spen">
              <Accordion.Collapse eventKey="1">
                <div className="panel-body row">
                  <div className="info">
                    <div className="info">
                      <div className="spent">
                        {props?.free_status?.oem_press_release.spent_by ? (
                          renderSpentBy(
                            props?.free_status?.oem_press_release.spent_by
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>

          {props?.free_status?.oem_program_subscription_plan
            ?.subscription_name === "Custom" && (
            <>
              <div className="tit mt-4">
                <h5 className="mb-2">Corporate development ads</h5>
                <p>
                  <h5 className="color-orange">
                    {props?.free_status?.oem_program_corporate_ads
                      ?.number_left +
                      " out of " +
                      (props?.free_status?.oem_program_corporate_ads
                        ?.number_max &&
                      props?.free_status?.oem_program_corporate_ads
                        ?.number_max >
                        props?.free_status?.oem_program_corporate_ads
                          ?.number_left
                        ? props?.free_status?.oem_program_corporate_ads
                            ?.number_max
                        : props?.free_status?.oem_program_corporate_ads
                            ?.number_left)}
                  </h5>
                  <p>Corporate development ad credits available</p>{" "}
                </p>
              </div>
              <Accordion
                className="accordionForSetting"
                activeKey={key.eventId}
              >
                <div className="">
                  <Accordion.Collapse eventKey="1">
                    <div className="panel-body row">
                      <div className="info">
                        <div className="spent">
                          {props?.free_status?.oem_program_corporate_ads
                            .spent_by ? (
                            renderSpentBy(
                              props?.free_status?.oem_program_corporate_ads
                                .spent_by
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </>
          )}
        </div>
      </div>
      <ModalMounthlyBuget {...providerMounthly} />
      <Accordion className="accordionForSetting oemAcc" activeKey={key.eventId}>
        {isShowToogle ? (
          <div className="lineAccordion">
            <Accordion.Toggle
              eventKey="1"
              onClick={() => {
                if (key.activeId == "1") {
                  setkey({ eventId: "0", activeId: "0" });
                } else {
                  setkey({ activeId: "1", eventId: "1" });
                }
                // toggleActive(1);
              }}
              className={`btnAccordion ${key.activeId === "1" ? "active" : ""}`}
            />
          </div>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};

export default OemProgramPlanDetails;
