import Modal from 'react-bootstrap/Modal';
import HowBillingWorks from './how-billing-works';
import RegisteredCard from './registered-card';
import RemoveCard from './remove-card';

import {
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from '../../../../../service/constant';
import {PaymentBrands} from "../../../payment-popup/payment-card/payment-brands";
import {CardRegistrationInputWrapper} from "./card-registration-input-wrapper";
import usePaymentMethodHook from "./use-payment-method";
import {ConfirmPay} from "./confirm-pay";
import React from "react";

const PaymentMethodInfo = (props: any) => {
  const {
    txtMessage,
    isOem,
    isBillingOwner,
    currentUserHasRegisteredCard,
    removeCard,
    showPopRemoveCard,
    setRemoveCard,
    loadingCardInformation,
    cardInformation,
    successUpdate,
    oemProgram,
    closePopRemoveCard,
    showRemoveCard,
    getEntityTypeId,
    unpaidInvoice,
    setUnpaidInvoice,
    setPaymentMethodId,
    showConfirmPayment,
    setShowConfirmPayment,
    confirmPay,
    postNewCard,
    showButtonLoader,
    setShowButtonLoader
  } = usePaymentMethodHook(props.setShowModalPaymentInfo)

  return (
    <>
      <div className='itemBilling'>
        <h2 className='title'>Payment information</h2>
        <div className='contentItem'>
          <div className='itemctt'>
            <b>{txtMessage.carteInfo}</b>
          </div>
          <div className='footerItem' style={{ fontSize: '14px' }}>
            <a
              onClick={
                (isOem &&
                  [
                    OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                    OEM_PROGRAM_EDITOR,
                  ].indexOf(props?.roleUserIncurrentProgram) !== -1) ||
                (isOem && !isBillingOwner)
                  ? props.setShowModalPermissionOem
                  : () => props.setShowModalPaymentInfo(true)
              }
            >
              {txtMessage.addOrUpdate}
            </a>
          </div>
        </div>
      </div>
      {/* MODAL */}
      <Modal
        show={props.showModalPaymentInfo}
        className='createCompanypopup large forStep5custom'
        style={{display: showConfirmPayment ? 'none' : 'block'}}
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Payment information</h2>
            <a className='close' onClick={() => props.setShowModalPaymentInfo(false)}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          {isOem && (
            <div className='contentBorder row forPaiement radius flex-column mb-4'>
              <HowBillingWorks />
            </div>
          )}
          {currentUserHasRegisteredCard && (
            <RegisteredCard
              removeCard={removeCard}
              showPopRemoveCard={showPopRemoveCard}
              setRemoveCard={setRemoveCard}
              loadingCardInformation={loadingCardInformation}
              cardInformation={cardInformation}
            />
          )}

          {!currentUserHasRegisteredCard && (
            <div className='contentBorder forBillingCenter radius pt-3'>
              <div className='checkCard pl-3'>
                <b>Payment</b>
                <PaymentBrands />
              </div>

              <div className='name col-12'>
                <CardRegistrationInputWrapper
                    oem={{
                      isOem: isOem,
                      id: isOem ? oemProgram.program.id: null
                    }}
                    setShowModalPaymentInfo={props.setShowModalPaymentInfo}
                    setPaymentMethodId={setPaymentMethodId}
                    setShowConfirmPayment={setShowConfirmPayment}
                    setUnpaidInvoice={setUnpaidInvoice}
                    postNewCard={postNewCard}
                    setShowButtonLoader={setShowButtonLoader}
                    showButtonLoader={showButtonLoader}/>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <RemoveCard
        closePopRemoveCard={closePopRemoveCard}
        showRemoveCard={showRemoveCard}
        entityDetails={getEntityTypeId()}
        cardInformation={cardInformation}
        closePaymentInformationModal={() => props.setShowModalPaymentInfo(false)}
        successUpdate={successUpdate}
      />
      {unpaidInvoice &&
          <ConfirmPay
              show={showConfirmPayment}
              closeModal={() => setShowConfirmPayment(false)}
              confirm={() => {
                setShowConfirmPayment(false)
                confirmPay()
              }}
              invoice={unpaidInvoice}
          />
      }
    </>
  );
};

export default PaymentMethodInfo;
