import React, { ChangeEvent } from "react";
import UseCreate from "../../../../controller/account-ctr/create-oem-account/complete-process-affilliated-account/use-photo";
import { getMemberDate } from "../../../../utils";
import Header from "../../../widget/header-logo-center/header-logo-center";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import { ReactComponent as LoaderWhite } from "../../../../resources/images/Rolling-white.svg";

export default function Index3() {
  const createCtr = UseCreate();

  return (
    <div>
      <Header />
      <form className="getstartedContainer">
        <div className="head-getstarted">
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              createCtr.onBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Almost done! Upload your profile picture.</h1>
        </div>
        <div className="contentBorder row align-items-stretch small-container">
          <div className="col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated">
            <a
              className="upload-photo"
              onClick={(e) => {
                e.preventDefault();
                createCtr.handleBtn();
              }}
            >
              {createCtr?.selectedFile && (
                <img
                  src={URL.createObjectURL(createCtr?.selectedFile)}
                  alt=""
                />
              )}
            </a>
            <input
              type="file"
              accept="image/png, image/jpeg, image/*"
              ref={createCtr?.refFile}
              style={{
                top: 0,
                left: 0,
                display: "none",
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                createCtr?.handleFileProfile(event);
              }}
            />
            <div className="info-final-getstarted">
              <div className="nom">
                {createCtr.first_name + " " + createCtr.last_name}
              </div>
              <div className="post">{createCtr.job_title}</div>
              <div className="post">
                <b>{createCtr.company_name}</b>
              </div>
              <div className="membersince">{getMemberDate()}</div>
            </div>
          </div>
          <div className="col-xl-5 col-md-6 col-12 d-flex align-items-center justify-content-end py-4 p-0">
            <a
              className="link-add-photo"
              onClick={(e) => {
                e.preventDefault();
                createCtr.handleBtn();
              }}
            >
              Add photo{" "}
            </a>
          </div>
        </div>
        <div className="contentBorder row align-items-stretch">
          <div className="col-12 d-flex align-items-center flex-column p-0">
            <button
              className="btn btn-devinsider large"
              onClick={() => createCtr.onSubmit()}
              type="button"
              disabled={createCtr.loading}
            >
              {createCtr.loading ? <Loader /> : "Next"}
            </button>
            <a
              className="skip"
              onClick={(e: any) => {
                e?.preventDefault();
                !createCtr.loading && createCtr.onSkip();
              }}
            >
              {createCtr?.loadingSkip ? <LoaderWhite /> : "Skip"}
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}
