/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { updateCompanyPageAction } from '../../../../redux/actions/company-page-isv-action/current_isv_action';

export default function usePitch() {
  const dispatch = useDispatch();

  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  /*********************************************
   *
   * Company page  to edit in pitch
   */
  const [isShowPitch, setIsShowPitch] = useState<boolean>(false);

  const [valueOfPitchForm, setValueOfPitchForm] = useState<string>('');
  const [isShowPitchPoint, setIsShowPitchPoint] = useState<boolean>(false);
  const [pitchPointValueForm, setPitchPointValueForm] = useState<string>('');
  const [isShowKeyCompetitive, setIsShowKeyCompetitive] =
    useState<boolean>(false);
  const [valueOfKeyCompetitiveForm, setValueOfKeyCompetitiveForm] =
    useState<string>('');
  const [valueOfKeyCompetitive, setValueOfKeyCompetitive] = useState<string>(
    companyPageObject?.key_competitive_differentiator_pitch || ''
  );
  const [isShowTeamUnique, setIsShowTeamUnique] = useState<boolean>(false);
  const [valueOfTeamUniqueForm, setValueOfTeamUniqueForm] =
    useState<string>('');
  const [valueOfTeamUnique, setValueOfTeamUnique] = useState<string>(
    companyPageObject?.team_differentiator_pitch || ''
  );

  const [isShowModalFile, setIsShowModalFile] = useState<boolean>(false);

  const fileRefDoc: React.RefObject<HTMLInputElement> = useRef(null);

  const [selectedFileDoc, setSelectedFile] = useState<File>();
  const [isFilePickedDoc, setIsFilePicked] = useState(false);

  const [fileName, setFileName] = useState<string>('');
  const [loaderPitch, setLoaderPitch] = useState<boolean>(false);
  const [isShowNotif, setShowNotif] = useState<boolean>(false);
  const [notifNotCompleted, setNotifNotCompleted] = useState<boolean>(false);
  const [keyPitch, setkeyPitch] = useState<string>('');
  const changeHandlerDoc = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        setSelectedFile(event?.target?.files[0]);
        setIsFilePicked(true);
        setFileName(event?.target?.files[0].name);
      }
    }
  };

  const handleDropDoc = (file: any) => {
    setSelectedFile(file[0]);
    setIsFilePicked(true);
    setFileName(file[0]?.name);
  };

  const cancelChangeFile = () => {
    setSelectedFile(undefined);
    setIsFilePicked(false);
    setIsShowModalFile(false);
  };

  const handleBtnDoc = () => {
    if (fileRefDoc) {
      fileRefDoc?.current?.click();
      // setIsFilePicked(false);
    }
  };
  /***********************************
   *
   * Update Pitch point
   */
  const cbUpdatePicth = (response: any) => {
    if (response && response?.status === 200) {
      setLoaderPitch(false);
      if (isShowPitch) {
        setIsShowPitch(false);
        setShowNotif(true);
        setNotifNotCompleted(false);
      }
      if (isShowPitchPoint) {
        setIsShowPitchPoint(false);
      }
      if (isShowKeyCompetitive) {
        setIsShowKeyCompetitive(false);
      }
      if (isShowTeamUnique) {
        setIsShowTeamUnique(false);
      }
      if (isShowModalFile) {
        setIsShowModalFile(false);
      }
    }
  };

  const updatePicth = (key?: string) => {
    let params = {
      description: valueOfPitchForm ? valueOfPitchForm : '',
      paintPointPitch: pitchPointValueForm ? pitchPointValueForm : '',
      keyCompetitiveDifferentiatorPitch: valueOfKeyCompetitiveForm
        ? valueOfKeyCompetitiveForm
        : '',
      teamDifferentiatorPitch: valueOfTeamUniqueForm
        ? valueOfTeamUniqueForm
        : '',
      pitchFile: selectedFileDoc,
    };
    setLoaderPitch(true);
    if (key === 'pitch') {
      setkeyPitch(key);
    }
    dispatch(
      updateCompanyPageAction(params, companyPageObject.id, cbUpdatePicth)
    );
  };

  useEffect(() => {
    setValueOfPitchForm(companyPageObject?.description || '');
    setPitchPointValueForm(companyPageObject?.paint_point_pitch || '');
    setValueOfKeyCompetitiveForm(
      companyPageObject?.key_competitive_differentiator_pitch || ''
    );
    setValueOfTeamUniqueForm(
      companyPageObject?.team_differentiator_pitch || ''
    );
    return () => {};
  }, [companyPageObject]);

  const showErrorNotCompleted = () => {
    if (companyPageObject?.totalProgress < 99) {
      setShowNotif(true);
      setNotifNotCompleted(true);
    }
  };

  return {
    notifNotCompleted,
    isShowNotif,
    setShowNotif,
    isShowPitch,
    setIsShowPitch,
    valueOfPitchForm,
    setValueOfPitchForm,
    updatePicth,
    isShowPitchPoint,
    setIsShowPitchPoint,
    pitchPointValueForm,
    setPitchPointValueForm,
    isShowKeyCompetitive,
    setIsShowKeyCompetitive,
    valueOfKeyCompetitiveForm,
    setValueOfKeyCompetitiveForm,
    valueOfKeyCompetitive,
    isShowTeamUnique,
    setIsShowTeamUnique,
    valueOfTeamUniqueForm,
    setValueOfTeamUniqueForm,
    valueOfTeamUnique,
    fileRefDoc,
    changeHandlerDoc,
    handleBtnDoc,
    isFilePickedDoc,
    selectedFileDoc,
    fileName,
    handleDropDoc,
    loaderPitch,
    isShowModalFile,
    setIsShowModalFile,
    showErrorNotCompleted,
    cancelChangeFile,
  };
}
