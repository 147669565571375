import React, { useState, useEffect } from 'react';
import { getSStorage, isInObject, isUrlLinkDean } from '../../../utils';
import * as T from '../../../screen/container/investor/profil-investor/types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { updateDraftDataAction } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { useParams } from 'react-router-dom';

export const UPDATE_OR_PUBLISH_DRAFT__DATA = "UPDATE_OR_PUBLISH_DRAFT__DATA";

export default function UseEditInfoDetailRigthCtr() {
  const [showEditInfoRigth, setShowEditInfoRigth] = useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );
  const dispatch = useDispatch();
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || '{}');

  const [loader, setLoader] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormRigth>({
    mode: 'onSubmit',
  });
  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setShowEditInfoRigth(false);
    dispatch({
      type: UPDATE_OR_PUBLISH_DRAFT__DATA,
      payload: {
        isupdated: true
      },
    });
  };

  const investorTypeWatch = watch('investorType');
  const linkDeanValue = watch('linkDean');

  const saveForm = async (data: T.FormRigth) => {
    setLoader(true);
    let lastValue = JSON.parse(CP_Investor?.investor_page_draft || '{}');

    let newValue = {
      investor_type: {
        id: data?.investorType,
        name: dataCompleted?.institutionalInvestorType?.find(
          (el: any) => el?.id == data?.investorType
        )?.name,
      },
      number_of_members: data?.investorType == 1 ? '' : data?.numberMember,
      website: data?.webstite,
      linkedin: data?.linkDean,
      country: {
        id: data?.location,
        country_name: dataCompleted?.country?.find(
          (el: any) => el?.id == data?.location
        )?.country_name,
      },
      location: data?.city,
    };

    dispatch(
      updateDraftDataAction(
        {
          investorPageDraft: JSON.stringify(Object.assign(lastValue, newValue)),
        },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  const valueOfSelect = watch('investorType');

  /**
   * Initialise foundraising goal
   */
  useEffect(() => {
    setValue(
      'webstite',
      isInObject(CP_DRAFT_DATA, 'website')
        ? CP_DRAFT_DATA?.website
        : CP_Investor?.website
    );
    setValue(
      'city',
      isInObject(CP_DRAFT_DATA, 'city')
        ? CP_DRAFT_DATA?.location
        : CP_Investor?.location
    );

    setValue(
      'investorType',
      CP_DRAFT_DATA?.institutional_investor_type
        ? CP_DRAFT_DATA?.institutional_investor_type?.id
        : CP_Investor?.institutional_investor_type?.id
    );

    setValue(
      'linkDean',
      isInObject(CP_DRAFT_DATA, 'linkedin')
        ? CP_DRAFT_DATA?.linkedin
        : CP_Investor?.linkedin
    );

    setValue(
      'location',
      isInObject(CP_DRAFT_DATA, 'country')
        ? CP_DRAFT_DATA?.country?.id
        : CP_Investor?.country?.id
    );

    setValue(
      'numberMember',
      isInObject(CP_DRAFT_DATA, 'number_of_members')
        ? CP_DRAFT_DATA?.number_of_members
        : CP_Investor?.number_of_members
    );

    return () => {};
  }, [CP_Investor?.investor_type, params?.id]);

  useEffect(() => {
    if (investorTypeWatch == 1) {
      setValue('numberMember', 0);
    }
    return () => {};
  }, [investorTypeWatch]);

  useEffect(() => {
    const { status, value } = isUrlLinkDean(linkDeanValue);
    if (status) {
      setValue('linkDean', value);
    }
    return () => {};
  }, [linkDeanValue]);

  return {
    showEditInfoRigth,
    setShowEditInfoRigth,
    dataCompleted,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    saveForm,
    valueOfSelect,
    loader,
  };
}
