import React, { useState, useEffect } from "react";
import { IDataResource } from "./type";
import { rootState } from "../../../redux/reducers";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { INVESTOR, OEM_PROGRAM } from "../../../service/constant";
const queryString = require("query-string");

export const UPDATE_OR_PUBLISH_DRAFT__DATA = "UPDATE_OR_PUBLISH_DRAFT__DATA";
export const PUPBLISH_OR_UPDATE = "PUPBLISH_OR_UPDATE";

export default function UseEditResourceCtr(props: {
  initialProps: any;
  validate: (params: any) => void;
}) {
  const [isShowRessource, setIsShowRessource] = useState<boolean>(false);
  const [fileDataSource, setFileDataSource] = useState<Array<IDataResource>>(
    []
  );
  const location: any = useLocation();

  const parsed = queryString.parse(location.search);

  const isPreviewOem = parsed?.preview;

  const userObject = useSelector((state: rootState) => state?.userReducer);
  const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");

  const isOem = role?.[0] === OEM_PROGRAM;
  const isInvestor = role?.[0] === INVESTOR;

  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(false);

  const [isEdit, setisEdit] = useState<boolean>(false);

  const initialiseRessource = () => {
    let draftRessource = props?.initialProps?.filter(
      (el: any) => el?.draft === true
    );

    if (isPreviewOem && isOem) {
      draftRessource = props?.initialProps?.filter(
        (el: any) => el?.draft === true
      );
    }

    if (isOtherUser && !isOem) {
      draftRessource = props?.initialProps?.filter(
        (el: any) => el?.draft === false && el.file_location && el.file_name
      );
    }
    if (!isOtherUser) {
      draftRessource = props?.initialProps?.filter(
        (el: any) => el?.draft === true
      );
    }
    // let draftRessource = props?.initialProps?.filter((el: any) =>
    //   isOtherUser ? el?.draft === false : el?.draft === true
    // );

    /**
     * Initialise draft with response value
     */
    let valueStoreResourceEdit = draftRessource?.map((el: IDataResource) => ({
      ...el,
      dataFile: "",
      isChange: false,
    }));
    setFileDataSource(valueStoreResourceEdit);
  };

  useEffect(() => {
    initialiseRessource();
    return () => {};
  }, [props?.initialProps]);

  const saveUpdate = () => {
    setLoader(true);
    /**
     * get file object in file change
     */
    let fileChange = fileDataSource?.map((el: any) => el?.dataFile);
    /**
     *get data  change
     */
    let stringChange = fileDataSource?.map((el: any, index: number) => {
      if (el?.isChange === true) {
        return {
          id: el?.id,
          file: el?.dataFile != "" ? index : "",
        };
      }
    });

    //remove undefined data array
    let dataStringToSend = stringChange?.filter(
      (el: any) => el?.id && el?.id != undefined
    );

    let dataToSend = {
      ...fileChange,
      investorPageResources: JSON.stringify(dataStringToSend),
    };
    props?.validate(dataToSend);

    if (isInvestor) {
      dispatch({
        type: UPDATE_OR_PUBLISH_DRAFT__DATA,
        payload: {
          isupdated: true,
        },
      });
    } else if (isOem) {
      dispatch({
        type: PUPBLISH_OR_UPDATE,
        payload: {
          isupdated: true,
        },
      });
    }
  };

  const setFileResource = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const file = event?.target && event?.target?.files?.[0];
    if (file) {
      let fileName = file.name?.split(".").pop()?.toLowerCase();
      let fileSize = file.size;
      const isUpLimit = fileSize > 10500000;
      if (isUpLimit) {
        return toast("Max resources file size is 10 Mo ");
      }
      if (file) {
        const isInFileType =
          fileName === "pdf" ||
          fileName?.includes("doc") ||
          fileName?.includes("docx");
        if (isInFileType) {
          let tempFile: any = fileDataSource?.map(
            (el: IDataResource, t: number) => {
              if (el?.id?.toString() === id?.toString()) {
                return {
                  ...el,
                  dataFile: event?.target?.files?.[0],
                  file_name: event?.target?.files?.[0].name,
                  isChange: true,
                };
              } else {
                return {
                  ...el,
                };
              }
            }
          );
          setFileDataSource(tempFile);
        }
      }
    }
  };

  const removeFile = async (id: number, index: number) => {
    let tempData: any = fileDataSource?.map((el: IDataResource, i: number) => {
      if (el?.id == id) {
        return {
          id: el?.id,
          dataFile: "",
          isChange: true,
          file_name: "",
          file_location: "",
          draft: true,
        };
      } else {
        return el;
      }
    });
    setFileDataSource(tempData);
  };

  const showModalEditRessource = () => {
    setIsShowRessource(true);
  };

  const closeRessourceModal = () => {
    setIsShowRessource(false);
    setLoader(false);
    initialiseRessource();
  };

  return {
    isEdit,
    setisEdit,
    saveUpdate,
    fileDataSource,
    setFileResource,
    loader,
    setLoader,
    setIsShowRessource,
    removeFile,
    isShowRessource,
    showModalEditRessource,
    closeRessourceModal,
  };
}
