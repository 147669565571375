import React from "react";
import useCompanyCtr from "../../../../../controller/oem-ctr/block-component/use-company-ctr";
import ModalOemProgramDetail from "../modal-edit-oem/oem-edit-program-detail";
import {
  concatString,
  getCountryFromId,
  refaCtoNullKey,
} from "../../../../../utils";
export default function OemCompany(props: any) {
  const {
    oemProgram,
    register,
    errors,
    handleSubmit,
    onSubmit,
    dataCompleted,
    modal,
    closeModal,
    loading,
    openModal,
    defaultValue,
  } = useCompanyCtr();
  return (
    <>
      <ul className="investorInfoList half">
        <a
          href="javascript:;"
          className="linkEditLeftCompany"
          onClick={(e) => {
            e.preventDefault();
            openModal();
          }}
        >
          Edit
          <span className="icon-edit-full" />
        </a>
        <li>
          <label>Company name</label>
          {defaultValue?.companyName}
        </li>
        <li className="editShow">
          <label>Website</label>
          <a href={oemProgram?.website}>{defaultValue?.website}</a>
        </li>
        <li>
          <label>Headquarters location</label>
          {concatString(
            refaCtoNullKey(
              getCountryFromId(defaultValue?.country)?.country_name
            ),
            defaultValue?.city
          )}
        </li>
      </ul>
      <ModalOemProgramDetail
        handleSubmit={handleSubmit}
        errors={errors}
        register={register}
        onSubmit={onSubmit}
        data={oemProgram}
        dataCompleted={dataCompleted}
        closeModal={closeModal}
        modal={modal}
        loading={loading}
        defaultValue={defaultValue}
      />
    </>
  );
}
