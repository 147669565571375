import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  appendMyEvents,
  deleteEventsAction,
  getMyEvents,
  updateEventsAction,
} from "../../redux/actions/event-action";
import { rootState } from "../../redux/reducers";
import store from "../../redux/store";
import { isRequired, concatStringArray, isExist, getTimeZoneUtc } from "../../utils";
import { useForm } from "react-hook-form";
import moment from "moment";
import { timeRange } from "../../utils/time-range";
import { useLocation } from "react-router-dom";
import timeZone from "../../utils/timezones.json";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import UseSearchEventForm from "../../screen/widget/SearchFormEvent/useSearchEventForm";
import { ITimezone, ITimezoneOption } from "react-timezone-select";

export type IPropsEvent = {
  startTime: string;
  endTime: string;
  isAllDate: boolean;
  eventUrl: string;
  eventTitle: string;
  eventTags: string;
  country?: string;
  city?: string;
  eventType: string;
  timezone: string;
};

export default function UseMyEvents() {
  const dispatch = useDispatch();
  const eventObject = useSelector(
    (state: rootState) => state?.eventReducer?.my_events
  );
  const urlPath: any = useLocation();
  const [selectedType, setSelectedType] = useState<Array<number>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [selectedLocation, setSelectedLocation] = useState<Array<number>>([]);
  const [selectedHosted, setSelectedHosted] = useState<Array<string>>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [eventDescription, setEventDescription] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [timezone, setTimezone] = useState<ITimezoneOption|ITimezone>(getTimeZoneUtc());
  
  const [startDate, setStartDate] = useState<Date>(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [showNext, setShowNext] = useState<boolean>(false);
  const [eventInfoSelected, setEventInfoSelected] = useState<{
    eventType: string;
    eventId: string;
  }>({ eventId: "", eventType: "" });

  const [isErrorInfo, setisErrorInfo] = useState(false);
  const [txtError, settxtError] = useState<string>("");

  const [defaultLabelValue, setDefaultLabelValue] = useState<string>("");

  const {
    handleFilterSelect,
    handleSelectedLabels,
    txtFilter,
    setTxtFilter,
    dataLabelFilter,
    setDataLabelFilter,
    defaultDataLabel,
    isFocused,
    setIsFocused,
  } = UseSearchEventForm({
    defaultLabelValue: defaultLabelValue,
  });
  const pathname = urlPath?.pathname;
  const moderatorUrl = "/events/all-event-moderator";
  const isModerator = pathname === moderatorUrl;
  const allEventType = [
    {
      key: "1",
      value: "Virtual Event",
    },
    {
      key: "2",
      value: "Physical Event",
    },
  ];

  const callback = (data: any) => {
    setLoader(false);
    if (eventObject?.results?.length >= eventObject?.total) setHasMore(false);
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<IPropsEvent>({ mode: "onChange" });

  const [isShowEndTime, setisShowEndTime] = useState<boolean>(false);
  const [loaderEdit, setLoaderEdit] = useState<boolean>(false);
  const [isAllTime, setisAllTime] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadingDelete, setLoadingDelete] = useState<{
    loading: boolean;
    idToDelete: number;
  }>({ loading: false, idToDelete: 0 });

  const getEvent = (key: string, value: any) => {
    setLoader(true);
    setHasMore(true);
    const virtuaLtypeOnly = selectedType?.length === 1 && selectedType[0] === 1;
    let params = {
      oem_program_id: store?.getState()?.oemProgramReducer?.program?.id,
      tags: isRequired(selectedTags)
        ? "(" + selectedTags?.join().trim() + ")"
        : "",
      event_type: isRequired(selectedType)
        ? "(" + selectedType?.join().trim() + ")"
        : "",
      country:
        isRequired(selectedLocation) && !virtuaLtypeOnly
          ? "(" + selectedLocation?.join().trim() + ")"
          : "",
      hosted_by: isRequired(selectedHosted)
        ? concatStringArray(selectedHosted)
        : "",
      limit: 10,
      page: 1,
      search: search,
      [key]: value,
    };
    dispatch(getMyEvents(params, isModerator, callback));
  };

  const resetFilter = () => {
    setSelectedTags([]);
    setSelectedType([]);
    setSelectedHosted([]);
    setSelectedLocation([]);
    setSearch("");
    setPage(1);
    setLoader(true);
    let params = {
      oem_program_id: store?.getState()?.oemProgramReducer?.program?.id,
      tags: "",
      event_type: "",
      country: "",
      hosted_by: "",
      limit: 10,
      page: 1,
      search: "",
    };
    dispatch(getMyEvents(params, isModerator, callback));
  };

  useEffect(() => {
    getEvent("page", 1);
  }, []);

  const filter = () => {
    setPage(1);
    getEvent("page", 1);
  };

  const handleSearch = (event: any) => {
    setSearch(event?.target?.value);
    setPage(1);
    if (event?.key === "Enter") {
      getEvent("search", event?.target?.value);
    }
  };

  const handlePageChange = (page: number | string) => {
    let temp = typeof page === "string" ? parseInt(page) : page;
    setPage(temp);
    getEvent("page", temp);
  };

  const cbDeleteEvents = (response: any) => {
    setLoadingDelete({
      idToDelete: 0,
      loading: false,
    });
    toast("Delete successfully");
  };
  const deleteEvent = (eventId: number) => {
    setLoadingDelete({
      idToDelete: eventId,
      loading: true,
    });
    dispatch(deleteEventsAction(eventId, cbDeleteEvents));
  };

  const cbAfterUpdate = (response: any) => {
    setLoaderEdit(false);
    if (showNext) {
      setShowNext(false);
    }
    reset();
    setEventDescription(EditorState.createEmpty());
  };

  const onSubmit = async (data: IPropsEvent) => {
    const isEmptyEndDate = data?.endTime === "" || data?.endTime === undefined;

    let startDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.startTime?.toString()
    );
    let endDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.endTime?.toString()
    );

    let selectedDate = moment(startDate).format("YYYY-MM-DD");
    let formatedDateToSend = moment(startDate).format("DD-MM-YYYY");

    const dateTimeStart = moment(
      new Date(selectedDate + " " + startDateSelected?.value)
    );

    const dateTimeEnd = moment(
      new Date(selectedDate + " " + endDateSelected?.value)
    );

    var dateDiffHour: number = 0;

    dateDiffHour = isAllTime
      ? 24
      : isEmptyEndDate
      ? -1
      : moment.duration(dateTimeEnd.diff(dateTimeStart)).asHours();

    let isEmptyTime = data?.startTime === "" && !isAllTime ? true : false;

    if (isEmptyTime) {
      setisErrorInfo(true);
      settxtError("start time is required");
      return;
    }

    if (dateDiffHour <= 0 && data?.startTime !== "" && !isEmptyEndDate) {
      setisErrorInfo(true);
      settxtError("start time must be greater than end time");
      return;
    }

    let tempLabels = txtFilter?.replace(/,$/, "")?.split(",");

    let eventType = allEventType?.find(
      (el: { key: string; value: string }) =>
        el?.value === eventInfoSelected?.eventType
    )?.key;
    setLoaderEdit(true);

    let realTimezone = timezone;

    if (typeof timezone === 'object' && 'value' in timezone) {
      realTimezone = timezone.value;
    } 
    
    let params = {
      eventType: eventType,
      at: isAllTime
        ? formatedDateToSend
        : formatedDateToSend + " " + startDateSelected?.value,
      duration: isAllTime ? "24 h" : dateDiffHour + " h",
      tags: tempLabels?.join(","),
      presentationText: JSON.stringify(
        convertToRaw(eventDescription.getCurrentContent())
      ),
      register: data?.eventUrl,
      title: data?.eventTitle,
      beginAt: isAllTime ? "" : data?.startTime,
      endAt: isAllTime || isEmptyEndDate ? "" : data?.endTime,
      country: data?.country || "",
      city: data?.city || "",
      timezone: realTimezone,
    };

    dispatch(
      updateEventsAction(eventInfoSelected?.eventId, params, cbAfterUpdate)
    );
  };

  useEffect(() => {
    if (loaderEdit) {
      setLoaderEdit(false);
    }

    if (isErrorInfo) {
      setisErrorInfo(false);
      settxtError("");
    }

    if (!showNext) {
      setStartDate(new Date(Date.now() + 3600 * 1000 * 24));
      reset();
      setEventDescription(EditorState.createEmpty());
    }

    return () => {};
  }, [showNext]);

  const showModalEdit = (event: any) => {
    setValue("eventUrl", event?.register);
    setValue("eventTitle", event?.title);
    setValue("country", event?.country_id);
    setValue("city", event?.city);
    setValue("eventType", event.event_type_id);
    setValue("timezone", event.timezone);
    setValue("startTime", event?.begin_at);
    setValue("endTime", isExist(event?.end_at) ? event?.end_at : null);

    setTimezone(event.timezone);
    if (event?.event_tags) {
      const joinLabels =
        event?.event_tags?.map((it: any) => it.title)?.join() || "";
      console.log("event_tags", joinLabels);

      setDefaultLabelValue(joinLabels);
    }

    try {
      const datasEntity = JSON.parse(event?.presentation_text);
      setEventDescription(
        EditorState?.createWithContent(convertFromRaw(datasEntity))
      );
    } catch (error) {
      setEventDescription(EditorState?.createEmpty());
    }

    setEventInfoSelected({
      eventType: event?.event_type.name,
      eventId: event?.id?.toString(),
    });
    setisAllTime(event?.duration === "24 h" ? true : false);
    if (isExist(event?.end_at)) {
      setisShowEndTime(true);
    }
    setShowNext(true);
  };

  useEffect(() => {
    if (!showNext) {
      reset({});
      setTxtFilter("");
    }
    return () => {};
  }, [showNext]);

  const handleNext = (page: number) => {
    setPage(page);
    setHasMore(true);
    let params = {
      oem_program_id: store?.getState()?.oemProgramReducer?.program?.id,
      tags: isRequired(selectedTags)
        ? "(" + selectedTags?.join().trim() + ")"
        : "",
      event_type: isRequired(selectedType)
        ? "(" + selectedType?.join().trim() + ")"
        : "",
      country: isRequired(selectedLocation)
        ? "(" + selectedLocation?.join().trim() + ")"
        : "",
      hosted_by: isRequired(selectedHosted)
        ? concatStringArray(selectedHosted)
        : "",
      limit: 10,
      page: page,
      search: search,
    };
    dispatch(appendMyEvents(params, callback));
  };

  const isSelected = (
    data: Array<{ label: string; value: string }>,
    value: string
  ): boolean => {
    return data?.find((el: any) => el?.value === value) ? true : false;
  };

  return {
    eventObject,
    selectedTags,
    selectedType,
    resetFilter,
    setSelectedTags,
    setSelectedType,
    filter,
    loader,
    search,
    page,
    handleSearch,
    setSearch,
    handlePageChange,
    deleteEvent,
    register,
    handleSubmit,
    watch,
    showNext,
    isShowEndTime,
    loaderEdit,
    onSubmit,
    setShowNext,
    startDate,
    setStartDate,
    isAllTime,
    setisAllTime,
    setisShowEndTime,
    errors,
    showModalEdit,
    getValues,
    handleNext,
    hasMore,
    isErrorInfo,
    txtError,
    isSelected,

    loadingDelete,
    selectedLocation,
    setSelectedLocation,
    selectedHosted,
    setSelectedHosted,
    control,
    eventDescription,
    setEventDescription,
    isValid,
    handleFilterSelect,
    handleSelectedLabels,
    txtFilter,
    setTxtFilter,
    dataLabelFilter,
    setDataLabelFilter,
    defaultDataLabel,
    isFocused,
    setIsFocused,
    setTimezone,
    timezone
  };
}
