import { useEffect, useState } from "react";
import { isEmail, isRequired } from "../../../utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { reportCompanyPage } from "../../../service/applicatif/company-page";

export default function useReportCompany() {
  const currentUser = useSelector((state: rootState) => state.userReducer);

  const [phone, setPhoneNumber] = useState<string>();

  const [dialCodeSelected, setDialCodeSelected] = useState(
    sessionStorage.getItem("dialCode") || "+1"
  );

  const [selected, setSelected] = useState(
    sessionStorage.getItem("phoneNumberCode") || "US"
  );

  const [phoneError, setErrorPhone] = useState<"validate" | "required" | null>(
    null
  );

  const [name, setName] = useState<string>(
    currentUser?.first_name + " " + currentUser?.last_name || ""
  );

  const [nameError, setNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>(currentUser?.email || "");

  const [emailError, setEmailError] = useState<boolean>(false);

  const [emailTxtError, setEmailTxtError] = useState<string>("");

  const [yourConcern, setYournConcern] = useState<string>();

  const [yourConcernError, setYournConcernError] = useState<boolean>(false);

  const [showModalReport, setShowModalReport] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setErrorPhone(null);
    return () => {};
  }, [phone]);

  useEffect(() => {
    setNameError(false);
    return () => {};
  }, [name]);

  useEffect(() => {
    setEmailError(false);
    return () => {};
  }, [email]);

  useEffect(() => {
    setYournConcernError(false);
    return () => {};
  }, [yourConcern]);

  const validPhone = (txt: string | undefined): boolean => {
    if (txt && txt.length > 0) {
      const validatePhone = isValidPhoneNumber(dialCodeSelected + txt);
      if (validatePhone) {
        return true;
      } else {
        setErrorPhone("validate");
        return false;
      }
    } else {
      setErrorPhone("required");
      return false;
    }
  };

  const validName = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setNameError(true);
      return false;
    }
  };

  const validEmail = (txt: string): boolean => {
    var retour = false;
    if (isRequired(txt)) {
      if (!isEmail(txt)) {
        setEmailError(true);
        setEmailTxtError("Your email address is not valid");
        retour = false;
      } else {
        retour = true;
      }
    } else {
      setEmailError(true);
      setEmailTxtError("This field is required");
      retour = false;
    }

    return retour;
  };

  const validYourConcern = (txt: string | undefined): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setYournConcernError(true);
      return false;
    }
  };

  const reportCompany = async (c_id: any) => {
    setLoading(true);
    if (
      validEmail(email) &&
      validName(name) &&
      validPhone(phone) &&
      validYourConcern(yourConcern)
    ) {
      let data = {
        entity_id: c_id,
        email: currentUser?.email,
        name: currentUser?.first_name + " " + currentUser?.last_name,
        dial_code: dialCodeSelected,
        phone: phone,
        phone_number_code: selected,
        your_concern: yourConcern,
      };
      let response = await reportCompanyPage(data, currentUser?.token);
      if (response?.status === 200) {
        setShowModalReport(true);
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } else {
      setLoading(false);
      return;
    }
  };

  return {
    phone,
    setPhoneNumber,
    dialCodeSelected,
    setDialCodeSelected,
    selected,
    setSelected,
    phoneError,
    setErrorPhone,
    name,
    setName,
    nameError,
    setEmail,
    email,
    emailError,
    emailTxtError,
    yourConcern,
    setYournConcern,
    yourConcernError,
    reportCompany,
    showModalReport,
    setShowModalReport,
    loading,
  };
}
