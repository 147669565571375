import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../redux/reducers';
import { INVESTOR, OEM_PROGRAM } from '../service/constant';
import { Redirect } from 'react-router-dom';

export default function MachingComponent(props: any) {
  const currentUser = useSelector((state: rootState) => state.userReducer);

  if (
    !currentUser.roles.includes(INVESTOR) &&
    !currentUser.roles.includes(OEM_PROGRAM)
  ) {
    return <Redirect to={'/profil-not-found'} />;
  }

  return <div>{props.children}</div>;
}
