import React from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import SofTwareSolution from "../../../widget/WidgetSelected/SolutionList/SolutionList";
import { IPropsSF } from "../../../widget/WidgetSelected/SolutionList/type";

type IProps = IPropsSF & {
  show: boolean;
  cancel: () => void;
  valid: () => void;
};

export default function ModalSolution(props: IProps) {
  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Select up to 3 solution categories.</h2>
          <NavLink
            to="#"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.cancel();
            }}
          >
            <span className="icon-close-pop"></span>
          </NavLink>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <SofTwareSolution
          solutionLeftColumnData={props.solutionLeftColumnData}
          solutionRigthColumnData={props.solutionRigthColumnData}
          featuredSolution={props.featuredSolution}
          solutionChecked={props.solutionChecked}
          solutionCheckedFeatures={props.solutionCheckedFeatures}
          addSolutionCheched={props.addSolutionCheched}
          addSolutionChechedFeatured={props.addSolutionChechedFeatured}
          otherValue={props.otherValue}
          setOtherValueTxt={props.setOtherValueTxt}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.cancel()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5 save"
            onClick={() => props?.valid()}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
