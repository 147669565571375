import Header from "../../../widget";
import LeftProgram from "../program-left-side/bloc-left-program";
import BlocEvent from "./bloc-event";
import UseProgramEvent from "../../../../controller/program-ctr/program-event/use-program-event";

export default function Directory() {
  const type = "EVENT";

  const providerProgramEvent = UseProgramEvent();

  return (
    <div className="findIsvcontainer programContainer">
      <Header />

      <LeftProgram
        route={type}
        filter={(selectedTags: any, selectedType: any) =>
          providerProgramEvent.filter(selectedTags, selectedType)
        }
        resetFilter={() => providerProgramEvent.resetFilter()}
      />

      <BlocEvent {...providerProgramEvent} />
    </div>
  );
}
