import { UserObject as User } from '../../redux/types/acount-interface';
import {
  dataWithToken,
  postWithoutToken,
  postPhoto,
  postData,
  getData,
  getDataWithoutToken, getStaticDataWithToken,
} from '../api';
import { url } from '../constant';
import { staticDataUrls } from '../constant';

interface LabelObjectResponse {
  category: string;
  icone: string;
  id: number;
  main: boolean;
  slug: string;
  title: string;
}

export interface ICountry {
  id: number;
  attributes: {
    Name: string;
    CouncilRegion: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    Continent: string;
    ISO2: string;
    ISO3: string;
  };
}
/**
 * @param user object create user
 * @returns promise response create user
 */
export const createUser = async (user: User): Promise<User> => {
  let params: dataWithToken = {
    url: url.acount.create_acount,
    data: user,
  };
  let response: User = await postPhoto(params);
  return response;
};

/**
 * @param user object create user expert
 * @returns promise response create user
 */
export const createUserExpert = async (user: any): Promise<any> => {
  let params: dataWithToken = {
    url: url.acount.create_acount,
    data: user,
  };
  let response: User = await postPhoto(params);
  return response;
};

/**
 * @returns interrested data
 */
export const getInterseted = async (): Promise<Array<LabelObjectResponse>> => {
  //let result: any = await getDataWithoutToken(url.acount.getInterested);
  let result: any = await getDataWithoutToken(url.acount.getLabelSubscription);
  return result?.data;
};

/**
 * @returns get completed acound
 */
export const getCompletedAcccount = async (): Promise<
  Array<LabelObjectResponse>
> => {
  let result: any = await getDataWithoutToken(url.acount.get_completed_account);
  return result;
};

/**
 * @returns get countries
 */
export const getCountries = async ($withSortName = false) => {
  let url = staticDataUrls.getCountries + ($withSortName ? '&sort[1]=Name:asc' : '');
  let result: any = await getStaticDataWithToken(url);
  return result;
};

export const getAllContinent = async (): Promise<
  Array<LabelObjectResponse>
> => {
  let result: any = await getDataWithoutToken(url.acount.getContinents);
  return result;
};

export const getResellerSolution = async (): Promise<
  Array<LabelObjectResponse>
> => {
  let result: any = await getDataWithoutToken(url.acount.getResellerSolution);
  return result;
};

export const getCompanyTarget = async (): Promise<
  Array<LabelObjectResponse>
> => {
  let result: any = await getDataWithoutToken(url.acount.getTargetCompany);
  return result;
};

export const signin = async (email: string, password: string): Promise<any> => {
  let response: any = await postWithoutToken(url.acount.signin, {
    username: email,
    password,
  });
  return response;
};

export const getInfoUser = async (token: any): Promise<any> => {
  let response: any = await getData(url.acount.getInfoUser, token);
  return response;
};

export const postInfoEom = async (data: any): Promise<any> => {
  let response: any = await postWithoutToken(url.acount.signinEcosystem, data);
  return response;
};

export const checkIfEmailExist = async (
  email: string,
  isNotPro: boolean = false
): Promise<any> => {
  let emailAdresse = isNotPro ? email + '/' + 1 : email;
  let response: any = await getDataWithoutToken(
    `${url.acount.checkEmail}/${emailAdresse}`
  );
  return response;
};

export const forgotPassword = async (data: any): Promise<any> => {
  let response: any = await postWithoutToken(url.acount.forgotPassword, data);
  return response;
};

export const resetPassword = async (data: any, token: string): Promise<any> => {
  let response: any = await postWithoutToken(
    `${url.acount.resetPassword}/${token}`,
    data
  );
  return response;
};

export const createResellerAccount = async (data: any): Promise<any> => {
  let params: any = {
    url: url.acount.create_reseller_account,
    data,
  };
  let response: any = await postPhoto(params);
  return response;
};

export const signupInvestorAccount = async (data: any): Promise<any> => {
  let params: any = {
    url: url.acount.create_investor_account,
    data,
  };
  let response: any = await postPhoto(params);
  return response;
};

export const createGuestUser = async (data: any): Promise<any> => {
  let params: dataWithToken = {
    url: url.acount.create_acount,
    data: data,
  };
  let response: User = await postPhoto(params);
  return response;
};

export const updateInfoUser = async (
  data: any,
  token: string
): Promise<any> => {
  let params: dataWithToken = {
    url: url.acount.updateUserProfilInfo,
    data: data,
  };
  let response = await postPhoto(params, token);
  return response;
};

export const updatePassword = async (
  data: { old_password: string; new_password: string },
  token: string
): Promise<any> => {
  let response = await postData(url.acount.updatePassWord, data, token);
  return response;
};

export const sendDigitCode = async (
  data: { type: string; email: string },
  token: string
): Promise<any> => {
  let response = await postData(url.acount.sendDigitVerification, data, token);
  return response;
};

export const updateMyEmail = async (
  data: {
    email: string;
    token: string;
    password: string;
  },
  token: string
): Promise<any> => {
  let response = await postData(url.acount.updateEmail, data, token);
  return response;
};

export const clauseAccountService = async (
  data: {
    reason?: string;
    feedback?: string;
  },
  token: string
): Promise<any> => {
  let response = await postData(url.acount.clauseAccount, data, token);
  return response;
};

//get information members affiliations
export const getAffiliatedMembers = async (
  entityId: number,
  token: string
): Promise<any> => {
  const response: any = await getData(
    url.acount.isvInCompanyPage + '/' + entityId,
    token
  );
  return response;
};

//get user
export const getUser = async (userId: number, token: string): Promise<any> => {
  const response: any = await getData(
    url.acount.create_acount + '/' + userId,
    token
  );
  return response;
};

//get user activity
export const getUserActivity = async (
  url: string,
  userId: number,
  token: string
): Promise<any> => {
  const response: any = await getData(url + '/' + userId, token);
  return response;
};

//post user activity
export const postUserActivity = async (
  url: string,
  userData: any,
  token: string
): Promise<any> => {
  const response: any = await postData(url, userData, token);
  return response;
};

//check user moderator password
export const checkPasswordUser = async (
  url: string,
  userData: any,
  token: string
): Promise<any> => {
  const response: any = await postData(url, userData, token);
  return response;
};

//get user
export const getBanner = async (data: any, token?: string): Promise<any> => {
  const response: any = await postData(url.acount.banner, data, token);
  return response;
};
