import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
} from "react-router-dom";
import { getDataWithoutToken, postWithoutToken } from "../../../service/api";
import { activeRequestClaim_Service } from "../../../service/applicatif/company-page-isv";
import { OEM_PROGRAM, url } from "../../../service/constant/index";
import { rootState } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import {
  getInfoUserAction,
  logout,
} from "../../../redux/actions/acounts-action";

const queryString = require("query-string");

export default function UseAffilliated() {
  let userObject = useSelector((state: rootState) => state.userReducer);
  const location = useLocation();
  const history = useHistory();
  let queryParameter = queryString.parse(location?.search);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getInfoCompany = async () => {
      setShowLoader(true);
      let response = await getDataWithoutToken(
        url.acount.get_info_affiliated + queryParameter?.tokenInvitation
      );
      const { companyName, email, type, website } = response?.data;
      if (response?.status == 200) {
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("companyName", companyName);
        sessionStorage.setItem("type_account", type);
        sessionStorage.setItem("webSite", website);
        sessionStorage.setItem(
          "token_affiliated",
          queryParameter?.tokenInvitation
        );
        history.push(
          type === OEM_PROGRAM
            ? "/complete-oem-info-affilliated"
            : "/complete-info"
        );
      }
    };
    if (queryParameter?.tokenInvitation) {
      getInfoCompany();
    }
  }, []);

  const cb = (params: boolean) => {
    history.push("/signin", {
      redirectUrl: "/company/profil",
    });
  };

  const cbGetInfoUser = (response: any) => {
    if (response && response?.status == 200) {
      history.push("/company/profil");
    } else {
      dispatch(logout(cb));
    }
  };
  useEffect(() => {
    let { request_claim, claim, email } = queryParameter;
    const validClaimRequest = async () => {
      setShowLoader(true);
      let params = request_claim + "/" + claim + "/" + email;
      let response: any = await activeRequestClaim_Service(params);
      //check if status 200
      if (response?.status === 200) {
        //check if userOblect exist and primry email
        if (userObject && userObject?.primary_email) {
          let workEmail = userObject?.primary_email;
          //compare work email in userObject and email send claim
          if (workEmail == email) {
            //if user in userObject === email sendClaim,  get Info User
            dispatch(getInfoUserAction(cbGetInfoUser));
          } else {
            //compare work email in userObject and email send claim not same
            //redirect to login
            dispatch(logout(cb));
          }
        } else {
          //if userObject and primary mail not exist , redirect login
          dispatch(logout(cb));
        }
      }
    };
    if (request_claim && claim && email) {
      validClaimRequest();
    }
  }, []);

  useEffect(() => {
    const { i, e } = queryParameter;
    const updateWorkEmail = async () => {
      setShowLoader(true);
      let data = {
        user_id: i,
        user_email: e,
      };
      let response = await postWithoutToken(
        url.company.valid_work_email_link,
        data
      );
      if (response?.status == 200) {
        dispatch(logout(() => history.push("/signin")));
        setShowLoader(false);
      }
    };
    if (i && e) {
      updateWorkEmail();
    }
  }, []);

  return {
    showLoader,
    error,
  };
}
