import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEvents } from "../../../redux/actions/find-program-action";
import { rootState } from "../../../redux/reducers";

export default function ProgramEventCtr() {
  const dispatch = useDispatch();
  const query: any = useParams();
  const eventObject = useSelector(
    (state: rootState) => state?.findProgramReducer?.program_events
  );

  const [type, setType] = useState<Array<number>>([]);
  const [tags, setTags] = useState<Array<number>>([]);

  const [loader, setLoader] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const cb = (data: any) => {
    if (data?.status === 200) {
      setLoader(false);
    }
  };

  const getUpcomingEvents = (
    key: string,
    value: any,
    isGetOneEvent: boolean = false
  ) => {
    setLoader(true);
    const idEvent = isGetOneEvent ? query?.event_id : 0;
    let data = {
      page: currentPage,
      limit: limit,
      event_type: type?.length > 0 ? "(" + type?.join().trim() + ")" : "",
      tags: tags?.length > 0 ? "(" + tags?.join().trim() + ")" : "",
      search: search,
      [key]: value,
    };
    dispatch(getEvents(data, idEvent, cb));
  };

  useEffect(() => {
    setCurrentPage(1);
    getUpcomingEvents("page", 1, true);
  }, []);

  const handlePageChange = (data: any) => {
    setCurrentPage(data);
    getUpcomingEvents("page", data);
  };

  const handleSearch = (event: any) => {
    setSearch(event?.target?.value);
    if (event?.key === "Enter") {
      getUpcomingEvents("search", event?.target?.value);
    }
  };

  const filter = (selectedTags: any, selectedType: any) => {
    setLoader(true);

    let data = {
      page: 1,
      limit: limit,
      event_type:
        selectedType?.length > 0 ? "(" + selectedType?.join().trim() + ")" : "",
      tags:
        selectedTags?.length > 0 ? "(" + selectedTags?.join().trim() + ")" : "",
      search: "",
    };
    setType(selectedType);
    setTags(selectedTags);
    setCurrentPage(1);
    dispatch(getEvents(data, 0, cb));
  };

  const resetFilter = () => {
    setLoader(true);
    let data = {
      page: 1,
      limit: limit,
      event_type: "",
      tags: "",
      search: "",
    };
    setSearch("");
    setCurrentPage(1);
    dispatch(getEvents(data, 0, cb));
  };

  const handleTag = (tagId: number) => {
    setLoader(true);
    setTags([tagId]);
    let data = {
      page: 1,
      limit: limit,
      event_type: "",
      tags: "(" + tagId?.toString() + ")",
      search: "",
    };
    dispatch(getEvents(data, 0, cb));
  };

  return {
    eventObject,
    loader,
    limit,
    currentPage,
    search,
    setSearch,
    handlePageChange,
    handleSearch,
    filter,
    resetFilter,
    handleTag,
  };
}
