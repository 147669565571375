import React from "react";
import { ReactComponent as Loader } from "../../../../resources/images/loader-chargement.svg";
import Header from "../../../widget/header-logo-center/header-logo-center";
import UseAffilliated from "../../../../controller/account-ctr/create-affiliated-account/use-affilliated-ctr";

export default function index() {
  const {} = UseAffilliated();
  return (
    <div>
      <Header />
      <div className="activate-loader">
        <Loader />
      </div>
    </div>
  );
}
