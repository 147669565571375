import React, { useState, useEffect } from "react";
import { checkIfExist, refaCtoNullKey, setInternationalPhoneNumber } from "../../../../../../utils";
import MemberCard from "../../../member-card/member-card";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import {contact} from "../../../../../../utils/country_phone";

const ProfilBodyReseller = (props: any) => {
  const [index1, setindex1] = useState<number>(6);
  const [index2, setindex2] = useState<number>(6);
  const [dialCode,setDialCode] = useState<string|null>(null);

  const segmentDetailsLength =
    props?.companyResellerPage?.segmentDetails?.length;
  const targetedIndustryLength =
    props?.companyResellerPage?.targetedIndustry?.length;

  const [activeId, setActiveId] = useState<any>("");

  const [expendAll, setExpendAll] = useState<boolean>(false);

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const flatArrayWithSameId = (data: any): Array<any> => {
    const arrayHashmap = data?.reduce((obj: any, item: any) => {
      obj[item.idContinent]
        ? obj[item.idContinent].push(item)
        : (obj[item.idContinent] = [item]);
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    return mergedArray;
  };

  useEffect(()=>{
    if (props?.userState?.phone_number_code !== "" && props?.userState?.phone_number_code !== null){
      setDialCode(contact.filter(element => element.code === props?.userState?.phone_number_code)[0].dial_code);
    }
  },[]);

  return (
    <>
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">Contact information</h2>
          <ul className="ulListInfo">
            <li>
              <div className="label">Primary email</div>
              <div className="infoLabel">
                {props?.userState?.email}
                <span className="icon-userIcon"></span>
              </div>
            </li>
            <li>
              <div className="label">Work email</div>
              <div className="infoLabel">
                {refaCtoNullKey(props?.userState?.primary_email, "-")}
                <span className="icon-userIcon"></span>
              </div>
            </li>
            <li>
              <div className="label">Phone number</div>
              <div className="infoLabel">
                {/* {dialCode === "" ? <></> : <>{"("+ dialCode + ") "}</>}
                {refaCtoNullKey(props?.userState?.phone, "-")} */}
                {setInternationalPhoneNumber(props?.userState?.phone, dialCode)}
                {props?.userState?.lock_phone_number === false ? (
                  <>
                    <span className="icon-userIcon"></span>
                  </>
                ) : (
                  <>
                    <span className="icon-locked"></span>
                  </>
                )}
              </div>
            </li>
            <li>
              <div className="label">Linkedin</div>
              <div className="infoLabel">
                <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/in/" +
                    refaCtoNullKey(props?.userState?.linkedin, "-")
                  }
                >
                  {refaCtoNullKey(props?.userState?.linkedin, "-").length > 36
                    ? refaCtoNullKey(props?.userState?.linkedin, "-").substring(
                        0,
                        33
                      ) + "..."
                    : refaCtoNullKey(props?.userState?.linkedin, "-")}
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          <>
            <div className="label">Company overview</div>
            {checkIfExist(props?.companyResellerPage?.overview) ? (
              props?.companyResellerPage?.overview
            ) : (
              <div className="fauxText">
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l50"></div>
              </div>
            )}
          </>
          <>
            <div className="label">Key competitive differentiators</div>
            {checkIfExist(props?.companyResellerPage?.key_competitive) ? (
              props?.companyResellerPage?.key_competitive
            ) : (
              <div className="fauxText">
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l50"></div>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <h2 className="title">Company details</h2>
        <ul className="listAffiliated">
          <ul className="ulListInfo">
            <li>
              <div className="label">Legal name</div>
              <div className="infoLabel">
                {props?.companyResellerPage?.company_legal_name}
              </div>
            </li>
            <li>
              <div className="label">Website</div>
              <div className="infoLabel">
                {refaCtoNullKey(props?.companyResellerPage?.website, "-")}
              </div>
            </li>
            <li>
              <div className="label">Location</div>
              <div className="infoLabel">
                {refaCtoNullKey(
                  props?.companyResellerPage?.city +
                    ", " +
                    props?.companyResellerPage?.country?.country_name,
                  "-"
                )}
              </div>
            </li>
            <li className="companydetails">
              <div className="label">Software solution</div>
              <ul className="listnamelabel">
                {props?.companyResellerPage?.segmentDetails
                  .filter((el: { id: number; name: string }, index: number) => {
                    return index < index1;
                  })
                  ?.map(
                    (element: { id: number; name: string }, index: number) => (
                      <li key={index}>
                        <div className="name">{element?.name}</div>
                      </li>
                    )
                  )}
                {segmentDetailsLength > 6 && (
                  <li className="link">
                    <a
                      onClick={(evt) => {
                        evt.preventDefault();
                        setindex1(
                          index1 === segmentDetailsLength
                            ? 6
                            : segmentDetailsLength
                        );
                      }}
                    >
                      See {index1 === segmentDetailsLength ? "less" : "more"}
                      {index1 === segmentDetailsLength
                        ? ""
                        : `(${segmentDetailsLength - index1})`}
                    </a>
                  </li>
                )}
              </ul>
            </li>
            <li className="companydetails">
              <div className="label">Targeted industry </div>
              <ul className="listnamelabel">
                {props?.companyResellerPage?.targetedIndustry
                  ?.filter(
                    (el: { id: number; name: string }, index: number) => {
                      return index < index2;
                    }
                  )
                  ?.map(
                    (element: { id: number; name: string }, index: number) => (
                      <li key={index}>
                        <div className="name">{element?.name}</div>
                      </li>
                    )
                  )}
                {targetedIndustryLength > 6 && (
                  <li className="link">
                    <a
                      onClick={(evt) => {
                        evt.preventDefault();
                        setindex1(
                          index2 === targetedIndustryLength
                            ? 6
                            : targetedIndustryLength
                        );
                      }}
                    >
                      See {index2 === targetedIndustryLength ? "less" : "more"}
                      {index2 === targetedIndustryLength
                        ? ""
                        : `(${targetedIndustryLength - index2})`}
                    </a>
                  </li>
                )}
              </ul>
            </li>
          </ul>
          <div className="geographicList companydetails">
            <div className="label">Geographic target Market</div>
            <Accordion defaultActiveKey={activeId}>
              {flatArrayWithSameId(
                props?.companyResellerPage?.targetedCountry
              )?.map((items: any, index: number) => {
                return (
                  <div
                    className={
                      activeId === index
                        ? "panel-wrap active-panel"
                        : "panel-wrap"
                    }
                    key={index}
                  >
                    <div className="panel-header">
                      <Accordion.Toggle
                        onClick={() => toggleActive(index)}
                        className={"panel-toggle"}
                        eventKey={index.toString()}
                      >
                        {items && items[0].nameContinent}
                        {`(${items?.length})`}{" "}
                        {items && items[0].idContinent == "6"}
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse
                      eventKey={index.toString()}
                      className={expendAll ? "show" : ""}
                    >
                      <div className="panel-body">
                        {items &&
                          items?.map((pays: any, index: number) => {
                            return (
                              <Form.Check
                                key={index}
                                custom
                                disabled
                                label={pays.countryName}
                                id={pays.id}
                              />
                            );
                          })}
                      </div>
                    </Accordion.Collapse>
                  </div>
                );
              })}
              <a href="javascript:;" onClick={() => setExpendAll(!expendAll)}>
                {!expendAll ? "Expand all" : "Collapse content"}
              </a>
            </Accordion>
          </div>
        </ul>
      </div>
    </>
  );
};

export default ProfilBodyReseller;
