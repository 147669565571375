import React, { memo } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  text?: string;
  aclasses?: string;
  liclasses?: string;
  url?: string;
  icon?: boolean;
  iconclasses?: string;
  target?: string;
}

function FooterLink(props: IProps) {
  let textLabel = 'Text';
  let isIcon = false;
  if (props?.text) textLabel = props?.text;
  if (props?.icon) isIcon = props?.icon;

  return (
    <li className={props?.liclasses}>
      {props.target === '_blank' ? (
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          {isIcon ? <span className={props?.iconclasses}></span> : textLabel}
        </a>
      ) : (
        <Link to={(props.url && props.url) || ''}>
          {isIcon ? <span className={props?.iconclasses}></span> : textLabel}
        </Link>
      )}
    </li>
  );
}

export default memo(FooterLink);

