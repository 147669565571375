import React from "react";
import Modal from "react-bootstrap/Modal";
import "../permission-oem/permission.scss";

export type IpropsPersmission = {
  show: boolean;
  hanleShow: (p: boolean) => void;
  allTxt: Array<string>;
};

export default function Index(props: IpropsPersmission) {
  return (
    <Modal
      show={props?.show}
      onHide={() => props?.hanleShow(false)}
      className="small forDesactivate"
    >
      <Modal.Header>
        <div className="headerpermission noStep">
          <h2 className="title">{props.allTxt?.[0]}</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.hanleShow(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            {props.allTxt?.[1]}
          </h3>

          <p>{props.allTxt?.[2]}</p>
          <p>{props.allTxt?.[3]}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
