import React from "react";
import UseEventFilters, {
  constante,
} from "../../../../controller/event-ctr/use-event-filters-ctr";
import { addToArray, paginate } from "../../../../utils";
import { Link } from "react-router-dom";

type IPropsEvent = {
  selectedType: Array<number>;
  selectedTags: Array<number>;
  setSelectedType: (a: Array<number>) => void;
  setSelectedTags: (a: Array<number>) => void;
  selectedLocation: Array<number>;
  selectedHosted: Array<string>;
  setSelectedLocation: (a: Array<number>) => void;
  setSelectedHosted: (a: Array<string>) => void;
  resetFilter: () => void;
  filter: () => void;
  tagFilterIn: number;
};

export default function BlocFilterEvent(props: IPropsEvent) {
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const handleType = (el: number) => {
    props?.setSelectedType(addToArray(props?.selectedType, el));
  };
  const handleTags = (el: number) => {
    props?.setSelectedTags(addToArray(props?.selectedTags, el));
  };

  const handleHosted = (el: string) => {
    props?.setSelectedHosted(addToArray(props?.selectedHosted, el));
  };

  const handleLocation = (el: number) => {
    props?.setSelectedLocation(addToArray(props?.selectedLocation, el));
  };

  const {
    eventObject,
    limitTag,
    limitType,
    limitHosted,
    limitCountryes,
    expandOrNot,
  } = UseEventFilters();

  const physicalChecked = () => {
    const found = props.selectedType?.find((sel) => sel === 2);
    return found;
  };

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="containerBtn block firstBlock">
          <button className="btn btn-devinsider cancel" onClick={props?.filter}>
            Filter
          </button>
          <Link
            to="#"
            className="resetFilter"
            onClick={(e) => {
              e.preventDefault();
              props?.resetFilter();
            }}
          >
            <span className="icon-refresh"></span>
            Reset filter
          </Link>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Event type
          </h3>
          <div className="listCheck">
            {paginate(eventObject.event_type, limitType)?.map(
              (
                el: {
                  id: number;
                  name: string;
                },
                key: number
              ) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={key}
                >
                  <input
                    type="checkbox"
                    id={"fr" + key}
                    className="custom-control-input"
                    checked={
                      props?.selectedType?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => handleType(el?.id)}
                  />
                  <label htmlFor={"fr" + key} className="custom-control-label">
                    {el?.name}
                  </label>
                </div>
              )
            )}
            {eventObject?.event_type?.length > limitType ? (
              <Link
                to="#"
                className="seemore"
                onClick={(e) => {
                  e.preventDefault();
                  expandOrNot(constante.EVENT_TYPE);
                }}
              >
                See more({eventObject?.event_type?.length - limitType})
              </Link>
            ) : (
              eventObject?.event_type?.length <= limitType &&
              eventObject?.event_type?.length > 6 && (
                <Link
                  to="#"
                  className="seemore"
                  onClick={(e) => {
                    e.preventDefault();
                    expandOrNot(constante.EVENT_TYPE, 2);
                  }}
                >
                  See less
                </Link>
              )
            )}
          </div>
        </div>
        {physicalChecked() && (
          <div className="block">
            <h3 className="title withChild" onClick={(e) => openBlock(e)}>
              Location
            </h3>
            <div className="listCheck">
              {paginate(eventObject.top_countries, limitCountryes)?.map(
                (
                  el: {
                    id: number;
                    country: string;
                    eventCount: number;
                  },
                  key: number
                ) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={key}
                  >
                    <input
                      type="checkbox"
                      id={"loc" + key}
                      className="custom-control-input"
                      checked={
                        props?.selectedLocation?.find((e) => e === el?.id)
                          ? true
                          : false
                      }
                      onChange={() => handleLocation(el?.id)}
                    />
                    <label
                      htmlFor={"loc" + key}
                      className="custom-control-label"
                    >
                      {el?.country} ({el?.eventCount})
                    </label>
                  </div>
                )
              )}
              {eventObject?.top_countries?.length > limitCountryes ? (
                <Link
                  to="#"
                  className="seemore"
                  onClick={() => expandOrNot(constante.TOP_COUNTRIES)}
                >
                  See more({eventObject?.top_countries?.length - limitCountryes}
                  )
                </Link>
              ) : (
                eventObject?.top_countries?.length <= limitCountryes &&
                eventObject?.top_countries?.length > 6 && (
                  <Link
                    to="#"
                    className="seemore"
                    onClick={(e) => {
                      e.preventDefault();
                      expandOrNot(constante.TOP_COUNTRIES, 2);
                    }}
                  >
                    See less
                  </Link>
                )
              )}
            </div>
          </div>
        )}

        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Top tag
          </h3>
          <div className="listCheck">
            {paginate(eventObject?.top_tags, limitTag)?.map(
              (
                el: {
                  id: number;
                  event_number: number;
                  title: string;
                },
                index: number
              ) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={"ai" + index}
                    className="custom-control-input"
                    checked={
                      props?.selectedTags?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => handleTags(el?.id)}
                  />
                  <label
                    htmlFor={"ai" + index}
                    className="custom-control-label"
                  >
                    {el?.title} ({el?.event_number})
                  </label>
                </div>
              )
            )}
            {eventObject?.top_tags?.length > limitTag ? (
              <Link
                to="#"
                className="seemore"
                onClick={() => expandOrNot(constante.TAGS)}
              >
                See more({eventObject?.top_tags?.length - limitTag})
              </Link>
            ) : (
              eventObject?.top_tags?.length <= limitTag &&
              eventObject?.top_tags?.length > 6 && (
                <Link
                  to="#"
                  className="seemore"
                  onClick={(e) => {
                    e.preventDefault();
                    expandOrNot(constante.TAGS, 2);
                  }}
                >
                  See less
                </Link>
              )
            )}
          </div>
        </div>
        {props.tagFilterIn !== 1 && (
          <div className="block">
            <h3 className="title withChild" onClick={(e) => openBlock(e)}>
              Hosted by
            </h3>
            <div className="listCheck">
              {paginate(eventObject.hosted_by, limitHosted)?.map(
                (
                  el: {
                    eventCount: number;
                    role: string;
                    role_name: string;
                  },
                  key: number
                ) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={key}
                  >
                    <input
                      type="checkbox"
                      id={"hosted" + key}
                      className="custom-control-input"
                      checked={
                        props?.selectedHosted?.find((e) => e === el?.role_name)
                          ? true
                          : false
                      }
                      onChange={() => handleHosted(el?.role_name)}
                    />
                    <label
                      htmlFor={"hosted" + key}
                      className="custom-control-label"
                    >
                      {el?.role} ({el?.eventCount})
                    </label>
                  </div>
                )
              )}
              {eventObject?.hosted_by?.length > limitHosted ? (
                <Link
                  to="#"
                  className="seemore"
                  onClick={() => expandOrNot(constante.HOSTED_BY)}
                >
                  See more({eventObject?.hosted_by?.length - limitHosted})
                </Link>
              ) : (
                eventObject?.hosted_by?.length <= limitHosted &&
                eventObject?.hosted_by?.length > 6 && (
                  <Link
                    to="#"
                    className="seemore"
                    onClick={(e) => {
                      e.preventDefault();
                      expandOrNot(constante.HOSTED_BY, 2);
                    }}
                  >
                    See less
                  </Link>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
