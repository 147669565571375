import React, { useEffect, useState } from "react";
import { rootState } from "../../../redux/reducers";
import {
  RESELLER,
  INVESTOR,
  ISV,
  GUEST,
  OEM_PROGRAM,
  CONTRIBUTOR,
} from "../../../service/constant/index";
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyPageAction,
  getCompanyPageAttributeAction,
} from "../../../redux/actions/company-page-isv-action/current_isv_action";
import {
  getSubscribeDiscussionAction,
  getUpcomingEventAction,
} from "../../../redux/actions/isv-action";
import useNavigation from "../../../hooks/use-navigate";
import { companyPageByUser } from "./type";
export default function UseIsvAccueilCtr() {
  const history = useNavigation();
  const dispatch = useDispatch();

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const upComingEvent = useSelector(
    (state: rootState) => state.isvReducer.upComingEvent
  );
  const subscribedDiscussion = useSelector(
    (state: rootState) => state.isvReducer.subscribedDiscussion
  );

  const [loader, setLoader] = useState<boolean>(false);
  const isvCompanyPage = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const [loaderAtribute, setLoaderAtribute] = useState<any>({
    distributionChannel: false,
    targetedCountry: false,
    segmentInterest: false,
    segmentInterestText: false,
    targetedIndustry: false,
    partners: false,
  });

  const companyPageObject = useSelector((state: rootState) =>
    companyPageByUser(state)
  );

  const addCompanyPage = () => {
    history.navigate("/company/create");
  };

  const goToCompanyPage = () => {
    history.navigate("/company/create");
  };

  const showProfil = () => {
    history.navigate("/profil");
  };

  const viewAllEvent = () => {
    history.navigate("/events/all-events");
  };

  const cb = (data: any) => {
    if (data && data.status === 200) {
      setLoader(false);
    }
  };

  const cbAttributDistributionChannel = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      distributionChannel: false,
    });
  };

  const cbAttributTargetedCountry = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      targetedCountry: false,
    });
  };

  const cbAttributeSegmentInterest = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      segmentInterest: false,
    });
  };

  const cbAttributeSegmentInterestText = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      segmentInterestText: false,
    });
  };

  const cbAttributTargetedIndustry = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      targetedIndustry: false,
    });
  };

  const cbAttributPartners = (data: any) => {
    setLoaderAtribute({
      ...loaderAtribute,
      partners: false,
    });
  };

  useEffect(() => {
    const haveAcompanyPage =
      userReducer.isvInCompanyPage &&
      userReducer.isvInCompanyPage.company_page.id;

    if (haveAcompanyPage && userReducer?.isvInCompanyPage) {
      setLoader(true);
      setLoaderAtribute({
        distributionChannel: true,
        targetedCountry: true,
        segmentInterest: true,
        segmentInterestText: true,
        targetedIndustry: true,
        partners: true,
      });
      const companyPageId = userReducer?.isvInCompanyPage?.company_page.id;
      dispatch(getCompanyPageAction(companyPageId, cb));
      dispatch(
        getCompanyPageAttributeAction(
          "distributionChannel",
          cbAttributDistributionChannel
        )
      );
      dispatch(
        getCompanyPageAttributeAction(
          "targetedCountry",
          cbAttributTargetedCountry
        )
      );
      dispatch(
        getCompanyPageAttributeAction(
          "oemProgramSegmentInterest",
          cbAttributeSegmentInterest
        )
      );
      dispatch(
        getCompanyPageAttributeAction(
          "oemProgramSegmentInterestText",
          cbAttributeSegmentInterestText
        )
      );
      dispatch(
        getCompanyPageAttributeAction(
          "targetedIndustry",
          cbAttributTargetedIndustry
        )
      );
      dispatch(getCompanyPageAttributeAction("partners", cbAttributPartners));
    }
    dispatch(getUpcomingEventAction());
    dispatch(getSubscribeDiscussionAction(4));
    return () => {};
  }, []);

  const navigateSelectedEvent = (eventId: number) => {
    history.navigate("/programs/event/" + eventId);
  };

  return {
    addCompanyPage,
    RESELLER,
    INVESTOR,
    ISV,
    GUEST,
    OEM_PROGRAM,
    CONTRIBUTOR,
    userReducer,
    loader,
    loaderAtribute,
    goToCompanyPage,
    companyPageObject,
    isvCompanyPage,
    upComingEvent,
    showProfil,
    subscribedDiscussion,
    viewAllEvent,
    navigateSelectedEvent,
  };
}
