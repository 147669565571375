import React, { useState, useEffect } from "react";
import {
  checkIfExist,
  concatString,
  refaCtoNullKey,
  setInternationalPhoneNumber,
} from "../../../../../../utils";
import { contact } from "../../../../../../utils/country_phone";
const ProfilBodyGuest = (props: any) => {
  const [dialCode, setDialCode] = useState<string | null>("");
  let phoneNumber = props?.userState?.phone
    ? contact?.find(
        (el: any) => el?.code == props?.userState?.phone_number_code
      )?.dial_code + props?.userState?.phone
    : "";

  useEffect(() => {
    if (
      props?.userState?.phone_number_code !== "" &&
      props?.userState?.phone_number_code !== null
    ) {
      setDialCode(
        contact.filter(
          (element) => element.code === props?.userState?.phone_number_code
        )[0]?.dial_code
      );
    }
  }, []);

  return (
    <>
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">Contact information</h2>
          <ul className="ulListInfo">
            <li>
              <div className="label">Primary email</div>
              <div className="infoLabel">
                {props?.userState?.email}
                <span className="icon-userIcon"></span>
              </div>
            </li>

            <li>
              <div className="label"> Phone number </div>
              <div className="infoLabel">
                {/* {dialCode === "" ? <></> : <>{"(" + dialCode + ") "}</>}
                {refaCtoNullKey(props?.userState?.phone, "-")} */}
                {setInternationalPhoneNumber(props?.userState?.phone, dialCode)}
                {props?.userState?.lock_phone_number === false ? (
                  <>
                    <span className="icon-userIcon"></span>
                  </>
                ) : (
                  <>
                    <span className="icon-locked"></span>
                  </>
                )}
              </div>
            </li>
            <li>
              <div className="label">Linkedin</div>
              <div className="infoLabel">
                <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/in/" +
                    refaCtoNullKey(props?.userState?.linkedin, "-")
                  }
                >
                  {refaCtoNullKey(props?.userState?.linkedin, "-").length > 36
                    ? refaCtoNullKey(props?.userState?.linkedin, "-").substring(
                        0,
                        33
                      ) + "..."
                    : refaCtoNullKey(props?.userState?.linkedin, "-")}
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          {checkIfExist(props?.userState?.plain_text) ? (
            props?.userState?.plain_text
          ) : (
            <div className="fauxText">
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l50"></div>
            </div>
          )}
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <h2 className="title">Company details</h2>
        <ul className="ulListInfo">
          <li>
            <div className="label"> Website</div>
            <div className="infoLabel">
              {refaCtoNullKey(phoneNumber, "-")}
              <span className="icon-locked"></span>
            </div>
          </li>
          <li>
            <div className="label">Headquarters location</div>
            <div className="infoLabel">
              {concatString(
                props?.userState.country?.country_name,
                props?.userState.city
              )}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfilBodyGuest;
