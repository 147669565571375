import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import {
  sendInvitationRolesOem,
  cancelInvitationAction,
  removeOemRoleAction,
} from "../../../../redux/actions/oem-program-action";
import { updateRoleAffiliatedAction } from "../../../../redux/actions/oem-program-action";
import { oem_roles } from "../../../../utils/index";
import { checkIfEmailExist } from "../../../../service/applicatif/acount";
import { trim } from "lodash";

export default function UseRolemaneger() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [changeProgramOwner, setChangeProgramOwner] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderRemoveMember, setLoaderRemoveMember] = useState<boolean>(false);

  const [showModalRemove, setShowModalRemove] = useState<boolean>(false);
  const [valideRemoveMember, setValideRemoveMember] = useState<boolean>(false);
  const [oemMemberSelected, setOemMemberSelected] = useState<any>();

  const [tempParamsForProgramOwner, setTempParamsForProgramOwner] =
    useState<any>();

  const handleModal = () => setShowModal(!showModal);

  const handleModalRemove = () => {
    if (showModalRemove) {
      setOemMemberSelected(null);
    }
    setShowModalRemove(!showModalRemove);
  };

  const eventAction = [
    {
      id: 1,
      label: "Active",
      value: "ACTIVE",
    },
    {
      id: 2,
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  const [emailInvite, setEmailInvite] = useState<string>("");
  const [typeAffiliated, setTypeAffiliated] = useState<string>("ADMIN");
  const [idRemove, setIdRemove] = useState<string>("");
  const [errors, setErrors] = useState<boolean>(false);
  const [showRequestAffiliatedInsider, setShowRequestAffiliatedInsider] =
    useState(location?.state?.showModal ? true : false);
  const [loaderAffiliated, setLoaderAffiliated] = useState<boolean>(false);
  const [loaderUpdateRole, setLoaderUpdateRole] = useState<boolean>(true);
  const oemProgram: any = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  let userObject = useSelector((state: rootState) => state.userReducer);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [txtMessage, setTxtMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<boolean>(false);
  const [roleAffiliated, setRoleAffiliated] = useState<{
    role: string;
    idUser: string;
  }>();
  const [membreState, setMembreState] = useState<{
    state: string;
    idUser: string;
  }>();

  const roleCurrentUser =
    oemProgram.oemProgramMembers?.find(
      (item: any) => item?.user?.id?.toString() === userObject.id?.toString()
    )?.role || "";

  const affiliatedType = [
    {
      id: 2,
      label: "Admin",
      value: "ADMIN",
    },
    {
      id: 3,
      label: "Editor",
      value: "EDITOR",
    },
    {
      id: 4,
      label: "Outreach Representative",
      value: "OUTREACH_REPRESENTATIVE",
    },
  ];

  const [roleOfAccepteRequest, setroleOfAccepteRequest] =
    useState<string>("MEMBER");

  const cbSendAffilliated = (data: any) => {
    setShowMessage(true);
    setLoaderAffiliated(false);
    if (data?.status == 201) {
      setTypeMessage(true);
      setTxtMessage(emailInvite + " is invited successfully");
    } else if (data?.status === 400) {
      setTypeMessage(false);
      setTxtMessage(data?.data?.detail);
    } else {
      setTypeMessage(false);
      setTxtMessage("An error occurred , verify your email adresse");
    }
    setEmailInvite("");
  };

  const sendInvitation = async () => {
    let oemId = oemProgram?.id?.toString();
    if (oemId == " " || emailInvite == "") {
      setErrors(true);
    } else {
      setLoaderAffiliated(true);
      if (showMessage) {
        setShowMessage(false);
      }
      const params = {
        email: trim(emailInvite),
        role: typeAffiliated,
        oemProgram: "/api/oem_programs/" + oemId,
      };
      dispatch(sendInvitationRolesOem(params, cbSendAffilliated));
    }
  };

  const cbRemoveInvitation = (data: any) => {
    setIdRemove("");
  };

  const removeInvitation = (id: any) => {
    if (showMessage) {
      setShowMessage(false);
    }
    setIdRemove(id?.toString());
    dispatch(cancelInvitationAction(id, cbRemoveInvitation));
  };

  const handleProgramOwnerRegistration = () => {
    finaliseUpdateRole(tempParamsForProgramOwner);
  };

  const cbUpdateRole = (response: any) => {
    const { data } = response;
    data?.role === oem_roles.owner && handleModal();
    setRoleAffiliated({ idUser: "", role: "" });
    setMembreState({ idUser: "", state: "" });
  };

  // const removeRole = (id: string) => {
  //   dispatch(removeUserRoleAffilliatedAction(id, cbRemoveRole));
  // };

  const finaliseUpdateRole = (params: any) => {
    dispatch(updateRoleAffiliatedAction(params, cbUpdateRole));
  };

  //Update roles and status
  useEffect(() => {
    //check if role or status
    const rules =
      (roleAffiliated?.idUser && roleAffiliated?.role) ||
      (membreState?.idUser && membreState.state);

    if (rules) {
      const items = oemProgram?.oemProgramMembers?.find(
        (el: any) =>
          el?.user?.id?.toString() === roleAffiliated?.idUser?.toString() ||
          el?.user?.id?.toString() === membreState?.idUser?.toString()
      );
      let params = {
        role: roleAffiliated?.idUser ? roleAffiliated?.role : items.role,
        user: items?.user.id,
        oemProgram: oemProgram?.id,
        newOemProgramOwner:
          roleAffiliated?.role === oem_roles.owner
            ? roleAffiliated?.idUser
            : "",
        status: membreState?.idUser ? membreState?.state : items.status,
      };
      const isUpdateProgramOwner =
        roleAffiliated?.idUser && roleAffiliated?.role === oem_roles.owner;
      if (isUpdateProgramOwner) {
        handleModal();
        setTempParamsForProgramOwner(params);
      } else {
        finaliseUpdateRole(params);
      }
    }
    return () => {};
  }, [roleAffiliated, membreState]);

  useEffect(() => {
    setEmailInvite("");
    setTypeMessage(false);
    return () => {};
  }, [showRequestAffiliatedInsider]);

  const removeRole = (userSeleceted: string) => {
    setOemMemberSelected(userSeleceted);
    handleModalRemove();
  };

  const cbRemoveRole = (data: any) => {
    handleModalRemove();
  };

  const removeOemMember = () => {
    const params = {
      user_id: oemMemberSelected?.user?.id,
      oem_id: oemProgram.id,
    };
    dispatch(removeOemRoleAction(params, cbRemoveRole));
  };

  const closeAffiliatedValue = () => {
    if (emailInvite) {
      setEmailInvite("");
    }
    if (showMessage) {
      setShowMessage(false);
    }
    if (txtMessage) {
      setTxtMessage("");
    }
    setShowRequestAffiliatedInsider(false);
  };

  return {
    affiliatedType,
    emailInvite,
    showRequestAffiliatedInsider,
    loaderAffiliated,
    typeAffiliated,
    typeMessage,
    showMessage,
    txtMessage,
    idRemove,
    loaderUpdateRole,
    roleAffiliated,
    setShowRequestAffiliatedInsider,
    setEmailInvite,
    setTypeAffiliated,
    sendInvitation,
    removeInvitation,
    setRoleAffiliated,
    removeRole,
    closeAffiliatedValue,
    roleOfAccepteRequest,
    setroleOfAccepteRequest,
    oemProgram,
    userObject,
    currentId: userObject?.id,
    companyName: oemProgram?.company_name,
    affiliatedList: oemProgram?.oemProgramMembers,
    listOfProgramPage: userObject?.oemProgramMembers,
    errors,
    eventAction,
    currentRoleUser: roleCurrentUser,
    setMembreState,
    showModal,
    handleModal,
    loader,
    changeProgramOwner,
    setChangeProgramOwner,
    handleProgramOwnerRegistration,
    removeOemMember,
    loaderRemoveMember,
    valideRemoveMember,
    setValideRemoveMember,
    handleModalRemove,
    showModalRemove,
    oemMemberSelected,
  };
}
