import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getDiscutionCategorie,
  getPartnerProgram,
  restoreDiscussions,
  restoreTopLabel,
} from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import UseSearch from "../../../screen/widget/community/search/use-search";
import { IpropsAuthor } from "../home-page/top-liked-author";
import { IPropsContributor } from "../home-page/top-liked-contributions";

export default function PartnerProgramReview() {
  const history = useHistory();
  const dispatch = useDispatch();

  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );
  const userReducer = useSelector((state: rootState) => state?.userReducer);

  /**
   *  Organization side
   */
  const [loaderOrganization, setLoaderOrganization] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Array<string>>([]);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limitTopOrganization, setLimitTopOrganization] = useState<number>(10);
  const [allViews, setAllViews] = useState<Array<any>>([]);
  const [view, setView] = useState<string>("");
  const [postType, setpostType] = useState(4);
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<IpropsAuthor>>([]);
  const [topLikeProgramReview, setTopLikeProgramReview] = useState<
    Array<IPropsContributor>
  >([]);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const cb = (data: any) => {
    setLoaderOrganization(false);
    if (data?.status === 200) {
    }
  };

  useEffect(() => {
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let topLabelStore = communityReducer?.topOrganizations;
    if (topLabelStore?.currentPage < currentPage) {
      setLoaderOrganization(true);
      dispatch(
        getPartnerProgram({ postType: postType, numberPage: currentPage }, cb)
      );
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreTopLabel(() => {}));
    };
  }, []);

  useEffect(() => {
    async function getFiltredView() {
      let response = await getDataService(
        url?.community?.get_filtred_view + "-1/" + postType,
        {},
        userReducer?.token
      );
      if (response?.status == 200) {
        let tempArrayResult = response?.data;
        setAllViews(tempArrayResult);
      }
    }
    getFiltredView();
    return () => {};
  }, []);

  const cbNext = () => {
    setLoaderOrganization(false);
    setcurrentPage(currentPage + 1);
  };

  const nextPage = (nextPage: number) => {
    //Chack if allData in store is inferieur of nextPage * limit
    let checkLoadNextData =
      communityReducer?.topOrganizations?.currentPage < nextPage;

    //if we must getData  , then we get a new topLabel
    if (checkLoadNextData) {
      setLoaderOrganization(true);
      dispatch(
        getPartnerProgram({ postType: 4, numberPage: nextPage }, cbNext)
      );
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  const clikPrev = (index: number) => {
    setcurrentPage(currentPage - 1);
  };

  /**
   * Discussion side
   */
  const [limitDiscution, setlimitDiscution] = useState<number>(10);
  const [currentDiscutionPage, setCurrentDiscutionPage] = useState<number>(1);
  const [sortedBy, setsortedBy] = useState<
    "post_date" | "last_activity" | "likes" | "views"
  >("post_date");
  const [orderBy, setOrderBy] = useState("DESC");
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let params = {
      views: allViews?.join(),
      labels: "",
      postTypes: postType,
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels: "",
      oem_program: "",
    };
    let discussions = communityReducer?.paginate_program_review;
    if (discussions?.currentPage < currentDiscutionPage) {
      setLoader(true);
      dispatch(getDiscutionCategorie(params, cb));
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreDiscussions(() => {}));
    };
  }, []);

  const getNewDiscutionAfterUpdate = (
    key: string,
    value: any,
    resetPage: boolean = false
  ) => {
    let pageToFetch = currentDiscutionPage;

    if (resetPage) {
      pageToFetch = 1;
      setCurrentDiscutionPage(1);
    }

    let data = {
      views: "",
      postTypes: postType,
      limit: limitDiscution,
      page: pageToFetch,
      sortBy: sortedBy,
      orderBy: orderBy,
      oem_program: "",
      notIdlabels: organizations?.join(),
      [key]: value,
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const handleOrganizations = (labelId: any) => {
    setLoader(true);
    let tempLabel = organizations?.find((el: string) => el == labelId);
    if (tempLabel) {
      setOrganizations(organizations?.filter((el: string) => el != labelId));
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            "notIdlabels",
            organizations?.filter((el: string) => el != labelId)?.join(),
            true
          )
        )
      );
    } else {
      setOrganizations([...organizations, labelId]);
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            "notIdlabels",
            [...organizations, labelId]?.join(),
            true
          )
        )
      );
    }
  };

  const handleFilterView = (p: string) => {
    setView(p);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("views", p, true))
    );
  };

  const setPaginateDataFilter = (p: number) => {
    setlimitDiscution(p);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("limit", p, true))
    );
  };

  const setSortedate = (sorted: any) => {
    setsortedBy(sorted);
    setLoader(true);
    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("sortBy", sorted, true)
      )
    );
  };

  const handlePageClick = (page: any) => {
    setCurrentDiscutionPage(page);
    setLoader(true);

    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("page", page))
    );
  };

  const getDefaultArticle = () => {
    let data = {
      views: "",
      labels: "",
      postTypes: 4,
      limit: 10,
      page: 1,
      sortBy: "post_date",
      orderBy: orderBy,
      notIdlabels: "",
      oem_program: "",
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const resetFilter = () => {
    setLoader(true);
    setOrganizations([]);
    setView("");
    setCurrentDiscutionPage(1);
    setsortedBy("post_date");
    setlimitDiscution(10);
    dispatch(restoreDiscussions(() => getDefaultArticle()));
  };

  const goToProfil = (id: number | string) => {
    history.push("/community/activity-user/" + id);
  };

  useEffect(() => {
    async function getTopLikeAuthor() {
      const result = await getDataService(
        url.community.get_top_liked_author + "/" + -1 + "/" + 4,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedAuthor(result?.data);
      }
    }

    async function getData() {
      //Get top liked Press release
      const result = await getDataService(
        url.community.get_top_liked_contribution + "/" + -1 + "/" + 4,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikeProgramReview(result?.data);
      }
    }

    getData();

    getTopLikeAuthor();
  }, []);

  const goToreplayPost = (currentPost: any) => {
    const categoryId = currentPost?.category?.id || 0;
    history.push("/community/reply-post/" + categoryId + "/" + currentPost?.id);
  };

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
      postType: "4",
      //categorie: idCategorie,
    });
  };

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
      postType: "4",
      //categorie: idLabel,
    });
  };

  return {
    communityReducer,
    //organization
    loaderOrganization,
    organizations,
    currentPage,
    limitTopOrganization,
    totalOrganization: communityReducer?.topOrganizations?.total,
    nextPage,
    clikPrev,
    allViews,
    view,
    //discussion
    limitDiscution,
    currentDiscutionPage,
    loader,
    sortedBy,
    handleOrganizations,
    handleFilterView,
    resetFilter,
    setPaginateDataFilter,
    setSortedate,
    handlePageClick,
    nbpPageDiscution: Math.ceil(
      communityReducer?.paginate_program_review?.total / limitDiscution
    ),
    goToProfil,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    topLikedAuthor,
    topLikeProgramReview,
    goToreplayPost,
    viewAllContributor,
    viewAllAuthor,
  };
}
