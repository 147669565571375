/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import "../OneCategory.scss";
import React, { useState } from "react";
import Header from "../../../../widget/index";
import Select from "react-select";
import UseProviderArticle from "../../../../../controller/community-ctr/bloc-community-ctr/use-filter-categorie-article";
import {
  ItemDetailsArticle,
  ItemDiscussion,
} from "../../../../../redux/types/community-interface";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  isModerator,
  makeClass,
  paginate,
  refaCtoNullKey,
} from "../../../../../utils";
import { LoadingArticle } from "../../../../widget/loader";
import moment from "moment";
import Pagination from "../../../../widget/use-pagination/pagination";
import { IpropsAuthor } from "../../../../../controller/community-ctr/home-page/top-liked-author";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg";
import { useHistory } from "react-router-dom";
import store from "../../../../../redux/store";

export default function ArticleCategory() {
  const history = useHistory();

  const currentPost = store.getState().communityReducer.curentDiscutionReplyPost.currentPost

  const providerArticle = { ...UseProviderArticle() };

  const offSet = Math.ceil(
    providerArticle?.totalCategorie / providerArticle?.limitCategorie
  );

  const isUserPost = (idUser: string) =>
    idUser === providerArticle?.userReducer?.id?.toString();

  const pinnedAndLocked = (userId: string, roles: any, isDelete: boolean) => {
    return (isUserPost(userId) || isModerator(roles)) && !isDelete;
  };

  const [isActiveCateg, setIsActiveCateg] = useState<number | null>(null);

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  const goToreplyPost = (currentPost: any) => {
    history.push(
      "/community/reply-post/" +
      currentPost?.post_type?.id +
      "/" +
      currentPost?.id
    );
  };

  console.log("HeaderHeader", providerArticle.loading?.toString());

  return (
    <div>
      <Header />
      <section className="discussion">
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li onClick={() => providerArticle?.goHome()}>
              <a href="javascript:;" onClick={(e) => e?.preventDefault()}>
                Community
              </a>
            </li>
            <li onClick={() => providerArticle?.goArticle()}>
              <a href="javascript:;" onClick={(e) => e?.preventDefault()}>
                Articles
              </a>
            </li>
          </ul>

          <div className="searchTopic">
            <SearchForm
              categories={
                providerArticle?.communityReducer?.discussionCategories
              }
              filterSearchText={providerArticle?.filterSearchText}
              handleFilterTxt={providerArticle?.handleFilterTxt}
              searchText={providerArticle?.inputText}
              handleCateGory={providerArticle?.handleCateGory}
            />
          </div>
          <div className="rightCommunityForum allWidth oneCateg">
            <div className="labelLeft">
              <div className="block">
                <h2 className="title">Categories</h2>
                <ul className="labelList collapseUl">
                  {providerArticle?.communityReducer?.detailsArticle?.map(
                    (el: ItemDetailsArticle, indexP: number) => {
                      const childElements =
                        providerArticle?.allCategories[`cat_${el.id}`];

                      const isAllChildInactive = childElements
                        ? childElements.every((element: any) =>
                          providerArticle?.allNotSelectedLabel.includes(
                            parseInt(element)
                          )
                        )
                        : false;

                      return (
                        <li className="liParent" key={indexP}>
                          <button
                            className={`parentUl 
                          ${isActiveCateg === el?.id ? "open active" : ""} 
                          ${isAllChildInactive ? "grayBackground" : ""}`}
                            onClick={() => {
                              if (isActiveCateg === el.id) {
                                setIsActiveCateg(null);
                              } else {
                                providerArticle?.getLabelsInCategories(
                                  el.id,
                                  true
                                );
                                setIsActiveCateg(el.id);
                              }
                            }}
                            title={el?.title}
                          >
                            <div className="parentTitle">
                              <span className="nameOfCategory">
                                {el?.title}
                              </span>
                              <span className="icon-icon-li" />
                              <span className="icon-icon-li-open" />
                            </div>
                            {el?.number_post > 0 && (
                              <span>
                                {providerArticle?.loaderGetLabels &&
                                  providerArticle?.cateGorieArticleSelected ==
                                  el?.id ? (
                                  <Loader />
                                ) : (
                                  el?.number_post
                                )}
                              </span>
                            )}
                          </button>
                          <ul className="child">
                            {paginate(
                              providerArticle?.communityReducer?.topLabelArticle
                                ?.labels,
                              providerArticle?.limitCategorie,
                              providerArticle?.currentPage
                            )?.map((element: any, index: number) => (
                              <li
                                key={index}
                                className={makeClass(
                                  providerArticle?.allSelectedLabel,
                                  providerArticle.allNotSelectedLabel,
                                  element?.label_id,
                                  providerArticle.useSelected
                                )}
                                onClick={() =>
                                  providerArticle?.communityReducer
                                    ?.successFetchPaginate &&
                                  providerArticle?.handleLabelsArticle(
                                    element?.label_id
                                  )
                                }
                                title={element?.label}
                              >
                                <span className="nameOfCategory">
                                  {element?.label}
                                </span>
                                <span>{element?.number_post}</span>
                              </li>
                            ))}

                            {providerArticle?.totalCategorie >
                              providerArticle?.limitCategorie && (
                                <li className="linkForLi nextPrev">
                                  <a
                                    className={
                                      providerArticle?.currentPage === 1
                                        ? "inactive"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      providerArticle?.currentPage !== 1 &&
                                        providerArticle?.clikPrev(
                                          providerArticle?.currentPage
                                        );
                                    }}
                                  >
                                    Previous
                                  </a>
                                  <span>
                                    {providerArticle?.currentPage} of {offSet}
                                  </span>
                                  <a
                                    className={
                                      providerArticle?.currentPage === offSet
                                        ? "inactive"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      providerArticle?.currentPage !== offSet &&
                                        providerArticle?.nextPage(
                                          providerArticle?.currentPage + 1
                                        );
                                    }}
                                  >
                                    Next
                                  </a>
                                </li>
                              )}
                          </ul>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="block">
                <h2 className="title">View</h2>
                <ul className="labelList">
                  {providerArticle?.allViews?.map((el: any, index: number) => (
                    <li
                      className={
                        providerArticle?.view === el?.key ? "active" : ""
                      }
                      key={index}
                      onClick={() =>
                        providerArticle?.communityReducer
                          ?.successFetchPaginate &&
                        providerArticle?.handleFilterView(el?.key)
                      }
                    >
                      {el?.title}
                      {parseInt(el?.total) > 0 && <span>{el?.total}</span>}
                    </li>
                  ))}
                  <li
                    className="linkForLi reset"
                    onClick={() => providerArticle?.resetFilter()}
                  >
                    <a
                      href="javascript:;"
                      className="resetFilter"
                      onClick={(e: any) => e?.preventDefault()}
                    >
                      <span className="icon-refresh" />
                      Reset filter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="containerCategory">
              <div className="titleCategory">
                <h2>
                  <span className="icon-article mr-3" />
                  Articles
                </h2>
                <div className="tri">
                  <div className="labelSelect">
                    <label>Show:</label>
                    <Select
                      className="triNumber"
                      isClearable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerArticle?.limitDiscution?.toString(),
                        label: providerArticle?.limitDiscution?.toString(),
                      }}
                      value={{
                        value: providerArticle?.limitDiscution?.toString(),
                        label: providerArticle?.limitDiscution?.toString(),
                      }}
                      onChange={(params: any) =>
                        providerArticle?.setPaginateDataFilter(params?.value)
                      }
                    />
                  </div>

                  <div className="sortedby labelSelect">
                    <label>Sorted by : </label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={[
                        { value: "post_date", label: "Post date" },
                        { value: "first_activity", label: "First activity" },
                        { value: "last_activity", label: "Latest activity" },
                        { value: "replies", label: "Replies" },
                        { value: "views", label: "Views" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerArticle?.sortedBy,
                        label: "Post date",
                      }}
                      onChange={(params: any) =>
                        providerArticle?.setSortedate(params?.value)
                      }
                    />
                  </div>
                </div>
              </div>
              {providerArticle?.loading ? (
                <LoadingArticle />
              ) : (
                <>
                  <ul className="feedList categoryList">
                    {store.getState().communityReducer?.paginate_article?.discussions?.map(
                      (el: ItemDiscussion, index: number) => (
                        <li
                          key={index}
                          className={`${el?.post_type?.id == 5 ? "pin featArt" : ""
                            }`}
                        >
                          {el?.solved && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check"></span>
                              </div>
                            </div>
                          )}
                          <div className="infoAbout">
                            <div className="withPost">
                              <div
                                className={`imageAffiliatedcompany empty
                                `}
                              >
                                {el?.user?.file_name && (
                                  <img
                                    src={fileUrl(el?.user?.file_name)}
                                    alt="profile"
                                  />
                                )}
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  el?.user?.roles?.[0]
                                )}`}
                              />
                            </div>
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerArticle?.goToreplayPost(el)
                                }
                              >
                                <span className="article">
                                  {el?.post_type?.type}
                                </span>
                                <span className="title">{el?.title}</span>
                              </div>
                              <div className="postBy">
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    providerArticle?.showActivityUser(
                                      el?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>{" "}
                                on{" "}
                                {moment(new Date(el?.created_at)).format(
                                  "MMM D, YYYY h:mm a"
                                )}
                              </div>
                              <div className="postBy">
                                Latest activity {dateDiff(el?.updated_at)} {" "}
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    providerArticle?.showActivityUser(
                                      el?.user?.id.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>
                              </div>
                              <div className="subCategory">
                                {el?.labels?.map(
                                  (element: any, index: number) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        providerArticle?.goToLabelCategory(
                                          element?.category?.id,
                                          element?.id
                                        )
                                      }
                                    >
                                      {element?.title}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{currentPost?.id === el?.id ? currentPost.numberOfLikes : el?.numberOfLikes}</b>Likes
                          </div>
                          <div className="viewInfo">
                            <b>{el?.numberOfViews}</b>views
                          </div>

                          {/* ADD BANNER LOCKED OR PINNED */}
                          {el?.isLocked &&
                            pinnedAndLocked(
                              el?.user?.id?.toString(),
                              providerArticle.userReducer.roles,
                              el?.isDeleted
                            ) && (
                              <img
                                className="pinned-item"
                                src={Locked}
                                alt=""
                              />
                            )}
                          {el?.isPinned && !el?.isLocked && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                          {el?.isLocked &&
                            el?.isPinned &&
                            pinnedAndLocked(
                              el?.user?.id?.toString(),
                              providerArticle.userReducer.roles,
                              el?.isDeleted
                            ) && (
                              <img
                                className="pinned-item"
                                src={Locked}
                                alt=""
                              />
                            )}
                          {el?.isLocked &&
                            el?.isPinned &&
                            !pinnedAndLocked(
                              el?.user?.id?.toString(),
                              providerArticle.userReducer.roles,
                              el?.isDeleted
                            ) && (
                              <img className="pinned-item" src={Pin} alt="" />
                            )}
                        </li>
                      )
                    )}
                  </ul>

                  {providerArticle?.communityReducer?.paginate_article?.total >
                    10 && (
                      <div className="pagginationCategory">
                        <div className="labelSelect">
                          <label>Show:</label>
                          <Select
                            className="triNumber"
                            isClearable={false}
                            options={[
                              { value: "10", label: "10" },
                              { value: "20", label: "20" },
                              { value: "30", label: "30" },
                            ]}
                            classNamePrefix="tri"
                            defaultValue={{
                              value: providerArticle?.limitDiscution?.toString(),
                              label: providerArticle?.limitDiscution?.toString(),
                            }}
                            value={{
                              value: providerArticle?.limitDiscution?.toString(),
                              label: providerArticle?.limitDiscution?.toString(),
                            }}
                            onChange={(params: any) =>
                              providerArticle?.setPaginateDataFilter(
                                params?.value
                              )
                            }
                          />
                        </div>
                        <div className="pagination">
                          <Pagination
                            className="pagination"
                            currentPage={providerArticle?.currentDiscutionPage}
                            totalCount={
                              providerArticle?.communityReducer?.paginate_article
                                ?.total
                            }
                            pageSize={providerArticle?.limitDiscution}
                            onPageChange={(page: any) =>
                              providerArticle?.handlePageClick(page)
                            }
                          />
                        </div>
                      </div>
                    )}
                </>
              )}

              <div className="leftCommunityForum allWidth">
                {providerArticle?.topLikedContributions?.length > 0 && (
                  <div className="block">
                    <h2 className="title">Top liked articles</h2>
                    <ul className="separateElement">
                      {providerArticle?.topLikedContributions?.map(
                        (el: any, index: number) => (
                          <li key={index}>
                            <div className="leftItem txtLabel">
                              <h3
                                className="title"
                                onClick={() => goToreplyPost(el)}
                              >
                                {el?.title}
                              </h3>
                              {el?.labels?.map((label: any, index: number) => (
                                <span
                                  className="topic"
                                  key={index}
                                  onClick={() =>
                                    providerArticle?.goToLabelCategory(
                                      el?.category?.id,
                                      label?.id
                                    )
                                  }
                                >
                                  {label?.title}
                                </span>
                              ))}
                            </div>
                            <div className="rightItem likeContent">
                              <span className="icon-like-full"></span>
                              {el?.numberOfLikes}
                            </div>
                          </li>
                        )
                      )}

                      {providerArticle?.topLikedContributions?.length > 0 && (
                        <li
                          className="linkForLi"
                          onClick={() => providerArticle?.viewAllContributor()}
                        >
                          <a
                            href="javascript"
                            onClick={(e) => e.preventDefault()}
                            className="viewAll"
                          >
                            View all
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                {providerArticle?.topLikedAuthor?.length > 0 && (
                  <div className="block">
                    <h2 className="title">Top liked authors</h2>
                    <ul className="separateElement">
                      {providerArticle?.topLikedAuthor?.map(
                        (author: IpropsAuthor, index: number) => (
                          <li key={index}>
                            <div
                              className="leftItem itemAuthor"
                              onClick={() =>
                                showActivityUser(
                                  author?.user_to_like_id?.toString()
                                )
                              }
                            >
                              <div className="imageAffiliatedcompany empty">
                                {author?.profil_picture && (
                                  <img src={fileUrl(author?.profil_picture)} />
                                )}
                              </div>
                              <div className="infoAffiliated">
                                <h3 className="title">
                                  {refaCtoNullKey(author?.user_name)}
                                </h3>
                                <div className="post">
                                  {refaCtoNullKey(author?.job_title)}
                                </div>
                              </div>
                            </div>
                            <div className="rightItem likeContent">
                              <span className="icon-like-full"></span>
                              {refaCtoNullKey(author?.number_of_like)}
                            </div>
                          </li>
                        )
                      )}
                      {providerArticle?.topLikedAuthor?.length > 0 && (
                        <li
                          className="linkForLi"
                          onClick={() => providerArticle?.viewAllAuthor()}
                        >
                          <a
                            className="viewAll"
                            onClick={(e) => e?.preventDefault()}
                          >
                            View all
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
