import React, { useState } from "react";
import { useHistory } from "react-router";
import store from "../../../redux/store";
import LoginModal from "../login/login-direct";
import "./Header.scss";

export default function SecondHeaderProgram() {
  const history = useHistory();

  const urlPath = window.location.href;
  let isInEvents = urlPath?.indexOf("event");
  let isInEventsTab = isInEvents !== -1 ? "Upcoming events" : "Directory list";
  let isInMatchingTab =
    urlPath?.indexOf("program-matching") !== -1 ||
    urlPath?.indexOf("program/matchmaking") !== -1
      ? true
      : false;

  let isInFavorites = urlPath?.indexOf("favorites") !== -1 || urlPath?.indexOf("favorite") !== -1 ? true : false;

  const [showLogin, setShowLogin] = useState(false);

  const isConnected = store?.getState()?.userReducer?.enable ? true : false;

  const companyPageReducer = store?.getState()?.userReducer?.isvInCompanyPage?.company_page;

  const goToThreeCase = () => {
    if (!isConnected) {
      setShowLogin(true);
      return;
    } else history.push("/programs/program-matching");
  };

  const goToFavorie = () => {
    if (!isConnected) {
      setShowLogin(true);
      return;
    }
    history.push("/programs/favorites");
  };

  const goToProgramHome = () => {
    let isInDirectoryList = isInEvents === -1;
    if (isInDirectoryList) {
      history.push("/programs/home");
    }
  };

  return (
    <div className="secondMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <h2 className="title">Find programs </h2>
          {!isInMatchingTab && !isInFavorites && (
            <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    goToProgramHome();
                  }}
                  className="active"
                >
                  {isInEventsTab}
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    goToThreeCase();
                  }}
                >
                  Go to Program matchmaking
                </a>
              </div>
            </div>
          )}

          {(isInMatchingTab || isInFavorites) && (
            <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    goToThreeCase();
                  }}
                  className={isInMatchingTab ? "active" : ""}
                >
                  Matching results
                </a>
              </div>
              {companyPageReducer && <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    goToFavorie();
                  }}
                  className={isInFavorites ? "active" : ""}
                >
                  Favorites
                </a>
              </div>}
            </div>
          )}
        </div>
        <div className="rightItemHeader"></div>
      </div>
      <LoginModal show={showLogin} setShow={setShowLogin} />
    </div>
  );
}
