import React from "react";
import { Link } from "react-scroll";
type IProps = {
  isShowRessource: boolean;
};
export default function LinkHeader(props: IProps) {
  return (
    <div className="container mx-auto">
      <div className="menuInvestorTab">
        <Link
          activeClass="active"
          className="active about"
          to="about"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-300}
        >
          About
        </Link>
        <Link
          activeClass="active"
          to="expertise"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-95}
        >
          Expertise
        </Link>
        <Link
          activeClass="active"
          to="funding"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-95}
        >
          Funding
        </Link>
        <Link
          activeClass="active"
          to="strategy"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-95}
        >
          {/* Design & Integration #45448 Investor page - Update text in menu bar from "Strategy Partnerhip" into "Strategic Partnerships" */}
          Strategic partnerships
        </Link>
        <Link
          activeClass="active"
          to="portfolio"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-85}
        >
          Portfolio
        </Link>
        {props.isShowRessource && (
          <Link
            activeClass="active"
            to="resources"
            spy={true}
            smooth={true}
            duration={1000}
            offset={-55}
          >
            Resources
          </Link>
        )}
      </div>
    </div>
  );
}
