import { Dispatch } from '../../types/type-action';
import store from '../../store';
// import { updateOemDraft } from "../../../service/applicatif/oem";
import { getDataService } from '../../../service/applicatif/back-end-service';
import { url } from '../../../service/constant/index';
import { updateInfoReseller } from '../../../service/applicatif/company-reseller-page';

export const GET_RESELLER_COMPANY = 'GET_RESELLER_COMPANY';
export const GET_RESELLER_ATTRIBUT = 'GET_RESELLER_ATTRIBUT';
export const REGISTER_RESELLER_CARD_INFORMATION =
  'REGISTER_RESELLER_CARD_INFORMATION';
export const UPDATE_COMPANY_RESELLER = 'UPDATE_COMPANY_RESELLER';

//BILLING CENTER UPDATE CARD INFORMATION
/**********************************
 *
 * Update payment method: card info
 */
export const updateBillingCenterCardResellerCompany = (
  payment_method: string
) => {
  return {
    type: REGISTER_RESELLER_CARD_INFORMATION,
    payload: payment_method,
  };
};

/**
 * @param id id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
export const getCompanyResellerAction = (id: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.reseller?.get_company_page + id,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_RESELLER_COMPANY,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/*********************************************************************
 *
 * Get attribute in company page
 */
export const getCompanyResellerAttributeAction = (
  att: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.reseller?.get_reseller_attribut + att,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_RESELLER_ATTRIBUT,
          payload: {
            data: result && result.data,
            attribute: att,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const updateCompanyResellerAction =
  (id: any, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      let response = await updateInfoReseller(
        id,
        data,
        store && store.getState().userReducer.token
      );
      if (response && response?.status === 200) {
        dispatch({
          type: UPDATE_COMPANY_RESELLER,
          payload: response.data,
        });
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
    }
  };
