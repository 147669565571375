/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import UseCompleteProfilPicture from "../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-profil-picture";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import "./style-complete-profil-picture.css";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import Modal from "../../../../widget/modal/modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";

export default function CompleteProfilPicture() {
  const {
    isFilePicked,
    selectedFile,
    fileRef,
    isLoaderShow,
    skipLoader,
    infoUser,
    error,
    params,
    history,
    setError,
    changeHandler,
    handleBtn,
    validateIsvInscription,
    skipvalidateIsvInscription,
    fileSizeError,
  } = UseCompleteProfilPicture();

  if (!params.state?.completedInfo) {
    return <Redirect to="/signin" />;
  }

  return (
    <div>
      <Header />
      <Modal open={error} handleModal={() => setError(false)} />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Almost done! Upload your profile picture.</h1>
          {fileSizeError && (
            <span className="txt-error">
              The maximum file size is 2 MB. Please upload a photo with a
              smaller file size.
            </span>
          )}
        </div>
        <div className="contentBorder row align-items-stretch small-container">
          <div className="col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated">
            <a
              className={`upload-photo ${selectedFile ? "noBackground" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                handleBtn();
              }}
            >
              {isFilePicked && selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt="" />
              ) : (
                <span></span>
              )}
            </a>
            <div className="info-final-getstarted">
              <div className="nom">
                {infoUser.firstName} {infoUser.lastName}
              </div>
              <div className="post">{infoUser.jobTitle}</div>
              <div className="post">
                <b>{infoUser.companyName}</b>
              </div>
              <div className="membersince">
                {`Member since ${new Date().toLocaleString("en-US", {
                  month: "long",
                })} ${new Date().getDate()}, ${new Date().getFullYear()} `}{" "}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-md-6 col-12 d-flex flex-column align-items-center justify-content-center py-4 p-0 ">
            <a
              onClick={(e) => {
                e.preventDefault();
                handleBtn();
              }}
              className="link-add-photo"
            >
              Add photo
            </a>
            <input
              type="file"
              id="getFile"
              accept="image/png, image/jpeg"
              ref={fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                changeHandler(event)
              }
              style={{ display: "none" }}
            />
            <p>(Max file size: 2 MB)</p>
          </div>
        </div>
        <div className="contentBorder row align-items-stretch">
          <div className="col-12 d-flex align-items-center flex-column p-0">
            <div className="btn btn-devinsider large">
              <BtnNext
                text="Done"
                click={() => validateIsvInscription()}
                showLoader={isLoaderShow}
              />
            </div>
            {skipLoader ? (
              <Loader />
            ) : (
              <a
                className="skip"
                onClick={(e) => {
                  e.preventDefault();
                  skipvalidateIsvInscription();
                }}
              >
                Skip
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
