import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { setSessionStorageObject } from "../../../utils/index";
import { useForm } from "react-hook-form";

export type InputAdressReseller = {
  jobReseller: string;
  companyReseller: string;
  phoneReseller: string;
  countryReseller: string;
};
export default function UseCompleteAdresseReseller() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<InputAdressReseller>({
    defaultValues: {
      countryReseller: sessionStorage.getItem("countryReseller") || "",
      phoneReseller: sessionStorage.getItem("phoneReseller") || "",
      companyReseller: sessionStorage.getItem("companyReseller") || "",
      jobReseller: sessionStorage.getItem("jobReseller") || "",
    },
  });

  const [selected, setSelected] = useState(
    sessionStorage.getItem("phoneNumberCode") || "US"
  );
  const [dialCodeSelected, setDialCodeSelected] = useState(
    sessionStorage.getItem("dialCode") || "+1"
  );
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );

  const goBack = () => {
    history.goBack();
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const onSubmitForm = async (data: InputAdressReseller) => {
    const dataObject = { ...data };
    setSessionStorageObject({ ...dataObject, phoneNumberCode: selected, dialCode: dialCodeSelected });
    history.push("/complete-interested-reseller");
  };

  return {
    goBack,
    dataCompleted,
    onSubmitForm,
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    selected,
    setSelected,
    dialCodeSelected,
    setDialCodeSelected
  };
}
