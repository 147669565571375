import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import "../Top.scss";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import UseViewAll from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-likeds";
import ContentListe from "./container-liste";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import { useHistory } from "react-router-dom";

export default function AuthorContribution() {
  const providerViewAll = UseViewAll();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const goHome = () => {
    history?.push("/community/home");
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li onClick={() => goHome()}>
            <a href="javascript:;" onClick={(e) => e?.preventDefault()}>Community</a>
          </li>
          <li>Discussion categories</li>
        </ul>
        <div className="searchTopic">
          <SearchForm
            categories={providerViewAll?.communityReducer?.discussionCategories}
            filterSearchText={providerViewAll?.filterSearchText}
            handleFilterTxt={providerViewAll?.handleFilterTxt}
            searchText={providerViewAll?.inputText}
            handleCateGory={providerViewAll?.handleCateGory}
          />
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="block">
            <Tab.Container defaultActiveKey={providerViewAll?.defaultActive}>
              <h2 className="title withLink">
                Top liked :
                <Nav.Link
                  eventKey="like_given"
                  onClick={() =>
                    providerViewAll?.handleTypeSelected("like_given")
                  }
                  className={
                    providerViewAll?.defaultActive === "like_given"
                      ? "active"
                      : ""
                  }
                >
                  LIKES GIVEN TO
                </Nav.Link>
                <Nav.Link
                  eventKey="like_from"
                  onClick={() =>
                    providerViewAll?.handleTypeSelected("like_from")
                  }
                  className={
                    providerViewAll?.defaultActive === "like_from"
                      ? "active"
                      : ""
                  }
                >
                  LIKES RECEIVED FROM
                </Nav.Link>
              </h2>
              <Tab.Content>
                <Tab.Pane eventKey="like_from">
                  <ContentListe
                    activeFilter={providerViewAll?.filter}
                    loading={providerViewAll?.loaderLikeFron}
                    dataAuthor={providerViewAll?.dataAuthorLikeFrom?.allData}
                    topLikedAuthor={
                      providerViewAll?.dataAuthorLikeFrom?.topThree
                    }
                    totalData={providerViewAll?.dataAuthorLikeFrom?.totalData}
                    handlePageClick={providerViewAll?.handlePageClickLike}
                    currentPage={providerViewAll?.currentPage}
                    categorie={providerViewAll?.defaultActive}
                    setFilterAuthor={providerViewAll?.setFilterLike}
                    showActivityUser={providerViewAll?.showActivityUser}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="like_given">
                  <ContentListe
                    activeFilter={providerViewAll?.filter}
                    loading={providerViewAll?.loaderLikeGiven}
                    dataAuthor={providerViewAll?.dataAuthorLikeGive?.allData}
                    topLikedAuthor={
                      providerViewAll?.dataAuthorLikeGive?.topThree
                    }
                    totalData={providerViewAll?.dataAuthorLikeGive?.totalData}
                    handlePageClick={providerViewAll?.handlePageClickLike}
                    currentPage={providerViewAll?.currentPage}
                    categorie={providerViewAll?.defaultActive}
                    setFilterAuthor={providerViewAll?.setFilterLike}
                    showActivityUser={providerViewAll?.showActivityUser}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
