import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import OneCase from '../program-matchmaking/complete-info';
import TwoCase from '../program-matchmaking/complete-info-process';
import MatchingProgram from '../../../../hooks/MatchingProgram';

export default function ProgramMatchmakingFavorite() {
  const companyPageReducer = useSelector(
    (state: rootState) => state?.userReducer?.isvInCompanyPage?.company_page
  );

  return (
    <MatchingProgram>
      <div className='findIsvcontainer programContainer'>
        {!companyPageReducer ? 
        <OneCase
          roleUser="program"
         /> : <TwoCase />}
      </div>
    </MatchingProgram>
  );
}
