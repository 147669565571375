import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../../redux/store";
import { MODERATOR, url } from "../../../service/constant";
import { getData } from "../../../service/api";
import { followUnfollowUserAction } from "../../../redux/actions/community-action";
import { IUserActivity } from "../../../interface/user-activity";
import { useHistory } from "react-router-dom";
import { conversationType } from "../../../utils";
import { toast } from "react-toastify";
import { checkPasswordUser } from "../../../service/applicatif/acount";

export default function UserInformation(user: IUserActivity, userId: number) {
  const [companyInfos, setCompanyInfos] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(true);
  const [isFollowedUser, setIsFollowedUser] = useState<boolean>(false);
  const [confirmFollow, setConfirmFollow] = useState<boolean>(false);
  const [isConnectedUser, setIsConnectedUser] = useState<boolean>(false);

  // information for moderator user
  const [showConfirmPasswordModerator, setShowConfirmPasswordModerator] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getInfoUser = async (userId: any) => {
    setIsCurrentUser(
      store.getState().userReducer.id?.toString() === userId?.toString()
    );
    const response = await getData(
      url.community.activity.get_information_company + userId,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setCompanyInfos(response?.data);
    }
    // check if user is connected
    if (store.getState().userReducer.token) setIsConnectedUser(true);
    setIsFollowedUser(user?.followed);
  };

  useEffect(() => {
    if (userId) {
      getInfoUser(userId);
    }
  }, [userId]);

  const cancelFollowClick = () => {
    setConfirmFollow(false);
  };

  const submitFollowClick = (data: any) => {
    setLoader(true);
    let communityAuthorData = { user: data };
    dispatch(
      followUnfollowUserAction(communityAuthorData, cbhandleUnFollowClick)
    );
  };

  const handleUnFollowClick = (data: any) => {
    setConfirmFollow(true);
  };

  const cbhandleUnFollowClick = (response: any) => {
    if (response?.status === 200) {
      setLoader(false);
      setConfirmFollow(false);
      setIsFollowedUser(
        response?.data?.users_followed.some(
          (user: any) => user.id?.toString() === userId?.toString()
        )
      );
    }
  };

  const sendMessage = (id: string) => {
    console.log("sendMessage", id);
    history.push("/contact/discussion", {
      userId: id,
      discussionType: conversationType?.community,
    });
  };

  const banUserProcess = () => {
    //function checkPassword user is the same signature as I need, so I'll use this one
    try {
      (async () => {
        let response = await checkPasswordUser(
          url.acount.ban_user,
          {
            user_email: userId,
          },
          store.getState().userReducer.token
        );
        //Dev #48290 Moderator suppression message
        if (response?.status === 200) {
          toast("The user has been banned successfully from Devinsider!");
          //redirect to current page to see the change
          history.push("/community/home");
        } else {
          toast("An error occurred, please try again");
        }
      })();
    } catch (error: any) {
      toast(
        "An error occurred while processing the operation, try again or contact the administrator. Thanks!"
      );
    }
    setShowConfirmPasswordModerator(false);
  };

  return {
    companyInfos,
    loader,
    handleUnFollowClick,
    isCurrentUser,
    confirmFollow,
    submitFollowClick,
    cancelFollowClick,
    isConnectedUser,
    isFollowedUser,
    sendMessage,
    showConfirmPasswordModerator,
    setShowConfirmPasswordModerator,
    isModeratorUser:
      store.getState().userReducer.roles.indexOf(MODERATOR) !== -1,
    banUserProcess: banUserProcess,
  };
}
