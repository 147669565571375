import React from 'react';
import express from '../../../../resources/images/american-express.svg';
import discover from '../../../../resources/images/discover.svg';
import mastercard from '../../../../resources/images/mastercard.svg';
import visa from '../../../../resources/images/visa.svg';

export const PaymentBrands: React.FC = () => {
    return (
        <div className='ml-0 form-group'>
            <input
                type='radio'
                className='form-check-input regular-checkbox big-checkbox'
                checked={true}
            />
            <ul>
                <li>
                    <img src={visa} alt={'visa'}/>
                </li>
                <li>
                    <img src={mastercard} alt={'mastercard'}/>
                </li>
                <li>
                    <img src={express} alt={'express'}/>
                </li>
                <li>
                    <img src={discover} alt={'discover'}/>
                </li>
            </ul>
        </div>
    )
}