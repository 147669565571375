import React from "react";
import Select from "react-select";
import { ItemContributorSearch } from "../../../../screen/container/community/search-result/types";
import moment from "moment";
import { LoadingArticle } from "../../../widget/loader";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  refaCtoNullKey,
} from "../../../../utils";
import Pagination from "../../../widget/use-pagination/pagination";
import AsyncSelect from "react-select/async";

type IPropsSearchContributor = {
  selectLocationOptions: Array<{ label: string; value: string }>;
  selectedDateOptions: Array<{ label: string; value: string }>;
  selectedMetaDataOptions: Array<{ label: string; value: string }>;
  selectedTypeContribution: Array<{ label: string; value: string }>;
  resultContributor: Array<ItemContributorSearch>;
  loaderContribution: boolean;
  currentPage: number;
  totalData: number;
  pageLimit: number;
  handlePageClick: (p: number) => void;

  handleCateGory: (selectedValue: any) => void;
  handleDate: (selectedValue: any) => void;
  handleMetaData: (selectedValue: any) => void;
  handleContribution: (selectedValue: any) => void;
  promiseOptions: (params: string) => void;
  setAuthorFilter: (params: string) => void;
  categories: string;
  goActivity: (p: string) => void;
  goToreplayPost: (post: any) => void;
};

export default function ContributionsPage(props: IPropsSearchContributor) {
  const loadingMessage = () => "Loading ...";

  return (
    <div>
      <div className="filterContribution">
        <div className="item">
          <label htmlFor="">Location</label>
          <Select
            isClearable
            options={props?.selectLocationOptions}
            classNamePrefix="categories"
            onChange={(selected: any) => props?.handleCateGory(selected)}
          />
        </div>
        <div className="item">
          <label htmlFor="">Author</label>

          <AsyncSelect
            classNamePrefix="zonetext"
            defaultOptions
            isClearable
            placeholder="Search author"
            loadOptions={(p: string) => props?.promiseOptions(p)}
            loadingMessage={() => loadingMessage()}
            onChange={(txt: any) => props?.setAuthorFilter(txt)}
          />
        </div>
        <div className="item">
          <label htmlFor="">Date</label>
          <Select
            isClearable
            classNamePrefix="categories"
            options={props?.selectedDateOptions}
            isSearchable={false}
            onChange={(selected: any) => props?.handleDate(selected)}
          />
        </div>
        <div className="item">
          <label htmlFor="">Metadata</label>
          <Select
            isClearable
            classNamePrefix="categories"
            options={props?.selectedMetaDataOptions}
            isSearchable={false}
            onChange={(selected: any) => props?.handleMetaData(selected)}
          />
        </div>
        <div className="item">
          <label htmlFor="">Type of contribution</label>
          <Select
            classNamePrefix="categories"
            options={props?.selectedTypeContribution}
            isSearchable={false}
            onChange={(selected: any) => props?.handleContribution(selected)}
          />
        </div>
      </div>
      <div className="blockOfList">
        <ul className="feedList categoryList">
          {props?.loaderContribution ? (
            <LoadingArticle />
          ) : (
            props?.totalData > 0 ? (
              props?.resultContributor?.map(
                (items: ItemContributorSearch, index: number) => (
                  <li key={index}>
                    <span className="rankPub">{index + 1}</span>
                    <div className="infoAbout">
                      <div className="withPost">
                        <div className="imageAffiliatedcompany empty">
                          <img src={fileUrl(items?.user?.file_name)} alt="" />
                        </div>
                        <div
                          className={`nameOfPost ${getUserType(
                            items?.user?.roles?.[0]
                          )}`}
                        ></div>
                      </div>
                      <div className="infoFeed">
                        <div
                          className="titlePost"
                          onClick={() => props?.goToreplayPost(items)}
                        >
                          {items?.post_type?.id?.toString() === "2" && (
                            <span className="article">Article</span>
                          )}
                          {items?.title}
                        </div>
                        <div className="postBy">
                          by{" "}
                          <a
                            href="javascript:;"
                            onClick={(e: any) => {
                              e?.preventDefault();
                              props?.goActivity(items?.user?.id?.toString());
                            }}
                          >
                            {/* DEV #47710 V1 [Community] Bug sur la page 'Search' lorsque l'on filtre par Author */}
                            {concatString(
                              items?.user?.first_name,
                              items?.user?.last_name,
                              " "
                            )}
                          </a>{" "}
                          on{" "}
                          {moment(new Date(items?.created_at)).format(
                            "MMM D, YYYY"
                          )}
                        </div>
                        <div className="postBy">
                          Latest activity {dateDiff(items?.updated_at)} by
                          <a
                            href="javascript:;"
                            onClick={(e: any) => {
                              e?.preventDefault();
                              props?.goActivity(items?.last_activity_by?.id);
                            }}
                          >
                            {" "}
                            {concatString(
                              items?.last_activity_by?.first_name,
                              items?.last_activity_by?.last_name,
                              " "
                            )}
                          </a>
                        </div>
                        <div className="subCategory">
                          {items?.labels?.map((element: any, index: number) => (
                            <span key={index}>{element?.title}</span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="replyInfo">
                      <b>{items?.numberOfLikes}</b>Likes
                    </div>
                    <div className="viewInfo">
                      {items?.numberOfViews && items?.numberOfViews > 0 ? (
                        <>
                          <b>{items?.numberOfViews}</b>views
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                )
              )
            ) : (
              <p>There are currently no posts associated with this user.</p>
            )
          )}
        </ul>
        <div className="pagginationCategory">
          <div className="pagination">
            <Pagination
              className="pagination"
              currentPage={props?.currentPage}
              totalCount={props?.totalData}
              pageSize={props?.pageLimit}
              onPageChange={(page: any) => props?.handlePageClick(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
