import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  appendallEvents,
  getallEvents,
} from "../../redux/actions/event-action";
import { rootState } from "../../redux/reducers";
import { concatStringArray, isRequired } from "../../utils";

export default function useAllEvents() {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState<Array<number>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [selectedLocation, setSelectedLocation] = useState<Array<number>>([]);
  const [selectedHosted, setSelectedHosted] = useState<Array<string>>([]);
  const eventObject = useSelector(
    (state: rootState) => state?.eventReducer?.all_events
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const callback = (data: any) => {
    setLoader(false);
    if (eventObject?.results?.length >= eventObject?.total) setHasMore(false);
  };

  const getEvent = (key: string, value: any) => {
    setLoader(true);
    setHasMore(true);
    const virtuaLtypeOnly = selectedType?.length === 1 && selectedType[0] === 1;

    let params = {
      tags: isRequired(selectedTags)
        ? "(" + selectedTags?.join().trim() + ")"
        : "",
      event_type: isRequired(selectedType)
        ? "(" + selectedType?.join().trim() + ")"
        : "",
      country:
        isRequired(selectedLocation) && !virtuaLtypeOnly
          ? "(" + selectedLocation?.join().trim() + ")"
          : "",
      hosted_by: isRequired(selectedHosted)
        ? concatStringArray(selectedHosted)
        : "",
      limit: 10,
      page: 1,
      search: search,
      [key]: value,
    };
    dispatch(getallEvents(params, callback));
  };

  useEffect(() => {
    getEvent("page", 1);
  }, []);

  const resetFilter = () => {
    setSelectedTags([]);
    setSelectedType([]);
    setSelectedHosted([]);
    setSelectedLocation([]);
    setSearch("");
    setPage(1);
    setLoader(true);
    let params = {
      tags: "",
      event_type: "",
      country: "",
      hosted_by: "",
      limit: 10,
      page: 1,
      search: "",
    };
    dispatch(getallEvents(params, callback));
  };

  const filter = () => {
    setPage(1);
    getEvent("page", 1);
  };

  const handleSearch = (event: any) => {
    setSearch(event?.target?.value);
    setPage(1);
    if (event?.key === "Enter") {
      getEvent("search", event?.target?.value);
    }
  };

  const handlePageChange = (page: number | string) => {
    let temp = typeof page === "string" ? parseInt(page) : page;
    setPage(temp);
    getEvent("page", temp);
  };

  const handleNext = (page: number) => {
    setPage(page);
    setHasMore(true);
    let params = {
      tags: isRequired(selectedTags)
        ? "(" + selectedTags?.join().trim() + ")"
        : "",
      event_type: isRequired(selectedType)
        ? "(" + selectedType?.join().trim() + ")"
        : "",
      country: isRequired(selectedLocation)
        ? "(" + selectedLocation?.join().trim() + ")"
        : "",
      hosted_by: isRequired(selectedHosted)
        ? concatStringArray(selectedHosted)
        : "",
      limit: 10,
      page: page,
      search: search,
    };
    dispatch(appendallEvents(params, callback));
  };

  return {
    eventObject,
    selectedTags,
    selectedType,
    resetFilter,
    setSelectedTags,
    setSelectedType,
    filter,
    search,
    setSearch,
    page,
    loader,
    handleSearch,
    handlePageChange,
    handleNext,
    hasMore,
    selectedLocation,
    setSelectedLocation,
    selectedHosted,
    setSelectedHosted,
  };
}
