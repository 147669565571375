/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";

export type IPropsManageProgram = {
  showTargeting: () => void;
  showManagePage: () => void;
  desactiveAccount: () => void;
};
export default function ManageProgrameOem(props: IPropsManageProgram) {
  //Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
  const currentState = useSelector((state: rootState) => state);
  const currentOemProgram = currentState?.oemProgramReducer?.program;
  let userObject = useSelector((state: rootState) => state.userReducer);

  const currentMemberRoleInProgram = currentOemProgram.oemProgramMembers.find(
    (member) => member.user.id === userObject.id
  );
  return (
    <ul className="linkManageProgram">
      <li>
        <a
          href="javascript:;"
          onClick={(e) => {
            e.preventDefault();
            props.showTargeting();
          }}
        >
          Program targeting
        </a>
      </li>

      {currentOemProgram?.uuid !== "" &&
        currentMemberRoleInProgram &&
        currentMemberRoleInProgram.role === "PROGRAM_OWNER" && (
          <li>
            <a
              href="javascript:;"
              onClick={(e) => {
                e.preventDefault();
                props.desactiveAccount();
              }}
            >
              Deactivate program
            </a>
          </li>
        )}
    </ul>
  );
}
