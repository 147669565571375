import React from "react";
import { Link } from "react-scroll";
type IProps = {
  isEmptyFile: boolean;
};
export default function Index(props: IProps) {
  return (
    <div className="menuInvestorTab">
      <Link
        activeClass="active"
        className="active about"
        to="about"
        spy={true}
        smooth={true}
        duration={1000}
        offset={-300}
      >
        About
      </Link>
      <Link
        activeClass="active"
        to="overview"
        spy={true}
        smooth={true}
        duration={1000}
        offset={-95}
      >
        Overview
      </Link>
      <Link
        activeClass="active"
        to="solutions"
        spy={true}
        smooth={true}
        duration={1000}
        offset={-95}
      >
        Solutions
      </Link>
      <Link
        activeClass="active"
        to="key"
        spy={true}
        smooth={true}
        duration={1000}
        offset={-95}
      >
        Key benefits
      </Link>
      <Link
        activeClass="active"
        to="started"
        spy={true}
        smooth={true}
        duration={1000}
        offset={-75}
      >
        Getting started
      </Link>
      {!props.isEmptyFile && (
        <Link
          activeClass="active"
          to="resource"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-15}
        >
          Resources
        </Link>
      )}
    </div>
  );
}
