/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useEffect, useState } from "react";

import ClassificAddsHeaderMessage from "./classific-adds-header-message";
import CommunityHeaderMessage from "./community-header-message";
import IsvToInvestorHeaderMessage from "./isv-to-investor-header-message";
import IsvToOemHeaderMessage from "./isv-to-oem-header-message";
import MAheaderMessage from "./ma-header-message";
import OemToIsvheadermessage from "./oem-to-isv-header-message";
import AlternativeHeaderMessage from "./alternative-header-message";

import Highlighter from "react-highlight-words";
import InvestorToIsv from "./investor-to-isv-message";

import { useSelector } from "react-redux";
import {
  Imessage,
  InfoCompany,
  InfoCompanyInvestorIsv,
  InfoCompanyOemIsv,
  InfoUser,
} from "../../../../../controller/tchat-ctr/type";
import { rootState } from "../../../../../redux/reducers";
import {
  concatString,
  conversationType,
  dateDiff,
  fileUrl,
  isFileOrVideo,
  isImageExtension,
} from "../../../../../utils";

import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
} from "../../../../../service/constant";
import { LoadingFileUpload } from "../../../../widget/loader/loading-file-upload";

type ITypeAndProfil = {
  profil: string;
  type: string;
};

type IPropsDiscussion = {
  discussionType: string;
  infoCompanyIsvToOem: InfoCompany;
  infoCompanyOemToIsv: InfoCompanyOemIsv;
  infoCompanyInvestorIsv: InfoCompanyInvestorIsv;
  infoCompanyIsvToInvestor: InfoCompany;
  infoCompanyClassificAdds: InfoCompany;
  infoCorporate: InfoUser;
  infoComunity: any;
  infoAlternative: any;
  showProfil: (id: string) => void;
  msgTxt: string;
  handleMsg: (txt: string) => void;
  openBlock: () => void;
  sendMsg: () => void;
  allMessage: Array<Imessage>;
  seeMessage: () => void;
  chatParent: any;
  refFile: any;
  handleAddFile: () => void;
  changeHandler: (evt: any) => void;
  selectedFile: Array<File>;
  removeFileSelected: (index: number, el: File) => void;
  loaderSendFile: boolean;
  matchingTxt: string;
  loadOtherMessge: () => void;
  loadOtherMessageInSearch: (scrollType: string) => void;
  isSearchTxtMessage: boolean;
  allFilterTxtMessage: Array<number>;
  handleClickUpDown: (p: string) => void;
  currentIndexFilter: number;
  lineRefs: any;
  txtFilterInMessage: string;
  setTxtFilterInMessage: (p: string) => void;
  setIsFocusMainResearch: (p: boolean) => void;
  parentHeigth: any;
  isLoadingFetchMessage: boolean;
  conversationId: number;
  isSending: boolean;
};

const TchatMessage = (props: IPropsDiscussion) => {
  // props.seeMessage();
  const userObject = useSelector((state: rootState) => state?.userReducer);
  const oemPage = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const companyResellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const companyPageInvestor = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );

  const [toggle, setToggle] = useState(false);

  const [showEmoji, setShowEmoji] = useState(false);

  const [messagesFiltered, setMessagesFiltered] = useState<Imessage[]>([]);

  const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");

  useEffect(() => {
    if (props?.conversationId.toString() !== "0") {
      const filtered = props?.allMessage.filter(
        (item: Imessage) =>
          item.conversation.id.toString() == props?.conversationId.toString()
      );
      const uniqueArray = filtered.filter(
        (ele, pos) => filtered.indexOf(ele) == pos
      );

      setMessagesFiltered(uniqueArray);
    }
  }, [props?.conversationId, props?.allMessage]);

  const getProfilAndTypeCompany = () => {
    const isIsv = userObject.roles?.[0] === ISV;
    const isOem = userObject.roles?.[0] === OEM_PROGRAM;
    const isInvestor = userObject.roles?.[0] === INVESTOR;
    const isReseller = userObject.roles?.[0] === RESELLER;
    const isGuest = userObject.roles?.[0] === GUEST;
    const isExpert = userObject.roles?.[0] === CONTRIBUTOR;

    if (isOem) {
      return { type: "OEM", profil: oemPage?.photo_profil };
    }
    if (isIsv) {
      return { type: "ISV", profil: companyPageObject?.photo_profil };
    }
    if (isGuest) {
      return { type: "GUEST", profil: userObject?.guestCompany?.logo };
    }
    if (isReseller) {
      return { type: "RESELLER", profil: companyResellerPage?.photo_profil };
    }
    if (isInvestor) {
      return { type: "INVESTOR", profil: companyPageInvestor?.photo_profil };
    }
    if (isExpert) {
      return {
        type: "EXPERT CONTRIBUTOR",
        profil: userObject?.expertCompany?.logo,
      };
    }
    return { type: "", profil: "" };
  };

  const typeAndProfil: ITypeAndProfil = getProfilAndTypeCompany();

  const handleScroll = (evt: any) => {
    const position = evt.target.scrollTop;
    setPrevScrollPos(position);

    const isInBottom = props.parentHeigth - position < 350;
    const isSrollIngTop = position < prevScrollPos;

    const isScrolInConversation =
      position <= 300 &&
      !props.isLoadingFetchMessage &&
      !props.isSearchTxtMessage &&
      props.allMessage.length > 0;

    const isScrolInMainMessage =
      position <= 300 &&
      !props.isLoadingFetchMessage &&
      props.isSearchTxtMessage &&
      props.allMessage.length > 0;

    const isInMainScrollBottom =
      isInBottom &&
      props.isSearchTxtMessage &&
      props.allMessage.length > 0 &&
      !props.isLoadingFetchMessage;

    if (isScrolInConversation && isSrollIngTop) {
      props.loadOtherMessge();
    }

    if (isScrolInMainMessage) {
      props.loadOtherMessageInSearch("top");
    }

    if (isInMainScrollBottom) {
      props.loadOtherMessageInSearch("bottom");
    }
  };

  const isLastSelectedFile = (index: number) =>
    props.loaderSendFile && index === props.selectedFile?.length - 1;

  const checkIfChowDateMessage = (date1: string, date2: string) => {
    let dayOne = new Date(date1).getDate();
    let dayTwo = new Date(date2).getDate() || "";
    if (dayOne === null) {
      return true;
    }
    if (dayOne == dayTwo) {
      return false;
    } else if (dayOne != dayTwo) {
      return true;
    }
  };

  const indexStatus =
    props.allFilterTxtMessage?.length > 0 ? props.currentIndexFilter + 1 : 0;

  const getNameSender = (message: Imessage) => {
    return concatString(
      message.sender.first_name + " " + message.sender.last_name
    );
  };

  const isCreatedConv =
    props.conversationId !== -1 && props.conversationId !== 0;

  useEffect(() => {
    if (props.conversationId) {
      scrollTchatParent();
    }
  }, [messagesFiltered]);

  const scrollTchatParent = () => {
    props.chatParent?.current?.scrollTo({
      top: props.chatParent?.current?.scrollHeight,
      behavior: "smooth",
    });
  };

  const sortByIDAscending = (a: Imessage, b: Imessage) => {
    return a.id - b.id;
  };

  return (
    <>
      <div className="tchat-title" ref={props?.lineRefs}>
        {props.discussionType === conversationType?.isv_to_oem && (
          <IsvToOemHeaderMessage
            companyName={props.infoCompanyIsvToOem?.company_name}
            profil={props.infoCompanyIsvToOem?.photo_profil}
            roleDiscussion={props.infoCompanyIsvToOem?.name}
          />
        )}

        {props.discussionType === conversationType?.investor_to_isv && (
          <InvestorToIsv
            companyName={props.infoCompanyInvestorIsv?.company_name}
            profil={props.infoCompanyInvestorIsv?.photo_profil}
            roleDiscussion={props.infoCompanyInvestorIsv?.name}
          />
        )}

        {props.discussionType === conversationType?.oem_to_isv && (
          <OemToIsvheadermessage
            companyName={props.infoCompanyOemToIsv?.company_name}
            profil={props.infoCompanyOemToIsv?.photo_profil}
            roleDiscussion={props.infoCompanyOemToIsv?.name}
            companyId={props.infoCompanyOemToIsv?.id?.toString()}
          />
        )}

        {props.discussionType === conversationType?.isv_to_investor && (
          <IsvToInvestorHeaderMessage
            companyName={props.infoCompanyIsvToInvestor?.company_name}
            profil={props.infoCompanyIsvToInvestor?.photo_profil}
            roleDiscussion={props.infoCompanyIsvToInvestor?.name}
          />
        )}

        {props.discussionType === conversationType?.community && (
          <CommunityHeaderMessage
            profil={props.infoComunity?.file_name}
            userName={
              props.infoComunity?.first_name
                ? concatString(
                    props.infoComunity?.first_name +
                      " " +
                      props.infoComunity?.last_name
                  )
                : ""
            }
            userId={props?.infoComunity?.id}
            showProfil={(id: string) => props.showProfil(id)}
          />
        )}

        {(props.discussionType === conversationType?.classific_adds_entity ||
          props.discussionType === conversationType?.entity_classific_adds) && (
          <ClassificAddsHeaderMessage
            companyName={props.infoCompanyClassificAdds?.company_name}
            profil={props.infoCompanyClassificAdds?.photo_profil}
            roleDiscussion={props.infoCompanyClassificAdds?.name}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject?.company_name
            }
            profil_current_user={typeAndProfil?.profil}
            roleDiscussion_current={typeAndProfil?.type}
          />
        )}
        {(props.discussionType === conversationType?.MA ||
          props.discussionType === conversationType?.MA_entity) && (
          <MAheaderMessage
            profil={props.infoCorporate?.file_name}
            userName={concatString(
              props.infoCorporate?.first_name +
                " " +
                props.infoCorporate?.last_name
            )}
            roleDiscussion={props.infoCorporate?.job_title}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject?.company_name
            }
            profil_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.photo_profil
                : userObject?.file_name
            }
            ma_id={props?.infoCorporate?.company_id}
            roleDiscussion_current={userObject?.job_title}
          />
        )}

        {props.discussionType === conversationType?.alternative && (
          <AlternativeHeaderMessage
            profil={props.infoAlternative?.file_name}
            userName={concatString(
              props.infoAlternative?.first_name +
                " " +
                props.infoAlternative?.last_name
            )}
            roleDiscussion={props.infoAlternative?.job_title}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject?.company_name
            }
            profil_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.photo_profil
                : userObject?.file_name
            }
            ma_id={props?.infoAlternative?.company_id}
            roleDiscussion_current={userObject?.job_title}
          />
        )}
      </div>
      <div
        className={`search-message ${props.isSearchTxtMessage ? "" : "d-none"}`}
      >
        <div className="search-box form-group has-search">
          <span className="icon-search-oem form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search in conversation"
            value={props.txtFilterInMessage}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              props.setTxtFilterInMessage(evt.target.value)
            }
            onFocus={() => props.setIsFocusMainResearch(true)}
            onBlur={() => {
              props.setIsFocusMainResearch(false);
            }}
          />
          <span className="form-control-feedback">
            {indexStatus}/{props?.allFilterTxtMessage?.length}
          </span>
          <div className="control">
            <span
              className="icon-msg-up-list"
              onClick={() => props?.handleClickUpDown("up")}
            />
            <span
              className="icon-msg-up-list down"
              onClick={() => props?.handleClickUpDown("down")}
            />
          </div>
          <div className="closed">
            <a href="javascript:;">Fermer</a>
          </div>
        </div>
        <hr />
      </div>

      <div
        onScroll={(ev) => handleScroll(ev)}
        ref={props.chatParent}
        className="tchat-message"
        id="parent-targed"
      >
        <div>
          {messagesFiltered
            ?.sort(sortByIDAscending)
            ?.map(
              (
                message: Imessage,
                index: number,
                arrayMessage: Array<Imessage>
              ) => (
                <div
                  key={index + "listm"}
                  id={index + "listm"}
                  className={`${
                    message?.isInvitation
                      ? "message-content-parent-invitation"
                      : "message-content-parent"
                  }`}
                >
                  <div className="newTchat">
                    <div className="date">
                      <span>
                        {checkIfChowDateMessage(
                          arrayMessage[index - 1]?.created_at,
                          message.created_at
                        ) &&
                          moment(new Date(message.created_at)).format("MMM DD")}
                      </span>
                    </div>
                  </div>
                  <div className="one-message">
                    <div className="user-photo-profil">
                      <img src={fileUrl(message?.sender?.file_name)} alt="" />
                    </div>
                    <div
                      className={`${
                        message?.isInvitation
                          ? "message-content-invitation"
                          : "message-content"
                      }`}
                    >
                      <div className="profil">
                        <div className="name-user">
                          {getNameSender(message)}
                        </div>
                        <div className="time">
                          {dateDiff(message?.created_at)}
                        </div>
                      </div>
                      <div className="full-message">
                        <Highlighter
                          highlightClassName="match"
                          searchWords={[props?.matchingTxt]}
                          autoEscape={true}
                          textToHighlight={message?.message}
                        />
                      </div>
                      <div>
                        {message.attachements?.map((file: any) => {
                          return (
                            <ul className="shared">
                              {isImageExtension(file?.extension) ? (
                                <li className="fileImages">
                                  <img
                                    alt=""
                                    src={fileUrl(file?.file_location)}
                                  />
                                  <a className="cl" href="javascript:;"></a>
                                </li>
                              ) : (
                                <li className="fileOthers">
                                  <a
                                    href={fileUrl(file.file_location)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="icon-empty-file"></span>{" "}
                                    <span className="nameFile">
                                      {file?.file}
                                    </span>
                                  </a>
                                </li>
                              )}
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          borderBottom: "3px solid #efedee",
          borderTop: 0,
        }}
        className="tchat-border"
      />
      <div className={`${toggle && "messageOpen"} taping-box withApercu`}>
        <ul
          className={`filesMiniature ${
            props.selectedFile?.length < 1 && "d-none"
          }`}
        >
          {props?.selectedFile?.map((el: File, index) =>
            isFileOrVideo(el?.name, "image") ? (
              <li className="fileImages" key={index}>
                <img alt="" src={URL.createObjectURL(el)} />
                <a
                  className="cl"
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    props?.removeFileSelected(index, el);
                  }}
                >
                  <span className="icon-close ic" />
                </a>
                {isLastSelectedFile(index) && <LoadingFileUpload />}
              </li>
            ) : (
              <li className="fileOthers" key={index}>
                <span className="icon-empty-file"></span>{" "}
                <span className="nameFile">{el?.name}</span>
                <a
                  className="cl"
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    props?.removeFileSelected(index, el);
                  }}
                >
                  <span className="icon-close ic" />
                </a>
                {isLastSelectedFile(index) && <LoadingFileUpload />}
              </li>
            )
          )}
        </ul>
        <textarea
          className="form-control textForMessage"
          placeholder="Type your message here…"
          value={props.msgTxt}
          onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
            props.handleMsg(evt.target.value)
          }
          onFocus={() => props.seeMessage()}
        ></textarea>
        <a onClick={() => setToggle(!toggle)}>
          <span className="icon-msg-up" />
        </a>

        <div className="action-zone container">
          <div className="icons-action">
            <span
              className={`${
                !isCreatedConv ? "d-none" : "icon-pj ico-reaction"
              }`}
              onClick={() => !props?.loaderSendFile && props.handleAddFile()}
            />
            <span
              className={`${
                !isCreatedConv ? "d-none" : "icon-media ico-reaction"
              }`}
              onClick={() => !props?.loaderSendFile && props.handleAddFile()}
            >
              <input
                type="file"
                name=""
                id=""
                style={{ display: "none" }}
                ref={props.refFile}
                onChange={(evt: any) => props?.changeHandler(evt)}
              />
            </span>

            <a onClick={() => setShowEmoji(!showEmoji)}>
              <span className="icon-emoticon ico-reaction" />
            </a>
            {showEmoji && (
              <div className="emojiTchat">
                <Picker
                  set="google"
                  onSelect={(value: any) => {
                    props.handleMsg(props.msgTxt + " " + value?.native);
                    setShowEmoji(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className="send">
            <button
              onClick={() => props.sendMsg()}
              disabled={
                props.loaderSendFile || props?.msgTxt == "" || props?.isSending
              }
              className={`send-button mr-2 ${
                props?.msgTxt === "" ||
                props?.loaderSendFile ||
                props?.isSending
                  ? "disabled-send-msg-btn"
                  : ""
              } `}
            >
              {props?.isSending ? <Loader /> : "send"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TchatMessage;
