/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "./style-complete-interested.css";
import UseCompleteInterested from "../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-interested";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import Header from "../../../../widget/header-logo-center/header-logo-center";

export default function CompleteInterested() {
  const {
    dataInterested,
    dataChecked,
    loader,
    params,
    history,
    checkedRadio,
    navigateCompletePicture,
  } = UseCompleteInterested();

  if (!params.state?.completedInfo) {
    return <Redirect to="/signin" />;
  }

  return (
    <div>
      <Header />
      <div className={`loader-container ${loader ? "d-flex" : "d-none"}`}></div>
      <div className="getstartedContainer">
        <div className="head-getstarted mini">
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Tell us what topics are you interested in.</h1>
        </div>
        {loader && <Loader />}

        <div className="contentBorder justify-content-center flex-wrap content-link-topic d-flex pb-3">
          {dataInterested
            ?.filter(
              (element: any, index: number) =>
                element?.main && element?.icone !== ""
            )
            ?.map((v: any, index: number) => (
              <div
                key={index}
                onClick={() => checkedRadio(v.id)}
                className={`link-topic ${
                  dataChecked && dataChecked?.find((el) => el == v.id)
                    ? "active"
                    : ""
                }`}
              >
                {<div dangerouslySetInnerHTML={{ __html: v && v.icone }} />}
                <span>{v.title}</span>
              </div>
            ))}
        </div>
        <div className="contentBorder py-3 border-top d-flex flex-wrap checkListTopic">
          {dataInterested
            ?.filter((element) => !element.main || element.icone === "")
            .map((element, index: number) => (
              <div
                className="custom-control custom-checkbox getstartedcheckbox"
                key={index}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`checkbox${element.id}`}
                  defaultChecked={
                    dataChecked && dataChecked.find((el) => el == element.id)
                      ? true
                      : false
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor={`checkbox${element.id}`}
                  onClick={() => checkedRadio(element.id)}
                >
                  {element.title}
                </label>
              </div>
            ))}
        </div>
        <div className="contentBorder row align-items-stretch">
          <div className="col-12 d-flex align-items-center p-0">
            <button
              className="btn btn-devinsider large"
              onClick={() =>
                dataChecked && dataChecked.length >= 3
                  ? navigateCompletePicture()
                  : null
              }
            >
              {dataChecked && dataChecked.length >= 3
                ? "Next"
                : `Select at least ${3 - dataChecked.length} more`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
