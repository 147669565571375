import React from 'react';
import Header from '../../../widget/index';
import Footer from '../../../widget/footer/footer';
import { useHistory } from 'react-router';

export default function GetStartedMarketplace() {
  const history = useHistory();

  const goToGetStarted = (): void => {
    history.push('/account-choices');
  };
  //redirect to browse program
  const goToBrowseProgram = () => {
    history.push('/programs/home');
  };
  return (
    <div className='landing_market'>
      <Header />
      <section className='joinHome market'>
        <div className='container mx-auto'>
          <h1 className='title'>Marketplace</h1>
          <p>
            Identify partnership opportunities with ISVs, resellers, <br />
            OEMs, ecosystems, and other professionals working in the <br />
            software industry.
          </p>
          <a
            onClick={() => goToGetStarted()}
            className='getStartedLink flex-column'
          >
            Get started
            <span className='itsfree small'>(It's free!)</span>
          </a>
        </div>
      </section>
      <section className='bggris softwareVendors'>
        <div className='container mx-auto'>
          <p className='text-center'>
            The Devinsider marketplace provides software vendors with access to
            strategic partnership opportunities among key-audiences <br />
            in the software industry. Use the different features to tap into our
            global community and fuel your business development efforts.
          </p>
          <div className='list'>
            <div className='item'>
              <span className='title'>Directory</span>
              <div className='smallFont'>
                <div className='subtitle'>
                  Browse a directory of partnership programs for software
                  vendors (ISVs).
                </div>
                <ul>
                  <li>
                    <span className='icon-check ico'></span>Extensive directory
                  </li>
                  <li>
                    <span className='icon-check ico'></span>Global coverage
                  </li>
                  <li>
                    <span className='icon-check ico'></span>Large and niche
                    players
                  </li>
                </ul>
                <a className='btn-more' onClick={() => goToBrowseProgram()}>
                  Get started
                </a>
              </div>
            </div>
            <div className='item'>
              <span className='title'>Technology partner program</span>
              <div className='smallFont'>
                <div className='subtitle'>
                  Match with OEM and ecosystem partner programs based on your
                  company profile and preferences.
                </div>
                <ul>
                  <li>
                    <span className='icon-check ico'></span>Advanced matchmaking
                  </li>
                  <li>
                    <span className='icon-check ico'></span>Easily compare
                  </li>
                  <li>
                    <span className='icon-check ico'></span>Directly contact a
                    representative
                  </li>
                </ul>
                <a className='btn-more' onClick={() => goToGetStarted()}>
                  Get started
                </a>
              </div>
            </div>
            <div className='item'>
              <span className='title'>Software white labeling</span>
              <div className='smallFont'>
                <div className='subtitle'>
                  White label your software solution to other software vendors.
                </div>
                <ul>
                  <li className='softLabeling'>
                    <span className='icon-check ico'></span>OEM partnerships,
                    APIs, and white labeling business models
                  </li>
                  <li>
                    <span className='icon-check ico'></span>Help your peers
                    innovate
                  </li>
                  <li className='softLabeling'>
                    <span className='icon-check ico'></span>Offer alternatives
                    to large established OEM programs
                  </li>
                </ul>
                <a className='btn-more' href='javascript:;'>
                  Coming soon
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
