import "../OneCategory.scss";
import React, { useEffect, useState } from "react";
import Header from "../../../../widget/index";
import Select from "react-select";
import UsePressReleaseCtr from "../../../../../controller/community-ctr/bloc-community-ctr/use-filter-press-release-ctr";
import { ItemDiscussion } from "../../../../../redux/types/community-interface";
import {
  concatString,
  dateDiff,
  fileUrl,
  isModerator,
  refaCtoNullKey,
} from "../../../../../utils";
import moment from "moment";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import * as BlockComponent from "./bloc-filter-pr/index";
import { IpropsAuthor } from "../../../../../controller/community-ctr/home-page/top-liked-author";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../redux/reducers";

import PressReleaseComponent from "../../../../../hooks/PressReleaseComponent";

export default function PressReleases() {
  const providerPR = { ...UsePressReleaseCtr() };
  const programReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNameUser = (user: any) => {
    return user?.first_name + " " + user?.last_name;
  };

  const [selectedOption, setSelectedOption] = useState({ value: "10", label: "10" });

  const handleChange = (option: any) => {
    setSelectedOption(option);
    providerPR?.handleLimit(Number(option?.value));
  };

  return (
    <PressReleaseComponent>
      <div>
        <Header />
        <section className="discussion">
          <div className="container mx-auto">
            <BlockComponent.HeaderBlue />
            <div className="searchTopic">
              <SearchForm
                categories={providerPR?.communityReducer?.discussionCategories}
                filterSearchText={providerPR?.filterSearchText}
                handleFilterTxt={providerPR?.handleFilterTxt}
                searchText={providerPR?.inputText}
                handleCateGory={providerPR?.handleCateGory}
              />
            </div>
            <div className="rightCommunityForum allWidth oneCateg">
              <BlockComponent.Tags
                allSelectedLabel={providerPR?.allSelectedLabel}
                allNotSelectedLabel={providerPR?.allNotSelectedLabel}
                useSelected={providerPR?.useSelected}
                clikPrev={providerPR?.clikPrev}
                currentPage={providerPR?.currentPage}
                labels={providerPR?.communityReducer?.topTags?.labels}
                limitTopLabel={providerPR?.limitTopLabel}
                loaderGetTags={providerPR?.loaderGetTags}
                nextPage={providerPR?.nextPage}
                paginate={providerPR?.paginate}
                resetFilter={providerPR?.resetFilter}
                setLabelsFilter={providerPR?.setLabelsFilter}
                successFetchPaginate={
                  providerPR?.communityReducer?.successFetchPaginate
                }
                totalLabel={providerPR?.totalLabel}
              />

              <div className="containerCategory">
                <BlockComponent.FilterSelect
                  handleChange={handleChange}
                  handleSorted={(params: any) =>
                    providerPR?.handleSorted(params?.value)
                  }
                  selectedOption={selectedOption}
                />

                <ul className="feedList categoryList pressRelease">
                  {providerPR?.loaderPosts ? (
                    <LoadingArticle />
                  ) : (
                    <>
                      {providerPR?.communityReducer?.paginate_press_release?.discussions?.map(
                        (el: ItemDiscussion, index: number) => (
                          <li key={index}>
                            <div className="solved">
                              {el?.solved && (
                                <div className="contentSolved">
                                  <span className="icon-check" />
                                </div>
                              )}
                            </div>
                            <div className="infoAbout">
                              <div className="withPost press">
                                <div className="imageAffiliatedcompany empty">
                                  {el?.user?.file_name && (
                                    <img
                                      src={fileUrl(el?.user?.file_name)}
                                      alt=""
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="infoFeed">
                                <div
                                  className="titlePost"
                                  onClick={() => providerPR?.goToreplayPost(el)}
                                >
                                  {el?.title}
                                </div>
                                <div className="postBy">
                                  by{" "}
                                  <div
                                    className="showpopOverPerso"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      providerPR?.goActivity(
                                        el?.user?.id?.toString()
                                      );
                                    }}
                                  >
                                    <a href="javascript:;" className="cName">
                                      {isModerator(el?.user?.roles)
                                        ? el?.user?.first_name +
                                          " " +
                                          el?.user?.last_name
                                        : concatString(
                                            el?.user?.first_name,
                                            el?.user?.last_name,
                                            " "
                                          )}
                                    </a>
                                    {providerPR.indexShowPopUp === index && (
                                      <BlockComponent.PopUpOver
                                        onMouseLeave={() =>
                                          providerPR.mouseLeave()
                                        }
                                        allPressReleaseByUserTotal={
                                          providerPR?.allPressReleaseByUser
                                            .total
                                        }
                                        clikPrevPr={(p: any) =>
                                          providerPR.clikPrevPr(p)
                                        }
                                        companyPageIdSelected={providerPR?.companyPageIdSelected?.toString()}
                                        company_name={
                                          isModerator(el?.user?.roles)
                                            ? getNameUser(el?.user)
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage
                                                ?.company_page?.company_name
                                            : programReducer?.program
                                                ?.company_name
                                        }
                                        company_summary={
                                          isModerator(el?.user?.roles)
                                            ? ""
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage
                                                ?.company_page?.company_summary
                                            : programReducer?.program
                                                ?.program_name
                                        }
                                        currentCompanyPageSelected={
                                          isModerator(el?.user?.roles)
                                            ? el?.user?.id?.toString()
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage?.company_page?.id?.toString()
                                            : programReducer?.program?.id?.toString()
                                        }
                                        currentPagePrGet={
                                          providerPR?.currentPagePrGet
                                        }
                                        isShowPopUpRelease={
                                          providerPR?.isShowPopUpRelease
                                        }
                                        nextPagePr={(p: any) =>
                                          providerPR.nextPagePr(p)
                                        }
                                        photo_profil={
                                          isModerator(el?.user?.roles)
                                            ? ""
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage
                                                ?.company_page?.photo_profil
                                            : programReducer?.program
                                                ?.photo_profil
                                        }
                                        prListe={
                                          providerPR?.allPressReleaseByUser
                                            ?.prListe
                                        }
                                      />
                                    )}
                                  </div>{" "}
                                  on{" "}
                                  {moment(new Date(el?.created_at)).format(
                                    "MMM D, YYYY h:mm a"
                                  )}
                                </div>
                                <div className="postBy">
                                  Latest activity{" "}
                                  {" " + dateDiff(el?.updated_at)} by hours ago
                                  by{" "}
                                  <div className="showpopOverPerso">
                                    <div
                                      onClick={(e) => {
                                        e?.preventDefault();
                                        providerPR?.goActivity(
                                          el?.user?.id?.toString()
                                        );
                                      }}
                                    >
                                      <a href="javascript:;" className="cName">
                                        {isModerator(el?.user?.roles)
                                          ? el?.user?.first_name +
                                            " " +
                                            el?.user?.last_name
                                          : concatString(
                                              el?.user?.first_name,
                                              el?.user?.last_name,
                                              " "
                                            )}
                                      </a>
                                    </div>
                                    {providerPR.indexShowPopUpLastActivity ===
                                      index && (
                                      <BlockComponent.PopUpOver
                                        onMouseLeave={() =>
                                          providerPR.mouseLeaveActivity()
                                        }
                                        allPressReleaseByUserTotal={
                                          providerPR?.allPressReleaseByUser
                                            .total
                                        }
                                        clikPrevPr={(p: any) =>
                                          providerPR.clikPrevPr(p)
                                        }
                                        companyPageIdSelected={providerPR?.companyPageIdSelected?.toString()}
                                        company_name={
                                          isModerator(el?.user?.roles)
                                            ? getNameUser(el?.user)
                                            : el?.user?.isvInCompanyPage
                                            ? el?.last_activity_by
                                                ?.isvInCompanyPage.company_page
                                                ?.company_name
                                            : programReducer?.program
                                                ?.company_name
                                        }
                                        company_summary={
                                          isModerator(el?.user?.roles)
                                            ? ""
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage
                                                ?.company_page?.company_summary
                                            : programReducer?.program
                                                ?.program_name
                                        }
                                        currentCompanyPageSelected={
                                          isModerator(el?.user?.roles)
                                            ? el?.user?.id.toString()
                                            : el?.last_activity_by?.isvInCompanyPage?.company_page?.id.toString()
                                        }
                                        currentPagePrGet={
                                          providerPR?.currentPagePrGet
                                        }
                                        isShowPopUpRelease={
                                          providerPR?.isShowPopUpRelease
                                        }
                                        nextPagePr={(p: any) =>
                                          providerPR.nextPagePr(p)
                                        }
                                        photo_profil={
                                          isModerator(el?.user?.roles)
                                            ? ""
                                            : el?.user?.isvInCompanyPage
                                            ? el?.user?.isvInCompanyPage
                                                ?.company_page?.photo_profil
                                            : programReducer?.program
                                                ?.photo_profil
                                        }
                                        prListe={
                                          providerPR?.allPressReleaseByUser
                                            ?.prListe
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="subCategory">
                                  {el?.tags?.map(
                                    (element: any, index: number) => (
                                      <span
                                        key={index}
                                        onClick={() =>
                                          providerPR?.goToTagsCategory(
                                            element?.id
                                          )
                                        }
                                      >
                                        {element?.name}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="replyInfo">
                              <b>{el?.numberOfLikes}</b>Likes
                            </div>
                            <div className="viewInfo">
                              <b>{el?.numberOfViews}</b>views
                            </div>
                          </li>
                        )
                      )}
                    </>
                  )}
                </ul>
                <div className="pagginationCategory">
                  <div className="labelSelect">
                    <label>Show: </label>
                    <Select
                      className="triNumber"
                      isClearable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      classNamePrefix="tri"
                      value={selectedOption}
                      onChange={handleChange}
                    />

                  </div>
                  <div className="pagination">
                    <Pagination
                      className="pagination"
                      currentPage={providerPR?.currentDiscutionPage}
                      totalCount={
                        providerPR?.communityReducer?.paginate_press_release
                          ?.total
                      }
                      pageSize={providerPR?.limitDiscution}
                      onPageChange={providerPR?.handlePageClick}
                    />
                  </div>
                </div>
                <div className="leftCommunityForum allWidth">
                  {providerPR?.topLikedPressRelease?.length > 0 && (
                    <div className="block">
                      <h2 className="title">Top liked press releases</h2>
                      <ul className="separateElement">
                        {providerPR?.topLikedPressRelease?.map(
                          (el: any, index: number) => (
                            <li key={index}>
                              <div className="leftItem txtLabel">
                                <h3 className="title">{el?.title}</h3>
                                {el?.tags?.map((el: any, index: number) => (
                                  <span
                                    className="topic"
                                    key={index}
                                    onClick={() =>
                                      providerPR?.goToTagsCategory(el?.id)
                                    }
                                  >
                                    {el?.name}
                                  </span>
                                ))}
                              </div>
                              <div className="rightItem likeContent">
                                <span className="icon-like-full"></span>
                                {el?.numberOfLikes}
                              </div>
                            </li>
                          )
                        )}

                        {providerPR?.topLikedPressRelease?.length > 0 && (
                          <li
                            className="linkForLi"
                            onClick={() => providerPR?.viewAllContributor()}
                          >
                            <a
                              href="javascript"
                              onClick={(e) => e.preventDefault()}
                              className="viewAll"
                            >
                              View all
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  {providerPR?.topLikedAuthor?.length > 0 && (
                    <div className="block">
                      <h2 className="title">Top liked authors</h2>
                      <ul className="separateElement">
                        {providerPR?.topLikedAuthor?.map(
                          (author: IpropsAuthor, index: number) => (
                            <li key={index}>
                              <div className="leftItem itemAuthor">
                                <div className="imageAffiliatedcompany empty">
                                  {author?.profil_picture && (
                                    <img
                                      src={fileUrl(author?.profil_picture)}
                                    />
                                  )}
                                </div>
                                <div className="infoAffiliated">
                                  <h3 className="title">
                                    {refaCtoNullKey(author?.user_name)}
                                  </h3>
                                  <div className="post">
                                    {refaCtoNullKey(author?.job_title)}
                                  </div>
                                </div>
                              </div>
                              <div className="rightItem likeContent">
                                <span className="icon-like-full"></span>
                                {refaCtoNullKey(author?.number_of_like)}
                              </div>
                            </li>
                          )
                        )}
                        {providerPR?.topLikedAuthor?.length > 0 && (
                          <li
                            className="linkForLi"
                            onClick={() => providerPR?.viewAllAuthor()}
                          >
                            <a
                              className="viewAll"
                              onClick={(e) => e?.preventDefault()}
                            >
                              View all
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </PressReleaseComponent>
  );
}
