import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { getSStorage } from "../../../../utils";

type IProps = {
  show: boolean;
  setShow: (p: boolean) => void;
  eventObject: any;
  activeCategorie: number;
  setShowNext: (p: boolean) => void;
  setEventTypeSelected: (p: string) => void;
};
export default function ModalChoiseEvent(props: IProps) {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const eventTypeDataSource = dataCompleted?.event_type || [];

  return (
    <Modal
      show={props?.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Create a new event</h2>
          <Link
            to="#"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props?.setShow(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder row forPitch">
          <div className="listPost">
            {eventTypeDataSource?.map((el: any, key: number) => (
              <Link
                to="#"
                className={`choicePost radius ${
                  props?.activeCategorie === key ? "active" : ""
                }`}
                key={key}
                onClick={(e: any) => {
                  e?.preventDefault();
                  props?.setShow(false);
                  props?.setShowNext(true);
                  props?.setEventTypeSelected(el?.id?.toString());
                }}
              >
                <div className="radius">
                  <span className={el?.icon}></span>
                </div>
                {el?.name}
              </Link>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
