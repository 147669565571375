import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UseOemGettingStarted from "../../../../controller/oem-ctr/block-component/use-oem-getting-started";
import UseOemKeyBenefict from "../../../../controller/oem-ctr/block-component/use-oem-key-benefict";
import UseOverview from "../../../../controller/oem-ctr/block-component/use-oem-overview-ctr";
import useOemResources from "../../../../controller/oem-ctr/block-component/use-oem-resources";
import UseSolution from "../../../../controller/oem-ctr/block-component/use-solutions-ctr";
import useProfilPageEdit from "../../../../controller/oem-ctr/use-profil-page-edit";
import CompletedAccount from "../../../../hooks/CompletedAccount";
import OemProgramSegment from "../../../../screen/container/oem/profil-oem/block-oem/oem-program-segment";
import Footer from "../../../widget/footer/footer";
import Header from "../../../widget/index";
import { LoadingAllPage } from "../../../widget/loader";
import PlanDetails from "../../../widget/manage-company-and-billing/index";
import UseManageCompanyAndBilling from "../../../widget/manage-company-and-billing/use-manage-company-and-billing";
import ModalEditSettings from "../../../widget/oem-program-targeting/index";
import UseEditSettings from "../../../widget/oem-program-targeting/use-program-targeting";
import ModalPermission from "../../../widget/permission-oem/index";
import "./../Oem-page.scss";
import Link from "./link-header/index";
import OemBackground from "./block-oem/oem-background";
import OemCompany from "./block-oem/oem-company";
import OemProgramInfo from "./block-oem/oem-program-info";
import OemOverview from "./block-oem/oem-overview";
import OemSolution from "./block-oem/oem-solution";
import OemKeyBenefict from "./block-oem/oem-key-benefict";
import OemGettingStared from "./block-oem/oem-getting-started";
import OemResources from "./block-oem/oem-resources";
import OemManaging from "./block-oem/oem-managing";

export default function OemProfilPage() {
  const providerUseManageCompanyAndBilling = UseManageCompanyAndBilling();
  const providerRessource = useOemResources();
  const providerOverview = UseOverview();
  const providerSolution = UseSolution();
  const providerKeyBenefic = UseOemKeyBenefict();
  const providerGettingStarted = UseOemGettingStarted();
  // const oemProgram: OemProgram = useSelector(
  //   (state: rootState) => state.oemProgramReducer.program
  // );
  const {
    editMode,
    switchEditMode,
    defaultValue,
    scrollPosition,
    loader,
    publishOrUpdate,
    showPreview,
    isCompletedProgram,
    isOtherCompanyPage,
    goBackMatchingPanel,
    addFavorite,
    handLePersmission,
    showPermission,
    validateEventRole,
    validateEventEditProgram,
    validatePublishEvent,
    loadingPage,
    configOtherCompany,
    contactOemPage,
    isPreview,
    programId,
    isNotOemAccount,
    handleUnpublish,
    showPopupUnpublish,
    showBtnUnpublish,
    setShowPopupUnpublish,
    setShowBtnUnpublish,
    processing,
    gearClick,
    setGearClick,
    oemProgram,
    oemProgramState,
  } = useProfilPageEdit();

  const providerEditSettings = { ...UseEditSettings() };
  const txtBtn =
    oemProgram?.validate_by_devinsider && oemProgram?.status
      ? "Update"
      : "Publish";

  const isPremium =
    oemProgram && oemProgram.oem_subscription.name === "Premium";

  const showMessage =
    (!isCompletedProgram &&
      !isOtherCompanyPage &&
      !oemProgram?.validate_by_devinsider) ||
    oemProgram?.status === false;

  const isOnEditWysiwyg =
    providerOverview.isEditClick ||
    providerKeyBenefic.isEditClick ||
    providerSolution.isEditClick ||
    providerGettingStarted.isEditClick;

  return (
    <CompletedAccount>
      <div className={`profilInvestor ${editMode && "editMode"}`}>
        <Header />
        <section className="InvestorPage ">
          {loadingPage && <LoadingAllPage />}
          <OemBackground />
          <div className="container mx-auto cont2">
            <div className="titleContent d-flex">
              <h3 className="title">{defaultValue?.programName} </h3>

              {isPremium && (
                <div className="premiumBadge ml-4 mb-3 mt-4">
                  <div className="badgeP">
                    <span className="icon-premium mr-1"></span>Premium
                  </div>
                  <div className="txt">partnership program</div>
                </div>
              )}
            </div>
            <div className="contentLeftInvestor">
              <div
                className={`secondHeader ${
                  scrollPosition < 470 ? "" : "fixedSecondHeader"
                }`}
              >
                <div className="container mx-auto">
                  <Link
                    isEmptyFile={
                      providerRessource.isOtherUserType &&
                      providerRessource.isEmptyFile
                    }
                  />
                </div>
              </div>
              {showMessage && !isNotOemAccount && (
                <div className="firstIn justForProfil">
                  <b>Welcome to Devinsider!</b> The last step in the
                  registration process is to complete your program page. On this
                  page you describe what your program is about and how ISVs can
                  benefit from it. Once your program page has been completed,
                  approved, and published it is visible to ISVs and the
                  matchmaking starts!
                  <button className="btn" onClick={switchEditMode}>
                    Complete program page
                  </button>
                </div>
              )}

              <div className="generalInfo" id="about">
                <OemCompany />
                <OemProgramInfo />
              </div>
              <div className="investorArticle justForProfil ">
                <OemProgramSegment />
              </div>
            </div>

            {!isPreview && (
              <div
                className={`contentRightInvestor ${
                  scrollPosition < 470 ? "" : "fixContentRight"
                }`}
              >
                <OemManaging
                  switchEditMode={() => {
                    if (validateEventEditProgram()) {
                      switchEditMode();
                      providerOverview.clickCancel();
                      providerSolution.clickCancel();
                      providerKeyBenefic.clickCancel();
                      providerGettingStarted.clickCancel();
                    }
                  }}
                  showPreview={showPreview}
                  setShowPageSettings={() =>
                    providerEditSettings?.setShowPageSettings()
                  }
                  setShowManagePage={() =>
                    validateEventRole() &&
                    providerUseManageCompanyAndBilling?.setShowBillingCenter(
                      true
                    )
                  }
                  publishOrUpdate={() =>
                    validatePublishEvent() && publishOrUpdate()
                  }
                  editMode={editMode}
                  loader={loader}
                  txtBtn={txtBtn}
                  isOtherUser={isOtherCompanyPage}
                  matching_value={configOtherCompany.matching}
                  goBackMatchingPanel={goBackMatchingPanel}
                  addFavorite={addFavorite}
                  isFavorite={configOtherCompany.is_favorite}
                  contactOem={() => contactOemPage()}
                  isPreview={isPreview}
                  isOnEditWysiwyg={isOnEditWysiwyg}
                  programIdToContact={programId}
                  gearClick={gearClick}
                  handleUnpublish={handleUnpublish}
                  processing={processing}
                  setGearClick={setGearClick}
                  setShowBtnUnpublish={setShowBtnUnpublish}
                  setShowPopupUnpublish={setShowPopupUnpublish}
                  showBtnUnpublish={showBtnUnpublish}
                  showPopupUnpublish={showPopupUnpublish}
                />
              </div>
            )}

            <div className="titleContent d-xl-none d-lg-block d-md-block d-block">
              <h3 className="title">{defaultValue?.programName}</h3>
            </div>
          </div>
          <div id="overview">
            <OemOverview
              isDoneEditing={editMode}
              isPreview={isPreview}
              {...providerOverview}
            />
          </div>
          <div id="solutions">
            <OemSolution
              isDoneEditing={editMode}
              isPreview={isPreview}
              {...providerSolution}
            />
          </div>
          <div id="key">
            <OemKeyBenefict
              isDoneEditing={editMode}
              isPreview={isPreview}
              {...providerKeyBenefic}
            />
          </div>
          <div id="started">
            <OemGettingStared
              isDoneEditing={editMode}
              isPreview={isPreview}
              {...providerGettingStarted}
            />
          </div>
          <div id="resource">
            <OemResources
              fileDataSource={providerRessource.fileDataSource}
              isOtherUserType={providerRessource.isOtherUserType}
              showModalEditRessource={() =>
                providerRessource.showModalEditRessource()
              }
              isPreview={providerRessource.isPreview}
              isEdit={providerRessource.isEdit}
              removeFile={(p: any, index: any) =>
                providerRessource.removeFile(p, index)
              }
              setFileResource={(event: any, index: any) =>
                providerRessource.setFileResource(event, index)
              }
              closeRessourceModal={() =>
                providerRessource.closeRessourceModal()
              }
              loader={providerRessource.loader}
              isShowRessource={providerRessource.isShowRessource}
              saveUpdate={() => providerRessource.saveUpdate()}
              isEmptyFile={providerRessource.isEmptyFile}
            />
          </div>

          <ModalEditSettings {...providerEditSettings} />

          <PlanDetails {...providerUseManageCompanyAndBilling} />
          <ModalPermission
            handleSkhow={handLePersmission}
            show={showPermission}
          />
        </section>
        <Footer />
      </div>
    </CompletedAccount>
  );
}
