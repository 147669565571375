/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import AffiliatedOem from './roles-oem/oem-manage-roles';
import AffiliatedIsv from './roles/affiliated-insider';
import UseAffiliated from './roles/use-affilliated';
import TabPanHeader from './tab-pan-company-header';

import UserOemMemberPlan from './plan-details/member-plan-detail/use-oem-member-plan';
import UseAffilliatedOem from './roles-oem/use-manage-roles';

import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
} from '../../../service/constant';
import * as Plan from './plan-details/index';
import * as TabTransaction from './transaction-history/index';
import {
  InvestorPlanDetail,
  PlanFreeDetails,
  TPropsPayementDetail,
} from './type';

export const BASIC = 'Basic';
export const PREMIUM = 'Premium';
export const CUSTOM = 'Custom';

const PlanDetails = (props: TPropsPayementDetail) => {
  const isIsv = props?.role?.indexOf(ISV) !== -1;
  const isInvestor = props?.role.indexOf(INVESTOR) !== -1;
  const isReseller = props?.role.indexOf(RESELLER) !== -1;
  const isOem = props?.role.indexOf(OEM_PROGRAM) !== -1;
  const providerUseAffiliated = UseAffiliated();
  const providerUseAffilliatedOem = UseAffilliatedOem();
  let programNumber = 0;
  const allPrograms = useSelector(
    (state: rootState) => state.userReducer.oemProgramMembers
  );
  if (isOem) {
    programNumber =
      allPrograms
        ?.filter((el) => el?.oem_program?.uuid !== '')
        .findIndex((p) => p.oem_program.id == props.oemProgramObject.id) + 1;
  }
  const users = UserOemMemberPlan({ tabShow: props?.tabNavigate });
  const renderIsvPlanDetails = (plan_details: PlanFreeDetails) =>
    isIsv &&
    props?.companyName && (
      <Plan.IsvPlanDetails
        isv_free_ads={plan_details.isv_free_ads}
        isv_press_release={plan_details.isv_press_release}
        isv_unlock_investor={plan_details.isv_unlock_investor}
        isv_free_corporate_ads={plan_details.isv_free_corporate_ads}
        isv_event={plan_details.isv_event}
      />
    );

  const renderInvestorPlanDetails = (plan_details: InvestorPlanDetail) =>
    isInvestor && (
      <Plan.InvestorPlanDetails
        investor_classified_ads={plan_details.investor_classified_ads}
        investor_unlock_isv={plan_details.investor_unlock_isv}
      />
    );

  const renderOemPlanDetails = (plan_details: any, setShowMonthlyBudget: any) =>
    isOem && (
      <Plan.OemPlanDetail
        free_status={plan_details}
        setShowMonthlyBudget={setShowMonthlyBudget}
      />
    );

  const renderResellerPlanDetails = (plan_details: any) =>
    isReseller && <Plan.ReselletPlanDetail free_status={plan_details} />;

  const renderUserInfo = () => {
    return (
      <div className='radius'>
        <div>
          {isIsv && !props.chargingPlanDetails && props.planDetails && (
            <Plan.MemberIsvPlanDetail />
          )}
        </div>
        <div>
          {isOem && !props.chargingPlanDetails && props.planDetails && (
            <Plan.MemberOemPlan users={users} />
          )}
        </div>
      </div>
    );
  };

  const renderPlanDetails = () => {
    if (isIsv) {
      return renderIsvPlanDetails(props.planDetails.free_status);
    }
    if (isInvestor) {
      return renderInvestorPlanDetails(props.planDetails.free_status);
    }
    if (isReseller) {
      return renderResellerPlanDetails(props.planDetails.free_status);
    }
    if (isOem) {
      return renderOemPlanDetails(
        props.planDetails.free_status,
        props?.setShowMonthlyBudget
      );
    }
    return <></>;
  };

  const isShowPlanDetail = !props.chargingPlanDetails && props.planDetails;

  return (
    <Modal
      show={props?.showBillingCenter}
      className={
        'createCompanypopup large forBillingCenter ' +
        (providerUseAffiliated?.hideModalBillingCenter ? 'hidden' : '')
      }
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>
            {isOem
              ? 'Manage program'
              : isIsv && props?.companyName
              ? 'Manage company'
              : ''}
          </h2>
          <a
            className='close'
            onClick={(e) => {
              e.preventDefault();
              props?.closeBillingCenter(true);
            }}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder forBillingCenter'>
          <Tab.Container
            id='left-tabs-example'
            defaultActiveKey='plan'
            activeKey={props?.tabNavigate}
          >
            <TabPanHeader
              setTabNavigate={(s: string) => props?.setTabNavigate(s)}
              setShowBillingCenter={() => props?.closeBillingCenter()}
              companyName={
                isOem ? 'Program ' + programNumber : props?.companyName
              }
              programCompanyName={isOem ? props.companyName : ''}
              role={props.role}
              showAddCompany={isIsv && !props?.companyName}
            />
            <div className='contentPlanTab'>
              <Tab.Content>
                <Tab.Pane eventKey='plan'>
                  {isShowPlanDetail ? renderPlanDetails() : 'Loading ...'}
                  {(isIsv || isOem) && renderUserInfo()}
                </Tab.Pane>

                <Tab.Pane eventKey='role'>
                  {isIsv && (
                    <AffiliatedIsv
                      {...providerUseAffiliated}
                      setRoleAffiliated={(role: string, idUser: string) =>
                        providerUseAffiliated?.setRoleAffiliated({
                          role,
                          idUser,
                        })
                      }
                    />
                  )}
                  {isOem && <AffiliatedOem {...providerUseAffilliatedOem} />}
                </Tab.Pane>

                <Tab.Pane eventKey='transaction'>
                  <div className='contentBorder forBillingCenter'>
                    <Tab.Container
                      id='left-tabs-example'
                      defaultActiveKey='history_list'
                      activeKey={props?.tabNavHistory}
                    >
                      <TabTransaction.HeaderTab
                        setTabNavHistory={(txt: string) =>
                          props.setTabNavHistory(txt)
                        }
                      />

                      <div className='contentPlanTab'>
                        <Tab.Content>
                          <TabTransaction.TabPanTransaction
                            tabType={props.tabNavHistory}
                          />
                          <TabTransaction.TabPanReceipts
                            tabType={props.tabNavHistory}
                          />
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right' />
      </Modal.Footer>
    </Modal>
  );
};

export default PlanDetails;
