import { ChangeEvent, useEffect, useRef, useState } from "react";
import Header from "../../widget/index";
import "./FindIsv.scss";
//@ts-ignore
import moment from "moment";
import Select from "react-select";
import UseFavorites from "../../../controller/find-isv/use-favorites-ctr";
import MachingComponent from "../../../hooks/MachingComponent";
import { IsvProgramType } from "../../../interface/isv-type";
import {
  changeNumberValue,
  fileUrl,
  handleClassName,
  handleStatus,
} from "../../../utils";
import { LoadingFavoriteProgram } from "../../widget/loader";
import Pagination from "../../widget/use-pagination/pagination";
import { PaymentPopup } from "../payment-popup/payment-popup";
import FilterListe from "./bloc-find-isv/filter";

export default function FavoritePage() {
  const [unlockedClickId, setUnclockedClickId] = useState<boolean>(false);
  const divRef: any = useRef(null);
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerUseFavorites = UseFavorites();
  //const providerMatching = UseMatchingIsv();

  const renderPercent = (number: string, hasclosed: boolean) => {
    let value = parseInt(number);

    if (hasclosed) {
      value = 59;
    }

    if (value < 60) {
      return (
        <div className="matchValue m60">
          <b>Match</b>
          <span className="icon-nextbtn" />
          {"60 %"}
        </div>
      );
    } else if (value >= 60 && value < 65) {
      return (
        <>
          <div className="matchValue m60">
            <span className="icon-nextbtn" /> <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 65 && value < 75) {
      return (
        <>
          <div className="matchValue m80">
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 75 && value < 90) {
      return (
        <>
          <div className="matchValue m80">
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 90) {
      return (
        <>
          <div className="matchValue m90">
            <b>Match</b> {value}%
          </div>
        </>
      );
    }
  };

  const setTxt = (isUnlocked: boolean) => (isUnlocked ? "Contact" : "Unlock");

  const handleBtn = (
    isUnlocked: boolean,
    idIsv: number,
    percentage: number
  ) => {
    if (percentage < 60) {
      setUnclockedClickId(true);
      return;
    }
    isUnlocked
      ? providerUseFavorites?.providerMatching?.contactIsv(idIsv)
      : unlockPage(idIsv);
  };

  const unlockPage = (idIsv: number) => {
    providerUseFavorites?.isOemUser
      ? providerUseFavorites?.ProviderOemUnlockIsv.goToPaymentModal(idIsv)
      : providerUseFavorites?.ProviderinvestorUnlockIsv.goToPaymentModal(idIsv);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (divRef.current && !divRef.current?.contains(event.target)) {
      setUnclockedClickId(false);
    }
  };

  return (
    <MachingComponent>
      <div className="findIsvcontainer">
        <Header />
        {providerUseFavorites?.isInvestor && (
          <PaymentPopup
            show={
              providerUseFavorites?.ProviderinvestorUnlockIsv?.showPaymentModal
            }
            title={"Unlock isv"}
            setShowPaymentModal={
              providerUseFavorites?.ProviderinvestorUnlockIsv
                ?.setShowPaymentModal
            }
            product={providerUseFavorites?.ProviderinvestorUnlockIsv?.product}
            successCallback={providerUseFavorites?.paymentMethodCallBack}
            free={
              providerUseFavorites?.ProviderinvestorUnlockIsv?.stateUnlock
                ?.number_left
            }
            isPriceInfo={false}
          />
        )}

        {providerUseFavorites?.isOemUser && (
          <PaymentPopup
            show={providerUseFavorites?.ProviderOemUnlockIsv?.showPaymentModal}
            title={"Unlock isv"}
            setShowPaymentModal={
              providerUseFavorites?.ProviderOemUnlockIsv?.setShowPaymentModal
            }
            product={providerUseFavorites?.ProviderOemUnlockIsv?.product}
            successCallback={providerUseFavorites?.paymentMethodCallBack}
            free={
              providerUseFavorites?.ProviderOemUnlockIsv?.stateUnlock
                .number_left
            }
            isPriceInfo={false}
            monthlyBudgetReached={
              providerUseFavorites?.ProviderOemUnlockIsv?.stateUnlock
                ?.monthly_budget_reached
            }
          />
        )}

        <FilterListe
          sliderValue={providerUseFavorites?.sliderValue}
          setSliderValue={(value: Array<number>) =>
            providerUseFavorites?.setSliderValue(value)
          }
          lunchFilter={() => providerUseFavorites?.lunchFilter()}
          setHideType={(p: string) => providerUseFavorites?.handleHideType(p)}
          hideType={providerUseFavorites?.selectedHideType}
          setCountryType={(p: string) => providerUseFavorites?.handleCountry(p)}
          selectedCountry={providerUseFavorites?.selectedCountry}
          selectedTargetedIndustry={providerUseFavorites?.selectedTargeted}
          setSelectedTargeted={(p: string) =>
            providerUseFavorites?.handleTargeted(p)
          }
          selectedSegment={providerUseFavorites?.selectedSegment}
          setSelectedSegment={(p: string) =>
            providerUseFavorites?.handleSegment(p)
          }
          selectedAnnual={providerUseFavorites?.selectedAnnual}
          setSelectedAnnual={(p: string) =>
            providerUseFavorites?.handleAnnual(p)
          }
          selectedCompanySize={providerUseFavorites?.selectedCompanySize}
          setSelectedCompanySize={(p: string) =>
            providerUseFavorites?.handleCompanySize(p)
          }
          resetFilter={() => providerUseFavorites?.resetFilter()}
          userType={providerUseFavorites?.userState?.roles?.[0]}
          selectedFundingRound={providerUseFavorites?.selectedFunding}
          setSelectedFundingRound={(p: string) =>
            providerUseFavorites?.handleFundingRound(p)
          }
          selectedFundraising={providerUseFavorites?.selectedFundraising}
          setSelectedFundraising={(p: string) =>
            providerUseFavorites?.handleFundraising(p)
          }
          filter_type="favorites"
          unlocked_left={providerUseFavorites.allIsvFavorie.unlocked_left}
        />

        <div className="container mx-auto minHeightwin">
          <div className="listOfIsv">
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  Showing{" "}
                  <b>
                    {changeNumberValue(
                      providerUseFavorites?.allIsvFavorie?.isv?.length
                    )}
                  </b>{" "}
                  ISVs
                </div>
                <form
                  action=""
                  onClick={(e) => openBlock(e)}
                  onSubmit={(e) => e?.preventDefault()}
                >
                  <input
                    placeholder="Search..."
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      providerUseFavorites?.setSearch_name(e?.target?.value)
                    }
                    value={providerUseFavorites?.search_name}
                    onBlur={() =>
                      providerUseFavorites?.search_name?.length <= 0 &&
                      providerUseFavorites?.handleSearche()
                    }
                  />
                </form>
              </div>
              <div className="rightHead">
                <div className="sortedby labelSelect">
                  <label>Sorted by :</label>
                  <Select
                    className="triOreder"
                    isClearable={false}
                    options={providerUseFavorites?.filtered}
                    classNamePrefix="tri"
                    defaultValue={providerUseFavorites?.filtered[0]}
                    onChange={(filter: any) =>
                      providerUseFavorites?.handleFilter(filter)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="scrollContainer">
              <div
                className={`${unlockedClickId ? "block" : "d-none"}`}
                id="boxError"
                ref={divRef}
              >
                <span className="warn-icon">i</span>Sorry, the match percentage
                is too low to contact this Isv
                <span
                  className="ml-5"
                  onClick={() => setUnclockedClickId(false)}
                >
                  &#x2715;
                </span>
              </div>
              {providerUseFavorites?.loaderFavorite ? (
                <LoadingFavoriteProgram />
              ) : (
                <ul className="listOfIsvMatch listUnlocked">
                  {providerUseFavorites?.allIsvFavorie?.isv?.map(
                    (program: IsvProgramType, index: number) => (
                      <li className={program?.is_unlocked ? "" : "locked"}>
                        <div
                          className={`isvLogo ${
                            program?.isBlacklisted && !program?.is_unlocked
                              ? "blacklisted"
                              : ""
                          }`}
                        >
                          <img
                            src={fileUrl(program?.isv?.photo_profil)}
                            alt=""
                          />
                        </div>
                        <div className="isvInfo">
                          <div className="infoUnlocked">
                            <h3 className="title">
                              <span className="name">
                                {program?.isv?.company_name}
                              </span>
                              <span
                                className="icon-star-on"
                                onClick={() =>
                                  providerUseFavorites?.handleFavorite(
                                    program?.isv?.id
                                  )
                                }
                              ></span>
                            </h3>
                            {renderPercent(
                              program?.matching_value?.toString(),
                              program?.isv?.has_closed_company
                            )}

                            <div className="country">
                              <b>Location</b>
                              {program?.isv?.country?.country_name}
                            </div>
                          </div>
                          <div className="actionUnlocked">
                            <div className="btnContent">
                              {program?.isBlacklisted &&
                              !program?.is_unlocked ? (
                                <></>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-devinsider view"
                                    onClick={() =>
                                      providerUseFavorites?.goToIsvPage(
                                        program?.isv?.id.toString(),
                                        program?.is_unlocked
                                      )
                                    }
                                  >
                                    View
                                  </button>
                                  {/* ${program?.matching_value < 60 && 'disabled'} */}
                                  {/* disabled={program?.matching_value < 60} */}
                                  <button
                                    className={`btn btn-devinsider ${
                                      program?.is_unlocked
                                        ? "save"
                                        : "unlockedBtn"
                                    }`}
                                    onClick={() => {
                                      handleBtn(
                                        program?.is_unlocked,
                                        program?.isv?.id,
                                        program?.matching_value
                                      );
                                    }}
                                    disabled={program?.isv?.has_closed_company}
                                    style={{
                                      cursor: program?.isv?.has_closed_company
                                        ? "not-allowed"
                                        : undefined,
                                    }}
                                  >
                                    {setTxt(program?.is_unlocked)}
                                  </button>
                                </>
                              )}
                            </div>

                            {program?.is_unlocked && (
                              <div className="unlockedBy">
                                {program?.isIsvUnlocked ? (
                                  <b>ISV request</b>
                                ) : (
                                  <>
                                    <b>
                                      Unlocked by {program?.user_who_unlocks}:
                                    </b>
                                  </>
                                )}
                                {moment(new Date(program?.unlocked_at)).format(
                                  "MMMM D, yyyy"
                                )}
                              </div>
                            )}

                            <div
                              className={`statusContact unlockedBy ${handleClassName(
                                program?.status_discussion
                              )}`}
                            >
                              <b>{handleStatus(program?.status_discussion)}</b>
                              <span className="icon-message"></span>
                              <>
                                {program?.time_message_status &&
                                  moment(
                                    new Date(program?.time_message_status)
                                  ).format("MMMM DD, yyyy")}
                              </>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>

            <div className="pagginationCategory fixePagination">
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={providerUseFavorites?.allIsvFavorie?.currentPage}
                  totalCount={
                    providerUseFavorites?.allIsvFavorie?.totalDataPaginate
                  }
                  pageSize={10}
                  onPageChange={(page: any) =>
                    providerUseFavorites?.loadMoreIsvMatching(page)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MachingComponent>
  );
}
