import React, { ChangeEvent, useState } from 'react';
import Author from '../../../resources/images/team1.png';
import CompanyImg from '../../../resources/images/splunk.png';
import Header from '../../widget/index';
import FormWysiwyg from '../../widget/post-wysiwig/index';


export default function PostSoftwareLabeling() {
    return (
        <>
        <Header />
            <section className='classified mergers details'>
                <div className='container mx-auto'>
                    <div className='leftSection'>
                        <div className='block'>
                            <div className='leftItem itemAuthor'>
                                <div className='imageAffiliatedcompany empty'>
                                    <img src={Author} alt="" />
                                </div>
                                <div className=''>
                                    <div className='post'>Author:</div>
                                    <h3 className='title'>
                                        John
                                    </h3>
                                    <h3 className='title'>
                                    Meyer
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="block infoCompanyAds">
                            <div className="imgclassified empty">
                                <img src={CompanyImg} alt="" />
                            </div>
                            <div className="infoAds">Company:<h3 className="title">Splunk</h3>
                                {/*<div className="role">ISV</div>*/}
                                <div className="contry">Paris, France</div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="slidecheck">
                                <div className="custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" />
                                    <label htmlFor='' className="custom-control-label">Display author name</label>
                                </div>
                                <div className="custom-checkbox">
                                    <input id="hideAuthor1" type="checkbox" className="custom-control-input" />
                                    <label htmlFor="hideAuthor1" className="custom-control-label">Display company name</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightSection newAdsPost newAdMergers">
                        <h2 className='title'>
                            New ad in “Software white labeling”
                        </h2>
                        <div className='mt-3 mb-3'>
                        This section is dedicated to helping software vendors explore OEM partnerships, 
                        APIs, and white labeling business models. Our community consists of your peers who could benefit from technology partnerships 
                        for product enhancement. Post ads to find your next technology partner. 
                        </div>
                        <div className='incognito'>
                            <span className="icon-incognito"></span> On the left side, you can change your visibility settings to appear anonymously.
                        </div>
                        <div className="pb-4 selectSol mt-4">
                            <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                                <div className="btn btn-devinsider">Select solution category(ies)</div>
                                <div className="listOf more"></div>
                            </div>
                            <div className="d-flex mb-1 w-100 align-items-center">
                                <div className="btn btn-devinsider">Select industry(ies)</div>
                                <div className="listOf more"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}