/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IPropsSoftwareSolution } from "../types";

export default function SolutionSpecial(props: IPropsSoftwareSolution) {
  const lengthSolution = props?.solutionList?.length;
  
  const uniqueSolutionList = props?.solutionList.reduce((acc: any, current) => {
    if (!acc.find((item: any) => item.name === current.name)) {
      acc.push(current);
    }
    return acc;
  }, []);
    
  return (
    <>
      <ul className="investorInfoList moreInfo">
        <li>
          <a
            className="linkEditLeftCompany"
            onClick={() => props.editSoftWareSolution()}
          >
            Edit<span className="icon-edit-full"></span>
          </a>
          <label>Solution specialization</label>
          <div className="listOf">
            {uniqueSolutionList
              ?.filter((el: any, index: number) => {
                return index < props?.index1;
              })
              ?.map((element: any, index: number) => (
                <p key={index}>{element?.name}</p>
              ))}

            {lengthSolution > 6 && (
              <a
                className="seeall"
                onClick={(evt) => {
                  evt.preventDefault();
                  props?.setindex1(
                    props?.index1 === lengthSolution ? 6 : lengthSolution
                  );
                }}
              >
                See {props?.index1 === lengthSolution ? "less" : "more"}
                {props?.index1 === lengthSolution
                  ? ""
                  : `(${lengthSolution - props?.index1})`}
              </a>
            )}
          </div>
        </li>
      </ul>
    </>
  );
}
