import store from "../../redux/store";
import {
  dataWithToken,
  postPhoto,
  getData,
  postData,
  postWithoutToken,
  asyncPostData,
} from "../api";
import { url } from "../constant";
import { postJsonDataService } from "./back-end-service";

export const createaAcompanyPage = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.create_company_page,
    data: params,
  };
  let response: any = await postPhoto(tempData, token);
  return response;
};

export const getCompanyPage = async (id: string, token: any): Promise<any> => {
  let response: any = await getData(url.company.get_company_page + id, token);
  return response;
};

export const getCompanyPageFromOem = async (
  id: string,
  idOem: any,
  token: any
): Promise<any> => {
  let response: any = await getData(
    url.company.get_company_page_from_oem + "/" + id + "/" + idOem,
    token
  );
  return response;
};

// export const getCompanyPageProgress = async (
//   id: number,
//   token: any
// ): Promise<any> => {
//   let response: any = await getData(
//     url.company.get_company_page_progresse + id,
//     token
//   );
//   return response;
// };

export const getAttributeCompanyPage = async (
  attribute: string,
  token: any,
  idCompany?: string
): Promise<any> => {
  let hasIdCompany = idCompany ? attribute + "/" + idCompany : attribute;
  let tempurl = url.company.get_other_company + hasIdCompany;
  let response: any = await getData(tempurl, token);
  return response;
};
// Dev #47679 V1 Agrandir la taille max de la video dans company page
export const updateCompanyPage = async (
  params: any,
  id: string,
  token: string,
  callBackProgression?: any
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.create_company_page + "/" + id,
    data: params,
  };
  const response: any = await postPhoto(tempData, token, callBackProgression);
  return response;
};

export const getCompanyPageLocked = async (
  params: {
    id: number | string;
    type: string;
  },
  id: string
): Promise<any> => {
  let tempUrl = url.company.company_page_locked + id;
  let data = params;

  const response: any = await postJsonDataService(
    tempUrl,
    data,
    store.getState()?.userReducer?.token
  );
  return response;
};

//search company page by trade name
export const searchCompanyPage = async (
  company_name: string,
  token: string
): Promise<any> => {
  const response: any = await getData(
    url.acount.companyPage + "/search/" + company_name,
    token
  );
  return response;
};

/*************************************
 * Send invitation affilliated and remove
 */
export const sendInvitation = (params: any, token: string): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.send_invitation,
    data: params,
  };

  return asyncPostData(tempData.url, tempData?.data, token);
};

/*************************************
 * Send invitation affilliated and remove
 */
export const updateRoleAffiliated = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.update_role_affiliated,
    data: params,
  };
  let response: any = await postData(tempData.url, tempData?.data, token);
  return response;
};

/*************************************
 * Send invitation affilliated and remove
 */
export const removeInvitation = async (
  id: string,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.remove_invitation_affiliated + id,
    {},
    token
  );
  return response;
};

/*************************************
 * Remove role user
 */
export const removeUserRole = async (
  id: string,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.remove_user_role + id,
    {},
    token
  );
  return response;
};
/***************************************
 *
 * Add leader ship
 */
export const addLeaderShip = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.addLeaderShip,
    data: params,
  };
  const response: any = await postPhoto(tempData, token);
  return response;
};

/*************************************
 * Remove role user
 */
export const removeLeaderShipById = async (
  id: number,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.removeLeaderShip + id,
    {},
    token
  );
  return response;
};

/*************************************
 * Send invitation claim
 */
export const sendClaimOwnerShip_Service = async (
  params: any,
  token: string
) => {
  let response: any = await postData(
    url?.company?.claim_owner_ship + params,
    {},
    token
  );
  return response;
};

/*************************************
 * Send Request Admin
 */
export const sendRequestAdmin_Service = async (data: any, token: string) => {
  let response: any = await postData(
    url?.company?.send_admin_request,
    data,
    token
  );
  return response;
};

export const checkRequest_Service = async (data: any, token: string) => {
  let response: any = await postData(
    url?.company?.send_invitation + "/check_request",
    data,
    token
  );
  return response;
};

/*************************************
 * Send invitation claim
 */
export const activeRequestClaim_Service = async (params: any) => {
  let response: any = await postWithoutToken(
    url?.company?.validate_request_claim + params,
    {}
  );
  return response;
};

/****************************************
 * Valid request to accept company page
 */
export const validRequestToAffiliated_Service = async (
  data: any,
  token: string
) => {
  let response: any = await postData(
    url?.company?.valid_request_admin,
    data,
    token
  );
  return response;
};

export const checkEmail_Service = async (
  email: string,
  token: any
): Promise<any> => {
  let response: any = await getData(url.company.checkEmail + email, token);
  return response;
};

export const sendValidWorkEmailService = async (
  email: string,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.valid_work_email + email,
    {},
    token
  );
  return response;
};

/***************************************
 * @param data form to send contact
 * @param {token}  to  identifie user id
 * @returns promise response post data contact form
 */
export const contactSupportBillingService = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.contact_support_billings,
    data: params,
  };
  const response: any = await postPhoto(tempData, token);
  return response;
};

export const contactSupportFindMoreIsv = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.contact_support_find_more_isv,
    data: params,
  };
  try {
    const response: any = await postPhoto(tempData, token);
    return response;
  } catch (e: any) {
    return {
      status: 500,
      message: "An error occurred,please try again or contact our support!",
    };
  }
};
