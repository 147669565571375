import Header from '../../../../widget/';
import React, { ChangeEvent, useEffect, useState } from 'react';
// import Select from "react-select";
import AsyncSelect from 'react-select/async';

import UseProgramReviewCtr from '../../../../../controller/community-ctr/bloc-community-ctr/use-program-review-ctr';
import FormPost from '../../../../widget/post-wysiwig/index';
import { postErrorUi } from '../../../../../utils';
import Footer from '../../../../widget/footer/footer';

export default function StartProgramReview() {
  const providerCommunity = UseProgramReviewCtr();

  useEffect(() => {
    if (providerCommunity?.errorAffiliated) {
      postErrorUi('community');
      providerCommunity?.setErrorAffiliated(false);
    }
  }, [providerCommunity?.errorAffiliated]);

  useEffect(() => {
    const root = document.getElementsByClassName('rdw-option-wrapper');
    const foontSizeWrapper = document.getElementsByClassName(
      'rdw-fontsize-wrapper'
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      'rdw-fontfamily-wrapper'
    );

    const fontblockWrapper =
      document.getElementsByClassName('rdw-block-wrapper');

    const alignWrapper = document.getElementsByClassName(
      'rdw-text-align-wrapper'
    );

    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = 'd-none';
      subsciprt.className = 'd-none';
      font.className = 'd-none';
      puiss.className = 'd-none';
    }

    if (foontSizeWrapper && foontSizeWrapper?.[0]) {
      foontSizeWrapper[0].className = 'd-none';
    }

    if (fontFamillyWrapper && fontFamillyWrapper?.[0]) {
      fontFamillyWrapper[0].className = 'd-none';
    }

    if (fontblockWrapper.length > 0) {
      fontblockWrapper[0].className = 'd-none';
    }

    if (alignWrapper.length > 0) {
      alignWrapper[0].className = 'd-none';
    }
  }, []);

  const loadingMessage = () => 'Loading ...';

  return (
    <div>
      <Header />

      <section className='discussion'>
        <div className='container mx-auto'>
          <ul className='breadcrumbPerso'>
            <li>
              <a href='javascript:;'>Community</a>
            </li>
            <li>Partner program</li>
          </ul>
          <div className='rightCommunityForum allWidth oneCateg postContainer'>
            <div className='labelLeft'>
              <div className='block'>
                <h2 className='title'>
                  Partner program{' '}
                  {providerCommunity?.programError && (
                    <div className='container-error pl-2 d-inline'>
                      <span className='error-red'> error </span>
                      <span className='txt-error'>
                        Partner program is required
                      </span>
                    </div>
                  )}
                </h2>
                <div className='selectPartner'>
                  <AsyncSelect
                    classNamePrefix='partnerProgram'
                    defaultOptions
                    isClearable
                    placeholder='Select a partner program'
                    loadOptions={(p: string) =>
                      providerCommunity?.promiseOptions(p)
                    }
                    loadingMessage={() => loadingMessage()}
                    value={providerCommunity.selectedProgram}
                    onChange={(newValue: any) => {
                      providerCommunity?.handleChange(newValue);
                    }}
                  />
                </div>
              </div>
              {providerCommunity?.selectedProgram?.value === '99' && (
                <div className='block'>
                  <label htmlFor=''>
                    Write your partner program :{' '}
                    {providerCommunity?.programError3 && (
                      <div className='container-error pl-2 d-inline'>
                        <span className='error-red'> error </span>
                        <span className='txt-error'>
                          Partner program is required
                        </span>
                      </div>
                    )}
                    {/* {providerCommunity?.programError2 && (
                        <div className='container-error pl-2 d-inline'>
                          <span className='error-red'> error </span>
                          <span className='txt-error'>Partener program does not exist</span>
                        </div>
                      )} */}
                  </label>
                  <div className='form-control relative'>
                    <input
                      type='text'
                      className='form-control search-msg lab'
                      placeholder=''
                      value={providerCommunity?.otherValue}
                      onChange={(newValue: ChangeEvent<HTMLInputElement>) => {
                        providerCommunity?.handleOtherChange(
                          newValue.target.value
                        );
                      }}
                    />
                    {providerCommunity?.isLoading && (
                      <div className='suggestions-container'>
                        <ul className='suggestions'>
                          <li>Loading...</li>
                        </ul>
                      </div>
                    )}
                    {providerCommunity?.suggestions.length > 0 && (
                      <div className='suggestions-container'>
                        <ul className='suggestions'>
                          {providerCommunity?.suggestions.map(
                            (suggestion: any, index: number) => (
                              <li
                                key={index}
                                onClick={() =>
                                  providerCommunity?.handleSuggestionClick(
                                    suggestion
                                  )
                                }
                              >
                                {suggestion.label}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className='block'>
                <div className='custom-control custom-checkbox orangecheck'>
                  <input
                    type='checkbox'
                    id='ifreplies'
                    className='custom-control-input'
                    checked={providerCommunity?.emailMe}
                    onClick={() => providerCommunity?.toogleEmailMe()}
                  />
                  <label htmlFor='ifreplies' className='custom-control-label'>
                    Email me when someone replies
                  </label>
                </div>
              </div>
            </div>
            <div className='containerCategory'>
              <h2 className='title'>
                Write a review about an ISV partner program{' '}
                {providerCommunity?.titleError && (
                  <div className='container-error pl-2 d-inline'>
                    <span className='error-red'> error </span>
                    <span className='txt-error'>Subject is required</span>
                  </div>
                )}
              </h2>
              <FormPost
                //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                mention={providerCommunity?.mentions}
                updateMention={providerCommunity?.updateMention}
                editorState={providerCommunity?.stateFormWysywig}
                setEditorState={providerCommunity?.setEditorStatePost}
                sendPost={() => providerCommunity?.post()}
                cancelPost={() => providerCommunity?.cancelPost()}
                object={providerCommunity?.object}
                setObject={(evt: string) =>
                  providerCommunity?.setTxtObject(evt)
                }
                loader={providerCommunity?.loader}
                showPreview={() => providerCommunity?.showPreview()}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer postType='4' />
    </div>
  );
}
