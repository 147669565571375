import { IAction } from "../../types/type-action";
import { IcompanyPage } from "../../../interface/company-page-isv";
import * as CONSTANTE from "../../actions/company-page-isv-action/other_isv_action";
export const initialState: IcompanyPage = {
  id: "0",
  completed: false,
  next_page: 0,
  company_name: "",
  company_legal_name: "",
  website: "",
  company_size: "",
  founding_date: "",
  type_of_office: "",
  annual_turnover: "",
  photo_profil: "",
  photo_couverture: "",
  description: "",
  video_profile: "",
  corporate_development: "",
  amount_want_to_raise: "",
  company_summary: "",
  updated_at: "",
  amount_to_raise_min: 0,
  amount_to_raise_max: 0,
  annual_turnover_min: 0,
  annual_turnover_max: 0,
  company_size_min: 0,
  company_size_max: 0,
  targeted_industry: [],
  country: {
    id: 0,
    country_name: "",
  },
  city: "",
  futur_milestones_expansion_strategy: "",
  future_milestones_product_development: "",
  paint_point_pitch: "",
  key_competitive_differentiator_pitch: "",
  team_differentiator_pitch: "",
  segment_details: [],
  partners: [],
  oem_program_segment_interest: [],
  oemProgramSegmentInterestText: "",
  is_joining_soon: false,
  isvPostClassifiedAds: {
    number_left: 0,
    next_grant_at: "",
    amount: 0,
  },
  isvPostPressRelease: {
    number_left: 1,
    next_grant_at: "",
    amount: 0,
  },
  isvUnlockFreeInvestor: {
    number_left: 0,
  },
  validate_by_devinsider: false,
  distribution_channel: [],
  licensing_model: [],
  targeted_country: [],
  funding_round: {
    id: 0,
    name: "",
  },
  progressBasicInfo: 0,
  progressOverview: 0,
  progressStrategy: 0,
  progressFinancial: 0,
  progressPitch: 0,
  totalProgress: 0,
  companyPageGraphs: [{ year: "", amount: "" }],
  pitch_file: "",
  companyPageLeadershipTeams: [
    {
      id: "",
      name: "",
      job_title: "",
      description: "",
      linkedin: "",
      photo_profil: "",
      id_form: 0,
    },
  ],
  membersInvitations: [],
  isvInCompanyPage: [],
  billing_owner_email: "",
  billing_owner_username: "",
  payment_method: "",
  billingInfo: {
    free_status: {
      isv_unlock_investor: {
        number_left: 0,
        number_max: 0,
      },
      isv_free_ads: {
        number_left: 0,
        number_max: 0,
        next_grant: "",
        spent_by: [],
      },
      isv_press_release: {
        number_left: 0,
        number_max: 0,
        next_grant: "",
        spent_by: [],
      },
    },
    information_member: [],
    monthRange: [],
  },
  showUpdatedFlag: false,
};

export const otherUserCompanyIsvReducer = (
  state = initialState,
  action: IAction
): IcompanyPage => {
  switch (action.type) {
    case CONSTANTE.GET_OTHER_COMPANY_PAGE:
      return { ...state, ...action.payload };
    case CONSTANTE.GET_OTHER_COMPANY_PAGE_ATTRIBUTE:
      return {
        ...state,
        [action.payload.attribute]: action.payload.data,
      };

    case CONSTANTE.CLEAR_ISV_CACHE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};
