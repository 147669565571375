import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../redux/reducers";
import {
  removePhotoProfileUser,
  updateInfoUserAction,
} from "../../redux/actions/acounts-action/index";
import { useForm, SubmitHandler } from "react-hook-form";
import { contact } from "../../utils/country_phone";
import { isValidPhoneNumber } from "react-phone-number-input";
import { UserObject } from "../../redux/types/acount-interface";
import * as T from "./types/index";
import { isInObject, refaCtoNullKey } from "../../utils";
import { url } from "../../service/constant";
import { postPhoto } from "../../service/api";
export default function UseEditIsvProfilCtr() {
  const history = useHistory();
  //redux , object user
  const userState = useSelector((state: rootState) => state.userReducer);
  //loader
  const [Show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [audienceShow, setAudienceShow] = useState(false);
  const [showGuestCompany, setShowGuestCompany] = useState<boolean>(false);
  //data local use for show static value
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );

  //Bug #47385 delete profile photo
  const [
    showConfirmationModalRemovePhoto,
    setShowConfirmationModalRemovePhoto,
  ] = useState<boolean>(false);

  const [processingRemovePhotoProfile, setProcessingRemovePhotoProfile] =
    useState<boolean>(false);

  const handleRemoveProfilePicture = () => {
    setProcessingRemovePhotoProfile(true);
    (async () => {
      const response = await postPhoto(
        {
          url: url?.acount?.updateUserProfilInfo,
          data: { _profilePicture: "1" },
          token: userState.token,
        },
        userState?.token
      );
      if (response?.status === 200) {
        //update redux
        dispatch(removePhotoProfileUser(response?.data?.file_name));
      }
      setProcessingRemovePhotoProfile(false);
      setShowConfirmationModalRemovePhoto(false);
    })();
  };

  //all info user to edit
  const [infoUserToEdit, setInfoUserToEdit] = useState<UserObject>({
    ...userState,
  });

  //check if audience is clicked
  const [audienceClicked, setAudienceClicked] = useState<string>("");
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [profilUser, setProfilUser] = useState<boolean>(false);

  //Save audience data to state to show in modal
  const [lockPrimaryEmail, setLockPrimaryEmail] = useState<boolean>(
    userState.lock_primary_email
  );
  const [lockWorkedEmail, setLockWorkedEmail] = useState<boolean>(
    userState.lock_work_email
  );
  const [lockContact, setLockContact] = useState<boolean>(
    userState.lock_phone_number
  );
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [codeFlags, setcodeFlags] = useState(
    userState?.phone_number_code || "US"
  );
  const [stateLinkDean, setstateLinkDean] = useState<boolean>(false);
  const [statePhone, setstatePhone] = useState<boolean>(true);
  const [isErrorLocked, setIsErrorLocked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<T.formDataEdit>({
    mode: "onChange",
    defaultValues: {
      firstName: userState?.real_first_name,
      lastName: userState?.real_last_name,
      companyName: userState?.real_company_name,
      location: userState?.country?.country_name,
      city: refaCtoNullKey(userState?.city),
      jobTitle: userState?.real_job_title,
    },
  });

  const {
    register: registerGuest,
    handleSubmit: handleGuest,
    setValue: setValueGuest,
    getValues: getValueGuest,
    formState: { errors: errorGuest },
  } = useForm<T.formCompanyGuest>({
    mode: "onChange",
    defaultValues: {
      companyName: userState?.real_company_name,
      location: userState?.country?.country_name,
      city: userState?.city,
    },
  });

  //for pick  image
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      setSelectedFile(event?.target?.files[0]);
      //  setIsFilePicked(true);
    }
  };

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
      //  setIsFilePicked(false);
    }
  };

  //Callbck after update infoUser
  const callBack = (resposeStatus: boolean) => {
    if (resposeStatus) {
      if (Show) {
        setShow(false);
      }
      if (lgShow) {
        setLgShow(false);
      }
      if (contactShow) {
        setContactShow(false);
      }
      if (showGuestCompany) {
        setShowGuestCompany(false);
      }
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  //Callbck after update Audience
  const cb = (resposeStatus: boolean) => {
    if (resposeStatus) {
      setAudienceShow(false);
      setContactShow(true);
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  //Callbck after update Photo
  const cbPhoto = (resposeStatus: boolean) => {
    if (resposeStatus) {
      setProfilUser(false);

      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  //navigate to edit photo
  const editProfile = () => {
    history.push("/profil/Isv/edit");
  };

  // update infoUser
  const onSub: SubmitHandler<T.formDataEdit> = (data) => {
    updateUserDetail({
      ...data,
    });
  };

  const submitCompanyGuest: SubmitHandler<T.formCompanyGuest> = (data) => {
    updateUserDetail({ ...data });
  };

  //update info User with redux
  const updateUserDetail = (params: any = null, type: string = "") => {
    setIsLoaderShow(true);
    let data = {
      firstName: params?.firstName?.trim() || userState?.real_first_name,
      lastName: params?.lastName?.trim() || userState?.real_last_name,
      companyName: params?.companyName?.trim() || userState?.real_company_name,
      jobTitle: params?.jobTitle?.trim() || userState?.real_job_title,
      plainText: infoUserToEdit?.plain_text?.trim(),
      city: isInObject(params, "city") ? params?.city : userState?.city,
      country: params?.location
        ? dataCompleted?.country?.find(
            (el: any) => el.country_name == params?.location?.trim()
          )?.id
        : dataCompleted?.country?.find(
            (el: any) => el.country_name == userState?.country?.country_name
          )?.id,
      website: isInObject(params, "website")
        ? params?.website
        : userState?.website,
      linkedin: infoUserToEdit?.linkedin?.trim(),
      phone: infoUserToEdit?.phone?.trim(),
      lockedWorkEmail: lockWorkedEmail ? 1 : 0,
      lockedPrimaryEmail: lockPrimaryEmail ? 1 : 0,
      lockedPhoneNumber: lockContact ? 1 : 0,
      fileName: selectedFile ? selectedFile : null,
      phoneNumberCode: codeFlags,
      fonction: "updateUser",
    };
    //dispatch change
    //type == audience , we call a callback for audience
    //type == phot , we call a callback for photo
    //else we call a default callback : callBack()
    dispatch(
      updateInfoUserAction(
        data,
        type === "audience" ? cb : type === "photo" ? cbPhoto : callBack
      )
    );
  };

  //Valid phone number if exist
  const validPhoneNumber = (): boolean => {
    if (infoUserToEdit.phone?.trim()) {
      if (
        isValidPhoneNumber(
          contact?.find((el: any) => el.code == codeFlags)?.dial_code +
            infoUserToEdit.phone?.trim()
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  //Lunch function to valid update contact detail
  const validAfterUpdate = () => {
    if (!validPhoneNumber()) {
      setstatePhone(false);
    }
    if (validPhoneNumber()) {
      updateUserDetail();
    }
  };

  //hide error text on change linkDean or phone number
  useEffect(() => {
    if (stateLinkDean || statePhone == false) {
      setstateLinkDean(false);
      setstatePhone(true);
    }
    return () => {};
  }, [infoUserToEdit]);

  //restore  info user detain on Cancel
  const cancelChange = () => {
    if (isLoaderShow) {
      setIsLoaderShow(false);
    }
    if (Show) {
      setShow(false);
    }
    if (lgShow) {
      setLgShow(false);
    }
    if (showGuestCompany) {
      setShowGuestCompany(false);
    }
    setValue("firstName", userState.real_first_name);
    setValue("lastName", userState.real_last_name);
    setValue("location", userState.country.country_name);
    setValue("companyName", userState.real_company_name);
    setValue("jobTitle", userState.real_job_title);
    setValue("city", userState.city);
    setValue("website", userState.website);
    setInfoUserToEdit({
      ...userState,
    });
  };

  //Show audience modal on Click
  const setShowAudiance = (data: string) => {
    setAudienceClicked(data);
    setAudienceShow(true);
    setContactShow(false);
  };

  //Hide audience data and show form user
  const setHideShowAudiance = () => {
    //Hide audience and show Contact form
    setAudienceShow(false);
    setIsErrorLocked(false);
    setContactShow(true);
    //we restore data after cancel
    setLockPrimaryEmail(userState.lock_primary_email);
    setLockWorkedEmail(userState.lock_work_email);
    setLockContact(userState.lock_phone_number);
  };

  //check what email type of audience is clicked, save email type click
  const checkAudienceType = (type: number) => {
    if (audienceClicked == "primary_email") {
      if (userState?.lock_work_email && type) {
        setIsErrorLocked(true);
      } else {
        setLockPrimaryEmail(type ? true : false);
      }
    } else if (audienceClicked == "worked_email") {
      if (userState?.lock_primary_email && type) {
        setIsErrorLocked(true);
      } else {
        setLockWorkedEmail(type ? true : false);
      }
    } else {
      setLockContact(type ? true : false);
    }
  };

  //save chande audience lock
  const saveAudienceChoix = () => {
    // Update audiance if data is changed else not update
    if (
      audienceClicked == "primary_email" &&
      lockPrimaryEmail != userState.lock_primary_email
    ) {
      updateUserDetail("audience");
      return;
    } else if (
      audienceClicked == "worked_email" &&
      lockWorkedEmail != userState.lock_work_email
    ) {
      updateUserDetail("audience");
      return;
    } else if (
      audienceClicked == "phone" &&
      lockContact != userState.lock_phone_number
    ) {
      updateUserDetail("audience");
      return;
    }
    setAudienceShow(false);
    setContactShow(true);
  };

  const hideSelectedFile = () => {
    setSelectedFile(undefined);
    setProfilUser(false);
  };

  const goToProfil = () => {
    history.push("/profil");
  };

  const restorePhotoChange = () => {
    if (selectedFile) {
      setSelectedFile(undefined);
    } else {
      handleRemoveProfilePicture();
    }
  };

  return {
    userState,
    codeFlags,
    Show,
    lgShow,
    contactShow,
    audienceShow,
    audienceClicked,
    lockPrimaryEmail,
    lockWorkedEmail,
    lockContact,
    dataCompleted,
    infoUserToEdit,
    profilUser,
    selectedFile,
    fileRef,
    isLoaderShow,
    errors,
    stateLinkDean,
    statePhone,
    isErrorLocked,
    setInfoUserToEdit,
    updateUserDetail,
    cancelChange,
    setcodeFlags,
    editProfile,
    setShow,
    setLgShow,
    setContactShow,
    setAudienceShow,
    setShowAudiance,
    setHideShowAudiance,
    checkAudienceType,
    saveAudienceChoix,
    setProfilUser,
    changeHandler,
    handleBtn,
    hideSelectedFile,
    goToProfil,
    register,
    handleSubmit,
    onSub,
    validAfterUpdate,
    restorePhotoChange,
    setstateLinkDean,
    showGuestCompany,
    setShowGuestCompany,
    submitCompanyGuest,
    registerGuest,
    handleGuest,
    setValueGuest,
    getValueGuest,
    errorGuest,
    //Bug #47385 delete profile photo
    showConfirmationModalRemovePhoto,
    setShowConfirmationModalRemovePhoto,
    processingRemovePhotoProfile,
    handleRemoveProfilePicture,
  };
}
