import React from "react";
import AlternativeFavoriteController from "../../../../controller/alternative-ctr/favorite-alternative-ctr";
import { NavLink, useHistory } from "react-router-dom";
import Header from "../../../widget";
import { LoadingAllPage } from "../../../widget/loader";
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  paginate,
} from "../../../../utils";
import SeeBtn from "../../mergers-acquisitions/SeeBtn";
import Pagination from "../../../widget/use-pagination/pagination";
import AboutSection from "../aboutSection/AboutSection";

export default function FavoriteAlternativeFinancing() {
  const provider = AlternativeFavoriteController();
  const history = useHistory();

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const formatNumber = (number: number) => {
    if (number !== null) {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(0) + "M";
      } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + "K";
      } else {
        return number.toString();
      }
    }
  };

  const parseContent = (donnees: any) => {
    const d = JSON.parse(donnees);
    const blocks = d.blocks;

    let allText = "";

    // Parcours de chaque tableau dans blocks
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      const text = block.text;

      // Concaténation des textes
      allText += text;
    }

    const sentenceDelimiters = [".", "!", "?"];
    const customDelimiters = ["..."];
    const delimiterPattern = `[${sentenceDelimiters.join(
      ""
    )}${customDelimiters.join("")}]`;
    const sentences = allText.split(new RegExp(delimiterPattern));
    const filteredSentences = sentences.filter(
      (sentence) => sentence.trim() !== ""
    );

    return filteredSentences.length;
  };

  const goToAds = (item: any) => {
    history.push("/alternative-financing/ads/" + item.id);
  };

  return (
    <div className="findIsvcontainer programContainer maContainer">
      <Header
        searchText={provider?.searchText}
        handleChange={provider?.setSearchText}
        handleClick={provider?.search}
      />

      {provider.showLoading && <LoadingAllPage />}

      {/* FILTRES */}
      <div className="filterFindIsv">
        <div className="contentFilter">
          <div className="containerBtn block infoProgramContainer">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => provider.filterAlternative()}
            >
              Filter
            </button>
            <NavLink
              to="#"
              className="resetFilter"
              onClick={() => provider.resetFilter()}
            >
              <span className="icon-refresh" />
              Reset filter
            </NavLink>
          </div>
          <div className="block">
            <h3 onClick={(e) => openBlock(e)} className="title withChild open">
              Solution
            </h3>
            <div className="listCheck">
              {paginate(provider.dataSolution, provider.limitSolution)?.map(
                (el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      id={`sol${index}`}
                      className="custom-control-input"
                      onChange={() => provider.handleSelectSolution(el?.id)}
                      checked={
                        provider.selectedSolution?.find(
                          (selected: any) => selected === el?.id
                        )
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={`sol${index}`}
                      className="custom-control-label"
                    >
                      {el.name}
                    </label>
                  </div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeSolution(p)}
                dataSource={provider.dataSolution}
                limit={provider.limitSolution}
              />
            </div>
          </div>
          <div className="block">
            <h3 onClick={(e) => openBlock(e)} className="title withChild open">
              Industry
            </h3>
            <div className="listCheck">
              {paginate(provider.industries, provider.limitIndustry)?.map(
                (el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      id={`ind${index}`}
                      className="custom-control-input"
                      onChange={() => provider.handleSelectIndustry(el?.id)}
                      checked={
                        provider.selectedIndustry?.find(
                          (selected: any) => selected === el?.id
                        )
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={`ind${index}`}
                      className="custom-control-label"
                    >
                      {el.name}
                    </label>
                  </div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeIndustry(p)}
                dataSource={provider.industries}
                limit={provider.limitIndustry}
              />
            </div>
          </div>
          <div className="block">
            <h3 onClick={(e) => openBlock(e)} className="title withChild open">
              HQ location
            </h3>
            <div className="listCheck">
              {paginate(provider?.hqLocation, provider.limitCountry)?.map(
                (el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      id={`country${index}`}
                      className="custom-control-input"
                      onChange={() => provider.handleSelectCountry(el?.id)}
                      checked={
                        provider.selectedCountry?.find(
                          (selected: any) => selected === el?.id
                        )
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={`country${index}`}
                      className="custom-control-label"
                    >
                      {el.country_name}
                    </label>
                  </div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeCountry(p)}
                dataSource={provider?.hqLocation}
                limit={provider.limitCountry}
              />
            </div>
          </div>
          <div className="block">
            <h3 onClick={(e) => openBlock(e)} className="title withChild open">
              Funding target
            </h3>
            <div className="listCheck">
              {paginate(provider?.fundingTarget, provider.limitFunding)
                ?.filter(
                  (el: {
                    annual_raise_min: number | null;
                    annual_raise_max: number | null;
                  }) =>
                    el?.annual_raise_min !== null &&
                    el?.annual_raise_max !== null
                )
                .map(
                  (
                    el: { annual_raise_min: number; annual_raise_max: number },
                    index: number
                  ) => (
                    <div
                      className="custom-control custom-checkbox orange"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        id={`funding${index}`}
                        className="custom-control-input"
                        onChange={() =>
                          provider.handleSelectFunding(el?.annual_raise_min)
                        }
                        checked={
                          provider.selectedFunding?.find(
                            (selected: any) => selected === el?.annual_raise_min
                          )
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor={`funding${index}`}
                        className="custom-control-label"
                      >
                        {formatNumber(el.annual_raise_min) +
                          " to " +
                          formatNumber(el.annual_raise_max) +
                          " USD"}
                      </label>
                    </div>
                  )
                )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeFunding(p)}
                dataSource={provider.fundingTarget}
                limit={provider.limitFunding}
              />
            </div>
          </div>
        </div>
      </div>

      {/* LISTE ALTERNATIVES */}
      <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          {(provider?.alternativeReducer?.data || [])?.length > 0 && (
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  Showing{" "}
                  <b>{provider?.alternativeReducer?.pagination?.totalItems}</b>{" "}
                  ads
                </div>
              </div>
            </div>
          )}

          <div className="scrollContainer">
            <AboutSection />
            <ul className="listOfIsvMatch listEvents">
              {provider?.alternativeReducer?.data?.map((el: any, index) => (
                <li onClick={() => goToAds(el)} key={index}>
                  <div className="isvLogo">
                    <img src={fileUrl(el.logo)} alt="" />
                    <div className="descAds">
                      <h6>{el.company_name}</h6>
                      <span>{el.country?.country_name}</span>
                    </div>
                  </div>
                  <div className="isvInfo">
                    <div className="infoUnlocked">
                      <div className="calendar">
                        <div className="item">
                          <div className="adsInfos">
                            <h3 className="title DIMemberTitle">{el.title}</h3>
                            <div className="infoProgram">
                              <div
                                className="detailsAds"
                                dangerouslySetInnerHTML={createMarkup(
                                  convertCommentFromJSONToHTML(
                                    getDescriptionEditorState(el.content)
                                  )
                                )}
                              />
                            </div>
                            {parseContent(el?.content) > 2 && (
                              <a href="javascript:;" className="seemore ma">
                                <span className="colorInitial">... </span>See
                                more
                              </a>
                            )}
                            <div className="topicAds">
                              {[
                                ...el.featured_solution,
                                ...el.software_solution,
                              ]?.map((soft, index) => (
                                <span className="topic" key={index}>
                                  {soft.name}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoEventContainer">
                        <div>
                          <div className="loader">
                            <div className="loader-result"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="pagginationCategory">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={provider?.alternativeReducer?.pagination?.current}
                totalCount={
                  provider?.alternativeReducer?.pagination?.totalItems || 0
                }
                pageSize={10}
                onPageChange={(page: any) => provider.handlePageClick(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
