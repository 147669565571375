import { rootState } from "../../../redux/reducers";
import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
} from "../../../service/constant";

export type AllCompanyPage = {
  company_name: string;
  id: string;
  profil: string;
  city: string | null;
  country: any;
};

/**
 * Get company page by user role
 * @param {rootState} state
 * @returns {AllCompanyPage}
 */
export const companyPageByUser = (state: rootState): AllCompanyPage => {
  const companyPage: AllCompanyPage = {
    company_name: "",
    id: "0",
    profil: "",
    city: null,
    country: null,
  };
  switch (state?.userReducer?.roles?.[0]) {
    case ISV:
      companyPage.id = state?.companyPageReducer?.id;
      if (state?.companyPageReducer?.completed) {
        companyPage.company_name = state?.companyPageReducer?.company_name;
        companyPage.profil = state?.companyPageReducer?.photo_profil;
        companyPage.country = state?.companyPageReducer?.country?.country_name;
        companyPage.city = state?.companyPageReducer?.city;
      } else {
        companyPage.company_name = "Add your company";
      }
      break;
    case INVESTOR:
      companyPage.id = state?.companyInvestorPageReducer?.id;
      companyPage.company_name =
        state?.companyInvestorPageReducer?.organization_name;
      companyPage.profil = state?.companyInvestorPageReducer?.photo_profil;
      companyPage.country =
        state?.companyInvestorPageReducer?.country?.country_name;
      companyPage.city = state?.companyInvestorPageReducer?.location;
      break;
    case OEM_PROGRAM:
      companyPage.id = state?.oemProgramReducer?.program?.id;
      companyPage.company_name =
        state?.oemProgramReducer?.program?.company_name;
      companyPage.profil = state?.oemProgramReducer?.program?.photo_profil;
      companyPage.country = state?.oemProgramReducer?.program?.location;
      break;
    case CONTRIBUTOR:
      companyPage.id = state?.userReducer?.expertCompany?.id;
      companyPage.company_name =
        state?.userReducer?.expertCompany?.company_legal_name ||
        state?.userReducer?.company_name;
      companyPage.profil = state?.userReducer?.expertCompany?.logo;
      companyPage.country =
        state?.userReducer?.expertCompany?.country?.country_name ||
        state?.userReducer?.country?.country_name;
      break;
    default:
      companyPage.id = state?.resellerCompanyReducer?.id;
      companyPage.company_name =
        state?.resellerCompanyReducer?.company_legal_name;
      companyPage.profil = state?.resellerCompanyReducer?.photo_profil;
      companyPage.country =
        state?.resellerCompanyReducer?.country?.country_name;
      companyPage.city = state?.resellerCompanyReducer?.city;
  }

  return companyPage;
};
