import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useDataContext } from "../../../../context";
import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from "../../../../service/constant";

type IpropMenu = {
  activeTab: number;
  setActiveTab: (idActive: number) => void;
  unreadeTypeNbr: any;
  userType: string;
  nbrRequest: number;
  nbrArchive: number;
  handleFilter: (type: any) => void;
  isLoadingConversationMessage: boolean;
  isLoadingFetchMessage: boolean;
  loaderGetInfoDiscussion: boolean;
};

export default function MenuSelectDiscussion(props: IpropMenu) {
  let tempFilter: any = props.unreadeTypeNbr;
  const { COMMUNITY, STRATEGIC_PARTNERSHIPS, CORPORATE_DEVELOPMENT, FIND_ISV } =
    props.unreadeTypeNbr;

  const totalUnread =
    (COMMUNITY || 0) +
    (STRATEGIC_PARTNERSHIPS || 0) +
    (CORPORATE_DEVELOPMENT || 0) +
    (FIND_ISV || 0);

  const [notifMessage, setNotifMessage] = useState<any>(totalUnread);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { newNotifMessage } = useDataContext();
  const inputRef = useRef<any>(null);

  const onClear = () => {
    inputRef.current?.select.clearValue();
  };

  useEffect(() => {
    onClear();
  }, [props.activeTab]);

  const options = [
    {
      value: "COMMUNITY",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-community"></span><span class=${
              tempFilter?.["COMMUNITY"] > 0 && "non-lu"
            }>${tempFilter?.["COMMUNITY"] || ""}</span></div> Community`,
          }}
        />
      ),
      className: "community",
    },
    {
      value: "FIND_ISV",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-find-investors"></span><span class=${
              tempFilter?.["FIND_ISV"] > 0 && "non-lu"
            }>${tempFilter?.["FIND_ISV"] || ""}</span></div></div> Find ISVs`,
          }}
        />
      ),
      classname: "findI",
    },
    {
      value: "STRATEGIC_PARTNERSHIPS",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-classified-ads"></span><span class=${
              tempFilter?.["STRATEGIC_PARTNERSHIPS"] > 0 && "non-lu"
            }>${
              tempFilter?.["STRATEGIC_PARTNERSHIPS"] || ""
            }</span></div></div> Strategic partnerships`,
          }}
        />
      ),
      classname: "ads",
    },
    {
      value: "CORPORATE_DEVELOPMENT",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-find-programs"></span><span class=${
              tempFilter?.["CORPORATE_DEVELOPMENT"] > 0 && "non-lu"
            }>${
              tempFilter?.["CORPORATE_DEVELOPMENT"] || ""
            }</span></div></div> Corporate development`,
          }}
        />
      ),
      classname: "findP",
    },
  ];

  const filterOption = (options: Array<any>, ...index: Array<number>) => {
    return options?.filter((el: any, i: number) => !index.includes(i));
  };
  const onValueChanged = (params: any) => {
    props.handleFilter(params);
    setSelectedCategory(params);
  };

  const indexFilter = (): Array<number> =>
    props.userType === ISV
      ? [1]
      : props.userType === OEM_PROGRAM
      ? [2]
      : props.userType === INVESTOR
      ? [2, 4]
      : props.userType === CONTRIBUTOR
      ? [0, 1, 2, 3]
      : props.userType === RESELLER
      ? [1, 2]
      : props.userType === MODERATOR
      ? [1, 2]
      : [1, 2, 3];

  const [refreshCount, setRefreshCount] = useState(0);

  const handleRefresh = () => {
    props.handleFilter({ value: "ALL" });
    setSelectedCategory(null);
    setRefreshCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (newNotifMessage || totalUnread) {
      setNotifMessage(newNotifMessage);
    }
    return () => {};
  }, [newNotifMessage]);

  useEffect(() => {
    setNotifMessage(totalUnread);
    return () => {};
  }, [props.unreadeTypeNbr]);

  return (
    <div className="container">
      <div className={"row"}>
        <div className="left-tab">
          <ul className="nav" id="tab-left" role="tablist">
            <li className="nav-item messages" onClick={handleRefresh}>
              <div className="ico-container">
                <span className="icon-msg-message"></span>
                <span className="non-lu">{notifMessage}</span>
              </div>
              <a
                onClick={(e) => e.preventDefault()}
                className="nav-link active "
                aria-controls="home"
                aria-selected="true"
              >
                All messages
              </a>
            </li>
            <li
              className="nav-item selectCat"
              style={
                props.userType === CONTRIBUTOR
                  ? { position: "absolute", opacity: 0, pointerEvents: "none" }
                  : {}
              }
            >
              <Select
                classNamePrefix="react-select"
                ref={inputRef}
                autoFocus={false}
                isSearchable={false}
                value={selectedCategory}
                placeholder={
                  <div className="cat placeHolder">
                    <div className="ico-container">
                      <span className="icon-msg-select-category"></span>
                      {/* <span className="non-lu">19</span> */}
                    </div>
                    <span className="txt">Select a category</span>
                  </div>
                }
                options={filterOption(options, ...indexFilter())}
                onChange={(params: any) => onValueChanged(params)}
              />
            </li>
          </ul>
        </div>

        <div className="right-tab">
          <ul className="nav nav-tabs" id="tab-left" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${props.activeTab === 0 && "active"} ${
                  (props?.isLoadingConversationMessage ||
                    props?.isLoadingFetchMessage ||
                    props?.loaderGetInfoDiscussion) &&
                  "disabled"
                }`}
                id="home-tab"
                data-toggle="tab"
                href="#conversation-tab"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(0);
                }}
              >
                <span>Conversations</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${props.activeTab === 1 && "active"} ${
                  (props?.isLoadingConversationMessage ||
                    props?.isLoadingFetchMessage ||
                    props?.loaderGetInfoDiscussion) &&
                  "disabled"
                }`}
                id="profile-tab"
                data-toggle="tab"
                href="#isv-request-tab"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(1);
                }}
              >
                <span>
                  {`Message requests   ${
                    notifMessage["NUMBER_REQUEST"]
                      ? "(" + notifMessage["NUMBER_REQUEST"] + ")"
                      : props.nbrRequest
                      ? "(" + props.nbrRequest + ")"
                      : ""
                  }`}
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${props.activeTab === 2 && "active"} ${
                  (props?.isLoadingConversationMessage ||
                    props?.isLoadingFetchMessage ||
                    props?.loaderGetInfoDiscussion) &&
                  "disabled"
                }`}
                id="profile-tab"
                data-toggle="tab"
                href="#archive-tab"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(2);
                }}
              >
                <span>
                  {`Archive  ${
                    props.nbrArchive ? "(" + props.nbrArchive + ")" : ""
                  }`}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
