import thunk from 'redux-thunk';
import reducers from './reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore, compose } from 'redux';

const persistConfig = {
  key: 'root',
  storage,
};

const logger = createLogger({
  // ...options
});

const middleware = [thunk, logger];

const pReducer = persistReducer(persistConfig, reducers);
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

export default store;
