import React from 'react';
import { Link } from 'react-router-dom';
import store from '../../../../redux/store';
type Iprops = {
  goToCommunityHome: () => void;
};
export default function BlockCommunity(props: Iprops) {
  const isPremium =
    store?.getState()?.oemProgramReducer.program &&
    store?.getState()?.oemProgramReducer.program.oem_subscription.name ===
      'Premium';

  return (
    <div className='submenu'>
      <div className='title'>
        <div className='container mx-auto'>
          <span onClick={() => props.goToCommunityHome()}>Community</span>
          Join a global community for software publishers (ISVs).
        </div>
      </div>
      <div className='container mx-auto'>
        <Link className='linkHeader' to='/community/discussions-categories'>
          <span className='name'>Forum discussions</span>
          <p>
            Discuss the business challenges of your software company with peers
            and industry experts.
          </p>
        </Link>
        <Link className='linkHeader' to='/community/article-category/0/0'>
          <span className='name'>Articles</span>
          <p>
            Share your expertise and insights to help software publishers with
            high-value content.
          </p>
        </Link>
        {/* <Link to='/community/press-releases/0' className='linkHeader'>
          <span className='name'>Press releases</span>
          <p>Create PR exposure among the global ISV community.</p>
        </Link> */}
        <Link
          className="linkHeader"
          to="/events/all-events"
        >
          <span className="name">Events</span>
          <p>
            Browse high-value online and offline events dedicated to 
            software publishers.
          </p>
        </Link>
      </div>
    </div>
  );
}
