/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Form from "react-bootstrap/Form";
import UseCompleteCompanyIndustrieTargetReseller, {
  IListTarget,
} from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-industrie-target-reseller";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";

export default function CompleteCompanyIndustrieTargetReseller() {
  const {
    navigateCompleteDescription,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    loader,
    addTargetCompany,
    companyTargetSelected,
    goBack,
    errorData,
  } = UseCompleteCompanyIndustrieTargetReseller();

  return (
    <div>
      <Header />
      <div className="getstartedContainer largeContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
          </div>
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Company information</h1>
        </div>
        <div className="contentBorder border-top row pt-3 pb-4">
          <div className="form-group required col-xl-12 p-0 m-0">
            <label htmlFor="" className="notBold">
              What industry do you target with your software solution(s)? Select
              all that apply.
              {errorData && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    select all least one industry
                  </span>
                </div>
              )}
            </label>
            {loader && <Loader />}

            <div className="contentTargetIndustry">
              <div className="col4">
                {companyTarget &&
                  companyTarget.map((element: IListTarget, index: number) => (
                    <Form.Check
                      custom
                      key={index}
                      checked={
                        companyTargetSelected?.find(
                          (ele: IListTarget) => ele.id == element.id
                        )
                          ? true
                          : false
                      }
                      label={element.name}
                      id={element.id.toString()}
                      onClick={() => {
                        addTargetCompany(element);
                      }}
                      onChange={() => null}
                    />
                  ))}
              </div>
              <div className="col4">
                {companyTargetSecound &&
                  companyTargetSecound.map(
                    (element: IListTarget, index: number) => (
                      <Form.Check
                        custom
                        key={index}
                        checked={
                          companyTargetSelected?.find(
                            (ele: IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          addTargetCompany(element);
                        }}
                        onChange={() => null}
                      />
                    )
                  )}
              </div>
              <div className="col4">
                {lastCompanyTarget &&
                  lastCompanyTarget.map(
                    (element: IListTarget, index: number) => (
                      <Form.Check
                        custom
                        checked={
                          companyTargetSelected?.find(
                            (ele: IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        key={index}
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          addTargetCompany(element);
                        }}
                        onChange={() => null}
                      />
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateCompleteDescription()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
