import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { contactSupportBillingService } from '../../../service/applicatif/company-page-isv';
import { rootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { FormContactSupport } from './type';
import { toast } from 'react-toastify';

export default function UseContactSupportCtr() {
  const [isShowContactSupport, setIsShowContactSupport] =
    useState<boolean>(false);

  let userObject = useSelector((state: rootState) => state.userReducer);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormContactSupport>({
    mode: 'onSubmit',
  });
  const fileName: any = watch('fileAttachement');
  const [loaderContact, setloaderContact] = useState<boolean>(false);
  /**
   * @param {T.FormContactSupport} p value of form
   * @returns promise of post form nd close modal
   */
  const submitFormContactSupport = async (p: FormContactSupport) => {
    setloaderContact(true);
    let params = {
      firstName: p?.firstName,
      lastName: p?.lastName,
      email: userObject.email,
      topic: p?.topics,
      subject: p?.subject,
      file: p?.fileAttachement ? p?.fileAttachement?.[0] : '',
    };
    let response = await contactSupportBillingService(
      params,
      userObject?.token
    );
    if (response?.status === 200) {
      setloaderContact(false);
      setIsShowContactSupport(false);
      toast(
        'Thank you for contacting us. Your message has been successfully sent.'
      );
    }
  };

  const removeFileUploaded = () => {
    setValue('fileAttachement', undefined);
  };

  return {
    isShowContactSupport,
    setIsShowContactSupport,
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    submit: submitFormContactSupport,
    getValues,
    fileName,
    loader: loaderContact,
    setloaderContact,
    userObject,
    removeFileUploaded,
  };
}
