import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

import UseIndustryList from "../../../widget/WidgetSelected/IndustryListe/UseIndustryList";
import UseCompleteCategoryInvestor from "../../../widget/WidgetSelected/SolutionList/use-SF";
import { IListTarget } from "../../../widget/industries-list/use-IL";
import { useHistory, useParams } from "react-router-dom";
import {
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  MODERATOR,
  url,
} from "../../../../service/constant";
import { getLooUpKeyPostMA, isExist } from "../../../../utils";
import DefaultCompany from "../../../../resources/images/defaultCompany.png";
import { toast } from "react-toastify";
import { getDataWithoutToken, putData } from "../../../../service/api";
import getMonth from "date-fns/esm/fp/getMonth/index.js";
import {
  ItemMa,
  ResponseIndustries,
  ResponseSegments,
} from "../../../../types/Ma";
import {
  getDataService,
  postJsonDataService,
} from "../../../../service/applicatif/back-end-service";
import store from "../../../../redux/store";

export default function usePostMA() {
  const params: { id?: string | undefined } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [segmentsList, setSegmentsList] = useState<ResponseSegments>({
    segments: [],
  });
  const [isLoadingIl, setIsLoadingIl] = useState<boolean>(false);
  const [industriesList, setIndustriesList] = useState<ResponseIndustries>({
    industries: [],
  });
  const [isLoadingFetch, setIsLoadingFetch] = useState<boolean>(false);
  const [defaultItemData, setDefaultItemData] = useState<ItemMa | null>();

  const [isEditPost, setIsEditPost] = useState<boolean>(false);
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);

  const editAnnounce = async (params: any, idP: any) => {
    setIsEditPost(true);
    try {
      const paramsData = {
        url: `${url.Ma.postAnnounce}/${idP || "0"}`,
        token: store?.getState()?.userReducer?.token,
        data: params,
      };
      const response = await putData(paramsData);

      console.log("editAnnounce", response);

      if (response?.status === 200) {
        //setDefaultItemData(response.data);
        return response.data;
      }
      setIsEditPost(false);
    } catch (error) {}
    setIsEditPost(false);
  };

  const postAnnounce = async (params: any) => {
    setIsLoadingPost(true);
    try {
      const response = await postJsonDataService(
        url.Ma.postAnnounce,
        params,
        store?.getState()?.userReducer?.token
      );
      setIsLoadingPost(false);
      console.log("postJsonDataService", response);
      return response?.data;
    } catch (error) {}
    setIsLoadingPost(false);
  };

  const getItemQuery = async (idP: any) => {
    setIsLoadingFetch(true);
    try {
      const response = await getDataService(
        `${url.Ma.getAnnounce}/${idP || "0"}`,
        {},
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        setDefaultItemData(response.data);
      }
      setIsLoadingFetch(false);
    } catch (error) {}
    setIsLoadingFetch(false);
  };

  const UseGetSegmentsQuery = async () => {
    setIsLoading(true);
    try {
      const response = await getDataService(
        url.Ma.get_solution,
        {},
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        setSegmentsList(response?.data);
      }
      setIsLoading(false);
    } catch (error) {}
    setIsLoading(false);
  };
  useEffect(() => {
    UseGetSegmentsQuery();
  }, []);

  const UseGetIndustriesQuery = async () => {
    setIsLoadingIl(true);
    try {
      const response = await getDataService(
        url.Ma.get_industry,
        {},
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        setIndustriesList(response?.data);
      }
      setIsLoadingIl(false);
    } catch (error) {}
    setIsLoadingIl(false);
  };
  useEffect(() => {
    UseGetIndustriesQuery();
  }, []);

  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const user = useSelector((state: rootState) => state.userReducer);
  const isModerator = user?.roles?.indexOf(MODERATOR) !== -1;
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [displayAuthorName, setDisplayAuthorName] = useState<boolean>(true);
  const [displayCompanyName, setDisplayCompanyName] = useState<boolean>(
    isModerator ? false : true
  );
  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [isErrorObject, setIsErrorObject] = useState(false);
  const [isErrorSolution, setIsErrorSolution] = useState(false);
  const [isErrorIndustry, setIsErrorIndustry] = useState(false);
  //const [isSuccess, setIsSuccess] = useState(false);
  //const [isPayementError, setIsPayementError] = useState(false);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [freeNumber, setFreeNumber] = useState<number>(0);
  const isIsv = user?.roles?.indexOf(ISV) !== -1;
  const isGuest = user?.roles?.indexOf(GUEST) !== -1;
  const isReseller = user?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = user?.roles?.indexOf(INVESTOR) !== -1;
  const isOem = user?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const now = new Date();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>({
    lookupKey: getLooUpKeyPostMA(user?.roles[0]),
    name: "Post mergers and acquisitions",
  });

  const companyPageIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const oemProgramReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer.program
  );

  const [selectedIndustry, setSelectedIndustry] = useState<Array<IListTarget>>(
    []
  );

  const [selectedSolution, setSelectedSolution] = useState<Array<IListTarget>>(
    []
  );

  const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<
    Array<IListTarget>
  >([]);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault,
    otherValue: otherValueIndustry,
    setOtherValueTxt: setOtherValueTxtIndustry,
  } = UseIndustryList({
    defaultSelected: selectedIndustry,
    defaultDataCompleted: industriesList?.industries || [],
  });

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    otherValue,
    setOtherValueTxt,
    backToDefault: backToDefaultSoftwareSolution,
  } = UseCompleteCategoryInvestor({
    defaultValue: selectedSolution,
    defaultValueFeatured: selectedFeaturedSolution,
    solutionCategoryList: segmentsList?.segments,
  });

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const cancelPost = () => {
    history.goBack();
  };

  const savePostMA = () => {
    if (title.length < 1) {
      setIsErrorObject(true);
      return;
    }
    if (selectedIndustry.length < 1) {
      setIsErrorIndustry(true);
      return;
    }
    if (selectedFeaturedSolution.length < 1 && selectedSolution.length < 1) {
      setIsErrorSolution(true);
      return;
    }
    const isEdit = isExist(params?.id);
    if (isEdit) {
      publishMa(undefined, "");
    } else {
      setShowPaymentModal(true);
    }
  };

  useEffect(() => {
    console.log("params", params);
    if (params.id) {
      getItemQuery(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (defaultItemData) {
      const makeIndustry = (defaultItemData?.targetedIndustry || [])?.map(
        (el) => {
          if (el.other) {
            return {
              ...el,
              id: -1,
            };
          }
          return el;
        }
      );
      const makeSolution = (
        [
          ...defaultItemData?.softwareSolution,
          ...defaultItemData.featuredSolution,
        ] || []
      )?.map((el) => {
        if (el.other) {
          return {
            ...el,
            id: -1,
          };
        }
        return el;
      });
      setTitle(defaultItemData?.title || "");
      setDisplayAuthorName(defaultItemData?.displayAuthorName);
      setDisplayCompanyName(defaultItemData?.displayCompanyName);
      setSelectedSolution(makeSolution);
      setSelectedIndustry(makeIndustry);
      setFirstName(
        defaultItemData?.author?.first_name === "Anonymous user"
          ? defaultItemData?.author?.first_name
          : "Anonymous user"
      );
      setEditorState(
        EditorState?.createWithContent(
          convertFromRaw(JSON.parse(defaultItemData?.content))
        )
      );
    }
  }, [defaultItemData]);

  const cancelTargeted = () => {
    backToDefault();
    setShow(!show);
  };

  const cancelSolution = () => {
    backToDefaultSoftwareSolution();
    setShowSolution(!showSolution);
  };

  const validTargeted = () => {
    const updatedOtherValue = companyTargetSelected.map((el) => {
      if (el.id === -1) {
        return {
          ...el,
          name: otherValueIndustry || "",
        };
      } else {
        return el;
      }
    });
    setSelectedIndustry(updatedOtherValue);
    setShow(!show);
  };

  const validSolution = () => {
    const updatedOtherValue =
      solutionChecked &&
      solutionChecked.map((el) => {
        if (el.id === -1) {
          return {
            ...el,
            name: otherValue || "",
          };
        } else {
          return el;
        }
      });
    setSelectedSolution(updatedOtherValue);
    setSelectedFeaturedSolution(solutionCheckedFeatures);
    setShowSolution(!showSolution);
  };

  useEffect(() => {
    setIsErrorObject(false);
  }, [title]);

  useEffect(() => {
    setIsErrorIndustry(false);
  }, [selectedIndustry]);

  useEffect(() => {
    setIsErrorSolution(false);
  }, [selectedFeaturedSolution, selectedSolution]);

  const publishMa = async (
    pid: string | undefined,
    choiseValue: string | undefined,
    savePaymentMethod?: boolean
  ) => {
    const selectedTargetIndustry = selectedIndustry?.map((el) => el.id);
    const selectedSolutionChecked = selectedSolution?.map((el) => el.id);
    const selectedFeatured = selectedFeaturedSolution?.map((el) => el.id);
    const otherValueSolution = selectedSolution?.find((el) => el.id === -1);
    const otherValueIndustry = selectedIndustry?.find((el) => el.id === -1);
    const paramsPost = {
      title: title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      displayAuthorName: displayAuthorName ? 1 : 0,
      displayCompanyName: displayCompanyName ? 1 : 0,
      softwareSolution: selectedSolutionChecked,
      featuredSolution: selectedFeatured,
      targetedIndustry: selectedTargetIndustry,
      othersIndustry: otherValueIndustry ? [otherValueIndustry.name] : [],
      othersSolution: otherValueSolution ? [otherValueSolution.name] : [],
      paymentId: pid || "",
      choice: choiseValue,
      oemProgramId: isOem ? oemProgramReducer.id : "",
      savePaymentMethod: savePaymentMethod || false,
    };
    const isEdit = isExist(params?.id);
    if (isEdit) {
      try {
        await editAnnounce(paramsPost, params?.id);
        history.push("/ma/ads/" + params?.id);
      } catch (error) {
        toast("An error occurred");
      }
    } else {
      const responsePost = await postAnnounce(paramsPost);
      console.log("responsePost", responsePost);
      if (responsePost && responsePost?.free_mode) {
        toast("The post has been added successfully");
        setIsFree(responsePost?.free_mode);
        showMAList();
        return;
      }
      if (responsePost && responsePost?.invoice_number) {
        setInvoiceNumber(responsePost?.invoice_number);
      }
      return responsePost;
    }
  };

  const companyPageObject = useSelector((state: rootState) => {
    let userRole = user?.roles?.[0];
    if (userRole === ISV)
      return {
        photo_profil: state.companyPageReducer.photo_profil,
        company_name: state.companyPageReducer.company_name,
      };
    if (userRole === OEM_PROGRAM)
      return {
        photo_profil: state.oemProgramReducer.program.photo_profil,
        company_name: state.oemProgramReducer.program.program_name,
      };
    if (userRole === INVESTOR)
      return {
        photo_profil: state.companyInvestorPageReducer.photo_profil,
        company_name: state.companyInvestorPageReducer.organization_name,
      };
    if (userRole === RESELLER)
      return {
        photo_profil: state.resellerCompanyReducer.photo_profil,
        company_name: state.resellerCompanyReducer.company_legal_name,
      };
    if (userRole === GUEST)
      return {
        photo_profil: state.userReducer.guestCompany.logo,
        company_name: state.userReducer.company_name,
      };
    if (userRole === MODERATOR)
      return {
        photo_profil: DefaultCompany,
        company_name: "Company confidential",
      };

    return { photo_profil: DefaultCompany, company_name: "" };
  });

  const showMAList = () => {
    history.push("/ma/listings");
  };

  React.useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        user.token
      );

      if (response?.status === 200) {
        const dynamicKey = isIsv
          ? "isv_free_corporate_ads"
          : isGuest
          ? "guest_free_corporate_ads"
          : isReseller
          ? "reseller_corporate_ads"
          : isInvestor
          ? "investor_free_corporate_ads"
          : isOem
          ? "oem_program_corporate_ads"
          : "isv_free_corporate_ads";
        const freeNumberStatus: any = response?.data?.free_status[dynamicKey];
        console.log("freeNumberStatus", freeNumberStatus);
        setFreeNumber(freeNumberStatus?.number_left || 0);
      }
      setChargingPlanDetails(false);
    }
    console.log("user", user);
    if (user.token) {
      getPlanDetails();
    }
  }, [currentMonth, user.token]);

  const paymentMethodCallBack = async (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod: boolean | undefined,
    setShowPaymentStatus: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    const choiseValue = isFree ? "FREE" : "PAY";
    const respPostMa: any = await publishMa(
      paymentMethodId || "",
      choiseValue,
      savePaymentMethod || false
    );
    console.log("respPostMarespPostMa", respPostMa);
    if (respPostMa && !respPostMa?.free_mode) {
      setShowPaymentStatus(true, {
        status: respPostMa.success ? "success" : "error",
        invoiceNumber: respPostMa.invoice_number,
        invoiceUrl: respPostMa.invoice_url,
      });
    }
  };

  const handleStatusOk = () => {
    history.push("/ma/listings");
  };

  return {
    mentions,
    user,
    editorState,
    setEditorState,
    cancelPost,
    savePostMA,
    title,
    setTitle,
    segmentsList,
    industriesList,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault,
    cancelTargeted,
    show,
    setShow,
    validTargeted,
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefaultSoftwareSolution,
    companyPageIsv,
    selectedIndustry,
    showSolution,
    setShowSolution,
    cancelSolution,
    validSolution,
    isLoadingPost,
    selectedSolution,
    selectedFeaturedSolution,
    showLoading: isLoading || isLoadingIl || isLoadingFetch || isEditPost,
    isErrorObject,
    isErrorSolution,
    isErrorIndustry,
    isGuest,
    isIsv,
    isReseller,
    isInvestor,
    isOem,
    isModerator,

    publishMa,
    displayAuthorName,
    displayCompanyName,
    setDisplayAuthorName,
    setDisplayCompanyName,
    companyPageObject,
    invoiceNumber,
    otherValue,
    setOtherValueTxt,
    otherValueIndustry,
    setOtherValueTxtIndustry,
    loadingSendMa: isLoadingPost || isEditPost,
    isFree,
    firstName,
    product,
    setProduct,
    showPaymentModal,
    setShowPaymentModal,
    chargingPlanDetails,
    freeNumber,
    paymentMethodCallBack,
    handleStatusOk,
  };
}
