import { useState } from 'react';
import { getSStorage } from '../../../utils';
import { useForm } from 'react-hook-form';
import { useCompleteDataStorageOem } from './use-complete-data-storage-oem';
import useNavigation from '../../../hooks/use-navigate';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';

interface StepData {
  companyNameOem: string;
  webSiteOem: string;
  selectCountryOem: number;
  cityOem: string;
}

export default function useCompleteWebsiteLocation() {
  const userState = useSelector((state: rootState) => state.userReducer);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );

  const { navigate } = useNavigation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { dataOem, addDataOem } = useCompleteDataStorageOem();

  const onSubmit = (data: StepData) => {
    addDataOem({
      _companyName: data.companyNameOem,
      website: data.webSiteOem,
      city: data.cityOem,
      location: data.cityOem,
      country: data.selectCountryOem,
      _country: data.selectCountryOem,
    });

    navigate('/complete-program-type-oem');
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    dataCompleted,
    dataOem,
    userState,
  };
}
