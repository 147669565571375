import {IReduxAction} from "../../types/type-action";
import {Reducer} from "redux";

export const actionType = {
    SET_PAYMENT_METHOD_ID: 'SET_PAYMENT_METHOD_ID'
} as const;

export type IBilling = {
    paymentMethodId: number | null;
}

const initialState: IBilling = {
    paymentMethodId: null
};

export const billingReducer: Reducer<IBilling, IReduxAction<any>> = (
    state = initialState,
    action
) => {
    switch (action?.type) {
        case actionType.SET_PAYMENT_METHOD_ID:
            return {
                ...state,
                paymentMethodId: action?.payload,
            };
        default:
            return state;
    }
};
