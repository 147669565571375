/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent } from "react";
import UseContactUsWidget from "../../../../widget/contact-us-widget/use-contact-us-widget";
import ModalContactUs from "../../../../widget/contact-us-widget/index";
import {
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from "../../../../../service/constant";
//dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
const ContactSupport = (props: any) => {
  const providerUseContactUsWidget = UseContactUsWidget();

  return (
    <>
      <div className="itemBilling">
        <h2 className="title">Contact support</h2>
        <div className="contentItem">
          <div className="itemctt">
            <p>
              <small style={{ fontSize: "14px" }}>
                For help and support with your billing questions, get in touch
                with our support team right now.
              </small>
            </p>
          </div>
          <div className="footerItem" style={{ fontSize: "14px" }}>
            {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
            <a
              onClick={() =>
                [
                  OEM_PROGRAM_EDITOR,
                  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                ].indexOf(props?.roleUserIncurrentProgram) !== -1
                  ? props?.setShowModalPermissionOem(true)
                  : providerUseContactUsWidget?.setIsShowContactSupport(true)
              }
            >
              Get in touch
            </a>
          </div>
        </div>
        <ModalContactUs {...providerUseContactUsWidget} required />
      </div>
    </>
  );
};

export default ContactSupport;
