import React, { FunctionComponent, ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import store from "../../../redux/store";
import { checkType, fileUrl } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { GUEST } from "../../../service/constant";
import { updateInfoUserAction } from "../../../redux/actions/acounts-action";

export const Communityprofile: FunctionComponent = () => {
  const userData = useSelector((state: rootState) => state.userReducer);
  const dispatch = useDispatch();

  const [communityProfile, setCommunityProfile] = useState<string>("");
  const isHidectivity = checkType(userData.roles, [GUEST]);

  const renderLastName = (communityProfile: string, last_name: string) => {
    if (communityProfile === "SEMI-VISIBLE")
      return last_name.charAt(0).toUpperCase();
    if (communityProfile === "ANONYMOUS") return last_name;
    return last_name;
  };

  const renderFirstName = (communityProfile: string, first_name: string) => {
    if (communityProfile === "ANONYMOUS") return "";
    return first_name;
  };

  const updateUserDetail = (key: string) => {
    let data = {
      userCommunityProfil: key?.toString(),
    };
    dispatch(updateInfoUserAction(data, () => {}));
  };

  return (
    <div className="accountSettings contentRightsettings">
      <h1 className="title">Devinsider profile settings</h1>
      <div className="infoAccount row p-0 m-0">
        <ul className="listSettings col-xl-7 col-lg-12 col-12 p-0 pr-5">
          <li>
            <h2 className="title ">
              Choose how other Devinsider members see your profile
            </h2>
            <div className="multichoosecontent  ">
              <div className="custom-control custom-radio ">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="visible"
                  value="visible"
                  name="visibleprofil"
                  checked={
                    userData.user_community_profil === "VISIBLE" ||
                    communityProfile === "VISIBLE"
                  }
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    updateUserDetail("VISIBLE");
                    setCommunityProfile("VISIBLE");
                  }}
                />
                <label className="custom-control-label" htmlFor="visible">
                  Visible
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="semi"
                  value="semi"
                  name="visibleprofil"
                  checked={
                    userData.user_community_profil === "SEMI-VISIBLE" ||
                    communityProfile === "SEMI-VISIBLE"
                  }
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    updateUserDetail("SEMI-VISIBLE");
                    setCommunityProfile("SEMI-VISIBLE");
                  }}
                />
                <label className="custom-control-label" htmlFor="semi">
                  Semi-Visible
                </label>
              </div>
              {/* ROLE AYANT ANONYMOUS => ISV */}
              {userData.roles.indexOf("ROLE_ISV") !== -1 ? (
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id="anonyme"
                    value="anonyme"
                    name="visibleprofil"
                    checked={
                      userData.user_community_profil === "ANONYMOUS" ||
                      communityProfile === "ANONYMOUS"
                    }
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      updateUserDetail("ANONYMOUS");
                      setCommunityProfile("ANONYMOUS");
                    }}
                  />
                  <label className="custom-control-label" htmlFor="anonyme">
                    Anonymous
                  </label>
                </div>
              ) : (
                <></>
              )}
            </div>
          </li>
          {!isHidectivity && (
            <li>
              <h2 className="title">
                Edit the 'About' section on your activity page
                <Link
                  to={{
                    pathname:
                      "/community/activity-user/" +
                      store.getState().userReducer?.id,
                    state: {
                      openModalAboutEdit: true,
                    },
                  }}
                >
                  Edit
                </Link>
              </h2>
              <div className="itemsEditProfil">
                <div className="underh2" style={{ width: "inherit" }}>
                  Tell other community members about yourself.
                </div>
              </div>
            </li>
          )}
        </ul>
        <div className="col-xl-5 col-lg-12 col-12 p-0 ">
          <div className="miniInfoProfil">
            <div className="cttImage">
              <img
                alt=""
                src={
                  communityProfile !== "ANONYMOUS"
                    ? `${fileUrl(userData.file_name)} `
                    : `${fileUrl(
                        "assets/images/users/default_logo/avatar.png"
                      )}`
                }
              />
            </div>
            <div className="info-final-getstarted">
              <div className="nom">
                {renderFirstName(communityProfile, userData.first_name)}{" "}
                {renderLastName(
                  communityProfile,
                  communityProfile !== "ANONYMOUS"
                    ? userData?.last_name
                    : userData?.encoded_username
                )}
              </div>
              <div className="post">
                {communityProfile !== "ANONYMOUS" &&
                communityProfile !== "SEMI-VISIBLE"
                  ? userData.job_title
                  : ""}
                <b>
                  {communityProfile !== "ANONYMOUS" &&
                  communityProfile !== "SEMI-VISIBLE"
                    ? userData.company_name
                    : ""}
                </b>
              </div>
              <div className="membersince">
                {`Member since ${new Date(userData?.created_at).toLocaleString(
                  "en-US",
                  {
                    month: "short",
                  }
                )} ${new Date(userData?.created_at).getDate()}, ${new Date(
                  userData?.created_at
                ).getFullYear()} `}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
