import "../community-categies-filter/OneCategory.scss";
import React from "react";
import Header from "../../../widget/index";
import Select from "react-select";
import UseFavorite from "../../../../controller/community-ctr/favorite/use-favorite";
import { LoadingArticle } from "../../../widget/loader";
import { ItemDiscussion } from "../../../../redux/types/community-interface";
import Pagination from "../../../widget/use-pagination/pagination";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
} from "../../../../utils";
import moment from "moment";
import SearchForm from "../../../widget/community/search/index";
import Footer from "../../../widget/footer/footer";

export default function FavoriteDiscussion() {
  const providerFavorite = UseFavorite();
  return (
    <div>
      <Header />
      <section className="discussion">
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li>
              <a href="javascript:;">Community</a>
            </li>
            <li>
              <a href="javascript:;">Favorite discussions</a>
            </li>
          </ul>
          <div className="searchTopic">
            <SearchForm
              categories={
                providerFavorite?.communityReducer?.discussionCategories
              }
              filterSearchText={providerFavorite?.filterSearchText}
              handleFilterTxt={providerFavorite?.handleFilterTxt}
              searchText={providerFavorite?.inputText}
              handleCateGory={providerFavorite?.handleCateGory}
            />
          </div>
          <div className="rightCommunityForum favorite">
            <div className="containerCategory">
              <h2 className="title">
                Favorite discussions <>({providerFavorite?.totalFavorite})</>
              </h2>

              {providerFavorite?.communityReducer?.favorite_discussions
                ?.discussions?.length > 0 ? (
                <>
                  <div className="titleCategory">
                    <Select
                      className="triOreder favoriteSelect"
                      isClearable={false}
                      options={[
                        {
                          value: "remove",
                          label: "Unsubscribe selected discussions",
                        },
                        { value: "check", label: "Check all" },
                        { value: "uncheck", label: "Uncheck all" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{ value: "0", label: "Selection" }}
                      onChange={(selected) =>
                        providerFavorite?.handleSelection(selected)
                      }
                    />
                    <div className="tri">
                      <div className="labelSelect">
                        <label>Show:</label>
                        <Select
                          className="triNumber"
                          isClearable={false}
                          options={[
                            { value: "10", label: "10" },
                            { value: "20", label: "20" },
                            { value: "30", label: "30" },
                          ]}
                          classNamePrefix="tri"
                          defaultValue={{
                            value: providerFavorite?.limit?.toString(),
                            label: providerFavorite?.limit?.toString(),
                          }}
                          value={{
                            value: providerFavorite?.limit?.toString(),
                            label: providerFavorite?.limit?.toString(),
                          }}
                          onChange={(params: any) =>
                            providerFavorite?.setPaginateDataFilter(
                              params?.value
                            )
                          }
                        />
                      </div>

                      <div className="sortedby labelSelect">
                        <label>Sorted by :</label>
                        <Select
                          className="triOreder"
                          isClearable={false}
                          options={[
                            { value: "post_date", label: "Post date" },
                            {
                              value: "last_activity",
                              label: "Latest activity",
                            },
                            { value: "replies", label: "Replies" },
                            { value: "unread", label: "Unread messages" },
                          ]}
                          classNamePrefix="tri"
                          defaultValue={{
                            value: providerFavorite?.sortedBy,
                            label: "Post date",
                          }}
                          onChange={(params: any) =>
                            providerFavorite?.handleSortedBy(params?.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <ul className="feedList categoryList favoriteList">
                    {!providerFavorite?.communityReducer?.favorite_discussions
                      ?.successDataFetched ? (
                      <LoadingArticle />
                    ) : (
                      <>
                        {providerFavorite?.communityReducer?.favorite_discussions?.discussions?.map(
                          (el: ItemDiscussion, key: number) => (
                            <li>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={"customCheck" + el?.id}
                                  checked={
                                    providerFavorite?.checked?.find(
                                      (element) => el?.id === element
                                    )
                                      ? true
                                      : false
                                  }
                                  value={el?.id}
                                  onClick={() =>
                                    providerFavorite?.handleClick(el?.id)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"customCheck" + el?.id}
                                >
                                  &nbsp;
                                </label>
                              </div>
                              <div className="infoAbout">
                                <div className="withPost">
                                  <div
                                    className={`imageAffiliatedcompany empty`}
                                  >
                                    <img
                                      src={fileUrl(el?.user?.file_name)}
                                      alt={el?.user?.file_name}
                                    />
                                  </div>
                                  <div
                                    className={`nameOfPost ${getUserType(
                                      el?.user?.roles?.[0]
                                    )}`}
                                  />
                                </div>
                                <div className="infoFeed">
                                  <div
                                    className="titlePost"
                                    onClick={() =>
                                      providerFavorite.goToReply(el)
                                    }
                                  >
                                    {el?.title}
                                  </div>
                                  <div
                                    className="postBy"
                                    onClick={() =>
                                      providerFavorite.goActivity(el.user?.id)
                                    }
                                  >
                                    by{" "}
                                    <a href="javascript:;">
                                      {concatString(
                                        el?.user?.first_name,
                                        el?.user?.last_name,
                                        " "
                                      )}
                                    </a>{" "}
                                    on{" "}
                                    {moment(new Date(el?.created_at)).format(
                                      "MMM D, YYYY h:mm a"
                                    )}
                                  </div>
                                  <div
                                    className="postBy"
                                    onClick={() =>
                                      providerFavorite.goActivity(
                                        el.last_activity_by?.id
                                      )
                                    }
                                  >
                                    Latest activity {dateDiff(el?.updated_at)}{" "}
                                    by{" "}
                                    <a href="javascript:;">
                                      {concatString(
                                        el?.last_activity_by?.first_name,
                                        el?.last_activity_by?.last_name,
                                        " "
                                      )}
                                    </a>
                                  </div>
                                  <div className="subCategory">
                                    {el?.labels?.map(
                                      (element: any, index: number) => (
                                        <span key={index}>
                                          {element?.title}
                                        </span>
                                      )
                                    )}
                                    {el?.tags?.map(
                                      (element: any, index: number) => {
                                        <span key={index}>
                                          {element?.name}
                                        </span>;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="replyInfo">
                                <b>{el?.numberOfReplies}</b>Replies
                              </div>
                              <div className="viewInfo">
                                <b>{el?.numberOfUnreadMessages}</b>Unreads
                              </div>
                            </li>
                          )
                        )}
                        <div className="pagginationCategory">
                          <div className="labelSelect">
                            <label>Show:</label>
                            <Select
                              className="triNumber"
                              isClearable={false}
                              options={[
                                { value: "10", label: "10" },
                                { value: "20", label: "20" },
                                { value: "30", label: "30" },
                              ]}
                              classNamePrefix="tri"
                              defaultValue={{
                                value: providerFavorite?.limit?.toString(),
                                label: providerFavorite?.limit?.toString(),
                              }}
                              value={{
                                value: providerFavorite?.limit?.toString(),
                                label: providerFavorite?.limit?.toString(),
                              }}
                              onChange={(params: any) =>
                                providerFavorite?.setPaginateDataFilter(
                                  params?.value
                                )
                              }
                            />
                          </div>
                          <div className="pagination">
                            <Pagination
                              pageSize={providerFavorite?.limit}
                              onPageChange={providerFavorite?.handlePageClick}
                              className="pagination"
                              currentPage={providerFavorite?.currentPage}
                              totalCount={providerFavorite?.totalFavorite}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </ul>
                  <div className="short-height"></div>
                </>
              ) : (
                <div className="short-height" />
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
