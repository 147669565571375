import { fileUrl } from "../../../../../utils";

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  profil_current_user: string;
  companyName_current_user: string;
  roleDiscussion_current: string;
};
export default function ClassificAddsHeaderMessage(props: Iprops) {
  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          {props.profil && <img src={fileUrl(props.profil)} alt="profil" />}
        </div>
        <div className="info-title">
          <div className="company-title">
            <a href="javascript:;" className="disable-click-name">
              {props?.companyName || "-"}
            </a>
          </div>
          <div className="company-type">{props?.roleDiscussion || "-"}</div>
        </div>
      </div>
    </>
  );
}
