import React, { ChangeEvent } from "react";
import Header from "../../../widget/index";
import usePostAlternative from "../../../../controller/alternative-ctr/post-alternative-ctr";
import useHomeAlternative from "../../../../controller/alternative-ctr/alternative-ctr";
import { concatString, fileUrl } from "../../../../utils";
import DefaultUser from "../../../../resources/images/avatar.png";
import DefaultLoGoCompany from "../../../../resources/images/defaultCompany.png";
import FormWysiwyg from "../../../widget/post-wysiwig/index";
import ModalIndustryList from "../../mergers-acquisitions/postMA/ModalIndustryList";
import ModalSolution from "../../mergers-acquisitions/postMA/ModalSolution";
import { PaymentPopup } from "../../payment-popup/payment-popup";

export default function PostAlternativeFinancing() {
  const provider = usePostAlternative();
  const providerHome = useHomeAlternative();

  const categorySelected: any = [
    ...provider.selectedSolution,
    ...provider.selectedFeaturedSolution,
  ];

  const txtBtnCat =
    categorySelected?.length > 0
      ? +categorySelected?.length + " " + "solutions selected"
      : "Select solution category(ies)";

  const txtBtnIndustry =
    provider.selectedIndustry?.length > 0
      ? +provider.selectedIndustry?.length + " " + "industries selected"
      : "Select industry(ies)";

  return (
    <>
      <Header />

      <PaymentPopup
        show={provider.showPaymentModal}
        title={"Post alternative financing"}
        setShowPaymentModal={provider.setShowPaymentModal}
        product={provider.product}
        successCallback={(
          isFree: boolean,
          paymentMethodId: string | null,
          setLoading: any,
          savePaymentMethod: boolean | undefined,
          setShowPaymentStatus: any
        ) =>
          provider.paymentMethodCallBack(
            isFree,
            paymentMethodId,
            setLoading,
            savePaymentMethod,
            setShowPaymentStatus
          )
        }
        free={providerHome.freeNumber}
        isPriceInfo={false}
        handleStatusOk={provider.handleStatusOk}
      />

      <section className="classified mergers details">
        <div className="container mx-auto">
          <div className="leftSection">
            <div className="block">
              <div className="leftItem itemAuthor">
                <div className="imageAffiliatedcompany empty">
                  <img
                    src={
                      provider?.displayAuthorName
                        ? fileUrl(provider.user.file_name)
                        : DefaultUser
                    }
                    alt=""
                  />
                </div>
                <div className="">
                  <div className="post">Author:</div>
                  {provider?.displayAuthorName ? (
                    <>
                      <h3 className="title">
                        {concatString(
                          provider?.user?.first_name,
                          provider?.user?.last_name,
                          " "
                        )}
                      </h3>
                      <div>{provider?.user?.job_title}</div>
                    </>
                  ) : (
                    // <h3 className="title">{provider?.user?.first_name}</h3>
                    <h3 className="title">Anonymous user</h3>
                  )}
                </div>
              </div>
            </div>
            <div className="block infoCompanyAds">
              <div className="imgclassified empty">
                {provider?.isModerator ? (
                  <img src={DefaultLoGoCompany} alt="" />
                ) : (
                  <img
                    src={
                      provider?.displayCompanyName
                        ? fileUrl(provider?.companyPageObject?.photo_profil)
                        : DefaultLoGoCompany
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="infoAds">
                Company:
                <h3 className="title">
                  {provider?.displayCompanyName
                    ? provider?.companyPageObject?.company_name
                    : "Company confidential"}
                </h3>
                <div className="contry">
                  {concatString(
                    provider.companyPageIsv?.country?.country_name,
                    provider.companyPageIsv?.city
                  )}
                </div>
              </div>
            </div>
            <div className="block">
              <div className="slidecheck">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="hideAuthor"
                    className="custom-control-input"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      provider.setDisplayAuthorName(event.target.checked)
                    }
                    checked={provider.displayAuthorName}
                  />
                  <label htmlFor="hideAuthor" className="custom-control-label">
                    Display author name
                  </label>
                </div>
                <div className="custom-checkbox">
                  <input
                    id="hideCompany"
                    type="checkbox"
                    className="custom-control-input disabled"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      provider.setDisplayCompanyName(event.target.checked)
                    }
                    checked={provider.displayCompanyName}
                    disabled={provider?.isModerator ? true : false}
                  />
                  <label htmlFor="hideCompany" className="custom-control-label">
                    Display company name
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="rightSection newAdsPost newAdMergers">
            <h2 className="title">New ad in “Alternative financing”</h2>
            <div className="mt-3 mb-3">
              This section is dedicated to helping software vendors identify
              financing options as an alternative to standard equity investments
              and loans. This is the place to meet partnersthat offer resources
              around debt financing, hybrid financing, IP financing,
              crowdfunding, revenue based financing, P2P lending, etc...
            </div>
            <div className="incognito">
              <span className="icon-incognito"></span> On the left side, you can
              change your visibility settings to appear anonymously.
            </div>
            <div className="pb-4 selectSol mt-4">
              {provider?.isErrorSolution && (
                <div className={`container-error pl-2  d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    solution category(ies) is required
                  </span>
                </div>
              )}
              <div
                className="d-flex mb-1 w-100 mb-3 align-items-center"
                onClick={() => provider.setShowSolution(true)}
              >
                <div className="btn btn-devinsider">{txtBtnCat}</div>
                <div className="listOf more">
                  {" "}
                  <div className="topicAds">
                    {[
                      ...provider.selectedSolution,
                      ...provider.selectedFeaturedSolution,
                    ]?.map((el) => (
                      <span className="topic">{el.name}</span>
                    ))}
                  </div>
                </div>
              </div>
              {provider?.isErrorIndustry && (
                <div className={`container-error pl-2  d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">industry(ies) is required</span>
                </div>
              )}
              <div
                className="d-flex mb-1 w-100 align-items-center"
                onClick={() => provider.setShowIndustry(true)}
              >
                <div className="btn btn-devinsider">{txtBtnIndustry}</div>
                <div className="listOf more">
                  <div className="topicAds">
                    {provider.selectedIndustry?.map((el, index) => {
                      return (
                        index < 3 && <span className="topic">{el.name}</span>
                      );
                    })}
                    {provider.selectedIndustry?.length > 3 && (
                      <a
                        href="javascript:;"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="small color-orange font-weight-600">
                          ...See more
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group required col-12 pl-0 pr-0">
              <select
                className="custom-select"
                onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                  provider?.setSelectFunding(evt.target.value);
                }}
                value={provider?.selectFunding || "{}"}
              >
                <option value={"{}"}>Select funding target...(optional)</option>
                {provider?.dataCompleted?.funding_target?.map(
                  (el: any, index: number) => (
                    <option value={JSON.stringify(el)} key={JSON.stringify(el)}>
                      {Object.keys(el)}
                    </option>
                  )
                )}
              </select>
            </div>

            {provider?.isErrorObject && (
              <div className={`container-error pl-2  d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">Object is required</span>
              </div>
            )}
            <FormWysiwyg
              mention={provider?.mentions}
              updateMention={() => null}
              user={provider?.user}
              editorState={provider?.editorState}
              cancelPost={provider?.cancelPost}
              loader={provider?.loader}
              setEditorState={provider?.setEditorState}
              sendPost={() => provider.saveAlternative()}
              object={provider?.title}
              setObject={provider?.setTitle}
              minify={true}
              showPreview={() => null}
              isShowPreview="not-showing"
              confirmButtonTitle={
                providerHome?.freeNumber > 0 || provider.isEdit
                  ? "Publish"
                  : "Pay and publish"
              }
              options={["inline", "list", "history", "remove"]}
            />

            <ModalIndustryList
              cancel={() => provider.cancelTargeted()}
              loader={false}
              show={provider.showIndustry}
              valid={() => provider.validTargeted()}
              addTargetCompany={provider.addTargetCompany}
              companyTarget={provider.companyTarget}
              companyTargetSecound={provider.companyTargetSecound}
              companyTargetSelected={provider.companyTargetSelected}
              lastCompanyTarget={provider.lastCompanyTarget}
              otherValue={provider.otherValueIndustry}
              setOtherValueTxt={provider.setOtherValueTxtIndustry}
            />
            <ModalSolution
              solutionLeftColumnData={provider.solutionLeftColumnData}
              solutionRigthColumnData={provider.solutionRigthColumnData}
              featuredSolution={provider.featuredSolution}
              solutionChecked={provider.solutionChecked}
              solutionCheckedFeatures={provider.solutionCheckedFeatures}
              addSolutionCheched={provider.addSolutionCheched}
              addSolutionChechedFeatured={provider.addSolutionChechedFeatured}
              show={provider.showSolution}
              cancel={() => provider.cancelSolution()}
              valid={() => provider.validSolution()}
              otherValue={provider.otherValue}
              setOtherValueTxt={provider.setOtherValueTxt}
            />
          </div>
        </div>
      </section>
    </>
  );
}
