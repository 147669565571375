import {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import { IAction } from '../../../../redux/types/acount-interface';

import {
  patchDataService,
  postJsonDataService,
} from '../../../../service/applicatif/back-end-service';
import { ISV, url } from '../../../../service/constant/index';
import { rootState } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { IProgramType } from '../../../../interface/program-type';
import useEventListener from '../../../../hooks/event-handler';
import useFilterEvent from './use-filter-control-ctr';
import { useHistory } from 'react-router-dom';
import {asyncPostData} from "../../../../service/api";

const GET_POST_INFO = 'GET_POST_INFO';
const ADD_FAVORITE = 'ADD_FAVORITE';

type IPropsInitialState = {
  oemPrograms: Array<IProgramType>;
  currentPage: number;
  totalDataPaginate: number;
};
const initialState: IPropsInitialState = {
  oemPrograms: [],
  totalDataPaginate: 0,
  currentPage: 0,
};

const helperAddFavorie = (state: Array<any>, id: number) => {
  return state?.map((el: any) => {
    if (el?.oem?.id?.toString() === id?.toString()) {
      return {
        ...el,
        is_favorite: !el?.is_favorite,
      };
    } else return el;
  });
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_POST_INFO:
      return {
        ...state,
        oemPrograms: action.payload?.oemPrograms,
        currentPage: parseInt(action?.payload?.currentPage),
        totalDataPaginate: parseInt(action.payload?.totalDataPaginate),
      };
    case ADD_FAVORITE:
      return {
        ...state,
        oemPrograms: helperAddFavorie(state?.oemPrograms, action.payload),
      };
    default:
      return state;
  }
};

export default function UseMatchingPanel() {
  const {
    handleFilterType,
    handleFilterCountry,
    handleFilterProgramSolution,
    resetFilterEvents,
    sliderValue,
    segmentsDetails,
    countries,
    programType,
    setSliderValue,
  } = useFilterEvent();

  const [allProgram, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);

  const userObject = useSelector((state: rootState) => state?.userReducer);

  const [sortBy, setSortBy] = useState<'last_update' | 'relevance'>(
    'relevance'
  );

  const history = useHistory();

  const [orderBy, setOrderBy] = useState('DESC');
  const [search_name, setSearch_name] = useState('');
  const [page, setPage] = useState<number>(1);
  const [limit, setlimit] = useState<number>(20);
  const [loaderMatching, setloaderMatching] = useState<boolean>(false);
  const isMounted = useRef(true);

  const filtered = [
    {
      value: 'relevance',
      label: 'Relevance',
    },
    {
      value: 'last_update ',
      label: 'Last Update',
    },
  ];

  let defaultParams = {
    programType: programType?.join(),
    minimumMatch: sliderValue[0],
    maximumMatch: sliderValue[1],
    countries: countries?.join(),
    segmentsDetails: segmentsDetails?.join(),
    sortBy,
    orderBy,
    search_name,
    page: page,
  };

  const getAllProgram = (params: any, dispatch: any, recursion = false) => {
    if (!recursion)
      setloaderMatching(true);

    asyncPostData(
      url?.programs?.get_matching_isv_oem,
      params,
      userObject?.token
    )
        .then((response: { status: number; data: {progress: any; results: any; total_to_paginate: any; }; }) => {
          if (isMounted.current) {
            if (response?.status === 200) {
              const inProgress = response?.data?.results === "IN_PROGRESS";
              const result = {
                oemPrograms: inProgress ?
                    ("Matching calculation progress: " +  response?.data?.progress + "%") :
                    response?.data?.results,
                totalDataPaginate: response?.data?.total_to_paginate,
                currentPage: params?.page,
              };
              dispatch({type: GET_POST_INFO, payload: result});
              if (response?.data?.results === "IN_PROGRESS") {
                getAllProgram(params, dispatch, true)
              }
            }
          }
        })
        .finally(() => {
          if (!recursion)
            setloaderMatching(false)
        })
  };

  const loadMoreProgram = (selectedPage: number) => {
    setPage(page);
    let paramsChange = {
      ...defaultParams,
      page: selectedPage,
    };
    getAllProgram(paramsChange, dispatch);
  };

  const handleFilter = (params: any) => {
    setSortBy(params?.value);
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      sortBy: params?.value,
      page: 1,
    };
    getAllProgram(paramsChange, dispatch);
  };

  useEffect(() => {
    getAllProgram(defaultParams, dispatch);

    return () => {
      isMounted.current = false;
    }
  }, []);

  const lunchFilter = () => {
    setPage(1);
    let paramsChange = {
      ...defaultParams,
      page: 1,
    };
    getAllProgram(paramsChange, dispatch);
  };

  const resetFilter = () => {
    resetFilterEvents();

    if (sortBy !== 'relevance') setSortBy('relevance');
    if (page !== 1) setPage(1);
    if (search_name !== '') setSearch_name('');

    let paramsChange = {
      ...defaultParams,
      page: 1,
      countries: '',
      segmentsDetails: '',
      programType: '',
      sortBy: 'relevance',
      minimumMatch: 60,
      maximumMatch: 100,
      search_name: '',
    };
    getAllProgram(paramsChange, dispatch);
  };

  const handleSearche = () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      search_name,
      page: 1,
    };
    getAllProgram(paramsChange, dispatch);
  };

  const keyEnter = ({ key }: any) => {
    if (key == 'Enter') {
      if (page !== 1) setPage(1);
      let paramsChange = {
        ...defaultParams,
        page: 1,
      };
      getAllProgram(paramsChange, dispatch);
    } else return;
  };

  useEventListener('keydown', keyEnter);

  const addFavorite = useCallback(async (id: number) => {
    let result: any = await patchDataService(
      url?.programs?.mark_as_favorite + id,
      {},
      userObject?.token
    );
    if (result?.status === 200) {
      dispatch({ type: ADD_FAVORITE, payload: id });
    }
  }, []);

  const showProgramPage = useCallback((oemProgramId: number) => {
    history.push('/OEM/profil/' + oemProgramId);
  }, []);

  const isIsv = userObject?.roles?.indexOf(ISV) !== -1;

  return {
    allProgram,
    loadMoreProgram,
    limit,
    loaderMatching,
    filtered,
    handleFilter,
    handleFilterType,
    programType,
    handleFilterCountry,
    countries,
    handleFilterProgramSolution,
    segmentsDetails,
    setSearch_name,
    search_name,
    lunchFilter,
    resetFilter,
    sliderValue,
    setSliderValue,
    handleSearche,
    addFavorite,
    showProgramPage,
    isIsv,
  };
}
