import { ICommunity, IAction } from '../../types/community-interface';
export const initialState: ICommunity = {
  featuredLabel: [],
  discussionCategories: [],
  category_about_community: [],
  communityAuthors: {
    user_followed: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },

  postType: [],
  latestPost: [],
  topLabel: {
    labels: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  topTags: {
    labels: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  paginate_press_release: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  topOrganizations: {
    labels: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  topLabelArticle: {
    labels: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  paginate_discussions: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  paginate_article: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  paginate_program_review: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  paginate_about_community: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
  },
  detailsDiscutionCategorie: [],
  detailsArticle: [],
  successFetchPaginate: false,
  curentDiscutionReplyPost: {
    currentPost: null,
    allReplies: [],
    currentPage: 0,
    total: 0,
    totaldataFetch: 0,
  },
  on_going_discussion: {
    id: 0,
    title: '',
    liked: false,
    numberOfLikes: 0,
    numberOfReplies: 0,
    numberOfUnreadMessages: 0,
    description: '',
    created_at: '',
    solved: false,
    updated_at: '',
    isFavorited: false,
    isPinned: false,
    isLocked: false,
    isDeleted: false,
    deleted_by: null,
    user: {
      id: 0,
      email: '',
      roles: [],
      last_name: '',
      first_name: '',
      file_name: '',
      job_title: '',
      isvInCompanyPage: {
        role: '',
        user: '',
        company_page: {
          id: '',
          company_name: '',
          company_legal_name: '',
          photo_profil: '',
          photo_couverture: '',
          description: '',
          company_summary: '',
        },
      },
      oemProgramMembers: [
        {
          email: '',
          role: '',
          user: '',
          oem_program: {
            id: '',
            company_name: '',
            photo_profil: '',
            photo_couverture: '',
            program_name: '',
          }
        
        }
      ]
    },
    category: {
      id: 0,
      title: '',
      slug: '',
      icon: '',
      about: false,
    },
    labels: [],
    tags: [],
    post_type: {
      id: 0,
      type: '',
    },
    // replies: [],
    last_activity_by: {
      id: '',
      first_name: '',
      last_name: '',
      isvInCompanyPage: {
        role: '',
        user: '',
        company_page: {
          id: '',
          company_name: '',
          company_legal_name: '',
          photo_profil: '',
          photo_couverture: '',
          description: '',
          company_summary: '',
        },
      },
    },
    numberOfViews: 0,
    author: {
      id: 0,
      lastName: '',
      firstName: '',
      fileName: '',
      jobTitle: '',
      companyName: ''
    },
  },
  favorite_discussions: {
    discussions: [],
    total: 0,
    currentPage: 0,
    totaldataFetch: 0,
    successDataFetched: false,
  },
};
