import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  createCompanyPageAction,
  updateCompanyPageAction,
} from "../../../redux/actions/company-page-isv-action/current_isv_action";
import { getInfoUserAction } from "../../../redux/actions/acounts-action/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  checkRequest_Service,
  searchCompanyPage,
} from "../../../service/applicatif/company-page-isv";
import { rootState } from "../../../redux/reducers";
import * as T from "../types/types-company-page";
import { refaCtoNullKey } from "../../../utils";
import { getCompletedAcccount } from "../../../service/applicatif/acount";
var _ = require("lodash");

export default function UseCompanyCtr() {
  const dispatch = useDispatch();
  const history = useHistory();

  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const userObject = useSelector((state: rootState) => state.userReducer);

  /** Get company page matching the company name of the user */
  const [proposalCompanies, setProposalCompanies] = useState([]);

  const isNameIncludes = (value1: string, value2: string) => {
    if (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      value2?.trim() == "Analytics Software" &&
      value1?.trim() == "Sales Analytics Software"
    ) {
      return false;
    }
    return (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      (value1?.toLowerCase().includes(value2?.toLowerCase()) ||
        value2?.toLowerCase().includes(value1?.toLowerCase()))
    );
  };

  useEffect(() => {
    searchCompanyPage(userObject?.company_name, userObject?.token)
      .then((response: any) => {
        setProposalCompanies(response.data);
      })
      .catch((error: any) => () => {});
  }, []);

  // useEffect(() => {
  //   checkRequest_Service( userObject?.token)
  //     .then((response: any) => {
  //       setNumberRequest(response.data);
  //     })
  //     .catch((error: any) => () => {});
  // }, []);

  const checkNumberRequest = async (elementId: any) => {
    let nombre = 0;
    let data = {
      entityId: elementId,
    };
    let response = await checkRequest_Service(data, userObject?.token);
    if (response?.status === 200) {
      nombre = response?.data?.number;
    }
    return nombre;
  };

  const [nextPage, setnextPage] = useState<number>(1);
  const [allResellerSolution, setAllResellerSolution] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [allOtherValue, setAllOtherValue] = useState<
    Array<{
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
      otherTxt: string;
    }>
  >([]);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<T.IdataMakeSolution>
  >([]);

  const [solutionCheckedValue, setSolutionCheckedValue] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const [allSolutionSellected, setAllSolutionSellected] = useState<
    Array<string>
  >([]);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );

  const [companyTarget, setcompanyTarget] = useState<Array<T.IListTarget>>([]);

  const [companyTargetSecound, setcompanyTargetSecond] = useState<
    Array<T.IListTarget>
  >([]);
  const [lastCompanyTarget, setLastcompanyTargetSecond] = useState<
    Array<T.IListTarget>
  >([]);
  const [companyTargetSelected, setCompanyTargetSelected] = useState<
    Array<T.IListTarget>
  >([]);

  const [isHaveErrorTargetCompany, setIsHaveErrorTargetCompany] =
    useState<boolean>(false);

  const [allContinents, setallContinents] = useState<Array<T.IobJectResponse>>(
    []
  );
  const [paysChecked, setPaysChecked] = useState<Array<object>>([]);
  const [allContinentSelected, setAllContinentSelected] = useState<
    Array<string>
  >([]);
  const [selectedOffice, setSelectedOffice] = useState<string>(
    companyPageObject.type_of_office || ""
  );
  const [isErrorCountrySelected, setIsErrorCountrySelected] =
    useState<boolean>(false);
  const [isShowErrorOffice, setIsShowErrorOffice] = useState<boolean>(false);
  const [isShowOfficeData, setIsShowOfficeData] = useState<boolean>(false);
  const [featuredSolution, setFeaturedSolution] = useState<T.ISolutionListe>();
  const [isHaveError, setIsHaveError] = useState<boolean>(false);
  const [showStep1, setShowStep1] = useState(false);
  const [showStep2, setShowStep2] = useState(false);
  const [licenCingModelSelected, setLicenCingModelSelected] = useState<
    Array<number>
  >(
    companyPageObject?.licensing_model?.map(
      (el: { id: number; name: string }) => el.id
    ) || []
  );
  const [distributedChanelSelected, setSelectedDistributedChanel] = useState<
    Array<number>
  >([]);
  const [isErrorLicencingModel, setIsErrorLicencingModel] =
    useState<boolean>(false);
  const [isErrorDistribuTionChanel, setIsErrorDistribuTionChanel] =
    useState<boolean>(false);

  const [partenerShipSelected, setPartenerShipSelected] = useState<Array<any>>(
    []
  );

  const [currentSelected, setCurrentSelected] = useState<{
    value: number;
    label: string;
  }>();

  const [currentSelectedList, setCurrentSelectedList] = useState<
    Array<{ value: number; label: string }>
  >([]);

  const [technologiesPartnership, setTechnologiesPartnership] = useState<
    Array<{ value: number; label: string }>
  >(
    dataCompleted?.partners?.map((el: { id: number; name: string }) => ({
      value: el.id,
      label: el.name,
    })) || []
  );

  const [currentPageModal, setCurrentPageModal] = useState<number>(1);

  const addListOption = (data: any) => {
    if (currentSelected) {
      let currentOptionList = technologiesPartnership.filter(
        (el: { value: number; label: string }) => el.value !== data.value
      );
      setCurrentSelectedList([...currentSelectedList, currentSelected]);
      setTechnologiesPartnership(currentOptionList);
      setCurrentSelected({ label: "", value: 0 });
    }
  };

  const removeSelected = (id: number) => {
    let data = currentSelectedList?.find(
      (el: { value: number; label: string }) => el.value === id
    );
    let tempValue = currentSelectedList?.filter(
      (el: { value: number; label: string }) => el.value !== id
    );
    setCurrentSelectedList(tempValue);
    if (data) {
      setTechnologiesPartnership([data, ...technologiesPartnership]);
    }
  };

  const [isShowErrorPartenerShip, setIsShowErrorPartenerShip] =
    useState<boolean>(false);

  const [isShowErrorTechnologyPartener, setIsShowErrorTechnologyPartener] =
    useState<boolean>(false);

  const [checkedSoftware, setCheckedSoftware] = useState<string>("false");
  const [fundingRoundValue, setFundingRound] = useState<string>(
    companyPageObject?.funding_round?.name || ""
  );
  const [amountWouldLike, setAmountWouldLike] = useState<any>("{}");
  const [isShowErrorFoundindRound, setIsShowErrorFoundindRound] =
    useState<boolean>(false);
  const [isShowAMountWouldLike, setIsShowAMountWouldLike] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const handleCloseStep1 = () => {
    setisShowModalConfirm(true);
    setShowStep1(false);
    setCurrentPageModal(1);
  };
  const [isShowModalConfirm, setisShowModalConfirm] = useState<boolean>(false);
  //Show screen 1
  const handleShowStep1 = () => {
    setShowStep1(true);
  };

  const handleCloseStep2 = () => {
    setisShowModalConfirm(true);
    setShowStep2(false);
    setCurrentPageModal(2);
  };

  //show screen 2
  const handleShowStep2 = () => {
    setShowStep2(true);
    setShowStep1(false);
  };

  const handleBackStep1 = () => {
    setShowStep2(false);
    setShowStep1(true);
    setnextPage(1);
  };

  const [showStep3, setShowStep3] = useState(false);
  const handleCloseStep3 = () => {
    setisShowModalConfirm(true);
    setShowStep3(false);
    setCurrentPageModal(3);
  };

  //Show screen 3
  const handleShowStep3 = () => {
    setShowStep3(true);
    setShowStep2(false);
  };
  const handleBackStep2 = () => {
    setShowStep3(false);
    setShowStep2(true);
    setnextPage(2);
  };

  const [showStep4, setShowStep4] = useState(false);
  const handleCloseStep4 = () => {
    setisShowModalConfirm(true);
    setShowStep4(false);
    setCurrentPageModal(4);
  };

  //Show screen 4
  const handleShowStep4 = () => {
    setShowStep4(true);
    setShowStep3(false);
  };
  const handleBackStep3 = () => {
    setShowStep4(false);
    setShowStep3(true);
    setnextPage(3);
  };

  const [showStep5, setShowStep5] = useState(false);
  const handleCloseStep5 = () => {
    setisShowModalConfirm(true);
    setShowStep5(false);
    setCurrentPageModal(5);
  };

  //Show screen 5
  const handleShowStep5 = () => {
    setShowStep5(true);
    setShowStep4(false);
  };
  const handleBackStep4 = () => {
    setShowStep5(false);
    setShowStep4(true);
    setnextPage(4);
  };

  const [showStep6, setShowStep6] = useState(false);
  const handleCloseStep6 = () => {
    setisShowModalConfirm(true);
    setShowStep6(false);
    setCurrentPageModal(6);
  };

  //Show screen 6
  const handleShowStep6 = () => {
    setShowStep6(true);
    setShowStep5(false);
  };
  const handleBackStep5 = () => {
    setShowStep6(false);
    setShowStep5(true);
    setnextPage(5);
  };

  const [showStep7, setShowStep7] = useState(false);

  const handleCloseStep7 = () => {
    setisShowModalConfirm(true);
    setShowStep7(false);
    setCurrentPageModal(7);
  };

  //Show scren 7
  const handleShowStep7 = () => {
    setShowStep7(true);
    setShowStep6(false);
  };
  const handleBackStep6 = () => {
    setShowStep7(false);
    setShowStep6(true);
    setnextPage(6);
  };

  const [showStep8, setShowStep8] = useState(false);
  const handleCloseStep8 = () => {
    setisShowModalConfirm(true);
    setShowStep8(false);
    setCurrentPageModal(8);
  };

  //Show screen8
  const handleShowStep8 = () => {
    setShowStep8(true);
    setShowStep7(false);
  };
  const handleBackStep7 = () => {
    setShowStep8(false);
    setShowStep7(true);
    setnextPage(7);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<T.formCompanyInfo>({
    mode: "onBlur",
    defaultValues: {
      companyLegalName: companyPageObject?.company_name || "",
      companyTradeName: userObject?.isvInCompanyPage
        ? companyPageObject.company_legal_name
        : userObject.company_name,
      webSite: refaCtoNullKey(userObject?.website),
    },
  });

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    watch: watchForm2,
    setValue: setValue2,
    getValues: getValuesForm2,
    formState: { errors: errorsForm2 },
  } = useForm<T.formCompanyAdresse>({
    mode: "onSubmit",
    defaultValues: {
      hQLocation: companyPageObject?.country?.id
        ? companyPageObject?.country?.id.toString()
        : "",
      city: companyPageObject?.city || "",
      annualTurnover:
        JSON.stringify({
          [companyPageObject?.annual_turnover]: {
            min: companyPageObject?.annual_turnover_min,
            max: companyPageObject?.annual_turnover_max,
          },
        }) || "",
      foundingDate: companyPageObject?.founding_date || "",
      companySize:
        JSON.stringify({
          [companyPageObject?.company_size]: {
            min: companyPageObject?.company_size_min,
            max: companyPageObject?.company_size_max,
          },
        }) || "",
    },
  });
  const headquartersLocation = watchForm2("hQLocation");

  useEffect(() => {
    async function getSolution() {
      let dataSolutionOffert = dataCompleted?.segments;
      let featured = dataSolutionOffert?.find((el: any) => el.id == 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataSolutionOffert?.filter((el: any) => el.id < 9999)
      );
    }
    getSolution();
    return () => {};
  }, []);

  useEffect(() => {
    let lengthSolution = allResellerSolution?.length;
    if (lengthSolution % 2 == 0) {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, lengthSolution / 2)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(
          (lengthSolution - 1) / 2 + 1,
          lengthSolution + 1
        )
      );
    }
    return () => {};
  }, [allResellerSolution]);

  const transFormnData = (data: any) => {
    const removeOther = data?.solutionOffert?.filter(
      (el: any) => el.name?.toLocaleLowerCase() !== "other"
    );
    let custom = removeOther?.map((el: any) => {
      let temp = {
        idSolutionOffert: data.idSolutionOffert,
        idSegment: el && el.id,
        nameSegment: el && el.name,
      };
      return temp;
    });
    return custom;
  };

  const selectSolution = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<T.IdataMakeSolution> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple select All
    if (data && data.solutionOffert) {
      //find if bouton is selected all is clicked
      let solutionSellectedAll = allSolutionSellected?.find(
        (el) => el == data.idSolutionOffert
      );
      //tranform data if array
      let dataAfterTransform = transFormnData(data);
      dataMake = [...dataAfterTransform];
      //if selected all is not clicked
      if (!solutionSellectedAll) {
        //remove if item is existe deja
        let dataToStore =
          solutionChecked &&
          solutionChecked.filter(
            (el: any) => el.idSolutionOffert != data.idSolutionOffert
          );

        dataMake?.map((el: any) => {
          dataMake = setRightLeftFeaturedSolutions(dataMake, el);
        });

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setSolutionChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllSolutionSellected([
          ...allSolutionSellected,
          data.idSolutionOffert,
        ]);
      } else {
        let tempSolutions = [...solutionChecked];
        data?.solutionOffert?.map((el: any) => {
          tempSolutions = tempSolutions?.filter(
            (elem: any) =>
              el.id != elem.idSegment &&
              el.name != elem.nameSegment &&
              !isNameIncludes(elem.nameSegment, el.name)
          );
        });

        setSolutionChecked(tempSolutions);

        //Make selected all is not clicked
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != data.idSolutionOffert)
        );
      }
    } else {
      dataMake = [data];
      let found = solutionChecked?.find(
        (el: any) => el.idSegment == data.idSegment
      );

      if (!found) {
        dataMake = setRightLeftFeaturedSolutions(dataMake, data);
        setSolutionChecked([...solutionChecked, ...dataMake]);
      } else {
        //deselection item
        const removeOtherTxt = allOtherValue?.filter(
          (tempRemove) =>
            tempRemove.idSegment.toString() !== data.idSegment?.toString()
        );

        setAllOtherValue(removeOtherTxt);

        let tempSolutions = solutionChecked?.filter(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            !isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        setSolutionChecked(tempSolutions);

        let found2 = solutionChecked?.find(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        if (found2) {
          setAllSolutionSellected(
            allSolutionSellected.filter(
              (el) =>
                el != data.idSolutionOffert &&
                el != found2?.idSolutionOffert.toString()
            )
          );
        } else {
          setAllSolutionSellected(
            allSolutionSellected.filter((el) => el != data.idSolutionOffert)
          );
        }
      }
    }
  };

  const removeItemSelected = (solution: any) => {
    let found = solutionChecked?.find(
      (el: T.IdataMakeSolution) => el.idSegment == solution.idSegment
    );
    if (found) {
      let temp: Array<T.IdataMakeSolution> = solutionChecked?.filter(
        (t: T.IdataMakeSolution) => {
          return (
            t.idSegment !== solution.idSegment &&
            !isNameIncludes(t.nameSegment, solution.nameSegment)
          );
        }
      );
      setSolutionChecked(temp);
      if (solution.idSolutionOffert) {
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != solution.idSolutionOffert)
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    let value = solutionChecked?.map((el: any) => ({
      id: el.idSegment,
      name: el.nameSegment,
    }));
    setSolutionCheckedValue(value || []);
    return () => {};
  }, [solutionChecked]);

  // Dev #48487, [ISV] Company page - bug sur la séléction des "software solutions" qui sont affichées plusieurs fois dans la liste
  const setRightLeftFeaturedSolutions = (
    dataMake: any,
    data: T.IdataMakeSolution
  ) => {
    //featured solutions
    solutionLeftColumnData?.forEach((el: T.ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: T.ItemsSolution) => {
        //check if already exists in solutionschecked
        let checkIfExists = solutionChecked?.find(
          (solution: any) => segmentDetails?.id === solution?.idSegment
        );
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment &&
          !checkIfExists
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    solutionRigthColumnData?.forEach((el: T.ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: T.ItemsSolution) => {
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    featuredSolution?.segmentDetails?.forEach((el: T.ItemsSolution) => {
      if (
        isNameIncludes(el.name, data?.nameSegment) &&
        el.id !== data.idSegment
      ) {
        dataMake = [
          ...dataMake,
          {
            idSegment: el.id,
            idSolutionOffert: featuredSolution.id,
            nameSegment: el.name,
          },
        ];
      }
    });
    return dataMake;
  };

  useEffect(() => {
    async function getTargetCompany() {
      let dataTargetCompany = dataCompleted?.targetedIndustry?.filter(
        (el: any) => el.id <= 27
      );
      let divider = Math.floor(dataTargetCompany?.length / 3);
      let firstColumn = dataTargetCompany?.slice(0, divider);
      let secondColumn = dataTargetCompany?.slice(divider, divider * 2);
      let lastComumn = dataTargetCompany?.slice(divider * 2);
      setcompanyTarget(firstColumn);
      setcompanyTargetSecond(secondColumn);
      setLastcompanyTargetSecond(lastComumn);
    }
    getTargetCompany();
    return () => {};
  }, []);

  const addTargetCompany = (dataMake: T.IListTarget) => {
    let found = companyTargetSelected?.find(
      (el: T.IListTarget) => el.id == dataMake.id
    );
    if (!found) {
      setCompanyTargetSelected([...companyTargetSelected, dataMake]);
    } else {
      setCompanyTargetSelected(
        companyTargetSelected?.filter(
          (elem: T.IListTarget) => elem.id != dataMake.id
        )
      );
    }
  };

  useEffect(() => {
    setIsHaveErrorTargetCompany(false);
    return () => {};
  }, [companyTargetSelected]);

  useEffect(() => {
    setIsHaveError(false);
    return () => {};
  }, [solutionChecked]);

  const addLicencingChecked = (item: number) => {
    let found = licenCingModelSelected?.find((el: number) => el == item);
    if (!found) {
      setLicenCingModelSelected([...licenCingModelSelected, item]);
    } else {
      setLicenCingModelSelected(
        licenCingModelSelected?.filter((elem: number) => elem != item)
      );
    }
  };

  const addDistributionChecked = (item: number) => {
    let found = distributedChanelSelected?.find((el: number) => el == item);
    if (!found) {
      setSelectedDistributedChanel([...distributedChanelSelected, item]);
    } else {
      setSelectedDistributedChanel(
        distributedChanelSelected?.filter((elem: number) => elem != item)
      );
    }
  };

  const transFormnDataCountry = (data: any) => {
    let custom = data.countryesData.map((el: any) => {
      let temp = {
        idContinent: data.idContinent,
        countrId: el && el.id,
        countryName: el && el.country_name,
      };
      return temp;
    });
    return custom;
  };

  const addPaysInArray = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<T.IdataMake> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple
    if (data && data.countryesData) {
      //find if bouton is selected all is clicked
      let cotinentItemIsSelected = allContinentSelected?.find(
        (el) => el == data.idContinent
      );
      //tranform data if array
      let dataAfterTransform = transFormnDataCountry(data);
      dataMake = [...dataAfterTransform];

      //if selected all is not clicked
      if (!cotinentItemIsSelected) {
        //remove if item is existe deja
        let dataToStore = paysChecked?.filter(
          (el: any) => el.idContinent != data.idContinent
        );

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setPaysChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllContinentSelected([...allContinentSelected, data.idContinent]);
      } else {
        //if all selected is clicked , remove all item same idContinent
        setPaysChecked(
          paysChecked?.filter((el: any) => el.idContinent != data.idContinent)
        );
        //Make selected all is not clicked
        setAllContinentSelected(
          allContinentSelected?.filter((el) => el != data.idContinent)
        );
      }
    } else {
      dataMake = [data];
      let found = paysChecked?.find((el: any) => el.countrId == data.countrId);
      if (!found) {
        setPaysChecked([...paysChecked, ...dataMake]);
      } else {
        setPaysChecked(
          paysChecked?.filter((elem: any) => elem.countrId != data.countrId)
        );
      }
    }
  };

  const addOffice = (dataMake: string) => {
    setSelectedOffice(dataMake);
  };

  const addParterShip = (params: any) => {
    // partenerShipSelected, setPartenerShipSelected;
    let found = partenerShipSelected?.find(
      (el: any) => el.idSegment == params.idSegment && el.name == params.name
    );

    if (!found) {
      setPartenerShipSelected([...partenerShipSelected, params]);
    } else {
      setPartenerShipSelected(
        partenerShipSelected?.filter(
          (elem: any) => elem.idSegment != params.idSegment
        )
      );
    }
  };

  useEffect(() => {
    async function getContinent() {
      setallContinents(dataCompleted?.targetedCountry);
    }
    getContinent();
    return () => {};
  }, []);

  useEffect(() => {
    setIsErrorCountrySelected(false);
    const isShow =
      paysChecked?.find((el: any) => el.idContinent == "5") &&
      headquartersLocation?.toString() !== "177";
    if (isShow) {
      setIsShowOfficeData(true);
      setSelectedOffice(companyPageObject?.type_of_office || "");
    } else {
      setIsShowOfficeData(false);
      setSelectedOffice("");
    }
    return () => {};
  }, [paysChecked]);

  useEffect(() => {
    const isInUsa = headquartersLocation?.toString() === "177";
    if (isInUsa && showStep6) {
      setIsShowOfficeData(false);
    }
    return () => {};
  }, [showStep6]);

  useEffect(() => {
    setIsShowErrorOffice(false);
    return () => {};
  }, [selectedOffice]);

  const submit = (data: T.formCompanyInfo) => {
    setnextPage(2);
    handleShowStep2();
  };

  const submitForm2 = (data: T.formCompanyAdresse) => {
    setnextPage(3);
    handleShowStep3();
  };

  const submitForm3 = () => {
    if (solutionChecked && solutionChecked.length > 0) {
      setnextPage(4);
      handleShowStep4();
    } else {
      setIsHaveError(true);
    }
  };

  const setOtherValueTxt = (
    data: {
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
    },
    otherTxt: string
  ) => {
    const foundIfAlreadyExist = allOtherValue.find(
      (el) => el.idSegment?.toString() === data.idSegment?.toString()
    );

    let tempTxt = [];

    if (foundIfAlreadyExist) {
      tempTxt = allOtherValue.map((el) => {
        if (el.idSegment?.toString() === data.idSegment.toString()) {
          return {
            ...el,
            otherTxt,
          };
        } else {
          return el;
        }
      });
    } else {
      tempTxt = [...allOtherValue, { ...data, otherTxt }];
    }
    setAllOtherValue(tempTxt);
  };

  const submitForm4 = () => {
    if (companyTargetSelected && companyTargetSelected.length > 0) {
      setnextPage(5);
      handleShowStep5();
    } else {
      setIsHaveErrorTargetCompany(true);
    }
  };

  const submitForm5 = (): void => {
    if (licenCingModelSelected.length <= 0) {
      setIsErrorLicencingModel(true);
      return;
    } else if (distributedChanelSelected.length <= 0) {
      setIsErrorDistribuTionChanel(true);
      return;
    } else {
      setnextPage(6);
      handleShowStep6();
    }
  };

  const validateForm6 = (): boolean => {
    if (paysChecked && paysChecked.length > 0) {
      return true;
    } else {
      setIsErrorCountrySelected(true);
      return false;
    }
  };

  const validateIfOfficeSelected = (): boolean => {
    if (isShowOfficeData) {
      if (selectedOffice) {
        return true;
      } else {
        setIsShowErrorOffice(true);
        return false;
      }
    } else {
      return true;
    }
  };

  const submitForm6 = (skip: boolean = false) => {
    if (!skip) {
      if (validateForm6() && validateIfOfficeSelected()) {
        setnextPage(7);
        handleShowStep7();
      }
    } else {
      setnextPage(7);
      handleShowStep7();
    }
  };

  const validPartenerShip = () => {
    if (partenerShipSelected && partenerShipSelected.length > 0) {
      return true;
    } else {
      setIsShowErrorPartenerShip(true);
      return false;
    }
  };

  const validTechonologyPartener = () => {
    if (
      checkedSoftware &&
      checkedSoftware !== null &&
      checkedSoftware !== "" &&
      checkedSoftware !== undefined &&
      checkedSoftware !== "null"
    ) {
      return true;
    } else {
      setIsShowErrorTechnologyPartener(true);
      return false;
    }
  };

  // segment_id;
  const submitForm7 = (skip: boolean = false) => {
    if (!skip) {
      if (validPartenerShip() && validTechonologyPartener()) {
        setnextPage(8);
        handleShowStep8();
      }
      return;
    } else {
      setnextPage(8);
      handleShowStep8();
    }
  };

  useEffect(() => {
    setIsShowErrorPartenerShip(false);
    return () => {};
  }, [partenerShipSelected]);

  useEffect(() => {
    setIsShowErrorTechnologyPartener(false);
    return () => {};
  }, [checkedSoftware]);

  useEffect(() => {
    setIsErrorLicencingModel(false);
    return () => {};
  }, [licenCingModelSelected]);

  useEffect(() => {
    setIsErrorDistribuTionChanel(false);
    return () => {};
  }, [distributedChanelSelected]);

  const validFundingRound = (): boolean => {
    if (fundingRoundValue != "") {
      return true;
    } else {
      setIsShowErrorFoundindRound(true);
      return false;
    }
  };
  const validAmountWoudRise = (): boolean => {
    if (fundingRoundValue !== "5") {
      if (amountWouldLike.toString() === "{}") {
        setIsShowAMountWouldLike(true);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsShowErrorFoundindRound(false);
    return () => {};
  }, [fundingRoundValue]);

  useEffect(() => {
    setIsShowAMountWouldLike(false);
    return () => {};
  }, [amountWouldLike]);

  const cbGetUserInfo = (data: any) => {
    if (data && data.status === 200) {
      setLoader(false);
      setisShowModalConfirm(false);
      setShowStep8(false);
      setnextPage(0);
      if (userObject?.isvInCompanyPage?.company_page?.completed) {
        history.push("/company/profil");
      }
    }
  };

  const getUserInfo = () => {
    dispatch(getInfoUserAction(cbGetUserInfo));
  };

  const cbAfterCreateAaccount = (data: any) => {
    if (data && data.status === 200) {
      getUserInfo();
    }
  };

  const sendFormData = () => {
    let companySizeTemp: any = Object.values(
      JSON.parse(getValuesForm2("companySize") || "[]")
    )[0];

    let annualTurnoverTemp: any = Object.values(
      JSON.parse(getValuesForm2("annualTurnover") || "[]")
    )[0];

    let tempAmount = JSON.parse(amountWouldLike || "{}");
    let objectTemp: any = Object.values(tempAmount)[0];
    const valueOfOther = allOtherValue?.map((el) => ({
      id: el.idSolutionOffert,
      other: el.otherTxt?.trim(),
    }));
    const dataToSend = {
      companyName: getValues("companyTradeName") || "",
      companyLegalName: getValues("companyLegalName") || "",
      website: getValues("webSite"),
      city: getValuesForm2("city"),
      typeOfOffice: selectedOffice ? selectedOffice : "",
      companySize: Object.keys(
        JSON.parse(getValuesForm2("companySize") || "[]")
      )[0],
      companySizeMin: companySizeTemp?.min || "",
      companySizeMax: companySizeTemp?.max || "",
      annualTurnover: Object.keys(
        JSON.parse(getValuesForm2("annualTurnover") || "[]")
      )[0],
      annualTurnoverMin: annualTurnoverTemp?.min || "",
      annualTurnoverMax: annualTurnoverTemp?.max || "",
      foundingDate: getValuesForm2("foundingDate") || null,
      country: getValuesForm2("hQLocation") || "",
      segmentDetails: JSON.stringify(
        solutionChecked &&
          solutionChecked?.map((el: T.IdataMakeSolution) => el.idSegment || [])
      ),
      otherSegmentDetails: JSON.stringify(valueOfOther),
      targetedIndustry: JSON.stringify(
        companyTargetSelected &&
          companyTargetSelected?.map((el: T.IListTarget) => el.id || [])
      ),

      licensingModel: JSON.stringify(licenCingModelSelected || []),
      distributionChannel: JSON.stringify(distributedChanelSelected || []),

      targetedCountry: JSON.stringify(
        paysChecked && paysChecked?.map((el: any) => el?.countrId || [])
      ),

      oemProgramSegmentInterest: JSON.stringify(
        partenerShipSelected?.map((el: any) => el.idSegment) || []
      ),
      oemProgramSegmentInterestText: JSON.stringify(partenerShipSelected || []),
      partners: JSON.stringify(
        currentSelectedList?.map((el: any) => el.value) || []
      ),
      fundingRound: fundingRoundValue,
      amountWantToRaise: Object.keys(tempAmount)[0],
      amountToRaiseMin: objectTemp?.min,
      amountToRaiseMax: objectTemp?.max,
      next_page:
        nextPage == 8 && validAmountWoudRise() && validFundingRound()
          ? 0
          : nextPage,
    };
    setLoader(true);
    if (userObject && userObject.isvInCompanyPage == null) {
      if (dataToSend?.companyName && dataToSend?.companyLegalName) {
        createFirstCompanyPage(dataToSend);
      } else {
        history.push("/company/profil");
      }
    } else {
      saveStepCreateAccount(dataToSend);
    }
  };

  const leaveFormCreateCompany = () => {
    setisShowModalConfirm(false);
    history.push("/company/create");
  };

  const createFirstCompanyPage = (data: any) => {
    dispatch(createCompanyPageAction(data, cbAfterCreateAaccount));
  };

  //Save step to create account
  const saveStepCreateAccount = (data: any) => {
    dispatch(
      updateCompanyPageAction(data, companyPageObject.id, cbAfterCreateAaccount)
    );
  };

  const completeCreateCompanyPage = () => {
    if (validFundingRound() && validAmountWoudRise()) {
      sendFormData();
    } else {
      return;
    }
  };

  const customFormShow = (key: string, type: string = "show") => {
    setShowStep1(true);
  };

  const researchingCompanyPage = (company_name: string, token: string) => {
    return searchCompanyPage(company_name, token);
  };

  const cancelCreateCompanyPage = () => {
    setisShowModalConfirm(false);
    switch (currentPageModal) {
      case 1:
        setShowStep1(true);
        break;
      case 2:
        setShowStep2(true);
        break;
      case 3:
        setShowStep3(true);
        break;
      case 4:
        setShowStep4(true);
        break;
      case 5:
        setShowStep5(true);
        break;
      case 6:
        setShowStep6(true);
        break;
      case 7:
        setShowStep7(true);
        break;
      case 8:
        setShowStep8(true);
        break;

      default:
        break;
    }
  };

  const getData = async () => {
    let result: any = await getCompletedAcccount();
    if (result && result?.data) {
      sessionStorage.setItem("completed_account", JSON.stringify(result.data));
    }
  };

  useEffect(() => {
    const isCompletedCountry = dataCompleted?.country?.length < 1;
    if (isCompletedCountry) {
      getData();
    }
    return () => {};
  }, []);

  return {
    showStep1,
    showStep2,
    showStep3,
    showStep4,
    showStep5,
    showStep6,
    showStep7,
    showStep8,
    errors,
    errorsForm2,
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    allSolutionSellected,
    featuredSolution,
    isHaveError,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    isHaveErrorTargetCompany,
    companyTargetSelected,
    licenCingModelSelected,
    distributedChanelSelected,
    allContinents,
    paysChecked,
    allContinentSelected,
    isErrorLicencingModel,
    isErrorDistribuTionChanel,
    isShowOfficeData,
    selectedOffice,
    dataCompleted,
    isErrorCountrySelected,
    isShowErrorOffice,
    partenerShipSelected,
    checkedSoftware,
    isShowErrorPartenerShip,
    isShowErrorTechnologyPartener,
    fundingRoundValue,
    amountWouldLike,
    isShowErrorFoundindRound,
    isShowAMountWouldLike,
    loader,
    isShowModalConfirm,
    solutionCheckedValue,
    technologiesPartnership,
    currentSelected,
    currentSelectedList,
    setCurrentSelected,
    setCheckedSoftware,
    setAmountWouldLike,
    submitForm6,
    addOffice,
    addPaysInArray,
    addLicencingChecked,
    addDistributionChecked,
    addTargetCompany,
    setFundingRound,
    selectSolution,
    removeItemSelected,
    submitForm2,
    register,
    handleSubmit,
    handleCloseStep1,
    handleShowStep1,
    handleCloseStep2,
    handleBackStep1,
    handleCloseStep3,
    handleCloseStep4,
    handleBackStep3,
    handleCloseStep5,
    handleBackStep4,
    handleCloseStep6,
    handleBackStep5,
    handleCloseStep7,
    handleBackStep6,
    handleCloseStep8,
    handleBackStep7,
    handleBackStep2,
    submit,
    registerForm2,
    handleSubmitForm2,
    addParterShip,
    submitForm3,
    submitForm4,
    submitForm5,
    submitForm7,
    completeCreateCompanyPage,
    customFormShow,
    companyPageObject,
    sendFormData,
    setisShowModalConfirm,
    userObject,
    proposalCompanies,
    researchingCompanyPage,
    getValuesForm2,
    addListOption,
    removeSelected,
    cancelCreateCompanyPage,
    getValues,
    leaveFormCreateCompany,
    allOtherValue,
    setOtherValueTxt,
    checkNumberRequest,
    watchForm2,
  };
}
