/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { isURL } from "../../../../../utils";
import { IPropsEditInfoRigth } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function EditInfoDetailRigth(props: IPropsEditInfoRigth) {
  return (
    <>
      <Modal
        show={props.showDetailsRigth}
        className="createCompanypopup large forStep5custom"
      >
        <form onSubmit={props?.handleSubmit(props?.submit)}>
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">Investor page Details</h2>
              <a className="close" onClick={() => props.closeModalInfoRigth()}>
                <span className="icon-close-pop"></span>
              </a>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className={`contentBorder row forPitch`}>
              <div
                className={`form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 ${
                  props?.isIndividualInvestor ? "d-none" : ""
                }`}
              >
                <label>
                  Investor type
                  {props.errors.investorType && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  id=""
                  className="custom-select"
                  placeholder="Select an option..."
                  {...props?.register("investorType", {
                    required: props?.required,
                  })}
                >
                  <option value="">Select an option...</option>
                  {props.dataInvestorType?.map((el: any) => (
                    <option
                      value={el.id}
                      selected={el.id == props?.getValuesForm("investorType")}
                      key={el.id}
                    >
                      {el.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className={`form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2`}
              >
                <label>
                  Website
                  {props.errors.webstite?.type == "required" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  {props.errors.webstite?.type == "validate" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">Url not valide</span>
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  className="input-text form-control"
                  {...props?.register("webstite", {
                    required: props?.required,
                    validate: (url: string) => isURL(url),
                  })}
                />
              </div>

              <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
                <label>
                  linkedin
                  {props.errors.linkDean?.type == "validate" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        Url linkedin is not valide
                      </span>
                    </div>
                  )}
                </label>
                <div className="linkedintext">
                  linkedin.com/in/
                  <input
                    type="text"
                    className="input-text form-control"
                    {...props?.register("linkDean", {
                      validate: (url: string) => {
                        if (url && url != "") {
                          if (isURL(url)) {
                            if (url?.includes("https://www.linkedin.com/in/")) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        } else {
                          return true;
                        }
                      },
                    })}
                  />
                </div>
              </div>
              <div className=" linkedintextform-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
                <label>
                  Location
                  {props.errors.location && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  id=""
                  className="custom-select"
                  placeholder="Select an option..."
                  {...props?.register("location", {
                    required: props?.required,
                  })}
                >
                  <option value="">Select an option...</option>
                  {props.location?.map((el: any) => (
                    <option value={el.id} key={el.id}>
                      {el.country_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
                <label>City</label>
                <input
                  type="text"
                  className="input-text form-control"
                  {...props?.register("city", {})}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right forPitch">
              <button
                className="btn btn-devinsider cancel"
                type="button"
                onClick={() => props.closeModalInfoRigth()}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-devinsider px-5">
                {props?.loader ? <Loader /> : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
