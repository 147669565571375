/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./login.scss";
import { Alert, Modal } from "react-bootstrap";
import { BtnNext } from "../bouton/btn-next";
import UseSigninCtr from "./useLogin";

type IModalLoginProps = {
  show: boolean;
  setShow: (a: boolean) => void;
};

export default function LoginModal(props: IModalLoginProps) {
  const {
    error,
    showLoader,
    email,
    password,
    showPassword,
    getStarted,
    signinUser,
    setEmail,
    setPassword,
    setShowPasswpord,
    forgotPassword,
  } = UseSigninCtr();

  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom logincomp"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Log in to Devinsider</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              props.setShow(false);
            }}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="loginContent">
          <form action="" className="loginForm">
            {error && <Alert variant="danger">Bad credentials</Alert>}
            <div className="form-group">
              <input
                type="text"
                placeholder="Email address"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setEmail(event.target.value)
                }
              />
            </div>
            <div className="form-group passwordContent">
              <input
                type={showPassword ? "text" : "password"}
                name=""
                id=""
                placeholder="Password"
                className="input password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setPassword(event.target.value)
                }
              />
              <span
                className="showPassWord"
                onClick={() => setShowPasswpord(!showPassword)}
              >
                Show
              </span>
            </div>
            <div className="rememberMe">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  forgotPassword();
                }}
              >
                Forgot password?
              </a>
            </div>
            <div className="loginbtncontent">
              <div className="btn btn-devinsider large containerBtnBtn">
                <BtnNext
                  text={"Log in"}
                  click={(): void => signinUser()}
                  showLoader={showLoader}
                />
              </div>
            </div>

            <div className="haveAccount">
              Don’t have a Devinsider account?&nbsp;
              <a
                onClick={(e) => {
                  e.preventDefault();
                  getStarted();
                }}
              >
                Get started
              </a>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
