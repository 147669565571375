import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateInfoUserAction } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";

export default function UseUpdateProfil() {
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [isShowImage, setShowImage] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const userState = useSelector((state: rootState) => state.userReducer);
  const dispatch = useDispatch();

  //for pick  image
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      setSelectedFile(event?.target?.files[0]);
      //  setIsFilePicked(true);
    }
  };

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
      //  setIsFilePicked(false);
    }
  };

  const restorePhotoChange = () => {
    setSelectedFile(undefined);
  };

  const hideSelectedFile = () => {
    setSelectedFile(undefined);
    setShowImage(false);
  };

  const cbUpdatePhoto = (data: any) => {
    setIsLoaderShow(false);
    setShowImage(false);
    setSelectedFile(undefined);
    if (data) {
      toast("Successfully updated");
    }
  };

  const updateProfil = () => {
    if (selectedFile) {
      setIsLoaderShow(true);
      const params = {
        fileName: selectedFile ? selectedFile : null,
        fonction: "updateUser"
      };
      dispatch(updateInfoUserAction(params, cbUpdatePhoto));
    } else {
      setShowImage(false);
    }
  };

  return {
    changeHandler,
    setShowImage,
    handleBtn,
    restorePhotoChange,
    isLoaderShow,
    selectedFile,
    fileRef,
    hideSelectedFile,
    isShowImage,
    updateProfil,
    fileUrl: userState.file_name,
  };
}
