/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import {
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import * as T from "../../types/types-company-page";
import { useParams } from "react-router-dom";
import { getOtherCompanyPageAttributeAction } from "../../../../redux/actions/company-page-isv-action/other_isv_action";
import { OEM_PROGRAM } from "../../../../service/constant";

export default function UseGeographieTarget() {
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const idOtherUser = params?.id ? true : false;
  const state = useSelector((state: rootState) => state);
  /************************************
   *
   * Company profil object
   */
  let companyPageObject = useSelector((state: rootState) =>
    idOtherUser ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );
  /*************************************
   *
   * User profil object
   */

  const [loaderTargetCountry, setLoader] = useState<boolean>(false);

  /********************************************************
   *
   * Pays checked value to show in Geographic Target Market
   */
  const [paysChecked, setPaysChecked] = useState<Array<object>>(
    companyPageObject?.targeted_country?.map((el: any) => ({
      idContinent: el.idContinent,
      countrId: el && el.countryId,
      countryName: el && el.countryName,
    }))
  );

  const [allContinentSelected, setAllContinentSelected] = useState<
    Array<string>
  >([]);
  const [isShowOfficeData, setIsShowOfficeData] = useState<boolean>(false);
  const [isShowErroCountrySelected, setIsShowErroCountrySelected] =
    useState<boolean>(false);

  const [isShowErrorNorthAmerica, setIsShowErrorNorthAmerica] =
    useState<boolean>(false);

  const [selectedOffice, setSelectedOffice] = useState<string>(
    companyPageObject.type_of_office || ""
  );
  const [showStrategyGeographic, setshowStrategyGeographic] =
    useState<boolean>(false);

  const flatArrayWithSameId = (data: any) => {
    const arrayHashmap = data.reduce((obj: any, item: any) => {
      obj[item.idContinent]
        ? obj[item.idContinent].push(item)
        : (obj[item.idContinent] = [item]);
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    return mergedArray;
  };

  const setTargetCountry = () => {
    let attributes = "targetedCountry";
    const oemIfExist =
      state.userReducer.roles.indexOf(OEM_PROGRAM) !== -1
        ? state.oemProgramReducer.program.id
        : "";
    let idCompany =
      oemIfExist !== "" ? params?.id + "/" + oemIfExist : params?.id || "";

    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attributes,
          idCompany,
          cbGetTargetCountry
        )
      );
    } else {
      dispatch(getCompanyPageAttributeAction(attributes, cbGetTargetCountry));
    }
  };

  const cbGetTargetCountry = (response: any) => {
    if (response && response?.status === 200) {
      let tempSelected =
        response &&
        response?.data?.map((el: any) => ({
          idContinent: el.idContinent,
          countrId: el && el.countryId,
          countryName: el && el.countryName,
        }));
      setPaysChecked(tempSelected);
      if (showStrategyGeographic) {
        setLoader(false);
        setshowStrategyGeographic(false);
      }
    }
  };

  useEffect(() => {
    const isEmptyTargetedCountry =
      companyPageObject?.id?.toString() !== "0" &&
      companyPageObject?.targeted_country?.length < 1 &&
      !idOtherUser;

    if (isEmptyTargetedCountry) setTargetCountry();

    if (idOtherUser) setTargetCountry();

    return () => {};
  }, []);

  const transFormnDataCountry = (data: any) => {
    let custom = data.countryesData.map((el: any) => {
      let temp = {
        idContinent: data.idContinent,
        countrId: el && el.id,
        countryName: el && el.country_name,
      };
      return temp;
    });
    return custom;
  };

  const addPaysInArray = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<T.IdataMake> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple
    if (data && data.countryesData) {
      //find if bouton is selected all is clicked
      let cotinentItemIsSelected = allContinentSelected?.find(
        (el) => el == data.idContinent
      );
      //tranform data if array
      let dataAfterTransform = transFormnDataCountry(data);
      dataMake = [...dataAfterTransform];

      //if selected all is not clicked
      if (!cotinentItemIsSelected) {
        //remove if item is existe deja
        let dataToStore =
          paysChecked &&
          paysChecked.filter((el: any) => el.idContinent != data.idContinent);

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setPaysChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllContinentSelected([...allContinentSelected, data.idContinent]);
      } else {
        //if all selected is clicked , remove all item same idContinent
        setPaysChecked(
          paysChecked.filter((el: any) => el.idContinent != data.idContinent)
        );
        //Make selected all is not clicked
        setAllContinentSelected(
          allContinentSelected.filter((el) => el != data.idContinent)
        );
      }
    } else {
      dataMake = [data];
      let found =
        paysChecked &&
        paysChecked?.find((el: any) => el.countrId == data.countrId);
      if (!found) {
        setPaysChecked([...paysChecked, ...dataMake]);
      } else {
        setPaysChecked(
          paysChecked &&
            paysChecked.filter((elem: any) => elem.countrId != data.countrId)
        );
      }
    }
  };

  useEffect(() => {
    const headquartersLocation = companyPageObject?.country?.id;
    const isShow =
      paysChecked?.find((el: any) => el.idContinent == "5") &&
      headquartersLocation?.toString() !== "177";

    if (isShow) {
      setIsShowOfficeData(true);
      setSelectedOffice(companyPageObject?.type_of_office || "");
    } else {
      setIsShowOfficeData(false);
      setSelectedOffice("");
    }
    return () => {};
  }, [paysChecked]);

  useEffect(() => {
    const headquartersLocation = companyPageObject?.country?.id;
    const isShowOfficeData = headquartersLocation?.toString() !== "177";
    if (isShowOfficeData) {
      setIsShowOfficeData(true);
      setSelectedOffice(companyPageObject?.type_of_office || "");
    } else {
      setIsShowOfficeData(false);
      setSelectedOffice("");
    }
    return () => {};
  }, []);

  const addOffice = (dataMake: string) => {
    setSelectedOffice(dataMake);
  };

  /***********************************
   *
   * Update Geographic target
   */

  const cbUpdateTargetCountry = (response: any) => {
    if (response && response?.status === 200) {
      setTargetCountry();
    }
  };

  const updateModalGeographicTarget = () => {
    const isNOtCompletedOffice =
      paysChecked?.find((el: any) => el.idContinent?.toString() === "5") &&
      companyPageObject?.country?.id?.toString() !== "177" &&
      selectedOffice === "";
    if (paysChecked?.length <= 0) {
      setIsShowErroCountrySelected(true);
      return;
    } else if (isNOtCompletedOffice) {
      setIsShowErrorNorthAmerica(true);
      return;
    } else {
      let params = {
        targetedCountry: JSON.stringify(
          paysChecked && paysChecked.map((el: any) => el?.countrId)
        ),
        typeOfOffice: selectedOffice ? selectedOffice : "",
      };

      setLoader(true);
      dispatch(
        updateCompanyPageAction(
          params,
          companyPageObject.id,
          cbUpdateTargetCountry
        )
      );
    }
  };

  const cancelGeoGraphieTarget = () => {
    setshowStrategyGeographic(false);
    setPaysChecked(
      companyPageObject?.targeted_country?.map((el: any) => ({
        idContinent: el.idContinent,
        countrId: el && el.countryId,
        countryName: el && el.countryName,
      }))
    );
  };

  useEffect(() => {
    setIsShowErroCountrySelected(false);
    setIsShowErrorNorthAmerica(false);
    return () => {};
  }, [paysChecked, selectedOffice]);

  return {
    paysChecked,
    addPaysInArray,
    allContinentSelected,
    isShowOfficeData,
    addOffice,
    selectedOffice,
    updateModalGeographicTarget,
    showStrategyGeographic,
    setshowStrategyGeographic,
    flatArrayWithSameId,
    loaderTargetCountry,
    cancelGeoGraphieTarget,
    isShowErroCountrySelected,
    isShowErrorNorthAmerica,
  };
}
