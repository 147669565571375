export const errorsCard = [
  {
    code: "account_invalid",
    message: "The account ID provided is invalid",
  },
  {
    code: "account_number_invalid",
    message: "The bank account number provided is invalid",
  },
  {
    code: "country_code_invalid",
    message: "The country code provided was invalid.",
  },
  {
    code: "expired_card",
    message:
      "The card has expired. Check the expiration date or use a different card.",
  },
  {
    code: "incorrect_cvc",
    message:
      "The card’s security code is incorrect. Check the card’s security code or use a different card.",
  },
  {
    code: "incorrect_number",
    message:
      "The card number is incorrect. Check the card’s number or use a different card.",
  },
  {
    code: "invalid_expiry_month",
    message:
      "The card’s expiration month is incorrect. Check the expiration date or use a different card.",
  },
  {
    code: "invalid_expiry_year",
    message:
      "The card’s expiration year is incorrect. Check the expiration date or use a different card.",
  },
  {
    code: "invalid_number",
    message:
      "The card number is invalid. Check the card details or use a different card.",
  },
];
