import React, { ChangeEvent, useEffect } from "react";
import Header from "../../../widget/index";
import Select from "react-select";

import BlockFilter from "../bloc-filter/filter-matching-panel";
import { LoadingMatchProgram } from "../../../widget/loader";
import UseProviderMatching from "../../../../controller/program-ctr/program-matchmaking-ctr/bloc-matching-panel/use-matching-panel-ctr";
import Pagination from "../../../widget/use-pagination/pagination";
import { changeNumberValue } from "../../../../utils";
import ItemListe from "./bloc-matching-panel/item-liste";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import {
  checkIsCompletedOemCompanyMatching
} from '../../../../utils';

export default function ThreeCase() {
  // Bug #48516,access to matchmaking
  const history = useHistory();
  const reduxStore = useSelector((rootState: rootState) => rootState);
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const providerMatching = UseProviderMatching();

  (() => {
    const haveCompanyPage = (() =>
      providerMatching.isIsv &&
      reduxStore.userReducer.isvInCompanyPage !== null)();
    if (haveCompanyPage === false) history.push("/program/matchmaking");
    if (!checkIsCompletedOemCompanyMatching(companyPageInfo)) history.push("/program/matchmaking");
  })();

  if (!providerMatching.isIsv) {
    return <Redirect to={"/profil-not-found"} />;
  }

  return (
    <div className="findIsvcontainer threeCaseContainer">
      <Header />
      <BlockFilter
        setSelectedType={(id: string) => providerMatching?.handleFilterType(id)}
        selectedType={providerMatching?.programType}
        selectedCountry={providerMatching?.countries}
        setSelectedCountry={(idCountry: string) =>
          providerMatching?.handleFilterCountry(idCountry)
        }
        selectedProgramType={providerMatching?.segmentsDetails}
        setSelectedProgramType={(id: string) =>
          providerMatching?.handleFilterProgramSolution(id)
        }
        lunchFilter={() => providerMatching?.lunchFilter()}
        resetFilter={() => providerMatching?.resetFilter()}
        sliderValue={providerMatching?.sliderValue}
        setSliderValue={(value: Array<number>) =>
          providerMatching?.setSliderValue(value)
        }
        type={"matching"}
      />

      <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          <div className="headerOfList">
            <div className="leftHead">
              {
                (typeof providerMatching?.allProgram?.oemPrograms !== 'string') ? (
                  <div className="number">
                  Showing{" "}
                  {!providerMatching?.loaderMatching && (
                    <>
                      {" "}
                      <b>
                        {changeNumberValue(
                          providerMatching?.allProgram?.totalDataPaginate
                        )}
                      </b>{" "}
                      Programs
                    </>
                  )}
                  </div>
                ) : 
                (<></>)
              }
              
              <form
                onClick={(e) => openBlock(e)}
                onSubmit={(e) => {
                  e?.preventDefault();
                }}
              >
                <input
                  placeholder="Search..."
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    providerMatching?.setSearch_name(e?.target?.value)
                  }
                  value={providerMatching?.search_name}
                  onBlur={() =>
                    providerMatching?.search_name?.length <= 0 &&
                    providerMatching?.handleSearche()
                  }
                />
              </form>
            </div>
            <div className="rightHead">
              <div className="sortedby labelSelect">
                <label>Sorted by :</label>
                <Select
                  className="triOreder"
                  isClearable={false}
                  options={providerMatching?.filtered}
                  classNamePrefix="tri"
                  defaultValue={providerMatching?.filtered[0]}
                  onChange={(filter: any) =>
                    providerMatching?.handleFilter(filter)
                  }
                />
              </div>
            </div>
          </div>
          <div className="scrollContainer">
            {providerMatching?.loaderMatching ? (
              <LoadingMatchProgram />
            ) : (typeof providerMatching?.allProgram?.oemPrograms === 'string') ? (
                <ul className="listOfIsvMatch">{providerMatching?.allProgram?.oemPrograms}</ul>
            ) :
            (
              <ItemListe
                allProgram={providerMatching?.allProgram}
                addFavorite={providerMatching?.addFavorite}
                showProgramPage={(id: number) =>
                  providerMatching?.showProgramPage(id)
                }
              />
            )}
          </div>

          <div className="pagginationCategory fixePagination">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={providerMatching?.allProgram?.currentPage}
                totalCount={providerMatching?.allProgram?.totalDataPaginate}
                pageSize={20}
                onPageChange={(page: any) =>
                  providerMatching?.loadMoreProgram(page)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
