import React, { useState } from 'react';
import { useHistory } from 'react-router';

export default function UseCompleteAboutExpert() {
  const history = useHistory();
  const [aboutExpert, setAboutExpert] = useState<string>(
    sessionStorage.getItem('aboutExpert')
      ? sessionStorage.getItem('aboutExpert') || ''
      : ''
  );

  const navigateCompletePostExpert = () => {
    if (aboutExpert) {
      sessionStorage.setItem('aboutExpert', aboutExpert);
    }
    history.push('/complete-post-expert');
  };

  return { aboutExpert, setAboutExpert, navigateCompletePostExpert };
}
