import React, {useEffect, useRef, useState} from 'react';
import {url} from "../../../../../service/constant";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {PaymentCard} from "../../../payment-popup/payment-card/payment-card";
import {BtnNext} from "../../../../widget/bouton/btn-next";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {rootState} from "../../../../../redux/reducers";
import {getDataMicroService} from "../../../../../service/applicatif/back-end-service";
import {PaymentInputErrors} from "../../../payment-popup/payment-info/payment-info";
import {PaymentBillingAddress} from "../../../payment-popup/payment-billing-address/payment-billing-address";

export type CardRegistrationInputProps = {
    oem: {
        isOem: boolean
        id: string | null
    }
    setUnpaidInvoice: (invoice: {
        amount: string,
        currency: string
    }) => void
    setPaymentMethodId: (id: string) => void
    setShowConfirmPayment: (show: boolean) => void
    showButtonLoader: boolean
    setShowModalPaymentInfo: (show: boolean) => void
    setShowButtonLoader: (show: boolean) => void
    postNewCard:  (id: string) => void
}

export const CardRegistrationInput: React.FC<CardRegistrationInputProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const userReducer = useSelector((state: rootState) => state.userReducer);
    const userEmail = userReducer.email;
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [isBusiness, setIsBusiness] = useState(true);
    const [companyName, setCompanyName] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [validVatNumber, setValidVatNumber] = useState(false);
    const [currency, setCurrency] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        country: '',
        city: '',
        line1: '',
        state: '',
        postal_code: '',
    });
    const [inputErrors, setInputErrors] = useState<PaymentInputErrors>({
        billingAddress: false,
        companyName: false,
        firstname: false,
        lastname: false,
        vatNumber: false
    });
    const [shouldValidateInputs, setShouldValidateInputs] = useState(false);
    const timeoutRef = useRef<number | null>(null);

    useEffect(() => {
        if (shouldValidateInputs) {
            setInputErrors({
                firstname: firstname === '',
                lastname: lastname === '',
                companyName: companyName === '',
                vatNumber: vatNumber != "" && !validVatNumber,
                billingAddress:
                    billingAddress.country === '' ||
                    billingAddress.city === '' ||
                    billingAddress.line1 === '' ||
                    billingAddress.state === '' ||
                    billingAddress.postal_code === ''
            })
        }
    }, [firstname, lastname, billingAddress, companyName, shouldValidateInputs]);

    const checkVatNumber = () => {
        getDataMicroService(
            url.microservice.payment.check_vat_number + '/' + billingAddress.country + '/' + vatNumber,
            {},
            userReducer.token)
            .then(response => {
                if (response.data.message === 'success') {
                    if (response.data.data.is_valid) {
                        setValidVatNumber(true)
                        setCompanyName(response.data.data.company_name)
                    } else {
                        setValidVatNumber(false)
                        setCompanyName("INVALID VAT NUMBER")
                    }
                }
                else
                    throw response.data.data.detail;
            })
            .catch(error => {
                toast('Error checking VAT number')
                console.log(error)
            })
    }

    useEffect(() => {
        if (vatNumber != "") {
            setValidVatNumber(false)
        } else {
            setCompanyName("")
        }

        const delayedAPICall = () => {
            if (vatNumber != '') {
                checkVatNumber()
            } else {
                if (!validVatNumber) {
                    setValidVatNumber(true)
                }
            }
        };

        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = timeoutRef.current = window.setTimeout(delayedAPICall, 1000);

        return () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [vatNumber]);

    const handleCountryChange = (value: string) => {
        if (value !== '') {
            setValidVatNumber(true)
            setVatNumber("")
            setCompanyName("")
            setCurrency('');
            setBillingAddress({
                ...billingAddress,
                country: value
            });
            getDataMicroService(
                url.microservice.payment.get_currency_by_country + value,
                {},
                userReducer.token)
                .then(response => {
                    if (response.data.message === 'success') {
                        setCurrency(response.data.data)
                    }
                    else
                        throw response.data.data.detail;
                })
                .catch(error => {
                    toast('Error getting country currency')
                    console.log(error)
                })
        }
    }

    const isFormValid = () => {
        if (firstname === '') {
            document.getElementById('input-firstname')?.focus()
            return false;
        }
        if (lastname === '') {
            document.getElementById('input-lastname')?.focus()
            return false;
        }
        if (billingAddress.country === '') {
            document.getElementById('input-country')?.focus()
            return false;
        }
        if (billingAddress.city === '') {
            document.getElementById('input-city')?.focus()
            return false;
        }
        if (billingAddress.line1 === '') {
            document.getElementById('input-line1')?.focus()
            return false;
        }
        if (billingAddress.state === '') {
            document.getElementById('input-state')?.focus()
            return false;
        }
        if (billingAddress.postal_code === '') {
            document.getElementById('input-postal_code')?.focus()
            return false;
        }
        if (companyName === '') {
            document.getElementById('input-companyName')?.focus()
            return false;
        }
        if (!validVatNumber) {
            document.getElementById('input-vatNumber')?.focus()
            return false;
        }

        return true;
    }

    const handleConfirm = async () => {
        setShouldValidateInputs(true);

        if (!isFormValid()) {
            return;
        }

        props.setShowButtonLoader(true)
        if (!stripe || !elements ) {
            toast('An Error occurred');
            props.setShowButtonLoader(false)
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
            billing_details: {
                name: firstname + ' ' + lastname,
                email: userEmail,
                address: billingAddress
            },
            metadata: {
                has_vat: isBusiness && vatNumber !== '' ? 'true' : 'false',
                company_name: companyName,
                vat_number: vatNumber,
                currency: currency ? currency : 'usd'
            }
        });

        if (error) {
            cardElement?.focus()
            props.setShowButtonLoader(false)
            toast("Card validation failed")
            console.error('Erreur lors de la création du PaymentMethod :', error);
        } else {
            if (paymentMethod) {
                if (props.oem.isOem) {
                    getDataMicroService(
                        url.microservice.payment.get_user_unpaid_grouped_invoice,
                        { oem_program_id: props.oem.id },
                        userReducer.token
                    ).then(response => {
                        if (response.data.message === 'success') {
                            const unpaidGroupedInvoice = response.data.data;

                            if (unpaidGroupedInvoice && unpaidGroupedInvoice.currency !== currency) {
                                props.setUnpaidInvoice({
                                    amount: unpaidGroupedInvoice.amount_due,
                                    currency: unpaidGroupedInvoice.currency
                                });
                                props.setPaymentMethodId(paymentMethod.id);
                                props.setShowButtonLoader(false)
                                props.setShowConfirmPayment(true);
                            } else {
                                props.postNewCard(paymentMethod.id)
                            }
                        } else {
                            throw response.data.data.detail;
                        }
                    }).catch(error => {
                        props.setShowButtonLoader(false)
                        toast('Error retrieving balance')
                        console.log(error)
                    })
                } else {
                    props.postNewCard(paymentMethod.id)
                }
            } else {
                toast("An error occurred")
                props.setShowButtonLoader(false)
            }
        }
    }

    return(
        <>
            <PaymentCard
                firstname={firstname}
                lastname={lastname}
                setFirstname={setFirstname}
                setLastname={setLastname}
                inputErrors={inputErrors}/>
            <br/>

            <PaymentBillingAddress
                billingAddress={billingAddress}
                handleCountryChange={handleCountryChange}
                inputErrors={inputErrors}
                setBillingAddress={setBillingAddress}
            />

            <div className='contentCheckboxsolution ml-3 mt-5'>
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={true}
                    />
                    <label htmlFor='' className="custom-control-label">I'm purchasing as a business</label>
                </div>
            </div>
            <div className='vatInformation required'>
                <label className='mr-3 ml-3 mt-3'>
                    VAT information
                    <div
                        className={`container-error-address pl-2 ${
                            inputErrors.companyName ? 'd-inline' : 'd-none'
                        }`}
                    >
                        <span className='error-red'> error </span>
                        <span className='txt-error'>Company name field is required</span>
                    </div>
                    <div
                        className={`container-error-address pl-2 ${
                            inputErrors.vatNumber ? 'd-inline' : 'd-none'
                        }`}
                    >
                        <span className='error-red'> error </span>
                        <span className='txt-error'>Invalid VAT number</span>
                    </div>
                </label>
                <div className='name col-6 mt-1'>
                    <input
                        id='input-companyName'
                        type='text'
                        className='form-control'
                        name='companyName'
                        placeholder='Company name'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        disabled={vatNumber != ""}
                    />
                </div>
                <div className='name col-6 mt-2'>
                    <input
                        id='input-vatNumber'
                        type='text'
                        className='form-control'
                        name='vat'
                        placeholder='VAT number'
                        disabled={billingAddress.country == ""}
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                    />
                </div>
            </div>
            <div className='contentBorder formForEmail text-right'>
                <button
                    type='button'
                    className='btn btn-devinsider px-5 save'
                    onClick={handleConfirm}
                    disabled={props.showButtonLoader}
                >
                    <BtnNext
                        text={'Save payment information'}
                        showLoader={props.showButtonLoader}
                    />
                </button>
            </div>
        </>
    )
}