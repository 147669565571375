import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import {
  IDiscutionCategorie,
  ITemDetailsDiscutionCategorie,
  ItemLabel,
} from "../../../../redux/types/community-interface";

export type IProps = {
  featuredLabel: Array<ItemLabel>;
  discussionCategories: Array<ITemDetailsDiscutionCategorie>;
  navigateToFilterCategorie: (params: any) => void;
};
export default function Index(props: IProps) {
  const [activeKey, setActiveKey] = useState("");

  function changeState(id: any) {
    if (activeKey === id) {
      setActiveKey("");
    } else {
      setActiveKey(id);
    }
  }

  return (
    <Tabs
      defaultActiveKey="featuredLabels"
      id="uncontrolled-tab-example"
      className="menuConversationchoice"
    >
      <Tab eventKey="featuredLabels" title="Featured labels">
        <ul className="listLabelLink">
          {props?.featuredLabel?.map((el: ItemLabel, index: number) => (
            <li
              key={index}
              onClick={() =>
                props?.navigateToFilterCategorie({
                  id: el?.category_id,
                  label_id: el?.id,
                  isInFeatured: true,
                })
              }
            >
              {el?.icone === "" ? (
                <></>
              ) : (
                <>
                  <a href="javascript:;" className={(index + 1)?.toString()}>
                    <div dangerouslySetInnerHTML={{ __html: el?.icone }} />
                    <>{el?.title}</>
                  </a>
                </>
              )}
            </li>
          ))}
        </ul>
      </Tab>
      <Tab eventKey="discussionCategories" title="Discussion categories">
        <ul className="discussionCategory">
          {props?.discussionCategories?.map(
            (el: ITemDetailsDiscutionCategorie, index: number) => (
              <li key={index}>
                <div className="itemDiscussion">
                  <div
                    className="linkDiscussion"
                    onClick={() => props?.navigateToFilterCategorie(el)}
                  >
                    <div
                      className="iconSpan"
                      dangerouslySetInnerHTML={{ __html: el?.icon }}
                    ></div>
                    {el?.title}
                  </div>
                  <div
                    className={`contract ${
                      activeKey === index?.toString() ? "open" : ""
                    }`}
                    onClick={() => {
                      changeState(index?.toString());
                    }}
                  >
                    <span className="openSubmenu">
                      {/* Dev #45424 Bug d'affichage sur la page "Manage my Feed" */}
                      {el?.labels?.length - 1 > 0 ? el?.labels?.length - 1 : ""}{" "}
                      Featured label
                      {el?.labels?.length - 1 > 1 ? "s" : ""}
                    </span>
                    <div className="linkInterne">
                      {el?.labels?.map(
                        (
                          element: { id: number; title: string },
                          index: number
                        ) => (
                          <a
                            href="javascript:;"
                            key={index}
                            onClick={() => {
                              if (el?.id !== 99) {
                                props?.navigateToFilterCategorie({
                                  id: el?.id,
                                  label_id: element?.id,
                                });
                              } else {
                                props?.navigateToFilterCategorie({
                                  id: el?.id,
                                });
                              }
                            }}
                          >
                            {element?.title}
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      </Tab>
    </Tabs>
  );
}
