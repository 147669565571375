import React, { useState, useEffect } from "react";
import { checkIfExist, refaCtoNullKey, setInternationalPhoneNumber } from "../../../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../../../redux/reducers";
import { contact } from "../../../../../../utils/country_phone";
import { userReducer } from "../../../../../../redux/reducers/acounts-reducer";

const ProfilBodyOemPage = (props: any) => {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [dialCode, setDialCode] = useState<string | null>("");
  useEffect(() => {
    if (userState.phone_number_code !== "" && userState.phone_number_code !== null) {
      setDialCode(
        contact.filter(
          (element) => element.code === userState.phone_number_code
        )[0].dial_code
      );
    }
  }, []);
  return (
    <>
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          <>
            {checkIfExist(userState?.plain_text) ? (
              userState?.plain_text
            ) : (
              <div className="fauxText">
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l100"></div>
                <div className="l50"></div>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <h2 className="title">Contact information</h2>
        <ul className="ulListInfo">
          <li>
            <div className="label">Email</div>
            <div className="infoLabel">{userState?.email}</div>
          </li>
          <li>
            <div className="label">Linkedin</div>
            <div className="infoLabel">
              <a
                target="_blank"
                href={
                  "https://www.linkedin.com/in/" +
                  refaCtoNullKey(userState.linkedin, "-")
                }
              >
                {refaCtoNullKey(userState.linkedin, "-").length > 14
                  ? refaCtoNullKey(userState.linkedin, "-").substring(0, 15) +
                    "..."
                  : refaCtoNullKey(userState.linkedin, "-")}
              </a>
            </div>
          </li>
          <li>
            <div className="label">Phone number</div>
            <div className="infoLabel">
              {/* {dialCode === "" ? <></> : <>{"(" + dialCode + ") "}</>}
              {refaCtoNullKey(userState.phone, "-")} */}
              {setInternationalPhoneNumber(userState?.phone, dialCode)}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfilBodyOemPage;
