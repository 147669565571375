import React from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import { useHistory } from "react-router";


export default function GetStartedCorporate() {
  const history = useHistory();

  const goToGetStarted = (): void => {
      history.push("/account-choices");
  };
  const goToMergersAcquisitions = () => {
    history.push("/ma/home");
  };
  return (
    <div className="landing_market">
      <Header />
      <section className="joinHome market corporate">
        <div className="container mx-auto">
          <h1 className="title">Corporate development</h1>
          <p>
            Manage your corporate development needs <br /> along all stages of
            the equity life cycle.
          </p>
          <a
            onClick={() => goToGetStarted()}
            className="getStartedLink flex-column"
          >
            Get started
            <span className="itsfree">(It's free!)</span>
          </a>
        </div>
      </section>
      <section className="bggris softwareVendors">
        <div className="container mx-auto">
          <p className="text-center">
            The Devinsider helps software vendors manage the equity life cycle
            from early stage funding to exit. Use the different features <br />
            to tap into our global community and achieve your corporate
            development goals.
          </p>
          <div className="list">
            <div className="item">
              <span className="title">Early stage funding</span>
              <div className="smallFont">
                <div className="subtitle">
                  Match with early stage software investors based on your company
                  profile and preferences.
                </div>
                <ul>
                  <li>
                    <span className="icon-check ico"></span>Seed and A round
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Advanced matchmaking
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Directly contact
                    investors
                  </li>
                </ul>
                <a className="btn-more" onClick={() => goToGetStarted()}>
                  Get started
                </a>
              </div>
            </div>
            <div className="item">
              <span className="title">Later stage funding</span>
              <div className="smallFont">
                <div className="subtitle">
                  Match with investors to cover funding needs beyond A round.
                </div>
                <ul>
                  <li>
                    <span className="icon-check ico"></span>Growth funding
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Advanced matchmaking
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Directly contact
                    investors
                  </li>
                </ul>
                <a className="btn-more" onClick={() => goToGetStarted()}>
                  Get started
                </a>
              </div>
            </div>
            <div className="item">
              <span className="title">M&A</span>
              <div className="smallFont">
                <div className="subtitle">
                  Find exit opportunities among Devinsider's global community of
                  software vendors and institutional investors.
                </div>
                <ul>
                  <li>
                    <span className="icon-check ico"></span>Find prospective
                    buyers
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Global reach
                  </li>
                  <li>
                    <span className="icon-check ico"></span>Expand exit options
                  </li>
                </ul>
                <a className="btn-more" onClick={() => goToMergersAcquisitions()}>
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
