import React from "react";
import { Link } from "react-router-dom";
const AboutCommunity = () => {
  return (
    <>
      <h2 className="title">About the Community</h2>
      Join a global community made for software vendors.
      <br />
      If you don't already have an account, you can&nbsp;
      <Link to="/account-choices">sign up</Link>&#46;
    </>
  );
};

export default AboutCommunity;
