/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'react-bootstrap/Modal';
import icoFailed from '../../../../../resources/images/ban-solid.svg';

const PaymentErrorModal = (props: any) => {
  return (
    <>
      <Modal
        show={props?.showPaymentErrorModal}
        className='createCompanypopup medium forStep5custom'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Premium subscription</h2>
            <a
              className='close'
              onClick={() => {
                props?.setShowPaymentErrorModal(false);
                if (props.paymentMethod) {
                  props.setShowPurchaseForm(true);
                } else {
                  props.setShowCardRegistrationForm(true);
                }
              }}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
            <div className='payement_failed'>
              <div>
                <img src={icoFailed} alt='' />
              </div>
              <span className='txt mb-3'>Payment failed</span>
              <p className='mb-0 mt-3'>
                Your payment was not successfully processed.
              </p>
              <p className='mb-0'>Your transaction has been canceled.</p>
              <p>
                Please contact the bank that issued your credit card for more
                <br /> information on why the payment failed.{' '}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className='contentBorder text-center'>
            <button
              className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
              onClick={() => {
                props?.setShowPaymentErrorModal(false);
                if (props.paymentMethod) {
                  props.setShowPurchaseForm(true);
                } else {
                  props.setShowCardRegistrationForm(true);
                }
              }}
            >
              Try again
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentErrorModal;
