import React, { useState, FunctionComponent, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import Accordion from "react-bootstrap/Accordion";
import { InotificationSettings } from "./types/notification-types";
import { useHistory } from "react-router";
export const Notification: FunctionComponent<InotificationSettings> = ({
  id,
  devinsider_notifications,
  community_general_all_community_email,
  community_email_notifications_categories_labels_i_follow,
  community_email_notifications_by_insider_i_follow,
  community_email_notifications_i_participated_or_favorites,
  community_email_notifications_solutions_discussions_or_favorite,
  community_email_notifications_message_iam_mentioned_in,
  community_email_notifications_message_community_member,
  community_email_notifications_members_start_following_me,
  updateSettingsInfo,
  isLoadingRadio,
  marketplace_messages_i_have_received,
  marketplace_matchmaking_updates,
  marketplace_ads,
  corporate_development_messages_i_have_received,
  corporate_development_matchmaking_updates,
  corporate_development_ads,
}) => {
  const history = useHistory();

  let userObject = useSelector((state: rootState) => state.userReducer);

  const [activeId, setActiveId] = useState("0");

  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const goToManageFeeds = () => {
    history?.push("/manage-feed");
  };

  return (
    <div className="contentRightsettings">
      <h1 className="title">Notifications settings</h1>

      <Accordion className="accordionForSetting" defaultActiveKey={activeId}>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="0"
            className={`btnAccordion ${activeId === "0" ? "active" : ""}`}
            onClick={() => {
              toggleActive("0");
            }}
          >
            Devinsider
            <div className="explication">Manage devinsider notifications</div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="panel-body">
              <div className="titleSettings pt-3">Notify me of:</div>
              <ul className="listSettings pl-3">
                <li>
                  <h2 className="title withValue">
                    Communication that helps me to get the most of Devinsider
                    <div
                      className={`${
                        isLoadingRadio == "devinsider_notifications"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="radioa"
                          name="radioA"
                          value="radioa"
                          checked={devinsider_notifications}
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(true, "devinsider_notifications")
                          }
                        />
                        <label
                          htmlFor="radioa"
                          className="custom-control-label"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="radiob"
                          name="radioA"
                          value="radiob"
                          checked={!devinsider_notifications}
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "devinsider_notifications"
                            )
                          }
                        />
                        <label
                          htmlFor="radiob"
                          className="custom-control-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
              </ul>
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="1"
            onClick={() => {
              toggleActive("1");
            }}
            className={`btnAccordion ${activeId === "1" ? "active" : ""}`}
          >
            Community
            <div className="explication">Manage sommunity notifications</div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="panel-body">
              <div className="titleSettings">General settings</div>
              <ul className="listSettings pl-3">
                <li>
                  <h2 className="title withValue">
                    Turn off all community email
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_general_all_community_email"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes"
                          name="turnof"
                          value="yes"
                          checked={community_general_all_community_email}
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              true,
                              "community_general_all_community_email"
                            )
                          }
                        />
                        <label htmlFor="yes" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no"
                          name="turnof"
                          value="no"
                          checked={!community_general_all_community_email}
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "community_general_all_community_email"
                            )
                          }
                        />
                        <label htmlFor="no" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                  <div className="underh2">
                    This will turn off all Community email notification. You
                    will still receive notifications on devinsider.
                  </div>
                </li>
                <li onClick={() => goToManageFeeds()}>
                  <h2 className="title">
                    Manage categories and insiders I follow
                    <a href="javascript:;">Edit</a>
                  </h2>
                  <div className="underh2">
                    Controle what appeares in your dashboard feed.
                  </div>
                </li>
              </ul>
              <div className="titleSettings pt-3">Email notifications</div>
              <div className="titleSettings">Notify me of:</div>
              <ul className="listSettings pl-3">
                <li>
                  <h2 className="title withValue">
                    Discussions and articles posted under the categories or
                    labels I follow
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_categories_labels_i_follow"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="daily"
                          name="radio1"
                          value="daily"
                          checked={
                            community_email_notifications_categories_labels_i_follow ==
                            "Daily"
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              "Daily",
                              "community_email_notifications_categories_labels_i_follow"
                            )
                          }
                        />
                        <label htmlFor="daily" className="custom-control-label">
                          Daily
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="weekly"
                          name="radio1"
                          value="weekly"
                          checked={
                            community_email_notifications_categories_labels_i_follow ==
                            "Weekly"
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              "Weekly",
                              "community_email_notifications_categories_labels_i_follow"
                            )
                          }
                        />
                        <label
                          htmlFor="weekly"
                          className="custom-control-label"
                        >
                          Weekly
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="monthly"
                          name="radio1"
                          value="monthly"
                          checked={
                            community_email_notifications_categories_labels_i_follow ==
                            "Monthly"
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              "Monthly",
                              "community_email_notifications_categories_labels_i_follow"
                            )
                          }
                        />
                        <label
                          htmlFor="monthly"
                          className="custom-control-label"
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no1"
                          name="radio1"
                          value="no"
                          checked={
                            community_email_notifications_categories_labels_i_follow ==
                            "None"
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              "None",
                              "community_email_notifications_categories_labels_i_follow"
                            )
                          }
                        />
                        <label htmlFor="no1" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Discussions and articles posted by insiders I follow
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_by_insider_i_follow"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="daily1"
                          name="radio2"
                          value="daily1"
                          checked={
                            community_email_notifications_by_insider_i_follow ==
                            "Daily"
                          }
                          onChange={() =>
                            updateSettingsInfo(
                              "Daily",
                              "community_email_notifications_by_insider_i_follow"
                            )
                          }
                        />
                        <label
                          htmlFor="daily1"
                          className="custom-control-label"
                        >
                          Daily
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="weekly1"
                          name="radio2"
                          value="weekly1"
                          checked={
                            community_email_notifications_by_insider_i_follow ==
                            "Weekly"
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              "Weekly",
                              "community_email_notifications_by_insider_i_follow"
                            )
                          }
                        />
                        <label
                          htmlFor="weekly1"
                          className="custom-control-label"
                        >
                          Weekly
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="monthly1"
                          name="radio2"
                          value="monthly1"
                          checked={
                            community_email_notifications_by_insider_i_follow ==
                            "Monthly"
                          }
                          onChange={() =>
                            updateSettingsInfo(
                              "Monthly",
                              "community_email_notifications_by_insider_i_follow"
                            )
                          }
                        />
                        <label
                          htmlFor="monthly1"
                          className="custom-control-label"
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no2"
                          checked={
                            community_email_notifications_by_insider_i_follow ==
                            "None"
                          }
                          onChange={() =>
                            updateSettingsInfo(
                              "None",
                              "community_email_notifications_by_insider_i_follow"
                            )
                          }
                        />
                        <label htmlFor="no2" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Replies to discussions I participated in or checked as
                    favorite
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_i_participated_or_favorites"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div
                        className="custom-control custom-radio"
                        onClick={() =>
                          updateSettingsInfo(
                            true,
                            "community_email_notifications_i_participated_or_favorites"
                          )
                        }
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes3"
                          checked={
                            community_email_notifications_i_participated_or_favorites
                          }
                          onChange={() => null}
                        />
                        <label htmlFor="yes3" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div
                        className="custom-control custom-radio"
                        onClick={() =>
                          updateSettingsInfo(
                            false,
                            "community_email_notifications_i_participated_or_favorites"
                          )
                        }
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no3"
                          checked={
                            !community_email_notifications_i_participated_or_favorites
                          }
                          onChange={() => null}
                        />

                        <label htmlFor="no3" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Solutions to discussions I participated in or checked as
                    favorite
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_solutions_discussions_or_favorite"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes4"
                          name="radio4"
                          value="no"
                          checked={
                            community_email_notifications_solutions_discussions_or_favorite
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              true,
                              "community_email_notifications_solutions_discussions_or_favorite"
                            )
                          }
                        />
                        <label htmlFor="yes4" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no4"
                          name="radio4"
                          value="no"
                          checked={
                            !community_email_notifications_solutions_discussions_or_favorite
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "community_email_notifications_solutions_discussions_or_favorite"
                            )
                          }
                        />
                        <label htmlFor="no4" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Messages I'm @mentioned in
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_message_iam_mentioned_in"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes5"
                          name="radio5"
                          value="no"
                          checked={
                            community_email_notifications_message_iam_mentioned_in
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              true,
                              "community_email_notifications_message_iam_mentioned_in"
                            )
                          }
                        />
                        <label htmlFor="yes5" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no5"
                          name="radio5"
                          value="no"
                          checked={
                            !community_email_notifications_message_iam_mentioned_in
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "community_email_notifications_message_iam_mentioned_in"
                            )
                          }
                        />
                        <label htmlFor="no5" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Private messages sent by a community member
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_message_community_member"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes6"
                          name="radio6"
                          value="no"
                          checked={
                            community_email_notifications_message_community_member
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              true,
                              "community_email_notifications_message_community_member"
                            )
                          }
                        />
                        <label htmlFor="yes6" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no6"
                          name="radio6"
                          value="no"
                          checked={
                            !community_email_notifications_message_community_member
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "community_email_notifications_message_community_member"
                            )
                          }
                        />
                        <label htmlFor="no6" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
                <li>
                  <h2 className="title withValue">
                    Community members who start following me
                    <div
                      className={`${
                        isLoadingRadio ==
                        "community_email_notifications_members_start_following_me"
                          ? "loading itemEdit"
                          : "itemEdit"
                      }`}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="yes7"
                          name="radio7"
                          value="no"
                          checked={
                            community_email_notifications_members_start_following_me
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              true,
                              "community_email_notifications_members_start_following_me"
                            )
                          }
                        />
                        <label htmlFor="yes7" className="custom-control-label">
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="no7"
                          name="radio7"
                          value="no"
                          checked={
                            !community_email_notifications_members_start_following_me
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            updateSettingsInfo(
                              false,
                              "community_email_notifications_members_start_following_me"
                            )
                          }
                        />
                        <label htmlFor="no7" className="custom-control-label">
                          No
                        </label>
                      </div>
                    </div>
                  </h2>
                </li>
              </ul>
            </div>
          </Accordion.Collapse>
        </div>

        {/* USERS THAT HAVE THIS PARAMETERS => ISV, */}

        {/* {userObject.roles.indexOf("ROLE_ISV") !== -1 ? (
          <div className="lineAccordion">
            <Accordion.Toggle
              eventKey="2"
              onClick={() => {
                toggleActive("2");
              }}
              className={`btnAccordion ${activeId === "2" ? "active" : ""}`}
            >

              Marketplace
              <div className="explication">

                Manage marketplace notifications
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <div className="panel-body">
                <div className="titleSettings">Notify me of:</div>
                <ul className="listSettings pl-3">
                  <li>
                    <h2 className="title withValue">
                      Private messages sent by a community member
                      <div
                        className={`${
                          isLoadingRadio == "classified_ads_message_i_received"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="privatemessageyes"
                            name="privatemessage"
                            value="yes"
                            checked={classified_ads_message_i_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "classified_ads_message_i_received"
                              )
                            }
                          />
                          <label
                            htmlFor="privatemessageyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="privatemessageno"
                            name="privatemessage"
                            value="no"
                            checked={!classified_ads_message_i_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "classified_ads_message_i_received"
                              )
                            }
                          />
                          <label
                            htmlFor="privatemessageno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                </ul>
              </div>
            </Accordion.Collapse>
          </div>
        ) : (
          <></>
        )} */}

        {/* USERS THAT HAVE THIS PARAMETERS => ISV, */}
        {userObject.roles.indexOf("ROLE_ISV") !== -1 ? (
          <div className="lineAccordion d-none">
            <Accordion.Toggle
              eventKey="3"
              onClick={() => {
                toggleActive("3");
              }}
              className={`btnAccordion ${activeId === "3" ? "active" : ""}`}
            >
              {/* Find programs */}
              Marketplace
              <div className="explication">
                {/*Miaro ticket 57241 Manage software ecosystem notification*/}
                Manage marketplace notifications
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <div className="panel-body">
                <div className="titleSettings">Notify me of:</div>
                <ul className="listSettings pl-3">
                  {/* <li>
                    <h2 className="title withValue">
                      Messages I have received
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_programs_messages_i_have_received"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="messagereceivedyes"
                            name="messagereceived"
                            value="yes"
                            checked={find_programs_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_programs_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="messagereceivedyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="messagereceivedno"
                            name="messagereceived"
                            value="no"
                            checked={!find_programs_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_programs_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="messagereceivedno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li> */}
                  {/* <li>
                    <h2 className="title withValue">
                      New programs joining the platform
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_programs_new_programs_joining_platform"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="platformyes"
                            name="platform"
                            checked={
                              find_programs_new_programs_joining_platform
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_programs_new_programs_joining_platform"
                              )
                            }
                          />
                          <label
                            htmlFor="platformyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="platformno"
                            name="platform"
                            value="no"
                            checked={
                              !find_programs_new_programs_joining_platform
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_programs_new_programs_joining_platform"
                              )
                            }
                          />
                          <label
                            htmlFor="platformno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li> */}
                  {/* <li>
                    <h2 className="title withValue">
                      Program representatives that want to get in touch
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_programs_program_representatives_want_to_get_in_touch"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="getintouchyes"
                            name="getintouch"
                            value="yes"
                            checked={
                              find_programs_program_representatives_want_to_get_in_touch
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_programs_program_representatives_want_to_get_in_touch"
                              )
                            }
                          />
                          <label
                            htmlFor="getintouchyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="getintouchno"
                            name="getintouch"
                            value="no"
                            checked={
                              !find_programs_program_representatives_want_to_get_in_touch
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_programs_program_representatives_want_to_get_in_touch"
                              )
                            }
                          />
                          <label
                            htmlFor="getintouchno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li> */}
                  <li>
                    <h2 className="title withValue">
                      Messages I have received
                      <div
                        className={`${
                          isLoadingRadio ==
                          "marketplace_messages_i_have_received"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="messagereceivedyes"
                            name="messagereceived"
                            value="yes"
                            checked={marketplace_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "marketplace_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="messagereceivedyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="messagereceivedno"
                            name="messagereceived"
                            value="no"
                            checked={!marketplace_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "marketplace_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="messagereceivedno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Matchmaking updates
                      <div
                        className={`${
                          isLoadingRadio == "marketplace_matchmaking_updates"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="platformyes"
                            name="platform"
                            checked={marketplace_matchmaking_updates}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "marketplace_matchmaking_updates"
                              )
                            }
                          />
                          <label
                            htmlFor="platformyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="platformno"
                            name="platform"
                            value="no"
                            checked={!marketplace_matchmaking_updates}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "marketplace_matchmaking_updates"
                              )
                            }
                          />
                          <label
                            htmlFor="platformno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Marketplace ads
                      <div
                        className={`${
                          isLoadingRadio == "marketplace_ads"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="getintouchyes"
                            name="getintouch"
                            value="yes"
                            checked={marketplace_ads}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(true, "marketplace_ads")
                            }
                          />
                          <label
                            htmlFor="getintouchyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="getintouchno"
                            name="getintouch"
                            value="no"
                            checked={!marketplace_ads}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(false, "marketplace_ads")
                            }
                          />
                          <label
                            htmlFor="getintouchno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                </ul>
              </div>
            </Accordion.Collapse>
          </div>
        ) : (
          <></>
        )}

        {/* USERS THAT HAVE THIS PARAMETERS => ISV, */}
        {userObject.roles.indexOf("ROLE_ISV") !== -1 ? (
          <div className="lineAccordion d-none">
            <Accordion.Toggle
              eventKey="4"
              onClick={() => {
                toggleActive("4");
              }}
              className={`btnAccordion ${activeId === "4" ? "active" : ""}`}
            >
              Corporate development
              <div className="explication">
                Manage corporate development notifications
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <div className="panel-body">
                <div className="titleSettings">Notify me of:</div>
                <ul className="listSettings pl-3">
                  {/* <li>
                    <h2 className="title withValue">
                      New investors joining the platform
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_investor_new_investors_joining_platform"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorplatformyes"
                            name="investorplatform"
                            value="yes"
                            checked={
                              find_investor_new_investors_joining_platform
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_investor_new_investors_joining_platform"
                              )
                            }
                          />
                          <label
                            htmlFor="investorplatformyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorplatformno"
                            name="investorplatform"
                            value="no"
                            checked={
                              !find_investor_new_investors_joining_platform
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_investor_new_investors_joining_platform"
                              )
                            }
                          />
                          <label
                            htmlFor="investorplatformno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Investors that want to get in touch
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_investor_investor_that_want_to_get_in_touch"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorgetintouchyes"
                            name="investorgetintouch"
                            value="yes"
                            checked={
                              find_investor_investor_that_want_to_get_in_touch
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_investor_investor_that_want_to_get_in_touch"
                              )
                            }
                          />
                          <label
                            htmlFor="investorgetintouchyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorgetintouchno"
                            name="investorgetintouch"
                            value="no"
                            checked={
                              !find_investor_investor_that_want_to_get_in_touch
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_investor_investor_that_want_to_get_in_touch"
                              )
                            }
                          />
                          <label
                            htmlFor="investorgetintouchno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Message I have received
                      <div
                        className={`${
                          isLoadingRadio ==
                          "find_investor_messages_i_have_received"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investormessagereceivedyes"
                            name="investormessagereceived"
                            value="yes"
                            checked={find_investor_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "find_investor_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="investormessagereceivedyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investormessagereceivedno"
                            name="investormessagereceived"
                            value="no"
                            checked={!find_investor_messages_i_have_received}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_investor_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="investormessagereceivedno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li> */}
                  <li>
                    <h2 className="title withValue">
                      Message I have received
                      <div
                        className={`${
                          isLoadingRadio ==
                          "corporate_development_messages_i_have_received"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investormessagereceivedyes"
                            name="investormessagereceived"
                            value="yes"
                            checked={
                              corporate_development_messages_i_have_received
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "corporate_development_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="investormessagereceivedyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investormessagereceivedno"
                            name="investormessagereceived"
                            value="no"
                            checked={
                              !corporate_development_messages_i_have_received
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "corporate_development_messages_i_have_received"
                              )
                            }
                          />
                          <label
                            htmlFor="investormessagereceivedno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Matchmaking updates
                      <div
                        className={`${
                          isLoadingRadio ==
                          "corporate_development_matchmaking_updates"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorplatformyes"
                            name="investorplatform"
                            value="yes"
                            checked={corporate_development_matchmaking_updates}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "corporate_development_matchmaking_updates"
                              )
                            }
                          />
                          <label
                            htmlFor="investorplatformyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorplatformno"
                            name="investorplatform"
                            value="no"
                            checked={
                              !corporate_development_matchmaking_updates
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "find_investor_new_investors_joining_platform"
                              )
                            }
                          />
                          <label
                            htmlFor="investorplatformno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                  <li>
                    <h2 className="title withValue">
                      Corporate development ads
                      <div
                        className={`${
                          isLoadingRadio == "corporate_development_ads"
                            ? "loading itemEdit"
                            : "itemEdit"
                        }`}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorgetintouchyes"
                            name="investorgetintouch"
                            value="yes"
                            checked={corporate_development_ads}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                true,
                                "corporate_development_ads"
                              )
                            }
                          />
                          <label
                            htmlFor="investorgetintouchyes"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="investorgetintouchno"
                            name="investorgetintouch"
                            value="no"
                            checked={
                              !corporate_development_matchmaking_updates
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              updateSettingsInfo(
                                false,
                                "corporate_development_matchmaking_updates"
                              )
                            }
                          />
                          <label
                            htmlFor="investorgetintouchno"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </h2>
                  </li>
                </ul>
              </div>
            </Accordion.Collapse>
          </div>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};
