import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getDataMicroService } from "../../../../service/applicatif/back-end-service";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  url,
} from "../../../../service/constant";
import { ITransaction } from "./transaction-type";
import { toast } from "react-toastify";

type IProps = {
  tabType: string;
};
export default function UseTransactionHistory(props: IProps) {
  const [loaderTransaction, setLoaderTransaction] = useState<boolean>(false);
  const companyIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [loaderGet, setLoaderGet] = useState<boolean>(false);
  const investorPageObject = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const resellerCompanyReducer = useSelector(
    (state: rootState) => state.userReducer?.resellerCompanies
  );

  const guestCompanyReducer = useSelector(
    (state: rootState) => state.userReducer?.guestCompany
  );

  const expertCompanyReducer = useSelector(
    (state: rootState) => state?.userReducer?.expertCompany
  );

  const userObject = useSelector((state: rootState) => state.userReducer);

  const userType = userObject.roles?.filter((el) => el !== "USER_ROLE")?.[0];

  const low = moment().subtract(1, "months").format("MMMM DD, YYYY");
  const dateNow = moment().format("MMMM DD, YYYY");
  const [lowDate, setStartDate] = useState<Date>(new Date(low));
  const [endDate, setEndDate] = useState<Date>(new Date(dateNow));
  const [triDate, setTriDate] = useState<"" | "day" | "week" | "month">("");

  let companyId: string =
    userType === ISV
      ? companyIsv.id
      : userType === OEM_PROGRAM
      ? oemProgramObject.id
      : userType === INVESTOR
      ? investorPageObject.id
      : userType === GUEST
      ? guestCompanyReducer.id
      : userType === CONTRIBUTOR
      ? expertCompanyReducer.id
      : resellerCompanyReducer?.[0]?.id;

  const [transaction, setTransaction] = useState<{
    items: Array<ITransaction>;
    meta: {
      nb_item: number;
      pages: number;
      pagination: number;
      total_count: number;
    };
  }>();

  const getTransaction = async (p?: {
    // ...paramsWs,
    begin: string;
    end: string;
    page: number;
    pageSize: number;
    // group: triDate,
  }) => {
    setLoaderTransaction(true);
    getDataMicroService(
      url.microservice.payment.get_user_transactions +
        (p
          ? "?page=" +
            p.page +
            "&page_size=" +
            p.pageSize +
            "&start_date=" +
            p.begin +
            "&end_date=" +
            p.end
          : ""),
      userType === OEM_PROGRAM ? { oem_program_id: companyId } : {},
      userObject.token
    )
      .then((response) => {
        if (response.data.message === "success") {
          setTransaction({
            items: response.data.data.transactions,
            meta: response.data.data.meta,
          });
        } else {
          throw response.data.data.detail;
        }
      })
      .catch((error) => {
        console.log(error);
        toast("Error getting receipts");
      })
      .finally(() => {
        setLoaderTransaction(false);
      });
  };

  useEffect(() => {
    async function getTransactionInfo() {
      setLoaderTransaction(true);
      await getTransaction({
        begin: moment(lowDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
        page: 1,
        pageSize: 5,
      });
    }

    props?.tabType === "history_list" && getTransactionInfo();
    return () => {};
  }, [props?.tabType, lowDate, endDate, triDate]);

  const handlePageClick = async (page: number) => {
    await getTransaction({
      begin: moment(lowDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD"),
      page: page,
      pageSize: 5,
    });
  };

  return {
    transaction,
    handlePageClick,
    loaderGet,
    lowDate,
    endDate,
    setStartDate,
    setEndDate,
    triDate,
    setTriDate,
    userObject,
    loaderTransaction,
  };
}
