import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import {
  GUEST,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from "../../service/constant";
import DefaultCompany from "../../resources/images/defaultCompany.png";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useHistory, useParams } from "react-router-dom";
import {
  createAlternative,
  getIndustries,
  getSolutions,
  updateAlternative,
} from "../../service/applicatif/alternative-service";
import UseIndustryList from "../../screen/widget/WidgetSelected/IndustryListe/UseIndustryList";
import UseCompleteCategoryInvestor from "../../screen/widget/WidgetSelected/SolutionList/use-SF";
import { toast } from "react-toastify";
import { getDetailAlternative } from "../../redux/actions/alternative-financing-action";
import { getLooUpKeyPostMA, isExist } from "../../utils";

export default function PostAlternativeController() {
  const params: { id?: string | undefined } = useParams();
  const isEdit = isExist(params?.id);
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: rootState) => state.userReducer);
  const isModerator = user?.roles?.indexOf(MODERATOR) !== -1;
  const isOem = user?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const oemProgramReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer.program
  );
  const [displayAuthorName, setDisplayAuthorName] = useState<boolean>(true);
  const [displayCompanyName, setDisplayCompanyName] = useState<boolean>(
    isModerator ? false : true
  );

  const [isErrorSolution, setIsErrorSolution] = useState(false);
  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [selectedSolution, setSelectedSolution] = useState<Array<any>>([]);
  const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<
    Array<any>
  >([]);
  const [segmentsList, setSegmentsList] = useState<Array<any>>([]);

  const [isErrorIndustry, setIsErrorIndustry] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState<Array<any>>([]);
  const [showIndustry, setShowIndustry] = useState<boolean>(false);
  const [industriesList, setIndustriesList] = useState<Array<any>>([]);

  const [isErrorObject, setIsErrorObject] = useState(false);
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [title, setTitle] = useState<string>("");
  const [loader, setLoader] = useState(false);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const [selectFunding, setSelectFunding] = useState<any>("{}");

  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>({
    lookupKey: getLooUpKeyPostMA(user?.roles[0]),
    name: "Post alternative financing",
  });
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const companyPageObject = useSelector((state: rootState) => {
    let userRole = user?.roles?.[0];
    if (userRole === ISV)
      return {
        photo_profil: state.companyPageReducer.photo_profil,
        company_name: state.companyPageReducer.company_name,
      };
    if (userRole === OEM_PROGRAM)
      return {
        photo_profil: state.oemProgramReducer.program.photo_profil,
        company_name: state.oemProgramReducer.program.program_name,
      };
    if (userRole === INVESTOR)
      return {
        photo_profil: state.companyInvestorPageReducer.photo_profil,
        company_name: state.companyInvestorPageReducer.organization_name,
      };
    if (userRole === RESELLER)
      return {
        photo_profil: state.resellerCompanyReducer.photo_profil,
        company_name: state.resellerCompanyReducer.company_legal_name,
      };
    if (userRole === GUEST)
      return {
        photo_profil: state.userReducer.guestCompany.logo,
        company_name: state.userReducer.company_name,
      };
    if (userRole === MODERATOR)
      return {
        photo_profil: DefaultCompany,
        company_name: "Company confidential",
      };

    return { photo_profil: DefaultCompany, company_name: "" };
  });

  const companyPageIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const alternativeReducer = useSelector(
    (state: rootState) => state?.alternativeReducer?.alternative
  );

  function currentFundingTarget(min: any, max: any) {
    if (
      dataCompleted.funding_target &&
      dataCompleted.funding_target.length > 0
    ) {
      for (const target of dataCompleted.funding_target) {
        const keys = Object.keys(target);
        if (keys.length === 1) {
          const rangeKey = keys[0];
          const rangeValue = target[rangeKey];
          if (rangeValue.min === min && rangeValue.max === max) {
            return target;
          }
        }
      }
    }
  }

  useEffect(() => {
    const getListSolutions = async () => {
      let data = await getSolutions();
      setSegmentsList(data || []);
    };
    const getListIndustry = async () => {
      let data = await getIndustries();
      setIndustriesList(data || []);
    };
    getListSolutions();
    getListIndustry();
  }, []);

  useEffect(() => {
    setIsErrorObject(false);
  }, [title]);

  useEffect(() => {
    setIsErrorIndustry(false);
  }, [selectedIndustry]);

  useEffect(() => {
    setIsErrorSolution(false);
  }, [selectedFeaturedSolution, selectedSolution]);

  const callback = (data: any) => {};
  const getDetail = () => {
    dispatch(getDetailAlternative(params.id || 0, callback));
  };

  useEffect(() => {
    if (params.id) {
      getDetail();
    }
  }, [params.id]);

  useEffect(() => {
    if (alternativeReducer && params.id) {
      const makeIndustry = (alternativeReducer?.targeted_industry || [])?.map(
        (el) => {
          if (el.other) {
            return {
              ...el,
              id: -1,
            };
          }
          return el;
        }
      );
      const makeSolution = (alternativeReducer?.software_solution || [])?.map(
        (el) => {
          if (el.other) {
            return {
              ...el,
              id: -1,
            };
          }
          return el;
        }
      );

      const makeFeatured = (alternativeReducer?.featured_solution || [])?.map(
        (el) => {
          return el;
        }
      );
      setTitle(alternativeReducer?.title || "");
      setDisplayAuthorName(alternativeReducer?.display_author_name);
      setDisplayCompanyName(alternativeReducer?.display_company_name);
      setSelectedSolution(makeSolution);
      setSelectedFeaturedSolution(makeFeatured);
      setSelectedIndustry(makeIndustry);
      setEditorState(
        EditorState?.createWithContent(
          convertFromRaw(JSON.parse(alternativeReducer?.content))
        )
      );
      setSelectFunding(
        JSON.stringify(
          currentFundingTarget(
            alternativeReducer?.annual_raise_min,
            alternativeReducer?.annual_raise_max
          )
        )
      );
    }
  }, [alternativeReducer, params.id]);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault,
    otherValue: otherValueIndustry,
    setOtherValueTxt: setOtherValueTxtIndustry,
  } = UseIndustryList({
    defaultSelected: selectedIndustry,
    defaultDataCompleted: industriesList || [],
  });

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    otherValue,
    setOtherValueTxt,
    backToDefault: backToDefaultSoftwareSolution,
  } = UseCompleteCategoryInvestor({
    defaultValue: selectedSolution,
    defaultValueFeatured: selectedFeaturedSolution,
    solutionCategoryList: segmentsList,
  });

  const cancelPost = () => {
    history.goBack();
  };

  // const cb = (success: boolean) => {
  //   setLoader(false);
  //   if(success) {
  //     toast('successfully  published');
  //     history.push('/alternative-financing/home');
  //   } else {
  //     toast('An error occured');
  //     history.push('/alternative-financing/home');
  //   }
  // }

  const cab = (success: boolean) => {
    setLoader(false);
    if (success) {
      toast("successfully  updated");
      history.push("/alternative-financing/ads/" + params?.id);
    } else {
      toast("An error occured");
      history.push("/alternative-financing/ads/" + params?.id);
    }
  };

  const publishAlternative = () => {
    const selectedTargetIndustry = selectedIndustry?.map((el) => el.id);
    const selectedSolutionChecked = selectedSolution?.map((el) => el.id);
    const selectedFeatured = selectedFeaturedSolution?.map((el) => el.id);
    const otherValueSolution = selectedSolution?.find((el) => el.id === -1);
    const otherValueIndustry = selectedIndustry?.find((el) => el.id === -1);
    let tempFunding = JSON.parse(selectFunding || "{}");
    let objectTemp: any = Object.values(tempFunding)[0];
    setLoader(true);
    let data = {
      title: title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      display_author_name: displayAuthorName ? 1 : 0,
      display_company_name: displayCompanyName ? 1 : 0,
      software_solution: selectedSolutionChecked,
      featured_solution: selectedFeatured,
      targeted_industry: selectedTargetIndustry,
      other_industry: otherValueIndustry ? [otherValueIndustry.name] : [],
      other_solution: otherValueSolution ? [otherValueSolution.name] : [],
      annual_raise_min: objectTemp?.min,
      annual_raise_max: objectTemp?.max,
      oem_program_id: isOem ? oemProgramReducer.id : "",
    };

    if (isEdit) {
      updateAlternative(data, params?.id, cab);
    } else {
      setShowPaymentModal(true);
      // createAlternative(data, cb);
    }
  };

  const saveAlternative = () => {
    if (title.length < 1) {
      setIsErrorObject(true);
      return;
    }
    if (selectedIndustry.length < 1) {
      setIsErrorIndustry(true);
      return;
    }
    if (selectedFeaturedSolution.length < 1 && selectedSolution.length < 1) {
      setIsErrorSolution(true);
      return;
    }
    publishAlternative();
  };

  const cancelTargeted = () => {
    backToDefault();
    setShowIndustry(!showIndustry);
  };

  const validTargeted = () => {
    const updatedOtherValue = companyTargetSelected.map((el) => {
      if (el.id === -1) {
        return {
          ...el,
          name: otherValueIndustry || "",
        };
      } else {
        return el;
      }
    });
    setSelectedIndustry(updatedOtherValue);
    setShowIndustry(!showIndustry);
  };

  const cancelSolution = () => {
    backToDefaultSoftwareSolution();
    setShowSolution(!showSolution);
  };

  const validSolution = () => {
    const updatedOtherValue =
      solutionChecked &&
      solutionChecked.map((el) => {
        if (el.id === -1) {
          return {
            ...el,
            name: otherValue || "",
          };
        } else {
          return el;
        }
      });
    setSelectedSolution(updatedOtherValue);
    setSelectedFeaturedSolution(solutionCheckedFeatures);
    setShowSolution(!showSolution);
  };

  const publishAlternativeFinancing = async (
    pid: string | undefined,
    choiseValue: string | undefined,
    savePaymentMethod?: boolean
  ) => {
    const selectedTargetIndustry = selectedIndustry?.map((el) => el.id);
    const selectedSolutionChecked = selectedSolution?.map((el) => el.id);
    const selectedFeatured = selectedFeaturedSolution?.map((el) => el.id);
    const otherValueSolution = selectedSolution?.find((el) => el.id === -1);
    const otherValueIndustry = selectedIndustry?.find((el) => el.id === -1);
    let tempFunding = JSON.parse(selectFunding || "{}");
    let objectTemp: any = Object.values(tempFunding)[0];
    setLoader(true);
    let data = {
      title: title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      display_author_name: displayAuthorName ? 1 : 0,
      display_company_name: displayCompanyName ? 1 : 0,
      software_solution: selectedSolutionChecked,
      featured_solution: selectedFeatured,
      targeted_industry: selectedTargetIndustry,
      other_industry: otherValueIndustry ? [otherValueIndustry.name] : [],
      other_solution: otherValueSolution ? [otherValueSolution.name] : [],
      annual_raise_min: objectTemp?.min,
      annual_raise_max: objectTemp?.max,
      oem_program_id: isOem ? oemProgramReducer.id : "",
      paymentId: pid || "",
      choice: choiseValue,
      savePaymentMethod: savePaymentMethod || false,
    };
    const resp = createAlternative(data);

    return resp;
  };

  const handleStatusOk = () => {
    history.push("/alternative-financing/home");
  };

  const paymentMethodCallBack = async (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod: boolean | undefined,
    setShowPaymentStatus: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    const choiseValue = isFree ? "FREE" : "PAY";
    const respPostMa: any = await publishAlternativeFinancing(
      paymentMethodId || "",
      choiseValue,
      savePaymentMethod || false
    );
    if (!respPostMa?.data?.free_mode) {
      setLoader(false);
      setShowPaymentStatus(true, {
        status: respPostMa?.data?.success ? "success" : "error",
        invoiceNumber: respPostMa?.data?.invoice_number,
        invoiceUrl: respPostMa?.data?.invoice_url,
      });
    } else {
      setLoader(false);
      if (respPostMa?.data?.success) {
        toast("successfully  published");
        history.push("/alternative-financing/home");
      } else {
        toast("An error occured");
        history.push("/alternative-financing/home");
      }
    }

    console.log("respPostAlternative", respPostMa);
  };

  return {
    displayAuthorName,
    displayCompanyName,
    setDisplayAuthorName,
    setDisplayCompanyName,
    user,
    isModerator,
    companyPageObject,
    companyPageIsv,
    isErrorSolution,
    showSolution,
    setShowSolution,
    selectedSolution,
    selectedFeaturedSolution,
    setSelectedFeaturedSolution,
    setSelectedSolution,
    isErrorIndustry,
    selectedIndustry,
    setSelectedIndustry,
    showIndustry,
    setShowIndustry,
    isErrorObject,
    mentions,
    editorState,
    setEditorState,
    cancelPost,
    title,
    setTitle,
    saveAlternative,
    cancelTargeted,
    validTargeted,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    companyTargetSelected,
    lastCompanyTarget,
    otherValueIndustry,
    setOtherValueTxtIndustry,
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    otherValue,
    setOtherValueTxt,
    cancelSolution,
    validSolution,
    dataCompleted,
    setSelectFunding,
    loader,
    selectFunding,
    product,
    setProduct,
    setShowPaymentModal,
    showPaymentModal,
    paymentMethodCallBack,
    handleStatusOk,
    isEdit,
  };
}
