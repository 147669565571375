import { LoadingPrograms } from '../../../widget/loader';
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  getGMTOffset,
  getTimeZone,
  handleDateEvent,
  limitedText,
} from '../../../../utils';
import { IEvent } from '../../../../redux/types/event-interface';
import Pagination from '../../../widget/use-pagination/pagination';
import { createRef, useState } from 'react';

const BlocEvent = (props: any) => {

  const [idToSHowMore, setIdToSHowMore] = useState<number>(0);
  let buttonRefs: any = [];

  function moreInfoEvent(element: any, id: number) {
    const elementLi: any = document.getElementById(
      element.target.getAttribute('data-id')
    );
    if (elementLi.classList.contains('open')) {
      elementLi.classList.remove('open');
      element.target.innerHTML = 'Show more';
      setIdToSHowMore(0);
    } else {
      setIdToSHowMore(id);
      elementLi.classList.add('open');
      element.target.innerHTML = 'Show less';
    }
  }

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains('open')) {
      elementH3.classList.remove('open');
    } else {
      elementH3.classList.add('open');
    }
  }

  const handleShowMore = (index: number) => {
    if (buttonRefs) {
      if (buttonRefs[index].current) {
        buttonRefs?.[index]?.current?.click();
      }
    }
  };

  return (
    <div className='container mx-auto minHeightwin'>
      <div className='listOfIsv'>
        <div className='headerOfList'>
          <div className='leftHead'>
            <div className='number'>
              Showing <b>{props?.eventObject?.events?.length || '0'}</b>{' '}
              Upcoming events
            </div>
            <form onClick={(e) => openBlock(e)}>
              <input
                placeholder='Search...'
                type='text'
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={props?.search}
                onChange={(e) => props?.setSearch(e?.target?.value)}
                onKeyPress={props?.handleSearch}
              />
            </form>
          </div>
        </div>
        <div className='scrollContainer'>
          {props?.loader ? (
            <LoadingPrograms />
          ) : (
            <ul className='listOfIsvMatch listEvents'>
              {props?.eventObject?.events?.map((el: IEvent, key: number) => {
                const newRef = createRef();
                buttonRefs.push(newRef);
                return (
                  <li id={'li' + key} key={"li" + key}>
                    <div className="isvLogo">
                      <img src={fileUrl(el?.logo)} alt="" />
                      <div className="descAds">
                        <h6>
                          {el.id === idToSHowMore
                            ? el?.company_name
                            : limitedText(el?.company_name, 20)}
                        </h6>
                      </div>
                    </div>
                    <div className='isvInfo'>
                      <div className='infoUnlocked'>
                        <div className='calendar'>
                          <div className='item'>
                            <div className='date'>
                              <div className='cal'>
                                <span className='month'>
                                  {el?.at_date?.split(' ')[0]}
                                </span>
                                <span className='day'>
                                  {el?.at_date?.split(' ')[1]}
                                </span>
                              </div>
                              <div className='eventInfos' onClick={() => handleShowMore(key)}>
                                {limitedText(el?.title, 50)}
                                <div className='infoEvent'>
                                  <div className='dateEvent'>
                                    {handleDateEvent(el?.at_time) +
                                      ' ' +
                                      getGMTOffset(el?.timezone)}
                                  </div>
                                  <div className='placeEvent'>
                                    {el?.event_type?.name}
                                  </div>
                                  <div className='hourEvent'>
                                    ~ {el?.duration}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='txtLabel'>
                          {el?.event_tags?.map((tag, index) => (
                            <span
                              className='topic'
                              key={index}
                              onClick={() => props?.handleTag(parseInt(tag.id))}
                            >
                              {tag?.title}
                            </span>
                          ))}
                        </div>
                        <div className='infoEventContainer'>
                          <div
                            className="detailsAds"
                            dangerouslySetInnerHTML={createMarkup(
                              convertCommentFromJSONToHTML(
                                getDescriptionEditorState(el?.presentation_text)
                              )
                            )}
                          />
                        </div>
                      </div>
                      <div className='actionUnlocked'>
                        <div className='btnContent'>
                          <button
                            className='btn btn-devinsider'
                            onClick={() => window.open(el?.register, '_blank')}
                          >
                            Register
                          </button>
                          <button
                            data-id={'li' + key}
                            className='btn btn-devinsider view'
                            onClick={(e) => {
                              moreInfoEvent(e, el.id);
                            }}
                          >
                            Show more
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <div className='pagginationCategory fixePagination'>
          <Pagination
            onPageChange={props?.handlePageChange}
            totalCount={props?.eventObject?.total}
            pageSize={props?.limit}
            className='pagination'
            currentPage={props?.currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BlocEvent;
