/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import ModalPermission from "../../widget/permission-oem/index";
import { IListTarget } from "../industries-list/type";
import RegionCountry from "../pays/index";
import AnnualTurnover from "../slider-annual-turnover/index";
import CompanySizePopup from "../slider-company-size-slider/index";
import SofTwareSolution from "../software-solution/index";
import { IPropsEditSettingPage } from "./type";

export default function EditSettingPage(props: IPropsEditSettingPage) {
  const [activeId, setActiveId] = useState("");
  const [companySizeChanged, setCompanySizeChanged] = useState(false);
  const [annualTurnoverChanged, setAnnualTurnoverChanged] = useState(false);
  const [countriesChanged, setCountriesChanged] = useState(false);
  const [solutionsChanged, setSolutionsChanged] = useState(false);
  const [featuredChanged, setFeaturedChanged] = useState(false);

  const [isvSolutionsChanged, setIsvSolutionsChanged] = useState(false);

  const [companyTargetChanged, setCompanyTargetChanged] = useState(false);

  const [initialTargetSelected, setInitialTargetSelected] = useState<{
    initialised: boolean;
    data: IListTarget[];
  }>({
    initialised: false,
    data: [],
  });

  const [initialCountriesSelected, setInitialCountriesSelected] = useState<{
    initialised: boolean;
    data: any;
  }>({
    initialised: false,
    data: [],
  });

  const [initialSolutionsSelected, setInitialSolutionsSelected] = useState<{
    initialised: boolean;
    data: Array<{ id: number; name: string }>;
  }>({
    initialised: false,
    data: [],
  });

  const [initialFeaturedSelected, setInitialFeaturedSelected] = useState<{
    initialised: boolean;
    data: Array<{ id: number; name: string }>;
  }>({
    initialised: false,
    data: [],
  });

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("");
    } else {
      setActiveId(id);
    }
  }

  useEffect(() => {
    initializeData(
      props.companyTargetSelected,
      initialTargetSelected,
      setInitialTargetSelected
    );
  }, [props.companyTargetSelected, initialTargetSelected]);

  useEffect(() => {
    checkIfEqual(
      initialTargetSelected,
      props?.companyTargetSelected,
      setCompanyTargetChanged,
      "id"
    );
  }, [props?.companyTargetSelected, initialTargetSelected]);

  useEffect(() => {
    initializeData(
      props.paysChecked,
      initialCountriesSelected,
      setInitialCountriesSelected
    );
  }, [props.paysChecked, initialCountriesSelected]);

  useEffect(() => {
    checkIfEqual(
      initialCountriesSelected,
      props?.paysChecked,
      setCountriesChanged,
      "countrId"
    );
  }, [props?.paysChecked, initialCountriesSelected]);

  useEffect(() => {
    initializeData(
      props.solutionChecked,
      initialSolutionsSelected,
      setInitialSolutionsSelected
    );
  }, [props.solutionChecked, initialSolutionsSelected]);

  useEffect(() => {
    initializeData(
      props.solutionCheckedFeatures,
      initialFeaturedSelected,
      setInitialFeaturedSelected
    );
  }, [props.solutionCheckedFeatures, initialFeaturedSelected]);

  useEffect(() => {
    checkIfEqual(
      initialFeaturedSelected,
      props?.solutionCheckedFeatures,
      setFeaturedChanged,
      "id"
    );
  }, [props?.solutionCheckedFeatures, initialFeaturedSelected]);

  useEffect(() => {
    initializeData(
      props.solutionChecked,
      initialSolutionsSelected,
      setInitialSolutionsSelected
    );
  }, [props.solutionChecked, initialSolutionsSelected]);

  useEffect(() => {
    checkIfEqual(
      initialSolutionsSelected,
      props?.solutionChecked,
      setSolutionsChanged,
      "id"
    );
  }, [props?.solutionChecked, initialSolutionsSelected]);

  useEffect(() => {
    if (solutionsChanged || featuredChanged) setIsvSolutionsChanged(true);
    else setIsvSolutionsChanged(false);
  }, [solutionsChanged, featuredChanged]);

  const checkIfEqual = (
    initialData: any,
    actualData: any,
    setter: any,
    property: string
  ) => {
    if (!initialData.initialised) return;
    if (initialData.data.length !== actualData.length) setter(true);
    else {
      const sameSolutions = initialData.data.every((item1: any) =>
        actualData.some(
          (item2: any) =>
            item1[property].toString() == item2[property].toString()
        )
      );
      setter(!sameSolutions);
    }
  };

  const initializeData = (
    dataSelected: any,
    initializedData: any,
    setter: any
  ) => {
    if (
      dataSelected &&
      dataSelected.length > 0 &&
      !initializedData.initialised
    ) {
      setter({
        initialised: true,
        data: dataSelected.length == 0 ? [] : dataSelected,
      });
    }
  };

  useEffect(() => {
    if (!props?.isShowModal) {
      setCompanySizeChanged(false);
      setAnnualTurnoverChanged(false);
      setCountriesChanged(false);
      setIsvSolutionsChanged(false);
      setSolutionsChanged(false);
      setFeaturedChanged(false);
      setCompanyTargetChanged(false);
    }
  }, [props?.isShowModal]);

  useEffect(() => {
    if (!props?.keyUpdate) {
      setCompanySizeChanged(false);
      setAnnualTurnoverChanged(false);
      setCountriesChanged(false);
      setIsvSolutionsChanged(false);
      setSolutionsChanged(false);
      setFeaturedChanged(false);
      setCompanyTargetChanged(false);
    }
  }, [props?.keyUpdate]);

  const maxSliderValue =
    props?.companySizeSlide[1] === 10
      ? "3000+ "
      : props?.companySizeSlide[1]?.toString() === "3200"
      ? "3000+ "
      : props?.companySizeSlide[1];

  return (
    <>
      <Modal show={props?.isShowModal} className="createCompanypopup large ">
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Program targeting</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => {
                setActiveId("");
                props.cancelUpdate(true);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Accordion className="accordionForSetting settingPageAccordion">
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="1"
                  className={`btnAccordion ${activeId === "1" ? "active" : ""}`}
                  onClick={() => toggleActive("1")}
                >
                  Company size:
                  <div className="explication">
                    {props?.companySizeSlide?.[0]} to {maxSliderValue} employees
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <CompanySizePopup
                      companySize={props?.companySize}
                      companySizeSlide={props?.companySizeSlide}
                      setCompanySize={(evt: Array<number>) => {
                        props?.setCompanySize(evt);
                      }}
                      customSetCompanySize={(min: number, max: number) => {
                        props?.customSetCompanySize(min, max);
                      }}
                      setCompanySizeChanged={setCompanySizeChanged}
                    />
                    {(companySizeChanged ||
                      (props?.loader &&
                        props?.keyUpdate === "company_size")) && (
                      <div className="contentBorder formForEmail text-left">
                        <button
                          className="btn btn-devinsider cancel"
                          onClick={() => {
                            setActiveId("");
                            props?.cancelUpdate(false);
                            setCompanySizeChanged(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-devinsider px-5"
                          onClick={() => {
                            if (props?.validateEditMatching()) {
                              props?.validateUpdate("company_size");
                            }
                          }}
                        >
                          {props?.loader &&
                          props?.keyUpdate === "company_size" ? (
                            <Loader />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="2"
                  className={`btnAccordion ${activeId === "2" ? "active" : ""}`}
                  onClick={() => toggleActive("2")}
                >
                  Annual revenue:
                  <div className="explication">
                    {props?.annualTurnoverSlide?.[0]}
                    {props?.unitTurnover} to{" "}
                    {props?.annualTurnoverSlide?.[1] === 600
                      ? ">500"
                      : props?.annualTurnoverSlide?.[1]}
                    {props?.unitTurnoverMax}{" "}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <AnnualTurnover
                      annualTurnoverSlide={props?.annualTurnoverSlide}
                      annualTurnover={props?.annualTurnover}
                      unitTurnover={props?.unitTurnover}
                      unitTurnoverMax={props?.unitTurnoverMax}
                      setAnnualTurnover={(evt: Array<number>) => {
                        props?.setAnnualTurnover(evt);
                      }}
                      customSetAnnualSize={(min: number, max: number) =>
                        props?.customSetAnnualSize(min, max)
                      }
                      setAnnualTurnoverChanged={setAnnualTurnoverChanged}
                    />
                    {(annualTurnoverChanged ||
                      (props?.loader && props?.keyUpdate === "annual")) && (
                      <div className="contentBorder formForEmail text-left">
                        <button
                          className="btn btn-devinsider cancel"
                          onClick={() => {
                            setActiveId("");
                            props?.cancelUpdate(false);
                            setAnnualTurnoverChanged(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-devinsider px-5"
                          onClick={() => {
                            if (props?.validateEditMatching()) {
                              props?.validateUpdate("annual");
                            }
                          }}
                        >
                          {props?.loader && props?.keyUpdate === "annual" ? (
                            <Loader />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </Accordion.Collapse>
              </div>

              {/* Pays */}
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="3"
                  className={`btnAccordion ${activeId === "3" ? "active" : ""}`}
                  onClick={() => toggleActive("3")}
                >
                  Region(s) / Country(ies):
                  <div className="explication">
                    {props?.paysChecked?.length} countries selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <RegionCountry
                      addPaysInArray={props.addPaysInArray}
                      allContinentSelected={props.allContinentSelected}
                      allContinents={props.allContinents}
                      paysChecked={props.paysChecked}
                    />
                    {props?.paysChecked.length > 0 &&
                      (countriesChanged ||
                        (props?.loader && props?.keyUpdate === "country")) && (
                        <div className="contentBorder formForEmail text-left">
                          <button
                            className="btn btn-devinsider cancel"
                            onClick={() => {
                              setActiveId("");
                              props?.cancelUpdate(false);
                              setCountriesChanged(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-devinsider px-5"
                            onClick={() => {
                              if (props?.validateEditMatching()) {
                                props?.validateUpdate("country");
                                setInitialCountriesSelected({
                                  ...initialCountriesSelected,
                                  data: props.paysChecked,
                                });
                              }
                            }}
                          >
                            {props?.loader && props?.keyUpdate === "country" ? (
                              <Loader />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      )}
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="4"
                  className={`btnAccordion ${activeId === "4" ? "active" : ""}`}
                  onClick={() => toggleActive("4")}
                >
                  What ISV solution Category(ies) are you interested in ? :
                  <div className="explication">
                    {props?.solutionChecked.length +
                      props?.solutionCheckedFeatures.length +
                      " "}
                    solutions selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <div>
                    <SofTwareSolution
                      solutionChecked={props?.solutionChecked}
                      addSolutionCheched={(p: { id: number; name: string }) =>
                        props?.addSolutionCheched(p)
                      }
                      addSolutionChechedFeatured={(p: {
                        id: number;
                        name: string;
                      }) => props?.addSolutionChechedFeatured(p)}
                      featuredSolution={props?.featuredSolution}
                      solutionCheckedFeatures={props?.solutionCheckedFeatures}
                      solutionLeftColumnData={props?.solutionLeftColumnData}
                      solutionRigthColumnData={props?.solutionRigthColumnData}
                      removeItemSelected={props?.removeItemSelected}
                    />
                    {(props?.solutionChecked.length > 0 ||
                      props?.solutionCheckedFeatures.length > 0) &&
                      (isvSolutionsChanged ||
                        (props?.loader && props?.keyUpdate === "segments")) && (
                        <div className="contentBorder formForEmail text-left">
                          <button
                            className="btn btn-devinsider cancel"
                            onClick={() => {
                              setActiveId("");
                              props?.cancelUpdate(false);
                              setIsvSolutionsChanged(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-devinsider px-5"
                            onClick={() => {
                              if (props?.validateEditMatching()) {
                                props?.validateUpdate("segments");
                                setInitialFeaturedSelected({
                                  ...initialFeaturedSelected,
                                  data: props.solutionCheckedFeatures,
                                });
                                setInitialSolutionsSelected({
                                  ...initialSolutionsSelected,
                                  data: props.solutionChecked,
                                });
                              }
                            }}
                          >
                            {props?.loader ? <Loader /> : "Save"}
                          </button>
                        </div>
                      )}
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="5"
                  className={`btnAccordion ${activeId === "5" ? "active" : ""}`}
                  onClick={() => toggleActive("5")}
                >
                  Define which industries the ISV should target to be qualified
                  for your program:
                  <div className="explication">
                    {props?.companyTargetSelected?.length} industries selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <div className="getstartedContainer largeContainer">
                    <div className="contentTargetIndustry">
                      <div className="col4">
                        {props?.companyTarget?.map(
                          (element: IListTarget, index: number) => (
                            <div key={element.id + "-" + element.name}>
                              <Form.Check
                                key={index + "-" + element.name}
                                custom
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                label={element.name}
                                id={element.id + "-" + element.name}
                                onClick={(e) => {
                                  props?.addTargetCompany(element);
                                  e.stopPropagation();
                                }}
                                onChange={() => null}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="col4">
                        {props?.companyTargetSecound?.map(
                          (element: IListTarget, index: number) => (
                            <div key={element.id + "-" + element.name}>
                              <Form.Check
                                key={index + "-" + element.name}
                                custom
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                label={element.name}
                                id={element.id + "-" + element.name}
                                onClick={(e) => {
                                  props?.addTargetCompany(element);
                                  e.stopPropagation();
                                }}
                                onChange={() => null}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="col4">
                        {props?.lastCompanyTarget?.map(
                          (element: IListTarget, index: number) => (
                            <div key={element.id + "-" + element.name}>
                              <Form.Check
                                key={index + "-" + element.name}
                                custom
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                label={element.name}
                                id={element.id + "-" + element.name}
                                onClick={(e) => {
                                  props?.addTargetCompany(element);
                                  e.stopPropagation();
                                }}
                                onChange={() => null}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {props?.companyTargetSelected.length > 0 &&
                      (companyTargetChanged ||
                        (props?.loader &&
                          props?.keyUpdate === "industrie")) && (
                        <div className="contentBorder formForEmail text-left">
                          <button
                            className="btn btn-devinsider cancel"
                            onClick={() => {
                              setActiveId("");
                              props?.cancelUpdate(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-devinsider px-5"
                            onClick={() => {
                              if (props?.validateEditMatching()) {
                                props?.validateUpdate("industrie");
                                setInitialTargetSelected({
                                  ...initialTargetSelected,
                                  data: props.companyTargetSelected,
                                });
                              }
                            }}
                          >
                            {props?.loader &&
                            props?.keyUpdate === "industrie" ? (
                              <Loader />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      )}
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
      <ModalPermission
        handleSkhow={props?.handLePersmission}
        show={props?.showPermission}
      />
      ;
    </>
  );
}
