import { NavLink } from "react-router-dom";
import { fileUrl } from "../../../../../../utils";

type Iprops = {
  profil: string;
  userName: string;
  companyId: number;
};
export default function Alternative(props: Iprops) {
  return (
    <>
      <div className="titleRepH">Corporate Development</div>
      <div className="d-flex align-items-center">
        <div className="photo-title">
          {props.profil && <img src={fileUrl(props.profil)} alt="profil" />}
        </div>
        <div className="info-title">
          <div className="company-title">
            <a className="disable-click-name" href="javascript:void(0);">
              {props?.userName || "-"}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
