/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { useState, ChangeEvent } from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "../complete-reseller.scss";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import UseCompleteCompanyPaysReseller from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-pays-reseller";
import RegionContry from "../../../../../screen/widget/pays";

interface ItemsPays {
  id: number;
  country_name: string;
}
interface Items {
  id: number;
  name: string;
  countries: Array<ItemsPays>;
}
export default function CompleteCompanyPaysReseller() {
  const {
    loader,
    allContinents,
    paysChecked,
    addPaysInArray,
    allContinentSelected,
    goBack,
    errorData,
    navigateCompleteSolutionOffert,
  } = UseCompleteCompanyPaysReseller();
  const [activeId, setActiveId] = useState<any>("0");

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
          </div>
          <a
            className="link-back"
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <span className="icon-back"></span>
          </a>
          <h1>Company information</h1>
        </div>
        <div className="contentBorder border-top row pt-3 pb-4">
          <div className="form-group required col-xl-12 pr-xl-2 pr-md-2 p-0 m-0">
            <label htmlFor="">
              What geographic market do you target ? Select all that apply.
              {errorData && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    select at least one country{" "}
                  </span>
                </div>
              )}
            </label>
          </div>
          {loader && <Loader />}
          <RegionContry
            addPaysInArray={(data: any) => addPaysInArray(data)}
            allContinentSelected={allContinentSelected}
            allContinents={allContinents}
            paysChecked={paysChecked}
          />
        </div>
        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateCompleteSolutionOffert()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
