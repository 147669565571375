import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { keycloakBaseUrl } from "./service/constant";

const initConfig: KeycloakConfig = {
  url: keycloakBaseUrl,
  realm: "devinsider",
  clientId: "devinsider",
};

export const initOptions: KeycloakInitOptions = {
  checkLoginIframe: true,
  pkceMethod: "S256",
  onLoad: "check-sso",
  checkLoginIframeInterval: 1,
};

const kc = new Keycloak(initConfig);

export default kc;
