import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CONTRIBUTOR } from "../../../service/constant/index";
import { createUserExpert } from "../../../service/applicatif/acount";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompletePictureExpert() {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const firstNameExpert = sessionStorage.getItem("firstNameExpert");
  const lastNameExpert = sessionStorage.getItem("lastNameExpert");
  const companyExpert = sessionStorage.getItem("companyNameExpert");
  const jobTitleExpert = sessionStorage.getItem("jobTitleExpert");
  const [loader, setloader] = useState<boolean>(false);
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [skipLoader, setSkipIsLoaderShow] = useState<boolean>(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        if(event?.target?.files[0].size > 2 * 1024 * 1024) {
          setFileSizeError(true);
        } else {
          setFileSizeError(false);
          setSelectedFile(event?.target?.files[0]);
          setIsFilePicked(true);
        }
      }
    }
  };

  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
    }
    setFileSizeError(false);
  };

  const createExpertUser = async () => {
    let data = {
      email: sessionStorage.getItem("businessEmailExpert") || "",
      password: sessionStorage.getItem("passwordExpert") || "",
      roles: CONTRIBUTOR,
      firstName: firstNameExpert || "",
      lastName: lastNameExpert || "",
      plainText: sessionStorage.getItem("aboutExpert") || "",
      isvFirstPostAbout: sessionStorage.getItem("postExpert") || "",
      isPrimaryEmail: true,
      companyName: sessionStorage.getItem("companyNameExpert") || "",
      jobTitle: sessionStorage.getItem("jobTitleExpert") || "",
      phone: sessionStorage.getItem("phoneNumberExpert") || "",
      website: sessionStorage.getItem("webSiteExpert") || "",
      labels: sessionStorage.getItem("labelsExpert") || "",
      country: sessionStorage.getItem("countryRegionExpert") || "",
      fileName: selectedFile,
      phoneNumberCode: sessionStorage.getItem("countryPhoneCode") || "",
      dialCode: sessionStorage.getItem("phoneSelectedExpert" || ""),
    };

    setloader(true);
    let res = await createUserExpert(data);
    if (res && res.status == 201) {
      sessionStorage.clear();
      setloader(false);
      history.push("./complete-contributor-account", { email: data?.email });
    }
  };

  const skipvalidateExpertInscription = async () => {
    let data = {
      email: sessionStorage.getItem("businessEmailExpert") || "",
      password: sessionStorage.getItem("passwordExpert") || "",
      roles: CONTRIBUTOR,
      firstName: firstNameExpert || "",
      lastName: lastNameExpert || "",
      plainText: sessionStorage.getItem("aboutExpert") || "",
      isvFirstPostAbout: sessionStorage.getItem("postExpert") || "",
      isPrimaryEmail: true,
      companyName: sessionStorage.getItem("companyNameExpert") || "",
      jobTitle: sessionStorage.getItem("jobTitleExpert") || "",
      phone: sessionStorage.getItem("phoneNumberExpert") || "",
      website: sessionStorage.getItem("webSiteExpert") || "",
      labels: sessionStorage.getItem("labelsExpert") || "",
      country: sessionStorage.getItem("countryRegionExpert") || "",
      // fileName: selectedFile,
      phoneNumberCode: sessionStorage.getItem("countryPhoneCode") || "",
      dialCode: sessionStorage.getItem("phoneSelectedExpert" || ""),
    };

    setSkipIsLoaderShow(true);
    let res = await createUserExpert(data);
    if (res && res.status == 201) {
      sessionStorage.clear();
      setloader(false);
      history.push("./complete-contributor-account", { email: data?.email });
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      createExpertUser();
    } else return;
  };
  useEventListener("keydown", keyEnter);

  return {
    isFilePicked,
    selectedFile,
    fileRef,
    history,
    firstNameExpert,
    lastNameExpert,
    companyExpert,
    jobTitleExpert,
    loader,
    createExpertUser,
    changeHandler,
    handleBtn,
    setFileSizeError,
    fileSizeError,
    skipLoader,
    skipvalidateExpertInscription
  };
}
