/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import UseCompleteContryInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-contry-investor";
import useGoBack from "../../../../../hooks/use-navigate";

interface ItemsPays {
  id: number;
  country_name: string;
}

export default function CompleteInvestorContry() {
  const {
    navigateTargetProgram,
    addPaysInArray,
    allContinents,
    loader,
    paysChecked,
    allContinentSelected,
    errorData,
  } = UseCompleteContryInvestor();
  const [activeId, setActiveId] = useState<any>("0");

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  const { goBack } = useGoBack();
  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
            className="link-back"
          >
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row py-4">
          <label htmlFor="" className="pb-3 fontNormal">
            Define the ISV characteristics you are interested in :
          </label>
          <div className="form-group required col-xl-12 pr-xl-2 pr-md-2 p-0 m-0">
            <label htmlFor="">Region(s) / Country(ies)</label>
            <span className="selectalloverstyle">Select all that apply.</span>
            {errorData && (
              <div className="container-error pl-2">
                <span className="error-red"> error </span>
                <span className="txt-error">select at least one country</span>
              </div>
            )}
          </div>

          {loader && <Loader />}

          {!loader && (
            <div className="geographicList">
              <Accordion defaultActiveKey={activeId}>
                {allContinents &&
                  allContinents.map((items: any, index: number) => {
                    return (
                      <div
                        className={
                          activeId == items.id
                            ? "panel-wrap active-panel"
                            : "panel-wrap"
                        }
                        key={items.id}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(items.id)}
                            className={`${
                              paysChecked?.filter(
                                (el: any) => el.idContinent == items.id
                              ).length > 0
                                ? "panel-toggle selectedItem"
                                : "panel-toggle"
                            }`}
                            eventKey={items.id}
                          >
                            {items && items.name}{" "}
                            {paysChecked?.filter(
                              (el: any) => el.idContinent == items.id
                            ).length > 1
                              ? `(   ${
                                  paysChecked?.filter(
                                    (el: any) => el.idContinent == items.id
                                  ).length
                                } countries selected )`
                              : ""}
                            {paysChecked?.filter(
                              (el: any) => el.idContinent == items.id
                            ).length == 1
                              ? `( 1 country  selected )`
                              : ""}
                          </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey={items.id}>
                          <div className="panel-body">
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                addPaysInArray({
                                  idContinent: items.id,
                                  countryesData: items.countries,
                                });
                              }}
                            >
                              <Form.Check
                                custom
                                label={`Select all `}
                                id="all"
                                checked={
                                  allContinentSelected?.find(
                                    (el: any) => el == items.id
                                  )
                                    ? true
                                    : false
                                }
                                disabled={true}
                                onClick={() => null}
                                value={index}
                                defaultChecked={false}
                                onChange={() => null}
                              />
                            </div>

                            {items &&
                              items.countries &&
                              items.countries.map((pays: any) => {
                                return (
                                  <Form.Check
                                    key={pays.id}
                                    defaultChecked={false}
                                    onChange={() => null}
                                    custom
                                    label={pays.country_name}
                                    id={pays.id}
                                    checked={
                                      paysChecked?.find(
                                        (t: any) => t.countrId == pays.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      addPaysInArray({
                                        idContinent: items.id,
                                        countrId: pays.id,
                                        countryName: pays.country_name,
                                      });
                                    }}
                                  />
                                );
                              })}
                          </div>
                        </Accordion.Collapse>
                      </div>
                    );
                  })}
              </Accordion>
            </div>
          )}
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateTargetProgram()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
