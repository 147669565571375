import "./Events.scss";
import React from "react";
import Header from "../../widget/index";
import BlocFilterEvent from "./bloc-filter/bloc-filter-event";
import useAllEvents from "../../../controller/event-ctr/use-all-events-ctr";
import EventSection from "./event-sections";
import Pagination from "../../widget/use-pagination/pagination";

export default function Allevents() {
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerEvents = useAllEvents();

  return (
    <div className="findIsvcontainer">
      <Header handleInitEvent={() => providerEvents.resetFilter()} />

      <BlocFilterEvent {...providerEvents} tagFilterIn={2} />

      <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          <div className="headerOfList">
            <div className="leftHead">
              <div className="number">
                Showing
                <b> {providerEvents?.eventObject?.total || " 0 "}</b> Upcoming
                events
              </div>
              <div
                className="formContentOpen"
                onClick={(e) => {
                  e.preventDefault();
                  openBlock(e);
                }}
              >
                <input
                  placeholder="Search..."
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  value={providerEvents?.search}
                  onChange={(e) => providerEvents?.setSearch(e?.target?.value)}
                  onKeyPress={providerEvents?.handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="scrollContainer" id="event-scroll">
            <EventSection.AllEvent
              events={providerEvents?.eventObject?.results}
              target="event-scroll"
            />
          </div>
          <div className="pagginationCategory">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={providerEvents?.page}
                totalCount={providerEvents?.eventObject?.total || 0}
                pageSize={10}
                onPageChange={(page: any) =>
                  providerEvents.handlePageChange(page)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
