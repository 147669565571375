/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import {
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import { useHistory, useParams } from 'react-router-dom';
import { getOtherCompanyPageAttributeAction } from '../../../../redux/actions/company-page-isv-action/other_isv_action';
import { OEM_PROGRAM } from '../../../../service/constant';

export default function UseLicencingModelDistributionChannel() {
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const history = useHistory();

  const idOtherUser = params?.id ? true : false;

  const state = useSelector((state: rootState) => state);

  /************************************
   *
   * Company profil object
   */
  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  /*************************************
   *
   * User profil object
   */

  const [loaderlicencingModel, setLoader] = useState<boolean>(false);
  const [isHaveErrorCategorie, setIsHaveErrorCategorie] =
    useState<boolean>(false);

  const [showFormEditDistributionChannel, setShowFormEditDistributionChannel] =
    useState(false);

  const [isShowLicencingModel, setIsShowLicencingModel] =
    useState<boolean>(false);

  /********************************************************
   *
   * Company page Distribution channel and licencing model  to edit in Strategie
   */
  const [licenCingModelSelected, setLicenCingModelSelected] = useState<
    Array<number>
  >(
    companyPageObject?.licensing_model?.map(
      (el: { id: number; name: string }) => el.id
    ) || []
  );

  const [distributionModelSelected, setDistributionModelSelected] = useState<
    Array<number>
  >(
    companyPageObject?.distribution_channel?.map(
      (el: { id: number; name: string }) => el.id
    ) || []
  );

  useEffect(() => {
    setLicenCingModelSelected(
      companyPageObject?.licensing_model?.map(
        (el: { id: number; name: string }) => el.id
      ) || []
    );
    return () => {};
  }, [companyPageObject]);

  /*******************************************
   *
   * Add licening model
   */
  const addLicencingChecked = (item: number) => {
    let found = licenCingModelSelected?.find((el: number) => el == item);
    if (!found) {
      setLicenCingModelSelected([...licenCingModelSelected, item]);
    } else {
      setLicenCingModelSelected(
        licenCingModelSelected?.filter((elem: number) => elem != item)
      );
    }
  };
  /*******************************************
   *
   * Add distribution channel
   */
  const addDistributionChannel = (item: number) => {
    let found = distributionModelSelected?.find((el: number) => el == item);
    if (!found) {
      setDistributionModelSelected([...distributionModelSelected, item]);
    } else {
      setDistributionModelSelected(
        distributionModelSelected?.filter((elem: number) => elem != item)
      );
    }
  };

  /***********************************
   *
   * Update Licencing model
   */
  const cbLicencingModelEdit = (response: any) => {
    if (response && response?.status === 200) {
      setDistribtionChannel();
    }
  };

  const updateLicencingModel = () => {
    if (
      licenCingModelSelected?.length <= 0 ||
      distributionModelSelected?.length <= 0
    ) {
      setIsHaveErrorCategorie(true);
    } else {
      let params = {
        licensingModel: JSON.stringify(licenCingModelSelected),
        distributionChannel: JSON.stringify(distributionModelSelected),
      };
      setLoader(true);
      /*****
       *  We call distribution channel onLy because , back not return distribution channel
       * but return  a licencing model in company page
       */
      dispatch(
        updateCompanyPageAction(
          params,
          companyPageObject.id,
          cbLicencingModelEdit
        )
      );
    }
  };

  const setDistribtionChannel = () => {
    let attributes = 'distributionChannel';
    const oemIfExist =
      state.userReducer.roles.indexOf(OEM_PROGRAM) !== -1
        ? state.oemProgramReducer.program.id
        : '';
    let idCompany =
      oemIfExist !== '' ? params?.id + '/' + oemIfExist : params?.id || '';
    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attributes,
          idCompany,
          cbDistributionChannel
        )
      );
    } else {
      dispatch(
        getCompanyPageAttributeAction(attributes, cbDistributionChannel)
      );
    }
  };

  useEffect(() => {
    const isEmptyDistributionChannel =
      companyPageObject?.id?.toString() !== '0' &&
      companyPageObject?.distribution_channel?.length < 1 &&
      !idOtherUser;

    if (isEmptyDistributionChannel) setDistribtionChannel();

    if (idOtherUser) setDistribtionChannel();

    return () => {};
  }, []);

  // Get completed attribute Distribution channel, to show with data
  const cbDistributionChannel = (response: any) => {
    if (response && response?.status === 200) {
      let tempSelected = response && response.data.map((el: any) => el.id);
      setDistributionModelSelected(tempSelected);
      if (loaderlicencingModel) setLoader(false);
      if (isShowLicencingModel) setIsShowLicencingModel(false);
    } else if (response?.status === 403) {
      history.push('/profil-not-found');
    }
  };

  useEffect(() => {
    if (isHaveErrorCategorie) setIsHaveErrorCategorie(false);
    return () => {};
  }, [licenCingModelSelected, distributionModelSelected]);

  const cancelUpdateCategorie = () => {
    if (isShowLicencingModel) setIsShowLicencingModel(false);
    setLicenCingModelSelected(
      companyPageObject?.licensing_model?.map(
        (el: { id: number; name: string }) => el.id
      ) || []
    );
    setDistributionModelSelected(
      companyPageObject?.distribution_channel?.map(
        (el: { id: number; name: string }) => el.id
      ) || []
    );
  };

  return {
    showFormEditDistributionChannel,
    setShowFormEditDistributionChannel,
    licenCingModelSelected,
    setLicenCingModelSelected,
    addLicencingChecked,
    updateLicencingModel,
    isShowLicencingModel,
    setIsShowLicencingModel,
    distributionModelSelected,
    addDistributionChannel,
    loaderlicencingModel,
    isHaveErrorCategorie,
    cancelUpdateCategorie,
  };
}
