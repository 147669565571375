import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import UseSubscriptionPlanCtr from "../../../../../controller/settings-ctr/billing-center/use-subscription-plan-ctr";
import { rootState } from "../../../../../redux/reducers";
import { getDataMicroService } from "../../../../../service/applicatif/back-end-service";
import { OEM_PROGRAM, url } from "../../../../../service/constant";
import Footer from "../../../../widget/footer/footer";
import HeaderProgram from "../../../../widget/index";
import { LoadingAllPage } from "../../../../widget/loader";
import { PaymentPopup } from "../../../payment-popup/payment-popup";
import CardRegistrationForm from "./card-registration-form";
import ContactForm from "./contact-form";
import PaymentErrorModal from "./payment-error";
import PaymentSuccessfullModal from "./payment-success";
import PurchaseForm from "./purchase-form";
import "./subscription-plan.scss";

export default function SubscriptionPlan() {
  const subscriptionPlanController = UseSubscriptionPlanCtr();
  const currentUser = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );

  const isOem = currentUser.roles.indexOf(OEM_PROGRAM) !== -1;

  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>({
    lookupKey: "OEM_PREMIUM",
    name: "Premium subscription",
  });

  const [prices, setPrices] = useState<{
    [key: string]: number;
  }>();

  const currencies: { [key: string]: string } = {
    usd: "$",
    eur: "€",
  };

  const [currency, setCurrency] = useState<string | undefined>();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (product && !prices) setLoading(true);
    if(!prices){
      getDataMicroService(
        url.microservice.payment.get_product_prices + product.lookupKey,
        isOem ? { oem_program_id: oemProgram.id } : {},
        currentUser.token
      )
        .then((response) => {
          if (response.data.message === "success") {
            const productPrices = response.data.data.prices;
            const curr = productPrices.usd === undefined ? "eur" : "usd";
            setLoading(false);
            setCurrency(curr);
            setPrices(productPrices);
          } else {
            setLoading(false);
            toast("Error getting product price");
            console.log(response.data.details);
          }
        })
        .catch((error) => {
          toast("Error getting product price");
          console.log(error.message);
        });
    }
  }, [isOem, oemProgram.id, prices, product, currentUser.token]);

  const goToPR = () => {
    history.push("/community/press-releases/0");
  };
  if (!currentUser.roles.includes(OEM_PROGRAM)) {
    return <Redirect to={"/profil-not-found"} />;
  }

  return (
    <>
      {loading && <LoadingAllPage />}
      <div className="settings">
        <HeaderProgram />
        <div className="container mx-auto subscription">
          <h2 className="title">
            Choose a plan that covers your business needs
          </h2>

          <div className="row subscription-content mx-auto mb-4">
            <div className="col-12 col-lg-4 mb-4">
              <div className="free">
                <h3>Basic</h3>
                <div className="price-container">
                  <div className="price">
                    {" "}
                    {currencies[currency || "usd"] + " " + 0}{" "}
                    {currency?.toUpperCase()}
                  </div>
                </div>

                <ul className="subscription-details">
                  <li>
                    Take ownership of your listing in our technology partner
                    program directory
                  </li>
                  <li>
                    Create and complete your program page to highlight the value
                    you offer to ISVs
                  </li>
                  <li>Match and receive inqueries from relevant ISVs</li>
                  <li>2 free unlocks to proactively contact ISVs</li>
                  <li>Access to the Devinsider community</li>
                  <li>
                    Post and respond to ads in the marketplace and corporate
                    development sections
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-4">
              <div className="gold">
                <h3>Premium</h3>
                <div className="price-container">
                  <div className="price">
                    {currency && prices
                      ? currencies[currency || "usd"] + " " + prices[currency]
                      : 0.0}{" "}
                    {currency?.toUpperCase()}
                  </div>
                  <div className="price-info">
                    <span>per /</span>
                    <span>year</span>
                  </div>
                </div>

                <ul className="subscription-details">
                  <li>Everything from basic</li>
                  <li>10% discount on ISV unlocks</li>
                  <li>
                    4 &nbsp;
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="featuresArticles">
                          Featured articles stand out from organic articles and
                          appear at the top of the article feed in the
                          Devinsider community for a duration of 2 weeks.
                        </Tooltip>
                      }
                    >
                      <span className="underline">featured articles</span>
                    </OverlayTrigger>
                    &nbsp;per year in the Devinsider Community to increase your
                    exposure with high quality content
                  </li>
                  <li>
                    4 press release posts per year in the &nbsp;
                    <span className="underline" onClick={() => goToPR()}>
                      Devinsider PR section
                    </span>
                  </li>
                  <li>
                    Premium partnership badge for increased visibility in the
                    ISV matchmaking results
                  </li>
                  <li>
                    2 events in the{" "}
                    <span className="underline" onClick={() => goToPR()}>
                      Devinsider events calendar
                    </span>
                  </li>
                </ul>

                <div className="contact-subscription">
                  <a
                    onClick={() => {
                      if (oemProgram?.oem_subscription?.name != "Premium")
                        subscriptionPlanController.goToPaymentModal();
                    }}
                    className={`${
                      oemProgram?.oem_subscription?.name == "Premium"
                        ? "disabled-btn-purchase"
                        : ""
                    }`}
                  >
                    {" "}
                    Purchase
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="custom">
                <h3>Entreprise</h3>
                <div className="price-container">
                  <div className="price">Custom</div>
                </div>

                <ul className="subscription-details">
                  <li>A customized plan to get the most out of Devinsider</li>
                </ul>

                <div className="contact-subscription">
                  <a
                    onClick={() => {
                      //update plan list
                      subscriptionPlanController.setCurrentPlan("Custom");
                      subscriptionPlanController.setPlanList([
                        {
                          value: "Custom",
                          label: "Custom plan",
                        },
                        {
                          value: "Premium",
                          label: "Premium plan",
                        },
                      ]);
                      subscriptionPlanController.setShowContactForm(true);
                    }}
                  >
                    {" "}
                    <span className="icon-phone"></span>contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ContactForm
        showContactForm={subscriptionPlanController.showContactForm}
        setShowContactForm={subscriptionPlanController.setShowContactForm}
        planList={subscriptionPlanController.planList}
        programName={subscriptionPlanController.programName}
        setProgramName={subscriptionPlanController.setProgramName}
        currentPlan={subscriptionPlanController.currentPlan}
        setCurrentPlan={subscriptionPlanController.setCurrentPlan}
        handleSubscriptionSubmit={
          subscriptionPlanController.handleSubscriptionSubmit
        }
        sendingSubscription={subscriptionPlanController.sendingSubscription}
        programId={subscriptionPlanController.programId}
        firstName={subscriptionPlanController.firstName}
        setFirstName={subscriptionPlanController.setFirstName}
        lastName={subscriptionPlanController.lastName}
        setLastName={subscriptionPlanController.setLastName}
        email={subscriptionPlanController.email}
        setEmail={subscriptionPlanController.setEmail}
        comments={subscriptionPlanController.comments}
        setComments={subscriptionPlanController.setComments}
        userReducer={subscriptionPlanController.userReducer}
        programDefault={subscriptionPlanController.programDefault}
      />
      <PaymentPopup
        show={subscriptionPlanController?.showPaymentModal}
        title={"Premium subscription"}
        setShowPaymentModal={subscriptionPlanController?.setShowPaymentModal}
        product={subscriptionPlanController?.product}
        successCallback={subscriptionPlanController?.paymentMethodCallBack}
        free={0}
        isPriceInfo={false}
      />
      <PurchaseForm
        showPurchaseForm={subscriptionPlanController.showPurchaseForm}
        setShowPurchaseForm={subscriptionPlanController.setShowPurchaseForm}
        cardInformation={subscriptionPlanController.cardInformation}
        settingSetupIntentProcess={
          subscriptionPlanController.settingSetupIntentProcess
        }
        postPremium={subscriptionPlanController.postPremiumPlan}
      />
      <CardRegistrationForm
        showCardRegistrationForm={
          subscriptionPlanController.showCardRegistrationForm
        }
        setShowCardRegistrationForm={
          subscriptionPlanController.setShowCardRegistrationForm
        }
        setShowPaymentSuccessfullModal={
          subscriptionPlanController.setShowPaymentSuccessfullModal
        }
        setShowPaymentErrorModal={
          subscriptionPlanController.setShowPaymentErrorModal
        }
        invoiceNumber={subscriptionPlanController.invoiceNumber}
        setInvoiceNumber={subscriptionPlanController.setInvoiceNumber}
        handleErrorCard={() => {
          subscriptionPlanController.setShowCardRegistrationForm(
            !subscriptionPlanController.showCardRegistrationForm
          );
          subscriptionPlanController.setShowPaymentErrorModal(
            !subscriptionPlanController.showPaymentErrorModal
          );
        }}
      />
      <PaymentSuccessfullModal
        showPaymentSuccessfullModal={
          subscriptionPlanController.showPaymentSuccessfullModal
        }
        setShowPaymentSuccessfullModal={
          subscriptionPlanController.setShowPaymentSuccessfullModal
        }
        invoiceNumber={subscriptionPlanController.invoiceNumber}
        setInvoiceNumber={subscriptionPlanController.setInvoiceNumber}
        goToSettings={subscriptionPlanController.goToSettings}
      />
      <PaymentErrorModal
        showPaymentErrorModal={subscriptionPlanController.showPaymentErrorModal}
        setShowPaymentErrorModal={
          subscriptionPlanController.setShowPaymentErrorModal
        }
        paymentMethod={subscriptionPlanController.paymentMethod}
        setShowCardRegistrationForm={
          subscriptionPlanController.setShowCardRegistrationForm
        }
        setShowPurchaseForm={subscriptionPlanController.setShowPurchaseForm}
      />
    </>
  );
}
