import { NavLink } from "react-router-dom";
import { fileUrl } from "../../../../../../utils";

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  pageId: number;
};
export default function InvestorToIsv(props: Iprops) {
  const companyUrl = "/company/company-unlocked/" + props?.pageId;
  return (
    <>
      <div className="titleRepH">Find ISV</div>
      <div className="d-flex align-items-center">
        <div className="photo-title">
          {props.profil && <img src={fileUrl(props.profil)} alt="profil" />}
        </div>
        <div className="info-title">
          <div className="company-title">
            <NavLink className="pointer" to={companyUrl}>
              {props?.companyName || "-"}
            </NavLink>
          </div>
          <div className="company-type">{props?.roleDiscussion || "-"}</div>
        </div>
      </div>
    </>
  );
}
