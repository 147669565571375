import React from "react";
import { fileUrl, paginate, refaCtoNullKey } from "../../../../../../utils";
type IProps = {
  isShowPopUpRelease: boolean;
  companyPageIdSelected: string;
  currentCompanyPageSelected: string;
  photo_profil: string;
  company_name: string;
  company_summary: string;
  prListe: any;
  currentPagePrGet: number;
  allPressReleaseByUserTotal: number;
  clikPrevPr: (p: number) => void;
  nextPagePr: (p: number) => void;
  onMouseLeave: () => void;
};
export default function PopUpOver(props: IProps) {
  const hasNexPage = (): boolean =>
    props?.currentPagePrGet !==
    Math.ceil(props?.allPressReleaseByUserTotal / 5);
  return (
    <div
      className={`popoverPerso ${
        props?.isShowPopUpRelease &&
        props?.companyPageIdSelected === props.currentCompanyPageSelected
          ? ""
          : "d-none"
      }`}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <div className="popovoverContent">
        <div className="infoAbout">
          <div className="imageAffiliatedcompany empty">
            <img src={fileUrl(props?.photo_profil)} alt="" />
          </div>
          <div className="infoFeed">{props.company_name}</div>
        </div>
        <p>{refaCtoNullKey(props?.company_summary)}</p>
        <div className="others">
          <h6>Press releases</h6>

          {paginate(props?.prListe, 5, props?.currentPagePrGet)?.map(
            (pr: any, index: number) => (
              <div className="d-flex" key={index}>
                <span className="info">{pr?.title}</span>
                <span className="date">{pr?.duration}</span>
              </div>
            )
          )}

          {props?.allPressReleaseByUserTotal > 5 && (
            <li className="linkForLi nextPrev">
              <a
                className={props?.currentPagePrGet === 1 ? "inactive" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  props?.currentPagePrGet !== 1 &&
                    props?.clikPrevPr(props?.currentPagePrGet);
                }}
              >
                Previous
              </a>
              <span className="pl-1 pr-1">
                {props?.currentPagePrGet} of{" "}
                {Math.ceil(props?.allPressReleaseByUserTotal / 5)}
              </span>
              <a
                className={
                  props?.currentPagePrGet ===
                  Math.ceil(props?.allPressReleaseByUserTotal / 5)
                    ? "inactive"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  hasNexPage() &&
                    props?.nextPagePr(props?.currentPagePrGet + 1);
                }}
              >
                Next
              </a>
            </li>
          )}
        </div>
      </div>
    </div>
  );
}
