import MA from '../screen/container/mergers-acquisitions/homePage/homePage';
import Favorites from '../screen/container/mergers-acquisitions/favorites/favorites';
import Listings from '../screen/container/mergers-acquisitions/listing/mylistings';
import Post from '../screen/container/mergers-acquisitions/postMA/post-mergers-acquisitions';
import Ads from '../screen/container/mergers-acquisitions/detail/ads';

export const routesMA = [
  {
    component: MA,
    exact: true,
    path: '/ma/home',
    guard: 'public',
  },

  {
    component: Favorites,
    exact: true,
    path: '/ma/favorites',
    guard: 'public',
  },

  {
    component: Listings,
    exact: true,
    path: '/ma/listings',
    guard: 'public',
  },

  {
    component: Post,
    exact: true,
    path: '/ma/post',
    guard: 'public',
  },
  {
    component: Post,
    exact: true,
    path: '/ma/edit/:id',
    guard: 'private',
  },

  {
    component: Ads,
    exact: true,
    path: '/ma/ads/:id',
    guard: 'public',
  },
];
