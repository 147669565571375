import { conversationType } from '../../utils';

export type InfoRepresentatif = {
  id: number;
  last_name: string;
  first_name: string;
  file_name: string;
  job_title: string;
  company_name: string;
};

export type InfoCompany = {
  id: number;
  company_name: string;
  photo_profil: string;
  // isv_members: Array<IsvMember>;
  name: string;
};

export type InfoUser = {
  file_name: string;
  first_name: string;
  id: string;
  last_name: string;
  company_id: number;
  job_title: string;
};

export type InfoCompanyMessageIsv = {
  id: number;
  company_name: string;
  photo_profil: string;
  isv_members: Array<IsvMember>;
  name: string;
};
export type InfoCompanyOemIsv = {
  id: number;
  company_name: string;
  photo_profil: string;
  isv_members: Array<IsvMember>;
  name: string;
};

export type InfoCompanyInvestorIsv = {
  id: number;
  company_name: string;
  photo_profil: string;
  // isv_members: Array<IsvMember>;
  name: string;
};

export type IsvMember = {
  id: number;
  last_name: string;
  first_name: string;
  file_name: string;
  company_name: string;
  job_title: string;
};

export type ISV_OEM_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    company_to_send: InfoCompanyMessageIsv;
    representative: Array<InfoRepresentatif>;
  };
};

export type ISV_Investor_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    company_to_send: InfoCompanyMessageIsv;
    representative: Array<InfoRepresentatif>;
  };
};

export type OEM_ISV_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    company_to_send: InfoCompanyOemIsv;
    program_representatives: Array<InfoRepresentatif>;
  };
};

export type Investor_Isv_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    company_to_send: InfoCompanyInvestorIsv;
    investor_representatives: Array<InfoRepresentatif>;
  };
};

export type ClassificAds_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    company_to_send: InfoCompany;
  };
};

export type Corporate_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    user_to_send: InfoUser;
  };
};

export type Alternative_InfoConversation = {
  id_conversation: number;
  informations_send_to: {
    user_to_send: InfoUser;
  };
};

export type Community = {
  id_conversation: number;
  informations_send_to: {
    user_to_send: {
      file_name: string;
      first_name: string;
      id: string;
      last_name: string;
    };
  };
};

export type IlistFile = {
  id: number;
  file: string;
  file_location: string;
  size: string;
  extension: string;
  conversation_id?: string;
};

export type Imessage = {
  id: number;
  isInvitation?: boolean;
  sender: {
    id: number;
    last_name: string;
    first_name: string;
    file_name: string;
    company_name: string;
    job_title: string;
  };
  conversation: {
    id: number;
    type: string;
    created_at: string;
  };
  message: string;
  created_at: string;
  attachements: Array<{
    created_at: string;
    extension: string;
    file: string;
    file_location: string;
    id: number;
    size: number;
  }>;
  me: boolean;
};

export type ItemDiscussion = {
  id_conversation: number;
  is_seen: boolean;
  is_unread: boolean;
  is_archived: boolean;
  is_muted: boolean;
  is_request: boolean;
  roles_sender: Array<string>;
  type_conversation: string;
  last_message: Array<Imessage>;
  match_count?: number;
  company_to_send: {
    id: number;
    company_name: string;
    photo_profil: string;
  };
  user_to_send: {
    id: number;
    first_name: string;
    last_name: string;
    file_name: string;
  };
  destination_type: string;
};
export type unreadType = {
  COMMUNITY: number;
  // CLASSIFIED_ADS: number;
  MARKETPLACE: number;
  // FIND_PROGRAM: number;
  // FIND_INVESTOR: number;
  CORPORATE_DEVELOPMENT: number;
  FIND_ISV: number;
  NUMBER_REQUEST: number;
  NUMBER_ARCHIVE: number;
};

//GLOBAL STATE SCREEN

export type IPropsDiscussion = {
  currentInfoToSendMessage: {
    sendTo: number;
    discussionType: string;
    idConversation: number;
    isNull: boolean;
  };

  paginateMessage: {
    messages: Array<Imessage>;
    page: number;
  };

  isv_to_oem: {
    infoConversation: ISV_OEM_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  oem_to_isv: {
    infoConversation: OEM_ISV_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  isv_to_investor: {
    infoConversation: ISV_Investor_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  investor_to_isv: {
    infoConversation: Investor_Isv_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  community: {
    infoConversation: Community;
    discussionItem: Array<ItemDiscussion>;
  };

  entity_to_classific_adds: {
    infoConversation: ClassificAds_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  classific_adds_to_entity: {
    infoConversation: ClassificAds_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  entity_to_corporate: {
    infoConversation: Corporate_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  alternative_financing: {
    infoConversation: Alternative_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  unreadeInCategory: unreadType;
  allDiscussion: Array<ItemDiscussion>;
  allFilePerDiscussion: Array<IlistFile>;
};

export const actionType = {
  GET_INFO_CONVERSATION: 'GET_INFO_CONVERSATION',
  RESTORE_CURRENT_INFO_CONVERSATION: 'RESTORE_CURRENT_INFO_CONVERSATION',
  GET_INFO_CONVERSATION_ON_CLICK: 'GET_INFO_CONVERSATION_ON_CLICK',
  GET_FILE_PER_CONVERSATION: 'GET_FILE_PER_CONVERSATION',
  GET_UNREAD_CATEGORY: 'GET_UNREAD_CATEGORY',
  GET_ALL_CONVERSATION: 'GET_ALL_CONVERSATION',
  HANDLE_CONVERSATION_IN_MERCURE: 'HANDLE_CONVERSATION_IN_MERCURE',
  GET_ALL_MESSAGE: 'GET_ALL_MESSAGE',
  SET_MESSAGE: 'SET_MESSAGE',
  // HANDLE_CONVERSATIO_ID: "HANDLE_CONVERSATIO_ID",
  HANDLE_CURRENT_INFO_MESSAGE: 'HANDLE_CURRENT_INFO_MESSAGE',
  TEMP_ACTIVE_CONVERSATION: 'TEMP_ACTIVE_CONVERSATION',
  LOAD_MORE_MESSAGE: 'LOAD_MORE_MESSAGE',
  RESTORE_CURRENT_MESSAGE_INFO: 'RESTORE_CURRENT_MESSAGE_INFO',
  RESTORE_INFO_CONVERSATION: 'RESTORE_INFO_CONVERSATION',
  SET_OEM_PARTICIPANT: 'SET_OEM_PARTICIPANT',
  REMOVE_CONVERSATION: 'REMOVE_CONVERSATION',
  HANDLE_SEE_CONVERSATION: 'HANDLE_SEE_CONVERSATION',
  HANDLE_MUTED: 'HANDLE_MUTED',
  HANDLE_READ: 'HANDLE_READ',
  REMOVE_ONE_ARCHIVE: 'REMOVE_ONE_ARCHIVE',
  REMOVE_ONE_REQUEST: 'REMOVE_ONE_REQUEST',
  ADD_ARCHIVE: 'ADD_ARCHIVE',
  REMOVE_ALL_CONVERSATION: 'REMOVE_ALL_CONVERSATION',
  HANDLE_NBR_REQUEST: 'HANDLE_NBR_REQUEST',
  RESTORE_STATE_FILTER_CONVERSATION: 'RESTORE_STATE_FILTER_CONVERSATION',
  RESTORE_MESSAGE_CURRENT_DISCUSSION: 'RESTORE_MESSAGE_CURRENT_DISCUSSION',
  SET_MESSAGE_FILTER: 'SET_MESSAGE_FILTER',
  PAGINATE_SEARCH_MESSAGE: 'PAGINATE_SEARCH_MESSAGE',
  HANDLE_ADD_MEMBER_OEM: 'HANDLE_ADD_MEMBER_OEM',
  HANDLE_CONVERSATION_ID: 'HANDLE_CONVERSATION_ID',
  ADD_ONE_REQUEST: 'ADD_ONE_REQUEST',
  REMOVE_CURRENT_CONV: 'REMOVE_CURRENT_CONV',
};

export const handleFilterType = (type: string): string => {
  return type === conversationType.isv_to_oem
    ? 'isv_to_oem'
    : type === conversationType.isv_to_investor
    ? 'isv_to_investor'
    : type === conversationType.oem_to_isv
    ? 'oem_to_isv'
    : type === conversationType.investor_to_isv
    ? 'investor_to_isv'
    : type === conversationType.entity_classific_adds
    ? 'entity_to_classific_adds'
    : type === conversationType.classific_adds_entity
    ? 'classific_adds_to_entity'
    : type === conversationType.MA
    ? 'entity_to_corporate'
    : type === conversationType.MA_entity
    ? 'entity_to_corporate'
    : type === conversationType.alternative
    ? 'alternative_financing'
    : 'community';
};

export let initialChatState = {
  currentInfoToSendMessage: {
    sendTo: 0,
    discussionType: '',
    idConversation: 0,
    isNull: true,
  },
  paginateMessage: {
    messages: [],
    page: 0,
  },
  allDiscussion: [],
  allFilePerDiscussion: [],
  isv_to_oem: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          isv_members: [],
          name: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },

  oem_to_isv: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          isv_members: [],
          name: '',
        },
        program_representatives: [],
      },
    },
    discussionItem: [],
  },

  isv_to_investor: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          isv_members: [],
          name: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },

  investor_to_isv: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          // isv_members: [],
          name: '',
        },
        investor_representatives: [],
      },
    },
    discussionItem: [],
  },
  community: {
    infoConversation: {
      id_conversation: -1,
      informations_send_to: {
        user_to_send: {
          file_name: '',
          first_name: '',
          id: '',
          last_name: '',
        },
      },
    },
    discussionItem: [],
  },

  entity_to_classific_adds: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          name: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },

  entity_to_corporate: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        user_to_send: {
          file_name: '',
          first_name: '',
          id: '',
          last_name: '',
          company_id: 0,
          job_title: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },
  alternative_financing: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        user_to_send: {
          file_name: '',
          first_name: '',
          id: '',
          last_name: '',
          company_id: 0,
          job_title: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },
  classific_adds_to_entity: {
    infoConversation: {
      id_conversation: 0,
      informations_send_to: {
        company_to_send: {
          id: 0,
          company_name: '',
          photo_profil: '',
          name: '',
        },
        representative: [],
      },
    },
    discussionItem: [],
  },
  unreadeInCategory: {
    COMMUNITY: 0,
    // CLASSIFIED_ADS: 0,
    MARKETPLACE: 0,
    // FIND_PROGRAM: 0,
    // FIND_INVESTOR: 0,
    CORPORATE_DEVELOPMENT: 0,
    FIND_ISV: 0,
    NUMBER_REQUEST: 0,
    NUMBER_ARCHIVE: 0,
  },
};

const addNewMessage = (allMessage: any, item: any, isTemp: boolean = false) => {
  let tempMessage: Array<Imessage> = [];
  //add if message is not existe
  let isUpPaginate = allMessage?.length >= 10;
  if (isUpPaginate) {
    if (!isTemp) {
      allMessage.pop();
    }
    tempMessage = [...allMessage, item];
  } else {
    tempMessage = [...allMessage, item];
  }
  return tempMessage;
};

export const helperUpdateMessage = (
  allMessage: Array<Imessage>,
  item: Imessage
) => {
  //check if message stream exist and me is sender
  let isTempMessage = item.id === 0;
  let tempMessage: Array<Imessage> = [];
  //add if message is not existe
  if (isTempMessage) {
    return addNewMessage(allMessage, item, true);
  } else {
    //Is temp message with index 0 existe
    let isTempMessageExist = allMessage.find((el) => el.id === 0);
    if (isTempMessageExist) {
      tempMessage = allMessage?.map((elem: Imessage) => {
        if (elem?.id === 0) {
          return item;
        } else {
          return elem;
        }
      });
      return tempMessage;
    } else {
      return addNewMessage(allMessage, item);
    }
  }
};

export const helperRemove = (
  allDiscussion: Array<ItemDiscussion>,
  id: number
) => {
  let tempAllDiscussion = allDiscussion?.filter(
    (discussion: ItemDiscussion) =>
      discussion?.id_conversation?.toString() !== id?.toString()
  );
  return tempAllDiscussion;
};

export const helperHandleSeeConversation = (
  allConveversation: Array<ItemDiscussion>,
  id: number
) => {
  return allConveversation.map((el: ItemDiscussion) => {
    if (el?.id_conversation?.toString() === id?.toString()) {
      return {
        ...el,
        is_seen: !el?.is_seen,
        is_unread: false,
      };
    } else {
      return el;
    }
  });
};

export const helperHandleMuteConversation = (
  allConveversation: Array<ItemDiscussion>,
  id: number
) => {
  return allConveversation.map((el: ItemDiscussion) => {
    if (el?.id_conversation?.toString() === id?.toString()) {
      return {
        ...el,
        is_muted: !el?.is_muted,
      };
    } else {
      return el;
    }
  });
};

export const helperHandleReadConversation = (
  allConveversation: Array<ItemDiscussion>,
  id: number
) => {
  let tempDiscussion = allConveversation.map((el: ItemDiscussion) => {
    if (el?.id_conversation?.toString() === id?.toString()) {
      return {
        ...el,
        is_unread: !el?.is_unread,
        is_seen: !el?.is_seen,
      };
    } else {
      return el;
    }
  });

  return tempDiscussion;
};
