import React from "react";
import { useHistory } from "react-router-dom";

export default function HeaderBlue() {

  const history = useHistory();

  const goHome = () => {
    history?.push("/community/home");
  };

  return (
    <ul className="breadcrumbPerso">
      <li onClick={() => goHome()}>
        <a href="javascript:;" onClick={(e) => e?.preventDefault()}>Community</a>
      </li>
      <li>
        <a href="javascript:;">Press release</a>
      </li>
    </ul>
  );
}
