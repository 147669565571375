import React, { useState, useEffect } from "react";
import "./contact.scss";
import Header from "../../widget/index";
import Footer from "../../widget/footer/footer";
import { Link } from "react-scroll";
import DIcommunity from "../../../resources/images/DIcommunity.png";
import ContactSupportCtr from "../../../controller/community-ctr/contact-support-ctr/use-contact-support-ctr";
import { BtnNext } from "../../widget/bouton/btn-next";
import Select from "react-select";
import { useHistory } from "react-router-dom";

export default function Contact() {
  const history = useHistory();
  const [showEmail, setshowEmail] = useState(false);
  const UseContactSupportCtr = ContactSupportCtr();
  const renderError = (message: string) => {
    return (
      <div className="col-12 form-group px-0" style={{ color: "red" }}>
        {message}
      </div>
    );
  };
  const [selectedOption, setSelectedOption] = useState<String>();
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
  };
  const options = [
    {
      id: 0,
      value: "0",
      label: "Please select...",
      customAbbreviation: "A",
      description: "",
    },
    {
      id: 1,
      value: "1",
      label: "Who can view my company page?",
      customAbbreviation: "A",
      description:
        "Your company page will not be visible outside of Devinsider. Within Devinsider your company page visibility will be limited to investors and technology programs that engage in matchmaking (coming soon!). By default the overview section of your company page is displayed on your personal profile, which is visible by all Devinsider members and visitors of the community. You can control the visibility settings of your personal profile in your settings.",
    },
    {
      id: 2,
      value: "2",
      label: "Is Devinsider free?",
      customAbbreviation: "B",
      description:
        "Creating an account on Devinsider is free. Once registered, you will be able to participate in the community as well as access Devinsider’s marketplace and matchmaking features. Members have the option to use paid features to get the most out of Devinsider but this is not required.",
    },
    {
      id: 3,
      value: "3",
      label: "I am not a software vendor, can I still join Devinsider?",
      customAbbreviation: "C",
      description:
        "The platform is strictly made for software vendors but we are making an exception for industry experts that could add value to the community by contributing to the forum discussions and by sharing high-value content as articles. If you would like to join Devinsider as an expert contributor, sign-up",
    },
    {
      id: 4,
      value: "4",
      label: "Can I stay anonymous on Devinsider?",
      customAbbreviation: "E",
      description:
        "Visitors must sign up and log in to be able to access all of Devinsider’s features. This is to ensure transparency and accountability across members in the community. As a member of Devinsider you can control your visibility settings and appear anonymous, semi-anonymous or fully visible according to your preference. We are always working to update and improve our website, and your feedback is highly appreciated. If this is a feature you would like to see in the future, please click contact us.",
    },
    {
      id: 5,
      value: "5",
      label: "How do I contact other Devinsider members?",
      customAbbreviation: "F",
      description:
        "Devinsider has a built-in messaging tool for users to contact each other via their profile page. Simply head to their profile and click on “Send a Message” to start a conversation.",
    },
    {
      id: 6,
      value: "6",
      label: "Do I have to be registered to use Devinsider?",
      customAbbreviation: "G",
      description:
        "Currently, visitors are allowed to browse resources on Devinsider but must sign up and log in to contribute to the community and access all of Devinsider’s features.",
    },
    {
      id: 7,
      value: "7",
      label: "What does “Verified Professional” mean?",
      customAbbreviation: "H",
      description:
        "When signing up on Devinsider, users are required to provide their work email address associated with their company. This is a prerequisite in creating a company page or posting an advertisement in the marketplace.",
    },
    {
      id: 8,
      value: "8",
      label: "Why can’t I access the “Find Programs” functionality?",
      customAbbreviation: "I",
      description:
        "This feature will be released soon! In the meantime, share your expertise and identify new opportunities in the community and marketplace.",
    },
    {
      id: 9,
      value: "9",
      label: "Why can’t I access the “Find Investors functionality?",
      customAbbreviation: "D",
      description:
        "This feature will be released soon! In the meantime, share your expertise and identify new opportunities in the community and marketplace.",
    },
  ];
  const [currentDescription, setCurrentDescription] = useState<{
    id: number;
    description: string;
  }>({
    id: options[0].id,
    description: options[0].description,
  });

  const expertcontributor = () => {
    history.push("/start-expert");
  };

  const formatOptionLabel = (options: {
    id: number;
    label: string;
    value: string;
    description: string;
  }) => (
    <div
      onClick={() =>
        setCurrentDescription({
          id: options?.id,
          description: options?.description,
        })
      }
    >
      {options?.label}
    </div>
  );
  const generateDescription = (data: { id: number; description: string }) => {
    return (
      <>
        {data.description}{" "}
        {data.id === 3 ? (
          <span
            className="linksHere"
            style={{ color: "#1897c8", cursor: "pointer" }}
            onClick={() => expertcontributor()}
          >
            here<span style={{ color: "initial", cursor: "initial" }}>.</span>
          </span>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="contactPage">
        <Header />
        <div className="container mx-auto">
          <div className="contentBorder bggris">
            <h1 className="title">How can we help you?</h1>
            <div className="topQuestion text-center">
              <h4>Top questions</h4>
              <p>
                Select from the drop down below to find answers to commonly
                raised questions.
              </p>
              <div className="form-group">
                <div className="iconn"></div>
                <div>
                  <Select
                    defaultValue={options[0]}
                    formatOptionLabel={(opt) => formatOptionLabel(opt)}
                    options={options}
                    className="selectQuestion"
                    classNamePrefix="selectQuestion"
                    placeholder="Please select..."
                  />
                  <div className="optionChoose" style={{ textAlign: "left" }}>
                    {generateDescription(currentDescription)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contentBorder p-5">
            <ul className="listsoftwarevendor">
              <li>
                <img src={DIcommunity} alt="" />
                <h2 className="title">Devinsider community</h2>
                <div className="textforitems">
                  <p>
                    Engage with every insiders members around the world to get
                    answers to your questions, and maximise success.
                  </p>
                </div>
                <a
                  className="learnMore"
                  onClick={() => UseContactSupportCtr?.goToCommunity()}
                >
                  Ask the Community
                </a>
              </li>
              {/* <li>
                <span className="icon-tchat"></span>
                <h2 className="title">Live chat</h2>
                <div className="textforitems">
                  <p>
                    Need immediate assistance? Get in touch with our team right
                    now.
                  </p>
                </div>
                <a className="learnMore">Chat with us</a>
              </li> */}
              <li>
                <span className="icon-email"></span>
                <h2 className="title">Email</h2>
                <div className="textforitems">
                  <p>Have an inquiry? Contact us via email!</p>
                </div>
                <Link to="email" spy={true} smooth={true} duration={1000}>
                  <button
                    className="learnMore"
                    onClick={(e) => {
                      e.preventDefault();
                      setshowEmail(!showEmail);
                    }}
                  >
                    Email us
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showEmail && (
        <section id="email" className="partener container email">
          <div className="container mx-auto ">
            <div className="getintouch mx-auto">
              <h1 className="title">Have a general question?</h1>
              <div className="formGetInTouch row px-3">
                <form>
                  <div className="col-12 form-group px-0">
                    <input
                      type="text"
                      placeholder="Your email"
                      className="form-control"
                      name="youremail"
                      value={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.email
                      }
                      onChange={(e) => {
                        let newData = {
                          ...UseContactSupportCtr?.formGeneralQuestion,
                        };
                        newData.data.email = e.target?.value;
                        newData.sendingQuestion = false;
                        UseContactSupportCtr?.setFormGeneralQuestion(newData);
                      }}
                    />
                    {UseContactSupportCtr?.formGeneralQuestion?.data?.email ===
                      "" &&
                      UseContactSupportCtr?.formGeneralQuestion
                        ?.sendingQuestion &&
                      renderError("Email is mandatory")}
                  </div>
                  <div className="lastandfirstname col-12 px-0">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="First name"
                        className="form-control"
                        name="firstName"
                        defaultValue={
                          UseContactSupportCtr?.formGeneralQuestion?.data
                            ?.firstName
                        }
                        value={
                          UseContactSupportCtr?.formGeneralQuestion?.data
                            ?.firstName
                        }
                        onChange={(e) => {
                          let newData = {
                            ...UseContactSupportCtr?.formGeneralQuestion,
                          };
                          newData.data.firstName = e.target?.value;
                          newData.sendingQuestion = false;
                          UseContactSupportCtr?.setFormGeneralQuestion(newData);
                        }}
                      />
                      {UseContactSupportCtr?.formGeneralQuestion?.data
                        ?.firstName === "" &&
                        UseContactSupportCtr?.formGeneralQuestion
                          ?.sendingQuestion &&
                        renderError("First name is mandatory")}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        name="lastName"
                        defaultValue={
                          UseContactSupportCtr?.formGeneralQuestion?.data
                            ?.lastName
                        }
                        value={
                          UseContactSupportCtr?.formGeneralQuestion?.data
                            ?.lastName
                        }
                        onChange={(e) => {
                          let newData = {
                            ...UseContactSupportCtr?.formGeneralQuestion,
                          };
                          newData.data.lastName = e.target?.value;
                          newData.sendingQuestion = false;
                          UseContactSupportCtr?.setFormGeneralQuestion(newData);
                        }}
                      />
                      {UseContactSupportCtr?.formGeneralQuestion?.data
                        ?.lastName === "" &&
                        UseContactSupportCtr?.formGeneralQuestion
                          ?.sendingQuestion &&
                        renderError("Last name is mandatory")}
                    </div>
                  </div>
                  <div className="col-12 form-group px-0">
                    <input
                      type="text"
                      placeholder="Company"
                      className="form-control"
                      name="company"
                      defaultValue={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.company
                      }
                      value={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.company
                      }
                      onChange={(e) => {
                        let newData = {
                          ...UseContactSupportCtr?.formGeneralQuestion,
                        };
                        newData.data.company = e.target?.value;
                        newData.sendingQuestion = false;
                        UseContactSupportCtr?.setFormGeneralQuestion(newData);
                      }}
                    />
                    {UseContactSupportCtr?.formGeneralQuestion?.data
                      ?.company === "" &&
                      UseContactSupportCtr?.formGeneralQuestion
                        ?.sendingQuestion &&
                      renderError("company is mandatory")}
                  </div>
                  <div className="col-12 form-group px-0">
                    <input
                      type="text"
                      placeholder="What can we help you with?"
                      className="form-control"
                      name="help"
                      defaultValue={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.topic
                      }
                      value={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.topic
                      }
                      onChange={(e) => {
                        let newData = {
                          ...UseContactSupportCtr?.formGeneralQuestion,
                        };
                        newData.data.topic = e.target?.value;
                        newData.sendingQuestion = false;
                        UseContactSupportCtr?.setFormGeneralQuestion(newData);
                      }}
                    />
                    {UseContactSupportCtr?.formGeneralQuestion?.data?.topic ===
                      "" &&
                      UseContactSupportCtr?.formGeneralQuestion
                        ?.sendingQuestion &&
                      renderError("Topic is mandatory")}
                  </div>
                  <div className="col-12 form-group px-0 moreinfo">
                    <textarea
                      name="moreinfo"
                      id="more"
                      className="input-text form-control"
                      defaultValue={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.message
                      }
                      value={
                        UseContactSupportCtr?.formGeneralQuestion?.data?.message
                      }
                      onChange={(e) => {
                        let newData = {
                          ...UseContactSupportCtr?.formGeneralQuestion,
                        };
                        newData.data.message = e.target?.value;
                        newData.sendingQuestion = false;
                        UseContactSupportCtr?.setFormGeneralQuestion(newData);
                      }}
                    />
                    {UseContactSupportCtr?.formGeneralQuestion?.data
                      ?.message === "" &&
                      UseContactSupportCtr?.formGeneralQuestion
                        ?.sendingQuestion &&
                      renderError("Message cannot be empty")}
                  </div>
                  <div className="col-12 px-0 btn btn-devinsider large containerBtnBtn">
                    <BtnNext
                      text={"Send"}
                      showLoader={UseContactSupportCtr?.sendingQuestion}
                      click={() => {
                        UseContactSupportCtr?.handleGeneralQuestionSent();
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}
