import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Account } from "./devinsider-account";
import { Communityprofile } from "./community-profile";
import { Notification } from "./notification";
import BillingCenter from "./billing-center/index";
import { Loginandsecurity } from "./login-security";
import UseSettings from "../../../controller/settings-ctr/use-settings";
import BillingCenterSetting from "../../../controller/settings-ctr/billing-center/use-billing-center-ctr";
import Header from "../../../screen/widget/index";
import Footer from "../../widget/footer/footer";
import PermissionOemModal from "../../widget/permission-oem/index";
import UseEditOemPage from "../../widget/oem-program-targeting/use-program-targeting";
import ModalEditOemSettings from "../../widget/oem-program-targeting/index";
import UseManagePage from "../../widget/manage-company-and-billing/use-manage-company-and-billing";
import ManageOemPage from "../../widget/manage-company-and-billing/index";
import { checkType } from "../../../utils";
import { CONTRIBUTOR, GUEST } from "../../../service/constant";
import UseDeactivateOemProgram from "../../../controller/oem-ctr/settings/deactivate-program/deactivate-program";

export default function Setting() {
  const providerSettings = { ...UseSettings() };
  const providerBilling = { ...BillingCenterSetting() };
  //Targeting oem
  const providerEditSettings = { ...UseEditOemPage() };
  //Oem page
  const providerManagePage = { ...UseManagePage() };
  const [show, setShow] = useState(false);
  // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
  const useDeactivateOemProgram = UseDeactivateOemProgram();
  
  return (
    <div className="settings">
      <Header />
      <PermissionOemModal
        handleSkhow={providerEditSettings.handLePersmission}
        show={providerEditSettings.showPermission}
      />
      <ModalEditOemSettings {...providerEditSettings} />
      <ManageOemPage {...providerManagePage} />

      <div className="container mx-auto">
        <div className="contentTabSettings">
          <Tabs
            defaultActiveKey={
              providerSettings?.tabToShow === "d-account"
                ? "d-account"
                : providerSettings?.tabToShow === "notifications"
                ? "notifications"
                : "billing"
            }
          >
            <Tab eventKey="d-account" title="Devinsider account">
              <Account
                // // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
                showModal={false}
                handleModal={useDeactivateOemProgram.handleModal}
                showModalDeactivateProgram={
                  useDeactivateOemProgram.showModalDeactivateProgram
                }
                processingDeactivateProgram={
                  useDeactivateOemProgram.processingDeactivateProgram
                }
                handleSubmitDeactivateProgram={
                  useDeactivateOemProgram.handleSubmitDeactivateProgram
                }
                navigateToEditProfil={providerSettings?.editProfil}
                isShowFormClauseAccount={
                  providerSettings?.isShowFormClauseAccount
                }
                setIsShowFormClauseAccount={(isShow) =>
                  providerSettings?.setIsShowFormClauseAccount(isShow)
                }
                feedBackClauseAccount={providerSettings?.feedBackClauseAccount}
                // raisonClauseAcount,
                setRaisonClauseAcount={(data: string) =>
                  providerSettings?.setRaisonClauseAcount(data)
                }
                setFeedBackClauseAccount={(data: string) =>
                  providerSettings?.setFeedBackClauseAccount(data)
                }
                clauseAccount={providerSettings?.clauseAccount}
                loaderClauseAcount={providerSettings?.loaderClauseAcount}
                navigateToCompanyPage={(type = "") =>
                  providerSettings?.navigateToCompanyPage(type)
                }
                desactiveAccount={() => {
                  setShow(true);
                }}
                showManagePage={() => {
                  providerManagePage?.showManageRole();
                }}
                showTargeting={() => {
                  providerEditSettings.setShowPageSettings();
                }}
                roleUserIncurrentProgram={
                  providerSettings.roleUserIncurrentProgram
                }
                showModalConfirmClauseAccount={
                  providerSettings.showModalConfirmClauseAccount
                }
                manageCompany={() => providerSettings.manageCompany()}
                setShowModalNoCompany={() =>
                  providerSettings.setShowModalWithoutCompany(
                    !providerSettings.modalWithoutCompany
                  )
                }
                showModalNoCompany={providerSettings.modalWithoutCompany}
                validClauseAccount={providerSettings.validClauseAccount}
              />
            </Tab>
            <Tab eventKey="c-profile" title="Devinsider profile">
              <Communityprofile />
            </Tab>
            <Tab eventKey="loginsecurity" title="Login and security">
              <Loginandsecurity
                {...providerSettings}
                password={providerSettings?.passWord}
                setPassword={providerSettings?.setPassWord}
                setNewPassWord={providerSettings?.setNewPassword}
                setNewConfirmPassword={providerSettings?.setConfirmNewPassword}
                isPassWordUpdated={providerSettings?.succedUpdate}
                workedMail={providerSettings?.workedEmail}
              />
            </Tab>
            {!checkType(providerSettings.userReducer.roles, [
              GUEST,
              CONTRIBUTOR,
            ]) && (
              <Tab eventKey="notifications" title="Notifications">
                <Notification
                  {...providerSettings?.settingsNotification}
                  updateSettingsInfo={providerSettings?.editInfoNotification}
                  isLoadingRadio={providerSettings?.isLoadingRadio}
                />
              </Tab>
            )}

            {!checkType([providerSettings.roleUserIncurrentProgram()], []) && (
              <Tab eventKey="billing" title="Billing center">
                <BillingCenter
                  showMonthlyBudget={providerBilling?.showMonthlyBudget}
                  setShowMonthlyBudget={providerBilling.setShowMonthlyBudget}
                  companyObject={providerBilling?.companyObject}
                  companyName={providerBilling?.companyName}
                  role={providerBilling?.role}
                  showBillingCenter={providerBilling?.showBillingCenter}
                  setShowBillingCenter={() =>
                    providerBilling?.setShowBillingCenter(
                      !providerBilling?.showBillingCenter
                    )
                  }
                  //BillingOwner
                  billing_owner_username={providerBilling?.billingOwnerUsername}
                  billing_owner_email={providerBilling?.billingOwnerEmail}
                  admin_members={providerBilling.membersAdmin}
                  billingOwnerRegistration={(
                    companyPageId: number,
                    userId: string
                  ) =>
                    providerBilling?.billingOwnerRegistration(
                      companyPageId,
                      userId
                    )
                  }
                  register_billing_owner={providerBilling?.registerBillingOwner}
                  company_page_id={providerBilling?.companyId}
                  tabNavigate={providerBilling?.tabNavigate}
                  setTabNavigate={(s: string) =>
                    providerBilling?.setTabNavigate(s)
                  }
                  setTabNavHistory={(s: string) =>
                    providerBilling?.setTabNavHistory(s)
                  }
                  tabNavHistory={providerBilling?.tabNavHistory}
                  receiptDate={providerBilling.planDetailDate.last_receipt}
                  transactionDate={
                    providerBilling.planDetailDate.transaction_history
                  }
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
      <Footer />
    </div>
  );
}
