/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";


const ContactForm = (props: any) => {

  const reset = () => {
    props.setFirstName(props.userReducer.first_name);
    props.setLastName(props.userReducer.last_name);
    props.setEmail(props.userReducer.email);
    props.setComments("");
  };

  return (
    <>
      <Modal
        show={props?.showContactForm}
        className="createCompanypopup large forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Contact sales</h2>
            <a
              className="close"
              onClick={() => {
                props?.setShowContactForm(false);
                reset();
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder forBillingCenter contact">
            <div className="contact row">
              <div className="name required col-6">
                <label htmlFor="">
                  First name
                  {/* <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue = {props.userReducer.first_name}
                  onChange={(event) => props.setFirstName(event.currentTarget.value)}
                ></input>
              </div>
              <div className="name required col-6">
                <label htmlFor="">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue = {props.userReducer.last_name}
                  onChange={(event) => props.setLastName(event.currentTarget.value)}
                />
              </div>
              <div className="name help col-6 required">
                <label htmlFor="">Program name</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue = {props.programDefault}
                  onChange = {(event)=> props.setProgramName(event.target.value)}
                />
              </div>
              <div className="help required col-12">
                <label htmlFor="">I'd like to get started with</label>
                <select
                  id="plan"
                  className="custom-select mr-3"
                  onChange={(event) => props.setCurrentPlan(event.currentTarget.value)}
                >
                  {props?.planList.map(
                    (plan: { value: string; label: string }) => (
                      <option value={plan.value}>{plan.label}</option>
                    )
                  )}
                </select>
              </div>

              <div className="help col-12">
                <label htmlFor=""></label>
                <div className="form-group col-12 descCompany p-0"></div>
              </div>
            </div>

            <div className=" subject row">
              <div className="help col-12">
                <label htmlFor="">Message (optional)</label>
                <div className="form-group col-12 descCompany p-0">
                  <textarea
                    placeholder="Your message..."
                    value={props.comments}
                    onChange={(event) => props.setComments(event.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail row">
            <div className="affiliatedPopup contentBorder formForEmail text-left col-lg-6"></div>
            <div className="contentBorder formForEmail text-right col-lg-6">
              <p>&nbsp;</p>
              <button
                type="submit"
                className="btn btn-devinsider px-5 save"
                disabled={
                  props.firstName === "" ||
                  props.lastName === "" ||
                  props.email === ""
                }
                onClick={() => {
                  props.handleSubscriptionSubmit({
                    firstName:props.firstName,
                    lastName:props.lastName,
                    email:props.email,
                    programName: props.programName,
                    plan:props.currentPlan,
                    comments:props.comments,
                    programId:props.programId
                  });
                  reset();
                }}
              >
                {props.sendingSubscription === true ? (
                  <Loader />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactForm;
