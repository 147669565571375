import { lowerCase } from "lodash";
import moment from "moment";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import toggleoff from "../../../../../resources/images/toggle.svg";
import toggleon from "../../../../../resources/images/toggleon.svg";

export type IpropsConfirmCancel = {
  show?: boolean;
  program?: any;
  handleShowConfirm: (p: boolean) => void;
  cancelSubscriptionRenew: (value: string) => void;
  enableSubscriptionRenew: (value: string) => void;
  showLoader: boolean;
};

const ConfirmUpdateRenewalSubscription = (props: IpropsConfirmCancel) => {
  const updateRenewal = (id: string) => {
    props?.program?.renew_subscription_enabled ?  props?.cancelSubscriptionRenew(id) : props?.enableSubscriptionRenew(id)
  }

  return (
    <Modal
    show={props.show}
    className='modalDevinsider medium forDeactivate'
    onHide={() => props.handleShowConfirm(false)}
  >
    <Modal.Header closeButton>
    <Modal.Title>{`Turn ${props?.program?.renew_subscription_enabled ? "off": "on"} automatic renewal`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="mx-auto">
        <img className="d-block mx-auto" src={props?.program?.renew_subscription_enabled ? toggleoff : toggleon}  style={{width:"70px", marginTop:"1rem"}} alt="toggle-disabled"/>
      </div>
      <h3 className="title-cancel title mb-5">
        Are you sure you want to continue?
      </h3>

      <p className="mb-5 pb-5 text-center">
        You are about to turn {props?.program?.renew_subscription_enabled ? "off": "on"} the renewal of your {lowerCase(props?.program?.oem_subscription?.name)} {" "} subscription.
        Your current subscription ends on <b>{moment(props?.program?.subscription_end_at).format("DD MMMM YYYY")}</b> and {props?.program?.renew_subscription_enabled ? "will not be renewed": "will be renewed automatically"}.
      </p>
    </Modal.Body>
    <Modal.Footer className="mt-5">
      <Button className='cancelbtn' onClick={() =>props.handleShowConfirm(false)}>
        Cancel
      </Button>
      <button
        className="btn-devinsider text-white border-0 px-5"
        onClick={() => updateRenewal(props?.program?.id)}
        type="button"
      >
        {props?.showLoader ? <Loader /> : "Confirm"}
      </button>
    </Modal.Footer>
  </Modal>
  );
}
export default ConfirmUpdateRenewalSubscription;