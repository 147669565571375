import React from "react";
import "./Loading.scss";
import {isNumber} from "lodash";

export const LoaderCommon: React.FC<{progress?: number}> = ({progress}) => {
  return (
    <div>
      <div className="loader">
        <div className="loader-result">
          {isNumber(progress) && (
              <div className="progress-value">{progress}%</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const LoadingAllPage = () => {
  return (
    <div className="loadingLogo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 123.57 123.57"
        width="123.56999969482422"
        height="123.56999969482422"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Layer_1" data-name="Layer 1">
            <path
              d="M61.79.5a61.3,61.3,0,0,0-54,90.33A58.75,58.75,0,0,1,28.37,47.49a59.63,59.63,0,0,1,7.93-5.73,32.4,32.4,0,0,1,41.94-7.9V2.74A61.11,61.11,0,0,0,61.79.5Z"
              fill="#f60"
              stroke="#f60"
              stroke-miterlimit="10"
              className="svg-elem-1"
            ></path>
            <path
              d="M85.39,91.63a59.7,59.7,0,0,1-9.32-.74,32.41,32.41,0,0,1-46.7-29.1,32.87,32.87,0,0,1,1-8,32.37,32.37,0,0,1,5.93-12,59.63,59.63,0,0,0-7.93,5.73A58.75,58.75,0,0,0,7.81,90.83,61.31,61.31,0,0,0,120.26,80.19,58.59,58.59,0,0,1,85.39,91.63Z"
              fill="#f30"
              stroke="#f30"
              stroke-miterlimit="10"
              className="svg-elem-2"
            ></path>
            <path
              d="M94.21,9.77V61.33h0c0,.15,0,.3,0,.46a32.42,32.42,0,0,1-18.14,29.1,59.7,59.7,0,0,0,9.32.74,58.59,58.59,0,0,0,34.87-11.44,60.36,60.36,0,0,0,1.88-7.72A61.38,61.38,0,0,0,94.21,9.77Z"
              fill="#f60"
              stroke="#f60"
              stroke-miterlimit="10"
              className="svg-elem-3"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export const LoadingArticle = () => {
  return (
    <div className="article-loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.39 204">
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_1-2" data-name="Calque 1">
            <rect x="33.39" y="8" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="24" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="33" width="87.4" height="3" fill="#e2e2e2" />
            <rect x="33.39" width="126.6" height="3" fill="#c4c4c4" />
            <rect x="33.39" y="16" width="196" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="13.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="19.5" width="15" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="13.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="19.5" width="15" height="3" fill="#e2e2e2" />
            <circle cx="12.5" cy="14" r="12.5" fill="#e2e2e2" />
            <path
              d="M16.43,11.32a3.76,3.76,0,0,0-1.15-2.7,4,4,0,0,0-5.56,0,3.76,3.76,0,0,0,0,5.41,4,4,0,0,0,5.56,0A3.77,3.77,0,0,0,16.43,11.32Z"
              fill="#fff"
            />
            <path
              d="M7.79,19c0,.77,1.77,1.53,4.71,1.53,2.76,0,4.71-.76,4.71-1.53,0-1.53-1.85-3.06-4.71-3.06S7.79,17.44,7.79,19Z"
              fill="#fff"
            />
            <rect x="33.39" y="64" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="80" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="89" width="87.4" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="56" width="126.6" height="3" fill="#c4c4c4" />
            <rect x="33.39" y="72" width="196" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="69.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="75.5" width="15" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="69.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="75.5" width="15" height="3" fill="#e2e2e2" />
            <circle cx="12.5" cy="70" r="12.5" fill="#e2e2e2" />
            <path
              d="M16.43,67.32a3.76,3.76,0,0,0-1.15-2.7,4,4,0,0,0-5.56,0,3.76,3.76,0,0,0,0,5.41,4,4,0,0,0,5.56,0A3.77,3.77,0,0,0,16.43,67.32Z"
              fill="#fff"
            />
            <path
              d="M7.79,75c0,.77,1.77,1.53,4.71,1.53,2.76,0,4.71-.76,4.71-1.53,0-1.53-1.85-3.06-4.71-3.06S7.79,73.44,7.79,75Z"
              fill="#fff"
            />
            <rect x="33.39" y="120" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="136" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="145" width="87.4" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="112" width="126.6" height="3" fill="#c4c4c4" />
            <rect x="33.39" y="128" width="196" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="125.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="131.5" width="15" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="125.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="131.5" width="15" height="3" fill="#e2e2e2" />
            <circle cx="12.5" cy="126" r="12.5" fill="#e2e2e2" />
            <path
              d="M16.43,123.32a3.76,3.76,0,0,0-1.15-2.7,4,4,0,0,0-5.56,0,3.76,3.76,0,0,0,0,5.41,4,4,0,0,0,5.56,0A3.77,3.77,0,0,0,16.43,123.32Z"
              fill="#fff"
            />
            <path
              d="M7.79,131c0,.77,1.77,1.53,4.71,1.53,2.76,0,4.71-.76,4.71-1.53,0-1.53-1.85-3.06-4.71-3.06S7.79,129.44,7.79,131Z"
              fill="#fff"
            />
            <rect x="33.39" y="176" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="192" width="196" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="201" width="87.4" height="3" fill="#e2e2e2" />
            <rect x="33.39" y="168" width="126.6" height="3" fill="#c4c4c4" />
            <rect x="33.39" y="184" width="196" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="181.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="292.39" y="187.5" width="15" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="181.5" width="9" height="3" fill="#e2e2e2" />
            <rect x="413.39" y="187.5" width="15" height="3" fill="#e2e2e2" />
            <circle cx="12.5" cy="182" r="12.5" fill="#e2e2e2" />
            <path
              d="M16.43,179.32a3.76,3.76,0,0,0-1.15-2.7,4,4,0,0,0-5.56,0,3.76,3.76,0,0,0,0,5.41,4,4,0,0,0,5.56,0A3.77,3.77,0,0,0,16.43,179.32Z"
              fill="#fff"
            />
            <path
              d="M7.79,187c0,.77,1.77,1.53,4.71,1.53,2.76,0,4.71-.76,4.71-1.53,0-1.53-1.85-3.06-4.71-3.06S7.79,185.44,7.79,187Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export const LoadingFeed = () => {
  return (
    <div className="feed-loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 579 246">
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_1-2" data-name="Calque 1">
            <rect width="579" height="246" fill="#fff" />
            <rect x="10" y="8" width="269" height="14" fill="#c1c1c1" />
            <circle cx="30" cy="65" r="24" fill="#f2f2f2" />
            <rect x="71" y="49" width="153" height="9" fill="#c1c1c1" />
            <rect x="71" y="72" width="84" height="12" fill="#f2f2f2" />
            <rect x="71" y="109" width="429" height="13" fill="#f2f2f2" />
            <rect x="71" y="134" width="429" height="13" fill="#f2f2f2" />
            <rect x="71" y="159" width="429" height="13" fill="#f2f2f2" />
            <rect x="71" y="187" width="429" height="13" fill="#f2f2f2" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export const LoadingPrograms = () => {
  return (
    <div className="program-loading">
      <svg viewBox="0 0 840 617">
        <rect width="830" height="140" fill="#fff" />
        <rect x="33" y="17" width="68" height="68" fill="#efeded" />
        <rect x="116" y="17" width="319" height="15" fill="#e2e2e2" />
        <rect x="116" y="44" width="444" height="15" fill="#efeded" />
        <rect x="116" y="65" width="300" height="15" fill="#efeded" />
        <rect x="116" y="95" width="46" height="16" rx="8" fill="#e2e2e2" />
        <rect x="168" y="95" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="249" y="95" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="328" y="95" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect
          x="648"
          y="17"
          width="133"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="648"
          y="65"
          width="133"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="159" width="830" height="140" fill="#fff" />
        <rect x="33" y="176" width="68" height="68" fill="#efeded" />
        <rect x="116" y="176" width="319" height="15" fill="#e2e2e2" />
        <rect x="116" y="203" width="444" height="15" fill="#efeded" />
        <rect x="116" y="224" width="300" height="15" fill="#efeded" />
        <rect x="116" y="254" width="46" height="16" rx="8" fill="#e2e2e2" />
        <rect x="168" y="254" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="249" y="254" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="328" y="254" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect
          x="648"
          y="176"
          width="133"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="648"
          y="224"
          width="133"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="318" width="830" height="140" fill="#fff" />
        <rect x="33" y="335" width="68" height="68" fill="#efeded" />
        <rect x="116" y="335" width="319" height="15" fill="#e2e2e2" />
        <rect x="116" y="362" width="444" height="15" fill="#efeded" />
        <rect x="116" y="383" width="300" height="15" fill="#efeded" />
        <rect x="116" y="413" width="46" height="16" rx="8" fill="#e2e2e2" />
        <rect x="168" y="413" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="249" y="413" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="328" y="413" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect
          x="648"
          y="335"
          width="133"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="648"
          y="383"
          width="133"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="477" width="830" height="140" fill="#fff" />
        <rect x="33" y="494" width="68" height="68" fill="#efeded" />
        <rect x="116" y="494" width="319" height="15" fill="#e2e2e2" />
        <rect x="116" y="521" width="444" height="15" fill="#efeded" />
        <rect x="116" y="542" width="300" height="15" fill="#efeded" />
        <rect x="116" y="572" width="46" height="16" rx="8" fill="#e2e2e2" />
        <rect x="168" y="572" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="249" y="572" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect x="328" y="572" width="75" height="16" rx="8" fill="#e2e2e2" />
        <rect
          x="648"
          y="494"
          width="133"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="648"
          y="542"
          width="133"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
      </svg>
    </div>
  );
};
export const LoadingMatchProgram = () => {
  return (
    <div className="matchprogram-loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 840 1047">
        <rect width="840" height="1047" fill="none" />
        <rect width="390.29" height="509" fill="#fff" />
        <rect width="390.29" height="200" fill="#efeded" />
        <rect
          x="20.49"
          y="188"
          width="127.82"
          height="25"
          rx="10.99"
          fill="#a5a5a5"
        />
        <rect x="290.77" y="162" width="79.03" height="81" fill="#e8e8e8" />
        <rect x="12.68" y="250" width="213.68" height="20" fill="#e2e2e2" />
        <rect x="12.68" y="283" width="357.12" height="20" fill="#efeded" />
        <rect x="12.68" y="316" width="357.12" height="20" fill="#efeded" />
        <rect x="12.68" y="349" width="145.38" height="20" fill="#efeded" />
        <rect x="12.68" y="395" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="148.31" y="395" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="283.94" y="392" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="413.71" width="390.29" height="509" fill="#fff" />
        <rect x="413.71" width="390.29" height="200" fill="#efeded" />
        <rect
          x="434.2"
          y="188"
          width="127.82"
          height="25"
          rx="10.99"
          fill="#a5a5a5"
        />
        <rect x="704.48" y="162" width="79.03" height="81" fill="#e8e8e8" />
        <rect x="426.39" y="250" width="213.68" height="20" fill="#e2e2e2" />
        <rect x="426.39" y="283" width="357.12" height="20" fill="#efeded" />
        <rect x="426.39" y="316" width="357.12" height="20" fill="#efeded" />
        <rect x="426.39" y="349" width="145.38" height="20" fill="#efeded" />
        <rect x="426.39" y="395" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="562.02" y="395" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="697.65" y="392" width="85.86" height="88" fill="#e2e2e2" />
        <rect y="538" width="390.29" height="509" fill="#fff" />
        <rect y="538" width="390.29" height="200" fill="#efeded" />
        <rect
          x="20.49"
          y="726"
          width="127.82"
          height="25"
          rx="10.99"
          fill="#a5a5a5"
        />
        <rect x="290.77" y="700" width="79.03" height="81" fill="#e8e8e8" />
        <rect x="12.68" y="788" width="213.68" height="20" fill="#e2e2e2" />
        <rect x="12.68" y="821" width="357.12" height="20" fill="#efeded" />
        <rect x="12.68" y="854" width="357.12" height="20" fill="#efeded" />
        <rect x="12.68" y="887" width="145.38" height="20" fill="#efeded" />
        <rect x="12.68" y="933" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="148.31" y="933" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="283.94" y="930" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="413.71" y="538" width="390.29" height="509" fill="#fff" />
        <rect x="413.71" y="538" width="390.29" height="200" fill="#efeded" />
        <rect
          x="434.2"
          y="726"
          width="127.82"
          height="25"
          rx="10.99"
          fill="#a5a5a5"
        />
        <rect x="704.48" y="700" width="79.03" height="81" fill="#e8e8e8" />
        <rect x="426.39" y="788" width="213.68" height="20" fill="#e2e2e2" />
        <rect x="426.39" y="821" width="357.12" height="20" fill="#efeded" />
        <rect x="426.39" y="854" width="357.12" height="20" fill="#efeded" />
        <rect x="426.39" y="887" width="145.38" height="20" fill="#efeded" />
        <rect x="426.39" y="933" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="562.02" y="933" width="85.86" height="88" fill="#e2e2e2" />
        <rect x="697.65" y="930" width="85.86" height="88" fill="#e2e2e2" />
      </svg>
    </div>
  );
};
export const LoadingFavoriteProgram = () => {
  return (
    <div className="favoriteProgram-loading">
      <svg
        id="Calque_3"
        data-name="Calque 3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 840 617"
      >
        <title>Sans titre - 2</title>
        <rect x="3" width="837" height="617" fill="none" />
        <rect width="824" height="140" fill="#fff" />
        <rect x="32.37" y="17" width="66.7" height="68" fill="#efeded" />
        <rect x="113.79" y="17" width="312.92" height="15" fill="#e2e2e2" />
        <rect x="113.79" y="44" width="177.55" height="15" fill="#efeded" />
        <rect x="113.79" y="65" width="177.55" height="15" fill="#efeded" />
        <rect
          x="677.66"
          y="17"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="539.34"
          y="17"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="159" width="824" height="140" fill="#fff" />
        <rect x="32.37" y="176" width="66.7" height="68" fill="#efeded" />
        <rect x="113.79" y="176" width="312.92" height="15" fill="#e2e2e2" />
        <rect x="113.79" y="203" width="177.55" height="15" fill="#efeded" />
        <rect x="113.79" y="224" width="177.55" height="15" fill="#efeded" />
        <rect
          x="677.66"
          y="176"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="539.34"
          y="176"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="318" width="824" height="140" fill="#fff" />
        <rect x="32.37" y="335" width="66.7" height="68" fill="#efeded" />
        <rect x="113.79" y="335" width="312.92" height="15" fill="#e2e2e2" />
        <rect x="113.79" y="362" width="177.55" height="15" fill="#efeded" />
        <rect x="113.79" y="383" width="177.55" height="15" fill="#efeded" />
        <rect
          x="677.66"
          y="335"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="539.34"
          y="335"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
        <rect y="477" width="824" height="140" fill="#fff" />
        <rect x="32.37" y="494" width="66.7" height="68" fill="#efeded" />
        <rect x="113.79" y="494" width="312.92" height="15" fill="#e2e2e2" />
        <rect x="113.79" y="521" width="177.55" height="15" fill="#efeded" />
        <rect x="113.79" y="542" width="177.55" height="15" fill="#efeded" />
        <rect
          x="677.66"
          y="494"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#c9c9c9"
        />
        <rect
          x="539.34"
          y="494"
          width="130.47"
          height="34"
          rx="10.54"
          fill="#dddbdb"
        />
      </svg>
    </div>
  );
};
export const LoadingMatchResult = () => {
  return (
    <div className="matchresult-loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 840 418.24">
        <rect width="840" height="418.24" fill="none" />
        <rect width="269" height="197" fill="#fff" />
        <rect x="12.68" y="12.14" width="97.34" height="97.34" fill="#e8e8e8" />
        <rect
          x="125.51"
          y="12.14"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="125.51"
          y="55.7"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="125.51"
          y="78.22"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="126.51"
          y="113.09"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
        <rect x="276.51" width="269" height="197" fill="#fff" />
        <rect
          x="289.19"
          y="12.14"
          width="97.34"
          height="97.34"
          fill="#e8e8e8"
        />
        <rect
          x="402.01"
          y="12.14"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="402.01"
          y="55.7"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="402.01"
          y="78.22"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="403.01"
          y="113.09"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
        <rect x="553.01" width="269" height="197" fill="#fff" />
        <rect
          x="565.69"
          y="12.14"
          width="97.34"
          height="97.34"
          fill="#e8e8e8"
        />
        <rect
          x="678.52"
          y="12.14"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="678.52"
          y="55.7"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="678.52"
          y="78.22"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="679.52"
          y="113.09"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
        <rect y="206.14" width="269" height="197" fill="#fff" />
        <rect
          x="12.68"
          y="218.28"
          width="97.34"
          height="97.34"
          fill="#e8e8e8"
        />
        <rect
          x="125.51"
          y="218.28"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="125.51"
          y="261.83"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="125.51"
          y="284.35"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="126.51"
          y="319.23"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
        <rect x="276.51" y="206.14" width="269" height="197" fill="#fff" />
        <rect
          x="289.19"
          y="218.28"
          width="97.34"
          height="97.34"
          fill="#e8e8e8"
        />
        <rect
          x="402.01"
          y="218.28"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="402.01"
          y="261.83"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="402.01"
          y="284.35"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="403.01"
          y="319.23"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
        <rect x="553.01" y="206.14" width="269" height="197" fill="#fff" />
        <rect
          x="565.69"
          y="218.28"
          width="97.34"
          height="97.34"
          fill="#e8e8e8"
        />
        <rect
          x="678.52"
          y="218.28"
          width="100.85"
          height="17.05"
          fill="#e2e2e2"
        />
        <rect
          x="678.52"
          y="261.83"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="678.52"
          y="284.35"
          width="131.87"
          height="14.56"
          fill="#efeded"
        />
        <rect
          x="679.52"
          y="319.23"
          width="120.13"
          height="26.15"
          rx="13.07"
          fill="#a5a5a5"
        />
      </svg>
    </div>
  );
};
export const LoadingLabel = () => {
  return (
    <div className="label-loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 150">
        <title>Sans titre - 1</title>
        <rect width="270" height="150" fill="none" />
        <rect x="1" y="0.17" width="269" height="40" rx="8.23" fill="#dcdada" />
        <rect x="13" y="13.17" width="175" height="14" fill="#b5b5b5" />
        <circle cx="247.5" cy="20.17" r="10.5" fill="#ffffff" />
        <rect
          x="1"
          y="46.23"
          width="269"
          height="40"
          rx="8.23"
          fill="#dcdada"
        />
        <rect x="13" y="59.23" width="175" height="14" fill="#b5b5b5" />
        <circle cx="247.5" cy="66.23" r="10.5" fill="#ffffff" />
        <rect
          x="1"
          y="97.23"
          width="269"
          height="40"
          rx="8.23"
          fill="#dcdada"
        />
        <rect x="13" y="110.23" width="175" height="14" fill="#b5b5b5" />
        <circle cx="247.5" cy="117.23" r="10.5" fill="#ffffff" />
      </svg>
    </div>
  );
};
