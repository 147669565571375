/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import { PlanFreeDetails } from "../type";

interface ISpentBy {
  id: number;
  first_name: string;
  last_name: string;
  user_uri: string;
  number: string;
  at: string;
}

const IsvPlanDetails = (props: PlanFreeDetails) => {
  const history = useHistory();
  const urlActivity = "/community/activity-user/";
  const [key, setkey] = useState({
    activeId: "1",
    eventId: "1",
  });

  // render spent by FREE ADS
  const spentBy = (users: Array<ISpentBy>) =>
    users?.map((user: ISpentBy) => (
      <>
        <p key={user.first_name + " " + user.last_name}>
          {/* TODO define the url to redirect user */}
          {user.number} free credit spent by{" "}
          <a
            style={{ color: "#35a4cf" }}
            onClick={(e) => {
              e.preventDefault();
              history.push(urlActivity + user.id);
            }}
          >
            {user.first_name} {user.last_name}
          </a>
        </p>
        <small>Spent on {formatDate(user.at)}</small>
      </>
    ));

  const formatDate = (date: string) => {
    const newDate = new Date(Date.parse(date));
    return (
      newDate.toLocaleString("en-US", { month: "short" }) +
      " " +
      newDate.getDate() +
      ", " +
      newDate.getFullYear()
    );
  };

  return (
    <div className="contentBorder radius p-0">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 border-right info">
          <h5>Investor unlock</h5>
          <div className="color-orange font-medium font-weight-600 mt-3">
            {props.isv_unlock_investor.number_left} out of{" "}
            {props.isv_unlock_investor.number_max}
          </div>
          <p>Free investor unlock available</p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 info">
          <h5>Press releases</h5>
          <div className="color-orange font-medium font-weight-600 mt-3">
            {props.isv_press_release.number_left} out of{" "}
            {props.isv_press_release.number_max}
          </div>
          <p>Free press release credit available</p>
        </div>
      </div>
      <Accordion className="accordionForSetting" activeKey={key.eventId}>
        <div className="lineAccordion isvAcc">
          {(props.isv_press_release.spent_by &&
            props.isv_press_release.spent_by.length > 0) ||
          (props.isv_free_ads.spent_by &&
            props.isv_free_ads.spent_by.length > 0) ? (
            <Accordion.Toggle
              eventKey="1"
              onClick={() => {
                if (key.activeId == "1" && key.activeId == "1") {
                  setkey({ eventId: "0", activeId: "0" });
                } else {
                  setkey({ activeId: "1", eventId: "1" });
                }
              }}
              className={`btnAccordion ${key.activeId === "1" ? "active" : ""}`}
            ></Accordion.Toggle>
          ) : (
            <></>
          )}
          <Accordion.Collapse eventKey="1">
            <div className="panel-body row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 border-right info">
                {props?.isv_unlock_investor?.spent_by.map(
                  (element: ISpentBy) => (
                    <div className="spent">
                      <p>
                        {element.number} free unlock by{" "}
                        <a
                          style={{ color: "#35a4cf" }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(urlActivity + element.id);
                          }}
                        >
                          {element.first_name} {element.last_name}
                        </a>
                      </p>
                      <small>Spent on {formatDate(element.at)}</small>
                    </div>
                  )
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-4 info">
                <div className="spent">
                  {spentBy(props.isv_press_release.spent_by)}
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default IsvPlanDetails;
