import React from "react";

export type Iprops = {
  allViews: Array<any>;
  view: any;
  handleFilterView: (p: any) => void;
  resetFilter: () => void;
};
export default function Views(props: Iprops) {
  return (
    <div className="block">
      <h2 className="title">View</h2>
      <ul className="labelList">
        {props?.allViews?.map((el: any, index: number) => (
          <li
            className={props?.view === el?.key ? "active" : ""}
            key={index}
            onClick={() => props?.handleFilterView(el?.key)}
          >
            <span className="nameOfCategory">{el?.title}</span>
            {parseInt(el?.total) > 0 && <span>{el?.total}</span>}
          </li>
        ))}

        <li className="linkForLi reset" onClick={() => props?.resetFilter()}>
          <a
            href="javascript:;"
            className="resetFilter"
            onClick={(e) => e.preventDefault()}
          >
            <span className="icon-refresh" />
            Reset filter
          </a>
        </li>
      </ul>
    </div>
  );
}
