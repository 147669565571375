import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { getLabelCommunity } from "../../../redux/actions/community-action";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { IDiscutionCategorie } from "../../../redux/types/community-interface";
import LogoRound from "../../../resources/images/logo-round.svg";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  OEM_PROGRAM,
} from "../../../service/constant";
import { isModerator } from "../../../utils";

export type IPropsHeader = {
  createPost?: () => void;
  setShowLogin?: (b: boolean) => void;
  role: string[];
};
export default function SecondHeaderCommunity(props: IPropsHeader) {
  let userObject = useSelector((state: rootState) => state.userReducer);

  const userState = useSelector((state: rootState) => state.userReducer);
  const isOem = userState?.roles?.[0] === OEM_PROGRAM;

  let isGranteedFeeds =
    userObject?.roles?.[0] === OEM_PROGRAM ||
    userObject?.roles?.[0] === INVESTOR;

  let isUserOem =
    userObject?.roles?.[0] === OEM_PROGRAM ||
    userObject?.roles?.[0] === CONTRIBUTOR;

  const isConnected = userObject?.id !== "";
  const history = useHistory();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const dispatch = useDispatch();

  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const cbGetLabel = (data: any) => {};

  useEffect(() => {
    dispatch(getLabelCommunity(cbGetLabel));
    return () => {};
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToOneCategory = (params: any) => {
    history.push("/community/one-category/" + params?.id + "/0");
  };

  const goToActivityUser = () => {
    history.push("/community/activity-user/" + userObject?.id);
  };

  const goToFavoriteDiscussion = () => {
    userObject && userObject?.enable
      ? history.push("/community/favorite-discussions")
      : props?.setShowLogin && props?.setShowLogin(true);
  };

  const goCommunityHome = () => {
    history.push("/community/home");
  };

  const goToOtherCategory = (params: any) => {
    history.push("/community/view-other-category/" + params?.id);
  };

  const goDashBoard = () => {
    const urlPage = isOem
      ? "/community/oem-feeds"
      : "/community/investor-feeds";

    history.push(urlPage);
  };

  return (
    <>
      <div
        className={`secondMenu ${scrollPosition < 57 ? "" : "fixedSecondMenu"}`}
      >
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <img src={LogoRound} className="logoRound" alt="Logo Devinsider" />
            <h2
              className="title"
              style={{ cursor: "pointer" }}
              onClick={() => goCommunityHome()}
            >
              Community
            </h2>
            <div className="menuDynamic bl">
              {!isUserOem && (
                <div className="item">
                  <a className="parent">About the community</a>
                  <div className="submenu">
                    {communityReducer?.category_about_community?.map(
                      (el: IDiscutionCategorie, index: number) => (
                        <a
                          key={index}
                          href="javascript:;"
                          onClick={(e) => {
                            e?.preventDefault();
                            goToOtherCategory(el);
                          }}
                        >
                          {el?.title}
                        </a>
                      )
                    )}
                  </div>
                </div>
              )}

              <div className="item">
                <a className="parent">Forum categories</a>
                <div className="submenu">
                  {communityReducer?.discussionCategories?.map(
                    (el: IDiscutionCategorie, index: number) => (
                      <a
                        onClick={(e) => {
                          e?.preventDefault();
                          goToOneCategory(el);
                        }}
                        key={index}
                      >
                        {el?.title}
                      </a>
                    )
                  )}
                </div>
              </div>

              {!isModerator(props?.role) && (
                <>
                  <div className="item">
                    {isConnected && (
                      <a
                        href="javascript:;"
                        onClick={(e) => {
                          e.preventDefault();
                          goToActivityUser();
                        }}
                      >
                        Activity
                      </a>
                    )}
                  </div>
                  <div className="item">
                    {isConnected && (
                      <a
                        href="javascript:;"
                        onClick={(e) => {
                          e.preventDefault();
                          goToFavoriteDiscussion();
                        }}
                      >
                        Favorites
                      </a>
                    )}
                  </div>
                </>
              )}

              {isGranteedFeeds && (
                <div className="item">
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e?.preventDefault();
                      goDashBoard();
                    }}
                  >
                    My feed
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="rightItemHeader">
            {props?.role[0]?.toString() !== GUEST && (
              <div className="item">
                <a className="startPost" onClick={props?.createPost}>
                  Start a post
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
