import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { ISV } from "../../../../../service/constant/index";
import { isURL, refaCtoNullKey } from "../../../../../utils";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../../utils/country_phone";
import Button from "react-bootstrap/Button";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export type IpropsModal = {
  contactShow: boolean;
  setContactShow: () => void;
  cancelChange: () => void;
  dataSource: any;
};

export default function ModalEditInfoUser(props: IpropsModal) {
  const renderEmailLabel = (roles: Array<string>) => {
    if (roles.indexOf(ISV) !== -1) return "Primary email";
    return "Email";
  };
  return (
    <Modal
      show={props?.contactShow}
      onHide={() => props?.setContactShow()}
      className="modalDevinsider medium"
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0">
          <div className="col-12 p-0 form-group">
            <label htmlFor="" className="smallIcon">
              {renderEmailLabel(props?.dataSource?.userState.roles)}{" "}
              {props?.dataSource?.userState?.roles.indexOf(ISV) !== -1 ? (
                <span
                  className={
                    props?.dataSource?.userState?.lock_primary_email
                      ? "icon-locked"
                      : "icon-userIcon"
                  }
                  onClick={() =>
                    props?.dataSource?.setShowAudiance("primary_email")
                  }
                ></span>
              ) : (
                <></>
              )}
            </label>
            <input
              type="text"
              className="input-text form-control"
              readOnly
              value={props?.dataSource?.infoUserToEdit?.email}
            />
          </div>
          {/* ROLE ISV */}
          {props?.dataSource?.userState?.roles.indexOf(ISV) !== -1 ? (
            <div className="col-12 p-0 form-group">
              <label htmlFor="" className="smallIcon">
                Work email{" "}
                <span
                  className={
                    props?.dataSource?.userState?.lock_work_email
                      ? "icon-locked"
                      : "icon-userIcon"
                  }
                  onClick={() =>
                    props?.dataSource?.setShowAudiance("worked_email")
                  }
                ></span>
              </label>
              <input
                type="text"
                className="input-text form-control"
                readOnly
                value={props?.dataSource?.infoUserToEdit.primary_email}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="col-12 p-0 form-group">
            <label htmlFor="" className="smallIcon">
              Phone number{" "}
              {props?.dataSource?.userState?.roles.indexOf(ISV) !== -1 ? (
                <span
                  className={
                    props?.dataSource?.userState?.lock_phone_number
                      ? "icon-locked"
                      : "icon-userIcon"
                  }
                  onClick={() => props?.dataSource?.setShowAudiance("phone")}
                ></span>
              ) : (
                <></>
              )}
            </label>
            {!props?.dataSource?.statePhone && (
              <div className={`container-error pl-2 "d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">
                  The phone number is not a valid format
                </span>
              </div>
            )}
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                style={{
                  textIndent:
                    contact?.find(
                      (el: any) => el.name == props?.dataSource?.codeFlags
                    )?.dial_code?.length == 2
                      ? 35
                      : contact?.find(
                          (el: any) => el.name == props?.dataSource?.codeFlags
                        )?.dial_code?.length == 3
                      ? 50
                      : contact?.find(
                          (el: any) => el.name == props?.dataSource?.codeFlags
                        )?.dial_code?.length == 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                value={props?.dataSource?.infoUserToEdit.phone}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  props?.dataSource?.setInfoUserToEdit({
                    ...props?.dataSource?.infoUserToEdit,
                    phone: evt.target.value,
                  })
                }
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                (
                {contact &&
                  contact.find(
                    (el: any) => el.code == props?.dataSource?.codeFlags
                  )?.dial_code}
                )
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={props?.dataSource?.codeFlags}
                onSelect={(code) => {
                  props?.dataSource?.setcodeFlags(code);
                }}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
          <div className="col-12 p-0 form-group">
            <label htmlFor="">Linkedin </label>
            {props?.dataSource?.stateLinkDean && (
              <div className={`container-error pl-2 "d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">
                  your linkedin url is not a valid
                </span>
              </div>
            )}
            <div className="linkedintext">
              linkedin.com/in/
              <input
                type="text"
                className="input-text form-control"
                value={refaCtoNullKey(
                  props?.dataSource?.infoUserToEdit?.linkedin
                )}
                onBlur={() => {
                  let url: string = props?.dataSource?.infoUserToEdit?.linkedin;
                  if (url && url != "" && url != "null") {
                    if (
                      isURL(url) &&
                      !url?.includes("https://www.linkedin.com/in/")
                    ) {
                      props?.dataSource?.setstateLinkDean(true);
                    } else {
                      let custom = url?.includes("https://www.linkedin.com/in/")
                        ? url?.split("https://www.linkedin.com/in/").join("")
                        : url;

                      props?.dataSource?.setInfoUserToEdit({
                        ...props?.dataSource?.infoUserToEdit,
                        linkedin: custom,
                      });
                    }
                  }
                }}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  props?.dataSource?.setInfoUserToEdit({
                    ...props?.dataSource?.infoUserToEdit,
                    linkedin: evt.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancelbtn"
          onClick={() => {
            props?.setContactShow();
            props?.cancelChange();
          }}
        >
          Cancel
        </Button>

        <Button
          className="validate"
          onClick={() => props?.dataSource?.validAfterUpdate()}
          disabled={props?.dataSource?.isLoaderShow}
        >
          {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
