import React from "react";
import UseFilter, {
  filterType,
} from "../../../../controller/program-ctr/program-matchmaking-ctr/bloc-matching-panel/use-filter-ctr";
import { IprogramType } from "../../../../interface/program-type";
import Slider, { Range } from "rc-slider";

type IPropsFilter = {
  setSelectedType: (p: string) => void;
  selectedType: Array<string>;
  selectedCountry: Array<string>;
  setSelectedCountry: (p: string) => void;
  selectedProgramType: Array<string>;
  setSelectedProgramType: (p: string) => void;
  sliderValue: Array<number>;
  setSliderValue: (value: Array<number>) => void;
  lunchFilter: () => void;
  resetFilter: () => void;
  type: string;
};
const FilterMatchingPanel = (props: IPropsFilter) => {
  const providerFilterPanel = UseFilter(props?.type);

  const handleClickIndex = (index: number, currentLength: number) =>
    index > 6 ? 6 : currentLength;

  const generatedText = (currentIndex: number, allLength: number) =>
    currentIndex < 7 ? `See more(${allLength - currentIndex})` : "See less";

  return (
    <>
      <div className="filterFindIsv">
        <div className="contentFilter">
          <div className="containerBtn block">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props?.lunchFilter()}
            >
              Filter
            </button>
            <a
              href="javascript:;"
              className="resetFilter"
              onClick={(e: any) => {
                e?.preventDefault();
                props?.resetFilter();
              }}
            >
              <span className="icon-refresh"></span>
              Reset filter
            </a>
          </div>
          <div className="block">
            <h3
              className={`title withChild ${
                providerFilterPanel?.isShowBloc === filterType?.program_type
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerFilterPanel?.handleBlock(filterType.program_type)
              }
            >
              Program type
            </h3>
            <div className="listCheck">
              {providerFilterPanel?.programTypes?.map(
                (el: IprogramType, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={el?.id + el?.name}
                      checked={
                        props?.selectedType?.find(
                          (element) => element === el?.id?.toString()
                        )
                          ? true
                          : false
                      }
                      onChange={() =>
                        props?.setSelectedType(el?.id?.toString())
                      }
                    />
                    <label
                      htmlFor={el?.id + el?.name}
                      className="custom-control-label"
                    >
                      {el?.name}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="block">
            <h3
              className={`title withChild ${
                providerFilterPanel?.isShowBloc === filterType?.match_slider
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerFilterPanel?.handleBlock(filterType.match_slider)
              }
            >
              Match
            </h3>
            <Range
              className="targetInvestorrange"
              min={60}
              max={100}
              value={props?.sliderValue}
              onChange={(value: Array<number>) => {
                props?.setSliderValue(value);
              }}
              marks={{
                0: props?.sliderValue[0] + "%",
                1: "",
                2: "",
                3: "",
                4: "",
                5: "",
                6: "",
                7: "",
                8: "",
                9: "",
                10: props?.sliderValue[1] + "%",
              }}
            />
          </div>

          <div className="block">
            <h3
              className={`title withChild ${
                providerFilterPanel?.isShowBloc === filterType?.location
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerFilterPanel?.handleBlock(filterType.location)
              }
            >
              Headquarters location
            </h3>
            <div className="listCheck">
              {providerFilterPanel?.countries
                ?.filter(
                  (el, index) => index < providerFilterPanel?.indexLocation
                )
                ?.map((el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={el?.id?.toString() + el?.country_name}
                      checked={
                        props?.selectedCountry?.find(
                          (element) =>
                            element?.toString() === el?.id?.toString()
                        )
                          ? true
                          : false
                      }
                      onChange={() =>
                        props?.setSelectedCountry(el?.id?.toString())
                      }
                    />
                    <label
                      htmlFor={el?.id?.toString() + el?.country_name}
                      className="custom-control-label"
                    >
                      {el?.country_name}
                    </label>
                  </div>
                ))}

              {providerFilterPanel?.countries?.length > 5 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => {
                    providerFilterPanel?.setIndexLocation(
                      handleClickIndex(
                        providerFilterPanel?.indexLocation,
                        providerFilterPanel?.countries?.length
                      )
                    );
                  }}
                >
                  {generatedText(
                    providerFilterPanel?.indexLocation,
                    providerFilterPanel?.countries?.length
                  )}
                </a>
              )}
            </div>
          </div>
          <div className="block">
            <h3
              className={`title withChild ${
                providerFilterPanel?.isShowBloc === filterType?.categories
                  ? "open"
                  : ""
              }`}
              onClick={(e) =>
                providerFilterPanel?.handleBlock(filterType.categories)
              }
            >
              Program solution
            </h3>
            <div className="listCheck">
              {providerFilterPanel?.categories
                ?.filter(
                  (el, index) => index < providerFilterPanel?.indexCategorie
                )
                .map((el: any, index: number) => (
                  <div
                    className="custom-control custom-checkbox orange"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={el?.id + el?.name}
                      checked={
                        props?.selectedProgramType?.find(
                          (element) =>
                            element?.toString() === el?.id?.toString()
                        )
                          ? true
                          : false
                      }
                      onChange={() =>
                        props?.setSelectedProgramType(el?.id?.toString())
                      }
                    />
                    <label
                      htmlFor={el?.id + el?.name}
                      className="custom-control-label"
                    >
                      {el?.name}
                    </label>
                  </div>
                ))}
              {providerFilterPanel?.categories?.length > 5 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => {
                    providerFilterPanel?.setIndexCategorie(
                      handleClickIndex(
                        providerFilterPanel?.indexCategorie,
                        providerFilterPanel?.categories?.length
                      )
                    );
                  }}
                >
                  {generatedText(
                    providerFilterPanel?.indexCategorie,
                    providerFilterPanel?.categories?.length
                  )}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(FilterMatchingPanel);
