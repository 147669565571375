import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { rootState } from "../../../../redux/reducers";
import { getData, getDataWithoutToken } from "../../../../service/api";
import { operationBillingAmount, url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [modalUnlock, setModalUnlock] = useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const [amountUnlockInvestor, setAmountUnlockInvestor] = useState<any>({
    current_price: "",
    instead_of: "",
  });

  const [idInvestorPage, setIdInvestorPage] = useState<number | undefined>();

  const [stateUnlock, setStateUnlock] = useState<
    | {
        number_left: number;
        number_max: number;
      }
    | undefined
  >();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();
  const [investorToUnlockId, setInvestorToUnlockId] = useState<string>();
  const [freeUnlock, setFreeUnlock] = useState<number>(0);
  const [isGettingFreePlan, setIsGettingFreePlan] = useState<boolean>(false);

  const closeUnlockModal = () => {
    setModalUnlock(false);
    setChoiseValue(undefined);
  };

  const showModalUnlock = (id: number) => {
    setModalUnlock(true);
    setIdInvestorPage(id);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  const getInvestorUnlockFreePlan = () => {
    const now = new Date();

    return getDataWithoutToken(
      url.settings.billingCenter + "/information/plan_details",
      { month: getMonth(now.getMonth()) + " " + now.getFullYear() },
      userReducer.token
    );
  };

  const goToPaymentModal = (investorId: string) => {
    if (isGettingFreePlan) return;
    setInvestorToUnlockId(investorId);
    setIsGettingFreePlan(true);
    getInvestorUnlockFreePlan()
      .then((response) => {
        setFreeUnlock(
          response.data.free_status.isv_unlock_investor.number_left || 0
        );
        setProduct({
          lookupKey: "INVESTOR_UNLOCK",
          name: "Investor unlock",
        });
        setShowPaymentModal(true);
      })
      .catch((error) => {
        toast("Error getting billing plan");
        console.log(error);
      })
      .finally(() => {
        setIsGettingFreePlan(false);
      });
  };

  //load amount to unlock investor
  useEffect(() => {
    (async () => {
      let response = await getData(
        url.settings.billingCenter +
          "/" +
          operationBillingAmount.ISV_UNLOCK_INVESTOR +
          "/" +
          companyPageReducer.id,
        userReducer.token
      );
      if (response?.status === 200) {
        setAmountUnlockInvestor(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    setStateUnlock(undefined);
  }, [modalUnlock]);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { isv_unlock_investor }: any = response.data.free_status;
        setStateUnlock(isv_unlock_investor);
        setChargingPlanDetails(false);
        const isHaveFree = isv_unlock_investor?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue("FREE");
        }
      }
    }
    if (modalUnlock) {
      getPlanDetails();
    }
  }, [currentMonth, modalUnlock]);

  return {
    modalUnlock,
    closeUnlockModal,
    setModalUnlock,
    chargingPlanDetails,
    stateUnlock,
    handleChoise,
    choiseValue,
    showModalUnlock,
    idInvestorPage,
    amountUnlockInvestor,
    setIdInvestorPage,
    goToPaymentModal,
    showPaymentModal,
    setShowPaymentModal,
    freeUnlock,
    product,
    investorToUnlockId,
    isGettingFreePlan,
  };
}
