/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { HIDDEN_INFORMATION } from "../../../../../../service/constant/index";
import {
  bolderedText,
  checkIfExist,
  fileUrl,
  isExist,
} from "../../../../../../utils";
import ReactHtmlParser from "react-html-parser";
import LockedFile from "../../../../../../resources/images/locked.png";
import { useParams } from "react-router-dom";
const LeadershipTeam = (props: any) => {
  const params: { id: any } = useParams();
  const isLocked = props.name && props.name.includes(HIDDEN_INFORMATION);
  const isOtherUser = params?.id ? true : false;

  const isLockedPreview =
    isOtherUser && isLocked && isExist(props?.description);

  const isLockedEmpty = isLockedPreview && !isExist(props?.description);

  const isShowUnlocked = isOtherUser && !isLocked;

  const isShowDescription = !isOtherUser && isExist(props?.description);

  const isShowFakeText = !isOtherUser && !isExist(props?.description);

  return (
    <div className="item">
      <div className="photo">
        <img src={fileUrl(props.photo_profil)} alt="profile picture" />
        <p className="linkedin_link">
          {isLocked ? (
            <>
              <div className="name">[hidden information]</div>
            </>
          ) : (
            props.name + " "
          )}

          {checkIfExist(props.linkedin) && !isLocked && (
            <a
              href={"https://linkedin.com/in/" + props.linkedin}
              target="_blank"
            >
              <span className="icon-linkedin-square"></span>
            </a>
          )}
        </p>
        <span>
          {isLocked
            ? ReactHtmlParser(bolderedText(props.job_title))
            : props.job_title}
        </span>
      </div>
      <div className="desc" style={{ whiteSpace: "break-spaces" }}>
        {(isShowDescription || isShowUnlocked) && props?.description}
        {isShowFakeText && props?.descriptionFauxTexte}
        {isLockedPreview && (
          <div className="lockedFile">
            <img src={LockedFile} alt="" width={"100%"} />
          </div>
        )}
        {isLockedEmpty && ""}
      </div>
    </div>
  );
};

export default LeadershipTeam;
