import React from "react";
import "./isv.scss";
import Header from "../../../widget/index";
import UseProfilCtr from "../../../../controller/profil-ctr/use-profil-ctr";

//component to output
import ProfilHeader from "./profil-header";
import ProfilBodyIsv from "./profil-body/profil-body-isv";
import ProfilBodyInvestor from "./profil-body/profil-body-investor";
import ProfilBodyReseller from "./profil-body/profil-body-reseller";
import ProfilBodyGuest from "./profil-body/profil-body-guest";
import ProfilBodyOemPage from "./profil-body/profil-body-oem";
import ProfilBodyExpertPage from "./profil-body/profil-body-expert";
import ProfilBodyModerator from "./profil-body/profil-body-moderator";

import {
  INVESTOR,
  ISV,
  RESELLER,
  GUEST,
  OEM_PROGRAM,
  CONTRIBUTOR,
  MODERATOR,
} from "../../../../service/constant";
import Footer from "../../../widget/footer/footer";

export default function Profil() {
  const providerUseProfilCtr = { ...UseProfilCtr() };

  const renderProfilBody = (providerUseProfilCtr: any) => {
    //get role
    const role = providerUseProfilCtr.userState.roles.filter(
      (role: string) => role !== "ROLE_USER"
    )[0];
    if (role === ISV) return <ProfilBodyIsv {...providerUseProfilCtr} />;
    if (role === INVESTOR)
      return <ProfilBodyInvestor {...providerUseProfilCtr} />;
    if (role === RESELLER)
      return <ProfilBodyReseller {...providerUseProfilCtr} />;
    if (role === GUEST) return <ProfilBodyGuest {...providerUseProfilCtr} />;
    if (role === CONTRIBUTOR)
      return <ProfilBodyExpertPage {...providerUseProfilCtr} />;
    if (role === OEM_PROGRAM) return <ProfilBodyOemPage />;
    if (role === MODERATOR)
      return <ProfilBodyModerator {...providerUseProfilCtr} />;

    return <></>;
  };
  return (
    <div className="isv profilIsv">
      <Header />
      <div className="contentProfil">
        <ProfilHeader {...providerUseProfilCtr} />
        <div className="row otherInfoProfil">
          {renderProfilBody({ ...providerUseProfilCtr })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
