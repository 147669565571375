import {
  ADD_NEW_OEM_PROGRAM,
  CREATE_USER,
  HANDLE_COMMUNITY_PROFILE,
  LOGOUT,
  REFRESH_TOKEN,
  REMOVE_PHOTO_PROFILE_USER,
  SIGNIN,
  UPDATE_BILLING_CENTER_CARD_EXPERT,
  UPDATE_BILLING_CENTER_CARD_GUEST,
  UPDATE_BILLING_CENTER_CARD_ISV,
  UPDATE_EMAIL_USER,
  UPDATE_EXPERT_OVERVIEW,
  UPDATE_INFO_USER,
  UPDATE_LOGO_GUEST,
  UPDATE_OEM_PROGRAM_INFO,
  UPDATE_OEM_PROGRAM_ROLE,
  UPDATE_PROGRAM_LIST,
  UPDATE_USER,
} from '../../actions/acounts-action';
import { IAction, UserObject } from '../../types/acount-interface';
export const initialState: UserObject = {
  //Bug #47415 Visibility setting display in community
  encoded_username: '',
  email: '',
  roles: [],
  username: '',
  enable: false,
  last_name: '',
  first_name: '',
  real_first_name: '',
  real_last_name: '',
  plain_text: '',
  file_name: '',
  country: {
    id: '',
    country_name: '',
    continent: {
      name: '',
    },
  },
  phone: '',
  phone_number_code: '',
  company_name: '',
  job_title: '',
  real_company_name: '',
  real_job_title: '',
  website: '',
  isvInCompanyPage: null,
  guest_user_type: '',
  primary_email: '',
  linkedin: '',
  city: '',
  token: '',
  token_mercure: '',
  token_notification: [],
  user_community_profil: '',
  lock_primary_email: false,
  lock_work_email: false,
  lock_phone_number: false,
  created_at: '',
  refreshToken: '',
  id: '',
  oemProgramMembers: [],
  investor_members: {
    investor_page: {
      id: '0',
      organization_name: '',
      photo_profil: '',
      status: false,
    },
    role: '',
  },
  resellerCompanies: [
    {
      id: '',
      company_trade_name: '',
      company_legal_name: '',
      payment_method: '',
      synchronised: false,
    },
  ],
  guestCompany: {
    id: '',
    logo: '',
    payment_method: '',
  },
  expertCompany: {
    id: '',
    company_overview: '',
    logo: '',
    city: '',
    company_legal_name: '',
    company_trade_name: '',
    country: {
      country_name: '',
      id: 0,
    },
    website: '',
    payment_method: '',
  },
  payment_method: '',
};

export const userReducer = (
  state = initialState,
  action: IAction
): UserObject => {
  switch (action.type) {
    //Bug #47385 delete profile photo
    case REMOVE_PHOTO_PROFILE_USER:
      return { ...state, file_name: action.payload };
    case CREATE_USER:
      return { ...state, ...action.payload };
    case SIGNIN:
      return { ...state, ...action.payload };
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case UPDATE_EMAIL_USER:
      return { ...state, ...action.payload };
    case UPDATE_INFO_USER:
      return { ...state, ...action.payload };
    case UPDATE_OEM_PROGRAM_INFO:
      return {
        ...state,
        oemProgramMembers: helperUpdateOem(
          action?.payload,
          state?.oemProgramMembers
        ),
      };
    case UPDATE_OEM_PROGRAM_ROLE:
      return {
        ...state,
        oemProgramMembers: action.payload,
      };
    case ADD_NEW_OEM_PROGRAM:
      return {
        ...state,
        oemProgramMembers: [...state.oemProgramMembers, action?.payload],
      };

    case UPDATE_LOGO_GUEST:
      return {
        ...state,
        guestCompany: action.payload,
      };

    case UPDATE_EXPERT_OVERVIEW:
      return {
        ...state,
        expertCompany: action.payload,
      };

    case HANDLE_COMMUNITY_PROFILE:
      return {
        ...state,
        user_community_profil: action.payload,
      };
    case UPDATE_PROGRAM_LIST:
      return {
        ...state,
        oemProgramMembers: action.payload,
      };

    case REFRESH_TOKEN:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state };
    case UPDATE_BILLING_CENTER_CARD_ISV:
      return {
        ...state,
        payment_method: action.payload,
      };
    case UPDATE_BILLING_CENTER_CARD_GUEST:
      return {
        ...state,
        guestCompany: {
          ...state?.guestCompany,
          payment_method: action.payload,
        },
      };
    case UPDATE_BILLING_CENTER_CARD_EXPERT:
      return {
        ...state,
        expertCompany: {
          ...state?.expertCompany,
          payment_method: action.payload,
        },
      };
    default:
      return state;
  }
};

const helperUpdateOem = (data: any, state: any) => {
  let tempData = state?.map((el: any) => {
    if (el?.oem_program?.id?.toString() === data?.id?.toString()) {
      let changeData = {
        ...el,
        oem_program: {
          ...state?.oem_program,
          program_name: data?.oem_name,
        },
      };
      return changeData;
    } else {
      return el;
    }
  });

  return tempData;
};

// role: string;
//   oem_program: {
//     id: number;
//     program_name: string;
//     status: boolean;
//   };
