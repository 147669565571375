import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useEventListener from "../../hooks/event-handler";
import { IsvProgramType } from "../../interface/isv-type";
import { updateOemProgramBillingDetails } from "../../redux/actions/oem-program-action";
import { rootState } from "../../redux/reducers";
import { IAction } from "../../redux/types/acount-interface";
import UseUnlockInvestorIsv from "../../screen/widget/payement-investors/unlock-isv/use-unlock-investor";
import UseOemUnlockIsv from "../../screen/widget/payement-oem/unlock-isv/use-unlock-isv";
import {
  patchDataService,
  postJsonDataService,
} from "../../service/applicatif/back-end-service";
import { INVESTOR, OEM_PROGRAM, url } from "../../service/constant";
import { conversationType } from "../../utils";
import UseEventFilters from "./use-handle-event-filter-ctr";
import UseMatchingIsv from "./use-matching-isv-ctr";

const filtered = [
  {
    value: "relevance",
    label: "Relevance",
  },
  {
    value: "last_update",
    label: "Last Update",
  },
  {
    value: "last_unlocked",
    label: "Last Unlocked",
  },
];

const GET_ISV_FAVORITES = "GET_ISV_FAVORITES";
const ADD_FAVORITE = "ADD_FAVORITE";
const UNLOCK = "UNLOCK";

const helperUnlocked = (state: Array<any>, data: any) => {
  return state?.map((el: any) => {
    if (el?.isv?.id?.toString() === data?.isv?.id?.toString()) {
      return data;
    } else return el;
  });
};

type IPropsInitialState = {
  isv: Array<IsvProgramType>;
  currentPage: number;
  totalDataPaginate: number;
  unlocked_left: number;
};
const initialState: IPropsInitialState = {
  isv: [],
  totalDataPaginate: 0,
  currentPage: 0,
  unlocked_left: 0,
};

const helperAddFavorie = (state: Array<any>, id: number) => {
  return state?.filter((el: any) => el?.isv?.id?.toString() !== id?.toString());
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_ISV_FAVORITES:
      return {
        ...state,
        isv: action.payload?.isvListe,
        currentPage: action?.payload?.currentPage,
        totalDataPaginate: action.payload?.totalDataPaginate,
      };

    case ADD_FAVORITE:
      return {
        ...state,
        isv: helperAddFavorie(state?.isv, action.payload),
      };

    case UNLOCK: {
      return {
        ...state,
        isv: helperUnlocked(state?.isv, action.payload),
      };
    }
    default:
      return state;
  }
};

export default function UseFavoritesCtr() {
  const [orderBy, setOrderBy] = useState("DESC");

  const [search_name, setSearch_name] = useState("");

  const [page, setPage] = useState(1);

  const [sortBy, setSortBy] = useState<string>("relevance");

  const userState = useSelector((state: rootState) => state.userReducer);

  const [loaderFavorite, setloaderFavorite] = useState<boolean>(false);

  const selectedOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const [allIsvFavorie, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);

  const history = useHistory();

  var ProviderinvestorUnlockIsv: any;
  var ProviderOemUnlockIsv: any;
  const isOemUser = userState?.roles?.[0] === OEM_PROGRAM;
  const isInvestor = userState?.roles?.[0] === INVESTOR;
  if (isInvestor) {
    ProviderinvestorUnlockIsv = UseUnlockInvestorIsv();
  }

  if (isOemUser) {
    ProviderOemUnlockIsv = UseOemUnlockIsv();
  }

  const providerMatching = UseMatchingIsv();
  
  const [loading, setLoading] = useState<boolean>(false);

  const dispatchRedux = useDispatch();
  
  const {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
  } = UseEventFilters();

  let oemParams = {
    oem: selectedOemProgram?.program?.id,
  };

  let investoPrams = {
    funding_round: selectedFunding?.join(),
    fundraising_goal: selectedFundraising?.join(),
  };

  let defaultCombine = {
    hide_unlocked: "",
    hide_blacklisted: "",
    minimumMatch: sliderValue[0],
    maximumMatch: sliderValue[1],
    country: selectedCountry?.join(),
    segment: selectedSegment?.join(),
    targeted_industry: selectedTargeted?.join(),
    company_size: selectedCompanySize?.join(),
    annual_turnover: selectedAnnual?.join(),
    sortBy,
    orderBy,
    searchCompanyName: search_name,
    page,
  };

  let defaultParams = isOemUser
    ? { ...defaultCombine, ...oemParams }
    : { ...defaultCombine, ...investoPrams };

  const getAllFavorites = async (params: any, dispatch: any) => {
    setloaderFavorite(true);

    let urlValue = isOemUser
      ? url?.findIsv?.get_all_favorite
      : url?.matching_investor_isv?.filteredFavorie;

    const response = await postJsonDataService(
      urlValue,
      params,
      userState?.token
    );
    if (response?.status === 200) {
      const result = {
        isvListe: response?.data?.results,
        totalDataPaginate: response?.data?.total_to_paginate,
        currentPage: params?.page,
      };
      dispatch({ type: GET_ISV_FAVORITES, payload: result });
    }
    setloaderFavorite(false);
  };

  const lunchFilter = async () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      page: 1,
    };
    await getAllFavorites(paramsChange, dispatch);
  };

  const resetFilter = async () => {
    resetFilterEvent();

    if (search_name !== "") setSearch_name("");
    if (page !== 1) setPage(1);
    if (sortBy !== "relevance") setSortBy("relevance");

    let paramsChange = {
      ...defaultParams,
      hide_unlocked: "",
      hide_blacklisted: "",
      minimumMatch: 60,
      maximumMatch: 100,
      country: "",
      segment: "",
      targeted_industry: "",
      company_size: "",
      annual_turnover: "",
      sortBy,
      orderBy,
      searchCompanyName: "",
      page: 1,
    };
    await getAllFavorites(paramsChange, dispatch);
  };

  const handleSearche = async () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      searchCompanyName: search_name,
      page: 1,
    };
    await getAllFavorites(paramsChange, dispatch);
  };

  const keyEnter = async ({ key }: any) => {
    if (key == "Enter") {
      handleSearche();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const handleFilter = async (params: any) => {
    setSortBy(params?.value);
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      sortBy: params?.value,
      page: 1,
    };
    await getAllFavorites(paramsChange, dispatch);
  };

  useEffect(() => {
    (async () => {
      await getAllFavorites(defaultParams, dispatch);
    })();
  }, []);

  const handleFavorite = async (id: number) => {
    let urlFavorite = isOemUser
      ? url?.findIsv?.mark_as_favorite +
        "/" +
        selectedOemProgram?.program?.id +
        "/" +
        id
      : url?.matching_investor_isv?.add_favorie + id;

    let result: any = await patchDataService(urlFavorite, {}, userState?.token);
    if (result?.status === 200) {
      dispatch({ type: ADD_FAVORITE, payload: id });
    }
  };

  const loadMoreIsvMatching = async (p: number) => {
    setPage(p);
    let paramsChange = {
      ...defaultParams,
      page: p,
    };
    await getAllFavorites(paramsChange, dispatch);
  };

  const unLockedPage = async (id: any) => {
    loading && setLoading(false);
    isInvestor && ProviderinvestorUnlockIsv.showModalUnlock(id);
    isOemUser && ProviderOemUnlockIsv.showModalUnlock(id);
  };
  const oemUnlockIsvPage = async (
    isFree: boolean,
    setLoading: (s: boolean) => void,
    setShowPaymentStatus?: (
        show: boolean,
        data: {
          status: 'success' | 'error';
          invoiceNumber?: string;
          invoiceUrl?: string;
        }
    ) => void
) => {
  // setLoading(true);
  // const isFree = ProviderOemUnlockIsv?.choiseValue === "FREE";

  let urlUnlock =  url?.findIsv?.unlocked +
      "/" +
      selectedOemProgram?.program?.id +
      "/" +
      ProviderOemUnlockIsv?.idIsv +
      (isFree ? "/0" : "/1");

  // ProviderOemUnlockIsv?.setButtonUnlockLoader(true);

  patchDataService(urlUnlock, {}, userState?.token)
    .then((result) => {
      if (result?.status === 200) {
        dispatch({ type: UNLOCK, payload: result?.data?.matching_unlocked });
        dispatchRedux(
          updateOemProgramBillingDetails(
            result?.data?.oem_program_program_billing_details
          )
        );
        // if (isFree) {
          toast("Unlocked successfully");
        // } else {
        //   setShowPaymentStatus && setShowPaymentStatus(
        //       true,
        //       {
        //         status: "success",
        //         invoiceNumber: "invoice number from backend", //todo Diams
        //         invoiceUrl: "", //todo Diams
        //       }
        //   )
        // }
        goToIsvPage(result?.data?.matching_unlocked?.isv?.id, true);
      } else {
        // if (isFree) {
          toast("An Error Occurred");
        // } else {
        //   setShowPaymentStatus && setShowPaymentStatus(
        //       true,
        //       {
        //         status: "error"
        //       }
        //   )
        // }
      }
    }).catch(() => {})
    .finally(() => {
      ProviderOemUnlockIsv?.closeUnlockModal();
      setLoading(false);
      // ProviderOemUnlockIsv?.setButtonUnlockLoader(false);
    });
};

const investorUnlockIsvPage = async (
  isFree: boolean,
  paymentMethodId: string | null,
  setLoading: (s: boolean) => void,
  savePaymentMethod?: boolean,
  setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
  ) => void
) => {
  setLoading(true);

  let urlUnlocked = url?.matching_investor_isv?.unlocked_isv + ProviderinvestorUnlockIsv?.idIsv;

  const params = {
    choice: ProviderinvestorUnlockIsv?.choiseValue,
    paymentId: paymentMethodId ?? "",
    savePaymentMethod: savePaymentMethod ?? false,
  };
  patchDataService(urlUnlocked, isInvestor ? params : {}, userState?.token)
    .then((result) => {
      if (result) {
        dispatch({ type: UNLOCK, payload: result?.data?.matching_unlocked });
        dispatchRedux(
          updateOemProgramBillingDetails(
            result?.data?.oem_program_program_billing_details
          )
        );

        if (isFree) {
          toast("Unlocked successfully");
        } else {
          setShowPaymentStatus && setShowPaymentStatus(
              true,
              {
                status: "success",
                invoiceNumber: result?.data?.invoice_number,
                invoiceUrl: result?.data?.invoice_url
              }
          )
        }
        
      }
    })
    .catch(() => {
        if (isFree) {
          setLoading(false)
          ProviderinvestorUnlockIsv?.setShowPaymentModal(false)
          toast('An Error Occurred');
        }
        else {
          setShowPaymentStatus && setShowPaymentStatus(true, {
            status: 'error',
          })
        }
    })
    .finally(() => {
      ProviderinvestorUnlockIsv?.closeUnlockModal();
      setLoading(false);
    });
};

  const constactIsv = (idIsv: number) => {
    history.push("/contact/discussion", {
      userId: idIsv,
      discussionType:
        userState.roles[0] === OEM_PROGRAM
          ? conversationType.oem_to_isv
          : conversationType.investor_to_isv,
    });
  };

  const goToIsvPage = (id: string, isUnlocked: boolean = false) => {
    if (isUnlocked) {
      history.push("/company/company-unlocked/" + id);
    } else {
      history.push("/isv/profil-locked/" + id);
    }
  };

  const paymentMethodCallBack = (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
        show: boolean,
        data: {
          status: 'success' | 'error';
          invoiceNumber?: string;
          invoiceUrl?: string;
        }
    ) => void
) => {
  if(isOemUser && ProviderOemUnlockIsv?.idIsv) oemUnlockIsvPage(isFree, setLoading, setShowPaymentStatus);
  else if(isInvestor && ProviderinvestorUnlockIsv?.idIsv) investorUnlockIsvPage(isFree, paymentMethodId, setLoading, savePaymentMethod, setShowPaymentStatus);
  else {
    toast("An error occurred");
    setLoading(false);
    isOemUser && ProviderOemUnlockIsv?.closeUnlockModal();
    isInvestor && ProviderinvestorUnlockIsv?.closeUnlockModal(false);
  }
};
  return {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    lunchFilter,
    resetFilter,
    filtered,
    search_name,
    setSearch_name,
    sortBy,
    setSortBy,
    orderBy,
    setOrderBy,
    handleSearche,
    handleFilter,
    loaderFavorite,
    allIsvFavorie,
    handleFavorite,
    loadMoreIsvMatching,
    userState,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
    unLockedPage,
    constactIsv,
    goToIsvPage,
    ProviderinvestorUnlockIsv,
    loading,
    isvMatchingLeft:
      selectedOemProgram?.program?.oemProgramBillingDetails?.unlock_isv_number,
    isInvestor,
    isOemUser,
    ProviderOemUnlockIsv,
    investorUnlockIsvPage,
    oemUnlockIsvPage,
    paymentMethodCallBack,
    providerMatching
  };
}
