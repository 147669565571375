import AlternativeFinancing from '../screen/container/alternative-financing/home-page/homePage';
import AlternativeFinancingPost from '../screen/container/alternative-financing/post-alternative/post-alternative';
import Listings from '../screen/container/alternative-financing/listing-alternative/listing';
import Ads from '../screen/container/alternative-financing/detail-alternative/detail';
import Favorite from '../screen/container/alternative-financing/favorite-alternative/favorite';

export const routesAF = [
    {
      component: AlternativeFinancing,
      exact: true,
      path: '/alternative-financing/home',
      guard: 'public',
    },

    {
      component: AlternativeFinancingPost,
      exact: true,
      path: '/alternative-financing/post',
      guard: 'public',
    },

    {
      component: Listings,
      exact: true,
      path: '/alternative-financing/listing',
      guard: 'private',
    },

    {
      component: Ads,
      exact: true,
      path: '/alternative-financing/ads/:id',
      guard: 'public',
    },
    {
      component: Favorite,
      exact: true,
      path: '/alternative-financing/favorite',
      guard: 'private',
    },

    {
      component: AlternativeFinancingPost,
      exact: true,
      path: '/alternative-financing/edit/:id',
      guard: 'private',
    },
]