import React from "react";
import Header from "../../widget/index";
import company from "../../../resources/images/company2.png";
import defaultCompany from "../../../resources/images/defaultCompany.png";
import { useHistory } from "react-router";
import "./software-labeling.scss";

export default function SoftwareLabeling() {
    const history = useHistory();
    const goToAds = () => {
        history.push("/ma/ads");
    };
    return (
        <div className="findIsvcontainer programContainer softwareContainer ">
        <Header />
            <div className="filterFindIsv">
                <div className="contentFilter">
                    <div className="containerBtn block infoProgramContainer">
                        <button className="btn btn-devinsider cancel">Filter</button>
                        <a href="javascript:;" className="resetFilter"><span className="icon-refresh"></span>Reset filter</a>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">Solution</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">Blockchain</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">IOT</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(11)</a>
                        </div>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">Industry</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">Aerospace</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">IOT</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(10)</a>
                        </div>
                    </div>
                    <div className="block">
                        <h3 className="title withChild open">HQ location</h3>
                        <div className="listCheck">
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type0" className="custom-control-input" value="1" />
                                <label htmlFor="" className="custom-control-label">France</label>
                            </div>
                            <div className="custom-control custom-checkbox orange">
                                <input type="checkbox" id="type1" className="custom-control-input" value="2" />
                                    <label htmlFor="type1" className="custom-control-label">Germany</label>
                            </div>
                            <a href="javascript:;" className="seemore">See more(2)</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto minHeightwin">
                <div className="listOfIsv">
                    <div className="headerOfList">
                        <div className="leftHead">
                            <div className="number">Showing <b>153</b> ads</div>
                        </div>
                    </div>
                    <div className="scrollContainer">
                    <div className="aboutSection">
                    This section is dedicated to helping software vendors explore OEM partnerships, APIs, and white labeling business models. Our community consists of your peers who could benefit from technology partnerships for product enhancement. Post ads to find your next technology partner. <br/><br/>

                    If you have a defined ISV/OEM partnership program in place, create a program page and leverage our matchmaking technology.<br/><br/>

                    Contact us if you need help getting started.
                    </div>
                        <ul className="listOfIsvMatch soft">
                            <li
                            onClick={() => goToAds()}
                            >
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li
                            onClick={() => goToAds()}
                            >
                                <div className="isvLogo">
                                    <img src={defaultCompany} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li
                            onClick={() => goToAds()}
                            >
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                            <li
                            onClick={() => goToAds()}
                            >
                                <div className="isvLogo">
                                    <img src={company} alt=""/>
                                </div>
                                <a href="javasctipt:;"><span className="ico icon-fav-line"></span></a>
                                <div>
                                    <span className="title">Looking for bla bla bla bla bla bla </span>
                                    <div className="name">Evostream
                                        <div className="role">ISV</div>
                                    </div>
                                    <div className="country">Paris, France</div>
                                    <div className="desc">
                                        Lorem ipsum dolor sit amet, consectetur 
                                        adipiscing elit. Aenean euismod bibendum laoreet. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                                    </div>
                                    <div className="date">
                                        1 Month
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}