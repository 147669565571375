import Header from "../../../../widget/header-logo-center/header-logo-center";
import StepperOem from "../../../../widget/stepper/StepperOem";
import React from "react";
import UseCompleteCompanyTarget from "../../../../../controller/account-ctr/create-oem-account/use-complete-company-target";
import SliderCompanySize from "../../../../widget/slider-company-size-slider";
import FundRaisingGoal from "../../../../widget/slider-annual-turnover";
import OEMCountry from "../../../../widget/pays";

export default function CompleteCompanyTargetOem() {
  const {
    companySizeSlide,
    companySize,
    setCompanySize,
    customSetCompanySize,
    customSetAnnualSize,
    annualTurnoverSlide,
    unitTurnoverMax,
    unitTurnover,
    annualTurnover,
    allContinentSelected,
    paysChecked,
    allContinents,
    addPaysInArray,
    onSubmit,
    countryError,
    errorAnnual,
    errorCompanySize,
  } = UseCompleteCompanyTarget();

  return (
    <div>
      <Header />
      <div className="getstartedContainer pt-0">
        <StepperOem step={3} />
        <div className="contentBorder row py-0">
          <div className="col-12">
            Define the ISV audience you target with your program.
          </div>
          <div className="col-12 row py-4">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="form-group required">
                <label htmlFor="type required">
                  {/* Design & Integration #45105 */}
                  Company size (number of employees)
                  {errorCompanySize && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
              </div>
              <SliderCompanySize
                companySizeSlide={companySizeSlide}
                companySize={companySize}
                setCompanySize={setCompanySize}
                customSetCompanySize={customSetCompanySize}
              />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="form-group required">
                <label htmlFor="type required">
                  Annual revenue
                  {errorAnnual && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
              </div>
              <FundRaisingGoal
                annualTurnover={annualTurnover}
                customSetAnnualSize={customSetAnnualSize}
                setAnnualTurnover={(data) => {
                }}
                unitTurnover={unitTurnover}
                unitTurnoverMax={unitTurnoverMax}
                annualTurnoverSlide={annualTurnoverSlide}
              />
            </div>
          </div>

          <div className="form-group required col-xl-12 col-md-12 col-sm-12 pr-xl-2 pr-md-2 mt-3 mb-0">
            <label htmlFor="type required">Region(s)/Country(ies)</label>
            <div className="d-inline pl-3">Select all that apply.</div>
            {countryError && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            )}
          </div>
          <OEMCountry
            addPaysInArray={addPaysInArray}
            allContinents={allContinents}
            paysChecked={paysChecked}
            allContinentSelected={allContinentSelected}
          />

          <div className="contentBorder border-top row align-items-stretch">
            <button
              type="submit"
              className="btn btn-devinsider large"
              onClick={onSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
