import React from 'react';
import { fileUrl } from '../../../../../../utils';
type IsvMember = {
  profil: string;
  user_name: string;
  job_title: string;
  company_name: string;
  index: number;
};
export default function oemRepresentative(props: IsvMember) {
  return (
    <div className='one-program' key={props.index}>
      <div className='photo'>
        <img src={fileUrl(props.profil)} alt='' />
      </div>
      <div className='program-info'>
        <span className='name'>{props.user_name}</span>
        <span className='isv-title'>{props.job_title}</span>
        <span className='isv-company'>{props.company_name}</span>
      </div>
    </div>
  );
}
