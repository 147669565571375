import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import { ISV } from "../../../../service/constant";
import { FundingStage } from "../../../../interface/compnay-page-investor";
import { checkIsCompletedInvestorCompanyMatching } from "../../../../utils";

export default function AboutSection() {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isConnected = userReducer?.token !== "";
  const isIsvConnected = isConnected && isIsv;
  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const goToFindInvestor = (stage: FundingStage = "all") => {
    if (!isConnected) {
      history.push("/landing-find-inv");
      return;
    }

    if (!checkIsCompletedInvestorCompanyMatching(companyPageInfo)) {
      history.push("/find-investors");
    } else {
      history.push("/investor/find-investor-wrapper", { stage });
    }
  };
  const ishowAboutTxt = isIsvConnected || !isConnected;
  return (
    <div className="aboutSection">
      This section is dedicated to helping software vendors identify financing
      options as an alternative to standard equity investments and loans. This
      is the place to meet partners that offer resources around debt financing,
      hybrid financing, IP financing, crowdfunding, revenue based financing, P2P
      lending, etc...
      <br />
      <br />
      {ishowAboutTxt && (
        <>
          If you are open to funding from VCs, incubators, or other
          institutional investors, check out our{" "}
          <a
            href="javascript:;"
            onClick={(e) => {
              e.preventDefault();
              goToFindInvestor("early");
            }}
          >
            early stage matchmaking section.
          </a>
        </>
      )}
    </div>
  );
}
