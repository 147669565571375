import {
  dataWithToken,
  postPhoto,
  getData,
  postData,
  postWithoutToken,
} from "../api";
import { url } from "../constant";

export const createaAcompanyPage = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.create_company_page,
    data: params,
  };
  let response: any = await postPhoto(tempData, token);
  return response;
};

export const getCompanyPage = async (id: number, token: any): Promise<any> => {
  let response: any = await getData(url.company.get_company_page + id, token);
  return response;
};

// export const getCompanyPageProgress = async (
//   id: number,
//   token: any
// ): Promise<any> => {
//   let response: any = await getData(
//     url.company.get_company_page_progresse + id,
//     token
//   );
//   return response;
// };

export const getAttributeCompanyPage = async (
  attribute: string,
  token: any
): Promise<any> => {
  let response: any = await getData(
    url.company.get_other_company + attribute,
    token
  );
  return response;
};

export const updateCompanyPage = async (
  params: any,
  id: number,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.create_company_page + "/" + id,
    data: params,
  };
  const response: any = await postPhoto(tempData, token);
  return response;
};

//search company page by trade name
export const searchCompanyPage = async (
  company_name: string,
  token: string
): Promise<any> => {
  const response: any = await getData(
    url.acount.companyPage + "/search/" + company_name,
    token
  );
  return response;
};

/*************************************
 * Send invitation affilliated and remove
 */
export const sendInvitation = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.send_invitation,
    data: params,
  };
  let response: any = await postData(tempData.url, tempData?.data, token);
  return response;
};

/*************************************
 * Send invitation affilliated and remove
 */
export const updateRoleAffiliated = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.update_role_affiliated,
    data: params,
  };
  let response: any = await postData(tempData.url, tempData?.data, token);
  return response;
};

/*************************************
 * Send invitation affilliated and remove
 */
export const removeInvitation = async (
  id: string,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.remove_invitation_affiliated + id,
    {},
    token
  );
  return response;
};

/*************************************
 * Remove role user
 */
export const removeUserRole = async (
  id: number,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.remove_user_role + id,
    {},
    token
  );
  return response;
};
/***************************************
 *
 * Add leader ship
 */
export const addLeaderShip = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.addLeaderShip,
    data: params,
  };
  const response: any = await postPhoto(tempData, token);
  return response;
};

/*************************************
 * Remove role user
 */
export const removeLeaderShipById = async (
  id: number,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.removeLeaderShip + id,
    {},
    token
  );
  return response;
};

/*************************************
 * Send invitation claim
 */
export const sendClaimOwnerShip_Service = async (
  params: any,
  token: string
) => {
  let response: any = await postData(
    url?.company?.claim_owner_ship + params,
    {},
    token
  );
  return response;
};

/*************************************
 * Send Request Admin
 */
export const sendRequestAdmin_Service = async (data: any, token: string) => {
  let response: any = await postData(
    url?.company?.send_admin_request,
    data,
    token
  );
  return response;
};

/*************************************
 * Send invitation claim
 */
export const activeRequestClaim_Service = async (params: any) => {
  let response: any = await postWithoutToken(
    url?.company?.validate_request_claim + params,
    {}
  );
  return response;
};

/****************************************
 * Valid request to accept company page
 */
export const validRequestToAffiliated_Service = async (
  data: any,
  token: string
) => {
  let response: any = await postData(
    url?.company?.valid_request_admin,
    data,
    token
  );
  return response;
};

export const checkEmail_Service = async (
  email: string,
  token: any
): Promise<any> => {
  let response: any = await getData(url.company.checkEmail + email, token);
  return response;
};

export const sendValidWorkEmailService = async (
  email: string,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.valid_work_email + email,
    {},
    token
  );
  return response;
};

/***************************************
 * @param data form to send contact
 * @param {token}  to  identifie user id
 * @returns promise response post data contact form
 */
export const contactSupportBillingService = async (
  params: any,
  token: string
): Promise<any> => {
  let tempData: dataWithToken = {
    url: url.company.contact_support_billings,
    data: params,
  };
  const response: any = await postPhoto(tempData, token);
  return response;
};

export const checkReportCompany = async (
  entity_id: any,
  email: any,
  name: any,
  token: string
): Promise<any> => {
  let response: any = await getData(
    url?.company?.check_report_company + entity_id + "/" + email + "/" + name,
    token
  );
  return response;
};

export const reportCompanyPage = async (
  data: any,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url?.company?.report_company_page,
    data,
    token
  );
  return response;
};
