import "./ManageFeed.scss";
import React from "react";
import Header from "../../../widget/index";
import { Tabs, Tab } from "react-bootstrap";
import CategoriesLables from "./categories-and-labels";
import CommunityAuthor from "./community-authors";
import UseManageFeeds from "../../../../controller/community-ctr/bloc-community-ctr/manage-feeds-ctr";
import Footer from "../../../widget/footer/footer";

export default function ManageFeed() {
  const providerManageFeeds = UseManageFeeds();
  return (
    <div className="profilInvestor settings">
      <Header />
      <div className="container mx-auto">
        <div className="contentTabSettings">
          <Tabs className="manageFeedTab" defaultActiveKey="categoriesLabel">
            <Tab
              title={
                <div
                  className="backToCommunity"
                  onClick={() => providerManageFeeds?.goToNotification()}
                >
                  Go to community <br /> Notification settings
                </div>
              }
            />
            <Tab eventKey="categoriesLabel" title="Categories & Labels">
              <CategoriesLables />
            </Tab>
            <Tab eventKey="communityAuthors" title="Community authors">
              <CommunityAuthor />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
