export const timeRange = [
  { id: 1, label: '01:00am', value: '01:00:00' },
  { id: 2, label: '01:30am', value: '01:30:00' },
  { id: 3, label: '02:00am', value: '02:00:00' },
  { id: 4, label: '02:30am', value: '02:30:00' },
  { id: 5, label: '03:00am', value: '03:00:00' },
  { id: 6, label: '03:30am', value: '03:30:00' },
  { id: 7, label: '04:00am', value: '04:00:00' },
  { id: 8, label: '04:30am', value: '04:30:00' },
  { id: 9, label: '05:00am', value: '05:00:00' },
  { id: 10, label: '05:30am', value: '05:30:00' },
  { id: 11, label: '06:00am', value: '06:00:00' },
  { id: 12, label: '06:30am', value: '06:30:00' },
  { id: 13, label: '07:00am', value: '07:00:00' },
  { id: 14, label: '07:30am', value: '07:30:00' },
  { id: 15, label: '08:00am', value: '08:00:00' },
  { id: 16, label: '08:30am', value: '08:30:00' },
  { id: 17, label: '09:00am', value: '09:00:00' },
  { id: 18, label: '09:30am', value: '09:30:00' },
  { id: 19, label: '10:00am', value: '10:00:00' },
  { id: 20, label: '10:30am', value: '10:30:00' },
  { id: 21, label: '11:00am', value: '11:00:00' },
  { id: 22, label: '11:30am', value: '11:30:00' },
  { id: 23, label: '12:00pm', value: '12:00:00' },
  { id: 24, label: '12:30pm', value: '12:30:00' },
  { id: 25, label: '01:00pm', value: '13:00:00' },
  { id: 26, label: '01:30pm', value: '13:30:00' },
  { id: 27, label: '02:00pm', value: '14:00:00' },
  { id: 28, label: '02:30pm', value: '14:30:00' },
  { id: 29, label: '03:00pm', value: '15:00:00' },
  { id: 30, label: '03:30pm', value: '15:30:00' },
  { id: 31, label: '04:00pm', value: '16:00:00' },
  { id: 32, label: '04:30pm', value: '16:30:00' },
  { id: 33, label: '05:00pm', value: '17:00:00' },
  { id: 34, label: '05:30pm', value: '17:30:00' },
  { id: 35, label: '06:00pm', value: '18:00:00' },
  { id: 36, label: '06:30pm', value: '18:30:00' },
  { id: 37, label: '07:00pm', value: '19:00:00' },
  { id: 38, label: '07:30pm', value: '19:30:00' },
  { id: 39, label: '08:00pm', value: '20:00:00' },
  { id: 40, label: '08:30pm', value: '20:30:00' },
  { id: 41, label: '09:00pm', value: '21:00:00' },
  { id: 42, label: '09:30pm', value: '21:30:00' },
  { id: 43, label: '10:00pm', value: '22:00:00' },
  { id: 44, label: '10:30pm', value: '22:30:00' },
  { id: 45, label: '11:00pm', value: '23:00:00' },
  { id: 46, label: '11:30pm', value: '23:30:00' },
  { id: 47, label: '12:00am', value: '24:00:00' },
];
