import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import store from '../redux/store';
import { isEmpty } from 'lodash';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  key: string;
  path: string;
  exact: boolean;
  allowedLoggedUser: Array<string>;
}

export default function NotAllowedConnectedPage(props: PrivateRouteProps) {
  const { component: Component, isSignedIn, ...rest } = props;
  const isConnected = !isEmpty(store.getState().userReducer.token);

  const roleConnected =
    store.getState().userReducer && store.getState().userReducer.roles?.[0];

  let isAcceptedConnected = false;

  if (props.allowedLoggedUser?.length > 0 && isConnected) {
    isAcceptedConnected = props.allowedLoggedUser?.find(
      (el) => el === roleConnected
    )
      ? true
      : false;
  } else {
    isAcceptedConnected = !isConnected;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAcceptedConnected ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/profil-not-found',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}
