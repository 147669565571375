/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteCountryExpert from "../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-country-expert";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../../utils/country_phone";
import useGoBack from "../../../../../hooks/use-navigate";

export default function CompleteCountryExpert() {
  const {
    dataCompleted,
    selected,
    setSelected,
    phoneNumberExpert,
    setPhoneNumberExpert,
    countryRegionExpert,
    setCountryRegionExpert,
    navigateCompleteInterested,
    errCountryRegionExpert,
    errPhoneNumberExpert,
    dialCodeSelected,
    setDialCodeSelected,
  } = UseCompleteCountryExpert();
  const { goBack } = useGoBack();

  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : "");
  };

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <div className="stepInscription">
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem done"></div>
            <div className="stepItem"></div>
            <div className="stepItem"></div>
          </div>
          <a
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
            className="link-back"
          >
            <span className="icon-back"></span>
          </a>
          <h1>Create your account.</h1>
        </div>
        <div className="contentBorder border-top row py-3">
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="organisation required">
              Country/Region
              <div
                className={
                  errCountryRegionExpert
                    ? "p-2 d-inline"
                    : "container-error pl-2 d-none"
                }
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <select
              name="selectcountry"
              id=""
              className="custom-select"
              onChange={(evt: ChangeEvent<HTMLSelectElement>) =>
                setCountryRegionExpert(evt.target.value)
              }
              value={countryRegionExpert}
            >
              <option value="">Country</option>
              {dataCompleted?.country?.map((el: any) => (
                <option value={el.id}>{el.country_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2 ">
            <label htmlFor="organisation required">
              Phone number
              <div
                className={
                  errPhoneNumberExpert
                    ? "p-2 d-inline"
                    : "container-error pl-2 d-none"
                }
              >
                <span className="error-red"> error </span>
                <span className="txt-error">
                  Phone number is not a valid format
                </span>
              </div>
            </label>
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                style={{
                  textIndent:
                    dialCodeSelected?.length == 2
                      ? 35
                      : dialCodeSelected?.length == 3
                      ? 40
                      : dialCodeSelected?.length == 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                value={phoneNumberExpert}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setPhoneNumberExpert(evt.target.value)
                }
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                ({dialCodeSelected})
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={selected}
                onSelect={handleSelect}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <div className="col-12 d-flex align-items-center pr-0 flex-column">
            <button
              className="btn btn-devinsider large containerBtnBtn"
              onClick={() => navigateCompleteInterested()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
