import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { sendRequestAdmin_Service } from '../../../../service/applicatif/company-page-isv';
import * as T from '../../types/types-company-page';

import { rootState } from '../../../../redux/reducers';
import { getDomainWebSite } from '../../../../utils';
export default function UseRequestAdminCtr() {
  const userObject = useSelector((state: rootState) => state.userReducer);

  const [showRequestAdmin, setShowRequestAdmin] = useState(false);
  const [isShowSuccessRequestAdmin, setIsShowSuccessRequestAdmin] =
    useState(false);
  const [errorDiffWorkEmail, setErrorDiffWorkEmail] = useState(false);
  const [emailRequest, setEmailRequest] = useState<string>(
    userObject?.primary_email?.split('@')?.[0]
  );
  const [isShowBecomeVerified, setIsBecomeVerified] = useState<boolean>(false);
  const [isShowLoaderSendRequest, sendIsShowLoaderSendRequest] =
    useState<boolean>(false);
  const [companyTosendClaim, setCompanyTosendClaim] =
    useState<T.TFormAddEmailClaimOwnerShip>({
      companyName: '',
      pays: '',
      webSite: '',
      acceptTerm: false,
      workEmail: '',
      logoCompany: '',
      id: '',
    });

  const showFormRequestAdmin = (el: T.TCompanyShearch) => {
    setShowRequestAdmin(true);
    let workEmail = userObject?.primary_email;
    let domainMail = workEmail?.split('@')?.[1];

    if (domainMail != getDomainWebSite(el?.website)) {
      setErrorDiffWorkEmail(true);
    } else {
      setErrorDiffWorkEmail(false);
    }
    let tempToValueForm = {
      companyName: el?.company_name,
      pays: el?.country_name,
      webSite: el?.website,
      acceptTerm: false,
      workEmail: '',
      logoCompany: el?.photo_profil,
      id: el?.id,
    };
    setCompanyTosendClaim(tempToValueForm);
  };

  const toogleAcceptTerm = (toogle: boolean) => {
    let tempState: T.TFormAddEmailClaimOwnerShip = {
      ...companyTosendClaim,
      acceptTerm: toogle,
    };
    setCompanyTosendClaim(tempState);
  };

  const sendReQuestAdmin = async () => {
    let data = {
      entityId: companyTosendClaim?.id,
    };
    sendIsShowLoaderSendRequest(true);
    let response = await sendRequestAdmin_Service(data, userObject?.token);
    if (response?.status === 200) {
      sendIsShowLoaderSendRequest(false);
      setShowRequestAdmin(false);
      setIsShowSuccessRequestAdmin(true);
    }
  };

  const cancelRequestAdmin = () => {
    setShowRequestAdmin(false);
  };

  return {
    showRequestAdmin,
    setShowRequestAdmin,
    isShowSuccessRequestAdmin,
    setIsShowSuccessRequestAdmin,
    showFormRequestAdmin,
    companyTosendClaim,
    toogleAcceptTerm,
    errorDiffWorkEmail,
    emailRequest,
    sendReQuestAdmin,
    isShowLoaderSendRequest,
    cancelRequestAdmin,
    isShowBecomeVerified,
    setIsBecomeVerified,
  };
}
