import React, { useEffect, useState } from "react";
import {
  checkIfExist,
  concatString,
  refaCtoNullKey,
  setInternationalPhoneNumber,
} from "../../../../../../utils";
import {contact} from "../../../../../../utils/country_phone";

const ProfilBodyExpertLeft = (props: any) => {
  const [dialCode,setDialCode] = useState<string|null>("");
  useEffect(() => {
    if (props?.userState?.phone_number_code !== "" && props?.userState?.phone_number_code !== null){
      setDialCode(contact.filter(element => element.code === props?.userState?.phone_number_code)[0].dial_code);
    }
  }, []);
  return (
    <>
      {/* TEXT DESCRIPTION */}
      {/* <div className="col-12 infoInvestoronly">
        <span className="icon-locked"></span>
        Only contacted members can view this information. Your personal details
        will never be published publicly.
      </div> */}

      {/* BODY RIGHT SIDE TODO */}
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">Contact information</h2>
          <ul className="ulListInfo">
            <li>
              <div className="label">Primary email</div>
              <div className="infoLabel">{props?.userState?.primary_email}</div>
            </li>
            <li>
              <div className="label">Work email</div>
              <div className="infoLabel">{props?.userState?.email}</div>
            </li>
            <li>
              <div className="label">Phone number</div>
              <div className="infoLabel">
                {/* {dialCode === "" ? <></> : <>{"(" + dialCode + ") "}</>}
                {refaCtoNullKey(props?.userState?.phone, "-")} */}
                {setInternationalPhoneNumber(props?.userState?.phone, dialCode)}
              </div>
            </li>
            <li>
              <div className="label">Linkedin</div>
              <div className="infoLabel">
                <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/in/" +
                    refaCtoNullKey(props?.userState?.linkedin, "-")
                  }
                >
                  {refaCtoNullKey(props?.userState?.linkedin, "-").length > 36
                    ? refaCtoNullKey(props?.userState?.linkedin, "-").substring(
                        0,
                        33
                      ) + "..."
                    : refaCtoNullKey(props?.userState?.linkedin, "-")}
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          <h4 className="subtitle">Expertise</h4>
          {checkIfExist(props?.userState?.plain_text) ? (
            props?.userState?.plain_text
          ) : (
            <div className="fauxText">
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l50"></div>
            </div>
          )}

          <h4 className="subtitle">Company overview</h4>
          {checkIfExist(props?.userState?.expertCompany?.company_overview) ? (
            props?.userState?.expertCompany?.company_overview
          ) : (
            <div className="fauxText">
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l50"></div>
            </div>
          )}
        </div>
      </div>
      {/* LEFT SIDE */}
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <h2 className="title">Company details</h2>
        <ul className="ulListInfo">
          <li>
            <div className="label">Trade name</div>
            <div className="infoLabel">
              {refaCtoNullKey(
                props?.userState?.expertCompany?.company_trade_name
              )}
              {/* <span className="icon-locked"></span> */}
            </div>
          </li>
          <li>
            <div className="label">Legal name</div>
            <div className="infoLabel">
              {refaCtoNullKey(
                props?.userState?.expertCompany?.company_legal_name
              )}
              {/* <span className="icon-locked"></span> */}
            </div>
          </li>

          <li>
            <div className="label"> Website</div>
            <div className="infoLabel">
              {props?.userState?.expertCompany?.website}
              {/* {refaCtoNullKey(props?.userState?.website, "-")} */}
              {/* <span className="icon-locked"></span> */}
            </div>
          </li>
          <li>
            <div className="label">Location</div>
            <div className="infoLabel">
              {refaCtoNullKey(
                props?.userState?.expertCompany?.country?.country_name
              )}{" "}
              {props?.userState?.expertCompany?.city !== "" &&
              props?.userState?.expertCompany?.city !== null
                ? ", " + props?.userState?.expertCompany?.city
                : ""}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfilBodyExpertLeft;
