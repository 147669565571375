import React from "react";
import { Modal } from "react-bootstrap";
import useOemBackground from "../../../../../controller/oem-ctr/block-component/use-oem-background";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { fileUrl } from "../../../../../utils";

export default function OemBackground(props: any) {
  const {
    backgroundInput,
    profileInput,
    handleBackgroundInput,
    handleProfileInput,
    defaultValue,
    backgroundLoading,
    profilLoading,
    showPopupRemoveCouverture,
    showPopupRemoveLogo,
    setShowPopupRemoveLogo,
    setShowPopupRemoveCouverture,
    handleRemoveLogoOemProgram,
    handleRemoveCouvertureOemProgram,
    processingRemovePicture,
  } = useOemBackground();

  return (
    <div className="mx-auto container cont1">
      <div className="photoMur">
        <div className="editShow">
          <a
            className="editBackground"
            onClick={(e) => {
              e.preventDefault();
              backgroundInput.current?.click();
            }}
          >
            {backgroundLoading ? (
              <Loader />
            ) : (
              <>
                <span className="icon-edit-full" />
                Edit background picture (Ideal image size: 1100x340)
              </>
            )}
          </a>
          {defaultValue?.background !==
            "assets/images/oemProgram/photos/couverture.png" && (
            <>
              <div className="removePhoto bg">
                <a href="javascript:;">
                  <span
                    className="icon-bin"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPopupRemoveCouverture(true);
                    }}
                  >
                    Remove background
                  </span>
                </a>
              </div>
            </>
          )}
        </div>
        <input
          type="file"
          className="d-none"
          ref={backgroundInput}
          onChange={handleBackgroundInput}
        />
        {defaultValue?.background && (
          <img src={fileUrl(defaultValue?.background)} className="Image" />
        )}

        <div className="profilInvestorImg">
          <div className="dvRelative">
            <div className="editShow">
              <a
                className="editPictureProfil"
                onClick={(e) => {
                  e.preventDefault();
                  profileInput.current?.click();
                }}
              >
                {profilLoading ? (
                  <Loader />
                ) : (
                  <>
                    <span className="icon-edit-full" />
                    Edit image
                  </>
                )}
              </a>
              {defaultValue?.profil !==
                "assets/images/oemProgram/photos/profil.png" && (
                <>
                  <div className="removePhoto">
                    <a href="javascript:;">
                      <span
                        className="icon-bin"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPopupRemoveLogo(true);
                        }}
                      >
                        Remove logo
                      </span>
                    </a>
                  </div>
                </>
              )}
            </div>
            {defaultValue?.profil && (
              <img src={fileUrl(defaultValue?.profil)} className="Image" />
            )}

            <input
              type="file"
              className="d-none"
              ref={profileInput}
              onChange={handleProfileInput}
            />
          </div>
        </div>
      </div>

      {/* MODAL CONFIRMATION REMOVE LOGO */}
      <Modal
        show={showPopupRemoveLogo || showPopupRemoveCouverture}
        className="small forDesactivate"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Remove logo</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setShowPopupRemoveCouverture(false);
                setShowPopupRemoveLogo(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            {showPopupRemoveLogo ? (
              <p>Are you sure you want to remove the current logo?</p>
            ) : (
              <p>
                Are you sure you want to remove the current background image?
              </p>
            )}
          </div>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => {
                setShowPopupRemoveCouverture(false);
                setShowPopupRemoveLogo(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => {
                if (showPopupRemoveLogo) {
                  handleRemoveLogoOemProgram();
                } else {
                  handleRemoveCouvertureOemProgram();
                }
              }}
            >
              {processingRemovePicture ? <Loader /> : <>Remove</>}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
