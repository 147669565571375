import React, {ChangeEvent, useEffect, useState} from "react";
import {BillingAddress, PaymentInputErrors} from "../payment-info/payment-info";
import {getCompletedAcccount, getCountries, ICountry} from "../../../../service/applicatif/acount";

type Props = {
    inputErrors: PaymentInputErrors
    handleCountryChange: (value: string) => void
    billingAddress: BillingAddress,
    setBillingAddress: (value: BillingAddress) => void
}

export const PaymentBillingAddress: React.FC<Props> = ({
    inputErrors,
    handleCountryChange,
    billingAddress,
    setBillingAddress
}) => {

    const [countries, setCountries] = useState<ICountry[]>([]);

    const fetchCountries = async () => {
        return await getCountries(true);
    };

    useEffect(() => {
        fetchCountries()
            .then((response) => {
                if (response.data.data) {
                    setCountries(response.data.data);
                }
            })
            .catch((error) => { });
        return () => {};
    }, []);

    return (
        <div className='billingAddress row m-0'>
            <div className='form-group required col-6'>
                <label htmlFor=''>
                    Billing address
                    <div
                        className={`container-error-address pl-2 ${
                            inputErrors.billingAddress ? 'd-inline' : 'd-none'
                        }`}
                    >
                        <span className='error-red'> error </span>
                        <span className='txt-error'>All billing address fields are required</span>
                    </div>
                </label>
                <select
                    id='input-country'
                    name='pets'
                    className='custom-select'
                    onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                        handleCountryChange(event.target.value);
                    }}
                >
                    <option value=''>Country</option>
                    {countries && countries.map((element: ICountry, index: number) => (
                        <option
                            value={element.attributes.ISO2}
                            selected={element.attributes.ISO2 === billingAddress.country}
                            key={index}
                        >
                            {`${element.attributes.Name}`}
                        </option>
                    ))}
                </select>
            </div>
            <div className='name col-6'>
                <label className='visibility-hidden'>A</label>
                <input
                    id='input-city'
                    type='text'
                    className='form-control'
                    name='CityName'
                    placeholder='City'
                    value={billingAddress.city}
                    onChange={(e) => {
                        setBillingAddress({
                            ...billingAddress,
                            city: e.target.value,
                        });
                    }}
                />
            </div>
            <div className='col-12 mt-2'>
                <input
                    id='input-line1'
                    type='text'
                    className='form-control'
                    name='streetName'
                    placeholder='Street'
                    value={billingAddress.line1}
                    onChange={(e) => {
                        setBillingAddress({
                            ...billingAddress,
                            line1: e.target.value,
                        });
                    }}
                />
            </div>
            <div className='name col-6 mt-4'>
                <input
                    id='input-state'
                    type='text'
                    className='form-control'
                    name='state'
                    placeholder='State/region'
                    value={billingAddress.state}
                    onChange={(e) => {
                        setBillingAddress({
                            ...billingAddress,
                            state: e.target.value,
                        });
                    }}
                />
            </div>
            <div className='name col-6 mt-4'>
                <input
                    id='input-postal_code'
                    type='text'
                    className='form-control'
                    name='zip'
                    placeholder='ZIP code'
                    value={billingAddress.postal_code}
                    onChange={(e) => {
                        setBillingAddress({
                            ...billingAddress,
                            postal_code: e.target.value,
                        });
                    }}
                />
            </div>
        </div>
    )
}