import React from "react";
import Modal from "react-bootstrap/Modal";
import ModelLicensing from "../../../company/profil/strategy/modal/modal-edit-licensing-and-distribution";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export type ILicensingModel = {
  isShowLicencingModel: boolean;
  cancelUpdateCategorie: () => void;
  licencingModelSelected: Array<number>;
  licencingModelData: Array<{ id: number; name: string }>;
  distributionModelData: Array<{ id: number; name: string }>;
  distributionModelSelected: Array<number>;
  isHaveError: boolean;
  addDistributionChannel: (id: number) => void;
  addLicencingChecked: (id: number) => void;
  updateLicencingModel: () => void;
  loaderlicencingModel: boolean;
};

export default function ModalLicensingModel(props: ILicensingModel) {
  return (
    <Modal
      show={props.isShowLicencingModel}
      className="createCompanypopup medium forStep5custom"
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Licensing model & Distribution channel</h2>
          <a className="close" onClick={() => props.cancelUpdateCategorie()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <ModelLicensing
          licengingModelData={props.licencingModelData}
          distributionModelData={props.distributionModelData}
          licenCingModelSelected={props.licencingModelSelected}
          distributionModelSelected={props.distributionModelSelected}
          isHaveError={props.isHaveError}
          addDistributionChannel={(id: number) => {
            props.addDistributionChannel(id);
          }}
          addLicencing={(id: number) => {
            props.addLicencingChecked(id);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.cancelUpdateCategorie()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5"
            onClick={() => props.updateLicencingModel()}
          >
            {props.loaderlicencingModel ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
