import { IProgramDetailValue, OemProgram, OemProgramDraft } from "../type";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { getSStorage } from "../../../utils";
import { updateOemProgramDraft } from "../../../redux/actions/oem-program-action";

export const PUPBLISH_OR_UPDATE = "PUPBLISH_OR_UPDATE";

export default function useCompanyCtr() {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const oemProgramDraft: OemProgramDraft = useSelector(
    (state: rootState) => state.oemProgramReducer.draft
  );

  const [defaultValue, setDefaultValue] = useState<IProgramDetailValue>();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const country: any = oemProgramDraft?.country
      ? oemProgramDraft?.country
      : oemProgram?.country?.id?.toString();
    setDefaultValue({
      companyName:
        "companyName" in (oemProgramDraft || {})
          ? oemProgramDraft?.companyName || ""
          : oemProgram?.company_name,
      city:
        "city" in (oemProgramDraft || {})
          ? oemProgramDraft?.city || ""
          : oemProgram?.city,
      country: parseInt(country),
      website:
        "website" in (oemProgramDraft || {})
          ? oemProgramDraft?.website || ""
          : oemProgram?.website,
    });
  }, [oemProgramDraft, oemProgram]);

  const onSubmit = (data: IProgramDetailValue) => {
    setLoading(true);
    dispatch(
      updateOemProgramDraft(data, oemProgram.id, () => {
        setLoading(false);
        setModal(false);
      })
    );
    dispatch({
      type: PUPBLISH_OR_UPDATE,
      payload: {
        isupdated: true,
      },
    });
  };

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    reset();
    setModal(true);
  };

  return {
    oemProgram,
    register,
    handleSubmit,
    errors,
    onSubmit,
    dataCompleted,
    closeModal,
    modal,
    loading,
    openModal,
    defaultValue,
  };
}
