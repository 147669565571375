import React from "react";
import UseFindInvestorFilter, {
  filterType,
} from "../../../../../controller/investor-ctr/find-investor/bloc-filter-find-investor/use-filter-find-investor";
import Slider from "rc-slider";
import { addToArray, paginate } from "../../../../../utils";
import {
  IHQLocation,
  IIndustry,
  IInvestorType,
  ISolution,
} from "../../../../../redux/types/find-investor-interface";

type IPropsFilter = {
  resetFilter: () => void;
  filter: () => void;

  match: Array<number>;
  selectedHqLocation: Array<number>;
  selectedIndustry: Array<number>;
  selectedSolution: Array<number>;
  selectedType: Array<number>;
  size: Array<number>;
  setMatch: (e: Array<number>) => void;
  setSelectedHqLocation: (e: Array<number>) => void;
  setSelectedIndustry: (e: Array<number>) => void;
  setSelectedSolution: (e: Array<number>) => void;
  setSelectedType: (e: Array<number>) => void;
  stage?: string;
};

export default function FindInvestorBlocFilter(props: IPropsFilter) {
  const createSliderWithTooltip = Slider.Range;
  const Range = createSliderWithTooltip;

  const handleClickIndex = (index: number, currentLength: number) =>
    index > 6 ? 6 : currentLength;

  const generatedText = (currentIndex: number, allLength: number) =>
    currentIndex < 7 ? `See more(${allLength - currentIndex})` : "See less";

  const {
    sizeSlide,
    customSetSize,
    findInvestorReducer,
    handleBlock,
    isShowBloc,
    indexType,
    indexIndustry,
    indexLocation,
    indexSolution,
    setIndexType,
    setIndexIndustry,
    setIndexLocation,
    setIndexSolution,
    setSizeSlide,
  } = UseFindInvestorFilter();

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="containerBtn block no-border">
          <button className="btn btn-devinsider cancel" onClick={props?.filter}>
            Filter
          </button>
          <a
            href="javascript:;"
            className="resetFilter"
            onClick={() => {
              setSizeSlide([70, 100]);
              props?.resetFilter();
            }}
          >
            <span className="icon-refresh"></span>
            Reset filter
          </a>
        </div>
        <div className="block">
          <h3
            className={`title withChild ${
              isShowBloc === filterType.investor_type ? "open" : ""
            }`}
            onClick={() => handleBlock(filterType.investor_type)}
          >
            Investor type
          </h3>
          <div className="listCheck">
            {paginate(findInvestorReducer?.investor_type, indexType)?.map(
              (el: IInvestorType, key: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={key}
                  onChange={() =>
                    props?.setSelectedType(
                      addToArray(props?.selectedType, el?.id)
                    )
                  }
                >
                  <input
                    type="checkbox"
                    id={`type${key}`}
                    className="custom-control-input"
                    checked={
                      props?.selectedType?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => {}}
                  />
                  <label
                    htmlFor={`type${key}`}
                    className="custom-control-label"
                  >
                    {el?.name}
                  </label>
                </div>
              )
            )}
            {findInvestorReducer?.investor_type?.length > 6 && (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => {
                  setIndexType(
                    handleClickIndex(
                      indexType,
                      findInvestorReducer?.investor_type?.length
                    )
                  );
                }}
              >
                {generatedText(
                  indexType,
                  findInvestorReducer?.investor_type?.length
                )}
              </a>
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title">Match</h3>
          <Range
            className="targetInvestorrange"
            min={0}
            max={3}
            value={props?.size}
            step={1}
            marks={{
              0: sizeSlide[0] + "%",
              1: "",
              2: "",
              3: sizeSlide[1] + "%",
            }}
            onChange={(event: Array<number>) => {
              customSetSize(event[0], event[1]);
              props?.setMatch(event);
            }}
          />
        </div>
        <div className="block">
          <h3
            className={`title withChild ${
              isShowBloc === filterType.location ? "open" : ""
            }`}
            onClick={() => handleBlock(filterType.location)}
          >
            Headquarters location
          </h3>
          <div className="listCheck">
            {paginate(findInvestorReducer?.hq_location, indexLocation)?.map(
              (el: IHQLocation, key: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  onChange={() =>
                    props?.setSelectedHqLocation(
                      addToArray(props?.selectedHqLocation, el?.id)
                    )
                  }
                  key={key}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`location${key}`}
                    checked={
                      props?.selectedHqLocation?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => {}}
                  />
                  <label
                    htmlFor={`location${key}`}
                    className="custom-control-label"
                  >
                    {el?.country_name}
                  </label>
                </div>
              )
            )}
            {findInvestorReducer?.hq_location?.length > 6 && (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => {
                  setIndexLocation(
                    handleClickIndex(
                      indexLocation,
                      findInvestorReducer?.hq_location?.length
                    )
                  );
                }}
              >
                {generatedText(
                  indexLocation,
                  findInvestorReducer?.hq_location?.length
                )}
              </a>
            )}
          </div>
        </div>
        <div className="block">
          <h3
            className={`title withChild 
                            ${isShowBloc === filterType.solution ? "open" : ""}
                        `}
            onClick={() => handleBlock(filterType.solution)}
          >
            Solution specialization
          </h3>
          <div className="listCheck">
            {paginate(
              findInvestorReducer?.solution_specialization,
              indexSolution
            )?.map((el: ISolution, key: number) => (
              <div
                className="custom-control custom-checkbox orange"
                onChange={() =>
                  props?.setSelectedSolution(
                    addToArray(props?.selectedSolution, el?.id)
                  )
                }
                key={key}
              >
                <input
                  type="checkbox"
                  id={`solution${key}`}
                  className="custom-control-input"
                  checked={
                    props?.selectedSolution?.find((e) => e === el?.id)
                      ? true
                      : false
                  }
                  onChange={() => {}}
                />
                <label
                  htmlFor={`solution${key}`}
                  className="custom-control-label"
                >
                  {el?.name}
                </label>
              </div>
            ))}
            {findInvestorReducer?.solution_specialization?.length > 6 && (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => {
                  setIndexSolution(
                    handleClickIndex(
                      indexSolution,
                      findInvestorReducer?.solution_specialization?.length
                    )
                  );
                }}
              >
                {generatedText(
                  indexSolution,
                  findInvestorReducer?.solution_specialization?.length
                )}
              </a>
            )}
          </div>
        </div>
        <div className="block">
          <h3
            className={`title withChild 
                            ${isShowBloc === filterType.industry ? "open" : ""}
                        `}
            onClick={() => handleBlock(filterType.industry)}
          >
            Industry specialization
          </h3>
          <div className="listCheck">
            {paginate(
              findInvestorReducer?.industry_specialization,
              indexIndustry
            )?.map((el: IIndustry, key: number) => (
              <div
                className="custom-control custom-checkbox orange"
                onChange={() =>
                  props?.setSelectedIndustry(
                    addToArray(props?.selectedIndustry, el?.id)
                  )
                }
                key={key}
              >
                <input
                  type="checkbox"
                  id={`industry${key}`}
                  className="custom-control-input"
                  checked={
                    props?.selectedIndustry?.find((e) => e === el?.id)
                      ? true
                      : false
                  }
                  onChange={() => {}}
                />
                <label
                  htmlFor={`industry${key}`}
                  className="custom-control-label"
                >
                  {el?.name}
                </label>
              </div>
            ))}
            {findInvestorReducer?.industry_specialization?.length > 6 && (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => {
                  setIndexIndustry(
                    handleClickIndex(
                      indexIndustry,
                      findInvestorReducer?.industry_specialization?.length
                    )
                  );
                }}
              >
                {generatedText(
                  indexIndustry,
                  findInvestorReducer?.industry_specialization?.length
                )}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
