import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ItemDiscussion,
  RelatedAdds,
} from "../../../../../redux/types/community-interface";
import { LoadingArticle } from "../../../../widget/loader";
import { url } from "../../../../../service/constant";

export type IProps = {
  loaderRelatedContent: boolean;
  //Bug #48623, related content
  relatedContents: Array<any>;
  loaderMorePost: boolean;
  morePostsUser: Array<ItemDiscussion>;
  showAllCategorie: () => void;
  showActivityUser: () => void;
  userName: string;
  showClassicAdds: () => void;
  goToLabelCategory: (categoryId: string, p: string, post_type: string) => void;
  showAllAds: () => void;
  loaderRelatedAds: boolean;
  moreRelatedAds: Array<RelatedAdds>;
};
export default function BottomRelatedMoreFeatured(props: IProps) {
  const history = useHistory();
  const style = { cursor: "pointer" };

  return (
    <div className="leftCommunityForum allWidth">
      <div className="block">
        <h2 className="title">Related content</h2>

        <ul className="separateElement">
          {props?.loaderRelatedContent ? (
            <LoadingArticle />
          ) : (
            // Bug #48623, related content
            props?.relatedContents?.map((el: any, index: number) => (
              <li key={index}>
                <div className="leftItem txtLabel relatedAndMore">
                  <h3
                    className="title"
                    onClick={() =>
                      history.push(
                        url.community.activity.front_post_details +
                          el?.category.id +
                          "/" +
                          el?.id
                      )
                    }
                    style={style}
                  >
                    {el?.title}
                  </h3>
                  {el?.labels?.map((label: any, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      onClick={() => {
                        props.goToLabelCategory(
                          label?.category_id,
                          label.id,
                          el?.post_type.id?.toString()
                        );
                      }}
                    >
                      {label?.title}
                    </span>
                  ))}

                  {el?.tags?.map((t: any, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      onClick={() => {
                        props.goToLabelCategory(
                          "0",
                          t.id,
                          el?.post_type.id?.toString()
                        );
                      }}
                    >
                      {t?.name}
                    </span>
                  ))}
                </div>
              </li>
            ))
          )}

          {props?.relatedContents?.length > 0 && (
            <li className="linkForLi" onClick={() => props?.showAllCategorie()}>
              <Link
                onClick={(e) => e?.preventDefault()}
                className="viewAll"
                to="#"
              >
                View all
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className="block">
        <h2 className="title">More from {props?.userName}</h2>
        <ul className="separateElement">
          {props?.loaderMorePost ? (
            <LoadingArticle />
          ) : (
            props?.morePostsUser?.map((el: ItemDiscussion, index: number) => (
              <li key={index}>
                <div className="leftItem txtLabel relatedAndMore">
                  <h3
                    className="title"
                    onClick={() =>
                      history.push(
                        url.community.activity.front_post_details +
                          el?.category?.id +
                          "/" +
                          el?.id
                      )
                    }
                    style={style}
                  >
                    {el?.title}
                  </h3>
                  {el?.labels?.map((label: any, index2: number) => (
                    <span
                      className="topic"
                      key={index2}
                      onClick={() => {
                        props.goToLabelCategory(
                          label?.category?.id,
                          label.id,
                          el.post_type.id?.toString()
                        );
                      }}
                    >
                      {label?.title}
                    </span>
                  ))}

                  {el?.tags?.map((t: any, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      onClick={() => {
                        props.goToLabelCategory(
                          "0",
                          t.id,
                          el?.post_type.id?.toString()
                        );
                      }}
                    >
                      {t?.name}
                    </span>
                  ))}
                </div>
              </li>
            ))
          )}

          {props?.morePostsUser?.length > 0 && (
            <li className="linkForLi" onClick={() => props?.showActivityUser()}>
              <Link
                onClick={(e) => e?.preventDefault()}
                className="viewAll"
                to="#"
              >
                View all
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
