import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { Iparams, ResponseFilter } from "../../../../types/Ma";
import { addToArray, isExist } from "../../../../utils";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import store from "../../../../redux/store";

export default function UseHomePage() {
  const [searchText, setSearchText] = useState("");
  const [limitSolution, setLimitSolution] = useState(6);
  const [limitIndustry, setLimitIndustry] = useState(6);
  const [limitCountry, setLimitCountry] = useState(6);

  const [selectedSolution, setSelectedSolution] = useState<Array<number>>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<Array<number>>([]);
  const [selectedCountry, setSelectedCountry] = useState<Array<number>>([]);
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [paramsAnnounce, setParamsAnnounce] = useState<Iparams>({
    softwareSolution: [],
    featuredSolution: [],
    targetedIndustry: [],
    country: [],
    itemsPerPage: 10,
    page: 1,
    search: undefined,
  });

  const [filteredData, setFilteredData] = useState<ResponseFilter>({
    country: [],
    softwareSolution: [],
    featuredSolution: [],
    targetedIndustry: [],
  });

  const [announcing, setAnnouncing] = useState({
    data: [],
    pagination: {
      current: 0,
      first: 1,
      last: 0,
      next: 0,
      parPage: 10,
      previous: 0,
      totalItems: 0,
    },
  });

  const getMaFilterHomePage = async () => {
    setLoadingFilter(true);
    try {
      const response = await getDataService(
        url.Ma.filterHomePage,
        {},
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        setFilteredData(response?.data);
      }
      setLoadingFilter(false);
    } catch (error) {}
    setLoadingFilter(false);
  };

  const getMaListeHomePage = async (params: any) => {
    setLoadingFetch(true);
    try {
      const response = await getDataService(
        url.Ma.getAnnounce,
        params,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        setAnnouncing({
          data: response?.data?.data,
          pagination: response?.data?.pagination,
        });
      }
      setLoadingFetch(false);
    } catch (error) {}
    setLoadingFetch(false);
  };

  useEffect(() => {
    getMaFilterHomePage();
  }, []);

  useEffect(() => {
    getMaListeHomePage(paramsAnnounce);
  }, []);

  const dataSolution = [
    ...(filteredData?.softwareSolution || []),
    ...(filteredData?.featuredSolution || []),
  ];

  const handleSelect = (element: number) => {
    setSelectedSolution(addToArray(selectedSolution, element));
  };

  const handleSelectedIndustry = (element: number) => {
    setSelectedIndustry(addToArray(selectedIndustry, element));
  };

  const handleSelectedCountry = (element: number) => {
    setSelectedCountry(addToArray(selectedCountry, element));
  };

  const handleSeeSolution = (action: string) => {
    setLimitSolution(action === "0" ? dataSolution.length : 6);
  };

  const handleSeeIndustry = (action: string) => {
    setLimitIndustry(
      action === "0" ? filteredData?.targetedIndustry?.length || 0 : 6
    );
  };

  const handleSeeCountry = (action: string) => {
    setLimitCountry(action === "0" ? filteredData?.country?.length || 0 : 6);
  };

  const resetFilter = async () => {
    setSelectedSolution([]);
    setSelectedIndustry([]);
    setSelectedCountry([]);
    const params = {
      featuredSolution: [],
      softwareSolution: [],
      targetedIndustry: [],
      country: [],
      itemsPerPage: 10,
      page: 1,
      search: undefined,
    };
    setParamsAnnounce(params);
    getMaListeHomePage(params);
  };

  const lunchFilter = () => {
    const getIdFeatured =
      filteredData?.featuredSolution.map((el) => el.id) || [];
    const getIdSolution =
      filteredData?.softwareSolution.map((el) => el.id) || [];

    const makeFeatured = selectedSolution.filter((el) => {
      if (getIdFeatured.includes(el)) {
        return el;
      }
    });
    const makeSolution = selectedSolution.filter((el) => {
      if (getIdSolution.includes(el)) {
        return el;
      }
    });

    const params = {
      featuredSolution: makeFeatured,
      softwareSolution: makeSolution,
      targetedIndustry: selectedIndustry,
      country: selectedCountry,
      itemsPerPage: 10,
      page: 1,
    };

    setParamsAnnounce(params);
    getMaListeHomePage({
      ...params,
      featuredSolution: JSON.stringify(makeFeatured),
      softwareSolution: JSON.stringify(makeSolution),
      targetedIndustry: JSON.stringify(selectedIndustry),
      country: JSON.stringify(selectedCountry),
    });
  };

  const isConnected = () => isExist(userReducer.token);

  const handlePageClick = (data: any) => {
    const params = {
      ...paramsAnnounce,
      page: data,
    };
    setParamsAnnounce(params);
    getMaListeHomePage({
      ...params,
      featuredSolution: JSON.stringify(paramsAnnounce.featuredSolution),
      softwareSolution: JSON.stringify(paramsAnnounce.softwareSolution),
      targetedIndustry: JSON.stringify(paramsAnnounce.targetedIndustry),
      country: JSON.stringify(paramsAnnounce.country),
    });
  };

  const search = () => {
    const params = {
      ...paramsAnnounce,
      search: searchText,
    };
    setParamsAnnounce(params);
    getMaListeHomePage({
      ...params,
      featuredSolution: JSON.stringify(paramsAnnounce.featuredSolution),
      softwareSolution: JSON.stringify(paramsAnnounce.softwareSolution),
      targetedIndustry: JSON.stringify(paramsAnnounce.targetedIndustry),
      country: JSON.stringify(paramsAnnounce.country),
    });
  };

  return {
    filteredData,
    limitSolution,
    limitCountry,
    limitIndustry,
    handleSelect,
    selectedSolution,
    handleSeeSolution,
    dataSolution,
    handleSelectedIndustry,
    selectedIndustry,
    handleSeeIndustry,
    selectedCountry,
    setSelectedCountry,
    handleSelectedCountry,
    handleSeeCountry,
    resetFilter,
    announcing: announcing?.data,
    pagination: announcing?.pagination,
    paramsAnnounce,
    lunchFilter,
    userReducer,
    isConnected,
    showLoading: loadingFetch || loadingFilter,
    handlePageClick,
    searchText,
    setSearchText,
    search,
  };
}
