/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./card-section-style.css";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

type Iprops = {
  txtError: string | undefined;
  chageCardElement: () => void;
};
function CardSection(props: Iprops) {
  return (
    <label id="cardContainer">
      Credit or debit card number
      <div
        className={`container-error-address pl-2 ${
          props.txtError ? "d-inline" : "d-none"
        }`}
      >
        <span className="txt-error">{props.txtError}</span>
      </div>
      <CardElement
        options={CARD_ELEMENT_OPTIONS}
        onChange={(evt) => props.chageCardElement()}
      />
    </label>
  );
}
export default CardSection;
