import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { fileUrl } from "../../../../utils";
// Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";

export type IPopsModal = {
  // // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
  handleModal: () => void;
  handleSubmitDeactivateProgram: () => void;
  processingDeactivateProgram: boolean;
  showModalDeactivateProgram: boolean;
  showModal: boolean;
};
export default function ModalDecativeProgram(props: IPopsModal) {
  const currentState = useSelector((state: rootState) => state);

  const currentOemProgramPage = currentState.oemProgramReducer;
  
  const activePrograms = currentState?.userReducer?.oemProgramMembers?.filter(
    (program) =>
      program.oem_program?.uuid != ""
  );

  return (
    <Modal
      show={props.showModalDeactivateProgram}
      onHide={props.handleModal}
      className="modalDevinsider medium forDeactivate"
    >
      <Modal.Header closeButton>
        <Modal.Title>Deactivate program</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            Are you sure you want to continue ?
          </h3>
          <p>You are about to deactivate this current program:</p>
          <div className="programtodeactivate">
            <div className="imgSv">
              <img
                src={fileUrl(currentOemProgramPage.program.photo_profil)}
                alt=""
              />
            </div>
            <div className="infoSv">
              <h3 className="title">
                {currentOemProgramPage.program.program_name}
              </h3>
              {currentOemProgramPage.program.company_name}
            </div>
          </div>
          {activePrograms.length > 1 && (
            <p>
              Deactivating a program page will remove the page from Devinsider.
              <br />
              the page will no longer appear in matchmaking results. Affiliated
              members will also be removed.
              <br />
              Once deactivated, you will no longer be able to manage this
              program.
            </p>
          )}
          {activePrograms.length == 1 && (
            <div>
              <p>
                Disabling your latest program will result in the deletion of
                your OEM account from the Devinsider platform.
              </p>
              <p> Are you sure you want to proceed? </p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelbtn" onClick={props.handleModal}>
          Cancel
        </Button>
        {/* // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile */}
        <Button
          className="validate"
          type="submit"
          onClick={() => {
            props.handleSubmitDeactivateProgram();
          }}
        >
          {props.processingDeactivateProgram === true ? (
            <Loader />
          ) : (
            "Deactivate"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
