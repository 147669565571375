import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { TPropsContactSupportFindMoreIsv } from './type';

export default function ContactUsFinMore(
  props: TPropsContactSupportFindMoreIsv
) {
  return (
    <Modal
      show={props?.isShowContactSupportFindMoreIsv}
      className='createCompanypopup large forStep5custom'
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Find more ISVs</h2>
            <a
              className='close'
              onClick={() => props?.setIsShowContactSupportFindMoreIsv(false)}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter contact'>
            <div className='contact row'>
              <div className='name required col-6'>
                <label htmlFor=''>
                  First name
                  {props.errors?.firstNameContactUs && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  className='form-control'
                  {...props?.register('firstNameContactUs', {
                    required: props?.required,
                  })}
                ></input>
              </div>
              <div className='name required col-6'>
                <label htmlFor=''>
                  Last name
                  {props.errors?.lastNameContactUs && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  className='form-control'
                  {...props?.register('lastNameContactUs', {
                    required: props?.required,
                  })}
                />
              </div>
              <div className='required col-6'>
                <label htmlFor=''>
                  Email address
                  {props.errors?.emailContactUs && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>
                        {props.errors.emailContactUs.type === 'required'
                          ? 'This field is required'
                          : 'Please enter a valid email address'}
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  className='form-control'
                  {...props?.register('emailContactUs', {
                    required: props?.required,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter a valid email address',
                    }
                  })}
                />
              </div>
              <div className='required col-6'>
                <label htmlFor=''>
                  Company name
                  {props.errors?.companyNameContactUs && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  className='form-control'
                  {...props?.register('companyNameContactUs', {
                    required: props?.required,
                  })}
                />
              </div>
            </div>
            <div className=' subject row'>
              <div className='help required col-12'>
                <label htmlFor=''>
                  Message
                  {props.errors?.messageContactUs && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <div className='form-group col-12 descCompany p-0'>
                  <textarea
                    {...props?.register('messageContactUs', {
                      required: props?.required,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail row'>
            <div className='affiliatedPopup contentBorder formForEmail text-left col-lg-6'></div>
            <div className='contentBorder formForEmail text-right col-lg-6'>
              <p>&nbsp;</p>
              <button type='submit' className='btn btn-devinsider px-5 save'>
                {props?.loader ? <Loader /> : 'Send'}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
