import { IAction } from "../../types/type-action";
import {
  IcompanyPage,
  IsvInCompanyPage,
} from "../../../interface/company-page-isv";
import * as CONSTANTE from "../../actions/company-page-isv-action/current_isv_action";
export const initialState: IcompanyPage = {
  id: "0",
  completed: false,
  next_page: 0,
  company_name: "",
  company_legal_name: "",
  website: "",
  company_size: "",
  founding_date: "",
  type_of_office: "",
  annual_turnover: "",
  photo_profil: "",
  photo_couverture: "",
  description: "",
  video_profile: "",
  corporate_development: "",
  amount_want_to_raise: "",
  company_summary: "",
  updated_at: "",
  amount_to_raise_min: 0,
  amount_to_raise_max: 0,
  annual_turnover_min: 0,
  annual_turnover_max: 0,
  company_size_min: 0,
  company_size_max: 0,
  targeted_industry: [],
  country: {
    id: 0,
    country_name: "",
  },
  city: "",
  futur_milestones_expansion_strategy: "",
  future_milestones_product_development: "",
  paint_point_pitch: "",
  key_competitive_differentiator_pitch: "",
  team_differentiator_pitch: "",
  segment_details: [],
  partners: [],
  oem_program_segment_interest: [],
  oemProgramSegmentInterestText: "",
  is_joining_soon: false,
  isvPostClassifiedAds: {
    number_left: 0,
    next_grant_at: "",
    amount: 0,
  },
  isvPostPressRelease: {
    number_left: 1,
    next_grant_at: "",
    amount: 0,
  },
  isvUnlockFreeInvestor: {
    number_left: 0,
  },
  validate_by_devinsider: false,
  distribution_channel: [],
  licensing_model: [],
  targeted_country: [],
  funding_round: {
    id: 0,
    name: "",
  },
  progressBasicInfo: 0,
  progressOverview: 0,
  progressStrategy: 0,
  progressFinancial: 0,
  progressPitch: 0,
  totalProgress: 0,
  companyPageGraphs: [{ year: "", amount: "" }],
  pitch_file: "",
  companyPageLeadershipTeams: [
    {
      id: "",
      name: "",
      job_title: "",
      description: "",
      linkedin: "",
      photo_profil: "",
      id_form: 0,
    },
  ],
  membersInvitations: [],
  isvInCompanyPage: [],
  billing_owner_email: "",
  billing_owner_username: "",
  payment_method: "",
  billingInfo: {
    free_status: {
      isv_unlock_investor: {
        number_left: 0,
        number_max: 0,
      },
      isv_free_ads: {
        number_left: 0,
        number_max: 0,
        next_grant: "",
        spent_by: [],
      },
      isv_press_release: {
        number_left: 0,
        number_max: 0,
        next_grant: "",
        spent_by: [],
      },
    },
    information_member: [],
    monthRange: [],
  },
};

export const companyPageReducer = (
  state = initialState,
  action: IAction
): IcompanyPage => {
  switch (action.type) {
    case CONSTANTE.REMOVE_COUVERTURE_COMPANY_PAGE:
      return {...state,photo_couverture:action.payload};
    case CONSTANTE.REMOVE_LOGO_COMPANY_PAGE:
      return {...state,photo_profil:action.payload};
    case CONSTANTE.UPDATE_VIDEO_COMPANY_PAGE:
      return {...state,video_profile : action.payload};
    case CONSTANTE.CREATE_COMPANY_PAGE:
      return { ...state, ...action.payload };
    case CONSTANTE.GET_COMPANY_PAGE:
      return { ...state, ...action.payload };
    case CONSTANTE.GET_COMPANY_PAGE_ATTRIBUTE:
      return {
        ...state,
        [action.payload.attribute]: action.payload.data,
      };
    case CONSTANTE.UPDATE_COMPANY_PAGE:
      if (action?.payload?.keyToUpdate) {
        return {
          ...state,
          [action?.payload?.keyToUpdate]: action.payload?.data,
        };
      } else {
        return {
          ...state,
          ...action.payload,
        };
      }

    case CONSTANTE.GET_COMPANY_PAGE_PROGRESS:
      return {
        ...state,
        ...action.payload?.details,
      };
    case CONSTANTE.SEND_INVITATION_AFFILIATED:
      return {
        ...state,
        membersInvitations: customSendInvitaion(
          state?.membersInvitations,
          action.payload
        ),
      };
    case CONSTANTE.REMOVE_INVITATION_AFFILIATED:
      return {
        ...state,
        membersInvitations: customSendInvitaion(
          state?.membersInvitations,
          action.payload
        ),
      };
    case CONSTANTE.UPDATE_ROLE_AFFILIATED:
      return {
        ...state,
        isvInCompanyPage: updateRoleHelper(
          state?.isvInCompanyPage,
          action.payload
        ),
      };
    case CONSTANTE.REMOVE_ROLE:
      return {
        ...state,
        isvInCompanyPage: removeRoleHelper(
          state?.isvInCompanyPage,
          action.payload
        ),
      };
    case CONSTANTE.ADD_LEADER_SHIP:
      return {
        ...state,
        companyPageLeadershipTeams: action?.payload,
      };
    case CONSTANTE.REMOVE_LEADER_SHIP:
      return {
        ...state,
        companyPageLeadershipTeams: removeByIdHelper(
          state?.companyPageLeadershipTeams,
          action.payload
        ),
      };

    case CONSTANTE.VALID_REQUEST_TO_AFFILIATED:
      return {
        ...state,
        membersInvitations: removeByIdHelper(
          state?.membersInvitations,
          action.payload?.idInvitation
        ),
        isvInCompanyPage:
          action?.payload?.event === "MEMBER_INVITATION_APPROVE"
            ? [...state?.isvInCompanyPage, ...action?.payload?.isvInCompanyPage]
            : [...state?.isvInCompanyPage],
      };

    case CONSTANTE.UPDATE_BILLING_CENTER_OWNER:
      return {
        ...state,
        billing_owner_email: action.payload.user_email,
        billing_owner_username:
          action.payload.user_last_name + " " + action.payload.user_first_name,
      };

    case CONSTANTE.UPDATE_BILLING_CENTER_CARD:
      return {
        ...state,
        payment_method: action.payload,
      };

    default:
      return state;
  }
};

const customSendInvitaion = (state: any, value: any) => {
  let found = state?.find((el: any) => el?.id == value?.id);
  let returnValue = [];
  if (found) {
    returnValue = [...state?.filter((el: any) => el.id != value?.id)];
  } else {
    returnValue = [value, ...state];
  }
  return returnValue;
};

const updateRoleHelper = (state: any, value: any): Array<IsvInCompanyPage> => {
  let updateRole = state?.map((el: any) => {
    if (el?.user?.id == value?.idUser) {
      return {
        ...el,
        role: value?.role,
      };
    } else {
      return el;
    }
  });
  return updateRole;
};

const removeRoleHelper = (state: any, value: any): Array<IsvInCompanyPage> => {
  let tempState = state?.filter(
    (el: any) => el?.user?.id?.toString() !== value?.id?.toString()
  );
  return tempState;
};

const removeByIdHelper = (state: any, value: any): Array<any> => {
  let tempState = state?.filter(
    (el: any) => el?.id?.toString() != value?.toString()
  );
  return tempState;
};
