import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getDirectoryPrograms,
  showMoreInfo,
} from "../../../redux/actions/find-program-action";
import { rootState } from "../../../redux/reducers";
import { IProgram } from "../../../redux/types/find-program-interface";
const queryString = require("query-string");

type IFilter = {
  selectedType: Array<number>;
  selectedLocation: Array<number>;
  selectedSolution: Array<number>;
};

export default function ProgramDirectoryCtr(
  props: IFilter = {
    selectedType: [],
    selectedLocation: [],
    selectedSolution: []
  }
) {
  const dispatch = useDispatch();
  let params:any = useLocation();
  let searchValue = queryString.parse(params?.search)?.search;

  const programObject = useSelector(
    (state: rootState) => state?.findProgramReducer?.directory_programs
  );

  const [loader, setLoader] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const defaultPageForSearch = 1;
  const [search, setSearch] = useState<string>(searchValue || "");

  const [type, setType] = useState<Array<number>>(props?.selectedType);
  const [location, setLocation] = useState<Array<number>>(
    props?.selectedLocation
  );
  const [solution, setSolution] = useState<Array<number>>(
    props?.selectedSolution
  );

  const cb = (data: any) => {
    if (data?.status === 200) {
      setLoader(false);
    }
  };

  const getDirectoryProgram = (key: string, value: any) => {
    setLoader(true);
    let params = {
      limit: limit,
      page: defaultPageForSearch,
      oem_program_type: type.length === 0 ? "" : "(" + type.join().trim() + ")",
      hq_location:
        location.length === 0 ? "" : "(" + location.join().trim() + ")",
      solutions:
        solution.length === 0 ? "" : "(" + solution.join().trim() + ")",
      search: search,
      [key]: value,
    };
    dispatch(getDirectoryPrograms(params, cb));
  };

  useEffect(() => {
    setCurrentPage(1);
    getDirectoryProgram("page", 1);
  }, [type, location, solution]);

  useEffect(() => {
    setType(props?.selectedType);
    setLocation(props?.selectedLocation);
    setSolution(props?.selectedSolution);
  }, [props]);

  const handlePageChange = (data: any) => {
    setCurrentPage(data);
    getDirectoryProgram("page", data);
  };

  const handleSearch = (event: any) => {
    setSearch(event?.target?.value);
    if (event?.key === "Enter") {
      getDirectoryProgram("search", event?.target?.value);
    }
  };

  const callbackMore = (data: any) => {};

  const showMore = (program: IProgram) => {
    if (!program?.information_fetched) {
      let data = {
        id: program?.id,
        solutions:
          program?.solutions?.length > 0
            ? "(" +
              program?.solutions
                ?.map((el) => el?.id)
                .join()
                .trim() +
              ")"
            : "",
        program: program,
      };
      dispatch(showMoreInfo(data, callbackMore));
    }
  };

  const clearSearch = () => {
    setCurrentPage(1);
    getDirectoryProgram("page", 1);
  };

  return {
    programObject,
    handlePageChange,
    limit,
    currentPage,
    search,
    setSearch,
    handleSearch,
    showMore,
    loader,
    clearSearch
  };
}
