import React, { useState, useEffect } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { contact } from "../../../utils/country_phone";
import {
  isEmail,
  isRequired,
  between,
  setSessionStorageObject,
  getSStorage,
} from "../../../utils/index";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompletePageInvestor() {
  const history = useHistory();

  const [selected, setSelected] = useState(
    getSStorage("phoneSelected") ? getSStorage("phoneSelected") || "" : "US"
  );

  const [dialCodeSelected, setDialCodeSelected] = useState(
    getSStorage("dialCode") ? getSStorage("dialCode") || "" : "+1"
  );

  const [toogleActive, setToogleActive] = useState<boolean>(
    getSStorage("toogleActive") == null
      ? true
      : getSStorage("toogleActive") == "true"
      ? true
      : false
  );
  const [jobTitileInvestor, setJobTitileInvestor] = useState<string>(
    getSStorage("jobTitileInvestor")
      ? getSStorage("jobTitileInvestor") || ""
      : ""
  );
  const [organisationNameInvestor, setOrganisationNameInvestor] =
    useState<string>(
      getSStorage("organisationNameInvestor")
        ? getSStorage("organisationNameInvestor") || ""
        : ""
    );
  const [emailInvestor, setEmailInvestor] = useState<string>(
    getSStorage("emailAdresseInvestor")
      ? getSStorage("emailAdresseInvestor") || ""
      : getSStorage("emailInvestor") || ""
  );
  const [phoneNumberInvestor, setPhoneNumberInvestor] = useState<string>(
    getSStorage("phoneValue") ? getSStorage("phoneValue") || "" : ""
  );

  const [txtEmailError, setTxtEmailError] = useState("");
  const [phoneNumberError, setTxtPhoneNumberError] = useState(
    "This field is required"
  );
  /*
   *Error form
   */
  const [errorJobTitileInvestor, setErrorJobTitileInvestor] =
    useState<boolean>(false);
  const [errorOrganisationNameInvestor, setErrorOrganisationNameInvestor] =
    useState<boolean>(false);
  const [errorEmailAdresseInvestor, setErrorEmailAdresseInvestor] =
    useState<boolean>(false);
  const [errorPhoneNumberInvestor, setErrorPhoneNumberInvestor] =
    useState<boolean>(false);

  /*
   * Valid JobTitileInvestor
   * params string
   * return boolean
   */
  const validJobTitileInvestor = (txt: any) => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorJobTitileInvestor(true);
      return false;
    }
  };

  /*
   * Valid organisation
   * params string
   * return boolean
   */
  const validOrganisationNameInvestor = (txt: any) => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorOrganisationNameInvestor(true);
      return false;
    }
  };

  /*
   * Valid Email
   * params string
   * return boolean
   */
  const validEmailAdresseInvestor = (txt: any) => {
    if (isEmail(txt) && isRequired(txt)) {
      return true;
    } else {
      setErrorEmailAdresseInvestor(true);
      return false;
    }
  };

  /*
   * Valid Email
   * params string
   * return boolean
   */
  const validPhoneInvestor = (txt: any) => {
    if (isRequired(txt) && between(5, 13, txt)) {
      return true;
    } else {
      setErrorPhoneNumberInvestor(true);
      return false;
    }
  };

  useEffect(() => {
    setErrorJobTitileInvestor(false);
    return () => {};
  }, [jobTitileInvestor]);

  useEffect(() => {
    setErrorOrganisationNameInvestor(false);
    return () => {};
  }, [organisationNameInvestor]);

  useEffect(() => {
    setErrorEmailAdresseInvestor(false);
    return () => {};
  }, [emailInvestor]);

  useEffect(() => {
    setErrorPhoneNumberInvestor(false);
    return () => {};
  }, [phoneNumberInvestor]);

  const navigate = () => {
    setSessionStorageObject({
      toogleActive,
      jobTitileInvestor,
      organisationNameInvestor,
      emailAdresseInvestor: emailInvestor,
      phoneNumberInvestor:
        dialCodeSelected +
        phoneNumberInvestor,
      phoneValue: phoneNumberInvestor,
      phoneSelected: selected,
      dialCode: dialCodeSelected
    });
    history.push("/complete-typeof-investor");
  };

  const navigateToInvestorInfo = () => {
    if (
      validJobTitileInvestor(jobTitileInvestor) &&
      validEmailAdresseInvestor(emailInvestor) &&
      validPhoneInvestor(phoneNumberInvestor) &&
      isValidPhoneNumber(
        dialCodeSelected +
          phoneNumberInvestor
      )
    ) {
      if (
        toogleActive &&
        validOrganisationNameInvestor(organisationNameInvestor)
      ) {
        navigate();
      }
      if (!toogleActive) {
        navigate();
      }
    } else {
      if (!validEmailAdresseInvestor(emailInvestor)) {
        setTxtEmailError("your email address is not valid");
      }
      if (!isRequired(emailInvestor)) {
        setTxtEmailError("This field is required");
      }
      if (
        !isValidPhoneNumber(
          dialCodeSelected +
            phoneNumberInvestor
        )
      ) {
        setErrorPhoneNumberInvestor(true);
        setTxtPhoneNumberError("Phone number is not a  valid format");
      }
    }
  };

  const setActive = () => {
    setToogleActive(!toogleActive);
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToInvestorInfo();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateToInvestorInfo,
    setActive,
    setJobTitileInvestor,
    setOrganisationNameInvestor,
    setEmailInvestor,
    setPhoneNumberInvestor,
    phoneNumberError,
    selected,
    setSelected,
    txtEmailError,
    toogleActive,
    jobTitileInvestor,
    organisationNameInvestor,
    emailInvestor,
    phoneNumberInvestor,
    errorJobTitileInvestor,
    errorOrganisationNameInvestor,
    errorEmailAdresseInvestor,
    errorPhoneNumberInvestor,
    dialCodeSelected,
    setDialCodeSelected
  };
}
