import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { setSessionStorageObject } from "../../../../utils";

export default function UseCreate() {
  const history = useHistory();
  const [result, setResult] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: sessionStorage?.getItem("first_name") || "",
      last_name: sessionStorage?.getItem("last_name") || "",
      email: sessionStorage?.getItem("email") || "",
      password: sessionStorage?.getItem("password") || "",
      checked: sessionStorage?.getItem("checked") || "",
    },
  });

  const onSubmit = (data: any) => {
    setResult(JSON.stringify(data));

    setSessionStorageObject({
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: sessionStorage?.getItem("email") || "",
      password: data?.password,
      checked: data?.checked,
    });
    history.push({
      pathname: "/complete-oem-adress-affilliated",
    });
  };

  return {
    register,
    handleSubmit,
    result,
    setResult,
    onSubmit,
    errors,
  };
}
