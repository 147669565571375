/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Header from "../../../widget/index";
import UseEditIsvProfilCtr from "../../../../controller/profil-ctr/use-edit-profil-ctr";
import { ISV } from "../../../../service/constant/index";
import ModalPhoto from "../../../widget/modal/modal-photo";
import {
  concatString,
  fileUrl,
  refaCtoNullKey,
  setInternationalPhoneNumber,
} from "../../../../utils";
import * as ModalEdit from "./profil-modal-edit/index";
import Footer from "../../../widget/footer/footer";
import { contact } from "../../../../utils/country_phone";
import { Modal } from "react-bootstrap";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";

export default function profilISVEdit() {
  const providerUseEditIsvProfilCtr = { ...UseEditIsvProfilCtr() };

  const renderEmailLabel = (roles: Array<string>) => {
    if (roles.indexOf(ISV) !== -1) return "Primary email";
    return "Email";
  };

  const dialCode = (() => {
    if (
      providerUseEditIsvProfilCtr?.userState?.phone_number_code !== "" &&
      providerUseEditIsvProfilCtr?.userState?.phone_number_code !== null
    ) {
      return contact.filter(
        (element) =>
          element.code ===
          providerUseEditIsvProfilCtr?.userState?.phone_number_code
      )[0].dial_code;
    }
    return "";
  })();

  const isIsv =
    providerUseEditIsvProfilCtr?.userState?.roles.indexOf(ISV) !== -1;

  return (
    <div className="isv edit profilIsv">
      <Header />
      <div className="contentProfil">
        <div className="row profilHead m-0 justify-content-between">
          <div className="col-xl-7 col-lg-7 col-md-12 col-12 infoProfil d-flex">
            <div
              className={`wrap imageProfil ${
                providerUseEditIsvProfilCtr?.userState.file_name
                  ? "overnoBg"
                  : ""
              }`}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  providerUseEditIsvProfilCtr?.setProfilUser(true);
                }}
              >
                <div className="absphoto">
                  <span className="icon-edit-full"></span>
                  Edit photo
                </div>
                <img
                  src={fileUrl(
                    providerUseEditIsvProfilCtr?.userState.file_name
                  )}
                  alt=""
                />
              </a>
            </div>
            <div className="aboutProfil">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  providerUseEditIsvProfilCtr?.setShow(true);
                }}
                className="editItem"
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <ul className="ulListInfo">
                <li>
                  <div className="label">Full name</div>
                  <div className="infoLabel">
                    {providerUseEditIsvProfilCtr?.userState.real_first_name}{" "}
                    {providerUseEditIsvProfilCtr?.userState.real_last_name}
                  </div>
                </li>
                <li>
                  <div className="label">Location</div>
                  <div className="infoLabel">
                    {concatString(
                      providerUseEditIsvProfilCtr?.userState?.country
                        ?.country_name,
                      providerUseEditIsvProfilCtr?.userState?.city
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">Company name</div>
                  <div className="infoLabel">
                    {providerUseEditIsvProfilCtr?.userState?.real_company_name}
                  </div>
                </li>
                <li>
                  <div className="label">Job title</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(
                      providerUseEditIsvProfilCtr?.userState?.real_job_title,
                      "-"
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-5 col-md-12 col-12 infoMyIsv"
            onClick={() => providerUseEditIsvProfilCtr?.goToProfil()}
          >
            <a className="editDoneIsv">Done editing </a>
          </div>
        </div>
        <div className="row otherInfoProfil m-0 justify-content-between">
          <div className="col-xl-7 col-lg-7 col-md-12 col-12 p-0 contactInfo">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  providerUseEditIsvProfilCtr?.setLgShow(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h2 className="title">About</h2>
              <p>
                {refaCtoNullKey(
                  providerUseEditIsvProfilCtr?.userState?.plain_text,
                  ""
                )}
              </p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-12 col-12 p-0 companyInfo">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  providerUseEditIsvProfilCtr?.setContactShow(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h3 className="title">Contact information</h3>
              <ul className="ulListInfo smallLabel">
                <li>
                  <div className="label">
                    {renderEmailLabel(
                      providerUseEditIsvProfilCtr?.userState?.roles
                    )}
                  </div>
                  <div className="infoLabel">
                    {providerUseEditIsvProfilCtr?.userState?.email}
                  </div>
                </li>
                {isIsv && (
                  <li>
                    <div className="label">Work email</div>
                    <div className="infoLabel">
                      {refaCtoNullKey(
                        providerUseEditIsvProfilCtr?.userState?.primary_email,
                        "-"
                      )}
                    </div>
                  </li>
                )}

                <li>
                  <div className="label">Phone number</div>
                  <div className="infoLabel">
                    {setInternationalPhoneNumber(
                      providerUseEditIsvProfilCtr?.userState?.phone,
                      dialCode
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">LinkedIn</div>
                  <div className="infoLabel">
                    <a
                      target="_blank"
                      href={
                        "https://www.linkedin.com/in/" +
                        refaCtoNullKey(
                          providerUseEditIsvProfilCtr?.userState?.linkedin,
                          "-"
                        )
                      }
                    >
                      {refaCtoNullKey(
                        providerUseEditIsvProfilCtr?.userState?.linkedin,
                        "-"
                      )}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ModalEdit.ModalEditInfoUser
        cancelChange={() => providerUseEditIsvProfilCtr?.cancelChange()}
        isLoaderShow={providerUseEditIsvProfilCtr?.isLoaderShow}
        onSub={providerUseEditIsvProfilCtr?.onSub}
        showEditModal={providerUseEditIsvProfilCtr?.Show}
      />

      <ModalEdit.ModalEditContactUser
        contactShow={providerUseEditIsvProfilCtr?.contactShow}
        setContactShow={() =>
          providerUseEditIsvProfilCtr?.setContactShow(false)
        }
        cancelChange={() => providerUseEditIsvProfilCtr?.cancelChange()}
        dataSource={providerUseEditIsvProfilCtr}
      />
      <ModalEdit.ModalEditAudience dataSource={providerUseEditIsvProfilCtr} />

      <ModalEdit.ModalEditAbout dataSource={providerUseEditIsvProfilCtr} />

      <ModalPhoto
        changeHandler={(p: any) =>
          providerUseEditIsvProfilCtr?.changeHandler(p)
        }
        isShowImage={providerUseEditIsvProfilCtr?.profilUser}
        hideSelectedFile={() => providerUseEditIsvProfilCtr?.hideSelectedFile()}
        fileUrl={providerUseEditIsvProfilCtr?.userState.file_name}
        selectedFile={providerUseEditIsvProfilCtr?.selectedFile}
        handleBtn={() => providerUseEditIsvProfilCtr?.handleBtn()}
        fileRef={providerUseEditIsvProfilCtr?.fileRef}
        updateUserDetail={(p: any) =>
          providerUseEditIsvProfilCtr?.updateUserDetail(null, "photo")
        }
        isLoaderShow={providerUseEditIsvProfilCtr?.isLoaderShow}
        processingRemovePhotoProfile={
          providerUseEditIsvProfilCtr.processingRemovePhotoProfile
        }
        setShowImage={(p: boolean) =>
          providerUseEditIsvProfilCtr?.setProfilUser(p)
        }
        restorePhotoChange={() =>
          providerUseEditIsvProfilCtr?.restorePhotoChange()
        }
        isProfil={true}
      />
      {/* MODAL CONFIRMATION REMOVE PROFILE PICTURE */}
      <Modal
        show={providerUseEditIsvProfilCtr?.showConfirmationModalRemovePhoto}
        className="small forDesactivate"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Remove profile picture</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                providerUseEditIsvProfilCtr?.setShowConfirmationModalRemovePhoto(
                  false
                );
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>Are you sure you want to remove your current profile picture?</p>
          </div>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => {
                providerUseEditIsvProfilCtr?.setShowConfirmationModalRemovePhoto(
                  false
                );
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => {
                providerUseEditIsvProfilCtr?.handleRemoveProfilePicture();
              }}
            >
              {providerUseEditIsvProfilCtr?.processingRemovePhotoProfile ? (
                <Loader />
              ) : (
                <>Remove</>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
}
