import React from "react";
import Select from "react-select";
import { PaymentBrands } from "../payment-card/payment-brands";
import { LoadingFavoriteProgram } from "../../../widget/loader";

type Props = {
  free?: number;
  cancel: () => void;
  prices?: { [key: string]: number };
  paymentMethod: any;
  currency?: string;
  setCurrency: (currency: string) => void;
  getCurrencySymbol: (currency?: string) => string;
  productPrice: {
    amount: number;
    tax: number;
    currency: string;
    totalAmount: number;
  };
  setProductPrice: (price: {
    amount: number;
    tax: number;
    currency: string;
    totalAmount: number;
  }) => void;
  productName?: string;
  isPriceInfo?: boolean;
  isOem: boolean;
  monthlyBudgetReached?: boolean;
  unlockAllowed?: boolean;
  showErrorOemEventActivate?: boolean;
};

export const ProductInfo: React.FC<Props> = ({
  free,
  prices,
  currency,
  setCurrency,
  paymentMethod,
  getCurrencySymbol,
  setProductPrice,
  productPrice,
  productName,
  isPriceInfo,
  isOem,
  monthlyBudgetReached,
  unlockAllowed,
  showErrorOemEventActivate,
}) => {
  // useEffect(() => {
  //     setCurrency('usd')
  // }, []);
  const handleCurrencyChange = (cur: string) => {
    setCurrency(cur);
    setProductPrice({
      ...productPrice,
      currency: cur,
      amount: prices ? prices[cur] : 0,
      tax: 0,
      totalAmount: prices ? prices[cur] : 0,
    });
  };

  const priceOptions = prices
    ? Object.keys(prices).map((key) => ({
        label: getCurrencySymbol(key) + " " + key.toUpperCase(),
        value: key,
      }))
    : [];

  const goToTermOfUse = () => {
    const url = "/terms-and-conditions";
    const tab = window.open(url, "_blank");
    tab?.focus();
  };

  const isShowErrorEvent = showErrorOemEventActivate && isOem;

  return (
    <div className="row forAds">
      {!!free && (
        <>
          <div className="border radius col-12 d-flex">
            <input
              type="radio"
              // defaultChecked={props.stateUnlock?.number_left ? true : false}
              checked
              name="choice"
              value="FREE"
            />
            <h5>
              {free} {/* out of{' '}  {props.stateUnlock?.number_max}  */}
              {productName} credit(s) available
            </h5>
          </div>
          {!isShowErrorEvent && (
            <div className="payC">
              <label htmlFor="">
                By clicking "Confirm", you agree to spend one (1) free credit.
              </label>
            </div>
          )}
          {isShowErrorEvent && (
            <div className="payC">
              <p>
                To use the event feature you need to publish your program page.
              </p>
            </div>
          )}
        </>
      )}

      {!free && prices && (
        <>
          {priceOptions.length > 1 && (
            <div id="currencies" className="col-3 pr-0 float-right">
              <Select
                classNamePrefix="currencies"
                options={priceOptions}
                onChange={(el: any) => {
                  handleCurrencyChange(el.value);
                }}
                value={priceOptions.find((el) => el.value === currency)}
                placeholder="Currency ..."
              />
            </div>
          )}
          <div className="border radius col-12 d-flex">
            <h5 className="pl-0">Total:</h5>
            <h5 className="color-orange text-align-right pr-0">
              <>
                {" "}
                {currency
                  ? getCurrencySymbol() + " " + prices[currency]
                  : 0.0}{" "}
                {currency?.toUpperCase()}
                <p className="small color-light-gray">(ex. VAT)</p>
              </>
            </h5>
          </div>
          {isShowErrorEvent && (
            <div className="payC">
              <p>
                To use the event feature you need to publish your program page.
              </p>
            </div>
          )}
          {!paymentMethod && !isPriceInfo && !monthlyBudgetReached && (
            <>
              {!isOem && (
                <div className="border radius col-12 d-flex">
                  <h5>
                    <div className="checkCard events">
                      <p className="cdt-dbt bold">Credit/Debit Card</p>
                      <PaymentBrands />
                    </div>
                  </h5>
                </div>
              )}
            </>
          )}
          {!paymentMethod && isOem && !monthlyBudgetReached && (
            <>
              {isOem && (
                <div className="payC">
                  <p>You do not have a credit/debit card registered.</p>
                  <p>
                    To continue your billing owner needs to register a valid
                    payment method in the billing center.
                  </p>
                </div>
              )}
            </>
          )}

          {isOem && monthlyBudgetReached && (
            <div className="payC">
              <p>
                The monthly unlock limit has been reached. To unlock more ISVs,
                contact your program owner or admin to increase your monthly
                budget.
              </p>
            </div>
          )}

          {!isPriceInfo &&
            !monthlyBudgetReached &&
            !unlockAllowed &&
            isOem &&
            paymentMethod && (
              <div className="payC process-blocked-description">
                You cannot process this transaction because the previous invoice
                of ISV unlocks is not yet paid. Please contact your billing
                owner to solve this issue.
              </div>
            )}

          {paymentMethod && !isPriceInfo && (
            <>
              <div className="border radius col-12 d-flex">
                <input type="radio" name="choice" value="PAY" checked={true} />
                <h5>
                  {paymentMethod.card.brand && <>{paymentMethod.card.brand}</>}{" "}
                  ***********
                  {paymentMethod.card.last4 && <>{paymentMethod.card.last4}</>}
                  <p className="small cdt-dbt color-light-gray mt-3">
                    Credit/Debit Card
                  </p>
                </h5>
              </div>
              <div className="payC">
                <label htmlFor="">
                  By clicking "Confirm", you agree to our{" "}
                  <a onClick={() => goToTermOfUse()}>terms & conditions</a>
                  {isOem && <> and you will complete this purchase.</>}
                  {!isOem && (
                    <>. You will be automatically charged for your purchase.</>
                  )}
                </label>
              </div>
            </>
          )}
        </>
      )}
      {!free && !prices && LoadingFavoriteProgram()}
    </div>
  );
};
