import SubscriptionPlan from '../screen/container/settings/billing-center/subscription-plan';
import { ISV, OEM_PROGRAM } from '../service/constant';
export const routesBillingCenter = [
  // {
  //   component: CardRegistrationInput,
  //   exact: true,
  //   path: '/billing-center/card-registration',
  //   guard: 'private',
  // },
  {
    component: SubscriptionPlan,
    exact: true,
    path: '/billing-center/subscription-plan',
    guard: 'private',
    allowedRole: [OEM_PROGRAM, ISV],
  },
];
