import React, { useState } from "react";
import * as Block from "./index";
import { useHistory, useLocation } from "react-router-dom";

type IProps = {
  reloadFilter?: () => void;
};
export default function SecondHeaderIsv(props: IProps) {
  const [show, setShow] = useState(false);

  const history = useHistory();

  const goToFindIsv = () => {
    history.push({
      pathname: "/isv/match",
    });
  };

  const goToIsvUnlocked = () => {
    history.push({
      pathname: "/isv/isv-unlocked",
    });
  };
  const goToFindMore = () => {
    history.push({
      pathname: "/isv/find-more",
    });
  };

  const goFavorite = () => {
    history.push({
      pathname: "/isv/favorite-page",
    });
  };

  const urlPath = window.location.href;
  let valueofSubmenu;
  urlPath.indexOf("match") !== -1
    ? (valueofSubmenu = "match")
    : urlPath.indexOf("isv-unlocked") !== -1
    ? (valueofSubmenu = "isv-unlocked")
    : urlPath.indexOf("find-more") !== -1
    ? (valueofSubmenu = "find-more")
    : urlPath?.indexOf("favorite-page") !== -1
    ? (valueofSubmenu = "favorite-page")
    : (valueofSubmenu = "neant");

  return (
    <>
      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">Find ISVs</h2>
            <Block.Nav
              goFavorite={goFavorite}
              goToFindIsv={goToFindIsv}
              goToFindMore={goToFindMore}
              goToIsvUnlocked={goToIsvUnlocked}
              setShow={setShow}
              valueofSubmenu={valueofSubmenu}
            />
            <Block.ModalBlackListe
              setShow={(p) => setShow(p)}
              show={show}
              reloadFilter={() => props.reloadFilter && props.reloadFilter()}
            />
          </div>

          <div className="rightItemHeader"></div>
        </div>
      </div>
    </>
  );
}
