import {
  dataWithToken,
  deleteData,
  deleteMicroserviceDataWithToken,
  getDataWithoutToken,
  getMicroserviceDataWithToken,
  patchData,
  postData,
  postMicroserviceDataWithToken,
  postPhoto,
  postWithoutToken,
} from "../api";

/**
 * @param params generique data to post
 * @param url url web service
 * @param token token for identifie user
 * @returns promise response post data
 */
export async function postDataFormDataService<T>(
  params: T,
  url: string,
  token: string
) {
  try {
    let tempData: dataWithToken = {
      url,
      data: params,
    };
    let response: any = await postPhoto(tempData, token);
    return response;
  } catch (error) {
    return error;
  }
}
/**
 * @param url url for api
 * @param token token to verifie id user
 * @returns promise any , response service
 */
export async function getDataService(url: string, params: any, token: string) {
  let response: any = await getDataWithoutToken(url, params, token);
  return response;
}

/**
 * @param url url for api
 * @param token token to verify id user
 * @returns promise any , response service
 */
export async function getDataMicroService(
  url: string,
  params: any,
  token: string
) {
  let response: any = await getMicroserviceDataWithToken(url, params, token);
  return response;
}

/**
 * @param url url for api
 * @param token token to verify id user
 * @returns promise any , response service
 */
export async function deleteDataMicroService(
  url: string,
  params: any,
  token: string
) {
  let response: any = await deleteMicroserviceDataWithToken(url, params, token);
  return response;
}

/**
 * @param url url for api
 * @param token token to verify id user
 * @returns promise any , response service
 */
export async function postDataMicroService(
  url: string,
  params: any,
  token: string
) {
  let response: any = await postMicroserviceDataWithToken(url, params, token);
  return response;
}

/**
 * @param url url for api
 * @param data params , not obligatoire
 * @returns promise any , response service
 */
export async function postDataWTokenService(url: string, data?: any) {
  let response: any = await postWithoutToken(url, data);
  return response;
}

/**
 * @param url url for api
 * @param data params json
 * @param token  token to identifie user
 * @returns promise any , response service
 */
export async function postJsonDataService(
  url: string,
  data: any,
  token: string
) {
  let response: any = await postData(url, data, token);
  return response;
}

/**
 * @param url url for api
 * @param data params json
 * @param token  token to identifie user
 * @returns promise any , response service
 */
export async function deleteMethode(url: string, token: string) {
  let params = {
    url,
    token,
    data: null,
  };
  let response: any = await deleteData(params);

  return response;
}

export async function patchDataService(url: string, data: any, token: string) {
  let response: any = await patchData(url, data, token);
  return response;
}
