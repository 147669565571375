import React, { createRef, useRef, useState } from "react";
import { IEvent } from "../../../../redux/types/event-interface";
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  getGMTOffset,
  getTimeZone,
  handleDateEvent,
  handleEventType,
  isExist,
  limitedText,
} from "../../../../utils";
import { IEventPage } from "./type";

export default function AllEvent(props: IEventPage) {
  const [idToSHowMore, setIdToSHowMore] = useState<number>(0);
  let buttonRefs: any = [];

  const handleShowMore = (index: number) => {
    if (buttonRefs) {
      if (buttonRefs[index].current) {
        buttonRefs?.[index]?.current?.click();
      }
    }
  };

  function moreInfoEvent(element: any, id: number) {
    const elementLi: any = document.getElementById(
      element.target.getAttribute("data-id")
    );
    if (elementLi.classList.contains("open")) {
      elementLi.classList.remove("open");
      element.target.innerHTML = "Show more";
      setIdToSHowMore(0);
    } else {
      setIdToSHowMore(id);
      elementLi.classList.add("open");
      element.target.innerHTML = "Show less";
    }
  }

  const targetingUrl = (url: string) => {
    const isHttpStart = url.startsWith("http://") || url.startsWith("https://");
    let urlRedirect = url;
    if (!isHttpStart) {
      urlRedirect = `https://${url}`;
    }

    return window.open(urlRedirect, "_blank");
  };

  return (
    <ul className="listOfIsvMatch listEvents">
      {props?.events?.map((el: IEvent, key: number) => {
        const newRef = createRef();
        buttonRefs.push(newRef);
        return (
          <li id={"li" + key} key={"li" + key}>
            <div className="isvLogo">
              <img src={fileUrl(el?.logo)} alt="" />
              <div className="descAds">
                <h6>
                  {el.id === idToSHowMore
                    ? el?.company_name
                    : limitedText(el?.company_name, 20)}
                </h6>
              </div>
            </div>
            <div className="isvInfo">
              <div className="infoUnlocked">
                <div className="calendar">
                  <div className="item">
                    <div className="date">
                      <div className="cal">
                        <span className="month">
                          {el?.at_date?.split(" ")[0]}
                        </span>
                        <span className="day">
                          {el?.at_date?.split(" ")[1]}
                        </span>
                      </div>

                      <div
                        className="eventInfos"
                        onClick={() => handleShowMore(key)}
                      >
                        {el?.title}
                        <div className="infoEvent">
                          <div className="dateEvent">
                            {el?.at_time + " " + getGMTOffset(el?.timezone)}
                          </div>
                          <div className="placeEvent">
                            {handleEventType(el)}
                          </div>
                          {isExist(el.end_at) ? (
                            <div className="hourEvent">
                              ~ {handleDateEvent(el?.duration)}
                            </div>
                          ) : (
                            <div className="hourEvent">All day event</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="txtLabel">
                  {el?.event_tags?.map((tag, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      style={{ cursor: "default" }}
                    >
                      {tag?.title}
                    </span>
                  ))}
                </div>

                <div className="infoEventContainer">
                  <div
                    className="detailsAds"
                    dangerouslySetInnerHTML={createMarkup(
                      convertCommentFromJSONToHTML(
                        getDescriptionEditorState(el?.presentation_text)
                      )
                    )}
                  />
                </div>
              </div>
              <div className="actionUnlocked">
                <div className="btnContent">
                  <button
                    className="btn btn-devinsider"
                    onClick={() => targetingUrl(el?.register)}
                  >
                    Register
                  </button>
                  <button
                    data-id={"li" + key}
                    className="btn btn-devinsider view"
                    onClick={(e) => {
                      moreInfoEvent(e, el.id);
                    }}
                    ref={buttonRefs[key]}
                  >
                    Show more
                  </button>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
