import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import store from "../redux/store";

interface PublicRouteRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  key: string;
  path: string;
  exact: boolean;
  allowedRole: Array<string>;
}

export default function PublicRoute(props: PublicRouteRouteProps) {
  const { component: Component, isSignedIn, ...rest } = props;

  const isConnected =
    store.getState().userReducer && store.getState().userReducer.enable;

  let isAcceptedConnected = false;

  if (props.allowedRole?.length > 0 && isConnected) {
    const roleConnected =
      store.getState().userReducer && store.getState().userReducer.roles?.[0];

    isAcceptedConnected = props.allowedRole?.find((el) => el === roleConnected)
      ? true
      : false;
  } else {
    isAcceptedConnected = true;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAcceptedConnected ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/profil-not-found",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}
