import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUserAction } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";

export default function UseEditAbout() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [expertise, handleChangeExpertise] = useState<string>(
    userState.plain_text
  );
  const [companyOverview, handleCompanyOverview] = useState<string>("");
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const dispatch = useDispatch();

  const cancelChange = () => {
    setShowModal(false);
  };

  const cbUpdatePhoto = (data: any) => {
    setIsLoaderShow(false);
    setShowModal(false);
  };

  const updateInfoAbout = () => {
    setIsLoaderShow(true);
    let data = {
      plainText: expertise,
      fonction: "updateUser"
    };
    dispatch(updateInfoUserAction(data, cbUpdatePhoto));
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      handleChangeExpertise(userState?.plain_text || "");
    }
  }, [showModal]);

  return {
    showModal,
    setShowModal,
    expertise,
    handleChangeExpertise,
    companyOverview,
    handleCompanyOverview,
    isLoaderShow,
    cancelChange,
    updateInfoAbout,
    handleShowModal,
  };
}
