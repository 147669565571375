import { ChangeEvent, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UseCommunityPRCtr, {
  ITagsProps,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-press-release-ctr";
import PressReleaseComponent from "../../../../../hooks/PressReleaseComponent";
import { rootState } from "../../../../../redux/reducers";
import { ISV } from "../../../../../service/constant";
import Header from "../../../../widget/";
import Footer from "../../../../widget/footer/footer";
import UsePayementPr from "../../../../widget/payement-isv/publish-pr/use-publish-press-release";
import FormPost from "../../../../widget/post-wysiwig/index";
import { PaymentPopup } from "../../../payment-popup/payment-popup";

export default function StartPressRelease() {
  const providerUsePaymentPr = UsePayementPr(true);
  const providerCommunity = UseCommunityPRCtr();

  const user = useSelector((state: rootState) => state.userReducer);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();

  const isIsv = user?.roles[0]?.toString() === ISV;

  const publishPR = (paymentMethod = null) => {
    providerCommunity.pbPR(paymentMethod);
  };

  const goToPaymentModal = () => {
    setProduct({
      lookupKey: "PRESS_RELEASE",
      name: "Press release",
    });
    setShowPaymentModal(true);
  };

  const paymentMethodCallBack = (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    providerCommunity.publishPR(
      isFree,
      paymentMethodId,
      setLoading,
      setShowPaymentModal,
      savePaymentMethod,
      setShowPaymentStatus
    );
  };

  const handleStatusOk = () => {
    providerCommunity?.showPr();
  };

  useEffect(() => {
    // if (providerUsePaymentPr.statePR) {
    const root = document.getElementsByClassName("rdw-option-wrapper");
    const foontSizeWrapper = document.getElementsByClassName(
      "rdw-fontsize-wrapper"
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      "rdw-fontfamily-wrapper"
    );
    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = "d-none";
      subsciprt.className = "d-none";
      font.className = "d-none";
      puiss.className = "d-none";
    }

    if (foontSizeWrapper && foontSizeWrapper[0]) {
      foontSizeWrapper[0].className = "d-none";
    }

    if (fontFamillyWrapper && fontFamillyWrapper[0]) {
      fontFamillyWrapper[0].className = "d-none";
    }
    // }
  }, []);

  useEffect(() => {
    if (providerCommunity?.errorAffiliated) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Complete information</h1>
              <p>
                You must be affiliated to a company page in order to publish a
                press release
              </p>
            </div>
          );
        },
      });
      providerCommunity?.setErrorAffiliated(false);
    }
  }, [providerCommunity?.errorAffiliated]);

  return (
    <PressReleaseComponent>
      <div>
        <Header />

        <section className="discussion">
          <div className="container mx-auto">
            <ul className="breadcrumbPerso">
              <li>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    return;
                  }}
                >
                  Community
                </a>
              </li>
              <li>New press release</li>
            </ul>
            <div className="rightCommunityForum allWidth oneCateg postContainer">
              <div className="labelLeft">
                <div className="block">
                  <h2 className="title">Tags</h2>
                  <div className="form-control relative">
                    <input
                      type="text"
                      className="form-control search-msg lab"
                      placeholder="Search"
                      value={providerCommunity?.txtFilter}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                        providerCommunity?.setTxtFilter(evt.target.value)
                      }
                    />

                    <div
                      className={`listOfChoice ${
                        providerCommunity?.dataTagsFilter?.length < 1
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <ul>
                        {providerCommunity?.dataTagsFilter?.map((el: any) => (
                          <li
                            onClick={() =>
                              providerCommunity.handleFilterSelect(el?.value)
                            }
                          >
                            {el?.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <label htmlFor="">Choose a Tag :</label>

                  <div className="listOfTopic">
                    {providerCommunity?.tags?.map(
                      (tag: ITagsProps, index: number) => (
                        <Link
                          to={"#"}
                          key={index}
                          id={tag.id}
                          className={
                            providerCommunity?.dataListe?.find(
                              (el) => el.trim() === tag?.name.trim()
                            )
                              ? "topic active"
                              : "topic"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            providerCommunity?.handleSelectedLabels(
                              " " + tag?.name
                            );
                          }}
                        >
                          {tag.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="containerCategory">
                <h2 className="title">
                  New press release{" "}
                  {providerCommunity?.titleError && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">Subject is required</span>
                    </div>
                  )}
                </h2>
                <FormPost
                  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                  mention={providerCommunity?.mentions}
                  updateMention={providerCommunity?.updateMention}
                  editorState={providerCommunity?.stateFormWysywig}
                  setEditorState={providerCommunity?.setEditorStatePost}
                  sendPost={() => {
                    !isIsv && publishPR();
                    isIsv &&
                      providerUsePaymentPr.statePR &&
                      providerUsePaymentPr.statePR?.number_left > 0 &&
                      publishPR();
                    isIsv &&
                      providerUsePaymentPr.statePR &&
                      providerUsePaymentPr.statePR?.number_left < 1 &&
                      goToPaymentModal();
                  }}
                  cancelPost={() => providerCommunity?.cancelPost()}
                  object={providerCommunity?.object}
                  setObject={(evt: string) =>
                    providerCommunity?.setTxtObject(evt)
                  }
                  loader={providerCommunity?.loading}
                  showPreview={() => providerCommunity?.showPreview()}
                  disabledButton={!providerUsePaymentPr.statePR && isIsv}
                  confirmButtonTitle={
                    !isIsv
                      ? "Publish"
                      : providerUsePaymentPr.statePR
                      ? providerUsePaymentPr.statePR?.number_left > 0
                        ? "Publish"
                        : "Pay and publish"
                      : "Loading..."
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <Footer postType={"3"} />
      </div>

      <PaymentPopup
        show={showPaymentModal}
        title={"Post press release"}
        setShowPaymentModal={setShowPaymentModal}
        product={product}
        successCallback={paymentMethodCallBack}
        free={providerUsePaymentPr.statePR?.number_left}
        isPriceInfo={false}
        handleStatusOk={handleStatusOk}
      />
    </PressReleaseComponent>
  );
}
