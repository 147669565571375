import React from "react";
import { fileUrl, mounths } from "../../../../../utils";
import Carousel from "react-bootstrap/Carousel";
type Iprops = {
  users: any;
};
const MembersPlanDetailsOEM = (props: Iprops) => {
    console.log(props?.users);
  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-3 selectMonth">
            <Carousel
              interval={null}
              activeIndex={props.users.index}
              onSelect={props.users.handleSelect}
            >
              {mounths?.map((el: { id: number; value: string }) => (
                <Carousel.Item>
                  <Carousel.Caption>
                    <h3>
                      {el.value} {props.users.currentYears}
                    </h3>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="contentBorder affiliatedPopup planOem p-0">
        <div className="contentBorder p-0">
          {props?.users?.userInfo?.map((user: any) => (
            <div className="row justify-content-around color-grey flex-nowrap small">
              <div className="d-flex align-items-center p-3">
                <div className="profilImg empty">
                  <img src={fileUrl(user?.user_photo)} alt="" />
                </div>
                <div className="infoAffiliated">
                  <a>
                    {user.user_last_name} {user.user_first_name}
                  </a>
                  <span className="role">{user.user_role}</span>
                </div>
              </div>
              <div className="d-flex p-3 flex-column">
                {user.UNLOCK_ISV === 0 && (
                  <div className="font-size-medium color-black">- </div>
                )}
                {user.UNLOCK_ISV !== 0 && (
                  <div className="font-weight-600 font-size-medium color-black">
                    {user.UNLOCK_ISV}{" "}
                  </div>
                )}
                ISVs <br />
                unlocked
              </div>
              <div className="d-flex p-3 flex-column">
                {user.POST_FEATURED_ARTICLE === 0 && (
                  <div className="font-size-medium color-black">N/A </div>
                )}
                {user.POST_FEATURED_ARTICLE !== 0 && (
                  <div className="font-weight-600 font-size-medium color-black">
                    {user.POST_FEATURED_ARTICLE}{" "}
                  </div>
                )}
                Featured article(s) <br />
                published
              </div>
              <div className="d-flex p-3 flex-column">
                {user.POST_PRESS_RELEASE === 0 && (
                  <div className="font-size-medium color-black">N/A </div>
                )}
                {user.POST_PRESS_RELEASE !== 0 && (
                  <div className="font-weight-600 font-size-medium color-black">
                    {user.POST_PRESS_RELEASE}{" "}
                  </div>
                )}
                Press release(s)
                <br />
                published
              </div>

              <div className="d-flex p-3 flex-column">
                {user.POST_CORPORATE_DEVELOPMENT === 0 && (
                  <div className="font-size-medium color-black">- </div>
                )}
                {user.POST_CORPORATE_DEVELOPMENT !== 0 && (
                  <div className="font-weight-600 font-size-medium color-black">
                    {user.POST_CORPORATE_DEVELOPMENT}{" "}
                  </div>
                )}
                Corp. development ad(s) <br />
                published
              </div>
              <div className="d-flex p-3 flex-column">
                {user.POST_EVENT === 0 && (
                  <div className="font-size-medium color-black">- </div>
                )}
                {user.POST_EVENT !== 0 && (
                  <div className="font-weight-600 font-size-medium color-black">
                    {user.POST_EVENT}{" "}
                  </div>
                )}
                Event(s) <br />
                published
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MembersPlanDetailsOEM;
