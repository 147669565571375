/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import Modal from "react-bootstrap/Modal";


const PaymentSuccessfullModal = (props: any) => {

  return (
    <>
      <Modal
        show={props?.showPaymentSuccessfullModal}
        className="createCompanypopup medium forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Premium subscription</h2>
            <a
              className="close"
              onClick={() => {
                props?.setShowPaymentSuccessfullModal(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder forBillingCenter pt-3 pb-0 text-center">
            <div className="payement_success">
              <div className="ico">
                <span className="icon-check-article"></span>
              </div>
              <span className="txt mb-2">Payment successful</span>
              <p className="pt-3">
                Your invoice number is{" "}
                <span className="font-weight-bold invoke">
                  {props?.invoiceNumber}
                </span>
              </p>
              <p>Thank you for your purchase.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder text-center">
            <button
              className="btn btn-devinsider containerBtnBtn pl-5 pr-5"
              onClick={() => props.goToSettings()}
            >
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentSuccessfullModal;


