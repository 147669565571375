/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import Header from "../../../../../widget";
import {
  concatString,
  fileUrl,
  refaCtoNullKey,
  setInternationalPhoneNumber,
} from "../../../../../../utils";
import Footer from "../../../../../widget/footer/footer";
import * as BlocEditExpert from "./modal/index";
import { rootState } from "../../../../../../redux/reducers";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling-white.svg";
import { contact } from "../../../../../../utils/country_phone";

export default function ProfilISVEdit() {
  const providerEditAbout = BlocEditExpert.UseEditAbout();
  const providerUseEditOverview = BlocEditExpert.UseEditOverview();
  const providerUseEditInfoUserExpert = BlocEditExpert.UseEditInfoUserExpert();
  const provideUseEditCompany = BlocEditExpert.UseEditCompany();
  const providerEditContact = BlocEditExpert.UseEditContactExpert();
  const providerEditProfil = BlocEditExpert.UseEditProfil();
  const providerUseUpdateLogo = BlocEditExpert.UseUpdateLogo();
  const userState = useSelector((state: rootState) => state.userReducer);
  const history = useHistory();
  const [dialCode, setDialCode] = useState<string | null>("");

  const goToProfil = () => {
    history.push("/profil");
  };
  useEffect(() => {
    if (
      userState.phone_number_code !== "" &&
      userState.phone_number_code !== null
    ) {
      setDialCode(
        contact.filter(
          (element) => element.code === userState.phone_number_code
        )[0].dial_code
      );
    }
  }, []);

  return (
    <div className="isv edit profilIsv">
      <Header />
      <div className="contentProfil">
        <div className="row profilHead m-0 justify-content-between">
          <div className="col-xl-7 col-lg-7 col-md-12 col-12 infoProfil d-flex">
            <div
              className={`imageProfil ${userState.file_name ? "overnoBg" : ""}`}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  providerEditProfil?.setShowImage(true);
                }}
              >
                <div className="absphoto">
                  <span className="icon-edit-full"></span>
                  Edit photo
                </div>
                <img src={fileUrl(userState.file_name)} alt="" />
              </a>
            </div>
            <div className="aboutProfil">
              <a
                onClick={() =>
                  providerUseEditInfoUserExpert?.setShowModal(true)
                }
                className="editItem"
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <ul className="ulListInfo">
                <li>
                  <div className="label">Full name</div>
                  <div className="infoLabel">
                    {userState.first_name} {userState.last_name}
                  </div>
                </li>
                <li>
                  <div className="label">Location</div>
                  <div className="infoLabel">
                    {concatString(
                      userState?.country?.country_name,
                      userState?.city
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">Job title</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(userState?.job_title, "-")}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-12 guest-action">
            <a onClick={() => goToProfil()} className="editDoneIsv">
              Done editing
            </a>

            <div
              className="guest-company-logo"
              onClick={() => {
                providerUseUpdateLogo.handleBtn();
              }}
            >
              <img
                src={
                  providerUseUpdateLogo.selectedFile
                    ? URL.createObjectURL(providerUseUpdateLogo.selectedFile)
                    : fileUrl(userState?.expertCompany?.logo)
                }
                style={{ objectFit: "cover" }}
              />
              {providerUseUpdateLogo?.isLoaderShow ? (
                <Loader />
              ) : (
                <>
                  <a>
                    <span className="icon-edit-full"></span>Edit your Company
                    logo
                  </a>
                </>
              )}

              <input
                type="file"
                name=""
                id=""
                accept="image/*"
                style={{ display: "none" }}
                ref={providerUseUpdateLogo.fileRef}
                onChange={(e: any) => providerUseUpdateLogo.changeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div className="row otherInfoProfil m-0 justify-content-between">
          {/* <div className="col-12 infoInvestoronly">
            <span className="icon-locked"></span>
            Only investors and technology partners can view this information.
            Your personal details will never be published publicly.
          </div> */}

          <div className="col-lg-6 col-12 contact-info mb-4">
            <div className="boxEditProfil mb-2">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  providerEditContact?.setContactShow(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h3 className="title">Contact information</h3>
              <ul className="ulListInfo smallLabel">
                <li>
                  <div className="label">Primary email</div>
                  <div className="infoLabel">{userState?.email}</div>
                </li>
                <li>
                  <div className="label">Phone number</div>
                  <div className="infoLabel">
                    {/* {dialCode === "" ? <></> : <>{"(" + dialCode + ") "}</>}
                    {refaCtoNullKey(userState.phone, "-")} */}
                    {setInternationalPhoneNumber(userState?.phone, dialCode)}
                  </div>
                </li>
                <li>
                  <div className="label">LinkedIn</div>
                  <div className="infoLabel">
                    <a
                      target="_blank"
                      href={
                        "https://www.linkedin.com/in/" +
                        refaCtoNullKey(userState.linkedin, "-")
                      }
                    >
                      {refaCtoNullKey(userState.linkedin, "-")}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-12 contact-info mb-4">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  provideUseEditCompany?.setCompanyDetailsShow(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h3 className="title">Company details</h3>
              <ul className="ulListInfo smallLabel">
                <li>
                  <div className="label">Trade name</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(
                      userState?.expertCompany?.company_trade_name,
                      "-"
                    )}
                  </div>
                </li>

                <li>
                  <div className="label">Legal name</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(
                      userState?.expertCompany?.company_legal_name,
                      "-"
                    )}
                  </div>
                </li>

                <li>
                  <div className="label">Website</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(userState?.expertCompany?.website, "-")}
                  </div>
                </li>
                <li>
                  <div className="label">Location</div>
                  <div className="infoLabel">
                    {concatString(
                      userState?.expertCompany?.country?.country_name,
                      userState?.expertCompany?.city,
                      ", "
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-12 contact-info mb-4 mr-4">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  providerEditAbout.setShowModal(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h2 className="title">Expertise</h2>
              <p>{refaCtoNullKey(userState?.plain_text, "")}</p>
            </div>
          </div>
          <div className="col-lg-6 col-12 contact-info mb-4">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={(e) => {
                  e.preventDefault();
                  providerUseEditOverview.setShowModal(true);
                }}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>

              <h3 className="title">Company overview</h3>

              <p>
                {refaCtoNullKey(userState?.expertCompany?.company_overview, "")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Edit About */}
      <BlocEditExpert.ModalAbout
        updateInfoAbout={providerEditAbout.updateInfoAbout}
        showModal={providerEditAbout.showModal}
        isLoaderShow={providerEditAbout.isLoaderShow}
        hideModal={() => providerEditAbout.setShowModal(false)}
        cancelChange={() => providerEditAbout.cancelChange()}
        expertise={providerEditAbout.expertise}
        handleChangeExpertise={(txt) =>
          providerEditAbout.handleChangeExpertise(txt)
        }
      />

      {/* Edit Overview */}
      <BlocEditExpert.ModalOverView
        updateInfoOverview={providerUseEditOverview.updateInfoOverview}
        showModal={providerUseEditOverview.showModal}
        isLoaderShow={providerUseEditOverview.isLoaderShow}
        hideModal={() => providerUseEditOverview.setShowModal(false)}
        cancelChange={() => providerUseEditOverview.cancelChange()}
        companyOverview={providerUseEditOverview.companyOverview}
        handleCompanyOverview={(txt) =>
          providerUseEditOverview.handleCompanyOverview(txt)
        }
      />
      <BlocEditExpert.ModalInfoUser
        {...providerUseEditInfoUserExpert}
        labelCity="city"
        labelCompanyName="companyName"
        labelFirstName="firstName"
        labelJobTitle="jobTitle"
        labelLastName="lastName"
        labelLocation="location"
        labelWebsite="website"
        required
      />

      <BlocEditExpert.ModalInfoCompany
        {...provideUseEditCompany}
        labelCity="city"
        labelCompanyTradeName="companyTradeName"
        labelCompanyLegalName="companyLegaName"
        labelLocation="location"
        labelWebsite="website"
        required
      />
      <BlocEditExpert.ModalContact {...providerEditContact} />

      <BlocEditExpert.ModalEditProfil {...providerEditProfil} />
      <Footer />
    </div>
  );
}
