import {useSelector} from "react-redux";
import {rootState} from "../../../../../redux/reducers";
import {OEM_PROGRAM, url} from "../../../../../service/constant";
import {useEffect, useState} from "react";
import {ICardInformation} from "../../../../../controller/settings-ctr/billing-center/use-global-billing";
import {getDataMicroService, postDataMicroService} from "../../../../../service/applicatif/back-end-service";
import {toast} from "react-toastify";

const cardTxt = {
    withoutCard: {
        addOrUpdate: 'Add billing information',
        carteInfo: 'No credit/debit card registered'
    },
    withCard: {
        addOrUpdate: 'Update billing information',
        carteInfo: 'You have a registered card'
    },
}

export default function usePaymentMethodHook(
    setShowModalPaymentInfo: (show: boolean) => void
) {
    const userReducer = useSelector((state: rootState) => state.userReducer);
    const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);
    const isOem = userReducer.roles.indexOf(OEM_PROGRAM) !== -1;

    const isBillingOwner =
        oemProgram?.program?.billing_owner_email === userReducer?.email;

    const [txtMessage, setTxtMessage] = useState({
        carteInfo: 'Loading...',
        addOrUpdate: '',
        infoSuccessfull: '',
    });

    const [removeCard, setRemoveCard] = useState(false);
    const [showRemoveCard, setShowRemoveCard] = useState(false);
    const [loadingCardInformation, setLoadingCardInformation] =
        useState<boolean>(false);
    const [cardInformation, setCardInformation] = useState<ICardInformation>({
        brand: '',
        exp_month: '',
        exp_year: '',
        last4: '',
    });
    const [currentUserHasRegisteredCard, setCurrentUserHasRegisteredCard] = useState(false)
    const [unpaidInvoice, setUnpaidInvoice] = useState<{
        currency: string,
        amount: string
    }>();
    const [paymentMethodId, setPaymentMethodId] = useState<string>()
    const [showConfirmPayment, setShowConfirmPayment] = useState(false);
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const showPopRemoveCard = () => {
        setShowModalPaymentInfo(false);
        setShowRemoveCard(true);
    };
    const closePopRemoveCard = () => {
        setShowRemoveCard(false);
    };

    const getEntityTypeId = () => {
        if (userReducer.roles.indexOf(OEM_PROGRAM) !== -1)
            return { entity_type: 'OEM_PROGRAM', entity_id: oemProgram.program.id };
        return undefined
    };

    useEffect(() => {
        getDataMicroService(
            url.microservice.payment.payment_method,
            isOem ? { oem_program_id: oemProgram.program.id } : {},
            userReducer.token)
            .then(response => {
                if (response.data.message === 'success') {
                    if (response.data.data) {
                        setCurrentUserHasRegisteredCard(true)
                        setCardInformation(response.data.data.card)
                        setTxtMessage({
                            ...txtMessage,
                            ...cardTxt.withCard
                        })
                    } else {
                        setCurrentUserHasRegisteredCard(false)
                        setTxtMessage({
                            ...txtMessage,
                            ...cardTxt.withoutCard
                        })
                    }
                } else {
                    throw response.data.data.detail
                }
            }).catch(error => {
            setTxtMessage({
                ...txtMessage,
                carteInfo: 'Error retrieving card informations.'
            })
            toast("Error on getting payment information")
            console.log(error)
        })
    }, [isOem, oemProgram.program.id, userReducer.token]);

    const successUpdate = (isAdd: boolean) => {
        setCurrentUserHasRegisteredCard(isAdd)
        setTxtMessage({
            ...txtMessage,
            ...(isAdd ? cardTxt.withCard : cardTxt.withoutCard)
        })
    }

    const postNewCard = (paymentMethod: string) => {
        postDataMicroService(
            url.microservice.payment.payment_method,
            isOem ? {
                payment_method_id: paymentMethod,
                oem_program_id: oemProgram.program.id,
            } : {
                payment_method_id: paymentMethod
            },
            userReducer.token)
            .then(response => {
                if (response.data.message === 'success') {
                    successUpdate(true);
                    setShowModalPaymentInfo(false);
                    setCardInformation(response.data.data.card)
                    toast("You have successfully registered your payment information.");
                } else {
                    throw response.data.data.detail;
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.detail) {
                    toast(error.response.data.data.detail)
                    console.log(error)
                } else {
                    if (typeof error === 'string')
                        toast(error)
                    else
                        toast('Error saving payment method')
                    console.log(error)
                }
            })
            .finally(() => {
                setShowButtonLoader(false)
            })
    }

    const confirmPay = () => {
        if (paymentMethodId) {
            setShowButtonLoader(true)
            postNewCard(paymentMethodId)
        }
    }

    return {
        txtMessage,
        isOem,
        isBillingOwner,
        currentUserHasRegisteredCard,
        removeCard,
        showPopRemoveCard,
        setRemoveCard,
        loadingCardInformation,
        cardInformation,
        successUpdate,
        oemProgram,
        closePopRemoveCard,
        showRemoveCard,
        getEntityTypeId,
        unpaidInvoice,
        showConfirmPayment,
        setShowConfirmPayment,
        confirmPay,
        setUnpaidInvoice,
        setPaymentMethodId,
        postNewCard,
        showButtonLoader,
        setShowButtonLoader
    }
}