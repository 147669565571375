import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { postWithoutToken } from "../../../service/api";
import { url } from "../../../service/constant";
export default function UseCompleteContributorAccount() {
  let params: any = useLocation();
  const [showLoader, setShowLoader] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener("popstate", () => {
      history.go(1);
    });
  }, []);

  const resendEmail = async () => {
    const email = params?.state.email;
    if (email) {
      setShowLoader(true);

      let response = await postWithoutToken(
        url.acount.resendOdooMailRequest + email,
        {}
      );
      if (response?.status === 200) {
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    }
  };

  return {
    params: params?.state,
    showLoader,
    resendEmail,
  };
}
