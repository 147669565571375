import { useState } from "react";
import "./billing-center.scss";
import BillingOwner from "./billing-owner/billing-owner";
import * as T from "../../../../controller/company-ctr/types/types-billings";
import UseManageCompanyAndBilling from "../../../../screen/widget/manage-company-and-billing/use-manage-company-and-billing";
import { OEM_PROGRAM } from "../../../../service/constant";
import CancelSubscription from "../../../widget/cancel-subscription";
import ModalPermission from "../../../widget/permission-oem";
import ContactSupport from "./contact-support/contact-support";
import MounthlyBudget from "./mounthly-budget/index";
import UseModalMounthlyMudget from "./mounthly-budget/use-modal-mounthly-budget";
import PaymentMethodInfo from "./payment-method-info/payment-method-info";
import PlanDetail from "./plan-detail/index";
import Receipts from "./receipts/receipts";
import ConfirmUpdateRenewalSubscription from "./subscription-plan/confirm-update-subscription";
import TransactionHistory from "./transaction-history/transaction-history";

export default function BillingCenter(props: T.TPropsBillingCenter) {
  const ProviderUseManageCompanyAndBilling = UseManageCompanyAndBilling();
  const providerMounthLy = UseModalMounthlyMudget();
  const [showModalPaymentInfo, setShowModalPaymentInfo] =
    useState<boolean>(false);

  const isOem = props.role.indexOf(OEM_PROGRAM) !== -1;

  return (
    <div className="contentRightsettings">
      <h1 className="title">Billing center</h1>
      <div className="contentBilling">
        {/*********** PLAN DETAILS ***********************/}
        {/* { isNotGuest && isNotExpert &&  */}
        <PlanDetail providerInfo={ProviderUseManageCompanyAndBilling} />
        {/* } */}
        {/************ PAYMENT INFO *************/}
        <PaymentMethodInfo
          showModalPaymentInfo={showModalPaymentInfo}
          setShowModalPaymentInfo={setShowModalPaymentInfo}
          showMonthlyPopup={providerMounthLy.showModalMounthly}
          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
          roleUserIncurrentProgram={
            ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
          }
          setShowModalPermissionOem={
            ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
          }
        />
        {isOem && (
          <MounthlyBudget
            //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
            roleUserIncurrentProgram={
              ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
            }
            setShowModalPermissionOem={
              ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
            }
            providerMounthLy={providerMounthLy}
          />
        )}
        {/*********** TRANSACTION HISTORY *****************/}
        <TransactionHistory
          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
          roleUserIncurrentProgram={
            ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
          }
          setShowModalPermissionOem={
            ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
          }
          showViewPayement={() =>
            ProviderUseManageCompanyAndBilling?.showTransactionBilling()
          }
          transactionDate={props.transactionDate}
          userObject={ProviderUseManageCompanyAndBilling.userObject}
          oemProgramObject={ProviderUseManageCompanyAndBilling.oemProgramObject}
        />
        {/*********** RECEIPTS *********************/}
        <Receipts
          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
          roleUserIncurrentProgram={
            ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
          }
          setShowModalPermissionOem={
            ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
          }
          showViewPayement={() =>
            ProviderUseManageCompanyAndBilling?.showReceiptBilling()
          }
          receiptDate={props.receiptDate}
          transactionDate={props.transactionDate}
          oemProgramObject={
            ProviderUseManageCompanyAndBilling?.oemProgramObject
          }
          userObject={ProviderUseManageCompanyAndBilling?.userObject}
        />
        {/* USER HAS BILLING OWNER (ISV,OEM) */}
        {isOem && (
          <>
            <BillingOwner
              //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
              roleUserIncurrentProgram={
                ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
              }
              setShowModalPermissionOem={
                ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
              }
              billing_owner_username={props?.billing_owner_username}
              billing_owner_email={props?.billing_owner_email}
              admin_members={props?.admin_members}
              billing_owner_registration={props?.billingOwnerRegistration}
              register_billing_owner={props?.register_billing_owner}
              company_page_id={props?.company_page_id}
            />
            <CancelSubscription
              show={ProviderUseManageCompanyAndBilling.showCancelSubscription}
              handleShow={() =>
                ProviderUseManageCompanyAndBilling.setShowCancelSubscription(
                  false
                )
              }
              program={ProviderUseManageCompanyAndBilling?.oemProgramObject}
              handleShowConfirm={(value: boolean) =>
                ProviderUseManageCompanyAndBilling?.setShowConfirmAutoRenewal(
                  value
                )
              }
            />
            <ConfirmUpdateRenewalSubscription
              show={ProviderUseManageCompanyAndBilling.showConfirmAutoRenewal}
              handleShowConfirm={() =>
                ProviderUseManageCompanyAndBilling.setShowConfirmAutoRenewal(
                  false
                )
              }
              program={ProviderUseManageCompanyAndBilling?.oemProgramObject}
              cancelSubscriptionRenew={(params: string) =>
                ProviderUseManageCompanyAndBilling?.cancelSubscriptionRenew(
                  params
                )
              }
              enableSubscriptionRenew={(params: string) =>
                ProviderUseManageCompanyAndBilling?.enableSubscriptionRenew(
                  params
                )
              }
              showLoader={ProviderUseManageCompanyAndBilling?.loaderConfirm}
            />
          </>
        )}
        {/******* CONTACT SUPPORT ******************/}
        <ContactSupport
          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
          roleUserIncurrentProgram={
            ProviderUseManageCompanyAndBilling.roleUserIncurrentProgram
          }
          setShowModalPermissionOem={
            ProviderUseManageCompanyAndBilling.setShowModalPermissionOem
          }
        />
        {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center"
        pour le rôle "Outreach representative" ET "Editor" 
        MENY PERMISSION
        */}
        <ModalPermission
          show={ProviderUseManageCompanyAndBilling.showModalPermissionOem}
          handleSkhow={() =>
            ProviderUseManageCompanyAndBilling.setShowModalPermissionOem(false)
          }
        />
      </div>
    </div>
  );
}
