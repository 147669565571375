import StartContributor from '../screen/container/acount/complete-create-acount-expert-contributor/start-contributor/start-contributor';
import CompleteInfoExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-info-expert/complete-info-expert';
import CompleteCompanyExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-company-expert/complete-company-expert';
import CompleteAboutExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-about-expert/complete-about-expert';
import CompletePostExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-post-expert/complete-post-expert';
import CompleteCountryExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-country-expert/complete-country-expert';
import CompleteInterestedExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-interested-expert/complete-interested-expert';
import CompletePictureExpert from '../screen/container/acount/complete-create-acount-expert-contributor/complete-picture-expert/complete-picture-expert';
import CompleteContributorAccount from '../screen/container/acount/complete-create-acount-expert-contributor/complete-contributor-account/complete-contributor-account';
import ProfilEditExpert from '../screen/container/profil/user-profil/profil-edit/profil-edit-expert/profil-edit-expert';
import { CONTRIBUTOR } from '../service/constant';

export const routesExpert = [
  {
    path: '/start-expert',
    exact: true,
    component: StartContributor,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-info-expert',
    exact: true,
    component: CompleteInfoExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-expert',
    exact: true,
    component: CompleteCompanyExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-about-expert',
    exact: true,
    component: CompleteAboutExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-post-expert',
    exact: true,
    component: CompletePostExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-country-expert',
    exact: true,
    component: CompleteCountryExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-interested-expert',
    exact: true,
    component: CompleteInterestedExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-picture-expert',
    exact: true,
    component: CompletePictureExpert,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-contributor-account',
    exact: true,
    component: CompleteContributorAccount,
    guard: 'not-logged-page',
  },
  {
    path: '/expert/profile/edit',
    exact: true,
    component: ProfilEditExpert,
    guard: 'private',
    allowedRole: [CONTRIBUTOR],
  },
];
