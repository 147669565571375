import React, { useState, useEffect } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";

import {
  isRequired,
  setSessionStorageObject,
  isURL,
  getSStorage,
} from "../../../utils/index";

export default function useCompleteAdresse() {
  let params: any = useLocation();
  const history = useHistory();
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [country, setCountry] = useState<string>(
    getSStorage("country") ? getSStorage("country") || "" : ""
  );
  const [phone, setPhoneNumber] = useState<string>(
    getSStorage("phone") ? getSStorage("phone") || "" : ""
  );
  const [companyName, setCompany] = useState<string>(
    getSStorage("companyName") ? getSStorage("companyName") || "" : ""
  );
  const [jobTitle, setJob] = useState<string>(
    getSStorage("jobTitle") ? getSStorage("jobTitle") || "" : ""
  );

  const [webSite, setWebSite] = useState<string>(
    getSStorage("webSite") ? getSStorage("webSite") || "" : ""
  );

  const [countryError, setErrorCountry] = useState<boolean>(false);
  const [phoneError, setErrorPhone] = useState<"validate" | "required" | null>(
    null
  );
  const [companyNameError, setCompanyNameError] = useState<boolean>(false);
  const [jobTytleError, setJobTitleError] = useState<boolean>(false);
  const [errorWebSiteIsv, setErrorWebSiteIsv] = useState<boolean>(false);

  const [selected, setSelected] = useState(
    sessionStorage.getItem("phoneNumberCode") || "US"
  );

  const [dialCodeSelected, setDialCodeSelected] = useState(
    sessionStorage.getItem("dialCode") || "+1"
  );

  /*
   * Text message error
   */
  const [txtPhoneNumberError, setTxtPhoneNumberError] = useState("");
  const [websiteTxtError, setTxtWebsiteError] = useState("");

  useEffect(() => {
    setErrorCountry(false);
    return () => {};
  }, [country]);

  useEffect(() => {
    setErrorPhone(null);
    return () => {};
  }, [phone]);

  useEffect(() => {
    setCompanyNameError(false);
    return () => {};
  }, [companyName]);

  useEffect(() => {
    setJobTitleError(false);
    return () => {};
  }, [jobTitle]);

  useEffect(() => {
    setErrorWebSiteIsv(false);
    return () => {};
  }, [webSite]);

  const validCountry = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorCountry(true);
      return false;
    }
  };

  const validPhone = (txt: string): boolean => {
    if (txt) {
      const validatePhone = isValidPhoneNumber(dialCodeSelected + txt);
      if (validatePhone) {
        return true;
      } else {
        setErrorPhone("validate");
        return false;
      }
    } else {
      return true;
    }
  };

  const validCompanyName = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setCompanyNameError(true);
      return false;
    }
  };

  const validJobTytle = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setJobTitleError(true);
      return false;
    }
  };

  const validWebSiteIsv = (txt: string): boolean => {
    if (isRequired(txt)) {
      if (isURL(txt)) {
        return true;
      }
      setTxtWebsiteError("Your website url is not valid");
      setErrorWebSiteIsv(true);
      return false;
    } else {
      setTxtWebsiteError("This field is required");
      setErrorWebSiteIsv(true);
      return false;
    }
  };
  const choiseInterested = () => {
    if (
      validCountry(country) &&
      validPhone(phone) &&
      validCompanyName(companyName) &&
      validJobTytle(jobTitle) &&
      validWebSiteIsv(webSite)
    ) {
      setSessionStorageObject({
        phone,
        companyName,
        jobTitle,
        country,
        webSite,
        phoneNumberCode: selected,
        dialCode: dialCodeSelected,
      });
      history.push("/complete-interested", {
        country,
        phone,
        companyName,
        jobTitle,
        phoneNumberCode: selected,
        dialCode: dialCodeSelected,
        ...params.state,
      });
    } else {
      setTxtPhoneNumberError("The phone number is not a  valid format");
      return;
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      choiseInterested();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    params,
    country,
    phone,
    companyName,
    jobTitle,
    history,
    countryError,
    phoneError,
    jobTytleError,
    companyNameError,
    txtPhoneNumberError,
    webSite,
    setWebSite,
    setPhoneNumber,
    setCountry,
    setCompany,
    setJob,
    choiseInterested,
    websiteTxtError,
    errorWebSiteIsv,
    dataCompleted,
    selected,
    setSelected,
    dialCodeSelected,
    setDialCodeSelected,
  };
}
