/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent, useEffect } from 'react';
/*import { Redirect } from 'react-router-dom';
*/
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import './report.scss';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import useReportCompany from '../../../../../controller/account-ctr/complete-create-acount-isv/use-report-company';
import useEventListener from '../../../../../hooks/event-handler';
import { contact } from '../../../../../utils/country_phone';
import { concatString, isURL, refaCtoNullKey } from '../../../../../utils/';
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { ReactComponent as Close } from '../../../../../resources/images/close.svg.svg';
import ReactFlagsSelect from 'react-flags-select';
import {ModalReport} from "../modal/ModalReport";

type Props = {
    showModal: boolean
    setShowModal: (show: boolean) => void
    c_id: string;

}

export type IpropsModal = {
  txt: string;
  isShowModal: boolean;
  closeModal: () => void;
  changeTxt: (txt: string) => void;
  cancelChange: () => void;
  updateTextArea: () => void;
  isLoaderShow: boolean;
};

export const ModalReportCompany: React.FC<Props> = ({showModal, setShowModal, c_id}) => {

  const {
    phone,
    setPhoneNumber,
    dialCodeSelected,
    setDialCodeSelected,
    selected,
    setSelected,
    phoneError,
    name,
    nameError,
    setName,
    setEmail,
    email,
    emailError,
    emailTxtError,
    yourConcern,
    yourConcernError,
    setYournConcern,
    reportCompany,
    showModalReport,
    setShowModalReport,
    loading,
  } = useReportCompany();

  // const keyEnter = ({ key }: any) => {
  //   if (key == 'Enter') {
  //     choiseInterested();
  //   } else return;
  // };

  // useEventListener('keydown', keyEnter);

  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : '');
  };

  const handleSubmit = async (c_id: any) => {
    const report = await reportCompany(c_id);
    if(report) {
      setShowModal(false);
    }
  }

  useEffect(() => {
    if(showModal) {
      setPhoneNumber("");
      setYournConcern("");
    }
  }, [showModal]);

    return (
      <>
         <Modal
        show={showModal}
        id="company-page"
        className='modal-dialog'
        >
    
         <Modal.Header>
          <Modal.Title className="report">Report company page</Modal.Title>
        {/*  <svg width={16} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="close">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>*/}
          <span  onClick={() => setShowModal(false)} className="close">
            
          </span>

        </Modal.Header>
        <Modal.Body>
          <div className="px-4">
            <div className='required form-group col-12 p-0'>
              <label
              htmlFor='first-name'
              className={`labelForEmailgetStarted `}
              >
              Name
              <div
                className={`container-error-address pl-2 ${
                  nameError ? 'd-inline' : 'd-none'
                }`}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
              </label>
              <input
              type='text'
              value={name}
              className='input-text form-control'
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setName(event.target.value)
              }
              />
            </div>
            <div className='form-group required col-12 p-0'>
              <label htmlFor=''>
              Phone Number
              {phoneError === 'validate' && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>Phone number is not valid</span>
                  </div>
              )}
              {phoneError === 'required' && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>This field is required</span>
                  </div>
              )}
              </label>
              <div className='numberphone' style={{ position: 'relative' }}>
                <input
                  type='number'
                  className='phonenumber'
                  value={phone}
                  style={{
                    textIndent:
                      dialCodeSelected?.length === 2
                        ? 35
                        : dialCodeSelected?.length === 3
                        ? 40
                        : dialCodeSelected?.length === 4
                        ? 50
                        : 60,
                    paddingBottom: 5,
                  }}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    setPhoneNumber(evt.target.value);
                  }}
                />
                <span style={{ position: 'absolute', top: 5, left: 60 }}>
                ({dialCodeSelected})
                </span>
                <ReactFlagsSelect
                  className='selectFlag'
                  selected={selected}
                  onSelect={handleSelect}
                  searchable={true}
                  showSelectedLabel={false}
                  placeholder=' '
                />
              </div>
            </div>

            <div className='required form-group col-12 p-0'>
              <label
              htmlFor='first-name'
              className={`labelForEmailgetStarted `}
              >
              Email Address
              {emailError && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>{emailTxtError}</span>
                  </div>
              )}
              </label>

              <input
              type='text'
              className='input-text form-control'
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setEmail(event.target.value)
              }
              />
            </div>

        
            <div className="col-12 p-0 required">
              <label htmlFor="text">
                Your concern
                {yourConcernError && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>This field is required</span>
                  </div>
                )}
              </label>
                <textarea
                  name=""
                  value={yourConcern}
                  onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                    setYournConcern(evt.target.value)
                  }
                  maxLength={450}
                />
          
            </div>
      

          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          <div className='m-0 d-flex mb-2 mx-4'>
          <button className="cancelbtn btn">
            <a
           onClick={() => setShowModal(false)}
          >
          Cancel
          </a>
          </button>
          <button
          className='btn btn-devinsider containerBtnBtn'
          onClick={() => handleSubmit(c_id)}
          >
          {loading ? <Loader /> : 'Submit'}
          </button>
          </div>
        </Modal.Footer>
        </Modal>
        <ModalReport
          showModal={showModalReport}
          setShowModal={setShowModalReport}
        />
      </>
        )
}


