import { useState } from "react";

export default function UseFilterControl() {
  const [match, setMatch] = useState<Array<number>>([60, 100]);
  const [size, setSize] = useState<Array<number>>([]);

  const [selectedType, setSelectedType] = useState<Array<number>>([]);
  const [selectedHqLocation, setSelectedHqLocation] = useState<Array<number>>(
    []
  );
  const [selectedIndustry, setSelectedIndustry] = useState<Array<number>>([]);
  const [selectedSolution, setSelectedSolution] = useState<Array<number>>([]);

  const handleMatch = (size: Array<number>) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (size[0]) {
      case 0:
        tempMin = 70;
        break;
      case 1:
        tempMin = 80;
        break;
      case 2:
        tempMin = 90;
        break;
      case 3:
        tempMin = 100;
        break;
      default:
        tempMin = 70;
        break;
    }
    switch (size[1]) {
      case 0:
        tempMax = 70;
        break;
      case 1:
        tempMax = 80;
        break;
      case 2:
        tempMax = 90;
        break;
      case 3:
        tempMax = 100;
        break;
      default:
        tempMax = 100;
        break;
    }
    setMatch([tempMin, tempMax]);
    setSize([size[0], size[1]]);
  };

  const resetFilter = () => {
    setSelectedType([]);
    setSelectedHqLocation([]);
    setSelectedIndustry([]);
    setSelectedSolution([]);
    setMatch([60, 100]);
    setSize([]);
  };

  return {
    match,
    selectedHqLocation,
    selectedIndustry,
    selectedSolution,
    selectedType,
    setMatch: handleMatch,
    setSelectedHqLocation,
    setSelectedIndustry,
    setSelectedSolution,
    setSelectedType,
    size,
    resetFilter,
  };
}
