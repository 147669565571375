import React from "react";

const HowBillingWorks = () => {
  return (
    <>
      <span className="title">Payment</span>
        <span className="desc">
        ISV unlocks will be charged once you reach a 300 USD/EUR credit balance or on the last day of the month. Other transactions will be charged immediately.
        </span>
    </>
  );
};

export default HowBillingWorks;
