/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import {
  CONTRIBUTOR,
  GUEST,
  OEM_PROGRAM,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from "../../../service/constant/index";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import {
  checkType,
  fileUrl,
  renderCompanyTexte,
  uppercaseFirstLetter,
} from "../../../utils";
import UseManagePage from "../../widget/manage-company-and-billing/use-manage-company-and-billing";

import * as BlocAccount from "./bloc-account/index";
import { IPropsManageProgram } from "./bloc-account/manage-programe-oem";
import { IPopsModal } from "./bloc-account/modal-desactive-program";
import ModalDesactiveAccount from "./bloc-account/modal-desactive-account";
import ModalNoCompany from "./bloc-account/modal-no-company";
import _ from "lodash";
import ManageOemPage from "../../widget/manage-company-and-billing/index";

type PropsAccount = IPropsManageProgram &
  IPopsModal & {
    navigateToEditProfil: () => void;
    isShowFormClauseAccount: boolean;
    feedBackClauseAccount: string;
    loaderClauseAcount: boolean;
    setIsShowFormClauseAccount: (data: boolean) => void;
    setFeedBackClauseAccount: (data: string) => void;
    setRaisonClauseAcount: (data: string) => void;
    clauseAccount: () => void;
    navigateToCompanyPage: (type?: string) => void;
    roleUserIncurrentProgram: () => string;
    showModalConfirmClauseAccount: boolean;
    manageCompany: () => void;
    showModalNoCompany: boolean;
    setShowModalNoCompany: () => void;
    validClauseAccount: () => void;
  };

export const Account: FunctionComponent<PropsAccount> = (
  props: PropsAccount
) => {
  // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
  let userObject = useSelector((state: rootState) => state.userReducer);

  console.log("userObject", userObject);

  const providerManagePage = { ...UseManagePage() };

  const role = userObject?.roles?.filter(
    (role: string) => role !== "ROLE_USER"
  )[0];

  const companyTxt = renderCompanyTexte(
    userObject.roles.filter((role: string) => role !== "ROLE_USER")[0]
  );

  const isOemUser =
    userObject.roles.filter((role: string) => role !== "ROLE_USER")[0] ===
    OEM_PROGRAM;

  const showManageOem = () => {
    props.clauseAccount();
    providerManagePage.setTabNavigate("role");
    providerManagePage.setShowBillingCenter(true);
  };
  return (
    <div className="accountSettings contentRightsettings">
      <h1 className="title">Devinsider account settings</h1>
      <div className="infoAccount row p-0 m-0">
        <ul className="listSettings col-xl-7 col-lg-12 col-12 p-0 pr-5">
          <li>
            <h2 className="title">
              Edit your Devinsider profile
              <a onClick={props?.navigateToEditProfil}>Edit</a>
            </h2>
            <div className="itemsEditProfil">
              <div className="underh2">
                Change your picture or the information displayed in your
                profile.
              </div>
            </div>
          </li>

          {role !== CONTRIBUTOR && role !== GUEST && (
            <li>
              <h2 className="title">
                Manage your {companyTxt}
                <a onClick={() => props?.navigateToCompanyPage("edit")}>Edit</a>
              </h2>
              <div className="itemsEditProfil withLink">
                <div className="underh2">
                  Manage the access & the information displayed in your
                  {companyTxt}
                </div>
                {isOemUser &&
                  !checkType(
                    [props.roleUserIncurrentProgram()],
                    [OEM_PROGRAM_EDITOR, OEM_PROGRAM_OUTREACH_REPRESENTATIVE]
                  ) && (
                    <>
                      <BlocAccount.ManageProgrameOem
                        //Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
                        desactiveAccount={props.handleModal}
                        showManagePage={props.showManagePage}
                        showTargeting={props.showTargeting}
                      />
                      <BlocAccount.ModalDesactiveProgram
                        // // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
                        showModal={false}
                        handleModal={props.handleModal}
                        showModalDeactivateProgram={
                          props.showModalDeactivateProgram
                        }
                        processingDeactivateProgram={
                          props.processingDeactivateProgram
                        }
                        handleSubmitDeactivateProgram={
                          props.handleSubmitDeactivateProgram
                        }
                      />
                    </>
                  )}

                {userObject?.isvInCompanyPage?.role === "ADMIN" && (
                  <div
                    className="underh2Over"
                    onClick={() => props?.navigateToCompanyPage()}
                  >
                    <a>
                      Affiliated insiders -
                      {uppercaseFirstLetter(userObject?.isvInCompanyPage?.role)}
                    </a>
                  </div>
                )}
              </div>
            </li>
          )}

          <li>
            <h2 className="title">
              Close your account
              <a
                href="javascript:;"
                onClick={() => props?.setIsShowFormClauseAccount(true)}
              >
                {!props?.isShowFormClauseAccount && "Edit"}
              </a>
            </h2>
            <div className="itemsEditProfil">
              <div className="underh2">
                Close your account permanently if you wish.
              </div>
            </div>

            <div
              className={`reasonOfleave multichoosecontent ${
                props?.isShowFormClauseAccount ? "d-block" : "d-none"
              }`}
            >
              <div className="custom-control px-0 py-3 d-block">
                <label htmlFor="otherReason">
                  Your feedback matters. Is there anything else you'd like us to
                  know ?
                </label>
                <textarea
                  name="otherReason"
                  id="otherReason"
                  className="otherreason"
                  onChange={(txt: ChangeEvent<HTMLTextAreaElement>) =>
                    props?.setFeedBackClauseAccount(txt.target.value)
                  }
                  value={props?.feedBackClauseAccount}
                />
              </div>
              <div className="custom-control p-0 btncloseaccount d-flex">
                <button
                  className="cancel"
                  onClick={() => props?.setIsShowFormClauseAccount(false)}
                >
                  Cancel
                </button>
                <button
                  className="saveEmail"
                  onClick={() => props?.clauseAccount()}
                >
                  {props?.loaderClauseAcount ? <Loader /> : "Close account"}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div className="col-xl-5 col-lg-12 col-12 p-0 ">
          <div className="miniInfoProfil">
            <div className="cttImage">
              <img alt="" src={`${fileUrl(userObject.file_name)} `} />
            </div>
            <div className="info-final-getstarted">
              <div className="nom">
                {userObject.first_name} {userObject.last_name}
              </div>
              <div className="post">
                {userObject.job_title}
                <b>{userObject.company_name}</b>
              </div>
              <div className="membersince">
                {`Member since   ${new Date(
                  userObject?.created_at
                ).toLocaleString("en-US", {
                  month: "short",
                })} ${new Date(userObject?.created_at).getDate()}, ${new Date(
                  userObject?.created_at
                ).getFullYear()} `}
              </div>
            </div>
          </div>
        </div>
        <ModalDesactiveAccount
          showModal={props.showModalConfirmClauseAccount}
          handleModal={props.clauseAccount}
          manageCompany={() => showManageOem()}
          validClauseAccount={props.validClauseAccount}
        />

        <ModalNoCompany
          showModal={props.showModalNoCompany}
          handleModal={props.setShowModalNoCompany}
        />
        <ManageOemPage {...providerManagePage} />
      </div>
    </div>
  );
};
