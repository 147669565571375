import { ISettings, IAction } from "../../types/settings-interface";
import {
  GET_INFO_NOTIFICATION,
  UPDATE_INFO_NOTIFICATION,
} from "../../actions/settings-action/index";
export const initialState: ISettings = {
  id: 0,
  devinsider_notifications: false,
  community_general_all_community_email: false,
  community_email_notifications_categories_labels_i_follow: "",
  community_email_notifications_by_insider_i_follow: "",
  community_email_notifications_i_participated_or_favorites: false,
  community_email_notifications_solutions_discussions_or_favorite: false,
  community_email_notifications_message_iam_mentioned_in: false,
  community_email_notifications_message_community_member: false,
  community_email_notifications_members_start_following_me: false,
  marketplace_messages_i_have_received: false,
  marketplace_matchmaking_updates: false,
  marketplace_ads: false,
  corporate_development_messages_i_have_received: false,
  corporate_development_matchmaking_updates: false,
  corporate_development_ads: false
};

export const settingsReducer = (
  state = initialState,
  action: IAction
): ISettings => {
  switch (action.type) {
    case GET_INFO_NOTIFICATION:
      return { ...state, ...action.payload };
    case UPDATE_INFO_NOTIFICATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
