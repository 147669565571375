import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IPropsSettingsAnnualTournOver } from "./type";
export default function SliderCompanySize(
  props: IPropsSettingsAnnualTournOver
) {
  const createSliderWithTooltip = Slider.Range;
  const Range = createSliderWithTooltip;

  const handleChange = (event: any) => {
    props?.customSetAnnualSize(event[0], event[1]);
    props?.setAnnualTurnover(event);
    if (props?.setAnnualTurnoverChanged) props?.setAnnualTurnoverChanged(true);
  };

  return (
    <Range
      className="targetInvestorrange"
      min={1}
      max={90}
      step={10}
      value={props?.annualTurnover}
      marks={{
        0:
          //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
          (props?.annualTurnoverSlide[0] == 1
            ? "$ 0 USD"
            : "$ " + props?.annualTurnoverSlide[0]) + props?.unitTurnover,
        10: "",
        20: "",
        30: "",
        40: "",
        50: "",
        60: "",
        70: "",
        80: "",
        90:
          //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
          props?.annualTurnoverSlide[1] == 600
            ? "> $ 500" + props?.unitTurnoverMax
            : props?.annualTurnoverSlide[1] == 90
            ? "> $ 500 " + props?.unitTurnoverMax
            : "$ " + props?.annualTurnoverSlide[1] + props?.unitTurnoverMax,
      }}
      onChange={(event) => {
        handleChange(event);
      }}
    />
  );
}
